import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { useDebounce } from '@library/CustomHooks'
import InputSearch from '@library/form/fields/InputSearch'

const SymptomsSearchBar = ({ onChange }) => {
  const { t } = useTranslation()

  const [searchValue, setSearchValue] = useState('')
  const debouncedSearchValue = useDebounce(searchValue)

  useEffect(() => onChange(debouncedSearchValue), [debouncedSearchValue])

  return (
    <InputSearch
      className="symptoms-search mb-30"
      field={{ value: searchValue, onChange: setSearchValue }}
      placeholder={t('symptoms.ph.search')}
      help={t('symptoms.ph.search_help')}
    />
  )
}

export default SymptomsSearchBar
