import React from 'react'

import { initReactI18next } from 'react-i18next'

import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import intervalPlural from 'i18next-intervalplural-postprocessor'
import moment from 'moment'
//need to import these to be able to switch between moment locales
// import localeEs from 'moment/locale/es'
// import localePl from 'moment/locale/pl'
// import localeRu from 'moment/locale/ru'
import localeUk from 'moment/locale/uk'

import { setRG } from '@common/analytics/raygun'

import $global from '@store/globalStore'

import mainConfig from '@config/main'

const { availableLanguages } = mainConfig?.localization
export const defaultLang = mainConfig?.localization?.defaultLanguage || 'en-US'

const languageDetector = new LanguageDetector()

export function initI18N() {
  i18n
    .use(intervalPlural)
    .use(initReactI18next)
    .use(Backend)
    .use(languageDetector)
    .init({
      detection: {
        order: ['localStorage', 'navigator'],
      },
      supportedLngs: availableLanguages,
      nsSeparator: '::',
      fallbackLng: defaultLang,
      // debug: true,
      saveMissing: true,
      returnNull: false,
      missingKeyHandler(lngs, ns, key, fallbackValue, updateMissing, options) {
        if (_.trim(key) === '') {
          return false
        }

        const customData = { lngs, ns, key, fallbackValue, updateMissing, options }

        setRG('send', {
          error: new Error('i18next::translator: missingKey'),
          tags: ['i18n'],
          customData,
        })

        if (process.env.REACT_APP_ENV === 'dev') {
          console.warn('i18next::translator: missingKey', customData)
        }
      },
      interpolation: {
        escapeValue: false,
      },
      backend: {
        queryStringParams: { v: process.env.REACT_APP_COMMIT_HASH },
        loadPath: `${process.env.PUBLIC_URL}/translations/{{lng}}.json`,
      },
      load: 'currentOnly',
    })
    .then(() => {
      moment.locale(i18n.language)
      $global.setTranslationsReady(true)
    })
}

export async function changeCurrentLanguage(locale) {
  await i18n.changeLanguage(locale)
  moment.locale(i18n.language)
}
