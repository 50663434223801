import React from 'react'

const Upload = ({ color = '#000' }) => (
  <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.2">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M1.5 18.8789C2.32843 18.8789 3 19.5505 3 20.3789V21.8789C3 22.7073 3.67157 23.3789 4.5 23.3789H22.5C23.3284 23.3789 24 22.7073 24 21.8789V20.3789C24 19.5505 24.6716 18.8789 25.5 18.8789C26.3284 18.8789 27 19.5505 27 20.3789V21.8789C27 24.3642 24.9853 26.3789 22.5 26.3789H4.5C2.01472 26.3789 0 24.3642 0 21.8789V20.3789C0 19.5505 0.671573 18.8789 1.5 18.8789Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M15.6213 19.5002C14.4497 20.6718 12.5503 20.6718 11.3787 19.5002L4.93934 13.0609C4.35355 12.4751 4.35355 11.5254 4.93934 10.9396C5.52513 10.3538 6.47487 10.3538 7.06066 10.9396L12 15.8789V1.50023C12 0.671803 12.6716 0.000228882 13.5 0.000228882C14.3284 0.000228882 15 0.671801 15 1.50023V15.8789L19.9393 10.9396C20.5251 10.3538 21.4749 10.3538 22.0607 10.9396C22.6464 11.5254 22.6464 12.4751 22.0607 13.0609L15.6213 19.5002Z"
      />
    </g>
  </svg>
)

export default Upload
