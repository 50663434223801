import React, { useEffect, useRef, useState } from 'react'

import { observer } from 'mobx-react'
import { Trans } from 'react-i18next'

import classNames from 'classnames'

import { useFirstRender, useLoader, usePageLoaderGetGroups } from '@library/CustomHooks'
import PageNoResults from '@library/pageNoResults/PageNoResults'
import ProgressRing from '@library/ui/progressRing/ProgressRing'

import ApptsListPage from '@pages/appointments/ApptsListPage'

import { $appts } from '@store'

import mainConfig from '@config/main'

import styles from './ApptsTab.module.scss'

const ApptsTabHistory = ({ preparedPastList, patientId, fromDate, toDate }) => {
  const [page, setPage] = useState(1)

  const isFirstRender = useFirstRender() // hook if will be added filter like in Patients in PS
  const loadCounter = useRef(0)

  const isLoading = useLoader(async () => {
    await $appts.getPast({ page, patientId, fromDate, toDate })
    loadCounter.current = loadCounter.current + 1
  }, [page, fromDate, toDate])

  const pages = usePageLoaderGetGroups({
    items: preparedPastList,
    page,
    counter: loadCounter.current,
    perPage: mainConfig.apptsPageLimit,
    pathToId: 'appointment.id',
  })

  function increasePage() {
    if (!isLoading) {
      setPage((x) => Number(x) + 1)
    }
  }

  useEffect(() => {
    setPage(1)
    loadCounter.current = 0
  }, [fromDate, toDate])

  return (
    <>
      {pages.length > 0 ? (
        <>
          <div className={styles.apptsList}>
            {pages.map((x, i) => (
              <ApptsListPage
                key={x.id}
                items={x.items}
                pages={pages.length}
                page={i + 1}
                onIncreasePage={increasePage}
                perPage={mainConfig.apptsPageLimit}
              />
            ))}
          </div>
        </>
      ) : !isLoading && (fromDate || toDate) ? (
        <PageNoResults className={styles.pageNoResult}>
          <Trans i18nKey="appts.empty.filtered" />
        </PageNoResults>
      ) : (
        !isLoading && (
          <PageNoResults>
            <Trans i18nKey="appts.empty.current" components={[<br />]} />
          </PageNoResults>
        )
      )}
      {isLoading && (pages.length === 0 || page > 1) && (
        <div className={classNames('page-content_loader', page === 1 && 'page-content_loader--bg')}>
          <ProgressRing progress={25} spin={true} />
        </div>
      )}
    </>
  )
}

export default observer(ApptsTabHistory)
