var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import _ from 'lodash';
import InputSearch from '@library/form/fields/InputSearch';
import Modal from '@library/modal/Modal';
import Button from '@library/ui/button/Button';
import styles from './Select.modal.module.scss';
var SelectModal = function (props) {
    var t = useTranslation().t;
    var title = props.title, _a = props.value, value = _a === void 0 ? [] : _a, _b = props.childValue, childValue = _b === void 0 ? [] : _b, _c = props.items, items = _c === void 0 ? [] : _c, onChange = props.onChange, onChangeChild = props.onChangeChild, allOption = props.allOption, _d = props.multiSelect, multiSelect = _d === void 0 ? false : _d, _e = props.allowUnselect, allowUnselect = _e === void 0 ? true : _e, _f = props.searchBar, searchBar = _f === void 0 ? false : _f, _g = props.searchLabel, searchLabel = _g === void 0 ? '' : _g, _h = props.searchMap, searchMap = _h === void 0 ? ['text'] : _h, _j = props.saveOnSelect, saveOnSelect = _j === void 0 ? true : _j, _k = props.buttons, buttons = _k === void 0 ? false : _k, _l = props.backButton, backButton = _l === void 0 ? false : _l, _m = props.doneText, doneText = _m === void 0 ? t('btn.apply') : _m, _o = props.cancelText, cancelText = _o === void 0 ? t('btn.cancel') : _o, modalOptions = props.modalOptions, _core = props._core;
    var _p = useState(value), selected = _p[0], setSelected = _p[1];
    var _q = useState(childValue), selectedChilds = _q[0], setSelectedChilds = _q[1];
    var _r = useState(''), q = _r[0], setQ = _r[1];
    function getContent(content) {
        return _.isFunction(content) ? content() : content;
    }
    function isSelected(id, childId) {
        if (!selected.length && typeof id === 'undefined' && !selectedChilds.length) {
            return true;
        }
        if (_.includes(selectedChilds, childId)) {
            return true;
        }
        return _.includes(selected, id);
    }
    function isSelectedChild(id) {
        return _.includes(selectedChilds, id);
    }
    function hasSelectedChild(childs) {
        // todo: typify it
        var hasSelected = false;
        _.forEach(childs, function (item) {
            hasSelected = isSelectedChild(item.id);
            if (hasSelected) {
                return false;
            }
        });
        return hasSelected;
    }
    function handleSelect(id, childId) {
        if (typeof childId !== 'undefined') {
            handleSelectChild(childId);
            return;
        }
        var result = selected;
        if (isSelected(id)) {
            if (allowUnselect) {
                result = _.filter(result, function (x) { return x !== id; });
            }
            else {
                return;
            }
        }
        else if (typeof id === 'undefined') {
            result = [];
        }
        else {
            if (multiSelect) {
                result = __spreadArray(__spreadArray([], result, true), [id], false).filter(function (x) { return x; });
            }
            else {
                result = [id];
            }
        }
        setSelected(result);
        setSelectedChilds([]);
        if (_.isFunction(onChange) && saveOnSelect && !multiSelect && !buttons) {
            onChange(result);
            _core.onHide();
        }
    }
    function handleSelectChild(id) {
        var result = selectedChilds;
        if (isSelectedChild(id)) {
            if (allowUnselect) {
                result = _.filter(result, function (x) { return x !== id; });
            }
        }
        else if (typeof id === 'undefined') {
            result = [];
        }
        else {
            if (multiSelect) {
                result = __spreadArray(__spreadArray([], result, true), [id], false).filter(function (x) { return x; });
            }
            else {
                result = [id];
            }
        }
        setSelected([]);
        setSelectedChilds(result);
        if (_.isFunction(onChangeChild) && saveOnSelect && !multiSelect && !buttons) {
            onChangeChild(result);
            _core.onHide();
        }
    }
    function handleSelectAll() {
        setSelected([]);
        setSelectedChilds([]);
        if (saveOnSelect && !multiSelect && !buttons) {
            if (_.isFunction(onChange)) {
                onChange([]);
            }
            if (_.isFunction(onChangeChild)) {
                onChangeChild([]);
            }
            _core.onHide();
        }
    }
    function handleClose() {
        if (_.isFunction(onChange) && (!saveOnSelect || multiSelect) && !buttons) {
            onChange(selected);
        }
        if (_.isFunction(onChangeChild) && saveOnSelect && !multiSelect && !buttons) {
            onChangeChild(selectedChilds);
        }
        _core.onHide();
    }
    function handleDone() {
        if (_.isFunction(onChange)) {
            onChange(selected);
        }
        if (_.isFunction(onChangeChild)) {
            onChangeChild(selectedChilds);
        }
        _core.onHide();
    }
    var filteredByQ = useMemo(function () {
        if (!q.length) {
            return items;
        }
        var needle = q
            .toLowerCase()
            .split(' ')
            .map(function (x) { return x.trim(); })
            .filter(function (x) { return x.length; });
        var result = [];
        _.forEach(items, function (item) {
            var tmp = item;
            var full = searchMap
                .reduce(function (acc, current) { return __spreadArray(__spreadArray([], acc, true), [tmp[current]], false); }, [])
                .join(' ')
                .toLowerCase();
            var isCurrent = _.every(needle, function (n) { return full.indexOf(n) !== -1; });
            var isChild = false;
            if (tmp.childs) {
                tmp.childs = tmp.childs.filter(function (child) {
                    // todo: typify it
                    var full = searchMap
                        .reduce(function (acc, current) { return __spreadArray(__spreadArray([], acc, true), [child[current]], false); }, [])
                        .join(' ')
                        .toLowerCase();
                    return _.every(needle, function (n) { return full.indexOf(n) !== -1; });
                });
                if (!tmp.childs.length) {
                    delete tmp['childs'];
                }
                else {
                    isChild = true;
                }
            }
            if (isCurrent || isChild) {
                result.push(tmp);
            }
        });
        return result;
    }, [q, items, searchMap]);
    return (_jsxs(Modal, __assign({ className: styles.selectModal, size: "md", scrollable: true, centered: true, headerBordered: buttons, footerShadow: buttons }, modalOptions, { onHide: handleClose }, { children: [_jsxs("div", __assign({ className: "modal-toolbar" }, { children: [backButton && (_jsx("div", { className: "modal-toolbar-btn modal-toolbar-btn--back", onClick: handleClose })), _jsx("div", { className: "modal-toolbar-btn modal-toolbar-btn--close", onClick: handleClose })] })), title && (_jsx(Modal.Header, __assign({ className: classNames(backButton && 'text-lg-center') }, { children: getContent(title) }))), _jsxs(Modal.Body, __assign({ className: "styled_scroll" }, { children: [searchBar && (_jsx(InputSearch, { className: "mb-20", field: { value: q, onChange: setQ }, placeholder: searchLabel })), _jsxs("div", __assign({ className: styles.selectModalGroup }, { children: [allOption && (_jsx("div", { children: _jsx("div", __assign({ className: classNames(styles.item, selected.length === 0 && styles.itemActive), onClick: handleSelectAll }, { children: allOption })) })), filteredByQ.map(function (item) { return (_jsxs("div", { children: [_jsx("div", __assign({ className: classNames(styles.item, isSelected(item.id, item.childId) && styles.itemActive, hasSelectedChild(item.childs) && styles.itemChildActive), onClick: function () { return handleSelect(item.id, item.childId); } }, { children: item.text })), item.childs &&
                                        item.childs.map(function (child /* todo: typify it */) { return (_jsx("div", __assign({ className: classNames(styles.item, styles.itemChild, isSelected(item.id, item.childId) && styles.itemParentActive, styles.itemActive, isSelectedChild(child.id) && styles.itemActive), onClick: function () { return handleSelectChild(child.id); } }, { children: child.text }), child.id)); })] }, item.id || 'default')); })] }))] })), buttons && (doneText || cancelText) && (_jsx(_Fragment, { children: _jsx(Modal.Footer, { children: _jsxs("div", __assign({ className: "btn-group" }, { children: [doneText && (_jsx(Button, { mode: "primary", className: "btn-sm", action: handleDone, label: getContent(doneText) })), cancelText && (_jsx(Button, { mode: "secondary", className: "btn-sm", action: handleClose, label: getContent(cancelText) }))] })) }) }))] })));
};
export default SelectModal;
