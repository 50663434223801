import { action, makeObservable, observable, runInAction } from 'mobx'

import clientService from '@services/client'

class ChatStore {
  constructor() {
    makeObservable(this, {
      queue: observable,
      errorRequestIds: observable,
      clear: action,
      sendMessage: action,
      sendMessages: action,
      addToQueue: action,
      removeFromQueue: action,
    })
  }

  queue = []
  errorRequestIds = []

  clear() {
    this.queue = []
  }

  // Computed

  // Mutations

  // Actions
  sendMessage(text = '', isUser = true, userId = '', requestId = 0) {
    const msg = {
      text,
      isUser,
      userId,
      requestId,
    }

    this.addToQueue(msg)
  }

  sendMessages(messages) {
    this.queue = [...this.queue, ...messages]
  }

  addToQueue(msg) {
    if (msg.text) {
      this.queue = [...this.queue, msg]
    }
  }

  removeFromQueue() {
    this.queue = this.queue.slice(1)
  }

  addToErrorRequestIds(id) {
    this.errorRequestIds = [...this.errorRequestIds, id]
  }
}

const store = new ChatStore()
export default store
