import React, { useEffect, useState } from 'react'

import { observer } from 'mobx-react'
import { Trans } from 'react-i18next'

import { useIntervalTimer, useLoader } from '@library/CustomHooks'
import PageNoResults from '@library/pageNoResults/PageNoResults'
import ProgressRing from '@library/ui/progressRing/ProgressRing'

import ApptsListPageItem from '@pages/appts/ApptsListPageItem'

import { $appts } from '@store'

import { AUTO_UPDATE_BY_ROUTE } from '@config/apptsCore'

import styles from './ApptsTab.module.scss'

const ApptsTabCurrent = ({ preparedActiveList, fromDate, toDate }) => {
  const [page, setPage] = useState(1)

  const isLoading = useLoader(async () => {
    await $appts.getActive({ fromDate, toDate })
  }, [fromDate, toDate])

  useIntervalTimer({
    callback: () => {
      if (preparedActiveList.length) {
        $appts.getActive({ fromDate, toDate })
      }
    },
    duration: AUTO_UPDATE_BY_ROUTE,
    autoStart: preparedActiveList.length,
    deps: [fromDate, toDate],
  })

  useEffect(() => {
    setPage(1)
  }, [fromDate, toDate])

  return (
    <>
      {preparedActiveList.length > 0 && !isLoading ? (
        <div className={styles.apptsList}>
          {preparedActiveList.map((fullAppt) => (
            <ApptsListPageItem fullAppt={fullAppt} key={fullAppt.appointment.id} />
          ))}
        </div>
      ) : !isLoading && (fromDate || toDate) ? (
        <PageNoResults className={styles.pageNoResult}>
          <Trans i18nKey="appts.empty.filtered" />
        </PageNoResults>
      ) : (
        !isLoading && (
          <PageNoResults>
            <Trans i18nKey="appts.empty.current" components={[<br />]} />
          </PageNoResults>
        )
      )}
      {/*{isLoading && (preparedActiveList.length === 0 || page > 1) && (*/}
      {isLoading && (
        <div className="page-content_loader page-content_loader--bg">
          <ProgressRing progress={25} spin={true} />
        </div>
      )}
    </>
  )
}

export default observer(ApptsTabCurrent)
