import { routeActions as routeCoreActions } from '@core/src/config/routeActions'

import { toPath } from '@helpers/router'

// () => ({to, href, wvAction, onClick})
export const routeActions = {
  ROOT: () => ({
    to: toPath('home'),
  }),
  HOME: () => ({
    to: toPath('home'),
  }),
  BOOK_START: () => ({
    to: toPath('home'),
  }),
  BOOK_ADDRESS: () => ({
    to: toPath('bookAddress'),
  }),
  BOOK_PATIENTS: () => ({
    to: toPath('bookPatients'),
  }),
  BOOK_SYMPTOMS: () => ({
    to: toPath('bookSymptoms'),
  }),
  BOOK_DATE: () => ({
    to: toPath('bookDate'),
  }),
  BOOK_FINISH: () => ({
    to: toPath('bookFinish'),
  }),
  APPTS: () => ({
    to: toPath('appts'),
  }),
  PATIENT_APPTS: ({ patientId }) => ({
    to: toPath('patientAppts', { params: { patientId: patientId } }),
  }),
  APPT: routeCoreActions.APPT,
  APPT_ENCOUNTER: ({ encounterId }) => ({
    to: toPath('apptEncounter', { params: { id: encounterId } }),
  }),
  APPT_EMPTY: () => ({
    to: toPath('apptEmpty'),
  }),
  PROFILE: () => ({
    to: toPath('profile'),
  }),
  PATIENTS: () => ({
    to: toPath('patients'),
  }),
  PATIENT: ({ patientId }) => ({
    to: toPath('patient', { params: { patientId } }),
  }),
  PS_PATIENT: ({ patientId, practiceId, categoryId }, searchParams = {}) => ({
    to: toPath('psPatient', { params: { patientId, practiceId, categoryId }, query: searchParams }),
  }),
  PLACES: () => ({
    to: toPath('places'),
  }),
  PMS: () => ({
    to: toPath('pms'),
  }),
  SIGNOUT: () => ({
    to: toPath('signout'),
  }),
}
