import React from 'react'

import { eventsConfig } from '@ps/config/events'
import { getReferenceId } from '@ps/helpers/fhir'
import { getResourceExtractorValueByPath, resourceExtractor } from '@ps/helpers/resourceExtractor'

const EventsViewer = ({ type, event, resource, extractor, ...passedProps }) => {
  if (!event || !resource) {
    return <span style={{ color: 'red' }}>{getReferenceId(event)}</span>
  }

  const viewerConfig = eventsConfig[event.configId]?.viewer || {}
  const View = viewerConfig[type]

  if (!extractor) {
    extractor = resourceExtractor(event, resource)
  }

  if (_.isObject(View)) {
    return <View event={event} resource={resource} extractor={extractor} {...passedProps} />
  }

  return <>{getResourceExtractorValueByPath(resource, extractor, type)}</>
}

export default EventsViewer
