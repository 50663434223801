export function isBase64(str = '') {
  return _.isString(str) && str.startsWith('data:') && str.indexOf('base64') !== -1
}

export function saveImageToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    const mime = file.type

    reader.onload = function ({ target: { result } }) {
      let str = btoa(result)
      resolve('data:' + mime + ';base64,' + str)
    }

    reader.onerror = reject

    reader.readAsBinaryString(file)
  })
}

export function getImageDimensions(file) {
  return new Promise((resolve, reject) => {
    const img = new Image()
    const objectUrl = URL.createObjectURL(file)

    img.onload = function () {
      const { width, height } = this

      URL.revokeObjectURL(objectUrl)
      resolve({ width, height })
    }
    img.onerror = reject
    img.src = objectUrl
  })
}
