export var ENUMS_TELECOM_PHONE = {
    mobile: {
        id: 'mobile',
        tKey: 'enums.telecom.mobile',
    },
    home: {
        id: 'home',
        tKey: 'enums.telecom.home',
    },
    work: {
        id: 'work',
        tKey: 'enums.telecom.work',
    },
};
