import { makeObservable, runInAction } from 'mobx'

import { initReaction, userStoreCore } from '@core/src/store/userStore'

import clientService from '@services/client'
import userService from '@services/user'

class userStore extends userStoreCore {
  constructor() {
    super()
    makeObservable(this, {})
  }

  async refreshProfile() {
    const profile = await clientService.getPatientProfile()
    runInAction(() => {
      this.profile = profile
    })
  }

  async saveProfile(data) {
    let resPatient = {}

    if (this.profile.id) {
      data = { id: this.profile.id, isHidden: false, ...data }
      resPatient = await clientService.updatePatient(data)
    } else {
      resPatient = await clientService.addPatient(data)
    }

    const resUser = await userService.updateUser({})

    if (resPatient.prepared && resUser.prepared) {
      runInAction(() => {
        this.profile = resPatient.prepared.patient
        this.info = resUser.prepared.user
      })

      return { isSuccess: true }
    } else {
      return { isSuccess: false }
    }
  }
}

const store = new userStore()
initReaction(store)

export default store
