import React, { useEffect, useState } from 'react'

import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'

import { bookingSchema } from '@booking/config/bookingSchema'

import ProgressRing from '@library/ui/progressRing/ProgressRing'

import { customGoTo } from '@helpers/router'

import { $booking, $loader, $patients, $user } from '@store'

import { routeActions } from '@config/routeActions'

import styles from './withBookingDataInit.module.scss'

const withBookingDataInit = (WrappedComponent) => {
  const WithBookingDataInit = ({ routeConfig: _routeConfig, ...props }) => {
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(true)

    // TODO uncomment when url upload will be ready
    // const searchParams = useRouterSearchParams('replace')
    // useEffect(() => {
    //   searchParams.set($booking.searchParams)
    // }, [$booking.searchParams])
    const routeConfig = _routeConfig || history.routeConfig
    const schemaConfig = bookingSchema[routeConfig.name]

    const checkRoute = () => {
      let fulfilledConditions = []

      if ($booking.place) fulfilledConditions.push('place')
      if (_.isNumber($booking.serviceId)) fulfilledConditions.push('service')
      if ($booking.$patients.selectedIds.length) fulfilledConditions.push('patients')
      if (_.isNumber($booking.practiceId)) fulfilledConditions.push('practice')

      if (schemaConfig) {
        if (!schemaConfig.required.every((x) => fulfilledConditions.includes(x))) {
          customGoTo(schemaConfig.initFailAction())
        } else {
          $booking.SET_SCHEMA_CONFIG_KEY(routeConfig.name)
        }
      } else {
        customGoTo(routeActions.BOOK_START())
      }
    }

    useEffect(() => {
      setIsLoading(true)
      if (!$user.user) {
        $booking.clear(true)
      } else if (schemaConfig?.isInitPage) {
        $booking.clear(false)
      }
      const init = async () => {
        const initValues = _.isFunction(schemaConfig?.initValues)
          ? schemaConfig.initValues()
          : undefined
        await $booking.init(initValues)
      }
      init().finally(() => {
        checkRoute()
        setIsLoading(false)
      })
    }, [$user.user, routeConfig.name])

    useEffect(() => {
      const ids = ['filling-card-done']
      const subIds = $loader.subscribe(ids, async () => await $patients.get())
      return () => $loader.unsubscribe(ids, subIds)
    }, [])

    if (isLoading) {
      return (
        <div className="box-body box-body--padding text-center">
          <ProgressRing className={styles.loading} progress={25} spin={true} />
        </div>
      )
    }

    return <WrappedComponent {...props} />
  }

  WithBookingDataInit.displayName = `withBookingDataInit(${
    WrappedComponent.displayName || WrappedComponent.name
  })`

  return observer(WithBookingDataInit)
}

export default withBookingDataInit
