var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import classNames from 'classnames';
import { changeDateMonth } from '@library/newDatePicker/dayPicker/DayPicker.helpers';
var DayPickerSelector = function (_a) {
    var model = _a.model, setModel = _a.setModel, setView = _a.setView, minDate = _a.minDate, maxDate = _a.maxDate;
    var handleChangeView = function (value) {
        return function () { return setView(value); };
    };
    var handleSelectMonth = function (isNextMonth) {
        return function () {
            setModel(changeDateMonth(model, isNextMonth));
        };
    };
    var isCanChange = function (isNextMonth) {
        return !isNextMonth ? model.isAfter(minDate, 'month') : model.isBefore(maxDate, 'month');
    };
    return (_jsx("div", __assign({ className: "day-picker-selector" }, { children: _jsxs("div", __assign({ className: "body" }, { children: [_jsxs(_Fragment, { children: [isCanChange(false) && (_jsx("div", { className: classNames('button', 'prev'), onClick: handleSelectMonth(false) })), !isCanChange(false) && _jsx("div", { className: classNames('button', 'prev', 'disabled') })] }), _jsxs("div", __assign({ className: "current-date" }, { children: [_jsx("span", __assign({ className: "month", onClick: handleChangeView('month') }, { children: model.format('MMMM') })), _jsx("span", __assign({ className: "year", onClick: handleChangeView('year') }, { children: model.format('YYYY') }))] })), _jsxs(_Fragment, { children: [isCanChange(true) && (_jsx("div", { className: classNames('button', 'next'), onClick: handleSelectMonth(true) })), !isCanChange(true) && _jsx("div", { className: classNames('button', 'next', 'disabled') })] })] })) })));
};
export default DayPickerSelector;
