import React from 'react'

import { useTranslation } from 'react-i18next'

import DtlGroup from '@library/ui/dtl/DtlGroup'
import DtlItem from '@library/ui/dtl/DtlItem'

const AppointmentEventDetails = ({ extractor, encounterExtractor }) => {
  const { t } = useTranslation()

  const { ids, address, practice, created, combinedStatus, start, end, participants, reasons } =
    extractor

  const symptoms = _.map(reasons, (x) => _.startCase(x.asString)).join(', ')

  return (
    <DtlGroup className="row pb-30 row--mb-20">
      {/*<div className="title-sm">{t('appt.title.details')}</div>*/}
      <DtlItem className="col-12 col-lg-6" label={t('ps.label.address')}>
        {address}
      </DtlItem>
      <DtlItem className="col-12 col-lg-6" label={t('appt.label.practice')}>
        {practice.name}
      </DtlItem>
      <DtlItem className="col-12 col-lg-6" label={t('appt.label.time_start')}>
        {start}
      </DtlItem>
      <DtlItem className="col-12 col-lg-6" label={t('appt.label.time_end')}>
        {end}
      </DtlItem>
      <DtlItem className="col-12 col-lg-6" label={t('ps.label.encounter_time_start')}>
        {encounterExtractor.start}
      </DtlItem>
      <DtlItem className="col-12 col-lg-6" label={t('ps.label.encounter_time_end')}>
        {encounterExtractor.end}
      </DtlItem>
      {participants.agent && (
        <DtlItem className="col-12 col-lg-6" label={t('appt.label.occ')}>
          {participants.agent.fullName}
        </DtlItem>
      )}
      {participants.provider && (
        <DtlItem className="col-12 col-lg-6" label={t('appt.label.provider')}>
          {participants.provider.fullName}
        </DtlItem>
      )}
      <DtlItem className="col-12 col-lg-6" label={t('appt.label.booked')}>
        {created}
      </DtlItem>
      {symptoms && (
        <DtlItem className="col-12 col-lg-6" label={t('appt.label.symptoms')}>
          {symptoms}
        </DtlItem>
      )}
      {ids.ziphyIdentifier?.value && (
        <DtlItem className="col-12 col-lg-6" label={t('appt.label.appointmentId')}>
          {ids.ziphyIdentifier?.value}
        </DtlItem>
      )}
      {encounterExtractor.ids?.ziphyIdentifier?.value && (
        <DtlItem className="col-12 col-lg-6" label={t('label.encounter_id')}>
          {encounterExtractor.ids.ziphyIdentifier.value}
        </DtlItem>
      )}
      <DtlItem className="col-12 col-lg-6" label={t('appt.label.status')}>
        {combinedStatus}
      </DtlItem>
      {/*<DtlItem className="col-12 col-lg-6" label={t('appt.label.appointmentId')}>*/}
      {/*  {extractor.originalApptId}*/}
      {/*</DtlItem>*/}
    </DtlGroup>
  )
}

export default AppointmentEventDetails
