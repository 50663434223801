import byPracticeConfig from '@config/byPractice';
export var getLinkByPractice = function (configKey, practiceId) {
    var _a;
    var env = process.env.REACT_APP_ENV;
    var config = (_a = byPracticeConfig[configKey]) === null || _a === void 0 ? void 0 : _a[env];
    if (!config) {
        return null;
    }
    for (var _i = 0, _b = Object.entries(config); _i < _b.length; _i++) {
        var _c = _b[_i], practiceIds = _c[0], value = _c[1];
        if (practiceIds === 'any') {
            return value;
        }
        else if (practiceIds.split('_').includes(_.toString(practiceId))) {
            return value;
        }
    }
    return null;
};
