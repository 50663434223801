import { formatDate } from '@helpers/time'

import {
  getActorByReference,
  getAddressByAppointmentId,
  getAddresses,
  getAllergyReaction,
  getAllergyStatus,
  getAllergyType,
  getAppointmentCombinedStatus,
  getAppointmentStatus,
  getAsString,
  getCodeObject,
  getCodingValuesAsString,
  getDiagnosticAccessionNumber,
  getDiagnosticDateOrdered,
  getDiagnosticDateReceived,
  getDiagnosticReportCancelledByPatient,
  getDiagnosticReportCannedComment,
  getDiagnosticReportChartNumber,
  getDiagnosticReportCollectLocation,
  getDiagnosticReportDateCancelled,
  getDiagnosticReportDateCorrected,
  getDiagnosticReportDateReported,
  getDiagnosticReportInReferenceRange,
  getDiagnosticReportIsRapidLabq,
  getDiagnosticReportObservations,
  getDiagnosticReportOrderId,
  getDiagnosticReportOrderingPhysician,
  getDiagnosticReportPerformer,
  getDiagnosticReportRoom,
  getDiagnosticReportStatus,
  getDiagnosticRouteNumber,
  getDiagnosticSpecimenType,
  getDisplayOrText,
  getDocumentValues,
  getEncounterIds,
  getEncounterStatus,
  getExaminationTemplate,
  getExaminationValues,
  getHpiValues,
  getHpiValuesAsString,
  getLabsCanonicalStatus,
  getMediaContent,
  getMediaValues,
  getMedicationRequestDosage,
  getMedicationRequestMedication,
  getMedicationRequestStatus,
  getMedicationStatementDosage,
  getMedicationStatementStatus,
  getObservationAbsentReason,
  getObservationComment,
  getObservationDateCorrected,
  getObservationInReferenceRange,
  getObservationInterpretation,
  getObservationIsRapidLabq,
  getObservationPerformer,
  getObservationReferenceRange,
  getObservationStatus,
  getObservationTestComponentConfig,
  getObservationValues,
  getOriginalAppt,
  getParticipants,
  getPassport,
  getPracticeByAppointment,
  getPreparedIdentifiers,
  getProcedureDate,
  getReasonsByAppointment,
  getServiceRequestType,
  getSubjectGender,
  getTelecom,
  getValues,
  getValuesAsString,
} from '@ps/helpers/resourceExtractor'

export const extractorScheme = (id) => {
  const list = {
    subject: {
      ids: (r) => getPreparedIdentifiers(r.identifier),
      firstName: (r) => r.firstName,
      lastName: (r) => r.lastName,
      fullName: (r) => r.fullName,
      birthDate: (r) => r.birthDate,
      gender: (r) => getSubjectGender(r),
      addresses: (r) => getAddresses(r, true),
      address: (r, ctx) => _.last(ctx.addresses(r))?.asString,
      phones: (r) => getTelecom(r, 'phone'),
      phone: (r, ctx) => _.last(ctx.phones(r))?.value,
      emails: (r) => getTelecom(r, 'email'),
      email: (r, ctx) => _.last(ctx.emails(r))?.value,
      passport: (r) => getPassport(r),
      asString: (r, ctx) => getAsString(r, ctx, []),
    },
    practitioner: {
      ids: (r) => getPreparedIdentifiers(r.identifier),
      firstName: (r) => r.firstName,
      lastName: (r) => r.lastName,
      fullName: (r) => r.fullName,
      birthDate: (r) => r.birthDate,
      gender: (r) => getSubjectGender(r),
      addresses: (r) => getAddresses(r, true),
      address: (r, ctx) => _.last(ctx.addresses(r))?.asString,
      phones: (r) => getTelecom(r, 'phone'),
      phone: (r, ctx) => _.last(ctx.phones(r))?.value,
      emails: (r) => getTelecom(r, 'email'),
      email: (r, ctx) => _.last(ctx.emails(r))?.value,
      asString: (r, ctx) => getAsString(r, ctx, []),
    },
    encounter: {
      ids: (r) => getPreparedIdentifiers(r.identifier),
      start: (r) => r.period?.start && formatDate(r.period.start),
      end: (r) => r.period?.end && formatDate(r.period.end),
      status: (r) => getEncounterStatus(r),
    },
    appointment: {
      deepinclude: [
        '*:encounter > Encounter:serviceProvider',
        '*:encounter > Encounter:location.location',
      ],
      ids: (r) => getPreparedIdentifiers(r.identifier),
      encounterIds: (r) => getEncounterIds(r),
      text: (r) => getDisplayOrText(r.appointmentType),
      type: (r) => getDisplayOrText(r.appointmentType),
      start: (r) => r.start && formatDate(r.start),
      end: (r) => r.end && formatDate(r.end),
      created: (r) => r.created && formatDate(r.created),
      address: (r) => getAddressByAppointmentId(r),
      reasons: (r) => getReasonsByAppointment(r),
      practice: (r) => getPracticeByAppointment(r),
      originalApptId: (r) => getOriginalAppt(r),
      participants: (r) => getParticipants(r),
      status: (r) => getAppointmentStatus(r),
      combinedStatus: (r) => getAppointmentCombinedStatus(r),
      asString: (r, ctx) => getAsString(r, ctx, ['text', 'valuesAsString']),
    },
    observation: {
      date: (r) => r.effectiveDateTime,
      dateCorrected: (r) => getObservationDateCorrected(r),
      codeObj: (r) => getCodeObject(r.code, ['loinc.org']),
      text: (r) => getDisplayOrText(r.code),
      values: (r) => getObservationValues(r),
      valuesAsString: (r, ctx) => getValuesAsString(r, ctx),
      status: (r) => getObservationStatus(r),
      canonicalStatus: (r) => getLabsCanonicalStatus(r),
      interpretation: (r) => getObservationInterpretation(r),
      referenceRange: (r) => getObservationReferenceRange(r),
      inReferenceRange: (r) => getObservationInReferenceRange(r),
      subject: (r) => getActorByReference(r.subject),
      performer: (r) => getObservationPerformer(r),
      comment: (r) => getObservationComment(r),
      absentReason: (r) => getObservationAbsentReason(r),
      asString: (r, ctx) => getAsString(r, ctx, ['text', 'valuesAsString']),
      isRapidLabq: (r) => getObservationIsRapidLabq(r),
      componentConfig: (r) => getObservationTestComponentConfig(r),
    },
    condition: {
      codeObj: (r) => getCodeObject(r.code),
      text: (r) => getDisplayOrText(r.code),
      values: (r) => getValues(r.note),
      valuesAsString: (r, ctx) => getValuesAsString(r, ctx),
      subject: (r) => getActorByReference(r.subject),
      asserter: (r) => getActorByReference(r.asserter),
      dateEntered: (r) => r.recordedDate,
      dateUpdated: (r) => r.meta.lastUpdated,
      clinicalStatus: (r) => getDisplayOrText(r.clinicalStatus),
      verificationStatus: (r) => getDisplayOrText(r.verificationStatus),
      asString: (r, ctx) =>
        getAsString(
          r,
          ctx,
          ['text', 'valuesAsString', 'clinicalStatus', 'verificationStatus'],
          ' • ',
        ),
    },
    allergy: {
      codeObj: (r) => getCodeObject(r.code),
      type: (r) => getAllergyType(r),
      text: (r) => getDisplayOrText(r.code),
      values: (r) => getAllergyReaction(r),
      valuesAsString: (r, ctx) => getCodingValuesAsString(r, ctx),
      note: (r) => getValues(r.note),
      noteAsString: (r, ctx) => getValuesAsString(r, ctx, 'note'),
      patient: (r) => getActorByReference(r.patient),
      asserter: (r) => getActorByReference(r.asserter),
      recorder: (r) => getActorByReference(r.recorder),
      status: (r) => getAllergyStatus(r),
      verificationStatus: (r) => getDisplayOrText(r.verificationStatus),
      asString: (r, ctx) => getAsString(r, ctx, ['text', 'status.display']),
    },
    assessment: {
      codeObj: (r) => getCodeObject(r.code),
      values: (r) => getValues(r.note),
      valuesAsString: (r, ctx) => getValuesAsString(r, ctx),
      subject: (r) => getActorByReference(r.subject),
      asserter: (r) => getActorByReference(r.asserter),
      asString: (r, ctx) => getAsString(r, ctx, ['text', 'valuesAsString']),
    },
    immunization: {
      codeObj: (r) => getCodeObject(r.vaccineCode),
      text: (r) => getDisplayOrText(r.vaccineCode),
      subject: (r) => getActorByReference(r.patient),
      occurrenceDateTime: (r) => r.occurrenceDateTime,
      reportOrigin: (r) => getDisplayOrText(r.reportOrigin),
      asString: (r, ctx) => getAsString(r, ctx, ['text']),
    },
    medication_statement: {
      codeObj: (r) =>
        getCodeObject(r.medicationCodeableConcept)
          ? getCodeObject(r.medicationCodeableConcept)
          : getCodeObject(getMedicationRequestMedication(r)?.code),
      text: (r, ctx) => ctx.codeObj(r, ctx)?.display,
      // text: (r) => getDisplayOrText(r.medicationCodeableConcept),
      values: (r) => getValues(r.dosage),
      valuesAsString: (r, ctx) => getValuesAsString(r, ctx),
      dosageValues: (r) => getMedicationStatementDosage(r),
      dosageValuesAsString: (r, ctx) => getValuesAsString(r, ctx, 'dosageValues'),
      route: (r) => getCodeObject(_.get(r, 'dosage[0].route')),
      site: (r) => getCodeObject(_.get(r, 'dosage[0].site')),
      routeAsString: (r, ctx) => getAsString(r, ctx, ['route.display', 'site.display']),
      frequency: (r) => getCodeObject(_.get(r, 'dosage[0].timing.code')),
      frequencyAsString: (r, ctx) => getAsString(r, ctx, ['frequency.display', 'take.display']),
      status: (r) => getMedicationStatementStatus(r),
      dateEntered: (r) => r.dateAsserted,
      dateUpdated: (r) => r.meta.lastUpdated,
      subject: (r) => getActorByReference(r.subject),
      informationSource: (r) => getActorByReference(r.informationSource),
      asString: (r, ctx) =>
        getAsString(
          r,
          ctx,
          ['text', 'dosageValuesAsString', 'routeAsString', 'frequencyAsString'],
          ' • ',
        ),
    },
    medication_request: {
      // form: (r) => getCodeObject(getMedicationRequestMedication(r)?.form),
      codeObj: (r) => getCodeObject(getMedicationRequestMedication(r)?.code),
      text: (r, ctx) => ctx.codeObj(r, ctx)?.display,
      dosageValues: (r) => getMedicationRequestDosage(r),
      dosageValuesAsString: (r, ctx) => getValuesAsString(r, ctx, 'dosageValues'),
      take: (r) => getCodeObject(_.get(r, 'dosageInstruction[0]')),
      route: (r) => getCodeObject(_.get(r, 'dosageInstruction[0].route')),
      site: (r) => getCodeObject(_.get(r, 'dosageInstruction[0].site')),
      routeAsString: (r, ctx) => getAsString(r, ctx, ['route.display', 'site.display']),
      frequency: (r) => getCodeObject(_.get(r, 'dosageInstruction[0].timing.code')),
      frequencyAsString: (r, ctx) => getAsString(r, ctx, ['frequency.display', 'take.display']),
      subject: (r) => getActorByReference(r.subject),
      recorder: (r) => getActorByReference(r.recorder),
      dateEntered: (r) => r.authoredOn,
      // effectivePeriodDateStart: (r) => getMedicationRequestEffectivePeriod(r, 'start'),
      // effectivePeriodDateEnd: (r) => getMedicationRequestEffectivePeriod(r, 'end'),
      status: (r) => getMedicationRequestStatus(r),
      asString: (r, ctx) =>
        getAsString(
          r,
          ctx,
          ['text', 'dosageValuesAsString', 'routeAsString', 'frequencyAsString'],
          ', ',
        ),
    },
    service_request: {
      codeObj: (r) => getCodeObject(r.code),
      type: (r) => getServiceRequestType(r),
      text: (r) => getDisplayOrText(r.code),
      performerTypeCodeObj: (r) => getCodeObject(r.performerType),
      reasonCodeObj: (r) => getCodeObject(r.reasonCode?.[0]),
      reasonText: (r) => getDisplayOrText(r.reasonCode?.[0]),
      subject: (r) => getActorByReference(r.subject),
      requester: (r) => getActorByReference(r.requester),
      asString: (r, ctx) =>
        getAsString(
          r,
          ctx,
          r?.code?.text === 'Referral to health worker' // temporary solution
            ? ['codeObj.display', 'performerTypeCodeObj.display']
            : ['type.display', 'text'],
        ),
    },
    careplan: {
      // todo add addresses, view conditions
      codeObj: (r) => getCodeObject(r.code),
      text: (r) => r.description,
      values: (r) => getValues(r.note),
      valuesAsString: (r, ctx) => getValuesAsString(r, ctx),
      subject: (r) => getActorByReference(r.subject),
      author: (r) => getActorByReference(r.author),
      asString: (r, ctx) => getAsString(r, ctx, ['text', 'valuesAsString']),
    },
    examination: {
      codeObj: (r) => getCodeObject(r.code),
      isLegacy: (r) => r.code?.text === 'examination',
      template: (r) => getExaminationTemplate(r),
      values: (r, ctx) => getExaminationValues(r, ctx),
      valuesAsString: (r, ctx) => getValuesAsString(r, ctx),
      subject: (r) => getActorByReference(r.subject),
      performer: (r) => getActorByReference(_.get(r, 'performer[0]')),
      asString: (r, ctx) => getAsString(r, ctx, ['template.display']),
    },
    hpi: {
      codeObj: (r) => getCodeObject(r.code),
      text: (r) => getDisplayOrText(r.code),
      values: (r) => getHpiValues(r),
      valuesAsString: (r, ctx) => getHpiValuesAsString(r, ctx),
      subject: (r) => getActorByReference(r.subject),
      performer: (r) => getActorByReference(_.get(r, 'performer[0]')),
      asString: (r, ctx) => getAsString(r, ctx, ['codeObj.display', 'valuesAsString']),
    },
    procedure: {
      codeObj: (r) => getCodeObject(r.code),
      text: (r) => getDisplayOrText(r.code),
      values: (r) => getValues(r.note),
      valuesAsString: (r, ctx) => getValuesAsString(r, ctx),
      subject: (r) => getActorByReference(r.subject),
      asserter: (r) => getActorByReference(r.asserter),
      recorder: (r) => getActorByReference(r.recorder),
      date: (r) => getProcedureDate(r),
      asString: (r, ctx) => getAsString(r, ctx, ['text', 'valuesAsString']),
    },
    media: {
      codeObj: (r) => getCodeObject(r.modality),
      text: (r) => getDisplayOrText(r.modality) || getDisplayOrText(r.note),
      values: (r) => getMediaValues(r),
      valuesAsString: (r, ctx) => getValuesAsString(r, ctx),
      subject: (r) => getActorByReference(r.subject),
      operator: (r) => getActorByReference(r.operator),
      content: (r) => getMediaContent(r),
      deviceName: (r) => r.deviceName,
      asString: (r, ctx) => getAsString(r, ctx, ['text', 'valuesAsString']),
    },
    document: {
      category: (r) => getDisplayOrText(r.category),
      type: (r) => getDisplayOrText(r.type),
      text: (r) => r.description,
      values: (r) => getDocumentValues(r),
      valuesAsString: (r, ctx) => getValuesAsString(r, ctx),
      subject: (r) => getActorByReference(r.subject),
      content: (r) => getMediaContent(r),
      asString: (r, ctx) => getAsString(r, ctx, ['text']),
    },
    diagnostic_report: {
      orderId: (r) => getDiagnosticReportOrderId(r),
      dateOrdered: (r) => getDiagnosticDateOrdered(r),
      dateReported: (r) => getDiagnosticReportDateReported(r),
      dateReceived: (r) => getDiagnosticDateReceived(r),
      dateCorrected: (r) => getDiagnosticReportDateCorrected(r),
      dateCancelled: (r) => getDiagnosticReportDateCancelled(r),
      isCancelledByPatient: (r) => getDiagnosticReportCancelledByPatient(r),
      accessionNumber: (r) => getDiagnosticAccessionNumber(r),
      dateCollected: (r) => r.effectiveDateTime,
      issued: (r) => r.issued,
      codeObj: (r) => getCodeObject(r.code),
      text: (r) => getDisplayOrText(r.code),
      conclusion: (r) => r.conclusion,
      status: (r) => getDiagnosticReportStatus(r),
      canonicalStatus: (r) => getLabsCanonicalStatus(r),
      inReferenceRange: (r) => getDiagnosticReportInReferenceRange(r),
      subject: (r) => getActorByReference(r.subject),
      performer: (r) => getDiagnosticReportPerformer(r),
      collectLocation: (r) => getDiagnosticReportCollectLocation(r),
      orderingPhysician: (r) => getDiagnosticReportOrderingPhysician(r),
      observations: (r) => getDiagnosticReportObservations(r),
      cannedComment: (r) => getDiagnosticReportCannedComment(r),
      specimenType: (r) => getDiagnosticSpecimenType(r),
      chartNumber: (r) => getDiagnosticReportChartNumber(r),
      room: (r) => getDiagnosticReportRoom(r),
      routeNumber: (r) => getDiagnosticRouteNumber(r),
      isRapidLabq: (r) => getDiagnosticReportIsRapidLabq(r),
      asString: (r, ctx) => getAsString(r, ctx, ['text']),
    },
    organization: {
      ids: (r) => getPreparedIdentifiers(r.identifier),
      extensions: (r) => getPreparedIdentifiers(r.extension),
      fullName: (r) => r.fullName,
      addresses: (r) => getAddresses(r, true),
      address: (r, ctx) => _.last(ctx.addresses(r))?.asString,
      phones: (r) => getTelecom(r, 'phone'),
      phone: (r, ctx) => _.last(ctx.phones(r))?.value,
      emails: (r) => getTelecom(r, 'email'),
      email: (r, ctx) => _.last(ctx.emails(r))?.value,
      asString: (r, ctx) => getAsString(r, ctx, []),
    },
  }

  return list[id]
}
