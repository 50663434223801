/*
  simple: {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {...}, // optional
  },
  simpleQueue: {
    from: { opacity: 0 },
    enter: [{ opacity: 1 }, {...}],
    leave: [{ opacity: 0 }, {...}],
    config: {...}, // optional
  },
  simpleFn: {
    from: ({ size }) => ({ opacity: 0 }),
    enter: ({ item, size }) => ({ opacity: 1 }),
    leave: ({ item, size }) => ({ opacity: 0 }),
  },
  asyncFn: {
    from: ({ size }) => ({ opacity: 0 }),
    enter: async ({ item, next, cancel, size }) => await next({ opacity: 1 }),
    leave: async ({ item, next, cancel, size }) => await next({ opacity: 0 }),
  },
 */

const presets = {
  /*
   * BASIC PRESETS
   */
  fade: {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  },
  fadeInDown50: {
    from: { opacity: 0, transform: 'translateY(-50px)' },
    enter: { opacity: 1, transform: 'translateY(0px)' },
    leave: { opacity: 0, transform: 'translateY(-50px)' },
  },
  inLeft: {
    from: { transform: 'translateX(100%)' },
    enter: { transform: 'translateX(0%)' },
    leave: { transform: 'translateX(100%)' },
  },
  fadeInTop50: {
    from: { opacity: 0, transform: 'translateY(50px)' },
    enter: { opacity: 1, transform: 'translateY(0%)' },
    leave: { opacity: 0, transform: 'translateY(50px)' },
  },
  /*
   * INLINE PRESETS
   */
  progressScreen: {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  },
  modal: {
    from: ({ size }) => (size.isMobile ? 'inLeft.from' : 'fadeInDown50.from'),
    enter: ({ size }) => (size.isMobile ? 'inLeft.enter' : 'fadeInDown50.enter'),
    leave: ({ size }) => (size.isMobile ? 'inLeft.leave' : 'fadeInDown50.leave'),
  },
  modalInTop: {
    from: ({ size }) => (size.isMobile ? 'fadeInTop50.from' : 'fadeInDown50.from'),
    enter: ({ size }) => (size.isMobile ? 'fadeInTop50.enter' : 'fadeInDown50.enter'),
    leave: ({ size }) => (size.isMobile ? 'fadeInTop50.leave' : 'fadeInDown50.leave'),
  },
  caution: {
    from: { opacity: 0, transform: 'translate(-50%, 100%)' },
    enter: { opacity: 1, transform: 'translate(-50%, 0%)' },
    leave: { opacity: 0, transform: 'translate(-50%, 100%)' },
  },
  popupNotices: {
    from: { opacity: 0, transform: 'translateX(100%)' },
    enter: { opacity: 1, transform: 'translateX(0%)' },
    leave: { opacity: 0, transform: 'translateX(0%)' },
  },
  alerts: {
    from: { opacity: 0, marginTop: 0, transform: 'translateY(-100%)' },
    enter: { opacity: 1, marginTop: 10, transform: 'translateY(0%)' },
    leave: { opacity: 0, marginTop: 10, transform: 'translateY(0%)' },
  },
  chatMessages: {
    from: { height: 0 },
    enter: { height: 'auto' },
    leave: { height: 0 },
    config: { duration: 150 },
  },
}

export default presets
