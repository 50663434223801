import React from 'react'

import { observer } from 'mobx-react'

import NearestAppt from '@library/nearestAppt/NearestAppt'

import { $user } from '@store'

const DefaultExpander = ({ children }) => {
  return (
    <>
      {children}
      {$user.user && <NearestAppt />}
    </>
  )
}

export default observer(DefaultExpander)
