import apiService from '@services/api'

class settings {
  async getAll() {
    return await apiService.fetchLoggedV3('settings.read')
  }
}

const settingsService = new settings()
export default settingsService
