import { action, makeObservable, observable, runInAction } from 'mobx'

import { analytics } from '@common/analytics/zipAnalytics'

import { getUuid } from '@helpers/other'

class ModalStore {
  constructor() {
    makeObservable(this, {
      current: observable,
      items: observable,
      add: action,
      setNextCurrent: action,
      setFooterShadow: action,
      getCurrentByTemplateName: action,
      getCurrentById: action,
      show: action,
      callCallbacks: action,
      dropAll: action,
      hide: action,
      forceHide: action,
      block: action,
      unblock: action,
      remove: action,
    })
  }

  current = []
  items = []

  // Computed

  // Mutations

  // Actions
  add(template, modalProps = {}) {
    const {
      multi = true,
      uniq = true,
      data = {}, // only for actionConfirmator
      animationOnShow = true,
      isBlocked = false,
      canDrop = true,
      onEnter,
      onEntered,
      beforeLeaveAlert,
      executeBeforeLeave = true, // if beforeLeave should be conditional, pass false and set it by condition from component
      onLeave,
      onLeaved,
      onCancel,
      onDone,
      ...props
    } = modalProps

    let id = getUuid()
    let passedProps = {}

    _.forEach(_.cloneDeep(props), (value, key) => {
      passedProps[key] = _.isFunction(value) ? action(value) : value
    })

    const templateName = template.type?.displayName || template.displayName || template.name

    if (uniq && this.getCurrentByTemplateName(templateName)) {
      return false
    }
    analytics.track('modal-add', { modal: templateName })

    let modalParams = {
      _core: {
        id,
        templateName,
        footerShadow: false,
        template,
        show: false,
        canDrop,
        multi,
        animationOnShow,
        isBlocked,
        executeBeforeLeave,
        setExecuteBeforeLeave: action((value) => this.setExecuteBeforeLeave(id, value)),
        onShow: action(() => this.show(id)),
        onHide: action((status = null, data) => this.hide(id, status, data)),
        onBlock: action(() => this.block(id)),
        onUnblock: action(() => this.unblock(id)),
        onEnter: onEnter ? action(() => onEnter()) : undefined,
        beforeLeaveAlert: beforeLeaveAlert ? action((e) => beforeLeaveAlert(e)) : undefined,
        onEntered: onEntered ? action(() => onEntered()) : undefined,
        onLeave: onLeave ? action(() => onLeave()) : undefined,
        onLeaved: onLeaved ? action(() => onLeaved()) : undefined,
        onDone: onDone ? action(onDone) : () => {},
        onCancel: onCancel ? action(onCancel) : () => {},
        forceHide: action(() => this.forceHide(id)),
      },
      ...(!_.isEmpty(data) && { data }),
      ...passedProps,
    }

    if (multi) {
      this.items = [modalParams, ...this.items]
    } else {
      this.items = [...this.items, modalParams]
    }

    this.setNextCurrent()

    return modalParams
  }

  setExecuteBeforeLeave(id, value) {
    const current = this.getCurrentById(id)
    if (current) {
      current._core.executeBeforeLeave = value
    }
  }

  setNextCurrent() {
    if (this.items.length && (_.isEmpty(this.current) || this.items[0]._core.multi)) {
      this.current = [...this.current, this.items.splice(0, 1)[0]]
    }
  }

  setFooterShadow(id, value) {
    let current = this.getCurrentById(id)
    current._core.footerShadow = value
  }

  getCurrentByTemplateName(name) {
    return _.find(this.current, (x) => x._core.templateName === name)
  }

  getCurrentById(id) {
    return _.find(this.current, (x) => x._core.id === id)
  }

  updateParams(id, updatedProps) {
    const modalIndex = _.findIndex(this.current, (x) => x._core.id === id)
    if (modalIndex > -1) {
      runInAction(() => {
        this.current[modalIndex] = { ...this.current[modalIndex], ...updatedProps }
      })
    }
  }

  show(id) {
    let current = this.getCurrentById(id)

    if (current) {
      current._core.show = true
      analytics.track('modal-show', { modal: current._core.templateName })
    }
  }

  callCallbacks(current, status, data) {
    analytics.track('modal-event', {
      modal: current._core.templateName,
      status: status ? 'done' : 'cancel',
    })

    if (status === true) {
      current._core.onDone(data)
    } else if (status === false) {
      current._core.onCancel(data)
    }
  }

  dropAll() {
    this.items = []
    _.forEach(this.current, (x) => {
      if (x._core.canDrop) {
        x._core.show = false
      }
    })
  }

  async hide(id, status, data) {
    let current = this.getCurrentById(id)
    if (current?._core?.beforeLeaveAlert && current?._core?.executeBeforeLeave) {
      await current._core.beforeLeaveAlert(() => {
        if (current && !current._core.isBlocked) {
          current._core.show = false
          this.callCallbacks(current, status, data)
        }
      })
    } else {
      if (current && !current._core.isBlocked) {
        current._core.show = false
        this.callCallbacks(current, status, data)
      }
    }
  }

  forceHide(id) {
    let current = this.getCurrentById(id)

    if (current) {
      this.remove(id)
    }
  }

  block(id) {
    let current = this.getCurrentById(id)

    if (current) {
      current._core.isBlocked = true
    }
  }

  unblock(id) {
    let current = this.getCurrentById(id)

    if (current) {
      current._core.isBlocked = false
    }
  }

  remove(id) {
    if (this.current.length) {
      this.current = this.current.filter((x) => x._core.id !== id)
      this.setNextCurrent()
    }
  }
}

const store = new ModalStore()
export default store
