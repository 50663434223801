import React, { useMemo, useRef } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useResizeDetector } from 'react-resize-detector'

import classNames from 'classnames'

import Toolbar from '@library/toolbar/Toolbar'
import Breadcrumbs from '@library/ui/breadcrumbs/Breadcrumbs'
import ProgressRing from '@library/ui/progressRing/ProgressRing'

import { getUserText } from '@helpers/user'

import { $appts, $booking, $main } from '@store'

import styles from './BookingLayout.module.scss'

const BookingLayout = ({
  children,
  button,
  breadcrumbs = [],
  title,
  isMobileFooterFixed,
  isDesktopFooterFixed,
  isLoading,
  className,
}) => {
  const { t } = useTranslation()
  const footerRef = useRef()

  const { height } = useResizeDetector({
    targetRef: footerRef,
    handleWidth: false,
  })

  const breadcrumbsValues = useMemo(() => {
    return breadcrumbs.map((item) => {
      switch (item) {
        case 'place':
          return $booking.place && $booking.placeExtended.buildingStreetCity()
        case 'service':
          return $booking.service && $booking.service.name
        case 'patients':
          return $booking.$patients.items.length > 1
            ? t('book.patients', { count: $booking.$patients.items.length })
            : getUserText($booking.$patients.items[0], ['fullName', 'gender', 'age'])
      }
    })
  }, [])

  if (isLoading) {
    return (
      <div className="box-body box-body--padding text-center">
        <ProgressRing className={'mt-100'} progress={25} spin={true} />
      </div>
    )
  }

  const prevStep = $booking.schemaConfig?.prevStep && $booking.schemaConfig?.prevStep()

  return (
    <div className={classNames('content', className)}>
      <Toolbar goBackProps={prevStep ? { autoTarget: prevStep } : null} />
      <div className={'container'}>
        <div
          style={{
            paddingBottom: height ? height + ($main.isMobileSize ? 20 : 40) : undefined,
          }}
          className={classNames(
            styles.body,
            styles.maxWidth760,
            'box',
            'box--full-height',
            isDesktopFooterFixed && 'box--desktop-footer-fixed',
            isMobileFooterFixed && 'box--mobile-footer-fixed',
          )}
        >
          {breadcrumbs.length > 0 && <Breadcrumbs values={breadcrumbsValues} />}
          <div className="box-header">
            <div className="box-header-title title-lg mb-0">{title}</div>
          </div>
          <div className="box-body box-body--padding">{children}</div>
          {button && (
            <div
              ref={footerRef}
              className={classNames(styles.footer, 'box-footer')}
              style={{
                paddingBottom: $appts.nearestBarHeight
                  ? $appts.nearestBarHeight + ($main.isMobileSize ? 15 : 30)
                  : undefined,
              }}
            >
              {isDesktopFooterFixed || (isMobileFooterFixed && $main.isMobileSize) ? (
                <div className={'container'}>
                  <div className={styles.maxWidth760}>{button}</div>
                </div>
              ) : (
                button
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
export default observer(BookingLayout)
