import React from 'react'

import { observer } from 'mobx-react'

import { $appts } from '@store'

const DefaultPageWrapper = ({ children }) => {
  const style = {
    marginBottom: $appts.nearestBarIsVisible && $appts.nearestBarHeight,
  }

  return (
    <div className="content-app-wrapper" style={style}>
      {children}
    </div>
  )
}

export default observer(DefaultPageWrapper)
