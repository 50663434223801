var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import styles from './PageNoResults.module.scss';
var PageNoResults = function (_a) {
    var children = _a.children, className = _a.className, bordered = _a.bordered, _b = _a.size, size = _b === void 0 ? 'lg' : _b;
    var t = useTranslation().t;
    if (children)
        return (_jsx("div", __assign({ className: classNames(styles.no_results, styles[size], bordered && styles.bordered, className) }, { children: children ? children : t('errors.no_results') })));
};
export default PageNoResults;
