import i18n from 'i18next'

import CustomAlertModal from '@library/modals/CustomAlert.modal'
import RestrictedModal from '@library/modals/Restricted.modal'

import LoginPhoneModal from '@pages/auth/LoginPhone.modal'

import { $user } from '@store'

// {
//   modalTemplate: ComponentModal,
//   enabled: true,
//   data: {
//     title: '',
//     message: '',
//     done: '',
//     cancel: '',
//   },
//   defaultCallback: () => fn(),
//   skip: () => fn(),
// }

let confirmActions = {
  checkAuth: {
    modalTemplate: LoginPhoneModal,
    enabled: true,
    skip: () => $user.user,
  },
  restrictedArea: {
    modalTemplate: RestrictedModal,
    enabled: true,
    skip: false,
  },
  customAlert: {
    modalTemplate: CustomAlertModal,
    enabled: true,
    data: {
      done: () => i18n.t('btn.confirm'),
      cancel: () => i18n.t('btn.cancel'),
    },
  },
}

export default confirmActions
