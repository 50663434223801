import React from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import ShowMoreText from '@library/showMoreText/ShowMoreText'

import { openEventModal } from '@ps/helpers/events'
import { resourceExtractor } from '@ps/helpers/resourceExtractor'
import EventsViewer from '@ps/library/eventsViewer/EventsViewer'

import { $psHistory } from '@store'

const LabsTablePage = ({ pageItems, showRelatedAppt }) => {
  const { t } = useTranslation()

  return (
    <>
      {pageItems.map(({ event, resource }) => {
        const extractor = resourceExtractor(event, resource)
        let label = _.isString(extractor.type) ? extractor.type : t(event.label)

        function handleClick() {
          openEventModal([{ event, resource }], { showRelatedAppt })
        }

        return (
          <div
            key={event.id}
            className={classNames('h_table-tr', 'h_table-tr--' + event.rowType)}
            onClick={handleClick}
          >
            <div className="h_table-td h_table-td--delimiter" />
            <div className="h_table-td h_table-td--label">{label}</div>
            <div className="h_table-td h_table-td--details">
              <div className="font-weight-600">
                <ShowMoreText
                  expanded={$psHistory.showMore.includes('more')}
                  showLessButton={$psHistory.showMore.includes('less')}
                  buttonClassName="mt-5"
                >
                  <EventsViewer
                    type="tableDetails"
                    event={event}
                    resource={resource}
                    extractor={extractor}
                    hasIcons={['document']}
                  />
                </ShowMoreText>
              </div>
            </div>
            <div className="h_table-td h_table-td--status">
              <EventsViewer
                type="tableStatus"
                event={event}
                resource={resource}
                extractor={extractor}
              />
            </div>
            <div className="h_table-td h_table-td--date">
              <EventsViewer
                type="tableDate"
                event={event}
                resource={resource}
                extractor={extractor}
              />
            </div>
          </div>
        )
      })}
    </>
  )
}

export default observer(LabsTablePage)
