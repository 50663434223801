import React from 'react'

import { useTranslation } from 'react-i18next'

import { TOS_PROVIDER } from '@library/plugins/termsOfService/Tos.config'

import mainConfig from '@config/main'

export const TosHeader = ({ type }) => {
  const { t } = useTranslation()

  return (
    <div className="modal-header-title title-md">
      {type === TOS_PROVIDER
        ? t('tos_provider_modal.title', { serviceCompanyAbbr: mainConfig.serviceCompanyName })
        : t('tos_modal.title', { serviceCompanyAbbr: mainConfig.serviceCompanyName })}
    </div>
  )
}
