import { $booking, $main } from '@store'

import { routeActions } from '@config/routeActions'

export const bookingSchema = {
  bookServiceType: {
    isInitPage: true,
    required: [],
    initValues: () => {
      let result = {
        patientIds: $main.initExtraData?.patientId ? [$main.initExtraData?.patientId] : [],
        predefinedServiceIds: $main.initExtraData?.serviceIds,
      }

      return result
    },
    initFailAction: () => null,
    prevStep: () => routeActions.ROOT(),
    nextStep: () => routeActions.BOOK_ADDRESS(),
  },
  bookAddress: {
    required: ['service'],
    initFailAction: () => routeActions.BOOK_START(),
    prevStep: () => routeActions.BOOK_START(),
    nextStep: () =>
      $booking.$patients.isAllPatientsValid
        ? routeActions.BOOK_SYMPTOMS()
        : routeActions.BOOK_PATIENTS(),
  },
  bookPatients: {
    required: ['place', 'service'],
    initFailAction: () => routeActions.BOOK_START(),
    prevStep: () => routeActions.BOOK_ADDRESS(),
    nextStep: () => routeActions.BOOK_SYMPTOMS(),
  },
  bookSymptoms: {
    required: ['place', 'service', 'patients'],
    initFailAction: () => routeActions.BOOK_START(),
    prevStep: () =>
      $booking.$patients.hasUpdatedValues
        ? routeActions.BOOK_PATIENTS()
        : routeActions.BOOK_ADDRESS(),
    nextStep: () => routeActions.BOOK_DATE(),
  },
  bookDate: {
    required: ['place', 'service', 'patients'],
    initFailAction: () => routeActions.BOOK_START(),
    prevStep: () => routeActions.BOOK_SYMPTOMS(),
    nextStep: () => routeActions.BOOK_FINISH(),
  },
  bookFinish: {
    required: ['place', 'service', 'patients', 'practice'],
    initFailAction: () => routeActions.BOOK_START(),
    prevStep: () => routeActions.BOOK_DATE(),
    nextStep: ({ appointmentId }) => routeActions.APPT({ appointmentId }),
  },
}
