var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import classNames from 'classnames';
import CustomLink from '@library/ui/customLink/CustomLink';
import styles from './SidebarMenuItem.module.scss';
var SidebarMenuItem = function (_a) {
    var autoTarget = _a.autoTarget, onClick = _a.onClick, className = _a.className, children = _a.children, params = _a.params, icon = _a.icon, counter = _a.counter;
    return (_jsxs(CustomLink, __assign({ autoTarget: autoTarget, type: "nav", onClick: onClick, className: classNames(styles.item, className), params: params }, { children: [icon && _jsx("img", { className: styles.icon, src: icon, alt: "" }), _jsx("span", __assign({ className: styles.text }, { children: children })), counter > 0 && _jsx("span", __assign({ className: styles.counter }, { children: counter }))] })));
};
export default SidebarMenuItem;
