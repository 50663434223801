import React from 'react'

import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'

import ApptDocuments from '@pages/appt/apptDocuments/ApptDocuments'
import ApptNotes from '@pages/appt/apptNotes/ApptNotes'
import ApptSymptoms from '@pages/appt/ApptSymptoms'
import ApptPatient from '@pages/appt/patient/ApptPatient'

import { customGoTo } from '@helpers/router'

import { $appts } from '@store'

import { routeActions } from '@config/routeActions'

const EncounterView = () => {
  const { encounterId } = useParams()
  const { fullAppt } = $appts.byRoute
  const { appointment, encounters, patients } = fullAppt

  if (!encounterId) {
    customGoTo(routeActions.APPT({ appointmentId: appointment.id }), { type: 'replace' })
  }

  const activeEncounter = encounters?.[encounterId]
  const activePatient = patients?.[activeEncounter?.patientId]

  return (
    <>
      {activePatient && (
        <>
          <ApptPatient />
          <ApptSymptoms byRoute={$appts.byRoute} />
          <ApptDocuments activePatient={activePatient} />
          <ApptNotes byRoute={$appts.byRoute} className="max_width--lg" />
        </>
      )}
    </>
  )
}

export default observer(EncounterView)
