var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';
import moment from 'moment-timezone';
import PageNoResults from '@library/pageNoResults/PageNoResults';
import RadioTabs from '@library/radioTabs/RadioTabs';
import Title from '@library/ui/title/Title';
import { $main, $notices } from '@store';
import { ReactComponent as CloseIcon } from '@assets/images/ui/close.svg';
import { ReactComponent as HideIcon } from '@assets/images/ui/hide.svg';
import styles from './Notifications.module.scss';
import NotificationsButton from './NotificationsButton';
var Notifications = function () {
    var t = useTranslation().t;
    var _a = useState('unresolved'), type = _a[0], setType = _a[1];
    var preparedList = useMemo(function () {
        var result = $notices.allItems;
        if (type === 'unresolved') {
            result = $notices.actualItems;
        }
        result = result.map(function (item) {
            var date = moment(item.created).fromNow();
            return __assign(__assign({}, item), { date: date });
        });
        return result;
    }, [$notices.allItems, $notices.actualItems, type]); // eslint-disable-line react-hooks/exhaustive-deps
    var ifFunction = function (value) { return (_.isFunction(value) ? value() : value); };
    return (_jsx("div", __assign({ className: classNames(styles.container) }, { children: _jsxs("div", __assign({ className: styles.box }, { children: [_jsxs("div", __assign({ className: styles.boxHeader }, { children: [_jsx(HideIcon, { className: styles.hideIcon, onClick: function () { return $main.hideRightSidebar(); } }), _jsx(Title, __assign({ size: 'h2' }, { children: t('notices.title') })), _jsx(CloseIcon, { className: styles.closeIcon, onClick: function () { return $main.removeRightSidebar('notifications'); } })] })), _jsxs("div", __assign({ className: classNames(styles.boxBody, 'styled_scroll') }, { children: [_jsx(RadioTabs, { value: type, setValue: setType, mode: 'dark', items: [
                                { id: 'unresolved', text: t('notices.status.unresolved') },
                                { id: 'all', text: t('notices.status.all') },
                            ], className: styles.tabs }), _jsx("div", __assign({ className: styles.notices }, { children: preparedList.length ? (_jsx(_Fragment, { children: preparedList.map(function (notice) { return (_jsxs("div", __assign({ className: classNames(styles.noticesItem, notice.viewed && styles.noticesItemViewed) }, { children: [_jsx("div", __assign({ className: styles.noticesItemTitle }, { children: ifFunction(notice.title) })), _jsx("div", { children: ifFunction(notice.body) }), _jsx("div", __assign({ className: classNames(styles.noticesItemButtons, 'btn-group', (!notice.actions || !notice.actions.length) && 'mt-0') }, { children: _jsxs(_Fragment, { children: [!notice.viewed &&
                                                        notice.actions &&
                                                        notice.actions.length > 0 &&
                                                        notice.actions.map(function (btn, index) { return (_jsx(NotificationsButton, { notice: notice, btn: btn }, index)); }), _jsx("div", __assign({ className: styles.noticesItemDate }, { children: notice.date }))] }) }))] }), notice.id)); }) })) : (
                            // @ts-ignore
                            _jsx(PageNoResults, { children: _jsx(Trans, { i18nKey: "notices.empty.current", components: [_jsx("br", {})] }) })) }))] }))] })) })));
};
export default observer(Notifications);
