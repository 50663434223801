import React, { useEffect, useRef } from 'react'

import { observer } from 'mobx-react'

import { TOS_GENERAL, TOS_PROVIDER } from '@library/plugins/termsOfService/Tos.config'
import TosModal from '@library/plugins/termsOfService/Tos.modal'

import { useRouterSearchParams } from '@helpers/router'

import { $modal } from '@store'

import mainConfig from '@config/main'

const TosLauncher = () => {
  const searchParams = useRouterSearchParams()
  const modal = useRef(null)

  useEffect(() => {
    function showModal(type) {
      const modalItem = $modal.add(TosModal, {
        type,
        canDrop: false,
        onLeave() {
          searchParams.remove(type)
        },
      })

      if (!modal.current) {
        modal.current = modalItem
      }
    }

    if (_.has(searchParams.getAll(), TOS_GENERAL)) {
      showModal(TOS_GENERAL)
    } else if (mainConfig.custom.tosProviderEnabled && _.has(searchParams.getAll(), TOS_PROVIDER)) {
      showModal(TOS_PROVIDER)
    } else if (modal.current) {
      modal.current._core.onHide()
      modal.current = null
    }
  }, [searchParams])

  return <></>
}

export default observer(TosLauncher)
