import React, { useEffect, useMemo } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import { useLoader } from '@library/CustomHooks'
import BubbleLoader from '@library/ui/bubbleLoader/BubbleLoader'

import SelectPaymentMethodModal from '@pages/paymentMethods/SelectMethod.modal'

import { $modal, $pms } from '@store'

import rightArrowSvg from '@assets/images/ui/next.svg'

import styles from './PaymentSelector.module.scss'

const PaymentSelector = ({ selected, onChangeSelected }) => {
  const { t } = useTranslation()

  const isLoading = useLoader(async () => {
    const lastPaymentMethod = await $pms.getLast()
    if (lastPaymentMethod.isSuccess && !lastPaymentMethod.method.isExpired) {
      onChangeSelected(lastPaymentMethod.method.id)
    }
  })

  useEffect(() => {
    if (!isLoading && _.isEmpty($pms.getPaymentById(selected))) {
      onChangeSelected(null)
    }
  }, [isLoading, $pms.items.length])

  const paymentMethod = useMemo(
    () => (_.isNumber(selected) ? $pms.getPaymentById(selected) : null),
    [selected],
  )

  const openModal = () => {
    $modal.add(SelectPaymentMethodModal, {
      selected: selected,
      onSelect: onChangeSelected,
    })
  }

  return (
    <div className={styles.container} onClick={openModal}>
      <div className={styles.label}>{t('book_details.label.payment_method')}</div>
      <div className={styles.cardNumber}>
        {isLoading ? (
          <div className={styles.bubbleLoader}>
            <BubbleLoader />
          </div>
        ) : paymentMethod ? (
          <>
            <div
              className={classNames(
                styles.cardIcon,
                'pms_icon',
                'pms_icon--' + _.kebabCase(paymentMethod.brand.toLowerCase()),
              )}
            />
            *{paymentMethod.last4}
          </>
        ) : (
          t('label.none')
        )}
      </div>
      <img src={rightArrowSvg} className={styles.rightArrow} />
    </div>
  )
}

export default observer(PaymentSelector)
