import React from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import MultiSelect from '@library/selectDropdown/MultiSelect'

const AddInsuranceSelect = ({ form, fieldKey, fieldProps, tKey }) => {
  const { t } = useTranslation()

  return (
    <MultiSelect
      className="mb-10"
      label={t(`insurance.label.${tKey}`)}
      items={fieldProps.items.map((x) => ({ id: x.id, text: t(x.text) }))}
      field={{
        ...form.fields[fieldKey],
        onChange: (values) => {
          form.fields[fieldKey].onChange(values[0])
          if (_.isFunction(fieldProps.onChange)) {
            fieldProps.onChange(values[0], form)
          }
        },
      }}
      placeholder={t(`insurance.ph.${tKey}`)}
      mode="lg"
    />
  )
}

export default observer(AddInsuranceSelect)
