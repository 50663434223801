import moment from 'moment-timezone'

import coreClient from '@core/src/services/client'

import apiService from '@services/api'
import { prepareApptListByMap } from '@services/client.helpers'

import { apptStatuses } from '@config/apptsCore'

class client extends coreClient {
  //
  // Appointments LIST
  //
  async getAppointments({
    patientId,
    practiceId,
    type,
    fromDate,
    toDate,
    expand,
    getEvents = true,
    skipAlert = false,
    limit,
    offset,
  }) {
    if (!expand) {
      expand = {
        encounter: [
          'patientId',
          'patientSymptomsId',
          // 'patientPostVisitNoteInternalId',
          //
          // 'telehealthConsentId',
          // 'hipaaConsentId',
          // 'patientIntakeId',
        ],
        appointment: [
          'serviceId',
          // 'practiceServiceId',
          'placeId',
          'officeId',
          'practiceId',
          'encounterIds',
          //
          'voucherId',
          'paymentId',
          'paymentMethodId',
          //
          'patientId',
          'patientSymptomsId',
          //
          'agentRoleId',
          // 'agentTimeslotId',
          // 'equipmentId',
          //
          'providerRoleId',
          // 'providerTimeslotId',
          'patientPostVisitNoteId',
        ],
      }
    }

    let method = 'appointments.list'

    let patientFilter = []
    let statusFilter = []
    let idFilter = []

    let fromDateFilter = []
    let toDateFilter = []
    let fixToDateFilter = []

    let order

    if (patientId) {
      patientFilter = [{ eq: ['patient_id', Number(patientId)] }]
    }

    if (type === 'active') {
      statusFilter = [{ in: ['status', apptStatuses.active] }]
      order = [
        ['appointed_start', 'asc'],
        ['id', 'asc'],
      ]
    } else if (type === 'past') {
      statusFilter = [{ in: ['status', apptStatuses.past] }]
      order = [
        ['appointed_start', 'desc'],
        ['id', 'desc'],
      ]
    }

    if (fromDate) {
      if (_.isString(fromDate)) {
        fromDate = moment(fromDate)
      }
      fromDate = fromDate.utc().format('YYYY-MM-DDTHH:mm:ss')

      fromDateFilter = [{ gte: ['appointed_start', `$datetime:${fromDate}`] }]
    }

    if (toDate) {
      if (_.isString(toDate)) {
        toDate = moment(toDate)
      }
      toDate = toDate.utc().format('YYYY-MM-DDTHH:mm:ss')

      toDateFilter = [{ lte: ['appointed_start', `$datetime:${toDate}`] }]
    }

    let response = await apiService.fetchLoggedV3(
      method,
      {
        expand,
        filter: {
          and: [
            ...patientFilter,
            ...statusFilter,
            ...idFilter,
            ...fromDateFilter,
            ...toDateFilter,
          ].filter((x) => x),
        },
        order,
        limit,
        offset,
      },
      { skipAlert },
    )

    if (!response) {
      response = {}
    }

    const appointments = _.get(response, 'prepared.appointments.items', [])

    if (appointments && appointments.length) {
      if (getEvents) {
        const events = await this.getApptEvents({
          ids: appointments.map((x) => x.id),
        })
        if (events && events.length) {
          response.prepared.events = events
        }
      }
      response.prepared = prepareApptListByMap(response.prepared, appointments)
    } else {
      response.prepared = []
    }

    return response
  }

  async getOneAppointment({ id, skipAlert, expand }) {
    let method = 'appointments.read'

    if (!expand) {
      expand = {
        encounter: [
          'patientId',
          'patientSymptomsId',
          // 'patientPostVisitNoteId',
          // 'patientPostVisitNoteInternalId',
          //
          // 'telehealthConsentId',
          // 'hipaaConsentId',
          // 'patientIntakeId',
        ],
        appointment: [
          'serviceId',
          // 'practiceServiceId',
          'placeId',
          'officeId',
          'practiceId',
          'encounterIds',
          //
          'voucherId',
          'paymentId',
          // 'paymentMethodId',
          //
          'patientId',
          'patientSymptomsId',
          //
          'agentRoleId',
          'agentTimeslotId',
          // 'equipmentId',
          //
          'providerRoleId',
          'providerTimeslotId',
        ],
      }
    }

    const response = await apiService.fetchLoggedV3(
      method,
      {
        id,
        expand,
      },
      { skipAlert },
    )

    const appointmentId = _.get(response, 'prepared.appointment.id')

    // todo maybe need check !expand for events
    if (appointmentId) {
      const events = await this.getApptEvents({ ids: [appointmentId] })
      if (events && events.length) {
        response.prepared.events = events
      }
      response.prepared = _.first(
        prepareApptListByMap(response.prepared, [response.prepared.appointment]),
      )
    }

    return response
  }
}

const service = new client()
export default service
