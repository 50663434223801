import { autorun, configure } from 'mobx'

import _ from 'lodash'
import 'moment-timezone'

import { MixpanelZipAnalyticsBrowser } from '@common/analytics/mixpanel/mixpanelZipAnalyticsBrowser'
import { initRaygun, setRG } from '@common/analytics/raygun'
import { analytics, ZipAnalytics } from '@common/analytics/zipAnalytics'

import { initI18N } from '@helpers/i18n'
import { history } from '@helpers/router'

import mainConfig from '@config/main'

export const initScript = (store) => {
  autorun((reaction) => {
    if (!store.translationsReady) {
      initI18N()
      return
    }

    if (window.location.hostname !== 'localhost') {
      const analyticKeys = mainConfig?.analytic

      if (analyticKeys?.raygunApiKey) {
        // todo: make a raygun module for analytics.registerModule
        initRaygun({ apiKey: analyticKeys.raygunApiKey })
      }
      if (analyticKeys?.mixpanelApiKey) {
        analytics.registerModule(
          ZipAnalytics.MIXPANEL_KEY,
          new MixpanelZipAnalyticsBrowser(analyticKeys?.mixpanelApiKey, {
            ignore_dnt: true,
            ip: false,
          }),
        )
      }
    }

    configure({
      enforceActions: 'always',
    })

    document.documentElement.classList.add(mainConfig.appType)

    history.listen((location) => {
      setRG('trackEvent', {
        type: 'pageView',
        path: location.pathname + location.hash,
      })
      analytics.nav(location.pathname + location.hash)
    })

    store.setAppIsReady(true)
  })
}
