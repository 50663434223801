import React, { useState } from 'react'

import { TransitionWrapper } from '@library/animations/Animations'

import styles from './CautionNotice.module.scss'

const CautionNotice = (props) => {
  const { show, title, message, onChange, removeOnLeaved = true } = props

  const [isShow, setIsShow] = useState(show)
  const [isRemoved, setIsRemoved] = useState(false)

  const closeHandle = () => {
    setIsShow(false)

    if (_.isFunction(onChange)) {
      onChange(false)
    }
  }

  const onLeavedHandle = () => {
    if (removeOnLeaved) {
      setIsRemoved(true)
    }
  }

  if (isRemoved) {
    return <></>
  }

  return (
    <TransitionWrapper preset="caution" items={isShow} initial={false} onLeaved={onLeavedHandle}>
      {(item) =>
        item &&
        ((style) => (
          <div className={styles.caution_notice} style={style}>
            <div className={styles.caution_noticeInner}>
              {title && <div className={styles.caution_noticeTitle}>{title}</div>}
              {message && <div>{message}</div>}
              <div className={styles.caution_noticeClose} onClick={closeHandle} />
            </div>
          </div>
        ))
      }
    </TransitionWrapper>
  )
}

export default CautionNotice
