import React, { useMemo } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import _ from 'lodash'
import queryString from 'query-string'

import clientService from '@services/client'

import Modal from '@library/modal/Modal'
import InfoBox from '@library/ui/infoBox/InfoBox'

import { $alerts, $loader, $user } from '@store'

import { apptDocumentsFormList } from '@config/apptDocumentsFormsList'
import mainConfig from '@config/main'

const FillOutFormsModal = ({ practiceId, encounterId, _core }) => {
  const env = process.env.REACT_APP_ENV
  const { t } = useTranslation()

  const preparedList = useMemo(() => {
    let result = []

    function getOpenBlank(obj, config) {
      obj = _.cloneDeep(obj)

      obj.tag = 'a'
      obj.to = config.link
      obj.onClick = async (e) => {
        e.preventDefault()
        window.open(config.link, '_blank')
        _core.onHide()
      }

      return obj
    }

    function getGetSurvey(obj, config, buttonType) {
      obj = _.cloneDeep(obj)

      if (env === 'dev' && buttonType) {
        obj.id = `${obj.id}[${buttonType}]`
        obj.label = `${obj.label} [${buttonType}]`
      }

      obj.tag = 'div'
      obj.onClick = async (e) => {
        e.preventDefault()

        const response = await clientService.getDocumentsSurvey({
          documentTemplateId: config.documentTemplateId,
          encounterId,
        })

        if (response.error) {
          return $alerts.add(response.error.message)
        }

        const queryParams = queryString.stringify({
          ...response.prepared,
          backUrl: window.location.href,
        })

        let resultUrl

        if (env === 'dev' && ['pdfHtml', 'pdfRender'].includes(buttonType)) {
          let targetUrl =
            'http://localhost:3235' +
            _.replace(config.link, '/forms/#/', '/forms-pdf/#/care_at_home/')
          resultUrl = [targetUrl, queryParams].join('?')

          if (buttonType === 'pdfHtml') {
          } else if (buttonType === 'pdfRender') {
            resultUrl = [`http://localhost:3315?url=${encodeURIComponent(resultUrl)}`]
          }
        } else {
          const targetUrl = mainConfig.web['formsUrl'] + config.link
          resultUrl = [targetUrl, queryParams].join('?')
        }

        window.open(resultUrl, '_blank')
        _core.onHide()
      }

      return obj
    }

    _.forEach(apptDocumentsFormList, (item) => {
      const groupByEnv = item[env]
      if (!groupByEnv) return

      let targetFormsList = {}

      Object.entries(groupByEnv).forEach(([ids, value]) => {
        ids.split('_').forEach((id) => {
          targetFormsList[id] = value
        })
      })

      let config = targetFormsList[practiceId]
      if (!config) {
        if (targetFormsList.any) {
          config = targetFormsList.any
        } else if (
          _.includes(
            $user.practice?.documentTemplateIds,
            targetFormsList.byPracticeConfig?.documentTemplateId,
          )
        ) {
          config = targetFormsList.byPracticeConfig
        } else {
          return
        }
      }

      let tmp = { id: item.id, label: t(item.label) }

      if (config.type === 'openBlank') {
        tmp = getOpenBlank(tmp, config)
      } else if (config.type === 'getSurvey') {
        tmp = getGetSurvey(tmp, config)
      } else {
        return
      }
      tmp.onClick = $loader.registerHandler('fill-out-forms-' + item.id, tmp.onClick)

      result.push(tmp)

      if (env === 'dev' && config.type === 'getSurvey') {
        result.push(getGetSurvey(tmp, config, 'pdfHtml'))
        result.push(getGetSurvey(tmp, config, 'pdfRender'))
      }
    })

    return _.orderBy(result, 'label', 'asc')
  }, [practiceId, encounterId, t, $user.practice]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal centered scrollable>
      <div className="modal-toolbar">
        <div className="modal-toolbar-btn modal-toolbar-btn--close" onClick={_core.onHide} />
      </div>
      <Modal.Header>{t('label.fill_out_forms')}</Modal.Header>
      <Modal.Body className="styled_scroll">
        <div>
          {preparedList.map((item) => (
            <InfoBox
              key={item.id}
              tag={item.tag}
              to={item.to}
              onClick={item.onClick}
              className="mb-10"
              mode={['lg', 'gray', 'icon']}
              clickable
              isLoading={$loader.isRunHandler('fill-out-forms-' + item.id)}
            >
              {item.label}
            </InfoBox>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default observer(FillOutFormsModal)
