import React, { useEffect } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { insuranceFields, insuranceTypes } from '@library/addInsuranceModal/AddInsurance.config'
import AddInsuranceInput from '@library/addInsuranceModal/fields/AddInsuranceInput'
import AddInsuranceSelect from '@library/addInsuranceModal/fields/AddInsuranceSelect'
import AddInsuranceTypeaheadProvider from '@library/addInsuranceModal/fields/AddInsuranceTypeaheadProvider'
import { useForm } from '@library/form/FormControllerNew'
import Modal from '@library/modal/Modal'
import Button from '@library/ui/button/Button'

const AddInsuranceModal = ({ initValues = {}, _core }) => {
  const { t } = useTranslation()

  const { form } = useForm()

  useEffect(() => {
    _.forEach(insuranceFields, (fieldProps, fieldName) => {
      form.addField(fieldName, {
        value: initValues[fieldName] || fieldProps.defaultValue,
        rules: fieldProps.rules,
      })
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = (e) => {
    e.preventDefault()
    const formIsValid = form.validateAll().isValid

    if (form.isChanged && formIsValid) {
      let result = form.values

      const insuranceTypeConfig = _.find(insuranceTypes, (x) => x.id === result.insuranceType)
      if (insuranceTypeConfig?.exportKeys) {
        result = _.mapValues(result, (x, key) => {
          return insuranceTypeConfig.exportKeys.includes(key) ? x : ''
        })
      }

      _core.onDone(result)
      _core.onHide()
    }
  }

  return (
    <Modal centered scrollable className="add-insurance-modal">
      <div className="modal-toolbar">
        <div className="modal-toolbar-btn modal-toolbar-btn--close" onClick={_core.onHide} />
      </div>
      <Modal.Header>{t('insurance.title')}</Modal.Header>
      <Modal.Body>
        {!_.isEmpty(form.fields) && (
          <form id="add-insurance-form" className="mb--10">
            {Object.entries(insuranceFields).map(([fieldKey, fieldProps]) => {
              const tKey = _.isFunction(fieldProps.key) ? fieldProps.key(form) : fieldProps.key

              let isVisible = _.isFunction(fieldProps.isVisible)
                ? fieldProps.isVisible(form, fieldKey)
                : fieldProps.isVisible
              if (!isVisible) return null

              if (fieldProps.fieldType === 'input') {
                return (
                  <AddInsuranceInput
                    key={fieldKey}
                    form={form}
                    fieldKey={fieldKey}
                    fieldProps={fieldProps}
                    tKey={tKey}
                  />
                )
              } else if (fieldProps.fieldType === 'select') {
                return (
                  <AddInsuranceSelect
                    key={fieldKey}
                    form={form}
                    fieldKey={fieldKey}
                    fieldProps={fieldProps}
                    tKey={tKey}
                  />
                )
              } else if (fieldProps.fieldType === 'selectProvider') {
                return (
                  <AddInsuranceTypeaheadProvider
                    key={fieldKey}
                    form={form}
                    fieldKey={fieldKey}
                    fieldProps={fieldProps}
                    tKey={tKey}
                  />
                )
              }

              return null
            })}
          </form>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button mode="primary" className="mb-10 btn-block" action={handleSubmit}>
          {t('btn.add_insurance')}
        </Button>
        <Button mode="secondary" className="btn-block" action={_core.onHide}>
          {t('btn.cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default observer(AddInsuranceModal)
