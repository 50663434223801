import React from 'react'

import { useTranslation } from 'react-i18next'

import { formatDate } from '@helpers/time'

import RoleName from '@ps/library/RoleName'

const CarePlan = ({ event, resource, extractor }) => {
  const { t } = useTranslation()
  const { text, valuesAsString, subject = {}, author = {} } = extractor

  return (
    <div className="event_details">
      <div className="event_details-header">
        <div className="event_details-text text-pre-line">
          <div className="font-weight-700">{text}</div>
          {valuesAsString && valuesAsString.length > 0 && (
            <div className="mt-10">
              <span>{t('ps.event_viewer.note:')}</span> {valuesAsString}
            </div>
          )}
        </div>
      </div>
      <div className="event_details-body">
        <div className="event_details-line">
          {t('ps.event_viewer.subject:')} {subject.value?.fullName}
        </div>
        <div className="event_details-line">
          {t('ps.event_viewer.author')} {author.value?.fullName}{' '}
          <RoleName role={author.role} resource={author.value} />
        </div>
        <div className="event_details-line event_details-line--date">
          {formatDate(event.effectiveDate)}
        </div>
      </div>
    </div>
  )
}

export default CarePlan
