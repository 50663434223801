import React from 'react'

import { observer } from 'mobx-react'

import classNames from 'classnames'

import MediaTypes from '@library/media/mediaTypes/MediaTypes'
import MediaViewerModal from '@library/media/MediaViewer.modal'

import { formatDate } from '@helpers/time'

import { $modal } from '@store'

import styles from './MediaPreview.module.scss'

const MediaPreview = ({ items = [], width, height, wrapperClassName }) => {
  function handleClick(index) {
    $modal.add(MediaViewerModal, { selectedIndex: index, items })
  }

  return (
    <div className={classNames(styles.wrapper, wrapperClassName)} style={{ width, height }}>
      {items.map((content, index) => (
        <div key={content.id} className={styles.item} onClick={() => handleClick(index)}>
          <MediaTypes content={content} collection="preview" />
          {1 === 2 && (
            <div className={styles.details}>
              <div className={styles.title}>{content.title}</div>
              <div className={styles.meta}>{formatDate(content.date)}</div>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default observer(MediaPreview)
