import React from 'react'

const Labs = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(7.376 5.143)" fill="#FFF" fillRule="evenodd">
      <rect x="3.934" width="13.379" height="2.571" rx="1" />
      <path
        d="M15.923 0H5.324v12.056l-5.962 8.947A3.857 3.857 0 0 0 2.572 27h16.104c.762 0 1.506-.225 2.14-.648l.191-.136a3.857 3.857 0 0 0 .879-5.213l-5.965-8.946L15.923 0zM7.896 2.571h5.455v10.267l6.395 9.592a1.286 1.286 0 0 1-1.07 1.999H2.571a1.286 1.286 0 0 1-1.07-2l6.395-9.593V2.571z"
        fillRule="nonzero"
      />
      <path fillRule="nonzero" d="M14.481 6.429V9H6.767V6.429z" />
    </g>
  </svg>
)

export default Labs
