import { outputPresets } from '@library/form/FormController.helpers'
import { getPhone } from '@library/phoneNumber/PhoneNumber'

import { convertKeysToCamel, isJson } from '@helpers/other'
import { isoToObject, objectToIso } from '@helpers/time'

export function prepareRequestData(method, data) {
  return _.cloneDeepWith(data, (item, key) => {
    if (_.isString(item) && item.split('|')[1] === 'strict') {
      return item.split('|')[0]
    }
    if (_.isString(item) && item.split('|')[1] === 'toNumber') {
      return parseFloat(item.split('|')[0])
    }
    if (key === 'schedule') {
      return { value: item, length: item.length * 4, _type: 'bitstring' }
    } else if (key === 'dob') {
      return isoToObject('date', item)
    } else if (key === 'email' && item === '') {
      return null
    } else if (_.isString(item) && item.indexOf('$datetime:') !== -1) {
      const value = item.slice('$datetime:'.length)
      return isoToObject('datetime', value)
    } else if (_.isString(item) && item.indexOf('$date:') !== -1) {
      const value = item.slice('$date:'.length)
      return isoToObject('date', value)
    } else if (key === 'phone') {
      if (item === '') {
        return null
      }
      if (_.isObject(item)) {
        return getPhone(item)
      }
    } else if (key === 'coords' && !_.isEmpty(item)) {
      return [item.lat, item.lng]
    } else if (key === 'avatar') {
      return outputPresets.avatar(item) || null
    } else if (key === 'sort') {
      return isJson(item) ? JSON.parse(item) : item
    } else if (key === 'meeting_id') {
      return item
    } else if (
      _.isString(key) &&
      (key === 'id' || key.slice(-2) === 'Id' || key.slice(-3) === '_id') &&
      _.isString(item)
    ) {
      const int = parseInt(item)
      return int.toString() === item ? int : item
    }
  })
}

export function prepareResponseData(data) {
  let expanded = data.expanded

  if (expanded) {
    delete data.expanded

    expanded = _.mapValues(expanded, (item) => {
      if (item._type === 'mapping') {
        item = item.items.map((x) => x.value)
      }
      return item
    })

    data = { ...data, ...expanded }
  }

  return convertKeysToCamel(
    _.cloneDeepWith(data, (item, key) => {
      const type = _.isObject(item) && item._type

      if (_.isString(item) && item.indexOf('$datetime:') === 0) {
        return item.slice('$datetime:'.length) + 'Z'
      } else if (type === 'datetime') {
        return objectToIso(type, item)
      } else if (_.isString(item) && item.indexOf('$date:') === 0) {
        return item.slice('$date:'.length)
      } else if (type === 'date') {
        return objectToIso(type, item)
      } else if (type === 'bitstring') {
        return item.value
      } else if (_.isString(item) && item.indexOf('$bitstring:') === 0) {
        return item.slice('$bitstring:'.length)
      } else if (key === 'coords') {
        if (_.isEmpty(item)) {
          return {}
        }
        return { lat: item[0], lng: item[1] }
      } else if (type === 'decimal') {
        return +item.value
      }
    }),
  )
}

export function parseJwt(token) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(''),
  )

  return JSON.parse(jsonPayload)
}
