var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { action, makeAutoObservable, runInAction } from 'mobx';
import { getUuid } from '@helpers/other';
var LoaderStore = /** @class */ (function () {
    function LoaderStore() {
        this.activeRequests = 0;
        this.registry = {};
        this.subscribers = {};
        makeAutoObservable(this);
    }
    Object.defineProperty(LoaderStore.prototype, "isLoading", {
        get: function () {
            return !!this.activeRequests;
        },
        enumerable: false,
        configurable: true
    });
    LoaderStore.prototype.increaseRequests = function () {
        this.activeRequests++;
    };
    LoaderStore.prototype.decreaseRequests = function () {
        var _this = this;
        if (this.activeRequests > 0) {
            setTimeout(function () { return runInAction(function () { return _this.activeRequests--; }); }, 150);
        }
    };
    LoaderStore.prototype.registerHandler = function (id, callback) {
        var _this = this;
        return function () {
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            return __awaiter(_this, void 0, Promise, function () {
                var _a, beforeStart, beforeFinish, afterFinish, result;
                var _this = this;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            _a = this.getSubscribesByHandlerId(id), beforeStart = _a.beforeStart, beforeFinish = _a.beforeFinish, afterFinish = _a.afterFinish;
                            runInAction(function () { return (_this.registry[id] = { isLoading: true }); });
                            if (!beforeStart.length) return [3 /*break*/, 2];
                            return [4 /*yield*/, Promise.all(beforeStart.map(function (x) { return x.callback(); }))];
                        case 1:
                            _b.sent();
                            _b.label = 2;
                        case 2: return [4 /*yield*/, callback.apply(void 0, args)];
                        case 3:
                            result = _b.sent();
                            if (!beforeFinish.length) return [3 /*break*/, 5];
                            return [4 /*yield*/, Promise.all(beforeFinish.map(function (x) { return x.callback(); }))];
                        case 4:
                            _b.sent();
                            _b.label = 5;
                        case 5:
                            runInAction(function () { return (_this.registry[id] = { isLoading: false }); });
                            if (afterFinish.length) {
                                afterFinish.forEach(function (x) { return x.callback(); });
                            }
                            return [2 /*return*/, result];
                    }
                });
            });
        };
    };
    LoaderStore.prototype.isRunHandler = function (id, useGlobal) {
        var _a;
        if (useGlobal === void 0) { useGlobal = false; }
        var flag = !!((_a = this.registry[id]) === null || _a === void 0 ? void 0 : _a.isLoading);
        return useGlobal ? flag && this.isLoading : flag;
    };
    LoaderStore.prototype.subscribe = function (handler, callback, type) {
        var _this = this;
        if (type === void 0) { type = 'beforeFinish'; }
        var handlers = _.isArray(handler) ? handler : [handler];
        var ids = [];
        handlers.forEach(function (x) {
            var item = { id: getUuid(), callback: action(callback), type: type };
            ids.push(item.id);
            if (_this.subscribers[x]) {
                _this.subscribers[x].push(item);
            }
            else {
                _this.subscribers[x] = [item];
            }
        });
        return _.isArray(handler) ? ids : ids[0];
    };
    LoaderStore.prototype.unsubscribe = function (handler, subscribeId) {
        var _this = this;
        var handlers = _.isArray(handler) ? handler : [handler];
        var ids = _.isArray(subscribeId) ? subscribeId : [subscribeId].filter(function (x) { return x; });
        handlers.forEach(function (x) {
            if (_this.subscribers[x]) {
                if (ids.length) {
                    var filtered = _this.subscribers[x].filter(function (x) { return !ids.includes(x.id); });
                    if (filtered.length) {
                        _this.subscribers[x] = filtered;
                    }
                    else {
                        delete _this.subscribers[x];
                    }
                }
                else {
                    delete _this.subscribers[x];
                }
            }
        });
    };
    LoaderStore.prototype.getSubscribesByHandlerId = function (handlerId) {
        var result = {
            beforeStart: [],
            beforeFinish: [],
            afterFinish: [],
        };
        if (this.subscribers[handlerId]) {
            result.beforeStart = this.subscribers[handlerId].filter(function (x) { return x.type === 'beforeStart'; });
            result.beforeFinish = this.subscribers[handlerId].filter(function (x) { return x.type === 'beforeFinish'; });
            result.afterFinish = this.subscribers[handlerId].filter(function (x) { return x.type === 'afterFinish'; });
        }
        return result;
    };
    return LoaderStore;
}());
var store = new LoaderStore();
export default store;
