import MedicationDetails from '@ps/library/eventsViewer/details/Medication'
import MedicationRequestDetails from '@ps/library/eventsViewer/details/MedicationRequestDetails'
import AsString from '@ps/library/eventsViewer/tableDetails/AsString'
import HistoryEventModal from '@ps/library/modals/HistoryEvent.modal'

export const medicationsConfig = {
  medication_statement: {
    extractor: 'medication_statement',
    eventModal: HistoryEventModal,
    condition: {
      and: [{ field: 'resource', eq: 'MedicationStatement' }],
      or: [
        { field: 'subtype', eq: ['medication'] },
        { field: 'subtype', eq: ['medication', 'intake'] },
      ],
    },
    rowType: 'medications',
    viewer: {
      tableDetails: AsString,
      details: MedicationDetails,
    },
  },
  medication_request: {
    extractor: 'medication_request',
    eventModal: HistoryEventModal,
    condition: [
      { field: 'resource', eq: 'MedicationRequest' },
      { field: 'subtype', eq: ['medication'] },
    ],
    rowType: 'medications',
    viewer: {
      tableDetails: AsString,
      details: MedicationRequestDetails,
    },
  },
}
