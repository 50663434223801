import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import apiService from '@services/api'
import clientService from '@services/client'

import { useLoader } from '@library/CustomHooks'
import Modal from '@library/modal/Modal'
import SelectPredefinedAddressesMap from '@library/selectPredefinedAddressesMap/SelectPredefinedAddressesMap'

import { $booking } from '@store'

import styles from './SelectOfficeAddress.modal.module.scss'

const code = 'QSURE'

const SelectOfficeAddressModal = ({ _core }) => {
  const { t } = useTranslation()
  const [offices, setOffices] = useState([])
  const [isCreatingPlace, setIsCreatingPlace] = useState(false)

  const isLoading = useLoader(async () => {
    const response = await apiService.fetchLoggedV3('offices.list', {
      expand: { office: ['practiceId'] },
      filter: {
        and: [{ eq: ['is_active', true] }, { imatch: ['code', '^' + _.escapeRegExp(code) + '$'] }],
      },
    })
    if (response.prepared?.offices.items.length > 0) {
      setOffices(response.prepared.offices.items)
    }
  })

  const handleSelectAddress = async (office) => {
    setIsCreatingPlace(true)
    const placeParams = _.pick(office, [
      'coords',
      'zip',
      'state',
      'country',
      'street',
      'city',
      'building',
      'apartment',
      'floor',
      'directions',
      'geocoderId',
    ])
    const response = await clientService.addPlace({ ...placeParams, isHidden: true })
    if (response && response.prepared) {
      const newPlace = response.prepared.place
      $booking.$places.SET_ITEMS([newPlace])
      $booking.SET_PLACE_ID(newPlace.id)
      $booking.SET_OFFICE_ID(office.id)
      _core.onHide()
    }
    setIsCreatingPlace(false)
  }

  return (
    <Modal size="lg" centered>
      <div className="modal-toolbar">
        <div className="modal-toolbar-btn modal-toolbar-btn--close" onClick={_core.onHide} />
      </div>
      <Modal.Header>{t('add_address.title_office')}</Modal.Header>
      <Modal.Body className={styles.body} isLoading={isLoading}>
        <div className={styles.bodyMap}>
          <SelectPredefinedAddressesMap
            items={offices}
            onSelect={handleSelectAddress}
            isLoadingButton={isCreatingPlace}
            buttonLabel={t('add_address.title_office')}
          />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default SelectOfficeAddressModal
