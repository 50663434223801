import React from 'react'

import classNames from 'classnames'

import { formatDate } from '@helpers/time'

import styles from './Types.module.scss'

const DocumentPdf = ({ date }) => {
  return (
    <div className={classNames(styles.document, styles.isDefault, date && styles.withDate)}>
      {date && (
        <div className={styles.documentDate}>{formatDate(date, { format: 'datetime' })}</div>
      )}
    </div>
  )
}

export default DocumentPdf
