import React from 'react'

import { toJS } from 'mobx'
import { useTranslation } from 'react-i18next'

import { formatDate } from '@helpers/time'

import ViewCode from '@ps/library/viewCode/ViewCode'

const Immunization = ({ event, resource, extractor }) => {
  const { t } = useTranslation()
  const { asString, codeObj, subject = {}, reportOrigin = {} } = extractor

  return (
    <div className="event_details">
      <div className="event_details-header">
        <div className="event_details-title">
          {asString}
          {event.isSelfReported && (
            <span className="badge-self_reported">{t('ps.label.self_reported')}</span>
          )}
        </div>
        <div className="event_details-code">
          <ViewCode codeObj={codeObj} />
        </div>
      </div>
      <div className="event_details-body">
        <div className="event_details-line">
          {t('ps.event_viewer.subject:')} {subject.value.fullName}
        </div>
        {/*<div className="event_details-line">*/}
        {/*  {t('ps.event_viewer.information_source')} {reportOrigin}*/}
        {/*</div>*/}
        <div className="event_details-line event_details-line--date">
          {formatDate(event.effectiveDate)}
        </div>
      </div>
    </div>
  )
}

export default Immunization
