import moment from 'moment-timezone'

const useCache = (callback, liveTime = 900) => {
  let cache = null

  return async (...args) => {
    if (cache && _.isEqual(cache.args, args) && moment().diff(cache.date, 'seconds') < liveTime) {
      return cache.result
    }
    const result = await callback(...args)
    cache = {
      result: result,
      args: args,
      date: new Date().toISOString(),
    }
    return result
  }
}

export default useCache
