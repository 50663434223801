import React, { useEffect, useMemo, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { useLoader } from '@library/CustomHooks'
import Modal from '@library/modal/Modal'
import Button from '@library/ui/button/Button'

import { apptEventsSchema } from '@ps/config/events'
import { groupEventsBySubcategory } from '@ps/helpers/events'
import { resourceExtractor } from '@ps/helpers/resourceExtractor'
import AppointmentEventDetails from '@ps/library/modals/appointmentEvent/AppointmentEventDetails'
import AppointmentEventTabs from '@ps/library/modals/appointmentEvent/AppointmentEventTabs'

import { $appts, $psHistory, $psResources } from '@store'

import { routeActions } from '@config/routeActions'

const AppointmentEventModal = ({ _core, baseItem }) => {
  const { t } = useTranslation()

  const apptEvent = baseItem.event
  const apptResource = baseItem.resource

  const encounterEvent = $psHistory.getEncounterByAppointmentId(apptEvent.id)
  const encounterResource = $psResources.getById(encounterEvent.id)

  const [isApptLoading, setIsApptLoading] = useState(true)
  const [canGoToAppointment, setCanGoToAppointment] = useState(false)

  const eventsBySubcategory = useMemo(() => {
    const events = _.filter($psHistory.events, (x) => {
      if (x.appointmentId === apptEvent.id) {
        return true
      }
      return ['document_hipaa_consent', 'document_telehealth_consent'].includes(x.configId)
    })
    return groupEventsBySubcategory(events, apptEventsSchema)
  }, [$psHistory.events, apptEvent]) // eslint-disable-line react-hooks/exhaustive-deps

  const isLoading = useLoader(async () => {
    const flatEventsBySubcategory = _.flatten(Object.values(eventsBySubcategory))
    const events = [...flatEventsBySubcategory, apptEvent, encounterEvent]

    await $psResources.getCompleteResources({ items: events })
  })

  const [apptExtractor, encounterExtractor] = useMemo(() => {
    return [
      resourceExtractor(apptEvent, apptResource),
      resourceExtractor(encounterEvent, encounterResource),
    ]
  }, [isLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(async () => {
    if (!isLoading) {
      const res = await $appts.checkOne({
        id: apptExtractor.ids.ziphyIdentifier.value,
      })

      setCanGoToAppointment(res.isSuccess)
      setIsApptLoading(false)
    }
  }, [isLoading])

  const backToAppointmentLink = useMemo(() => {
    return routeActions.APPT({
      appointmentId: apptExtractor.ids.ziphyIdentifier.value,
      encounterId: encounterExtractor.ids?.ziphyIdentifier?.value,
    })
  }, [isLoading, isApptLoading])

  return (
    <Modal centered scrollable size="xxl" fullHeight>
      <div className="modal-toolbar">
        <div className="modal-toolbar-btn modal-toolbar-btn--close" onClick={_core.onHide} />
      </div>

      {apptExtractor.type && (
        <Modal.Header>
          <div className="modal-header-title title-md">
            {apptExtractor.type}, {apptExtractor.start}
          </div>
          {canGoToAppointment && (
            <Button autoTarget={backToAppointmentLink} mode={['sm', 'primary']} className="mt-10">
              {t('ps.label.go_to_appointment')}
            </Button>
          )}
        </Modal.Header>
      )}

      <Modal.Body isLoading={isApptLoading} className="styled_scroll">
        <AppointmentEventDetails
          extractor={apptExtractor}
          encounterExtractor={encounterExtractor}
        />
        <AppointmentEventTabs eventsBySubcategory={eventsBySubcategory} extractor={apptExtractor} />
      </Modal.Body>
    </Modal>
  )
}

export default observer(AppointmentEventModal)
