import React from 'react'

import { useTranslation } from 'react-i18next'

import { isAbsoluteEmpty } from '@helpers/other'
import { removeHttp } from '@helpers/url'

import { DISABLED_CODE_SYSTEMS } from '@ps/library/viewCode/ViewCode.config'

const ViewCode = ({ codeObj = {}, emptyValue = '—', checkEnabled = true }) => {
  const { t } = useTranslation()

  let value = codeObj.asString

  if (checkEnabled) {
    if (
      _.isEmpty(codeObj.system) ||
      _.some(DISABLED_CODE_SYSTEMS, (x) => _.includes(x, removeHttp(codeObj.system)))
    ) {
      value = ''
    }
  }

  if (isAbsoluteEmpty(value)) {
    return <>{emptyValue}</>
  }

  return <>{value}</>
}

export default ViewCode
