import React from 'react'

import { observer } from 'mobx-react'

import { historyPageLimit } from '@ps/config/events'
import HistoryTable from '@ps/library/historyTable/HistoryTable'
import MedicationsSearch from '@ps/pages/patient/tabs/medications/MedicationsSearch'
import MedicationsTableHead from '@ps/pages/patient/tabs/medications/MedicationsTableHead'
import MedicationsTablePage from '@ps/pages/patient/tabs/medications/MedicationsTablePage'

import { $psHistory } from '@store'

const TabMedications = () => {
  return (
    <>
      <MedicationsSearch />
      <HistoryTable
        tableItems={$psHistory.table}
        isLoading={$psHistory.isLoading}
        pageLimit={historyPageLimit}
        mode={$psHistory.category}
        headComponent={MedicationsTableHead}
        pageComponent={MedicationsTablePage}
      />
    </>
  )
}

export default observer(TabMedications)
