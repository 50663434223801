import React from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import Modal from '@library/modal/Modal'
import DtlGroup from '@library/ui/dtl/DtlGroup'
import DtlItem from '@library/ui/dtl/DtlItem'

import { getEnumsValue } from '@helpers/enums'

import { ENUMS_INSURANCE_POLICY_HOLDERS, ENUMS_INSURANCE_TYPES } from '@config/enums/insurance'

const ViewInsuranceModal = ({ data = {}, _core }) => {
  const { t } = useTranslation()

  return (
    <Modal centered scrollable size="lg">
      <div className="modal-toolbar">
        <div className="modal-toolbar-btn modal-toolbar-btn--close" onClick={_core.onHide} />
      </div>
      <Modal.Header>{getEnumsValue(data.insuranceType, ENUMS_INSURANCE_TYPES)}</Modal.Header>
      <Modal.Body>
        <DtlGroup className="row pb-30 row--mb-20">
          <DtlItem
            className="col-12 col-lg-4"
            label={t('insurance.label.insurance_provider_name')}
            hideIfEmpty
          >
            {data.insuranceProviderName}
          </DtlItem>
          <DtlItem
            className="col-12 col-lg-4"
            label={t('insurance.label.insurance_provider_id')}
            hideIfEmpty
          >
            {data.insuranceProviderId}
          </DtlItem>
          <DtlItem
            className="col-12 col-lg-4"
            label={t('insurance.label.insurance_group_id')}
            hideIfEmpty
          >
            {data.insuranceGroupId}
          </DtlItem>
          <DtlItem
            className="col-12 col-lg-4"
            label={t('insurance.label.insurance_member_name')}
            hideIfEmpty
          >
            {[data.insuranceMemberFirstName, data.insuranceMemberLastName].join(' ')}
          </DtlItem>
          <DtlItem
            className="col-12 col-lg-4"
            label={t(
              data.insuranceType === 'medicare'
                ? 'insurance.label.medicare_number'
                : 'insurance.label.insurance_member_id',
            )}
            hideIfEmpty
          >
            {data.insuranceMemberId}
          </DtlItem>
          <DtlItem
            className="col-12 col-lg-4"
            label={t('insurance.label.insurance_policy_holder')}
            hideIfEmpty
          >
            {data.insurancePolicyHolder &&
              getEnumsValue(data.insurancePolicyHolder, ENUMS_INSURANCE_POLICY_HOLDERS)}
          </DtlItem>
        </DtlGroup>
      </Modal.Body>
    </Modal>
  )
}

export default observer(ViewInsuranceModal)
