var TrendsListChartHelpers;
(function (TrendsListChartHelpers) {
    var getDatesDifferent = function (minDate, maxDate) {
        return Math.abs(new Date(maxDate).getTime() - new Date(minDate).getTime());
    };
    TrendsListChartHelpers.getRangeOfDates = function (minDate, maxDate, count) {
        count -= 1;
        var gap = getDatesDifferent(minDate, maxDate) / count;
        var result = [new Date(minDate)];
        for (var i = 0; i < count; i++)
            result.push(new Date(result[i].getTime() + gap));
        return result;
    };
})(TrendsListChartHelpers || (TrendsListChartHelpers = {}));
export default TrendsListChartHelpers;
