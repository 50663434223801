import React, { useState } from 'react'

import { GoogleMap, InfoBox, Marker } from '@react-google-maps/api'
import { Trans, useTranslation } from 'react-i18next'

import maps from '@library/map/Map'
import mapStyles from '@library/map/mapStyles.json'
import Button from '@library/ui/button/Button'

import { extendAddress } from '@helpers/addressHelper'

import { $alerts } from '@store'

import mainConfig from '@config/main'

import PointMarker from '@assets/images/map/point-marker.svg'

import styles from './SelectPredefinedAddressesMap.module.scss'

const OPTIONS = {
  styles: mapStyles,
  zoom: 15,
  disableDefaultUI: true,
  zoomControl: true,
  gestureHandling: 'auto',
}

const SelectPredefinedAddressesMap = ({ items = [], onSelect, buttonLabel, isLoadingButton }) => {
  const { t } = useTranslation()
  const [userLocation, setUserLocation] = useState(null)
  const [selected, setSelected] = useState(null)

  const clearSelected = () => setSelected(null)

  const handleSelect = (item) => {
    if (selected === item) {
      clearSelected()
    } else {
      setSelected(item)
    }
  }

  const handleDetectLocation = async () => {
    const { coords, error } = await maps.getUserGeolocation()

    if (coords) {
      setUserLocation(coords)
    } else if (error) {
      $alerts.add(t('addresses.detect_disabled.message'), {
        title: t('addresses.detect_disabled.title'),
      })
    }
  }

  const center = userLocation ? userLocation : items[0]?.coords

  return (
    <div className="g-map">
      {_.isEmpty(center) ? (
        <div className={styles.alert}>
          <Trans
            i18nKey="book.alert.appointments_at_our_clinics_are_not_available"
            values={{ email: mainConfig.helpMail }}
            components={[<a href={`mailto:${mainConfig.helpMail}`} />]}
          />
        </div>
      ) : (
        <>
          <GoogleMap mapContainerClassName={'g-map-inner'} center={center} options={OPTIONS}>
            {items.map((item) => (
              <Marker
                key={item.id}
                position={item.coords}
                icon={PointMarker}
                clickable={true}
                cursor={'pointer'}
                onClick={() => handleSelect(item)}
              />
            ))}
            {/* TODO change PointMarker to user location marker */}
            {/*{userLocation !== null && (*/}
            {/*  <Marker position={userLocation} icon={PointMarker} clickable={false} />*/}
            {/*)}*/}
            {selected !== null && (
              <InfoBox
                position={selected.coords}
                options={{
                  closeBoxURL: '',
                  enableEventPropagation: false,
                  boxClass: styles.info,
                }}
              >
                <div className={styles.box}>
                  <div className={styles.close} onClick={clearSelected} />
                  <div className={styles.header}>{extendAddress(selected).shortAddress()}</div>
                  <Button
                    mode="primary"
                    tag="button"
                    buttonType="button"
                    label={buttonLabel}
                    className={styles.button}
                    action={() => onSelect(selected)}
                    isLoading={isLoadingButton}
                  />
                </div>
              </InfoBox>
            )}
          </GoogleMap>
          <Button
            mode={['secondary', 'icon']}
            className={styles.location}
            action={handleDetectLocation}
          />
        </>
      )}
    </div>
  )
}

export default SelectPredefinedAddressesMap
