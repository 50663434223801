import React from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { getUserText } from '@helpers/user'

const PatientHeaderForPrint = ({ patient }) => {
  const { t } = useTranslation()
  return (
    <div className="patient_medications_print">
      <p className="title-lg mb-20">
        {t('label.patient_name')}: {getUserText(patient, ['fullName'])}
      </p>
      <div className="row">
        <div className="col-6">
          <p className="label">{t('label.date_of_birth')}</p>
          <p>{getUserText(patient, ['dob'])}</p>
        </div>
        <div className="col-6">
          <p className="label">{t('label.patient_id')}</p>
          <p>{patient.zid}</p>
        </div>
      </div>
    </div>
  )
}

export default observer(PatientHeaderForPrint)
