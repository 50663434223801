import React from 'react'

import { useTranslation } from 'react-i18next'

import DtlGroup from '@library/ui/dtl/DtlGroup'
import DtlItem from '@library/ui/dtl/DtlItem'

import { getUserText } from '@helpers/user'

const CocPatientDetails = ({ patient }) => {
  const { t } = useTranslation()

  return (
    <DtlGroup className="row row--mb-20">
      <DtlItem className="col-12 col-lg-4" label={t('label.name')}>
        {getUserText(patient, ['fullName'])}
      </DtlItem>
      <DtlItem className="col-12 col-lg-4" label={t('label.sex_assigned_at_birth')}>
        {getUserText(patient, ['gender'])}
      </DtlItem>
      <DtlItem className="col-12 col-lg-4" label={t('label.date_of_birth')}>
        {getUserText(patient, ['dob'])}
      </DtlItem>
      <DtlItem className="col-12 col-lg-4" label={t('label.referral_partner_code')}>
        {patient.code}
      </DtlItem>
      <DtlItem className="col-12 col-lg-4" label={t('label.email')} valueClassName="text-ellipsis">
        {patient.email}
      </DtlItem>
      <DtlItem className="col-12 col-lg-4" label={t('label.phone')}>
        {patient.phone}
      </DtlItem>
    </DtlGroup>
  )
}

export default CocPatientDetails
