var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Fragment } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
var DayPickerYear = function (_a) {
    var currentDate = _a.currentDate, selectedDate = _a.selectedDate, setModel = _a.setModel, setView = _a.setView, minDate = _a.minDate, maxDate = _a.maxDate, availableYears = _a.availableYears, board = _a.board, setRefYear = _a.setRefYear;
    var handleSelectYear = function (value) {
        return function () {
            setModel(value);
            setView('month');
        };
    };
    var isSelected = function (value) {
        if (!selectedDate) {
            return false;
        }
        return value.isSame(selectedDate, 'year');
    };
    var isCurrent = function (value) {
        return value.isSame(currentDate, 'year');
    };
    var isLocked = function (value) {
        if (!_.isEmpty(availableYears)) {
            return !(value.format('YYYY') in availableYears);
        }
        else {
            return value.isBefore(minDate, 'year') || value.isAfter(maxDate, 'year');
        }
    };
    return (_jsx("div", __assign({ className: classNames('day-picker-year', 'styled_scroll') }, { children: _jsx("div", __assign({ className: "body" }, { children: _jsx("div", __assign({ className: "row-year" }, { children: board.map(function (_a, i) {
                    var text = _a.text, value = _a.value;
                    return (_jsxs(Fragment, { children: [text && !isLocked(value) && (_jsx("div", __assign({ className: classNames('cell', {
                                    'cell-selected': isSelected(value),
                                    'cell-current': isCurrent(value),
                                }), onClick: handleSelectYear(value), ref: setRefYear && setRefYear(value, 'YYYY') }, { children: text }))), text && isLocked(value) && (_jsx("div", __assign({ className: classNames('cell', {
                                    'cell-selected': isSelected(value),
                                    'cell-current': isCurrent(value),
                                    'cell-locked': true,
                                }), ref: setRefYear && setRefYear(value, 'YYYY') }, { children: text })))] }, value.toString() + i));
                }) })) })) })));
};
export default DayPickerYear;
