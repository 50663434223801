var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import PageNoResults from '@library/pageNoResults/PageNoResults';
import ProgressRing from '@library/ui/progressRing/ProgressRing';
import styles from './WithLoaderWrapper.module.scss';
var WithLoaderWrapper = function (_a) {
    var _b = _a.isLoading, isLoading = _b === void 0 ? false : _b, _c = _a.visibleCondition, visibleCondition = _c === void 0 ? true : _c, className = _a.className, children = _a.children, noResultsComponent = _a.noResultsComponent;
    var t = useTranslation().t;
    noResultsComponent =
        typeof noResultsComponent !== 'undefined' ? (noResultsComponent) : (_jsx(PageNoResults, { children: t('errors.no_results') }));
    return (_jsx(_Fragment, { children: !isLoading ? (_jsx(_Fragment, { children: visibleCondition ? children : noResultsComponent })) : (_jsx("div", __assign({ className: classNames(styles.loader, className) }, { children: _jsx(ProgressRing, { progress: 25, spin: true }) }))) }));
};
export default WithLoaderWrapper;
