import React from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { usePhone } from '@library/CustomHooks'
import InputBase from '@library/form/fields/new/InputBase'
import InputDate from '@library/form/fields/new/InputDate'
import UploadAvatar from '@library/form/fields/UploadAvatar'
import { useForm } from '@library/form/FormControllerNew'
import Modal from '@library/modal/Modal'
import PhoneNumber from '@library/phoneNumber/PhoneNumber'
import { $configure } from '@library/plugins/configure/configure.store'
import ConfigureProgress from '@library/plugins/configure/library/ConfigureProgress'
import MultiSelect from '@library/selectDropdown/MultiSelect'
import Button from '@library/ui/button/Button'

import { ageLimit } from '@helpers/patients'

import { $auth, $loader, $user } from '@store'

import { ENUMS_PERSON_GENDER } from '@config/enums/person'

const ConfigureFillingCardModal = ({ _core }) => {
  const { t } = useTranslation()

  const { profile } = $user
  const dobType = 'profile'

  const initEmail = $auth.getAccountEmail
  const initPhone = $auth.getAccountPhone

  const { form } = useForm({
    avatar: { value: profile.avatar, rules: [] },
    firstName: {
      value:
        profile.firstName || ($user.info.firstName !== 'Anonymous' && $user.info.firstName) || '',
      rules: ['name', 'required'],
    },
    lastName: {
      value: profile.lastName || $user.info.lastName || '',
      rules: ['name', 'required'],
    },
    gender: { value: profile.gender, rules: ['gender', 'required'], prepValue: 'singleSelect' },
    dob: { value: profile.dob, rules: [{ name: 'dob', dobType }, 'required'] },
    email: { value: initEmail, rules: ['email', 'required'], output: 'emptyUndefined' },
    phone: {
      value: usePhone(initPhone),
      rules: [{ name: 'phone', required: true }],
      output: ['getPhone', 'emptyUndefined'],
    },
    code: { value: profile.code, rules: [] },
  })
  const { avatar, firstName, lastName, gender, dob, email, phone, code } = form.fields

  const handleSubmit = $loader.registerHandler('filling-card-done', async (e) => {
    e.preventDefault()

    if (form.validateAll().isValid) {
      _core.onBlock()

      const res = await $user.saveProfile(form.values)

      _core.onUnblock()

      if (res.isSuccess) {
        _core.onHide()
      }
    }
  })

  function handleBack() {
    $configure.checkConfigure({ showPreview: true })
    _core.onHide()
  }

  return (
    <Modal centered scrollable closeOnOverlay={false}>
      <div className="modal-toolbar">
        <div className="modal-toolbar-btn modal-toolbar-btn--back" onClick={handleBack} />
        <div className="modal-toolbar-meta d-lg-none">{t('configure_card.meta_title')}</div>
        <div className="modal-toolbar-progress">
          <ConfigureProgress />
        </div>
      </div>
      <Modal.Header className="text-lg-center">{t('configure_card.title')}</Modal.Header>
      <Modal.Body>
        <form id="edit_profile_form" onSubmit={handleSubmit}>
          <UploadAvatar
            user={{ ...profile, firstName: firstName.value, lastName: lastName.value }}
            field={avatar}
            value={avatar.value}
            onChange={avatar.onChange}
          />

          <div className="row row--gutters-5">
            <div className="col-6">
              <InputBase
                className="mb-10"
                label={t('user.label.first_name')}
                field={firstName}
                mode="lg"
              />
            </div>
            <div className="col-6">
              <InputBase
                className="mb-10"
                label={t('user.label.last_name')}
                field={lastName}
                mode="lg"
              />
            </div>
          </div>

          <MultiSelect
            className="mb-10"
            label={t('label.sex_assigned_at_birth')}
            items={Object.values(ENUMS_PERSON_GENDER).map((x) => ({ id: x.id, text: t(x.tKey) }))}
            field={gender}
            mode="lg"
          />

          <div className="row row--gutters-5">
            <div className="col-6">
              <InputDate
                className="mb-10"
                minDate={ageLimit(dobType).from}
                maxDate={ageLimit(dobType).to}
                label={t('calendar.dob')}
                field={dob}
                mode="lg"
              />
            </div>
            <div className="col-6">
              <InputBase className="mb-10" label={t('user.label.code')} field={code} mode="lg" />
            </div>
          </div>

          <InputBase
            label={t('user.label.email')}
            field={email}
            type="email"
            placeholder={t('user.ph.email')}
            mode="lg"
          />

          <InputBase label={t('user.label.phone')} field={phone} mode="lg">
            <PhoneNumber
              popupClass={'phone_number-popup--on_top'}
              phone={phone.value}
              onChange={phone.onChange}
            />
          </InputBase>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          tag="button"
          mode={['primary', 'block']}
          form="edit_profile_form"
          isLoading={$loader.isRunHandler('filling-card-done')}
        >
          {t('btn.done')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default observer(ConfigureFillingCardModal)
