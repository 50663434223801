import React, { useMemo } from 'react'

// map loader
import { GoogleMap, Marker } from '@react-google-maps/api'
import { observer } from 'mobx-react'
import { useResizeDetector } from 'react-resize-detector'

import classNames from 'classnames'

import mapStyles from '@library/map/mapStyles.json'

import PointMarker from '@assets/images/map/point-marker.svg'

const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  gestureHandling: 'none',
  zoom: 16,
}

const Map = ({ coords = {}, showMarker = true, className }) => {
  const { ref, width = 0, height = 0 } = useResizeDetector()

  const getShiftedCenter = useMemo(() => {
    let lat = coords.lat
    let lng = coords.lng

    return {
      lat: lat - Math.abs(lat) * height * 0.000000055,
      lng: lng,
    }
  }, [height, coords])

  return (
    <div ref={ref} className={classNames('g-map g-map--static', className)}>
      {coords && (
        <GoogleMap
          mapContainerClassName={'g-map-inner'}
          center={getShiftedCenter}
          zoom={options.zoom}
          options={options}
        >
          {showMarker && <Marker position={coords} icon={PointMarker} />}
        </GoogleMap>
      )}
    </div>
  )
}

export default observer(Map)
