import { customActions as customCoreActions } from '@core/src/config/customActions'

import EditPatientModal from '@library/patients/EditPatient.modal'

import { $modal } from '@store'

// () => ({onClick, wvAction})
export const customActions = {
  ...customCoreActions,
  HANDLE_ADD_PATIENT: ({ callback }) => ({
    onClick: () => callback(),
  }),
  HANDLE_EDIT_PATIENT: ({ patientId }) => ({
    onClick: () => {
      $modal.add(EditPatientModal, { editId: patientId })
    },
  }),
}
