import React, { useRef } from 'react'

const BaseImage = ({
  src,
  srcSet,
  fallbackSrc,
  onIsLoading,
  onIsError,
  onIsDefault,
  ...passedProps
}) => {
  const imageRef = useRef()

  const changeIsLoading = (value) => {
    if (_.isFunction(onIsLoading)) {
      onIsLoading(value)
    }
  }

  const loadHandle = () => {
    changeIsLoading(false)
  }

  const errorHandle = () => {
    changeIsLoading(false)

    if (_.isFunction(onIsError)) {
      onIsError(true)
    }

    if (fallbackSrc) {
      imageRef.current.src = fallbackSrc
    } else {
      if (_.isFunction(onIsDefault)) {
        onIsDefault(true)
      }
    }
  }

  return (
    <img
      ref={imageRef}
      src={src}
      srcSet={srcSet}
      onLoad={loadHandle}
      onError={errorHandle}
      alt=""
      {...passedProps}
    />
  )
}

export default BaseImage
