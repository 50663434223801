import React, { useEffect, useMemo, useRef, useState } from 'react'

import { observer } from 'mobx-react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import classNames from 'classnames'
import queryString from 'query-string'

import packageJson from '@root/package.json'

import { analytics } from '@common/analytics/zipAnalytics'

import appLogo from '@src/assets/images/app-logo.svg'

import AwsCaptcha from '@library/awsCaptcha/AwsCaptcha'
import CodeResender from '@library/codes/CodeResender'
import CodeResenderButton from '@library/codes/CodeResenderButton'
import CodeResenderCallMe from '@library/codes/CodeResenderCallMe'
import { usePhone } from '@library/CustomHooks'
import InputBase from '@library/form/fields/new/InputBase'
import { useForm } from '@library/form/FormControllerNew'
import PhoneNumber from '@library/phoneNumber/PhoneNumber'
import { TOS_GENERAL, TOS_PROVIDER } from '@library/plugins/termsOfService/Tos.config'
import SelectModal from '@library/select/Select.modal'
import Button from '@library/ui/button/Button'
import CautionNotice from '@library/widgets/cautionNotice/CautionNotice'

import { changeCurrentLanguage } from '@helpers/i18n'
import { customGoTo, toPath } from '@helpers/router'

import { $alerts, $auth, $loader, $modal, $user } from '@store'

import { LANGUAGES } from '@config/countries'
import mainConfig from '@config/main'
import { routeActions } from '@config/routeActions'

import styles from './LoginFull.module.scss'

const LoginFull = () => {
  const { t, i18n } = useTranslation()
  const location = useLocation()

  const { referrer, phone } = queryString.parse(location.search)
  const successUrl = referrer ? { to: toPath(referrer) } : routeActions.HOME()

  let isInit = useRef(true)
  const [type, setType] = useState('phone')
  const [initialPrefetch, setInitialPrefetch] = useState()

  const isPhone = type === 'phone'

  const formPhone = useForm({
    account: {
      value: usePhone(phone),
      rules: [{ name: 'phone', required: true }],
      output: ['getPhone', 'emptyUndefined'],
    },
    code: { value: '', rules: ['code'] },
  })

  const formEmail = useForm({
    account: { value: '', rules: ['email', 'required'], output: 'emptyUndefined' },
    code: { value: '', rules: ['code'] },
  })

  const { form } = isPhone ? formPhone : formEmail

  const { account, code } = form.fields

  const [codeResenderData, setCodeResenderData] = useState({})
  const isSent = useRef(false)
  const [tmpValue, setTmpValue] = useState(false)
  const isWaiting = isSent.current && _.isEqual(account.value, tmpValue)

  async function handlePrefetchCode(using) {
    setTmpValue(account.value)
    code.onChange('')

    const params = { type, using, value: form.values.account }
    const res = await $auth.sendCode(params, { setIsBound: true })

    return _.get(res, 'prepared.codeSent')
  }

  function handleChangeType(type) {
    clear()
    setType(type)
  }

  function clear() {
    account.onChange('')
    code.onChange('')
    setTmpValue(false)
    isSent.current = false
  }

  const submitSendCodeHandle = $loader.registerHandler('auth-handle-submit', async (e) => {
    e.preventDefault()

    if (form.validate('account').isValid) {
      isSent.current = false
      const codeSent = await handlePrefetchCode()

      if (codeSent) {
        isSent.current = true
        setInitialPrefetch(codeSent)
        isInit.current = false
      } else {
        setInitialPrefetch(null)
        if (!$auth.needCaptcha) {
          form.forceError('account')
        }
      }
    }
  })

  const submitDoneHandle = $loader.registerHandler('auth-handle-submit', async (e) => {
    e.preventDefault()

    if (!form.validateAll().isValid) return
    const values = form.values
    const response = await $auth.loginByCode({ type, value: values.account, code: values.code })

    if (response.error) {
      if (response.error.code === 'account_role') {
        $alerts.add(t(`login.alert.account_role.body`, { role: mainConfig.appType }), {
          title: t(`login.alert.account_role.title`),
        })
        analytics.error('account_role')
        clear()
      } else {
        form.forceError('code')
      }
    } else if (response.prepared) {
      handleComplete()
    }
  })

  function handleComplete() {
    if (successUrl) {
      customGoTo(successUrl)
    }
  }

  useEffect(() => {
    if ($user.user) {
      handleComplete()
    }
  }, [$user.user])

  const availableLangs = useMemo(() => LANGUAGES.map((x) => ({ id: x.locale, text: x.name })), [])
  const onChangeLang = (locale) => {
    if (i18n.language === locale) return
    changeCurrentLanguage(locale)
  }

  return (
    <div className={classNames(styles.authPage, styles.authPageContent, 'content')}>
      <div className="box box--full-height">
        <div className="box-header">
          <div className="box-header-title title-lg mb-0">
            <img src={appLogo} alt="" />
          </div>
        </div>
        <div className="box-body box-body--padding">
          <form
            id="auth_form"
            onSubmit={isWaiting ? submitDoneHandle : submitSendCodeHandle}
            noValidate
          >
            {isPhone ? (
              <InputBase key="inputPhone" field={account} mode="lg">
                <PhoneNumber phone={account.value} onChange={account.onChange} clear />
              </InputBase>
            ) : (
              <InputBase
                key="inputEmail"
                field={account}
                type="email"
                mode="lg"
                placeholder={t('login.ph.email')}
                clear
              />
            )}
            <AwsCaptcha
              show={$auth.needCaptcha}
              apiKey={mainConfig.awsCaptcha.key}
              domainList={mainConfig.awsCaptcha.domains}
            />
            {isWaiting && (
              <>
                <div className={classNames(styles.authPageInfoText, 'mt-10')}>
                  <Trans i18nKey="login.message.sent" components={[<br />]} />
                </div>
                <CodeResender
                  key={type}
                  initialPrefetch={initialPrefetch}
                  prefetch={handlePrefetchCode}
                  code={code}
                  onChange={code.onChange}
                  onSetData={setCodeResenderData}
                  label={false}
                  placeholder={t('login.ph.code')}
                  textButton={false}
                  autoFocus={isInit.current}
                />
              </>
            )}
            <div className="mt-10">
              <Button
                tag="button"
                mode={['primary', 'block']}
                form="auth_form"
                isLoading={$loader.isRunHandler('auth-handle-submit')}
              >
                {isWaiting ? t('btn.continue') : t(`login.btn.${type}`)}
              </Button>
            </div>
            {isWaiting && (
              <div className="mt-10">
                <CodeResenderButton type="button" className="btn-block" {...codeResenderData} />
              </div>
            )}
            <div className={classNames(styles.authPageChange, 'mt-20')}>
              {isPhone && isWaiting ? (
                <>
                  <CodeResenderCallMe {...codeResenderData} />
                </>
              ) : (
                <>
                  {t('login.other.text')}{' '}
                  {isPhone ? (
                    <span className="a" onClick={() => handleChangeType('email')}>
                      {t(`login.other.email`)}
                    </span>
                  ) : (
                    <span className="a" onClick={() => handleChangeType('phone')}>
                      {t(`login.other.phone`)}
                    </span>
                  )}
                </>
              )}
            </div>
            {availableLangs.length > 1 && (
              <div className={classNames(styles.authPageChange, 'mt-10')}>
                <span
                  className="a text-meta"
                  onClick={() =>
                    $modal.add(SelectModal, {
                      title: t('profile.label.language'),
                      items: availableLangs,
                      value: i18n.language,
                      allowUnselect: false,
                      onChange: (v) => onChangeLang(v[0]),
                    })
                  }
                >
                  {t('login.label.change_language')}
                </span>
              </div>
            )}
          </form>
        </div>
        <div className="box-footer">
          {mainConfig.custom.tosProviderEnabled ? (
            <Trans
              i18nKey="login.agreement_provider"
              values={{ name: mainConfig.appName }}
              components={[
                <Link to={`?${TOS_GENERAL}`} className="a" />,
                <Link to={`?${TOS_GENERAL}=pp`} className="a" />,
                <Link to={`?${TOS_PROVIDER}`} className="a" />,
              ]}
            />
          ) : (
            <Trans
              i18nKey="login.agreement"
              values={{ name: mainConfig.appName }}
              components={[
                <Link to={`?${TOS_GENERAL}`} className="a" />,
                <Link to={`?${TOS_GENERAL}=pp`} className="a" />,
              ]}
            />
          )}
          <div className="mt-10">{t('version', { number: packageJson.version })}</div>
        </div>
      </div>
      {mainConfig.custom.staffLoginCautionNotice && (
        <CautionNotice
          show={true}
          title={t('caution_notice.hipaa.title')}
          message={t('caution_notice.hipaa.message')}
        />
      )}
    </div>
  )
}

export default observer(LoginFull)
