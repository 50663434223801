import { INSURANCE_FIELDS } from '@config/enums/insurance'

class InsuranceVoucherSelectModalHelpers {
  INSURANCE_FIELDS = INSURANCE_FIELDS
  REQUIRED_INSURANCE_FIELDS = ['insuranceType', 'insuranceMemberId']

  getEmptyInsuranceFields = () => {
    return this.INSURANCE_FIELDS.reduce(
      (acc, item) => {
        acc[item] = null
        return acc
      },
      { useInsurance: false },
    )
  }

  hasInsurance = (values) => {
    return this.REQUIRED_INSURANCE_FIELDS.every((item) => !!values[item])
  }

  pickInsuranceFields = (values) => {
    return { ..._.pick(values, this.INSURANCE_FIELDS), useInsurance: false }
  }
}

export default new InsuranceVoucherSelectModalHelpers()
