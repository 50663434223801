import React, { useEffect, useMemo } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import { TransitionWrapper } from '@library/animations/Animations'
import InputBase from '@library/form/fields/new/InputBase'
import { useForm } from '@library/form/FormControllerNew'
import Button from '@library/ui/button/Button'
import InfoBox from '@library/ui/infoBox/InfoBox'

import { extendAddress } from '@helpers/addressHelper'

import { $main, $places } from '@store'

import styles from './AddAddressModalDetails.module.scss'

const AddAddressModalDetails = ({ ...props }) => {
  const { t } = useTranslation()

  const {
    place: _place,
    editId,
    isDetails,
    onChange,
    onSave,
    onRemove,
    toggleView,
    _core,
    isBuildingReadOnly,
    isStreetReadOnly,
    isCityReadOnly,
    isStateReadOnly,
    isZipReadOnly,
    isEdit,
    isLoading,
  } = props

  const { form } = useForm({
    building: { value: _place.building, rules: ['required'] },
    street: { value: _place.street, rules: ['required'] },
    city: { value: _place.city, rules: ['required'] },
    state: { value: _place.state, rules: ['required'] },
    zip: { value: _place.zip, rules: [] },
    apartment: { value: _place.apartment },
    floor: { value: _place.floor },
    directions: { value: _place.directions },
  })

  const { building, street, city, state, zip, apartment, floor, directions } = form.fields

  const formInvalid = useMemo(() => {
    return _place.country === 'US' && !_.trim(state.value)
  }, [_place.country, state.value]) // eslint-disable-line react-hooks/exhaustive-deps

  const place = { ..._place, ...form.values } // eslint-disable-line react-hooks/exhaustive-deps
  const extendedPlace = extendAddress(place)

  useEffect(() => {
    _.forEach(form.values, (value, key) => {
      if (!_.isEqual(value, _place[key])) {
        form.fields[key].setValue(_place[key])
      }
    })
    if (_place.country === 'US' && !_.trim(_place.state)) {
      form.forceError('state')
    }
  }, [_place]) // eslint-disable-line react-hooks/exhaustive-deps

  const isChanged = useMemo(() => {
    return editId && !_.isEqual(place, $places.getById(editId))
  }, [$places, place, editId]) // eslint-disable-line react-hooks/exhaustive-deps

  function onToggle(e) {
    onChange(place)
    toggleView(e)
  }

  function onCancel(e) {
    e.preventDefault()
    _core.onHide()
  }

  function handleOnSave(e) {
    e.preventDefault()

    if (form.validateAll().isValid) {
      onChange(place, true)
    } else {
      onChange(place)
    }
  }

  function handleOnRemove(e) {
    onRemove(e)
  }

  return (
    <TransitionWrapper
      items={isDetails}
      initial={!isDetails}
      from={{ opacity: 0, height: 0, marginTop: 0 }}
      enter={{ opacity: 1, height: 'auto', marginTop: 20 }}
      leave={{ opacity: 0, height: 0, marginTop: 0 }}
    >
      {(item) =>
        item &&
        ((style) => (
          <form className={styles.details} style={style}>
            <>
              {$main.isMobile ? (
                <div
                  className={classNames('btn btn-arrow', { 'pe-none': isEdit })}
                  onClick={isEdit ? undefined : onToggle}
                >
                  <div className="btn-arrow-title">{extendedPlace.shortLine1()}</div>
                  <div className="btn-arrow-meta">{extendedPlace.line2()}</div>
                </div>
              ) : (
                <>
                  <InfoBox
                    className="mb-10"
                    mode={['sm', 'gray', 'reverse']}
                    label={extendedPlace.line2()}
                    value={extendedPlace.shortLine1()}
                    childButton
                  >
                    {!isEdit && (
                      <Button mode="white" className="btn-sm" action={onToggle}>
                        {t('add_address.btn.change')}
                      </Button>
                    )}
                  </InfoBox>
                </>
              )}
              <div className="mb-30">
                <div className="row row--gutters-10 row--mb-10">
                  <div className="col-4 col-lg-3">
                    <InputBase
                      className={classNames({ 'pe-none': isEdit })}
                      label={t('add_address.label.building')}
                      field={building}
                      placeholder={t('add_address.ph.apt')}
                      mode="lg"
                      readonly={isBuildingReadOnly}
                      onClick={() => isBuildingReadOnly && toggleView()}
                    />
                  </div>
                  <div className="col-8 col-lg-5">
                    <InputBase
                      className={classNames({ 'pe-none': isEdit })}
                      label={t('add_address.label.street')}
                      field={street}
                      placeholder={t('add_address.ph.street')}
                      mode="lg"
                      readonly={isStreetReadOnly}
                      onClick={() => isStreetReadOnly && toggleView()}
                    />
                  </div>
                  <div className="col-6 col-lg-2">
                    <InputBase
                      label={t('add_address.label.apt')}
                      field={apartment}
                      placeholder={t('add_address.ph.apt')}
                      mode="lg"
                    />
                  </div>
                  <div className="col-6 col-lg-2">
                    <InputBase
                      label={t('add_address.label.floor')}
                      field={floor}
                      placeholder={t('add_address.ph.floor')}
                      mode="lg"
                    />
                  </div>
                  <div className="col-12 col-lg-6">
                    <InputBase
                      className={classNames({ 'pe-none': isEdit })}
                      label={t('add_address.label.city')}
                      field={city}
                      placeholder={t('add_address.ph.city')}
                      mode="lg"
                      readonly={isCityReadOnly}
                      onClick={() => isCityReadOnly && toggleView()}
                    />
                  </div>
                  <div className="col-8 col-lg-4">
                    <InputBase
                      className={classNames({ 'pe-none': isEdit })}
                      label={t('add_address.label.state')}
                      field={state}
                      placeholder={t('add_address.ph.state')}
                      mode="lg"
                      readonly={isStateReadOnly}
                      onClick={() => isStateReadOnly && toggleView()}
                    />
                  </div>
                  <div className="col-4 col-lg-2">
                    <InputBase
                      className={classNames({ 'pe-none': isEdit })}
                      label={t('add_address.label.zip')}
                      field={zip}
                      placeholder={t('add_address.ph.zip')}
                      mode="lg"
                      readonly={isZipReadOnly}
                      onClick={() => isZipReadOnly && toggleView()}
                    />
                  </div>
                  <div className="col-12">
                    <InputBase
                      label={t('add_address.label.directions')}
                      field={directions}
                      placeholder={t('add_address.ph.directions')}
                      mode="lg"
                    />
                  </div>
                </div>
              </div>
              <div className="btn-group">
                {editId ? (
                  isChanged ? (
                    <>
                      <Button
                        tag="button"
                        mode="primary"
                        disabled={formInvalid}
                        action={handleOnSave}
                        isLoading={isLoading}
                      >
                        {t('btn.save')}
                      </Button>
                      {!$main.isMobile && (
                        <Button tag="button" mode="secondary-dark" action={onCancel}>
                          {t('btn.cancel')}
                        </Button>
                      )}
                    </>
                  ) : (
                    <>
                      <Button tag="button" mode="primary" action={onCancel}>
                        {t('btn.done')}
                      </Button>
                      {!$main.isMobile && (
                        <Button tag="button" mode="secondary-dark" action={handleOnRemove}>
                          {t('btn.delete')}
                        </Button>
                      )}
                    </>
                  )
                ) : (
                  <Button tag="button" mode="primary" disabled={formInvalid} action={handleOnSave}>
                    {t('btn.add')}
                  </Button>
                )}
              </div>
            </>
          </form>
        ))
      }
    </TransitionWrapper>
  )
}

export default observer(AddAddressModalDetails)
