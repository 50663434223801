import React, { useMemo, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { useForm } from '@library/form/FormControllerNew'
import PageNoResults from '@library/pageNoResults/PageNoResults'
import MultiSelect from '@library/selectDropdown/MultiSelect'

import { apptEventsSchema } from '@ps/config/events'
import { sortEventsBy } from '@ps/helpers/events'
import EventsViewer from '@ps/library/eventsViewer/EventsViewer'

import { $psResources } from '@store'

const ApptTabNotesList = ({ category, eventsBySubcategory }) => {
  const { t } = useTranslation()

  const categoryConfig = apptEventsSchema[category] || {}
  const [subcategory, setSubcategory] = useState(
    (_.find(categoryConfig.subcategories, (x) => x.isDefault) || {}).id,
  )
  const events = eventsBySubcategory[subcategory] || []
  const viewType = categoryConfig.subcategories[subcategory].viewType

  const { form } = useForm({
    sortBy: { value: 'date_desc', prepValue: 'singleSelect' },
  })
  const { sortBy } = form.fields

  const items = useMemo(() => {
    let result = []

    _.forEach(sortEventsBy(events, sortBy.value), (x) => {
      result.push({ event: x, resource: $psResources.getById(x.id) })
    })

    return result
  }, [events, sortBy.value]) // eslint-disable-line react-hooks/exhaustive-deps

  const sortByItems = useMemo(() => {
    return [
      { id: 'date_desc', text: t('ps.label.sortBy.date_desc') },
      { id: 'date_asc', text: t('ps.label.sortBy.date_asc') },
    ]
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="row_wrapper mb-20">
        <div className="row row--mb-10">
          <div className="col-12 col-lg-4">
            <MultiSelect items={sortByItems} field={sortBy} mode={['sm', 'fw-400']} />
          </div>
        </div>
      </div>
      {items.length > 0 ? (
        <div className="row_wrapper">
          {items.map(({ event, resource }) => (
            <EventsViewer type="details" event={event} resource={resource} key={event.id} />
          ))}
        </div>
      ) : (
        <PageNoResults>{t('errors.no_results')}</PageNoResults>
      )}
    </>
  )
}

export default observer(ApptTabNotesList)
