var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { $main, $notices } from '@store';
import { ReactComponent as NotificationIcon } from '@assets/images/ui/notification.svg';
import styles from './Notification.module.scss';
var Notification = function (_a) {
    var className = _a.className;
    var handleClick = function () {
        $main.showRightSidebar('notifications', {});
    };
    return (_jsx("div", __assign({ onClick: handleClick, className: classNames(styles.container, className, $notices.actualItems.length > 0 && styles.containerRedMark) }, { children: _jsx(NotificationIcon, {}) })));
};
export default observer(Notification);
