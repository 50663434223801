import apiService from '@services/api'

import { $auth } from '@store'

class user {
  //
  // Profile
  //
  async acceptTos({ tosType = 'client' }) {
    return await apiService.fetchLoggedV3('user.profile.accept_tos', { tosType })
  }

  async updateUser(payload) {
    return await apiService.fetchLoggedV3('user.profile.update', payload)
  }

  async deleteProfile(code) {
    return await apiService.fetchLoggedV3('user.profile.delete', {
      accountId: $auth.currentLogged.account.id,
      code,
    })
  }

  //
  // Roles
  //
  async getRoles(expand = { role: ['practice_id'] }) {
    const response = await apiService.fetchLoggedV3('user.roles.list', {
      expand,
      filter: {
        eq: ['is_active', true],
      },
    })

    let roles = _.get(response, 'prepared.roles.items', [])
    let practices = _.filter(_.get(response, 'prepared.practices', []), (x) => x.isActive)

    return { roles, practices }
  }

  //
  // Accounts
  //
  async getActiveAccount({ type }) {
    return await apiService.fetchLoggedV3('user.accounts.list', {
      filter: {
        and: [{ eq: ['type', type] }, { eq: ['status', 'active'] }],
      },
      limit: 1,
    })
  }

  async getActualAccounts() {
    const response = await apiService.fetchLoggedV3('user.accounts.list', {
      filter: {
        ne: ['status', 'removed'],
      },
    })
    return _.get(response, 'prepared.accounts.items', [])
  }

  async addAccount({ type, value, code }) {
    return await apiService.fetchLoggedV3('user.accounts.add', { type, value, code })
  }

  async setPrimary(id) {
    await apiService.fetchLoggedV3('user.accounts.set_primary', { id })
  }

  async removeAccount(id) {
    await apiService.fetchLoggedV3('user.accounts.delete', { id })
  }
}

const service = new user()
export default service
