import React from 'react'

import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import PracticeIcon from '@library/practice/practiceIcon/PracticeIcon'
import Button from '@library/ui/button/Button'

import styles from './PracticeButton.module.scss'

const PracticeButton = ({
  imageUrl,
  practiceName,
  description = '',
  isActive,
  to,
  onClick,
  buttonText,
}) => {
  const { t } = useTranslation()

  const Tag = to ? Link : 'div'

  return (
    <Tag to={to} onClick={onClick} className={styles.practiceItem}>
      <PracticeIcon imageUrl={imageUrl} name={practiceName} isActive={isActive} />
      <div className={styles.text}>
        <div className={styles.label}>{practiceName}</div>
        {description && <div className={styles.meta}>{description}</div>}
      </div>
      <div className={styles.button}>
        <Button mode="white" className="btn-sm" label={buttonText || t('btn.open')} />
      </div>
    </Tag>
  )
}

export default PracticeButton
