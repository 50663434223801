import React from 'react'

import classNames from 'classnames'

import s from './PopoverViewer.module.scss'

const PopoverViewer = () => {
  return <div className={classNames('popover-viewer', s.popoverViewer)}></div>
}

export default PopoverViewer
