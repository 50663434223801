import React from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import CustomLink from '@library/ui/customLink/CustomLink'
import PatientPhotoAndName from '@library/ui/patientPhotoAndName/PatientPhotoAndName'

import { $main } from '@store'

import { customActions } from '@config/customActions'

import styles from './CocPatientHeader.module.scss'

const CocPatientHeader = ({ patient }) => {
  const { t } = useTranslation()

  return (
    <div className={styles.header}>
      <PatientPhotoAndName
        firstName={patient.firstName}
        lastName={patient.lastName}
        avatar={patient.avatar}
        photoSize='48'
        mobileView={false}
      />
      <div className={styles.buttonGroup}>
        {customActions.HANDLE_EDIT_PATIENT && (
          <CustomLink
            className={styles.button}
            autoTarget={customActions.HANDLE_EDIT_PATIENT({ patientId: patient.id })}
          >
            {t('btn.edit_profile')}
          </CustomLink>
        )}
      </div>
    </div>
  )
}

export default observer(CocPatientHeader)
