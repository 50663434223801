import React from 'react'

import { observer } from 'mobx-react'
import { Trans, useTranslation } from 'react-i18next'

import { confirmAction } from '@library/ActionConfirmator'
import Modal from '@library/modal/Modal'
import { $configure } from '@library/plugins/configure/configure.store'
import Button from '@library/ui/button/Button'

import { customExecute } from '@helpers/other'

import { customActions } from '@config/customActions'
import mainConfig from '@config/main'

const ConfigurePreviewModal = ({ _core }) => {
  const { t } = useTranslation()

  const requiredSteps = $configure.getRequiredSteps()

  function handleSubmit(e) {
    e.preventDefault()

    $configure.checkConfigure()
    _core.onHide()
  }

  function handleLogout() {
    confirmAction('customAlert', {
      data: {
        title: t('confirmator.logout.title'),
        message: t('confirmator.logout.message'),
      },
      callback: async () => {
        await customExecute(customActions.HANDLE_REJECT_PAPERWORK())
        _core.onHide()
      },
    })
  }

  return (
    <Modal centered scrollable closeOnOverlay={false}>
      <div className="modal-toolbar">
        <div className="modal-toolbar-btn modal-toolbar-btn--close" onClick={handleLogout} />
      </div>
      <Modal.Header>
        <div className="title-md text-lg-center">
          <Trans
            i18nKey="configure_preview.title"
            components={[<br className="d-none d-lg-block" />]}
          />
        </div>
      </Modal.Header>
      <Modal.Body>
        <form id="configure_preview" onSubmit={handleSubmit}>
          <p>{t('configure_preview.description')}</p>
          {requiredSteps.map((step, index) => (
            <p key={index}>
              {index + 1}.{' '}
              {t('configure_preview.steps.' + _.snakeCase(step.name), {
                appName: mainConfig.appName,
              })}
              {index === requiredSteps.length - 1 ? '.' : ';'}
            </p>
          ))}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          mode={['primary', 'block']}
          tag="button"
          buttonType="submit"
          form="configure_preview"
          label={t('configure_preview.btn.start')}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default observer(ConfigurePreviewModal)
