import React, { useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import TextareaBase from '@library/form/fields/new/TextareaBase'
import { useForm } from '@library/form/FormControllerNew'
import Modal from '@library/modal/Modal'
import Button from '@library/ui/button/Button'

import { $appts, $loader } from '@store'

const ApptFeedbackModal = ({ fullAppt, _core }) => {
  const { t } = useTranslation()

  const { appointment } = fullAppt

  const [text, setText] = useState('')

  const { form } = useForm({
    feedback: { value: text, rules: ['required'] },
  })
  const { feedback } = form.fields

  const handleSubmit = $loader.registerHandler('submit-feedback', async (e) => {
    e.preventDefault()

    if (form.validateAll().isValid) {
      let res = await $appts.sendFeedback(appointment.id, { feedback: feedback.value })

      if (res.isSuccess) {
        setText(feedback.value)
        _core.onHide()
      }
    }
  })

  return (
    <Modal centered scrollable>
      <div className="modal-toolbar">
        <div className="modal-toolbar-btn modal-toolbar-btn--close" onClick={_core.onHide} />
      </div>
      <Modal.Header>{t('appt_feedback.title')}</Modal.Header>
      <Modal.Body>
        <div className="mb-30">{t('appt_feedback.message')}</div>
        <form id="appt_feedback_modal" onSubmit={handleSubmit}>
          <TextareaBase
            label={t('appt_feedback.label.text')}
            rows={7}
            field={feedback}
            placeholder={t('appt_feedback.ph.text')}
            mode="lg"
            autoFocus={true}
          />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          tag="button"
          mode={['primary', 'block']}
          form="appt_feedback_modal"
          isLoading={$loader.isRunHandler('submit-feedback')}
        >
          {t('appt_feedback.btn.submit')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default observer(ApptFeedbackModal)
