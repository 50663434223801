import React from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import Toolbar from '@library/toolbar/Toolbar'

import PaymentMethodsList from '@pages/paymentMethods/PaymentMethodsList'

import { $pms } from '@store'

import { routeActions } from '@config/routeActions'

const PaymentMethods = () => {
  const { t } = useTranslation()

  const handleOnSelect = (method) => {
    // $pms.SET_DEFAULT_ID(method);
  }

  return (
    <div className="content">
      <Toolbar goBackProps={{ autoTarget: routeActions.HOME() }} />
      <div className="container">
        <div className="box">
          <div className="box-header">
            <div className="box-header-title title-lg mb-0">{t('pms.title')}</div>
          </div>
          <div className="box-body box-body--padding box--md">
            <PaymentMethodsList onSelect={handleOnSelect} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(PaymentMethods)
