import React, { useEffect, useState } from 'react'

import { observer } from 'mobx-react'

import withBookingDataInit from '@booking/library/withBookingDataInit/withBookingDataInit'
import BookingChat from '@booking/pages/bookingSymptoms/bookingChat/BookingChat'
import BookingSelectSymptoms from '@booking/pages/bookingSymptoms/bookingSelectSymptoms/BookingSelectSymptoms'

import { customGoTo } from '@helpers/router'

import { $booking } from '@store'

const BookingSymptoms = () => {
  const service = $booking.service
  const [isChat, setIsChat] = useState(service.hasSymptoms === false)

  useEffect(() => {
    setIsChat(service.hasSymptoms === false)
  }, [service.hasSymptoms])

  const breadcrumbs = ['place', 'service', 'patients']

  return isChat ? (
    <BookingChat
      setChat={setIsChat}
      skipSymptoms={service.hasSymptoms === false}
      breadcrumbs={breadcrumbs}
      onClickContinue={() => customGoTo($booking.schemaConfig?.nextStep())}
    />
  ) : (
    <BookingSelectSymptoms
      service={service}
      onClickNext={() => setIsChat(true)}
      breadcrumbs={breadcrumbs}
    />
  )
}

export default withBookingDataInit(observer(BookingSymptoms))
