import { routeActions as routeCoreActions } from '@core/src/config/routeActions'

import { routeActions as routeAppActions } from '@appNoBrand/config/routeActions'

import { toPath } from '@helpers/router'

// () => ({to, href, wvAction, onClick})
export const routeActions = {
  ...routeAppActions,
  ROOT: routeCoreActions.BRAND_ROOT,
  APPTS: () => ({
    to: toPath('home'),
  }),
  BOOK_START: () => ({
    to: toPath('bookServiceType'),
  }),
}
