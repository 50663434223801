import React from 'react'

import { Provider } from 'mobx-react'
import { observer } from 'mobx-react'

import { $modal } from '@store'

const ModalViewer = () => {
  let modals = []

  _.forEach($modal.current, (item) => {
    const template = item._core.template

    if (_.isObject(template)) {
      modals.push({ component: template, item })
    } else {
      console.error('The template "' + template + '" is missing.')
      return
    }
  })

  return (
    <div className="modal-root">
      {modals.map((modal) => {
        const Component = modal.component

        return (
          <Provider key={modal.item._core.id} _core={modal.item._core}>
            <Component {...modal.item} />
          </Provider>
        )
      })}
    </div>
  )
}

export default observer(ModalViewer)
