var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { isRouteByArea } from '@helpers/router';
import { $loader, $main } from '@store';
import styles from './RightSidebar.module.scss';
var constants = {
    MAX_WIDTH: 480,
    MIN_WIDTH: 250,
    CONTENT_MIN_WIDTH: 790,
};
export var RIGHT_SIDEBAR_LOADER_KEY = 'right-sidebar-options-updated';
var Sidebar = observer(function (_a) {
    var config = _a.config, payload = _a.payload, isVisible = _a.isVisible, elements = _a.elements, removeElementsStyles = _a.removeElementsStyles;
    var prevOptions = useRef();
    var sidebarUpdated = $loader.registerHandler(RIGHT_SIDEBAR_LOADER_KEY, function () { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        return [2 /*return*/];
    }); }); });
    var sidebar = useMemo(function () {
        var _a = config.maxWidth, maxWidth = _a === void 0 ? constants.MAX_WIDTH : _a, _b = config.minWidth, minWidth = _b === void 0 ? constants.MIN_WIDTH : _b;
        return {
            maxWidth: minWidth > maxWidth ? minWidth : maxWidth,
            minWidth: minWidth,
        };
    }, [config]);
    var options = useMemo(function () {
        var wrapper = elements.wrapper, contents = elements.contents, toolbar = elements.toolbar;
        if (!wrapper || !(contents === null || contents === void 0 ? void 0 : contents.length)) {
            return prevOptions.current ? prevOptions.current : null;
        }
        if (isVisible) {
            removeElementsStyles();
        }
        var setContentWidth = function (maxWidth, margin, toolbarWidth) {
            if (isVisible) {
                contents.forEach(function (element) {
                    element.style.maxWidth = maxWidth + 'px';
                    element.style.marginRight = margin + 'px';
                    element.style.marginLeft = margin + 'px';
                });
                if (toolbar) {
                    toolbar.style.maxWidth =
                        toolbarWidth !== undefined ? toolbarWidth + 'px' : maxWidth + 'px';
                }
            }
        };
        var wrapperRect = wrapper.getBoundingClientRect();
        var contentRect = contents[0].getBoundingClientRect();
        var availableWidth = wrapperRect.width - contentRect.width;
        // FIRST CASE - there is free space for sidebar
        if (availableWidth >= sidebar.maxWidth) {
            setContentWidth(null, (availableWidth - sidebar.maxWidth) / 2, wrapperRect.width - sidebar.maxWidth);
            sidebarUpdated();
            return { type: 'sidebar', width: sidebar.maxWidth };
        }
        availableWidth = wrapperRect.width - constants.CONTENT_MIN_WIDTH;
        // SECOND CASE - there is no free space even with minimum content width
        var result;
        if (availableWidth < sidebar.minWidth) {
            setContentWidth(null, null);
            result = { type: 'modal' };
        }
        // THIRD CASE - there is free space with calculated content width
        else if (availableWidth > sidebar.maxWidth) {
            setContentWidth(constants.CONTENT_MIN_WIDTH + (availableWidth - sidebar.maxWidth), 0);
            result = { type: 'sidebar', width: sidebar.maxWidth };
        }
        // FOURTH CASE - there is available space with minimum content width
        else {
            setContentWidth(constants.CONTENT_MIN_WIDTH, 0);
            result = { type: 'sidebar', width: availableWidth };
        }
        sidebarUpdated();
        return result;
    }, [elements, $main.windowSize.width, isVisible]);
    prevOptions.current = options;
    if (!options) {
        return null;
    }
    if (options.type === 'modal') {
        return (_jsx("div", __assign({ onClick: function () { return $main.hideRightSidebar(); }, className: classNames(styles.modal, isVisible && styles.modalVisible) }, { children: _jsx("div", __assign({ className: styles.container, onClick: function (e) { return e.stopPropagation(); } }, { children: React.cloneElement(config.component, payload) })) })));
    }
    return (_jsx("div", __assign({ style: {
            bottom: config.bottom,
            width: options.width,
            maxWidth: sidebar.maxWidth,
            minWidth: sidebar.minWidth,
        }, className: classNames(styles.container, isVisible && styles.containerVisible) }, { children: React.cloneElement(config.component, payload) })));
});
var RightSidebar = function (_a) {
    var config = _a.config, _b = _a.dependencies, dependencies = _b === void 0 ? [] : _b;
    var isVisible = !isRouteByArea('hideRightSidebar');
    var _c = useState({
        wrapper: null,
        contents: null,
        toolbar: null,
    }), elements = _c[0], setElements = _c[1];
    useEffect(function () {
        setTimeout(function () {
            var toolbar = document.querySelector('#toolbar');
            var wrapper = document.querySelector('.content-app-wrapper');
            var contents = wrapper === null || wrapper === void 0 ? void 0 : wrapper.querySelectorAll('.container');
            setElements({ wrapper: wrapper, contents: contents, toolbar: toolbar });
        }, 0);
    }, __spreadArray([config, $main.pageIsLoaded], dependencies, true));
    var removeAppliedContentStyles = function () {
        var toolbar = elements.toolbar, contents = elements.contents;
        if (toolbar) {
            toolbar.style.maxWidth = null;
        }
        contents === null || contents === void 0 ? void 0 : contents.forEach(function (element) {
            element.style.maxWidth = null;
            element.style.marginLeft = null;
            element.style.marginRight = null;
        });
    };
    useEffect(function () {
        if (!$main.rightSidebar.active) {
            removeAppliedContentStyles();
        }
    }, [$main.rightSidebar.active]);
    return (_jsx(_Fragment, { children: _.map($main.rightSidebar.payload, function (item, key) {
            return config[key] ? (_jsx(Sidebar, { payload: item, config: config[key], isVisible: isVisible && $main.rightSidebar.active === key, removeElementsStyles: removeAppliedContentStyles, elements: elements }, key)) : null;
        }) }));
};
export default observer(RightSidebar);
