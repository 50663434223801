import queryString from 'query-string'

import { history } from '@helpers/router'

import { $user } from '@store'

export function getPreparedPatientId() {
  let id = history.match.params.patientId

  if (!id) {
    const queryParams = queryString.parse(history.location.search)
    id = queryParams.patientId
  }

  return id
}

export function getPreparedPracticeId() {
  let id = history.match.params.practiceId

  if (!id) {
    const queryParams = queryString.parse(history.location.search)
    id = queryParams.practiceId
  }

  if (!id) {
    id = $user.role?.practiceId
  }

  return id
}
