import React, { useEffect } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams, useRouteMatch } from 'react-router-dom'

import { useIntervalTimer } from '@library/CustomHooks'
import Outlet from '@library/Outlet'
import Toolbar from '@library/toolbar/Toolbar'

import ApptConference from '@pages/appt/apptConference/ApptConference'
import ApptContacts from '@pages/appt/apptContacts/ApptContacts'
import ApptControl from '@pages/appt/apptControl/ApptControl'
import ApptDesc from '@pages/appt/apptDesc/ApptDesc'
import ApptDetails from '@pages/appt/apptDetails/ApptDetails'
import ApptMap from '@pages/appt/apptMap/ApptMap'
import ApptOfficeNote from '@pages/appt/apptOfficeNote/ApptOfficeNote'
import ApptStatus from '@pages/appt/apptStatus/ApptStatus'
import PatientsList from '@pages/appt/patientsList/PatientsList'

import hAppt from '@helpers/hAppt'
import { customGoTo } from '@helpers/router'

import { $appts, $main } from '@store'

import { AUTO_UPDATE_BY_ROUTE } from '@config/apptsCore'
import { routeActions } from '@config/routeActions'
import { PATIENT_GROUP_TYPES } from '@config/services'

const Appt = ({ routeConfig, hasVideo, hasOfficeNote }) => {
  const { t } = useTranslation()
  let { path } = useRouteMatch()
  const location = useLocation()
  const { id: appointmentId } = useParams()

  const { fullAppt, status, actions } = $appts.byRoute
  const { appointment, encounters, patients } = fullAppt

  useEffect(() => {
    const nestedEncounterIsNotOpened = () => location.pathname.indexOf('encounter/') <= 0

    if (
      appointment?.id === +appointmentId && // $appts.byRoute might be updated later than navigation happened, redirect onlly if updated
      !_.isEmpty(encounters) &&
      nestedEncounterIsNotOpened()
    ) {
      customGoTo(
        routeActions.APPT({
          appointmentId: appointment.id,
          encounterIds: appointment.encounterIds,
        }),
        { type: 'replace' },
      )
    }
  }, [encounters, appointmentId, appointment]) // eslint-disable-line react-hooks/exhaustive-deps

  useIntervalTimer({
    callback: async () => {
      const res = await $appts.getOne({ id: appointmentId })

      if (!res.isSuccess) {
        customGoTo(routeActions.APPTS())
      }
    },
    duration: AUTO_UPDATE_BY_ROUTE,
    autoStart: !status.isPast,
    deps: [appointmentId],
  })

  const isShowVideo =
    hasVideo && !$main.isMobileOnly && actions.startVideo && status.innerVideoStarted

  if (!appointment) {
    return <></>
  }

  const groupType = hAppt.getGroupTypeKey(appointment, patients)

  return (
    <div className="content">
      <Toolbar goBackProps={{ autoTarget: routeActions.APPTS() }} />
      <div className="container container-overflow-x-hidden h-100">
        <div className="box box--full-height appt_details_root">
          <div className="box-header">
            <ApptStatus />
          </div>
          <div className="box-body box-body--padding">
            {isShowVideo ? (
              <>
                <div className="appt_video_target embed-responsive embed-responsive-16by9" />
                <ApptControl />
              </>
            ) : (
              <>
                <ApptMap />
              </>
            )}
            <ApptDesc />
            <ApptDetails />
            {hasOfficeNote && <ApptOfficeNote />}
            {hasVideo && <ApptConference />}
            <ApptContacts />
            <div className="title-md mb-10">{t(`appt_type.${groupType}`)}</div>
            {groupType !== PATIENT_GROUP_TYPES.individual && !_.isEmpty(patients) && (
              <PatientsList appointment={appointment} patients={patients} />
            )}
            <Outlet basePath={path} routesList={routeConfig.children} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(Appt)
