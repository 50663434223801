import { makeAutoObservable, reaction, runInAction } from 'mobx'

import moment from 'moment-timezone'

import clientService from '@services/client'

import hAppt from '@helpers/hAppt'
import { isRouteByArea } from '@helpers/router'

import { $user } from '@store'

import mainConfig from '@config/main'

class ApptsStore {
  constructor() {
    makeAutoObservable(this)
  }

  activeList = []
  pastList = []

  byPatient = {
    activeList: [],
    pastList: [],
  }

  byRoute = {
    fullAppt: {},
    statuses: [],
    status: {},
    actions: {},
  }

  documentTemplates = {}
  documentsAccessToken = ''
  nearestBarHeight = 0

  clear() {
    this.activeList = []
    this.pastList = []

    this.byPatient = {
      activeList: [],
      pastList: [],
    }

    this.byRoute = {
      fullAppt: {},
      statuses: [],
      status: {},
      actions: {},
    }

    this.documentTemplates = {}
    this.documentsAccessToken = ''
    this.nearestBarHeight = 0
  }

  // Computed
  get getNearest() {
    if (this.activeList.length) {
      let fullAppt
      let status

      _.find(this.activeList, (x, i) => {
        status = hAppt.getCurrentStatus({ fullAppt: x })

        if (!status.isHasClientConfirmation) {
          return (fullAppt = this.activeList[i])
        }
      })
      if (fullAppt) {
        return { fullAppt, status }
      }
    }
    return false
  }

  get nearestBarIsVisible() {
    const nearestId = this.getNearest?.fullAppt?.appointment.id
    if (!nearestId) return false

    return !isRouteByArea('hideNearestBar')
    // temporarily disabled
    // todo fix change appointmentId
    // return !isRouteByArea('hideNearestBar', { id: nearestId })
  }

  // Mutations
  SET_BY_ROUTE(value) {
    this.byRoute = value
  }

  SET_NEAREST_BAR_HEIGHT(value) {
    this.nearestBarHeight = value
  }

  // Actions
  async get({ type, patientId, page, fromDate, toDate } = {}) {
    if (!type) return { isSuccess: false }

    let pageParams = {}

    if (page) {
      pageParams = {
        limit: mainConfig.apptsPageLimit,
        offset: mainConfig.apptsPageLimit * (page - 1),
      }
    }

    const res = await clientService.getAppointments({
      type,
      patientId,
      fromDate,
      toDate,
      ...pageParams,
    })
    const prepared = _.get(res, 'prepared', [])

    runInAction(() => {
      function _update(items = [], newItems = []) {
        if (!_.isEmpty(pageParams) && page > 1) {
          return [...items, ...newItems]
        } else {
          return newItems
        }
      }

      if (patientId) {
        if (type === 'active') {
          this.byPatient.activeList = _update(this.byPatient.activeList, prepared)
        } else if (type === 'past') {
          this.byPatient.pastList = _update(this.byPatient.pastList, prepared)
        }
      } else {
        if (type === 'active') {
          this.activeList = _update(this.activeList, prepared)
        } else if (type === 'past') {
          this.pastList = _update(this.pastList, prepared)
        }
      }
    })

    return { isSuccess: !_.isEmpty(prepared), items: prepared }
  }

  async getActive({ patientId, page, fromDate, toDate } = {}) {
    return this.get({ type: 'active', patientId, page, fromDate, toDate })
  }

  async getPast({ patientId, page, fromDate, toDate } = {}) {
    if (!fromDate) {
      fromDate = moment.utc().subtract(1, 'years').format('YYYY-MM-DDTHH:mm:ss')
    }
    return this.get({ type: 'past', patientId, page, fromDate, toDate })
  }

  async getOne({ id, setByRoute = true, expand }) {
    const res = await clientService.getOneAppointment({ id, skipAlert: true, expand })
    let prepared = _.get(res, 'prepared', {})

    const isSuccess = !_.isEmpty(prepared)

    if (isSuccess) {
      if (setByRoute) {
        if (!_.isEmpty(prepared.encounters)) {
          const documentTemplates = await clientService.getAppointmentPatientForms(
            Object.keys(prepared.encounters),
          )
          runInAction(
            () => (this.documentTemplates = { ...this.documentTemplates, ...documentTemplates }),
          )
        }
        prepared = hAppt.extendFullAppt(prepared)
        this.SET_BY_ROUTE(prepared)
      } else {
        prepared = hAppt.extendFullAppt(prepared)
      }
    }

    return { isSuccess, item: prepared }
  }

  async checkOne({ id }) {
    let res = await clientService.getOneAppointment({
      id,
      skipAlert: true,
      expand: {},
    })
    let prepared = _.get(res, 'prepared', {})

    return { isSuccess: !_.isEmpty(prepared) }
  }

  async getDocumentsAccessToken(encounterId) {
    const res = await clientService.getDocumentsAccessToken({ encounterId })
    const prepared = _.get(res, 'prepared.accessToken', '')

    runInAction(() => (this.documentsAccessToken = prepared))

    return { isSuccess: !_.isEmpty(prepared), documentsAccessToken: prepared }
  }

  //
  // Role Actions
  //
  async callOffice({ appointmentId, buttonId }) {
    const res = await clientService.callOfficeAppointment({ appointmentId, buttonId })
    const isSuccess = res && res.prepared

    return { isSuccess }
  }

  async cancel(id) {
    const res = await clientService.cancelAppointment({ id })

    if (res && res.prepared) {
      await this.getOne({ id })
      await this.getActive()
      return { isSuccess: true }
    }

    return { isSuccess: false }
  }

  async complete({ id, ok }) {
    const res = await clientService.completeAppointment({ id, ok })

    if (res && res.prepared) {
      await this.getActive()
      await this.getOne({ id })
      return { isSuccess: true }
    }

    return { isSuccess: false }
  }

  async readyForConference({ id }) {
    const res = await clientService.readyForConference({ id })

    if (res && res.prepared) {
      await this.getOne({ id })
      return { isSuccess: true }
    }

    return { isSuccess: false }
  }

  async sendFeedback(id, { feedback }) {
    const res = await clientService.sendFeedbackAppointment({ id, feedback })

    if (res && res.prepared) {
      await this.getOne({ id })
      return { isSuccess: true }
    } else {
      return { isSuccess: false }
    }
  }

  async sendSurvey(id, { survey }) {
    const res = await clientService.sendSurveyAppointment({ id, survey })

    if (res && res.prepared) {
      await this.getOne({ id })
      return { isSuccess: true }
    } else {
      return { isSuccess: false }
    }
  }
}

const store = new ApptsStore()

// update active appointments when login/logout
reaction(
  () => $user.user,
  async (val) => {
    if (val === true) {
      await store.getActive()
    } else {
      store.clear()
    }
  },
)

export default store
