import { makeAutoObservable, reaction, runInAction } from 'mobx'

import { setRG } from '@common/analytics/raygun'
import { analytics } from '@common/analytics/zipAnalytics'

import authService from '@services/auth'
import userService from '@services/user'

import { $configure } from '@library/plugins/configure/configure.store'

import { localStore } from '@helpers/other'

import { $accounts, $main, $notices, $user } from '@store'

import mainConfig from '@config/main'

class authStoreCore {
  constructor() {
    makeAutoObservable(this)
  }

  isInit = true
  isBound = null
  forcedAccessToken = null
  logged = localStore.get('loggedInfo', {})
  accounts = []
  needCaptcha = false

  // Computed
  get currentLogged() {
    return this.findLoggedById({ roleId: this.logged.currentRoleId })
  }

  get getAccountPhone() {
    const items = $accounts.items.length ? $accounts.items : this.accounts
    const acc = _.find(items, (x) => x.type === 'phone')
    return _.get(acc, 'value', '')
  }

  get getAccountEmail() {
    const items = $accounts.items.length ? $accounts.items : this.accounts
    const acc = _.find(items, (x) => x.type === 'email')
    return _.get(acc, 'value', '')
  }

  // Mutations
  SET_IS_INIT(value) {
    runInAction(() => (this.isInit = value))
  }

  SET_FORCED_ACCESS_TOKEN(value) {
    runInAction(() => (this.forcedAccessToken = value))
  }

  PUSH_ACCOUNT(value) {
    const index = _.findIndex(this.accounts, (x) => x.id === value.id)

    if (index === -1) {
      this.accounts = [...this.accounts, value]
    } else {
      let tmp = _.cloneDeep(this.accounts)
      tmp[index] = value
      this.accounts = tmp
    }
  }

  SET_NEED_CAPTCHA(value) {
    runInAction(() => (this.needCaptcha = value))
  }

  // Actions
  findLoggedById({ roleId, practiceId }) {
    if (practiceId) {
      return _.find(this.logged, (x) => x.role && x.role.practiceId === practiceId)
    }
    return this.logged['forRole_' + roleId]
  }

  async getAccessToken({ roleId = 0, practiceId = 0, changeCurrentRole = false } = {}) {
    if (this.forcedAccessToken) {
      return this.forcedAccessToken
    }

    if (!this.logged?.sessionToken) {
      return false
    }

    const isSpecified = Boolean(roleId || practiceId)
    let loggedForRole

    if (isSpecified) {
      loggedForRole = this.findLoggedById({ roleId, practiceId })
    } else {
      loggedForRole = this.currentLogged
    }

    if (loggedForRole && loggedForRole.session) {
      const now = Date.now() / 1000
      const lastUsed = loggedForRole.lastUsed / 1000
      const isValid = now - lastUsed < loggedForRole.accessTokenTimeout - 10

      if (isValid) {
        return loggedForRole.accessToken
      }
    }

    let isRefreshed

    if (isSpecified) {
      isRefreshed = await this.refresh({
        roleId: loggedForRole?.role?.id || roleId,
        practiceId,
        changeCurrentRole,
      })
    } else {
      isRefreshed = await this.refresh()
    }

    return isRefreshed && isRefreshed.accessToken
  }

  async signout(deleteSession = true) {
    if (this.logged && deleteSession) {
      await authService.signout()
    }

    runInAction(() => {
      $user.clear()
      this.forcedAccessToken = null
      this.logged = {}
    })
  }

  async refresh({ roleId, practiceId, changeCurrentRole = true } = {}) {
    if (!roleId && practiceId) {
      const foundRole =
        _.find($user.roles, (x) => x.practiceId === practiceId && x.role === mainConfig.appType) ||
        {}
      roleId = foundRole.id
    }

    const response = await authService.refresh({ sessionToken: this.logged.sessionToken, roleId })

    if (response.error || !this.isRoleExist(response.prepared)) {
      await this.signout(false)
      return false
    }

    await this._fillData(response, changeCurrentRole)
    return response.prepared
  }

  async _fillData(response, changeCurrentRole = true) {
    const prepared = response.prepared

    let data = {
      ...this.logged,
      userId: prepared.user.id,
      sessionToken: prepared.sessionToken,
      sessionTokenTimeout: prepared.sessionTokenTimeout,
      ['forRole_' + prepared.role.id]: {
        accessToken: prepared.accessToken,
        accessTokenTimeout: prepared.accessTokenTimeout,
        session: prepared.session,
        account: prepared.account,
        role: prepared.role,
        lastUsed: Date.now(),
      },
    }

    if (changeCurrentRole) {
      data.currentRoleId = prepared.role.id
    }

    this.logged = data

    this.PUSH_ACCOUNT(prepared.account)

    $user.user = true
    $user.isLoading = false

    if (changeCurrentRole) {
      $user.info = response.prepared.user

      if (!_.isEmpty(prepared.roles?.items)) {
        $user.roles = prepared.roles.items
      }
      if (!_.isEmpty(prepared.practices)) {
        $user.practices = prepared.practices
      }

      if (this.isBound !== false && this.isRoleExist(response.prepared)) {
        await $user.refreshProfile({
          // need this only for provider to get profile data from
          ...prepared.role,
          avatar: prepared.user.avatar,
          gender: prepared.user.gender,
        })
      }
    }
  }

  async sendCode(params, { setIsBound = false, skipAlert = false } = {}) {
    const res = await authService.requestCode(params, skipAlert)

    if (setIsBound) {
      runInAction(() => (this.isBound = _.get(res, 'prepared.isBound', false)))
    }

    return res
  }

  async loginByCode({ type, value, code }) {
    let response

    if (this.isBound) {
      response = await authService.login({ type, value, code })
    } else {
      response = await authService.signup({ type, value, code })
    }

    return await this.#prepareAuthResponse(response)
  }

  async loginByToken(token, extraParams) {
    const response = await authService.loginByToken(token, extraParams)
    return await this.#prepareAuthResponse(response)
  }

  async #prepareAuthResponse(response) {
    if (response.error) {
      return response
    }

    if (!this.isRoleExist(response.prepared)) {
      await this.signout()

      delete response.prepared
      response.error = { code: 'account_role' }

      return response
    }

    await this._fillData(response)
    await this.loadInitData()

    return response
  }

  async loadInitData() {
    await Promise.all([
      this.loadAnotherAccount(),
      $main.loadSettings(), //
    ])
    await $configure.init()
    await $notices.getHistory()
  }

  async loadAnotherAccount() {
    const current = _.get(this.currentLogged, 'account')

    if (current && !_.isEmpty(current)) {
      const type = current.type === 'phone' ? 'email' : 'phone'

      const response = await userService.getActiveAccount({ type })
      const account = _.get(response, 'prepared.accounts.items.0')

      if (account) {
        this.PUSH_ACCOUNT(account)
      }
    }
  }

  clear() {
    this.isBound = null
    this.accounts = []
  }

  isRoleExist({ role = {}, roles = [] }) {
    if (role.role === mainConfig.appType) {
      return true
    }

    const result = _.find(roles, (x) => x.role === mainConfig.appType)

    return Boolean(result)
  }
}

const store = new authStoreCore()

reaction(
  () => JSON.stringify(store.logged),
  (logged) => {
    const parsed = JSON.parse(logged)

    if (!_.isEmpty(parsed)) {
      setRG('setUser', {
        identifier: $user.info?.id,
        isAnonymous: false,
        fullName: $user.info?.name,
      })
      analytics.startSession($user.info?.id, {})
      localStore.set('loggedInfo', parsed)
    } else {
      setRG('setUser', {
        isAnonymous: true,
      })
      analytics.endSession()
      localStore.remove('loggedInfo')
      $accounts.clear()
      store.clear()
    }
  },
)

export default store
