import React, { useLayoutEffect } from 'react'

import classNames from 'classnames'

import { useChangeScrollDirection, useFirstRender, useInnerValue } from '@library/CustomHooks'

import { $main } from '@store'

import './RadioTabs.scss'

const RadioTabs = ({ value = '', setValue, items = [], mode = 'default', className, size }) => {
  const ref = useChangeScrollDirection()
  const isFirstRender = useFirstRender()

  const [innerValue, setInnerValue] = useInnerValue(value)

  function handleOnClick(id) {
    setInnerValue(value)

    if (_.isFunction(setValue)) {
      setValue(id)
    }
  }

  useLayoutEffect(() => {
    if ($main.isMobileSize && ref.current && !isFirstRender) {
      // if mobile - scroll selected btn into view
      const scrollContainer = ref.current
      const activeBtn = scrollContainer.querySelector('.radio_tabs-item--active')
      if (activeBtn) {
        const isLastBtn =
          scrollContainer.childNodes.length ===
          Array.from(scrollContainer.childNodes).indexOf(activeBtn) + 1
        activeBtn.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: isLastBtn ? 'nearest' : 'center',
        })
      }
    }
  }, [ref, innerValue]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classNames('radio_tabs', 'radio_tabs--' + mode, className)}>
      <div className="radio_tabs-scroll" ref={ref}>
        {items.map((tab, index) => (
          <div
            key={tab.id + index}
            className={classNames(
              'radio_tabs-item',
              tab.id === innerValue && 'radio_tabs-item--active',
              tab.isDisabled && 'radio_tabs-item--disabled',
              size && 'radio_tabs-item--' + size,
              tab.className && tab.className,
            )}
            onClick={() => handleOnClick(tab.id)}
          >
            {tab.text}
            {!_.isUndefined(tab.count) && (
              <span className="radio_tabs-item-count">{tab.count}</span>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default RadioTabs
