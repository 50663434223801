var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import UserPhoto from '@library/user/UserPhoto';
import { getUserText } from '@helpers/user';
import { $main } from '@store';
import styles from './PatientPhotoAndName.module.scss';
var PatientPhotoAndName = function (_a) {
    var firstName = _a.firstName, lastName = _a.lastName, fullName = _a.fullName, avatar = _a.avatar, _b = _a.withAvatar, withAvatar = _b === void 0 ? true : _b, photoSize = _a.photoSize, moreInfo = _a.moreInfo, _c = _a.mobileView, mobileView = _c === void 0 ? true : _c;
    var photoData = { firstName: firstName, lastName: lastName, name: fullName, avatar: avatar };
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: classNames(styles.content, mobileView && styles.mobileView) }, { children: [withAvatar && (_jsx(UserPhoto, { item: photoData, size: photoSize ? photoSize : "".concat($main.windowSize.maxLG ? 100 : 48) })), _jsxs("div", __assign({ className: styles.textContainer }, { children: [_jsx("div", __assign({ className: classNames('title-lg', styles.text, moreInfo && styles.withMoreInfo) }, { children: firstName && lastName ? getUserText({ firstName: firstName, lastName: lastName }, ['fullName']) : fullName })), moreInfo && _jsx("div", __assign({ className: styles.moreInfoText }, { children: moreInfo }))] }))] })) }));
};
export default observer(PatientPhotoAndName);
