import axios from 'axios'
import i18n from 'i18next'

import { setRG } from '@common/analytics/raygun'

import { customMessages, skipAlertErrorCodes } from '@services/api.helpers'

import { prepareRequestData, prepareResponseData } from '@helpers/api'
import { waitForVariable } from '@helpers/async'
import { convertArrayValuesToSnake, convertKeysToSnake } from '@helpers/other'

import { $alerts, $auth } from '@store'

import mainConfig from '@config/main'

function getCustomMessages(code, data = {}) {
  let found = []

  _.forEach(customMessages, (item) => {
    if (code !== item.code) {
      return
    }

    let all = true

    _.forEach(item.data, (x, k) => {
      if (data[k] !== x) {
        all = false
        return false
      }
    })

    if (all) {
      found.push(item)
    }
  })

  return found
}

function getCustomMessage(code, data = {}) {
  let item = _.first(getCustomMessages(code, data))
  if (item?.msg) {
    item.msg = i18n.exists(item.msg) ? i18n.t(item.msg) : item.msg
  }
  return item
}

class base {
  requestId = 0
  apiVersion = [2, 7]
  apiVersionV3 = [3, 0]

  getRequestParams(method, payload, accessToken, apiVersion = this.apiVersion) {
    payload = convertKeysToSnake(payload)

    let meta = {
      version: apiVersion,
    }

    if (accessToken) {
      meta.access_token = accessToken
    }

    if (payload.expand) {
      meta.expand = convertArrayValuesToSnake(payload.expand)
      delete payload.expand
    }

    return {
      id: ++this.requestId,
      method: method,
      meta: meta,
      params: prepareRequestData(method, payload),
    }
  }

  checkResponseErrors(requestParams, response, skipAlert) {
    const code = _.get(response, 'error.code')
    const message = _.get(response, 'error.message')

    let result = {}

    if (message) {
      const data = _.get(response, 'error.data')
      const customMessage = getCustomMessage(code, data)

      result.msg = customMessage?.msg
      result.code = code

      if (!result.msg) {
        let reason = _.get(data, 'reason')

        if (_.isObject(reason)) {
          reason = _.toPairs(reason).map((x) => x.join(': '))
        }

        const condition = _.get(data, 'condition')

        if (reason) {
          result.msg = reason
        } else {
          result.msg = [message, condition].filter((m) => !_.isEmpty(m)).join(', ')
        }

        result.code = code
        result.message = message
        result.reason = reason
        result.condition = condition
      }

      if (result.msg) {
        result.analyticsMessage = [
          customMessage?.msgPrefix,
          requestParams.method + ' - ',
          result.msg,
        ]
          .filter((x) => x)
          .join('')
      }

      if (result.msg && !skipAlert && !skipAlertErrorCodes.includes(code)) {
        $alerts.add(result.msg)
      }
    }

    return result
  }

  withPreparedResponse(response) {
    if (response.result) {
      response.prepared = prepareResponseData(response.result)
    }

    if (!response.hasOwnProperty('prepared') && !response.error) {
      response.prepared = []
    }

    return response
  }

  async fetch(method = '', payload = {}, options = {}) {
    const { accessToken, skipAlert = false, prepareResult = true, apiVersion } = options

    const requestParams = this.getRequestParams(method, payload, accessToken, apiVersion)

    let url = mainConfig.api.baseUrl
    url += '?' + method

    let response = {}

    if (['user.auth.request_code', 'test2.user.auth.request_code'].includes(method)) {
      try {
        await waitForVariable('AwsWafIntegration')
        const res = await window.AwsWafIntegration.fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestParams),
        })
        $auth.SET_NEED_CAPTCHA(false)
        const jsonRes = await res.json()
        response = jsonRes || {}
      } catch (e) {
        if (e && e.code) {
          response.error = { code: e.code, message: e.message }
        } else {
          response.error = { code: 'error.auth.high_rate_captcha', message: '' }
          $auth.SET_NEED_CAPTCHA(true)
        }
      }
    } else {
      try {
        const res = await axios.post(url, requestParams)
        response = res.data || {}
      } catch (e) {
        response.error = {
          code: e.code || 'error.catch.internal',
          message: e.message,
        }
      }
    }

    const error = this.checkResponseErrors(requestParams, response, skipAlert)

    if (error.analyticsMessage) {
      setRG('send', {
        error: error.analyticsMessage,
        customData: { request: requestParams, response: response },
      })
    }

    if (prepareResult) {
      response = this.withPreparedResponse(response)
    }

    return response
  }

  async fetchV3(method = '', payload = {}, options = {}) {
    return this.fetch(method, payload, { ...options, apiVersion: this.apiVersionV3 })
  }

  async fetchBatch(requestsData = [], options = {}) {
    const { accessToken, skipAlert = false, prepareResult = true, apiVersion } = options

    if (!requestsData.length) {
      return {}
    }

    const batchParams = []
    let methods = []

    requestsData.forEach(({ method, params }) => {
      batchParams.push(this.getRequestParams(method, params, accessToken, apiVersion))
      methods.push(method)
    })

    let url = mainConfig.api.baseUrl
    url += '?batch__' + _.uniq(methods).join(',')

    const res = await axios.post(url, batchParams)
    let result = res.data || []

    result.forEach((response, index) => {
      const requestParams = batchParams[index]
      const requestDataItem = requestsData[index]

      const skip = _.isBoolean(requestDataItem.skipAlert) ? requestDataItem.skipAlert : skipAlert
      const error = this.checkResponseErrors(requestParams, response, skip)

      if (error.analyticsMessage) {
        setRG('send', {
          error: error.analyticsMessage,
          customData: { batchIndex: index, request: requestParams, response: response },
        })
      }

      if (prepareResult) {
        response = this.withPreparedResponse(response)
      }

      result[index] = response
    })

    return result
  }

  async fetchLogged(method, payload, options) {
    const accessToken = await $auth.getAccessToken(
      _.pick(options, ['roleId', 'practiceId', 'changeCurrentRole']),
    )

    if (!accessToken) {
      return false
    }

    return await this.fetch(method, payload, { ...options, accessToken })
  }

  async fetchLoggedV3(method, payload, options) {
    return this.fetchLogged(method, payload, { ...options, apiVersion: this.apiVersionV3 })
  }

  async fetchLoggedBatch(requestsData, options) {
    const accessToken = await $auth.getAccessToken(
      _.pick(options, ['roleId', 'practiceId', 'changeCurrentRole']),
    )

    if (!accessToken) {
      return false
    }

    return await this.fetchBatch(requestsData, { ...options, accessToken })
  }

  async fetchLoggedBatchV3(requestsData, options) {
    return this.fetchLoggedBatch(requestsData, { ...options, apiVersion: this.apiVersionV3 })
  }

  async getBuildJson() {
    const response = await axios.get(
      `${process.env.PUBLIC_URL}/.build.json?timestamp=${new Date().getTime()}`,
      {
        headers: {
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
          Expires: '0',
        },
      },
    )

    return response.data
  }

  async getAvailableMethodsList(list = [], apiVersion) {
    const response = await axios.post(mainConfig.api.baseUrl, {
      id: ++this.requestId,
      method: 'list_methods',
      meta: {
        version: apiVersion || this.apiVersionV3,
      },
    })
    let items = response.data?.result

    if (items && list.length > 0) {
      items = items.filter((x) => _.includes(list, x.name))
    }

    return items
  }

  async fetchUniversal(url, payload = {}, options = {}) {
    const {
      method = 'post',
      skipAlert = false,
      prepareResult = true,
      convertKeys = true,
      ...axiosConfig
    } = options

    if (convertKeys) {
      payload = convertKeysToSnake(payload)
    }

    const requestData = {
      ...prepareRequestData(url, payload),
    }

    let responseData = {}

    try {
      const response = await axios({ method, url, data: requestData, ...axiosConfig })
      responseData = response.data
    } catch (err) {
      responseData = err.response?.data || err
    }

    if (responseData.detail) {
      const detail = responseData.detail

      let messages = []
      let message = ''

      if (_.isString(detail)) {
        messages.push(detail)
      } else if (_.isObject(detail)) {
        _.forEach(detail, (x) => {
          let tmp = []
          tmp.push(_.capitalize(x.msg))

          if (_.isArray(x.loc)) {
            tmp.push(x.loc.filter((x) => x !== 'body').join('.'))
          }

          messages.push(tmp.join(': '))
        })

        message = messages.join(', ')
      }

      if (!skipAlert) {
        $alerts.add(message)
      }

      setRG('send', {
        error: message,
        customData: { request: requestData, response: responseData },
      })

      responseData.error = {
        detail: responseData.detail,
        message: message,
      }
    }

    let result = {}

    if (prepareResult) {
      if (responseData.error) {
        result.error = responseData.error
      } else if (!_.isEmpty(responseData)) {
        result.original = responseData
        result.prepared = prepareResponseData(responseData)
      }
      if (!result.hasOwnProperty('prepared') && !responseData.hasOwnProperty('error')) {
        result.prepared = []
      }
    }

    return result
  }
}

const service = new base()
export default service
