import React, { useEffect } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import PageNoResults from '@library/pageNoResults/PageNoResults'

import { customGoTo } from '@helpers/router'

import { $appts } from '@store'

import { routeActions } from '@config/routeActions'

const EmptyView = () => {
  const { t } = useTranslation()
  const { id: appointmentId } = useParams()

  const { fullAppt } = $appts.byRoute
  const { appointment, encounters } = fullAppt

  useEffect(() => {
    if (+appointmentId === appointment.id && encounters) {
      customGoTo(
        routeActions.APPT({
          appointmentId: appointmentId,
          encounterIds: appointment.encounterIds,
        }),
        { type: 'replace' },
      )
    }
  }, [encounters]) // eslint-disable-line react-hooks/exhaustive-deps

  return <PageNoResults>{t('appt.no_encounters')}</PageNoResults>
}

export default observer(EmptyView)
