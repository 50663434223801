import React from 'react'

import { observer } from 'mobx-react'

import classNames from 'classnames'

import Checkbox from '@library/ui/checkbox/Checkbox'

import { formatAmount } from '@helpers/text'

import styles from './VoucherCard.module.scss'

const VoucherCard = ({
  title,
  subTitle,
  description,
  price,
  className,
  isCheckbox,
  isChecked,
  onClick,
  buttonLabel,
  onButtonClick,
}) => {
  return (
    <div className={classNames(styles.wrapper, isCheckbox && styles.wrapperClickable, className)}>
      <div className={styles.body} onClick={onClick}>
        {isCheckbox && <Checkbox className={styles.checkbox} isChecked={isChecked} isDark={true} />}
        <div className={styles.content}>
          <div className={styles.title}>
            <div className={styles.titleText}>{title}</div>
            {price && (
              <div className={styles.titlePrice}>{formatAmount(price.amount, price.currency)}</div>
            )}
          </div>
          <div className={styles.subtitle}>{subTitle}</div>
        </div>
      </div>
      {buttonLabel && onButtonClick && (
        <div className={styles.footer} onClick={onButtonClick}>
          {buttonLabel}
        </div>
      )}
    </div>
  )
}

export default observer(VoucherCard)
