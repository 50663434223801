import React, { useMemo } from 'react'

import { observer } from 'mobx-react'
import { IMask } from 'react-imask'

import moment from 'moment-timezone'

import InputBase from '@library/form/fields/new/InputBase'
import NewDatePickerModal from '@library/newDatePicker/NewDatePicker.modal'

import { $modal, $user } from '@store'

import calendarIcon from '@assets/images/ui/calendar.svg'

const InputDate = ({
  minDate,
  maxDate,
  type,
  timezone = $user.timezone,
  scrollTo,
  datePickerBtn = true,
  visibleFormat = 'MM/DD/YYYY',
  placeholder = visibleFormat,
  field,
  ...props
}) => {
  const innerValue = useMemo(() => {
    if (type === 'selectDateTime') {
      return field.value && moment(field.value).tz(timezone).format(visibleFormat)
    } else {
      return field.value && moment(field.value).format(visibleFormat)
    }
  }, [field.value, timezone, type, visibleFormat])

  minDate = useMemo(() => {
    return minDate ? moment(minDate) : moment().subtract(100, 'year')
  }, [minDate])

  maxDate = useMemo(() => {
    return maxDate ? moment(maxDate) : moment().add(100, 'year')
  }, [maxDate])

  function handleOnChange(value) {
    if (moment(value, ['YYYY-MM-DD', visibleFormat, 'YYYY-MM-DDTHH:mm:ss.sssZ'], true).isValid()) {
      if (type === 'selectDateTime') {
        field.onChange(value)
      } else {
        value = moment(value, ['YYYY-MM-DD', visibleFormat]).format('YYYY-MM-DD')
        field.onChange(value)
      }
    } else if (value === '') {
      field.onChange('')
    }
  }

  function getSelectedDate() {
    const currentDate = moment().format()
    let result

    if (innerValue) {
      result = innerValue
    } else {
      result = moment(maxDate).isAfter(currentDate) ? currentDate : maxDate
    }
    return result
  }

  return (
    <InputBase
      field={{
        ...field,
        value: innerValue,
        onChange: handleOnChange,
      }}
      placeholder={placeholder}
      icon={
        !props.readonly && {
          class: 'calendar',
          action: () =>
            $modal.add(NewDatePickerModal, {
              selectedDate: getSelectedDate(),
              onChange: handleOnChange,
              minDate,
              maxDate,
              type,
              scrollTo,
            }),
          img: calendarIcon,
        }
      }
      {...props}
      mask={{
        mask: Date,
        pattern: visibleFormat,
        lazy: true,
        autofix: true,
        min: minDate.toDate(),
        max: maxDate.toDate(),
        format(date) {
          return moment(date).format(visibleFormat)
        },
        parse(str) {
          return moment(str, visibleFormat)
        },
        blocks: {
          YYYY: {
            mask: IMask.MaskedRange,
            from: Number(minDate.format('YYYY')),
            to: Number(maxDate.format('YYYY')),
            maxLength: 4,
          },
          MM: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 12,
            maxLength: 2,
          },
          DD: {
            mask: IMask.MaskedRange,
            from: 1,
            to: 31,
            maxLength: 2,
          },
        },
      }}
    />
  )
}

export default observer(InputDate)
