import React from 'react'

const Attention = ({ color = '#fff', bgColor = '#FF3A30' }) => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="18" height="18" rx="9" fill={bgColor} />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.875 4.5C7.875 3.87868 8.37868 3.375 9 3.375C9.62132 3.375 10.125 3.87868 10.125 4.5V10.125C10.125 10.7463 9.62132 11.25 9 11.25C8.37868 11.25 7.875 10.7463 7.875 10.125V4.5Z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.875 13.5C7.875 12.8787 8.37868 12.375 9 12.375C9.62132 12.375 10.125 12.8787 10.125 13.5C10.125 14.1213 9.62132 14.625 9 14.625C8.37868 14.625 7.875 14.1213 7.875 13.5Z"
      fill={color}
    />
  </svg>
)

export default Attention
