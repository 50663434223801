import { makeAutoObservable, reaction, runInAction } from 'mobx'

import userService from '@services/user'

class AccountStore {
  constructor() {
    makeAutoObservable(this)
  }

  items = []

  clear() {
    this.items = []
  }

  // Computed

  // Mutations

  // Actions
  async get() {
    const response = await userService.getActualAccounts()

    runInAction(() => {
      this.items = response
    })
  }

  async checkCodeAndAdd({ type, value, code }) {
    const response = await userService.addAccount({ type, value, code })
    return { isSuccess: !_.isEmpty(response.prepared) }
  }

  async remove(item) {
    await userService.removeAccount(item.id)

    runInAction(() => {
      this.items = this.items.filter((i) => i.id !== item.id)
    })
  }

  async setPrimary(item) {
    await userService.setPrimary(item.id)
    await this.get()
  }
}

const store = new AccountStore()
export default store
