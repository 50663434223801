const INSURANCE_STRICT_FIELDS = ['insuranceProviderId', 'insuranceGroupId', 'insuranceMemberId']

class BookingApiHelpers {
  getFormattedPatientUpdatedValues = (values) => {
    const result = {}

    _.forEach(values, (item, key) => {
      if (item && INSURANCE_STRICT_FIELDS.includes(key)) {
        result[key] = item + '|strict'
      } else {
        result[key] = item
      }
    })

    return result
  }
}

export default new BookingApiHelpers()
