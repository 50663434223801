import _ from 'lodash';
import moment from 'moment-timezone';
export var getParsedDate = function (value, type, timezone) {
    var date = moment(value ? value : new Date());
    if (type === 'selectDateTime') {
        date.tz(timezone);
    }
    return date;
};
export var getSwitchDates = function (switchDates, type, timezone) {
    var result = {
        day: {},
        month: {},
        year: {},
    };
    switchDates.forEach(function (value) {
        var parsedDate = getParsedDate(value, type, timezone);
        result.day[parsedDate.format('YYYY-MM-DD')] = parsedDate;
        result.month[parsedDate.format('YYYY-MM')] = parsedDate;
        result.year[parsedDate.format('YYYY')] = parsedDate;
    });
    return result;
};
export var getMinMaxDate = function (minDate, maxDate, availableDates, currentDate, type, timezone) {
    var currentYear = currentDate.get('year');
    var range = 100;
    var parsedMinDate;
    var parsedMaxDate;
    if (!_.isEmpty(availableDates)) {
        var keys = Object.keys(availableDates.day).sort();
        parsedMinDate = availableDates.day[keys[0]];
    }
    else if (minDate) {
        parsedMinDate = getParsedDate(minDate, type, timezone);
    }
    else {
        parsedMinDate = currentDate.clone().set('year', currentYear - range);
    }
    if (!_.isEmpty(availableDates)) {
        var keys = Object.keys(availableDates.day).sort();
        var lastIndex = keys.length - 1;
        parsedMaxDate = availableDates.day[keys[lastIndex]];
    }
    else if (maxDate) {
        parsedMaxDate = getParsedDate(maxDate, type, timezone);
    }
    else {
        parsedMaxDate = currentDate.clone().set('year', currentYear + range);
    }
    return [parsedMinDate, parsedMaxDate];
};
export var getScrollToDate = function (selectedDate, currentDate, minDate, maxDate, scrollTo) {
    var scrollToDate;
    if (!selectedDate && scrollTo === 'start') {
        scrollToDate = minDate.clone();
    }
    else if (!selectedDate && scrollTo === 'end') {
        scrollToDate = maxDate.clone();
    }
    else {
        scrollToDate = selectedDate ? selectedDate.clone() : currentDate.clone();
    }
    return scrollToDate;
};
