import React, { useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'

import MediaTypes from '@library/media/mediaTypes/MediaTypes'
import Modal from '@library/modal/Modal'
import Button from '@library/ui/button/Button'

import { getFileNameFromUrl } from '@helpers/url'

import styles from './MediaViewer.module.scss'

const MediaViewerModal = ({ _core, selectedIndex = 0, items = [], details: Details }) => {
  const { t } = useTranslation()

  const [position, setPosition] = useState(selectedIndex)
  const [isDownloading, setIsDownloading] = useState(false)

  const content = useMemo(() => {
    let result = _.cloneDeep(items[position]) || {}

    if (!result.title) {
      result.title = getFileNameFromUrl(result.url)
    }

    result.downloadParams = {
      url: result.url,
      target: '_blank',
      label: t('btn.download'),
      ...result.downloadParams,
    }

    return result
  }, [items, position])

  const hasControls = items.length > 1

  function prevHandle() {
    return setPosition((x) => {
      if (x <= 0) {
        return x
      }
      return x - 1
    })
  }

  function nextHandle() {
    return setPosition((x) => {
      if (x >= items.length - 1) {
        return x
      }

      return x + 1
    })
  }

  return (
    <Modal centered className={styles.wrapper} headerBordered size="xl">
      <div className="modal-toolbar">
        <div className="modal-toolbar-btn modal-toolbar-btn--close" onClick={_core.onHide} />
      </div>
      <Modal.Header>
        <div className="title-md text-first-letter-uppercase">{content.title}</div>
        <div className={styles.headerPanel}>
          <div className={styles.links}>
            {_.isFunction(content.downloadParams.action) ? (
              <Button
                tag="div"
                mode="anchor"
                isLoading={isDownloading}
                disabled={isDownloading}
                action={async () => {
                  setIsDownloading(true)
                  await content.downloadParams.action()
                  setIsDownloading(false)
                }}
              >
                {content.downloadParams.label}
              </Button>
            ) : (
              <a
                href={content.downloadParams.url}
                className="font-weight-700"
                target={content.downloadParams.target}
                rel="noreferrer"
              >
                {content.downloadParams.label}
              </a>
            )}
          </div>
          {hasControls && (
            <div className={styles.count}>
              {position + 1} / {items.length}
            </div>
          )}
        </div>
        {Details && <Details />}
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        {hasControls && (
          <Modal.Controls
            position={position}
            items={items}
            onPrev={prevHandle}
            onNext={nextHandle}
          />
        )}
        <div className={styles.content}>
          <MediaTypes content={content} collection="viewer" />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default MediaViewerModal
