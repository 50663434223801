import React from 'react'

import { toJS } from 'mobx'
import { useTranslation } from 'react-i18next'

const DocumentsTableHead = () => {
  const { t } = useTranslation()

  return (
    <div className="h_table-thead">
      <div className="h_table-tr">
        <div className="h_table-th h_table-th--label">{t('ps.label.document_type')}</div>
        <div className="h_table-th h_table-th--details">{t('ps.label.details')}</div>
        <div className="h_table-th h_table-th--date">{t('ps.label.date')}</div>
      </div>
    </div>
  )
}

export default DocumentsTableHead
