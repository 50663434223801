import React, { useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import AwsCaptcha from '@library/awsCaptcha/AwsCaptcha'
import CodeResender from '@library/codes/CodeResender'
import CodeResenderButton from '@library/codes/CodeResenderButton'
import CodeResenderCallMe from '@library/codes/CodeResenderCallMe'
import { useForm } from '@library/form/FormControllerNew'
import Modal from '@library/modal/Modal'
import Button from '@library/ui/button/Button'

import { $alerts, $auth, $loader, $user } from '@store'

import mainConfig from '@config/main'

export const DeleteProfile = ({ onBack, onHide, _core }) => {
  const { t } = useTranslation()

  const { type, value } = $auth.currentLogged.account

  const isPhone = type === 'phone'

  const { form } = useForm({
    code: { value: '', rules: ['code', 'required'] },
  })
  const { code } = form.fields

  const [codeResenderData, setCodeResenderData] = useState({})

  async function handlePrefetchCode(using) {
    code.onChange('')

    const params = { type, using, value }
    const res = await $auth.sendCode(params, { setIsBound: true })

    return _.get(res, 'prepared.codeSent')
  }

  const submitHandle = $loader.registerHandler('delete-profile-submit', async (e) => {
    e.preventDefault()

    if (form.validateAll().isValid) {
      _core.onBlock()

      const res = await $user.deleteProfile(form.values.code)

      if (!res.isSuccess) {
        form.forceError('code')
      }

      _core.onUnblock()

      if (res.isSuccess) {
        _core.forceHide()
        $auth.signout(false)
      }
    } else {
      $alerts.add(t('delete_profile.alert.provide_code'))
    }
  })

  return (
    <>
      <div className="modal-toolbar">
        {onBack && <div className="modal-toolbar-btn modal-toolbar-btn--back" onClick={onBack} />}
        {onHide && <div className="modal-toolbar-btn modal-toolbar-btn--close" onClick={onHide} />}
      </div>

      <Modal.Header className="text-lg-center">{t('delete_profile.title')}</Modal.Header>

      <Modal.Body className="styled_scroll">
        <form id="delete_profile_form" onSubmit={submitHandle} noValidate>
          <p>
            {t('delete_profile.description')}&nbsp;{value}
          </p>
          <AwsCaptcha
            show={$auth.needCaptcha}
            apiKey={mainConfig.awsCaptcha.key}
            domainList={mainConfig.awsCaptcha.domains}
            className="mb-10"
            onSuccess={handlePrefetchCode}
          />
          <div className={classNames($auth.needCaptcha && 'd-none')}>
            <CodeResender
              prefetch={handlePrefetchCode}
              code={code}
              onChange={code.onChange}
              onSetData={setCodeResenderData}
              label={false}
              placeholder={t(`code_resender.ph.${type}`)}
              textButton={false}
              autoFocus={true}
              className="mb-10"
            />
          </div>
          {!$auth.needCaptcha && (
            <Button
              tag="button"
              mode={['danger', 'block']}
              className="mb-10"
              form="delete_profile_form"
              isLoading={$loader.isRunHandler('delete-profile-submit')}
            >
              {t('delete_profile.btn.confirm')}
            </Button>
          )}
          <CodeResenderButton type="button" className="btn-block" {...codeResenderData} />
          {isPhone && (
            <div className="text-center text-muted font-weight-600 mt-20">
              <CodeResenderCallMe {...codeResenderData} />
            </div>
          )}
        </form>
      </Modal.Body>
    </>
  )
}

export default observer(DeleteProfile)
