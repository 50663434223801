import React from 'react'

import { observer } from 'mobx-react'
import { Trans, useTranslation } from 'react-i18next'

import SelectCode from '@booking/pages/bookingHome/selectPractice/voucherModal/selectCode/SelectCode'
import SelectVoucher from '@booking/pages/bookingHome/selectPractice/voucherModal/selectVoucher/SelectVoucher'

import Modal from '@library/modal/Modal'
import ShowMoreText from '@library/showMoreText/ShowMoreText'

import { $booking } from '@store'

import styles from './Voucher.modal.module.scss'

const VoucherModal = ({ _core }) => {
  const { t } = useTranslation()

  return (
    <Modal scrollable _core={_core}>
      <div className="modal-toolbar">
        <div className="modal-toolbar-btn modal-toolbar-btn--close" onClick={_core.onHide} />
      </div>
      <Modal.Header>
        <div className="title-md mb-0">{t('label.voucher_or_code')}</div>
      </Modal.Header>
      <Modal.Body className={styles.modalBody}>
        <ShowMoreText lines={3} className={styles.infoText}>
          <p>
            <Trans i18nKey="book.vouchers.info_text.p1" components={[<strong />]} />
          </p>
          <p>
            <Trans i18nKey="book.vouchers.info_text.p2" components={[<strong />]} />
          </p>
        </ShowMoreText>
        <SelectCode
          officeId={$booking.officeId}
          onChange={({ officeId }) => {
            $booking.SET_OFFICE_ID(officeId)
          }}
        />
        <SelectVoucher
          voucher={$booking.voucher}
          onChange={({ voucher, serviceId }) => {
            $booking.SET_VOUCHER(voucher)
            $booking.SET_PREDEFINED_SERVICE_IDS(serviceId ? [serviceId] : [])
          }}
        />
      </Modal.Body>
    </Modal>
  )
}

export default observer(VoucherModal)
