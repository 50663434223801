import { makeAutoObservable, runInAction } from 'mobx'

import clientService from '@services/client'

import { extendAddress } from '@helpers/addressHelper'

class PlacesStore {
  constructor() {
    makeAutoObservable(this)
  }

  items = []

  // Computed
  get extendedItems() {
    return this.items.map((item) => extendAddress(item))
  }

  // Mutations
  SET_ITEMS(value) {
    this.items = value
  }

  // Actions
  clear() {
    this.items = []
  }

  async get() {
    const response = await clientService.getPlaces()
    runInAction(() => {
      this.items = _.get(response, 'prepared.places.items', [])
    })
  }

  async add(place) {
    const result = await clientService.addPlace({ ...place })
    if (result && result.prepared) {
      await this.get()
    }
  }

  async update(id, place) {
    const result = await clientService.updatePlace({ id, ...place })
    if (result && result.prepared) {
      await this.get()
    }
  }

  async remove(id) {
    const result = await clientService.removePlace({ id })
    if (result && result.prepared) {
      await this.get()
    }
  }

  getById(id) {
    return _.find(this.items, (x) => String(x.id) === String(id))
  }
}

const store = new PlacesStore()
export default store
