import React from 'react'

import { observer } from 'mobx-react'

import ApptControl from '@pages/appt/apptControl/ApptControl'
import Map from '@pages/appt/apptMap/Map'

import { $appts } from '@store'

import mainConfig from '@config/main'

const ApptMap = ({ byRoute }) => {
  const { fullAppt, status } = byRoute || $appts.byRoute
  const { place } = fullAppt

  const isSuccess = mainConfig.appType === 'provider' ? status.isDone : status.isSuccess
  const isCompleted = !status.payAttention?.length && isSuccess

  return (
    <div className="appt_map">
      {place && (
        <>
          <div className="appt_map-map">
            <Map coords={place.coords} showMarker={!isCompleted} />
          </div>
          {isCompleted && <div className="appt_map-completed_marker" />}
          <ApptControl byRoute={byRoute} />
        </>
      )}
    </div>
  )
}

export default observer(ApptMap)
