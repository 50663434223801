import { useMemo } from 'react'

import { observable } from 'mobx'
import { generatePath, matchPath, useHistory, useLocation } from 'react-router-dom'

import { createBrowserHistory, createHashHistory } from 'history'
import queryString from 'query-string'

import mainConfig from '@config/main'
import { areaList, routesBaseList } from '@config/routes'
import { routesExtraList } from '@config/routesExtra'

function historyInstance() {
  let result

  if (mainConfig.hashRouter) {
    result = createHashHistory()
    result.isHash = true
  } else {
    result = createBrowserHistory({ basename: process.env.PUBLIC_URL })
    result.isHash = false
  }

  return result
}

export const history = observable(historyInstance())

export function toPath(
  routeName,
  {
    params = {},
    query: searchParams = {},
    savedSearchParams = mainConfig.savedSearchParamsList,
    hash = '',
    state = {},
  } = {},
) {
  if (!routeName) {
    return // TODO: change with undefined and check it
  }

  const route = getRouteByName(routeName)

  let path = routeName
  if (route && route.path) {
    path = route.path
  }

  if (route && route.name !== routeName) {
    const child = _.find(route.children, (x) => x.name === routeName)
    path += child.path
  }

  let pathname = generatePath(path, params)
  if (savedSearchParams.length) {
    const currentParams = queryString.parse(history?.location?.search)
    searchParams = { ..._.pick(currentParams, savedSearchParams), ...searchParams }
  }
  let search = queryString.stringify(searchParams)

  return {
    pathname,
    search,
    hash,
    state,
    url: queryString.stringifyUrl({ url: pathname, query: searchParams, fragmentIdentifier: hash }),
  }
}

export function customGoTo(
  autoTarget,
  {
    type = 'push', // push | replace
    history: _history,
    hrefTarget: _hrefTarget = '_self',
  } = {},
) {
  const wvAction = autoTarget?.wvAction
  if (_.isFunction(wvAction)) {
    return wvAction()
  }

  const href = autoTarget?.href
  if (href) {
    if (type === 'replace') {
      return window.location.replace(href)
    }
    const target = autoTarget?.hrefTarget || _hrefTarget
    const newWindow = window.open(href, target, 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
    return
  }

  const to = autoTarget?.to
  if (to) {
    const h = _history || history

    if (type === 'replace') {
      return h.replace(to)
    }
    return h.push(to)
  }
}

export const routes = (() => {
  let result = routesBaseList

  routesExtraList.forEach((extraRoute) => {
    const index = result.findIndex((x) => x.name === extraRoute.name)

    if (index !== -1) {
      if (extraRoute.isRemoved) {
        result.splice(index, 1)
      } else {
        result[index] = { ...result[index], ...extraRoute }
      }
    } else {
      result.push(extraRoute)
    }
  })

  return _.orderBy(result, (x) => x.path.length, 'desc')
})()

export function getRouteByName(name = '') {
  let childPathName = null
  let result = _.find(
    routes,
    (x) =>
      x.name === name ||
      (x.children &&
        _.find(x.children, (xChild) => {
          if (xChild.name === name) {
            childPathName = xChild.path
            return true
          }
        })),
  )
  if (childPathName) {
    result.fullPath = result.path + childPathName
  }
  return result
}

export function isRouteByArea(areaName, params = {}) {
  const area = areaList[areaName] || {}

  const isRoute = _.keys(area).includes(history.routeConfig?.name)
  if (!isRoute) return false
  if (_.isEmpty(params)) return true

  const { id } = params
  const routeId = history.match.params[area[history.routeConfig.name]]

  // todo: add match by two or more params

  return String(id) === String(routeId)
}

export function getParamsByHistory(routeName = '') {
  const routeConfig = getRouteByName(routeName) || history.routeConfig
  const matched = matchPath(history.location.pathname, { path: routeConfig.path }) || {}
  return matched.params || {}
}

export function getParamsByPathname(routeName = '', pathname = '') {
  const routeConfig = getRouteByName(routeName)
  const matched = matchPath(pathname, { path: routeConfig.fullPath || routeConfig.path }) || {}
  return matched.params || {}
}

export const useRouterSearchParams = (
  type,
  qsOptions = { arrayFormat: 'comma', parseBooleans: true, parseNumbers: true },
) => {
  const history = useHistory()
  const location = useLocation()

  const searchParams = useMemo(
    () => queryString.parse(location.search, qsOptions),
    [location.search],
  )

  return routerSearchParams({ history, type, searchParams })
}

export function routerSearchParams({
  history: _history,
  location: _location,
  searchParams: _searchParams,
  type = 'auto',
  qsOptions = { arrayFormat: 'comma', parseBooleans: true, parseNumbers: true },
} = {}) {
  const rHistory = _history || history
  const rLocation = _location || history?.location
  const searchParams = _searchParams || queryString.parse(rLocation?.search, qsOptions)

  const setSearchParams = (payload) => {
    const params = {
      search: queryString.stringify(payload, qsOptions),
    }
    if (type === 'push') {
      rHistory.push(params)
    } else if (type === 'replace') {
      rHistory.replace(params)
    } else {
      if (_.isEqual(searchParams, payload)) {
        rHistory.replace(params)
      } else {
        rHistory.push(params)
      }
    }
  }

  const getAllQueryParams = () => {
    return searchParams
  }

  const getQueryParam = (key) => {
    return searchParams[key]
  }

  const setQueryParam = (params, value) => {
    const updatedParams = { ...searchParams }

    if (typeof params === 'object') {
      Object.entries(params).forEach(([key, val]) => {
        if (val == null) {
          delete updatedParams[key]
        } else {
          if (typeof val === 'number') {
            val = val.toString()
          }
          updatedParams[key] = val
        }
      })
    } else if (typeof params === 'string') {
      if (value == null) {
        delete updatedParams[params]
      } else {
        if (typeof value === 'number') {
          value = value.toString()
        }
        updatedParams[params] = value
      }
    }

    setSearchParams(updatedParams)
  }

  const removeQueryParam = (params) => {
    const updatedParams = { ...searchParams }

    if (typeof params === 'string') {
      delete updatedParams[params]
    } else if (Array.isArray(params)) {
      params.forEach((key) => delete updatedParams[key])
    }

    setSearchParams(updatedParams)
  }

  const clearQueryParams = () => setSearchParams({})

  return {
    getAll: getAllQueryParams,
    get: getQueryParam,
    set: setQueryParam,
    remove: removeQueryParam,
    clear: clearQueryParams,
  }
}
