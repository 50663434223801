import { makeAutoObservable, runInAction } from 'mobx'

import moment from 'moment-timezone'

import clientService from '@services/client'

class PaymentMethodsStore {
  constructor() {
    makeAutoObservable(this)
  }

  _items = []
  defaultId = false

  // Computed
  get items() {
    return _.cloneDeep(this._items).map((x) => this.extendItem(x))
  }

  // Mutations
  SET_DEFAULT_ID(id) {
    this.defaultId = id
  }

  // Actions
  getPaymentById(id) {
    return this.items.find((x) => x.id === id) || {}
  }

  extendItem(item) {
    const currentMonth = moment().startOf('month')
    const cardMonth = moment(item.expYear + '-' + item.expMonth, 'YYYY-MM')

    item.isExpired = cardMonth.isBefore(currentMonth)

    return item
  }

  async get() {
    const response = await clientService.getPayments()

    runInAction(() => {
      this._items = _.get(response, 'prepared.paymentMethods.items', [])
    })
  }

  async getLast(type = 'lastAdded') {
    await this.get()

    let method = _.first(this.items) || {}
    let methodId = method.id || false

    if (type === 'lastUsed') {
      const response = await clientService.getLastUsedPayedAppointment()
      methodId = _.get(response, 'prepared.appointments.items.0.paymentMethodId')
      method = this.getPaymentById(methodId)
    }

    this.SET_DEFAULT_ID(methodId)

    if (methodId) {
      return { isSuccess: true, method }
    }

    return { isSuccess: false }
  }

  async add(card, stripe) {
    const result = await clientService.addPayment(card, stripe)

    if (result && result.prepared) {
      await this.get()

      return {
        isSuccess: true,
        paymentMethod: this.extendItem(result.prepared.paymentMethod),
      }
    }

    return { isSuccess: false }
  }

  async remove(id) {
    const result = await clientService.removePayment({ id })

    if (result && result.prepared) {
      await this.get()
    }
  }
}

const store = new PaymentMethodsStore()
export default store
