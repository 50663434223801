import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'

import { observer } from 'mobx-react'

import classNames from 'classnames'

import { $appts } from '@store'

const ApptStatus = ({ byRoute }) => {
  const { statuses, status } = byRoute || $appts.byRoute

  const [shift, setShift] = useState(0)
  const currentRef = useRef(null)

  const shiftStyles = useMemo(() => {
    return shift ? { transform: 'translateX(-' + shift + 'px)' } : {}
  }, [shift])

  useEffect(() => {
    function changeShift() {
      status && setShift(_.get(currentRef, 'current.offsetLeft', 0))
    }

    changeShift()

    window.addEventListener('resize', changeShift)
    return () => {
      window.removeEventListener('resize', changeShift)
    }
  }, [currentRef, status])

  return (
    <div className="appt_status" style={shiftStyles}>
      {statuses.map((status, index) => (
        <Fragment key={status.id}>
          <div
            ref={status.current ? currentRef : undefined}
            className={classNames(
              'appt_status-item title-lg',
              (status.previous || status.current) && 'appt_status-item--active',
            )}
          >
            {_.get(status, 'texts.status')}
          </div>
          {index < statuses.length - 1 && <div className="appt_status-delimiter" />}
        </Fragment>
      ))}
    </div>
  )
}

export default observer(ApptStatus)
