import React from 'react'

import * as EmailValidator from 'email-validator'

import { getUuid } from '@helpers/other'

export function formatByMask(value, mask, { placeholder = '.', strict = true } = {}) {
  if (!mask) {
    return value
  }

  const tmp = mask.split('').reduce(
    (acc, character) => {
      if (!acc.remaining.length) {
        return acc
      }

      if (character === placeholder) {
        return {
          formatted: acc.formatted + acc.remaining[0],
          remaining: acc.remaining.slice(1),
        }
      }

      return {
        formatted: acc.formatted + character,
        remaining: acc.remaining,
      }
    },
    { formatted: '', remaining: value.split('') },
  )

  let result = tmp.formatted

  if (!strict) {
    result = result + tmp.remaining.join('')
  }

  return result
}

export function toSnakeCase(value) {
  return value.replace(/([A-Z])/g, '_$1')
}

// todo remove?
export function replaceValues(str, values = {}, options = { open: '{{', close: '}}' }) {
  if (str) {
    _.forEach(values, (value, pattern) => {
      let regexp = new RegExp(options.open + pattern + options.close, 'g')
      str = str.replace(regexp, value)
    })
  }
  return str
}

export function splitName(name = '', type) {
  let matches = name
    .replace(new RegExp(String.fromCharCode(160), 'g'), ' ')
    .split(' ')
    .filter((x) => x)

  let firstName = ''
  let lastName = ''

  if (matches && matches.length) {
    if (type === 'consistently') {
      firstName = matches[0]
      lastName = matches[1]
    } else {
      if (matches.length > 1) {
        firstName = matches.slice(0, -1).join(' ')
        lastName = matches[matches.length - 1]
      } else {
        firstName = matches[0]
      }
    }
  }

  return [firstName, lastName].filter((x) => x)
}

export function getFullName({ firstName, lastName } = {}) {
  return [firstName, lastName]
    .map((i) => i && i.trim())
    .filter((i) => i)
    .join(' ')
}

export function firstLetters(names = []) {
  names = names.map((x) => (typeof x === 'undefined' ? '' : x))
  return names
    .map((x) => x.trim())
    .filter((x) => x)
    .map((x) => x[0])
    .join('')
}

// todo: add zero as free
export function formatAmount(amount, currency, precision = 2) {
  let curr = currency.toUpperCase()

  if (curr === 'USD') {
    amount = '$' + _.floor(amount, precision)
  } else {
    amount = _.floor(amount, precision) + ' ' + curr
  }

  return amount
}

export function isValidPhoneNumber(value, isRequired = true) {
  return (!isRequired && _.isEmpty(value.number)) || value.isValid
}

export function isValidEmail(value, isRequired = true) {
  return (!isRequired && _.isEmpty(String(value).trim())) || EmailValidator.validate(value)
}

// todo remove?
export function filterOutput(str, values = {}) {
  if (typeof str === 'string') {
    str = str.replace(/(?:\r\n|\r|\n)/g, '<br/>')
    return replaceValues(str, values)
  }
}

export function humanize(str, startCase = false) {
  const strCleaned = _.replace(str, /_/g, ' ')

  return startCase ? _.startCase(strCleaned) : _.capitalize(strCleaned)
}

export function chunkStr(str, size) {
  const numChunks = Math.ceil(str.length / size)
  const chunks = new Array(numChunks)

  for (let i = 0, o = 0; i < numChunks; ++i, o += size) {
    chunks[i] = str.substr(o, size)
  }

  return chunks
}

export function simpleTagConverter(str = '', Tag = 'em') {
  const regex = new RegExp('<' + Tag + '>[\\s\\S]*?<\\/' + Tag + '>', 'ig')
  const matches = str.match(regex) || []

  if (matches.length) {
    let lastPosition = 0
    let result = []

    _.forEach(matches, (m) => {
      const start = str.indexOf(m)

      result.push(str.substring(0, start))
      result.push(<Tag key={getUuid()}>{m.replace(/<\/?[^>]+(>|$)/g, '')}</Tag>)

      lastPosition = start + m.length
      str = str.substring(lastPosition)
    })

    result.push(str)

    return result
  } else {
    return str
  }
}

export function stripHtml(str) {
  return _.replace(str, /(<([^>]+)>)/gi, '')
}

export function valueOrDash(value) {
  return (value && _.trim(value)) || '—'
}

function characterByIndex(index, charStart = 'A', charEnd = 'Z') {
  let alphabet = []

  for (let i = charStart.charCodeAt(); i <= charEnd.charCodeAt(); i++) {
    alphabet.push(String.fromCharCode(i))
  }

  if (index <= alphabet.length - 1) {
    return alphabet[index]
  } else if (index <= Math.pow(alphabet.length, 2) + alphabet.length) {
    let firstChar = ''
    let secondChar = ''
    let ind = 0

    for (let i = index; i >= 0; i -= alphabet.length) {
      ind++
      firstChar = alphabet[ind - 2]
      secondChar = alphabet[i]
    }
    return [firstChar, secondChar].join('')
  } else {
    return index
  }
}

export function T({ children, tag, className }) {
  const Tag = tag || 'div'
  const regex = new RegExp(/(\bhttps?:\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi)
  const regexAnchor = new RegExp(/\bhttps?:\/\//gi)

  return (
    children && (
      <Tag className={className}>
        {children.split(regex).map((item, index) => {
          if (regex.test(item)) {
            const anchor = item.replace(regexAnchor, '')
            return (
              <a key={index} target="_blank" href={item}>
                {anchor}
              </a>
            )
          }
          return item
        })}
      </Tag>
    )
  )
}
