import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { analytics } from '@common/analytics/zipAnalytics';
import CustomLink from '@library/ui/customLink/CustomLink';
import styles from './GoBack.module.scss';
var GoBack = function (_a) {
    var autoTarget = _a.autoTarget, onClick = _a.onClick, _b = _a.historyBack, historyBack = _b === void 0 ? false : _b, className = _a.className;
    var history = useHistory();
    function handleOnClick(event) {
        if (_.isFunction(onClick)) {
            onClick(event);
        }
        analytics.breadcrumb('nav-goBack', {});
        if (historyBack) {
            history.goBack();
        }
    }
    return (_jsx(CustomLink, { autoTarget: autoTarget, onClick: handleOnClick, className: classNames(styles.toolbarGoBack, className) }));
};
export default GoBack;
