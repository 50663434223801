import React, { useMemo } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import BookingLayout from '@booking/library/bookingLayout/BookingLayout'
import withBookingDataInit from '@booking/library/withBookingDataInit/withBookingDataInit'
import classNames from 'classnames'

import Button from '@library/ui/button/Button'

import { customGoTo } from '@helpers/router'

import { $booking, $loader, $main } from '@store'

import CameraMarker from '@assets/svg/CameraMarker'
import HomeMarker from '@assets/svg/HomeMarker'
import LocationMarker from '@assets/svg/LocationMarker'

import styles from './BookingServiceType.module.scss'

const iconsMap = {
  home: HomeMarker,
  virtual: CameraMarker,
  clinic: LocationMarker,
}

const getIconKey = (item) => {
  if (item.isAtClinic) {
    return 'clinic'
  } else if (item.isAtHome) {
    return 'home'
  } else {
    return 'virtual'
  }
}

const BookingServiceType = () => {
  const { t } = useTranslation()

  const preparedList = useMemo(() => {
    const serviceIds = $main.initExtraData?.serviceIds
    if (serviceIds && serviceIds.length) {
      return $booking.$services.items.filter((item) => serviceIds.includes(item.id))
    }
    return $booking.$services.items
  }, [$booking.$services.items])

  const handleNext = $loader.registerHandler('booking-next', async () => {
    customGoTo($booking.schemaConfig?.nextStep())
  })

  return (
    <BookingLayout
      breadcrumbs={['service', 'patients']}
      title={t('book.title.how_can_we_help_you')}
      isMobileFooterFixed={true}
      button={
        <Button
          mode="primary"
          tag="button"
          buttonType="button"
          disabled={$booking.serviceId === null}
          label={t('btn.next')}
          action={handleNext}
          isLoading={$loader.isRunHandler('booking-next')}
        />
      }
    >
      <div className={styles.container}>
        {preparedList.map((item) => {
          const isSelected = $booking.serviceId === item.id
          const Icon = iconsMap[getIconKey(item)]

          return (
            <div
              className={classNames(styles.service, isSelected && styles.serviceSelected)}
              key={item.id}
              onClick={() => $booking.SET_SERVICE_ID(item.id)}
            >
              <div className={styles.name}>{item.name}</div>
              <div className={styles.icon}>
                <Icon color={isSelected ? 'rgba(255,255,255,0.5)' : '#17a2c6'} />
              </div>
              {isSelected ? <div className={styles.checkmark} /> : null}
            </div>
          )
        })}
      </div>
    </BookingLayout>
  )
}

export default withBookingDataInit(observer(BookingServiceType))
