import React, { useMemo, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import InputBase from '@library/form/fields/new/InputBase'
import { useForm } from '@library/form/FormControllerNew'
import NewDatePickerModal from '@library/newDatePicker/NewDatePicker.modal'
import MultiSelect from '@library/selectDropdown/MultiSelect'
import Button from '@library/ui/button/Button'

import { formatDob } from '@helpers/time'

import { $modal, $psHistory } from '@store'

const AppointmentsSearch = () => {
  const { t } = useTranslation()

  const [showFilters, setShowFilters] = useState(false)

  const { minDate, maxDate } = $psHistory.eventsMinMaxDate

  const sortByItems = useMemo(() => {
    return [
      { id: 'date_desc', text: t('ps.label.sortBy.date_desc') },
      { id: 'date_asc', text: t('ps.label.sortBy.date_asc') },
    ]
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  const { form, useFormOnChange } = useForm({
    searchString: { store: $psHistory }, // SET_SEARCH_STRING
    dateFrom: { store: $psHistory }, // SET_DATE_FROM
    dateTo: { store: $psHistory }, // SET_DATE_TO
    sortBy: { store: $psHistory, prepValue: 'singleSelect' }, // SET_SORT_BY
  })
  const { searchString, dateFrom, dateTo, sortBy } = form.fields

  useFormOnChange(async () => await $psHistory.searchEvents(), [searchString.value])

  return (
    <div className="pd-search-params mb-20">
      <div className="row">
        <div className="col-12 col-lg-4 pd-search-params-filters--input">
          <InputBase
            type="search"
            field={searchString}
            label={t('ps.label.search')}
            placeholder={t('ps.ph.search_details')}
            mode={['sm', 'fw-400']}
            clear
          />
        </div>
        <div className="col-12 col-lg-auto pd-search-params-filters--btn">
          <Button
            mode="filter-outline"
            className={showFilters && 'btn--active'}
            action={() => setShowFilters((x) => !x)}
          >
            <span className="btn-text">{t('ps.btn.filters')}</span>
          </Button>
        </div>
      </div>
      {showFilters && (
        <div className="row mt-20">
          <div className="col-12 col-lg-4">
            <div className="tsform-join-group">
              <InputBase
                field={{
                  ...dateFrom,
                  value: formatDob(dateFrom.value),
                  onChange: dateFrom.onChange,
                }}
                onClick={() => {
                  $modal.add(NewDatePickerModal, {
                    type: 'selectDateTime',
                    selectedDate: dateFrom.value,
                    onChange: dateFrom.onChange,
                    minDate: minDate,
                    maxDate: maxDate,
                    scrollTo: 'start',
                  })
                }}
                label={t('ps.label.date_from')}
                placeholder={t('ps.ph.date_from')}
                mode={['sm', 'fw-400', 'cursor-pointer']}
                readOnly={true}
                clear
              />
              <InputBase
                field={{ ...dateTo, value: formatDob(dateTo.value), onChange: dateTo.onChange }}
                onClick={() => {
                  $modal.add(NewDatePickerModal, {
                    type: 'selectDateTime',
                    selectedDate: dateTo.value,
                    onChange: dateTo.onChange,
                    minDate: minDate,
                    maxDate: maxDate,
                    scrollTo: 'end',
                  })
                }}
                label={t('ps.label.date_to')}
                placeholder={t('ps.ph.date_to')}
                mode={['sm', 'fw-400', 'cursor-pointer']}
                readOnly={true}
                clear
              />
            </div>
          </div>
          <div className="col-12 col-lg-4">
            <MultiSelect
              label={t('ps.label.sortBy')}
              items={sortByItems}
              field={sortBy}
              mode={['sm', 'fw-400']}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default observer(AppointmentsSearch)
