import { toJS } from 'mobx'

import i18n from 'i18next'
import moment from 'moment-timezone'

import { formatAmount } from '@helpers/text'

import { $appts, $main } from '@store'

import { apptActions } from '@config/appts'
import {
  apptTypes,
  getAllStatuses,
  TIME_FOR_CONFIRMATION,
  TIME_FOR_ONSITE_REBOOK,
  TIME_FOR_VIRTUAL_REBOOK,
} from '@config/apptsCore'
import mainConfig from '@config/main'
import { PATIENT_GROUP_TYPES } from '@config/services'

class hApptCore {
  formsNotFilled({ encounters }) {
    let result = {
      encounters: {},
      any: true,
    }
    _.forEach(encounters, (enc, encId) => {
      const documentTemplates = toJS($appts.documentTemplates)[encId]

      if (documentTemplates) {
        result.encounters[encId] = _.some(documentTemplates, (x) => !x.isAccepted)
      } else {
        result.encounters[encId] =
          !enc.telehealthConsentId || !enc.hipaaConsentId || !enc.patientIntakeId
      }
    })

    result.any = _.some(Object.values(result.encounters), (x) => x)
    return result
  }

  typeLabel({ service }) {
    return _.trim(service?.name)
  }

  practiceLabel({ office }) {
    return office.practice
  }

  isFree(amount) {
    return amount <= 0
  }

  amount({ appointment }) {
    if (this.isFree(appointment.amount)) {
      return i18n.t('appt.price.free')
    }

    return formatAmount(appointment.amount, appointment.currency)
  }

  prepareDescription({ patientSymptoms }) {
    return _.get(patientSymptoms, 'payload.description')
  }

  prepareSymptoms({ patientSymptoms, asStr = true }) {
    let list = _.get(patientSymptoms, 'payload.symptoms', patientSymptoms)

    if (list) {
      list = _.map(list, (x) => _.startCase(x.name))
    }

    if (asStr) {
      list = list.join(', ')
    }

    return list
  }

  isTimeInProgress({ appointment }) {
    const { appointedStart, appointedFinish } = appointment
    const start = moment.utc(appointedStart).subtract(0, 'hours')
    const end = moment.utc(appointedFinish)
    const now = moment.utc()

    return now.isSameOrAfter(start) && now.isSameOrBefore(end)
  }

  getMeetingId(fullAppt) {
    const { agent, provider, service } = fullAppt

    if (apptTypes.isStartAgent(service)) {
      return agent.meetingId
    } else {
      return provider.meetingId
    }
  }

  meetingUrl({ fullAppt, status = {}, type, source = 'auto' }) {
    let meetingId = this.getMeetingId(fullAppt)
    let url

    if (_.isUndefined(type)) {
      type = mainConfig.appType !== 'client' && status.isStartProvider ? 'start' : 'join'
    }

    const list = mainConfig.zoom.conferenceUrl[type] || {}

    if ((source === 'auto' && $main.isMobile) || source === 'browser') {
      url = list.browser
    } else {
      url = list.app
    }

    return _.replace(url, '{{meetingId}}', meetingId)
  }

  getCurrentStatus({ fullAppt, statuses }) {
    if (fullAppt) {
      statuses = getAllStatuses(fullAppt)
    }

    return _.find(statuses, (x) => x.current) || {}
  }

  extendFullAppt(fullAppt) {
    const statuses = getAllStatuses(fullAppt)
    const status = this.getCurrentStatus({ statuses })
    const actions = apptActions({ fullAppt, status }, true)

    return { fullAppt, statuses, status, actions }
  }

  needToFinishEncountersManually({ appointment, patients }) {
    let result = false

    if (appointment.patientGroupType === PATIENT_GROUP_TYPES.group) {
      result = true
    } else if (appointment.patientGroupType === PATIENT_GROUP_TYPES.family) {
      if (Object.keys(patients).length > 1) {
        result = true
      }
    }

    return result
  }

  isTimeToConfirm({ appointment }) {
    const { appointedStart } = appointment
    const start = moment.utc(appointedStart)
    const now = moment.utc()
    const dt = start.diff(now, 'minutes')

    return dt < TIME_FOR_CONFIRMATION
  }

  isCanRebook({ appointment, service }) {
    const { appointedStart } = appointment

    const start = moment.utc(appointedStart)
    const now = moment.utc()
    const dt = start.diff(now, 'minutes')

    const isVirtual = apptTypes.isVirtual(service)
    const availableTime = isVirtual ? TIME_FOR_VIRTUAL_REBOOK : TIME_FOR_ONSITE_REBOOK

    return dt > availableTime
  }

  feesAmount({ appointment, payment }) {
    const isCanceled = appointment.status === 'canceled'
    const isPaid = payment.status && payment.status !== 'refunded'

    if (!this.isFree(appointment.amount) && isCanceled && isPaid) {
      return formatAmount(appointment.amount, appointment.currency)
    }

    return false
  }

  getGroupTypeKey = (appointment, patients) => {
    let result = appointment.patientGroupType
    if (
      appointment.patientGroupType === PATIENT_GROUP_TYPES.family &&
      Object.keys(patients).length === 1
    ) {
      result = PATIENT_GROUP_TYPES.individual
    }
    return result
  }
}

export default new hApptCore()
