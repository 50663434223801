var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useLoader } from '@library/CustomHooks';
import { trendsListConfig, trendsListConfigMap, trendsListConfigValues, } from '@library/trendsList/TrendsList.config';
import { getBMIMeasurement, measurementsResponseAdapter, } from '@library/trendsList/TrendsList.helpers';
import TrendsListChart from '@library/trendsList/trendsListChart/TrendsListChart';
import Title from '@library/ui/title/Title';
import WithLoaderWrapper from '@library/ui/withLoaderWrapper/WithLoaderWrapper';
import psService from '@ps/services/psService';
import styles from './TrendsList.module.scss';
var TrendsList = function () {
    var t = useTranslation().t;
    var _a = useState([]), data = _a[0], setData = _a[1];
    var isLoading = useLoader(function () { return __awaiter(void 0, void 0, void 0, function () {
        var codes, response, measurements, height, weight, bmi;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    codes = _.map(trendsListConfigValues, function (item) { return item.code; });
                    return [4 /*yield*/, psService.getMeasurements({ codes: codes })];
                case 1:
                    response = _c.sent();
                    if (!((_a = response.prepared) === null || _a === void 0 ? void 0 : _a.measurements)) {
                        return [2 /*return*/];
                    }
                    measurements = measurementsResponseAdapter((_b = response.prepared) === null || _b === void 0 ? void 0 : _b.measurements);
                    // Sort by config sequence
                    measurements.sort(function (a, b) {
                        return _.findIndex(trendsListConfigValues, function (item) { return item.code === a.code; }) -
                            _.findIndex(trendsListConfigValues, function (item) { return item.code === b.code; });
                    });
                    height = measurements.findIndex(function (item) { return item.code === trendsListConfig.bodyHeight.code; });
                    weight = measurements.findIndex(function (item) { return item.code === trendsListConfig.bodyWeight.code; });
                    if (height > -1 && weight > -1) {
                        bmi = getBMIMeasurement(measurements[height], measurements[weight]);
                        measurements.splice(height, 0, bmi);
                    }
                    setData(measurements);
                    return [2 /*return*/];
            }
        });
    }); });
    return (_jsxs(WithLoaderWrapper, __assign({ isLoading: isLoading, visibleCondition: data.length > 0, noResultsComponent: null }, { children: [_jsx(Title, __assign({ size: "h3", className: "mb-20" }, { children: t('label.measures') })), _jsx("div", __assign({ className: styles.container }, { children: data.map(function (item) { return (_jsx(TrendsListChart, { value: item, config: trendsListConfigMap.get(item.code), className: styles.chart }, item.code)); }) }))] })));
};
export default TrendsList;
