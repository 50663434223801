var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import i18n from 'i18next';
import { isAbsoluteEmpty } from '@helpers/other';
import styles from './dtl.module.scss';
var DtlItem = function (_a) {
    var className = _a.className, label = _a.label, children = _a.children, _b = _a.valueFallBack, valueFallBack = _b === void 0 ? 'label.none' : _b, _c = _a.hideIfEmpty, hideIfEmpty = _c === void 0 ? false : _c, valueClassName = _a.valueClassName;
    var t = useTranslation().t;
    if (hideIfEmpty && isAbsoluteEmpty(children)) {
        return _jsx(_Fragment, {});
    }
    return (_jsxs("div", __assign({ className: classNames(styles.item, className) }, { children: [_jsx("div", __assign({ className: styles.label }, { children: label })), !isAbsoluteEmpty(children) ? (_jsx("div", __assign({ className: classNames(styles.value, valueClassName) }, { children: children }))) : (_jsx("div", __assign({ className: classNames(styles.value, styles.muted) }, { children: i18n.exists(valueFallBack) ? t(valueFallBack) : valueFallBack })))] })));
};
export default DtlItem;
