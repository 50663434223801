import { makeAutoObservable, reaction, runInAction } from 'mobx'

import _ from 'lodash'
import moment from 'moment-timezone'

import { $psHistory, $psPatient } from '@src/store'

import { isAbsoluteEmpty } from '@helpers/other'

import { eventsSchema } from '@ps/config/events'
import {
  getEventsMinMaxDate,
  groupEventsBySubcategory,
  prepareEvents,
  sortEventsBy,
} from '@ps/helpers/events'
import psService from '@ps/services/psService'

import { MEDICAL_CONDITIONS_ACTIVE_STATUSES } from '@config/enums/fhirMedicalConditions'
import { MEDICATIONS_ACTIVE_STATUSES } from '@config/enums/fhirMedications'

class psHistoryStore {
  constructor() {
    makeAutoObservable(this)
  }

  /*
   * Category data
   */
  category = false
  subcategory = ''

  // clear
  clearSubcategory() {
    let result = this.category ? this.category + '_all' : ''

    if (this.categoryConfig?.subcategories) {
      const found = _.find(this.categoryConfig.subcategories, (x) => x.isDefault)

      if (found) {
        result = found.id
      }
    }

    this.subcategory = result
  }

  // Mutations
  SET_CATEGORY(value) {
    const categoryId = _.snakeCase(value)
    this.category = eventsSchema[categoryId] ? categoryId : ''
  }

  SET_SUBCATEGORY(value) {
    this.subcategory = value
  }

  // Computed
  get categoryConfig() {
    return eventsSchema[this.category] || {}
  }

  /*
   * Events data
   */
  _events = []

  // Computed
  get events() {
    return prepareEvents(this._events)
  }

  get eventsBySubcategory() {
    return groupEventsBySubcategory(this.events, eventsSchema)
  }

  get eventsMinMaxDate() {
    return getEventsMinMaxDate(this.eventsBySubcategory[this.subcategory])
  }

  //
  get encounters() {
    // todo maybe make from subcategory and isDisplay tab for eventsSchema
    return _.filter(this.events, (x) => x.configId === 'other_encounter')
  }

  // Mutations
  SET_EVENTS(value) {
    this._events = value
  }

  // Actions
  getEventById(id, subcategory = '') {
    let target = this.events

    if (subcategory && this.eventsBySubcategory[subcategory]) {
      target = this.eventsBySubcategory[subcategory]
    }

    return _.find(target, (x) => x.id === id) || {}
  }

  // maybe can delete
  getEncounterByAppointmentId(id) {
    return _.find(this.encounters, (x) => x.appointmentId === id) || {}
  }

  /*
   *    Table data
   */
  isLoading = false
  useSearched = false
  _searchedEvents = []

  searchString = ''
  dateFrom = '' // 2021-12-30
  dateTo = ''
  sortBy = 'date_desc'
  reported = ['staff'] // self staff
  eventType = []
  showMore = ['less']

  // clear
  clearSearchedEvents() {
    this._searchedEvents = []
    this.isLoading = false
    this.useSearched = false
  }

  clearSearch() {
    this.searchString = ''
    this.dateFrom = ''
    this.dateTo = ''
    this.sortBy = 'date_desc'
    this.reported = ['staff']
    this.eventType = []
    this.showMore = ['less']

    this.clearSearchedEvents()
  }

  // Computed
  get filteredEventsBySubcategory() {
    let result = this.eventsBySubcategory
    let ids = []

    // if there's been input search
    // need to make ids array of ids of events that we have after search
    // to search only among them
    if (this.useSearched) {
      // Maybe temporary solution
      // patient_stories.search_events returns data and dateTime format without differences
      // const searchedEvents = prepareEvents(this._searchedEvents);
      _.forEach(this._searchedEvents, (x) => {
        ids.push(x.id)

        if (x.appointmentId) {
          ids.push(x.appointmentId)
        }

        if (x.diagnosticreportId) {
          ids.push(x.diagnosticreportId)
        }
      })
    }

    result = _.mapValues(result, (subcategory) => {
      return subcategory.filter((x) => {
        let isSearchOK = true
        let isDateFromOk = true
        let isDateToOk = true
        let isReported = false
        let isEventType = false

        if (!isAbsoluteEmpty(this.useSearched)) {
          isSearchOK = Boolean(ids.includes(x.id))
        }
        const date = moment(x.effectiveDate)

        if (!isAbsoluteEmpty(this.dateFrom)) {
          isDateFromOk = date.isSameOrAfter(this.dateFrom, 'day')
        }

        if (!isAbsoluteEmpty(this.dateTo)) {
          isDateToOk = date.isSameOrBefore(this.dateTo, 'day')
        }

        if (
          !this.reported.length ||
          (this.reported.includes('self') && x.isSelfReported) ||
          (this.reported.includes('staff') && !x.isSelfReported)
        ) {
          isReported = true
        }

        if (!this.eventType.length || this.eventType.includes(x.configId)) {
          isEventType = true
        }

        return isSearchOK && isDateFromOk && isDateToOk && isReported && isEventType
      })
    })

    return result
  }

  get filteredEvents() {
    return this.filteredEventsBySubcategory[this.subcategory] || []
  }

  get table() {
    return sortEventsBy(this.filteredEvents, this.sortBy)
  }

  get overviewEvents() {
    let result = _.pick(this.eventsBySubcategory, ['medical_history_conditions', 'medications_all'])

    result = _.mapValues(result, (items, key) => {
      return items.filter((x) => {
        if (x.isSelfReported) return

        if (key === 'medical_history_conditions') {
          return MEDICATIONS_ACTIVE_STATUSES.includes(x.status)
        } else if (key === 'medications_all') {
          return MEDICAL_CONDITIONS_ACTIVE_STATUSES.includes(x.status)
        }
      })
    })

    return result
  }

  // useForm mutations
  SET_SEARCH_STRING(value) {
    runInAction(() => (this.searchString = value))
  }

  SET_DATE_FROM(value) {
    runInAction(() => (this.dateFrom = value))
  }

  SET_DATE_TO(value) {
    runInAction(() => (this.dateTo = value))
  }

  SET_SORT_BY(value) {
    runInAction(() => (this.sortBy = value))
  }

  SET_REPORTED(value) {
    runInAction(() => (this.reported = value))
  }

  SET_EVENT_TYPE(value) {
    runInAction(() => (this.eventType = value))
  }

  SET_SHOW_MORE(value) {
    runInAction(() => (this.showMore = value))
  }

  // useForm mutations

  // Mutations

  // Actions
  async loadEvents() {
    const response = await psService.searchEvents()
    const items = response.prepared?.eventHistory || []
    this.SET_EVENTS(items)
    return items
  }

  async searchEvents(params = {}) {
    if (isAbsoluteEmpty(this.searchString)) {
      this.clearSearchedEvents()
      return
    }

    const minSearchLength = 3

    if (this.searchString.length < minSearchLength) {
      return
    }

    this.isLoading = true

    const response = await psService.searchEvents({
      text: this.searchString,
      ...params,
    })
    const items = response.prepared?.eventHistory || []

    runInAction(() => {
      this._searchedEvents = items
      this.isLoading = false
      this.useSearched = true
    })

    return items
  }
}

const store = new psHistoryStore()

// update current patient
reaction(
  () => store.category,
  async (newValue, lastValue) => {
    if (lastValue !== false) {
      if (newValue) {
        await $psHistory.loadEvents()
      } else {
        await $psPatient.loadData()
      }
    }
  },
)

export default store
