import React from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import SidebarMenuItem from '@src/layouts/sidebar/SidebarMenuItem'

import { confirmAction } from '@library/ActionConfirmator'
import Button from '@library/ui/button/Button'

import { $appts, $user } from '@store'

import mainConfig from '@config/main'
import { routeActions } from '@config/routeActions'

import * as imgs from '@assets/images/menu'

const SidebarMenu = ({ onHideSidebar }) => {
  const { t } = useTranslation()

  const countAppts = $appts.activeList.length

  function checkAuth(e, props) {
    confirmAction('checkAuth', props, e)
  }

  return (
    <>
      {$user.user ? (
        <>
          <Button mode={['secondary']} autoTarget={routeActions.ROOT()} className="mb-20">
            {t('btn.return_to_app', { appName: mainConfig.parentProductName })}
          </Button>
          <SidebarMenuItem
            autoTarget={routeActions.APPTS()}
            onClick={onHideSidebar}
            params={{ exact: true }}
            icon={imgs.appointments}
            counter={countAppts}
          >
            {t('menu.appointments')}
          </SidebarMenuItem>
          <SidebarMenuItem
            autoTarget={routeActions.PATIENTS()}
            onClick={onHideSidebar}
            icon={imgs.circleOfCare}
          >
            {t('menu.circle_of_care')}
          </SidebarMenuItem>
          <SidebarMenuItem
            autoTarget={routeActions.PLACES()}
            onClick={onHideSidebar}
            icon={imgs.addresses}
          >
            {t('menu.addresses')}
          </SidebarMenuItem>
        </>
      ) : (
        <div className="text-center">
          <Button mode={['secondary', 'block']} action={checkAuth} label={t('btn.start')} />
        </div>
      )}
    </>
  )
}

export default observer(SidebarMenu)
