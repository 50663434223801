import React, { useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import { confirmAction } from '@library/ActionConfirmator'
import AddInsuranceModal from '@library/addInsuranceModal/AddInsurance.modal'
import { usePhone } from '@library/CustomHooks'
import InputBase from '@library/form/fields/new/InputBase'
import InputDate from '@library/form/fields/new/InputDate'
import UploadAvatar from '@library/form/fields/UploadAvatar'
import { useForm } from '@library/form/FormControllerNew'
import Modal from '@library/modal/Modal'
import PhoneNumber from '@library/phoneNumber/PhoneNumber'
import MultiSelect from '@library/selectDropdown/MultiSelect'
import Button from '@library/ui/button/Button'
import CustomLink from '@library/ui/customLink/CustomLink'

import { getEnumsValue } from '@helpers/enums'
import { ageLimit } from '@helpers/patients'
import { customGoTo } from '@helpers/router'

import { $loader, $modal, $patients, $user } from '@store'

import { ENUMS_INSURANCE_TYPES, INSURANCE_FIELDS } from '@config/enums/insurance'
import { ENUMS_PERSON_GENDER } from '@config/enums/person'
import { routeActions } from '@config/routeActions'

import styles from './AddPatientModal.module.scss'

const AddPatientModal = ({ editId = false, _core }) => {
  const { t } = useTranslation()

  const [patient] = useState(() => $patients.getById(editId))

  const { isDefault } = patient
  const dobType = isDefault ? 'profile' : 'patient'

  const { form } = useForm({
    avatar: { value: patient.avatar, rules: [] },
    firstName: { value: patient.firstName, rules: ['name', 'required'] },
    lastName: { value: patient.lastName, rules: ['name', 'required'] },
    gender: { value: patient.gender, rules: ['gender', 'required'], prepValue: 'singleSelect' },
    dob: { value: patient.dob, rules: [{ name: 'dob', dobType }, 'required'] },
    email: {
      value: patient.email,
      rules: ['email', 'required'],
      output: 'emptyUndefined',
    },
    phone: {
      value: usePhone(patient.phone),
      rules: [{ name: 'phone', required: true }],
      output: ['getPhone', 'emptyUndefined'],
    },
    code: { value: patient.code, rules: [] },
    insuranceType: { value: patient.insuranceType, output: ['string', 'emptyNull'] },
    insuranceProviderName: {
      value: patient.insuranceProviderName,
      output: ['string', 'emptyNull'],
    },
    insuranceProviderId: {
      value: patient.insuranceProviderId,
      output: ['strict', 'string', 'emptyNull'],
    },
    insuranceGroupId: {
      value: patient.insuranceGroupId,
      output: ['strict', 'string', 'emptyNull'],
    },
    insuranceMemberFirstName: {
      value: patient.insuranceMemberFirstName,
      output: ['string', 'emptyNull'],
    },
    insuranceMemberLastName: {
      value: patient.insuranceMemberLastName,
      output: ['string', 'emptyNull'],
    },
    insuranceMemberId: {
      value: patient.insuranceMemberId,
      output: ['strict', 'string', 'emptyNull'],
    },
    insurancePolicyHolder: {
      value: patient.insurancePolicyHolder,
      output: ['string', 'emptyNull'],
    },
  })

  const {
    avatar,
    firstName,
    lastName,
    gender,
    dob,
    email,
    phone,
    code,
    insuranceType,
    insuranceMemberId,
  } = form.fields

  const handleAddInsurance = (e) => {
    e?.preventDefault()

    $modal.add(AddInsuranceModal, {
      onDone: (insuranceFields) => {
        _.forEach(insuranceFields, (value, fieldName) => {
          form.fields[fieldName].onChange(value)
        })
      },
    })
  }

  const handleRmInsurance = () => {
    INSURANCE_FIELDS.forEach((x) => {
      form.fields[x].onChange(null)
    })
  }

  function handleRemove(e) {
    e.stopPropagation()

    confirmAction(
      'customAlert',
      {
        data: {
          title: t('add_patient.remove_alert.title'),
          message: t('add_patient.remove_alert.message'),
        },
        callback: async () => {
          customGoTo(routeActions.PATIENTS())
          await $patients.remove(editId)
          _core.onHide()
        },
      },
      e,
    )
  }

  const handleSubmit = $loader.registerHandler('add-patient-modal-save', async (e) => {
    e.preventDefault()

    if (form.validateAll().isValid) {
      _core.onBlock()

      const data = form.values

      let res

      if (editId) {
        if (isDefault) {
          res = await $user.saveProfile({ id: editId, ...data })
          await $patients.get()
        } else {
          res = await $patients.update(editId, data)
        }
      } else {
        res = await $patients.add(data)
      }
      _core.onUnblock()

      if (res.isSuccess) {
        _core.onHide(true, res.patient)
      }
    }
  })

  return (
    <Modal centered scrollable>
      <div className="modal-toolbar">
        <div className="modal-toolbar-btn modal-toolbar-btn--close" onClick={_core.onHide} />
      </div>
      <Modal.Header>
        {editId ? t('add_patient.title.details') : t('add_patient.title.add')}
      </Modal.Header>
      <Modal.Body>
        <form id="book_add_patient" onSubmit={handleSubmit}>
          <UploadAvatar
            user={{ ...patient, firstName: firstName.value, lastName: lastName.value }}
            field={avatar}
            value={avatar.value}
            onChange={avatar.onChange}
          />

          <div className="row row--gutters-5">
            <div className="col-6">
              <InputBase
                className="mb-10"
                label={t('user.label.first_name')}
                field={firstName}
                mode="lg"
              />
            </div>
            <div className="col-6">
              <InputBase
                className="mb-10"
                label={t('user.label.last_name')}
                field={lastName}
                mode="lg"
              />
            </div>
          </div>

          <MultiSelect
            className="mb-10"
            label={t('label.sex_assigned_at_birth')}
            items={Object.values(ENUMS_PERSON_GENDER).map((x) => ({ id: x.id, text: t(x.tKey) }))}
            field={gender}
            mode="lg"
          />

          <div className="row row--gutters-5">
            <div className="col-sm-6">
              <InputDate
                className="mb-10"
                minDate={ageLimit(dobType).from}
                maxDate={ageLimit(dobType).to}
                label={t('calendar.dob')}
                field={dob}
                mode="lg"
              />
            </div>
            <div className="col-sm-6">
              <InputBase className="mb-10" label={t('user.label.code')} field={code} mode="lg" />
            </div>
          </div>

          {/*{editId && 'relationship'}*/}

          <InputBase
            label={t('user.label.email')}
            field={email}
            type="email"
            placeholder={t('user.ph.email')}
            mode="lg"
          />

          <InputBase className={'mb-10'} label={t('user.label.phone')} field={phone} mode="lg">
            <PhoneNumber
              popupClass={'phone_number-popup--on_top'}
              phone={phone.value}
              onChange={phone.onChange}
            />
          </InputBase>

          {!insuranceType.value ? (
            <Button
              tag="button"
              mode={['secondary-dark', 'icon']}
              className="mb-10 btn-block"
              action={handleAddInsurance}
            >
              {t('btn.add_insurance')}
            </Button>
          ) : (
            <div className={classNames(styles.insurance, 'mb-10')}>
              <p className={styles.insuranceTitle}>{t('label.health_insurance')}</p>
              <p className={styles.insuranceData}>
                {getEnumsValue(insuranceType.value, ENUMS_INSURANCE_TYPES)}{' '}
                <span className="text-muted">&bull; {insuranceMemberId.value}</span>
              </p>
              <div className={styles.insuranceRm} onClick={handleRmInsurance} />
            </div>
          )}

          {editId && (
            <>
              <CustomLink
                autoTarget={routeActions.PATIENT_APPTS({ patientId: editId })}
                onClick={_core.onHide}
                className="btn btn-arrow"
              >
                {t('add_patient.btn.history')}
              </CustomLink>

              {!isDefault && (
                <Button mode={['secondary-dark', 'block']} className="mt-30" action={handleRemove}>
                  {t('add_patient.btn.delete')}
                </Button>
              )}
            </>
          )}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          tag="button"
          mode={['primary', 'block']}
          form="book_add_patient"
          isLoading={$loader.isRunHandler('add-patient-modal-save')}
        >
          {t('btn.save')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default observer(AddPatientModal)
