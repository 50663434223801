import { useCallback, useEffect, useRef } from 'react'

import { types } from 'mobx-state-tree'

import {
  outputPresets,
  prepValuePresets,
  validationRules,
} from '@library/form/FormController.helpers'

import { getUuid, isAbsoluteEmpty } from '@helpers/other'

const updateStoreValue = ({ value, fieldName, store, storeUpdateMethod }) => {
  if (!store) {
    return false
  }

  if (storeUpdateMethod) {
    if (_.isString(storeUpdateMethod)) store[storeUpdateMethod](value)
    else if (_.isFunction(storeUpdateMethod)) storeUpdateMethod(value)
  } else if (_.isObject(store)) {
    const method = 'SET_' + _.toUpper(_.snakeCase(fieldName))
    if (_.isFunction(store[method])) store[method](value)
  }
}

const Field = types
  .model({
    id: '',
    counter: types.number,
    name: types.identifier,
    value: types.frozen(),
    isValid: true,
    isInvalid: false,
    errors: types.array(types.string),
    isChanged: false,
    isDeleted: false,
    changeTime: '',
    customParams: types.optional(types.frozen(), {}),
    group: '',

    customParamsRules: types.frozen(),
    customParamsValidity: types.frozen(),

    prepValue: '', // prepare value before onChange
    output: types.union(types.array(types.string), types.string, types.undefined),
    rules: types.frozen(),
    store: types.frozen(),
    storeUpdateMethod: types.frozen(),
    initialState: types.frozen(),
  })
  .actions((self) => ({
    afterCreate() {
      self.initialState = _.pick(self.toJSON(), [
        'id',
        'group',
        'value',
        'changeTime',
        'isChanged',
        'isDeleted',
        'customParams',
      ])
    },
    setValue(value) {
      self.value = value
      self.setIsChanged()
      self.isInvalid = false
      self.isValid = true

      updateStoreValue({
        value: self.value,
        fieldName: self.name,
        store: self.store,
        storeUpdateMethod: self.storeUpdateMethod,
      })
    },
    onChange(e) {
      const preparedValue = presetHandler(e, self.prepValue || 'value', 'prepValue')
      self.setValue(preparedValue)
    },
    resetToInitialState(include = []) {
      const exclude = ['customParams'].filter((x) => !_.includes(include, x))
      const filteredItems = _.omit(self.initialState, exclude)

      _.forEach(filteredItems, (value, key) => {
        self[key] = value
      })
    },
    setIsChanged() {
      const compareList = ['value', 'isDeleted', 'customParams']

      if (_.isEqual(_.pick(self, compareList), _.pick(self.initialState, compareList))) {
        self.resetToInitialState()
      } else {
        self.isChanged = true
        self.changeTime = new Date().toISOString()
      }
    },
    setErrors(errors) {
      self.errors = errors
      self.isValid = _.isEmpty(errors)
      self.isInvalid = !_.isEmpty(errors)
    },
    setIsDeleted(value = false) {
      self.isDeleted = value
      self.setIsChanged()
    },
    setCustomParams(customParamsObj) {
      self.customParams = customParamsObj
      _.forEach(self.customParams, (paramValue, paramKey) => {
        self.customParamsValidity = {
          ...self.customParamsValidity,
          [paramKey]: true,
        }
      })
      self.setIsChanged()
    },
    setSpecificCustomParam(key, value) {
      self.customParams = {
        ...self.customParams,
        [key]: value,
      }
      self.customParamsValidity = {
        ...self.customParamsValidity,
        [key]: true,
      }
      self.setIsChanged()
    },
    setCustomParamValidity(key, value) {
      self.customParamsValidity = {
        ...self.customParamsValidity,
        [key]: value,
      }
    },
  }))
  .views((self) => ({
    getOutputValue() {
      return presetHandler(self.value, self.output, 'output')
    },
  }))

const Form = types
  .model({
    counter: 1,
    _fields: types.optional(types.map(Field), {}),
    isRegistered: false,
  })
  .actions((self) => ({
    addFieldToGroup(groupId, data) {
      self.addField(groupId + self.counter, { ...data, group: groupId })
    },
    addField(name, data) {
      let usedValue = data.value

      if (data.store) {
        if (!isAbsoluteEmpty(data.value))
          updateStoreValue({
            value: usedValue,
            fieldName: name,
            store: data.store,
            storeUpdateMethod: data.storeUpdateMethod,
          })
        else if (_.isUndefined(data.value)) {
          usedValue = data.store[name]
        }
      }
      let customParamsValidity = {}
      let customParamsRules = {}
      _.forEach(data.customParamsRules, (paramRules, paramKey) => {
        customParamsRules[paramKey] = prepareRules(paramRules)
      })

      _.forEach(data.customParams, (paramValue, paramKey) => {
        customParamsValidity[paramKey] = true
      })

      data.customParamsValidity = customParamsValidity

      self._fields.set(
        name,
        Field.create({
          ...data,
          counter: self.counter,
          id: data.id || getUuid(),
          name,
          value: usedValue,
          rules: prepareRules(data.rules),
          customParamsRules,
        }),
      )

      self.counter = self.counter + 1
    },
    register(items) {
      if (!self.isRegistered) {
        _.forEach(items, (value, key) => {
          if (_.isArray(value)) {
            _.forEach(value, (groupValue) => self.addFieldToGroup(key, groupValue))
          } else {
            self.addField(key, value)
          }
        })

        self.isRegistered = true
      }
    },
    validate(name, withCustomParams = false) {
      const errors = []
      const field = self._fields.get(name)

      _.forEach(field.rules, (rule) => {
        const result = validByRule(field.value, rule, field.rules, self)

        if (!result.isValid) {
          errors.push(result.error)
        }
      })

      field.setErrors(errors)
      if (withCustomParams) {
        _.forEach(field.customParamsRules, (paramRules, paramKey) => {
          _.forEach(paramRules, (rule) => {
            const result = validByRule(field.customParams[paramKey], rule, paramRules, self)
            if (!result.isValid) {
              field.setCustomParamValidity(paramKey, false)
            }
          })
        })
      }
      return getValidResult(errors)
    },
    validateAll(withCustomParams = false) {
      const errors = []

      self._fields.forEach((value, key) => {
        const result = self.validate(key, withCustomParams)
        if (!result.isValid) errors.push({ key, ...result })
      })

      return getValidResult(errors)
    },
    getOutputValue(name) {
      const field = self._fields.get(name)
      return field.getOutputValue()
    },
    forceError(name, value = true) {
      const field = self._fields.get(name)

      if (value) {
        if (field.errors.includes('force_error')) return false
        else field.setErrors([...field.errors, 'force_error'])
      } else field.setErrors(field.errors.filter((el) => el !== 'force_error'))
    },
    deleteField(name, imitation = false) {
      if (imitation) {
        const field = self._fields.get(name)
        field.setIsDeleted(true)
      } else {
        self._fields.delete(name)
      }
    },
  }))
  .views((self) => ({
    getGroup(name) {
      let group = []

      self._fields.forEach((value) => {
        if (value.group === name) group.push(value)
      })

      return group
    },
    get groups() {
      let result = {}

      self._fields.forEach((value) => {
        if (value.group) {
          if (result[value.group]) {
            result[value.group].push(value)
          } else {
            result[value.group] = [value]
          }
        }
      })

      return result
    },
    get fields() {
      let result = {}

      self._fields.forEach((field, name) => {
        result[name] = field
      })

      return result
    },
    get values() {
      return _.mapValues(self._fields.toJSON(), (field, name) => self.getOutputValue(name))
    },
    get isChanged() {
      return _.some(self._fields.toJSON(), (x) => x.isChanged)
    },
  }))

export const useForm = (params) => {
  const form = useRef(Form.create())

  form.current.register(params)

  // todo: add update field after change params

  function useFormOnChange(callback, deps = [], delay = 400) {
    const fn = useCallback(_.debounce(callback, delay), []) //eslint-disable-line react-hooks/exhaustive-deps
    useEffect(fn, deps) //eslint-disable-line react-hooks/exhaustive-deps
  }

  return { form: form.current, useFormOnChange }
}

const prepareRules = (rules) => {
  const result = []

  _.forEach(rules, (item) => {
    if (_.isString(item)) result.push({ name: item })
    else if (_.isFunction(item)) result.push({ name: 'func', value: item })
    else if (_.isRegExp(item)) result.push({ name: 'pattern', value: item })
    else if (!_.isEmpty(item)) result.push(item)
  })

  return result
}

function validByRule(value, rule = {}, rules, selfForm) {
  const { name, condition } = rule

  if (_.isFunction(condition) && !condition(value, rule, rules, selfForm)) return { isValid: true }

  const handler = validationRules[name]

  if (!handler) {
    console.warn('Unknown validation rule ', name)
    return { isValid: false, error: 'unknown_rule: ' + name }
  }

  const isNotReq =
    valueIsEmpty(value) &&
    !hasRule('required', rules) &&
    !hasRule('minLen1OrEmpty', rules) &&
    !hasRule('func', rules)

  if (isNotReq || handler(value, rule, rules, selfForm)) return { isValid: true }

  return { isValid: false, error: name }
}

const valueIsEmpty = (value) => _.isEmpty(_.isString(value) ? value.trim() : value)

const hasRule = (ruleName, rules) => _.find(rules, (x) => x.name === ruleName)

function getValidResult(errors = []) {
  const isValid = _.isEmpty(errors)
  return { isValid, isInvalid: !isValid, errors }
}

/*

action ---- fn() || 'presetName' || ['presetName1', 'presetName2', ...]

  */
function presetHandler(value, action, preset) {
  if (!action) return value

  let arr = _.isArray(action) ? action : [action]
  let result = value

  _.forEach(arr, (item) => {
    if (_.isFunction(item)) result = item(result)
    else if (_.isString(item)) {
      let handler

      if (preset === 'output') handler = outputPresets[item]
      else if (preset === 'prepValue') handler = prepValuePresets[item]

      if (_.isFunction(handler)) result = handler(result)
    }
  })

  return result
}
