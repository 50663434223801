var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { useInnerValue } from '@library/CustomHooks';
import { getUuid, isAbsoluteEmpty } from '@helpers/other';
var initField = {
    value: '',
};
var TextareaBase = forwardRef(function (_a, forwardedRef) {
    var _b = _a.field, field = _b === void 0 ? initField : _b, 
    //
    onClick = _a.onClick, onClear = _a.onClear, className = _a.className, fieldClassName = _a.fieldClassName, label = _a.label, _c = _a.labelClamp, labelClamp = _c === void 0 ? 1 : _c, _d = _a.headerActions, headerActions = _d === void 0 ? [] : _d, placeholder = _a.placeholder, _id = _a.id, _e = _a.clear, clear = _e === void 0 ? false : _e, _f = _a.readonly, readonly = _f === void 0 ? false : _f, mode = _a.mode, _g = _a.rows, rows = _g === void 0 ? 3 : _g, onRemove = _a.onRemove, _h = _a.canRemove, canRemove = _h === void 0 ? false : _h, fieldAttributes = __rest(_a, ["field", "onClick", "onClear", "className", "fieldClassName", "label", "labelClamp", "headerActions", "placeholder", "id", "clear", "readonly", "mode", "rows", "onRemove", "canRemove"]);
    var value = field.value, onChange = field.onChange, isInvalid = field.isInvalid;
    var _j = useInnerValue(value), innerValue = _j[0], setInnerValue = _j[1];
    var id = useMemo(function () { return (_id ? _id : getUuid()); }, [_id]);
    function handleOnChange(value) {
        setInnerValue(value);
        if (_.isFunction(onChange)) {
            onChange(value);
        }
    }
    function handleClear(e) {
        if (e && _.isFunction(e.stopPropagation)) {
            e.stopPropagation();
        }
        handleOnChange('');
        if (_.isFunction(onClear)) {
            onClear();
        }
    }
    function getMode(mode) {
        var array = _.isArray(mode) ? mode : [mode];
        return array.map(function (x) { return 'tsform-item--' + x; }).join(' ');
    }
    var fieldRef = useRef(null);
    // @ts-ignore
    useImperativeHandle(forwardedRef, function () { return ({
        focus: function () {
            fieldRef.current.focus();
        },
        clear: function () {
            handleClear();
        },
    }); });
    return (_jsxs("div", __assign({ className: classNames(className, 'tsform-item', 'tsform-item--textarea', mode && getMode(mode), isInvalid && 'tsform-item--error', readonly && 'tsform-item--readonly', canRemove && _.isFunction(onRemove) && 'tsform-item--with_remove') }, { children: [_jsxs("label", __assign({ className: "tsform-item-inner" }, { children: [(label || headerActions.length > 0) && (_jsxs("div", __assign({ className: "tsform-header" }, { children: [label && (_jsx("div", __assign({ className: classNames('tsform-label', labelClamp && 'tsform-label-clamp-' + labelClamp) }, { children: label }))), headerActions.length > 0 &&
                                headerActions.map(function (action) { return (_jsx("div", __assign({ className: "tsform-header-action", onClick: function () { return action.onClick(); } }, { children: action.text }), action.text)); })] }))), _jsx("div", __assign({ className: "tsform-body" }, { children: _jsxs("div", __assign({ className: classNames('tsform-field', fieldClassName) }, { children: [_jsx("textarea", __assign({ ref: fieldRef, rows: rows, value: innerValue, onChange: function (e) { return handleOnChange(e.target.value); }, id: id, placeholder: placeholder, onClick: onClick, readOnly: readonly }, fieldAttributes)), clear && !isAbsoluteEmpty(value) && (_jsx("div", { className: "tsform-field-clear", onClick: handleClear }))] })) }))] })), canRemove && _.isFunction(onRemove) && (_jsx("div", { className: "tsform-item-remove_button", onClick: onRemove }))] })));
});
export default observer(TextareaBase);
