import { makeAutoObservable, runInAction } from 'mobx'

import apiService from '@services/api'

import useCache from '@helpers/useCache'

import mainConfig from '@config/main'

class BookingServicesStore {
  _rootStore

  constructor(store) {
    makeAutoObservable(this)
    this._rootStore = store
  }

  _items = []

  // Computed

  get items() {
    return this._items.map((item) => {
      const { agentLoad, providerLoad } = item

      const additionalParams = {
        isVirtual: false,
        isAtHome: false,
        isAtClinic: false,
      }

      if (item.reasons.includes('clinic')) {
        additionalParams.isAtClinic = true
      } else {
        if (
          _.includes([null, 'virtual'], agentLoad) &&
          _.includes([null, 'virtual'], providerLoad)
        ) {
          additionalParams.isVirtual = true
        }
        if (agentLoad === 'onsite' && _.includes([null, 'virtual'], providerLoad)) {
          additionalParams.isAtHome = true
        }
      }

      return { ...item, ...additionalParams }
    })
  }

  // Mutations

  // Actions
  getServiceById(id) {
    return this.items.find((i) => i.id === id)
  }

  load = useCache(async () => {
    const filter = {
      and: [
        { eq: ['is_active', true] },
        { ne: ['patient_group_type', 'group'] },
        { contains: ['allowed_to', [mainConfig.appType]] },
      ],
    }

    const response = await apiService.fetchV3('services.list', {
      filter: filter,
      limit: 1000,
    })

    runInAction(() => {
      this._items = _.get(response, 'prepared.services.items', [])
    })

    return response
  }, 900)
}

export default BookingServicesStore
