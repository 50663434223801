import React from 'react'

import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import styles from './Types.module.scss'

const Unknown = () => {
  const { t } = useTranslation()

  return (
    <div className={classNames(styles.unknown, styles.isDefault)}>
      <div className={styles.defaultContent}>
        <div className={styles.icon} />
        <div className={styles.text}>{t('errors.preview_unavailable')}</div>
      </div>
    </div>
  )
}

export default Unknown
