import React from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import Toolbar from '@library/toolbar/Toolbar'
import CustomLink from '@library/ui/customLink/CustomLink'

import { routeActions } from '@config/routeActions'

const Error404 = () => {
  const { t } = useTranslation()

  return (
    <div className="content">
      <Toolbar />
      <div className="container">
        <h1>{t('errors.404')}</h1>
        <CustomLink autoTarget={routeActions.ROOT()}>{t('btn.back_to_home')}</CustomLink>
      </div>
    </div>
  )
}

export default observer(Error404)
