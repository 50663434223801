import React, { useMemo } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { useLoader } from '@library/CustomHooks'
import Modal from '@library/modal/Modal'
import PatientsList from '@library/patients/patientsList/PatientsList'

import { $booking } from '@store'

import styles from './SelectPatient.modal.module.scss'

const SelectPatientModal = ({ selectedIds = [], onSelect, _core }) => {
  const { t } = useTranslation()

  const isLoading = useLoader(async () => {
    return await $booking.$patients.get()
  })

  const items = useMemo(() => {
    return $booking.$patients.originalItems.filter((x) => !selectedIds.includes(x.id))
  }, [$booking.$patients.originalItems, selectedIds]) // eslint-disable-line react-hooks/exhaustive-deps

  function handleOnSelect(item) {
    onSelect(item)
    _core.onHide()
  }

  return (
    <Modal centered scrollable>
      <div className="modal-toolbar">
        <div className="modal-toolbar-btn modal-toolbar-btn--close" onClick={_core.onHide} />
      </div>
      <Modal.Header>{t('book.select_patient')}</Modal.Header>
      <Modal.Body isLoading={isLoading} className={styles.modalBody}>
        <PatientsList
          items={items}
          onClick={handleOnSelect}
          withSearch={true}
          addOnTop={true}
          addOnDone={handleOnSelect}
        />
      </Modal.Body>
    </Modal>
  )
}

export default observer(SelectPatientModal)
