import React, { useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import BookingLayout from '@booking/library/bookingLayout/BookingLayout'
import PaymentSelector from '@booking/library/paymentSelector/PaymentSelector'
import withBookingDataInit from '@booking/library/withBookingDataInit/withBookingDataInit'
import CopyVoucher from '@booking/pages/bookingFinish/copyVoucher/CopyVoucher'
import PatientItem from '@booking/pages/bookingFinish/patientItem/PatientItem'

import clientService from '@services/client'

import InputBase from '@library/form/fields/new/InputBase'
import Button from '@library/ui/button/Button'

import { customGoTo } from '@helpers/router'
import { formatAmount } from '@helpers/text'
import { formatDate } from '@helpers/time'

import { $booking } from '@store'

import mainConfig from '@config/main'

import styles from './BookingFinish.module.scss'

const BookingFinish = () => {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  const handleBook = async () => {
    setIsLoading(true)
    const response = await clientService.createAppointment($booking.bookingPayload)
    if (!response.error && response.prepared) {
      const appointmentId = response.prepared.appointment.id
      customGoTo($booking.schemaConfig?.nextStep({ appointmentId }))
      $booking.clear()
    } else {
      setIsLoading(false)
    }
  }

  const formattedPrice = formatAmount($booking.payments.total, $booking.practiceService.currency)

  return (
    <BookingLayout
      title={t('book_details.title')}
      isMobileFooterFixed={true}
      isDesktopFooterFixed={false}
      isLoading={isLoading}
      button={
        <Button
          mode="primary"
          tag="button"
          buttonType="button"
          disabled={$booking.payments.total > 0 && _.isEmpty($booking.paymentMethod)}
          label={
            $booking.practiceService.price > 0
              ? t('book_details.btn.price_book_now', { price: formattedPrice })
              : t('book_details.btn.book_now')
          }
          action={handleBook}
        />
      }
    >
      <InputBase
        label={t('book_details.label.address')}
        field={{ value: $booking.placeExtended.buildingStreetCity() }}
        readonly={true}
        mode={['lg', 'gray-without-border', 'value-bold']}
      />
      <div className={styles.visitContainer}>
        <InputBase
          label={t('book_details.label.visit')}
          field={{
            value: t('book_details.label.service.to_practice', {
              service: $booking.service.name,
              practice: $booking.practice?.name,
            }),
          }}
          readonly={true}
          mode={['lg', 'gray-without-border', 'value-bold']}
        />
        <InputBase
          label={t('label.visit_date')}
          field={{
            value: formatDate($booking.selectedTime, {
              format: 'datetimeFullNoYear',
              timezone: $booking.place.timezone,
            }),
          }}
          readonly={true}
          mode={['lg', 'gray-without-border', 'value-bold']}
        />
      </div>

      <div className={styles.title}>{t('book_details.label.patients')}</div>
      {$booking.$patients.items.map((item, index) => (
        <PatientItem key={item.id} patient={item} number={index + 1} />
      ))}
      {$booking.voucher && $booking.practiceService.price > 0 ? (
        <CopyVoucher className={styles.copyVoucher} />
      ) : null}

      {$booking.payments.total > 0 ? (
        <>
          <div className={styles.title}>
            {t('book_details.label.total', { amount: formattedPrice })}
          </div>
          <PaymentSelector
            selected={$booking.paymentMethodId}
            onChangeSelected={$booking.SET_PAYMENT_METHOD_ID}
          />
        </>
      ) : null}
    </BookingLayout>
  )
}

export default withBookingDataInit(observer(BookingFinish))
