import { useEffect, useState } from 'react'

import maps from '@library/map/Map'

class SelectAddressHooks {
  usePlaceDetails = (_place) => {
    const [details, setDetails] = useState({})

    useEffect(() => {
      if (_place) {
        ;(async () => {
          const details = await maps.fetchPlaceDetails(_place)
          setDetails(details)
        })()
      }
    }, [_place])

    return {
      details,
      isDetailed: !_.isEmpty(details) && !_.isEmpty(details.coords),
    }
  }
}

export default new SelectAddressHooks()
