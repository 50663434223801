var DocumentUploaderTypes;
(function (DocumentUploaderTypes) {
    var Status;
    (function (Status) {
        Status[Status["UPLOADING"] = 0] = "UPLOADING";
        Status[Status["UPLOADED"] = 1] = "UPLOADED";
        Status[Status["ERROR"] = 2] = "ERROR";
    })(Status = DocumentUploaderTypes.Status || (DocumentUploaderTypes.Status = {}));
})(DocumentUploaderTypes || (DocumentUploaderTypes = {}));
export default DocumentUploaderTypes;
