import React from 'react'

import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'

import Toolbar from '@library/toolbar/Toolbar'

import { toPath, useRouterSearchParams } from '@helpers/router'

import { $psHistory } from '@store'

import { routeActions } from '@config/routeActions'

const PatientToolbar = ({ hasVitals }) => {
  const params = useParams()
  const { patientId } = params
  const searchParams = useRouterSearchParams()
  const backToApptUrl = searchParams.get('backToApptUrl')

  const isOutlet = $psHistory.category || hasVitals

  return (
    <Toolbar
      goBackProps={{
        autoTarget: isOutlet
          ? {
              to: toPath('psPatient', {
                params: { ...params, categoryId: undefined },
                query: { ...(backToApptUrl && { backToApptUrl }) },
              }),
            }
          : routeActions.PATIENT({ patientId }),
      }}
      useBackToApptUrl={!isOutlet}
    />
  )
}

export default observer(PatientToolbar)
