import { isAbsoluteEmpty } from '@helpers/other'

import { ENUMS_INSURANCE_POLICY_HOLDERS, ENUMS_INSURANCE_TYPES } from '@config/enums/insurance'

const MEDICARE_DEFAULT_PROVIDER = 'Medicare'

export const insuranceTypes = Object.values(ENUMS_INSURANCE_TYPES).map((x) => {
  let result = { id: x.id, text: x.tKey }

  if (x.id === 'medicare') {
    result.exportKeys = ['insuranceType', 'insuranceProviderName', 'insuranceMemberId']
  }

  return result
})

export const insurancePolicyHolders = Object.values(ENUMS_INSURANCE_POLICY_HOLDERS).map((x) => ({
  id: x.id,
  text: x.tKey,
}))

export const insuranceFields = {
  insuranceType: {
    key: 'insurance_type',
    defaultValue: '',
    fieldType: 'select',
    items: insuranceTypes,
    isVisible: true,
    rules: ['required'],
    onChange: (value, { fields }) => {
      if (value === 'medicare') {
        fields.insuranceProviderName.onChange(MEDICARE_DEFAULT_PROVIDER)
        fields.insuranceProviderId.onChange('')
      } else if (fields.insuranceProviderName.value === MEDICARE_DEFAULT_PROVIDER) {
        fields.insuranceProviderName.onChange('')
        fields.insuranceProviderId.onChange('')
      }
    },
  },
  insuranceProviderName: {
    key: 'insurance_provider_name',
    defaultValue: '',
    fieldType: 'selectProvider',
    isVisible: ({ values }) => values.insuranceType !== 'medicare',
    rules: ['required'],
  },
  insuranceProviderId: {
    key: 'insurance_provider_id',
    defaultValue: '',
    fieldType: '',
    isVisible: false,
  },
  insuranceGroupId: {
    key: 'insurance_group_id',
    defaultValue: '',
    fieldType: 'input',
    isVisible: ({ values }) => values.insuranceType !== 'medicare',
    rules: [
      (value, rule, rules, { values }) => {
        return isAbsoluteEmpty(value) ? values.insuranceType === 'medicare' : true
      },
    ],
  },
  insuranceMemberFirstName: {
    key: 'insurance_member_first_name',
    defaultValue: '',
    fieldType: 'input',
    isVisible: ({ values }) => values.insuranceType !== 'medicare',
    rules: [
      (value, rule, rules, { values }) => {
        return isAbsoluteEmpty(value) ? values.insuranceType === 'medicare' : true
      },
    ],
  },
  insuranceMemberLastName: {
    key: 'insurance_member_last_name',
    defaultValue: '',
    fieldType: 'input',
    isVisible: ({ values }) => values.insuranceType !== 'medicare',
    rules: [
      (value, rule, rules, { values }) => {
        return isAbsoluteEmpty(value) ? values.insuranceType === 'medicare' : true
      },
    ],
  },
  insuranceMemberId: {
    key: ({ values }) =>
      values.insuranceType === 'medicare' ? 'medicare_number' : 'insurance_member_id',
    defaultValue: '',
    fieldType: 'input',
    isVisible: true,
    rules: ['required'],
  },
  insurancePolicyHolder: {
    key: 'insurance_policy_holder',
    defaultValue: '',
    fieldType: 'select',
    items: insurancePolicyHolders,
    isVisible: ({ values }) => values.insuranceType !== 'medicare',
    rules: [
      (value, rule, rules, { values }) => {
        return isAbsoluteEmpty(value) ? values.insuranceType === 'medicare' : true
      },
    ],
  },
}
