import { $configure } from '@library/plugins/configure/configure.store'
import ConfigureCoeModal from '@library/plugins/configure/modals/ConfigureCoe.modal'
import ConfigureFillingCardModal from '@library/plugins/configure/modals/ConfigureFillingCard.modal'
import ConfigureTosModal from '@library/plugins/configure/modals/ConfigureTos.modal'
import { TOS_PROVIDER } from '@library/plugins/termsOfService/Tos.config'

import { localStore } from '@helpers/other'

import { $modal, $user } from '@store'

import mainConfig from '@config/main'

export const availableConfigureSteps = [
  {
    name: 'tos',
    condition: () =>
      !$configure.checkTosVersion('client') || !$configure.checkTosVersion('client_pp'),
    action: () => $modal.add(ConfigureTosModal, { canDrop: false, uniq: false }),
  },
  {
    name: 'tos-provider',
    condition: () => {
      if (!mainConfig.custom.tosProviderEnabled) {
        return false
      }

      return !$configure.checkTosVersion('provider')
    },
    action: () =>
      $modal.add(ConfigureTosModal, { canDrop: false, uniq: false, type: TOS_PROVIDER }),
  },
  {
    name: 'coe',
    condition: () => !$configure.checkTosVersion('emergency_instructions'),
    action: () => $modal.add(ConfigureCoeModal, { canDrop: false }),
  },
  {
    name: 'user',
    condition: () => !$user.isFilledProfile,
    action: () => $modal.add(ConfigureFillingCardModal, { canDrop: false }),
  },
]
