import _ from 'lodash';
import moment from 'moment-timezone';
export var changeDateMonth = function (date, isNextMonth) {
    // Decrease year
    if (date.month() === 0 && !isNextMonth) {
        return date
            .clone()
            .set('year', date.year() - 1)
            .set('month', 11);
    }
    // Increase year
    if (date.month() === 11 && isNextMonth) {
        return date
            .clone()
            .set('year', date.year() + 1)
            .set('month', 0);
    }
    // Add or substract
    return date.clone().add(isNextMonth ? 1 : -1, 'months');
};
var _getCalendarCells = function (date, shownOtherDays) {
    var daysInMonth = date.daysInMonth();
    var calendarCells = [];
    var prepareCell = function (date, dayNumber, isShown) {
        if (isShown === void 0) { isShown = true; }
        return {
            text: isShown ? String(dayNumber) : '',
            value: date.clone().date(dayNumber),
        };
    };
    // Push current month day cells
    for (var i = 0; i < daysInMonth; i++) {
        calendarCells.push(prepareCell(date, i + 1));
    }
    // Fill in the cells of other months
    var cellsToAdd = 42 - daysInMonth;
    var weekdayNames = moment.weekdaysMin();
    // Add to start from previous month
    var firstWeekDay = date.clone().startOf('month').format('dd');
    var addToStart = weekdayNames.indexOf(firstWeekDay);
    if (addToStart > 0) {
        var lastMonth = date.clone().subtract(1, 'month');
        for (var i = 0; i < addToStart; i++) {
            calendarCells.unshift(prepareCell(lastMonth, lastMonth.daysInMonth() - i, shownOtherDays));
        }
    }
    // Add to end from next month
    var addToEnd = cellsToAdd - addToStart;
    if (addToEnd > 0) {
        var nextMonth = date.clone().add(1, 'month');
        for (var i = 0; i < addToEnd; i++) {
            calendarCells.push(prepareCell(nextMonth, i + 1, shownOtherDays));
        }
    }
    return calendarCells;
};
export var getCalendarRows = function (date, shownOtherDays) {
    var cells = _getCalendarCells(date, shownOtherDays);
    var itemOnRow = 7;
    return _.chunk(cells, itemOnRow);
};
var _getYearCells = function (date, start, end) {
    var cells = [];
    for (var i = start; i <= end; i++) {
        cells.push({
            text: String(i),
            value: date.clone().set('year', i),
        });
    }
    return cells;
};
export var getYearRows = function (date, minDate, maxDate) {
    var modelYear = date.get('year');
    var start = moment(minDate).get('year');
    var end = moment(maxDate).get('year');
    if (modelYear < start) {
        start = modelYear;
    }
    if (modelYear > end) {
        end = modelYear;
    }
    return _getYearCells(date, start, end);
};
var _getMonthCells = function (date) {
    var monthNames = moment.months();
    var cells = [];
    for (var i = 0; i <= 11; i++) {
        cells.push({
            text: monthNames[i],
            value: date.clone().set('month', i),
        });
    }
    return cells;
};
export var getMonthRows = function (date) {
    var cells = _getMonthCells(date);
    var itemOnRow = 3;
    return _.chunk(cells, itemOnRow);
};
