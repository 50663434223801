import { action, computed, isAction, makeObservable, observable, runInAction } from 'mobx'

import moment from 'moment-timezone'

import clientService from '@services/client'

import { pushOrUpdate } from '@helpers/other'
import { isRouteByArea } from '@helpers/router'

import { $appts, $user } from '@store'

import mainConfig from '@config/main'

let idCounter = 0

const getId = () => {
  idCounter--
  return idCounter
}

let stoppedChatIdTimeout

const excludedForUpdateApptByRoute = [
  'appointment_reminder',
  'appointment_unstarted',
  'appointment_unfinished',

  'agent_shift_reminder',

  'provider_shift_reminder',
  'provider_note_reminder',

  'client_complete_reminder',

  'chat_message_received',
]

const includedForUpdateApptActive = [
  'appointment_booked',
  'appointment_unbooked',
  'appointment_canceled',
  'appointment_finished',

  'payment_authorized',

  'agent_assigned',
  'agent_unassigned',

  'provider_assigned',
  'provider_unassigned',
]

const includedForUpdateApptActual = [
  'appointment_booked',
  'appointment_unbooked',
  'appointment_canceled',

  'appointment_started',
  'appointment_unstarted',
  'appointment_finished',
  'appointment_unfinished',
  'appointment_note_added',
  'appointment_canceled',
  'appointment_exception',

  'client_ready',
  'agent_ready',
  'provider_ready',

  'agent_assigned',
  'agent_unassigned',

  'provider_assigned',
  'provider_unassigned',

  'provider_note_reminder',

  'chat_message_received',
]

class NotificationsStore {
  constructor() {
    makeObservable(this, {
      items: observable,
      popups: observable,
      lastTime: observable,
      stoppedChatIds: observable,
      clear: action,
      allItems: computed,
      actualItems: computed,
      SET_STOPPED_CHAT: action,
      getHistory: action,
      getNew: action,
      addOrUpdate: action,
      view: action,
      addPopup: action,
      updatePopup: action,
      showPopup: action,
      hidePopup: action,
      removePopup: action,
    })
  }

  items = []
  popups = []
  lastTime = false
  stoppedChatIds = false

  clear() {
    this.items = []
    this.popups = []
    this.lastTime = false
    this.stoppedChatIds = false
  }

  // Computed
  get allItems() {
    let result = []
    let prevMessages = []

    _.forEach(this.items, (item) => {
      if (item.hideIfViewed && item.viewed) {
        return
      }

      // filter by practiceId
      const practiceId = item.event?.practiceId
      const currentPracticeId = $user.role.practiceId
      const isOtherPractice = practiceId && currentPracticeId && practiceId !== currentPracticeId

      if (isOtherPractice) {
        return
      }

      // Display only one chat message for the sender
      if (item.notification === 'chat_message_received') {
        const pattern = `${item.event.appointmentId}_${item.event.actorRole}`

        if (prevMessages.length && prevMessages.includes(pattern)) {
          return
        } else {
          prevMessages.push(pattern)
        }
      }

      result.push(item)
    })

    return result
  }

  get actualItems() {
    return this.allItems.filter((x) => x.isActual && !x.viewed)
  }

  // Mutations
  SET_STOPPED_CHAT(ids) {
    if (ids === false) {
      stoppedChatIdTimeout = setTimeout(() => {
        runInAction(() => {
          this.stoppedChatIds = ids
        })
      }, mainConfig.notifications.updateInterval)
    } else {
      clearTimeout(stoppedChatIdTimeout)
      this.stoppedChatIds = ids
    }
  }

  // Notices Actions
  async getHistory() {
    const now = moment.utc()
    const historyTime = moment.utc().subtract(mainConfig.notifications.historyPeriod, 'minutes')

    // check limit on the frequency of history requests
    const lastTime = this.lastTime ? this.lastTime : historyTime
    const diff = moment.duration(now.diff(lastTime)).asMilliseconds()

    if (diff < mainConfig.notifications.updateInterval) {
      return false
    }

    const items = await clientService.getPreparedNotifications({ lastTime: historyTime })

    runInAction(() => {
      this.items = items
      this.lastTime = moment().utc()
    })
  }

  async getNew() {
    if (!this.lastTime) {
      await this.getHistory()
      return false
    }

    const list = await clientService.getPreparedNotifications({ lastTime: this.lastTime })

    runInAction(() => {
      if (list.length) {
        // const canShow = $main.rightSidebar.active !== 'notifications'
        const canShow = true

        let hasPopup = false
        let idsNeedToView = []
        let updateApptByRoute = false
        let updateApptActive = false
        let updateApptActual = false

        this.lastTime = moment().utc()

        _.forEach(list.reverse(), (item) => {
          const noticeName = item.notification

          if (!item.viewed) {
            let needPopup = true

            // check chat messages
            if (this.itIsOpenChat(item)) {
              needPopup = false
              item.viewed = true
              idsNeedToView.push(item.id)
            }

            // show popup
            const practiceId = item.event?.practiceId
            const currentPracticeId = $user.role.practiceId
            const isOtherPractice =
              practiceId && currentPracticeId && practiceId !== currentPracticeId

            if (needPopup && (canShow || isOtherPractice)) {
              this.addPopup(item, true)
              hasPopup = true
            }

            // updating an open appointment
            if (!updateApptByRoute && !excludedForUpdateApptByRoute.includes(noticeName)) {
              const appointmentId = _.get(item, 'event.appointmentId')
              const isApptPage = isRouteByArea('appointmentPage', { id: appointmentId })

              if (isApptPage) {
                updateApptByRoute = appointmentId
              }
            }

            // updating active appointments
            if (!updateApptActive && includedForUpdateApptActive.includes(noticeName)) {
              updateApptActive = true
            }
            if (!updateApptActual && includedForUpdateApptActual.includes(noticeName)) {
              updateApptActual = true
            }
          }

          this.addOrUpdate(item)
        })

        if (hasPopup) {
          this.showPopup()
        }
        if (idsNeedToView.length) {
          this.view({ ids: idsNeedToView })
        }
        if (updateApptByRoute) {
          $appts.getOne({ id: updateApptByRoute })
        }
        if (updateApptActive) {
          $appts.getActive()
        }
        if (updateApptActual && _.isFunction($appts.getActual)) {
          $appts.getActual() // todo: move to extends
        }
      }
    })
  }

  addOrUpdate({ head, title, body, ...other }) {
    const newItem = {
      head: isAction(head) ? head : action(() => head),
      title: isAction(title) ? title : action(() => title),
      body: isAction(body) ? body : action(() => body),
      ...other,
    }

    this.items = pushOrUpdate(this.items, newItem, { pushTo: 'start', merge: true })
  }

  async view({ ids = [], chatIds = [] }) {
    chatIds.map((chatId) => {
      const found = _.find(this.items, (x) => chatId === _.get(x, 'event.chatMessageId'))

      if (found) {
        ids.push(found.id)
      }
    })

    ids = ids.filter((id) => {
      return _.find(this.items, (x) => x.id === id && !x.viewed)
    })

    const res = await clientService.viewNotifications({ ids })

    if (res && res.length) {
      runInAction(() => {
        _.forEach(res, (x) => this.addOrUpdate(x))
      })
    }
  }

  // Popup Notices Queue
  addPopup(
    { head, title, body, actions = [], id = getId(), onLeaved, ...other } = {},
    isNotification = false,
  ) {
    const newPopup = {
      show: false,
      isNotification,
      id: id,
      head: isAction(head) ? head : action(() => head),
      title: isAction(title) ? title : action(() => title),
      body: isAction(body) ? body : action(() => body),
      actions: actions,
      onLeaved: _.isFunction(onLeaved) ? action(() => onLeaved()) : onLeaved,
      ...other,
    }

    this.popups = pushOrUpdate(this.popups, newPopup, { pushTo: 'start' })

    if (!isNotification) {
      this.showPopup()
    }
  }

  // Popup Notices Show
  updatePopup(id, payload) {
    const index = _.findIndex(this.popups, (x) => x.id === id)

    if (index !== -1) {
      this.popups[index] = { ...this.popups[index], ...payload }
    }
  }

  showPopup() {
    if (!this.popups.length || _.some(this.popups, (x) => x.show)) {
      return false
    }

    let item = _.findLast(this.popups, (x) => !x.show)

    if (item.isNotification) {
      const notification = _.find(this.items, (x) => x.id === item.id)

      if (!notification || notification.viewed || this.itIsOpenChat(notification)) {
        this.removePopup(item.id)
        return false
      }
    }

    this.updatePopup(item.id, { show: true })
  }

  hidePopup(id) {
    this.updatePopup(id, { show: false })
  }

  removePopup(id) {
    if (this.popups.length) {
      this.popups = this.popups.filter((x) => x.id !== id)
      this.showPopup()
    }
  }

  itIsOpenChat(item) {
    if (item.notification === 'chat_message_received' && this.stoppedChatIds) {
      const ids = _.get(item, 'event.recipientIds', [])
      const result = _.intersection(ids, this.stoppedChatIds)
      if (!_.isEmpty(result)) {
        return true
      }
    }

    return false
  }

  hideAllPopups() {
    if (this.popups.length) {
      runInAction(() => {
        this.popups = this.popups.filter((x) => x.show)
        _.forEach(this.popups, (x) => this.hidePopup(x.id))
      })
    }
  }
}

const store = new NotificationsStore()
export default store
