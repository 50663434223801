var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { $main } from '@store';
import { ReactComponent as MoveLeftArrowIcon } from '@assets/images/ui/move-left-arrow.svg';
import styles from './Notes.module.scss';
var Notes = function (_a) {
    var className = _a.className;
    var t = useTranslation().t;
    var notesPayload = $main.rightSidebar.payload.notes;
    if (!notesPayload) {
        return null;
    }
    var handleClick = function () {
        $main.showRightSidebar('notes', notesPayload);
    };
    return (_jsxs("div", __assign({ onClick: handleClick, className: classNames(styles.container, className) }, { children: [_jsx(MoveLeftArrowIcon, {}), t('label.appointment'), "\u00A0", notesPayload.appointmentId] })));
};
export default observer(Notes);
