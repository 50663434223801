import React, { useEffect, useRef, useState } from 'react'

import { observer } from 'mobx-react'

import moment from 'moment-timezone'

import { useIntervalTimer } from '@library/CustomHooks'

import { formatDuration } from '@helpers/time'

const Duration = (props) => {
  const { from, to } = props

  const [duration, setDuration] = useState(calcDuration())
  const [spanWidth, setSpanWidth] = useState(0)

  const timerRef = useRef(null)

  const { start, stop } = useIntervalTimer({
    callback: () => setDuration(calcDuration()),
    duration: 1000,
    autoStart: false,
  })

  useEffect(() => {
    if (!spanWidth && timerRef.current) {
      const timerEl = timerRef.current
      const timerText = timerEl.innerText
      if (timerText?.length) {
        const sampleEl = timerEl.cloneNode(true)
        sampleEl.innerText = 4
        sampleEl.style.color = 'transparent'
        sampleEl.style.position = 'absolute'
        timerEl.appendChild(sampleEl)

        const targetWidth = sampleEl.getBoundingClientRect().width
        setSpanWidth(targetWidth.toFixed(1))
        sampleEl.remove()
      }
    }
  }, [timerRef])

  useEffect(() => {
    if (!to) {
      start()
    }

    return () => stop()
  }, [to, start, stop])

  function calcDuration() {
    const m1 = moment(from)
    const m2 = to ? moment(to) : moment.utc()
    return m2.diff(m1)
  }

  function renderDuration() {
    let format = 'h:mm:ss'

    if (duration < 60 * 60 * 1000) {
      format = 'mm:ss'
    }

    if (props.format) {
      format = props.format
    }

    return formatDuration(duration, format)
  }

  return (
    <div
      ref={timerRef}
      style={{
        position: 'relative',
        display: 'inline-block',
        textAlign: 'left',
      }}
      className="duration-wrapper"
    >
      {renderDuration()
        .split('')
        .map((x, i) => {
          return (
            <span
              key={`timer-item-${x}-${i}`}
              style={{
                display: 'inline-block',
                width: x === ':' ? 'initial' : `${spanWidth}px`,
                textAlign: 'center',
              }}
            >
              {x}
            </span>
          )
        })}
    </div>
  )
}

export default observer(Duration)
