import React, { useEffect, useRef, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { confirmAction } from '@library/ActionConfirmator'
import Modal from '@library/modal/Modal'
import { $configure } from '@library/plugins/configure/configure.store'
import ConfigureProgress from '@library/plugins/configure/library/ConfigureProgress'
import Button from '@library/ui/button/Button'

import { customExecute } from '@helpers/other'
import { T } from '@helpers/text'

import { customActions } from '@config/customActions'
import mainConfig from '@config/main'

const ConfigureCoeModal = ({ scrollRequired = false, _core }) => {
  const { t } = useTranslation()

  const [scrolled, setScrolled] = useState(false)
  const bodyRef = useRef(null)

  useEffect(() => {
    setTimeout(() => {
      handleScroll({ target: bodyRef.current })
    }, 0)
  }, [])

  function handleBack() {
    $configure.checkConfigure({ showPreview: true })
    _core.onHide()
  }

  function handleScroll({ target }) {
    if (Math.floor(target.scrollHeight - target.scrollTop) <= target.clientHeight + 10) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }

  function handleCancel() {
    confirmAction('customAlert', {
      data: {
        title: t('confirmator.logout.title'),
        message: t('confirmator.logout.message'),
      },
      callback: async () => {
        await customExecute(customActions.HANDLE_REJECT_PAPERWORK())
        _core.onHide()
      },
    })
  }

  async function handleSubmit(e) {
    e.preventDefault()

    if (!scrollRequired || scrolled) {
     let res = await $configure.setTosAccepted({ tosType: 'emergency_instructions' })

      if (res.isSuccess) {
        $configure.checkConfigure()
        _core.onHide()
      }
    }
  }

  return (
    <Modal centered scrollable size="xl" footerShadow closeOnOverlay={false}>
      <div className="modal-toolbar">
        <div className="modal-toolbar-btn modal-toolbar-btn--back" onClick={handleBack} />
        <div className="modal-toolbar-meta d-lg-none">
          {t('configure.meta_title', { n: $configure.currentStep })}
        </div>
        <div className="modal-toolbar-progress">
          <ConfigureProgress />
        </div>
      </div>
      <Modal.Header className="text-center">{t('configure_coe.title')}</Modal.Header>
      <Modal.Body ref={bodyRef} className="styled_scroll" onScroll={handleScroll}>
        <form id="configure_coe" onSubmit={handleSubmit}>
          <p>{t('configure_coe_text.p1')}</p>
          <p>
            - {t('configure_coe_text.l1')}
            <br />- {t('configure_coe_text.l2')}
            <br />- {t('configure_coe_text.l3')}
            <br />- {t('configure_coe_text.l4')}
            <br />
          </p>
          {mainConfig.custom.coeExtendedText && (
            <>
              <T tag="p">{t('configure_coe_text.p2')}</T>
              <T tag="p">{t('configure_coe_text.p3')}</T>
            </>
          )}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <div className="btn-group btn-group--full-mobile">
          <Button
            mode="primary"
            tag="button"
            buttonType="submit"
            form="configure_coe"
            disabled={scrollRequired && !scrolled}
            label={t('btn.agree')}
          />
          <Button
            mode="secondary"
            tag="button"
            buttonType="submit"
            action={handleCancel}
            label={t('btn.cancel')}
          />
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default observer(ConfigureCoeModal)
