import React, { useRef } from 'react'

import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import BubbleLoader from '@library/ui/bubbleLoader/BubbleLoader'

import { simpleTagConverter } from '@helpers/text'

import styles from './TypeAheadPopup.module.scss'

const TypeAheadPopup = ({
  searchString,
  items,
  isLoading,
  onSelect,
  simplifyGroups,
  useAsIs,
  fieldWidth,
}) => {
  const { t } = useTranslation()
  const ref = useRef(null)

  const RenderItems = ({ items }) => {
    return items.map((item, index) => {
      if (item.hasOwnProperty('items')) {
        return (
          <React.Fragment key={[item.id, item.text, index].join('_')}>
            {simplifyGroups && item.items.length === 1 ? (
              <RenderItems items={item.items} />
            ) : (
              <div className={styles.group}>
                <div className={styles.groupTitle}>
                  {item.highlight ? simpleTagConverter(item.highlight) : item.text}
                </div>
                <RenderItems items={item.items} />
              </div>
            )}
          </React.Fragment>
        )
      } else {
        return (
          <div
            key={[item.id, item.text, index].join('_')}
            className={styles.item}
            onClick={() => onSelect(item)}
          >
            <div className={styles.itemInner}>
              <span className={styles.itemInnerTitle}>
                {item.highlight ? simpleTagConverter(item.highlight) : item.text}
                {item.metaText && <span className={styles.itemInnerMetaText}>{item.metaText}</span>}
              </span>
              {item.rightText && (
                <span className={styles.itemInnerRightText}>{item.rightText}</span>
              )}
            </div>
            {item.subText && <span className={styles.itemSubText}>{item.subText}</span>}
          </div>
        )
      }
    })
  }

  const RenderLoader = () => (
    <div className={styles.loader}>
      <BubbleLoader mode="lg" innerClassNames={styles.bubbleLoader} />
    </div>
  )

  return (
    <div
      style={{
        width: `${fieldWidth}px` || '100%',
      }}
      className={classNames(styles.wrapper, isLoading && !_.isEmpty(items) && styles.isLoading)}
    >
      <div className={classNames(styles.scrollArea, 'styled_scroll')} ref={ref}>
        {isLoading === null ? (
          <RenderLoader />
        ) : _.isEmpty(items) && !isLoading ? (
          <>
            <div className={styles.error}>{t('errors.no_results')}</div>
            {useAsIs && (
              <div className={styles.useAsIs}>
                <div
                  className={classNames(styles.item)}
                  onClick={() => onSelect({ text: searchString.value })}
                >
                  {t('label.use_as_is:')} <span>{searchString.value}</span>
                </div>
              </div>
            )}
          </>
        ) : (
          <RenderItems items={items} />
        )}
      </div>
      {isLoading && <RenderLoader />}
    </div>
  )
}

export default TypeAheadPopup
