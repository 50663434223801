import { isMobile, isMobileOnly } from 'react-device-detect'

import _ from 'lodash'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'

import { toSnakeCase } from '@helpers/text'
import WebView from '@helpers/webView'

import mainConfig from '@config/main'

export function getUuid() {
  return uuidv4()
}

export function getRandomNumber(min, max) {
  return Math.ceil(Math.random() * (max - min) + min)
}

export function getWindowSizes(settings = {}) {
  const width = window.innerWidth
  const height = window.innerHeight

  const s = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    ...settings,
  }

  const h = {
    xs: 0,
    sm: 360,
    md: 700,
    lg: 800,
    xl: 1080,
    ...settings,
  }

  return {
    width,
    height,
    isXS: width >= s.xs && width < s.sm,
    isSM: width >= s.sm && width < s.md,
    isMD: width >= s.md && width < s.lg,
    isLG: width >= s.lg && width < s.xl,
    isXL: width >= s.xl,
    //
    isMobile: isMobile || width < s.lg,
    isMobileOnly: isMobileOnly || width < s.md,
    //
    minXS: width >= s.xs,
    minSM: width >= s.sm,
    minMD: width >= s.md,
    minLG: width >= s.lg,
    minXL: width >= s.xl,
    //
    maxXS: width < s.xs,
    maxSM: width < s.sm,
    maxMD: width < s.md,
    maxLG: width < s.lg,
    maxXL: width < s.xl,

    //
    hMinXS: height >= h.xs,
    hMinSM: height >= h.sm,
    hMinMD: height >= h.md,
    hMinLG: height >= h.lg,
    hMinXL: height >= h.xl,
    //
    hMaxXS: height < h.xs,
    hMaxSM: height < h.sm,
    hMaxMD: height < h.md,
    hMaxLG: height < h.lg,
    hMaxXL: height < h.xl,
  }
}

function getStorageInstance(storage) {
  const instance = {
    _parsePath(pp, options = {}) {
      let { withAppType, withUserId } = options

      if (_.isObject(pp) && pp.hasOwnProperty('name')) {
        return pp
      }

      let [name, ...path] = _.toPath(pp) || []

      if (withUserId || (name === 'userSettings' && _.isUndefined(withUserId))) {
        const userId = this.get('loggedInfo.userId')

        if (userId) {
          name = [name, userId].join('_')
        } else {
          return { name: -1, path }
        }
      }

      if (withAppType || (name === 'loggedInfo' && _.isUndefined(withAppType))) {
        name = [name, mainConfig.appType].join('_')
      }

      return { name, path }
    },
    get(pp, defaultValue, options = {}) {
      const { name, path } = this._parsePath(pp, options)
      let result = storage.getItem(name)

      if (typeof result === 'undefined' || result === 'undefined') {
        return defaultValue
      }

      result = isJson(result) ? JSON.parse(result) : result

      if (path.length > 0) {
        return _.get(result, path, defaultValue)
      }

      return result || defaultValue
    },
    // lifeTime: amount in seconds
    set(pp, payload, options = { lifeTime: null, lifeTimePath: null }) {
      const { name, path } = this._parsePath(pp, options)
      let result

      if (name === -1) {
        return false
      }

      if (path.length > 0) {
        result = this.get(name, {}, options)
        _.set(result, path, payload)
      } else {
        result = payload
      }

      if (options.lifeTime) {
        let expName = name
        let expPath = path

        if (options.lifeTimePath) {
          const expParsed = this._parsePath(options.lifeTimePath, options)
          expName = expParsed.name
          expPath = expParsed.path
        }

        const expData = {
          path: [expName, ...expPath].filter((x) => x).join('.'),
          exp: moment().add(options.lifeTime, 'seconds'),
        }

        const existingTempData = this.get('temp', [])
        let result = _.filter(existingTempData, (x) => x.path !== expData.path)
        result.push(expData)
        storage.setItem('temp', JSON.stringify(result))
      }

      storage.setItem(name, JSON.stringify(result))
    },
    remove(pp, options = {}) {
      const { name, path } = this._parsePath(pp, options)
      let result

      if (name === -1) {
        return false
      }

      if (path.length > 0) {
        result = this.get(name, {}, options)
        _.unset(result, path)
        storage.setItem(name, JSON.stringify(result))
      } else {
        storage.removeItem(name)
      }
    },
    clearTemp() {
      let result = []
      const existingTempData = this.get('temp', [])
      _.forEach(existingTempData, (x) => {
        if (moment().isBefore(x.exp) && this.get(x.path)) {
          result.push(x)
        } else {
          this.remove(x.path)
        }
      })
      storage.setItem('temp', JSON.stringify(result))
    },
  }

  instance.clearTemp()

  return instance
}

export const localStore = getStorageInstance(localStorage)
export const sessionStore = getStorageInstance(sessionStorage)

export function isAbsoluteEmpty(value) {
  if (_.isArray(value) || _.isObject(value)) {
    return _.isEmpty(value)
  }

  return (value !== 0 && !value) || String(value).trim() === ''
}

export function detectMouseWheelDirection(e) {
  let delta = null
  let direction = false

  if (e.wheelDelta) {
    // will work in most cases
    delta = e.wheelDelta / 60
  } else if (e.detail) {
    // fallback for Firefox
    delta = -e.detail / 2
  }

  if (delta !== null) {
    direction = delta > 0 ? 'up' : 'down'
  }

  return direction
}

export function pushOrUpdate(
  arr = [],
  value,
  { byKey = 'id', pushTo = 'end', merge = false } = {},
) {
  const index = _.findIndex(arr, (x) => x[byKey] === value[byKey])

  if (index === -1) {
    if (pushTo === 'start') {
      arr = [value, ...arr]
    } else {
      arr = [...arr, value]
    }
  } else {
    let tmp = _.cloneDeep(arr)

    if (merge) {
      tmp[index] = _.merge(tmp[index], value)
    } else {
      tmp[index] = value
    }

    arr = tmp
  }

  return arr
}

export function isJson(str) {
  try {
    JSON.parse(str)
    return true
  } catch (e) {
    return false
  }
}

export function convertKeysToCamel(data) {
  const processVal = (val) =>
    typeof val !== 'object' || val === null
      ? val
      : Array.isArray(val)
      ? val.map(processVal)
      : renameKeys(val)
  const renameKeys = (obj) =>
    _.fromPairs(
      _.entries(obj).map(([key, val]) => [
        key.replace(/_(.)/g, (g) => g[1].toUpperCase()),
        processVal(val),
      ]),
    )

  return _.isArray(data) ? processVal(data) : renameKeys(data)
}

export function convertKeysToSnake(data) {
  function processVal(val) {
    if (typeof val !== 'object' || val === null) {
      return val
    } else {
      if (Array.isArray(val)) {
        return val.map(processVal)
      } else {
        return renameKeys(val)
      }
    }
  }

  function renameKeys(obj) {
    return _.fromPairs(
      _.entries(obj).map(([key, val]) => {
        let tmp = key.split('|')
        let resultKey = tmp[0]

        if (tmp[1] === 'strict') {
        } else {
          resultKey = toSnakeCase(key).toLowerCase()
        }

        return [resultKey, processVal(val)]
      }),
    )
  }

  return _.isArray(data) ? processVal(data) : renameKeys(data)
}

export function convertArrayValuesToSnake(value) {
  if (_.isArray(value)) {
    value = _.map(value, (x) => convertArrayValuesToSnake(x))
  } else if (_.isObjectLike(value)) {
    value = _.mapValues(value, (x) => convertArrayValuesToSnake(x))
  } else {
    value = toSnakeCase(value).toLowerCase()
  }

  return value
}

export function splitAtIndex(value, index) {
  return [value.slice(0, index), value.slice(index + 1)]
}

// pathWithConditions = [
//   'modifierExtension',
//   [{
//     field: 'url',
//     eq: 'https://fhir.ziphy.com/1.0/StructureDefinition/observation-travelLocation',
//   }],
//   'valueString',
// ]

// pathWithConditions = [
//   'modifierExtension',
//    {
//      and: [
//       {
//          field: 'url',
//          eq: 'https://fhir.ziphy.com/1.0/StructureDefinition/observation-travelLocation',
//        },
//       {
//          field: 'some',
//          eq: 'thing',
//        },
//      ]
//    }
//   'valueString',
// ]
export const getValueByPathWithConditions = (data, pathWithConditions, multi = false) => {
  let target = data

  let path = [...pathWithConditions]
  while (path.length) {
    if (_.isEmpty(target)) {
      break
    }

    const currentPathEl = path[0]
    path.shift()
    if (typeof currentPathEl === 'string') {
      // if path = 'string' just get data by path

      if (_.isArray(target)) {
        let newTarget = []
        _.forEach(target, (item) => {
          const el = _.get(item, currentPathEl)
          if (_.isArray(el)) {
            newTarget = _.concat(newTarget, el)
          } else {
            newTarget.push(el)
          }
        })

        target = newTarget
      } else {
        target = _.get(target, currentPathEl)
      }
    } else {
      // if path = condition object extract data by condition
      let newTarget = []
      _.every(target, (item) => {
        if (!_.isEmpty(findByCondition(item, [currentPathEl]))) {
          if (_.isArray(item)) {
            _.concat(newTarget, item)
          } else {
            newTarget.push(item)
          }

          if (!multi) {
            return false // stop iterating on first match
          }
        }

        return true
      })

      target = newTarget
    }
  }

  return _.isArray(target) ? target : [target]
}

// condition: {
//   and: [{ field: 'resource', eq: 'Observation' }],
//   or: [
//     { field: 'subtype', eq: ['medical_history'] },
//     { field: 'subtype', eq: ['medical_history', 'lab'] },
//   ],
// },
// condition: [
//   { field: 'resource', eq: 'Observation' },
//   { field: 'subtype', eq: ['medical_history', 'lab'] },
// ],

export function findByCondition(
  data,
  conditions = [],
  { byKey = 'condition', findFirst = false } = {},
) {
  function _iterateAnd(items, obj) {
    let isDone = true

    if (!items) {
      return false
    }

    _.forEach(items, (item) => {
      isDone = _matchConditions(item, obj)
      if (!isDone) return false
    })

    return isDone
  }

  function _iterateOr(items, obj) {
    let isDone = false

    if (_.isArray(items) && _.isArray(items[0])) {
      _.forEach(items, (item) => {
        isDone = _iterateAnd(item, obj)
        if (isDone) return false
      })
    } else {
      _.forEach(items, (item) => {
        isDone = _matchConditions(item, obj)
        if (isDone) return false
      })
    }

    return isDone
  }

  function _matchConditions(item, obj) {
    let isDone = true

    const { field, ...match } = item
    const value = _.get(obj, field)

    _.forEach(match, (needle, type) => {
      isDone = _matchValue(type, needle, value)

      if (!isDone) {
        return false
      }
    })

    return isDone
  }

  function _matchValue(type, needle, value) {
    let tmp

    if (_.isArray(needle)) {
      needle = _.sortBy(needle)
    }
    if (_.isArray(value)) {
      value = _.sortBy(value)
    }

    switch (_.toLower(type)) {
      case 'eq':
        return _.isEqual(value, needle)
      case 'ne':
        return !_.isEqual(value, needle)
      case 'lt':
        return value < needle
      case 'lte':
        return value <= needle
      case 'gt':
        return value > needle
      case 'gte':
        return value >= needle
      case 'in':
        tmp = _.isArray(needle) ? needle : [needle]
        return tmp.every((x) => _.includes(value, x))
      case 'nin':
        tmp = _.isArray(needle) ? needle : [needle]
        return tmp.every((x) => !_.includes(value, x))
      case 'sin': // some in
        tmp = _.isArray(needle) ? needle : [needle]
        return tmp.some((x) => _.includes(value, x))
      case 'snin': // some nin
        tmp = _.isArray(needle) ? needle : [needle]
        return tmp.some((x) => !_.includes(value, x))
      case 'exists':
        tmp = typeof value === 'undefined' || value === ''
        return tmp !== needle
    }
    return false
  }

  let result = []

  _.forEach(conditions, (obj) => {
    const condition = obj[byKey] || obj
    let andFound
    let orFound

    if (condition?.and || _.isArray(condition)) {
      andFound = _iterateAnd(condition.and || condition, data)
    } else {
      andFound = true
    }

    if (condition?.or) {
      orFound = _iterateOr(condition.or, data)
    } else {
      orFound = true
    }

    if (andFound && orFound) {
      result.push(obj)

      if (findFirst) {
        return false
      }
    }
  })

  return findFirst ? _.first(result) : result
}

export function getMode(mode, styles) {
  let result = []
  const array = _.isArray(mode) ? mode : [mode]

  _.forEach(array, (x) => {
    result.push(styles[x])
  })
  return result
}

export async function customExecute({ wvAction, onClick }) {
  if (WebView.isWebView && _.isFunction(wvAction)) {
    return await wvAction()
  }

  if (onClick) {
    return await onClick()
  }
}
