var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import InputSearch from '@library/form/fields/InputSearch';
import AddPatientModal from '@library/patients/AddPatient.modal';
import PatientsListItem from '@library/patients/patientsList/PatientsListItem';
import CustomLink from '@library/ui/customLink/CustomLink';
import { $modal } from '@store';
import { customActions } from '@config/customActions';
import mainConfig from '@config/main';
import styles from './PatientsList.module.scss';
var PatientsList = function (_a) {
    var props = __rest(_a, []);
    var t = useTranslation().t;
    var items = props.items, to = props.to, onClick = props.onClick, _b = props.hoverText, hoverText = _b === void 0 ? t('btn.select') : _b, _c = props.addBtnText, addBtnText = _c === void 0 ? t('btn.add_patient') : _c, addOnDone = props.addOnDone, withSearch = props.withSearch, addOnTop = props.addOnTop;
    var _d = useState(''), searchString = _d[0], setSearchString = _d[1];
    var preparedList = useMemo(function () {
        var result = items;
        if (_.trim(searchString)) {
            var needle_1 = _.trim(searchString).toLowerCase().split(' ');
            result = result.filter(function (x) {
                var full = [x.firstName, x.lastName].join(' ').toLowerCase();
                return _.every(needle_1, function (n) { return full.includes(n); });
            });
        }
        return _.sortBy(result, function (x) {
            return [x.firstName, x.lastName].join(' ').toLowerCase();
        });
    }, [items, searchString]);
    function handleOnTo(item) {
        if (_.isFunction(to)) {
            return to(item);
        }
    }
    function handleOnClick(item) {
        if (_.isFunction(onClick)) {
            onClick(item);
        }
    }
    function handleAddPatient() {
        var params = {};
        if (_.isFunction(addOnDone)) {
            params.onDone = addOnDone;
        }
        $modal.add(AddPatientModal, params);
    }
    return (_jsxs(_Fragment, { children: [(withSearch || items.length > mainConfig.searchIfMore) && (_jsx(InputSearch, { className: "mb-20", field: { value: searchString, onChange: setSearchString }, placeholder: t('select_patient.ph.search') })), _jsxs("div", __assign({ className: styles.patientsList }, { children: [customActions.HANDLE_ADD_PATIENT && addOnTop && (_jsxs(CustomLink, __assign({ className: classNames(styles.patientsListBtnNew, styles.patientsListBtnNewTop), autoTarget: customActions.HANDLE_ADD_PATIENT({ callback: handleAddPatient }) }, { children: [_jsx("div", { className: styles.patientsListBtnNewIcon }), _jsx("div", __assign({ className: styles.patientsListBtnNewText }, { children: addBtnText }))] }))), preparedList.map(function (item) { return (_jsx(CustomLink, __assign({ autoTarget: handleOnTo(item), className: styles.patientsListItem, onClick: function () { return handleOnClick(item); } }, { children: _jsx(PatientsListItem, { item: item, hoverText: hoverText, innerClassNames: styles.select }) }), item.id)); }), customActions.HANDLE_ADD_PATIENT && !addOnTop && (_jsxs(CustomLink, __assign({ className: styles.patientsListBtnNew, autoTarget: customActions.HANDLE_ADD_PATIENT({ callback: handleAddPatient }) }, { children: [_jsx("div", { className: styles.patientsListBtnNewIcon }), _jsx("div", __assign({ className: styles.patientsListBtnNewText }, { children: addBtnText }))] })))] }))] }));
};
export default observer(PatientsList);
