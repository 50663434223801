import React, { useEffect, useRef, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { confirmAction } from '@library/ActionConfirmator'
import Modal from '@library/modal/Modal'
import { $configure } from '@library/plugins/configure/configure.store'
import ConfigureProgress from '@library/plugins/configure/library/ConfigureProgress'
import { TOS_GENERAL, TOS_PROVIDER } from '@library/plugins/termsOfService/Tos.config'
import { useLoadJson } from '@library/plugins/termsOfService/Tos.hooks'
import { TosHeader } from '@library/plugins/termsOfService/TosHeader'
import { TosText } from '@library/plugins/termsOfService/TosText'
import Button from '@library/ui/button/Button'

import { customExecute } from '@helpers/other'

import { $alerts, $loader } from '@store'

import { customActions } from '@config/customActions'

const ConfigureTosModal = ({ type = TOS_GENERAL, _core }) => {
  const { t } = useTranslation()

  const [scrolled, setScrolled] = useState(false)
  const bodyRef = useRef(null)

  const { json, isLoading, extractRef } = useLoadJson({ type })

  useEffect(() => {
    if (isLoading) return
    setTimeout(() => {
      handleScroll({ target: bodyRef.current })
    }, 0)
  }, [isLoading])

  function handleBack() {
    $configure.checkConfigure({ showPreview: true })
    _core.onHide()
  }

  function handleScroll({ target }) {
    if (Math.floor(target.scrollHeight - target.scrollTop) <= target.clientHeight + 10) {
      setScrolled(true)
    }
  }

  function handleReject() {
    confirmAction('customAlert', {
      data: {
        title: t('confirmator.tos_reject.title'),
        message: t('confirmator.tos_reject.message'),
        done: t('btn.reject_tos'),
        cancel: t('btn.view_tos'),
      },
      callback: async () => {
        await customExecute(customActions.HANDLE_REJECT_PAPERWORK())
        _core.onHide()
      },
    })
  }

  const handleSubmit = $loader.registerHandler('confirm-tos-submit', async (e) => {
    e.preventDefault()

    if (scrolled) {
      let res

      _core.onBlock()

      if (type === TOS_PROVIDER) {
        res = await $configure.setTosAccepted({ tosType: 'provider' })
      } else {
        res = await $configure.setTosAccepted({ tosType: 'client' })
        res = await $configure.setTosAccepted({ tosType: 'client_pp' })
      }

      _core.onUnblock()

      if (res.isSuccess) {
        $configure.checkConfigure()

        _core.onHide()
      }
    } else {
      $alerts.add(t('configure_tos.not_read'))
    }
  })

  return (
    <Modal centered scrollable size="xl" footerShadow closeOnOverlay={false}>
      <div className="modal-toolbar">
        <div className="modal-toolbar-btn modal-toolbar-btn--back" onClick={handleBack} />
        <div className="modal-toolbar-meta d-lg-none">
          {t('configure.meta_title', { n: $configure.currentStep })}
        </div>
        <div className="modal-toolbar-progress">
          <ConfigureProgress />
        </div>
      </div>
      <Modal.Header className="text-center">
        <TosHeader type={type} />
      </Modal.Header>
      <Modal.Body
        ref={bodyRef}
        isLoading={isLoading}
        className="styled_scroll"
        onScroll={handleScroll}
      >
        <form id="configure_tos" onSubmit={handleSubmit}>
          <TosText type={type} json={json} extractRef={extractRef} onlyScroll={true} />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <div className="btn-group btn-group--full-mobile">
          <Button
            tag="button"
            mode="primary"
            form="configure_tos"
            isLoading={$loader.isRunHandler('confirm-tos-submit')}
          >
            {t('btn.accept')}
          </Button>
          <Button tag="button" mode="secondary" action={handleReject}>
            {t('btn.reject')}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

ConfigureTosModal.displayName = 'ConfigureTos'

export default observer(ConfigureTosModal)
