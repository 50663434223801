import React, { useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import InsuranceSelector from '@booking/library/insuranceSelector/InsuranceSelector'
import VoucherSelector from '@booking/library/voucherSelector/VoucherSelector'

import Modal from '@library/modal/Modal'
import RadioTabs from '@library/radioTabs/RadioTabs'
import Button from '@library/ui/button/Button'

import { $booking } from '@store'

import helpers from './InsuranceVoucherSelect.modal.helpers'
import styles from './InsuranceVoucherSelect.modal.module.scss'

const INSURANCE = {
  id: 'insurance',
  text: 'label.insurance',
}
const VOUCHER = {
  id: 'voucher',
  text: 'label.voucher',
}
const TABS = [INSURANCE, VOUCHER]

const InsuranceVoucherSelectModal = ({ _core, patient }) => {
  const { t } = useTranslation()
  const [tab, setTab] = useState(INSURANCE.id)
  const [voucher, setVoucher] = useState(null)
  const [insurance, setInsurance] = useState(
    helpers.hasInsurance(patient) ? helpers.pickInsuranceFields(patient) : null,
  )

  const handleSubmit = () => {
    if (tab === INSURANCE.id) {
      const values = insurance === null ? helpers.getEmptyInsuranceFields() : insurance
      $booking.$patients.updateSelected(patient.id, values, true)
    } else if (tab === VOUCHER.id) {
      $booking.$patients.updateSelected(patient.id, { voucher: voucher })
    }
    _core.onHide()
  }

  return (
    <Modal centered _core={_core}>
      <div className="modal-toolbar">
        <div className="modal-toolbar-btn modal-toolbar-btn--close" onClick={_core.onHide} />
      </div>
      <Modal.Header>{t('book_details.label.add_insurance_or_voucher')}</Modal.Header>
      <Modal.Body>
        <RadioTabs
          value={tab}
          setValue={setTab}
          items={TABS.map((item) => ({ ...item, text: t(item.text) }))}
        />
        <div className={styles.body}>
          {tab === INSURANCE.id ? (
            <InsuranceSelector insurance={insurance} onChangeInsurance={setInsurance} />
          ) : (
            <VoucherSelector
              selected={voucher}
              onChangeSelected={setVoucher}
              availablePracticeIds={$booking.availablePracticeIds}
              practiceServiceId={$booking.practiceService.id}
            />
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          mode="primary"
          tag="button"
          buttonType="button"
          className={styles.submitButton}
          disabled={tab === INSURANCE.id ? false : _.isEmpty(voucher)}
          label={t('btn.submit')}
          action={handleSubmit}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default observer(InsuranceVoucherSelectModal)
