import { useEffect } from 'react'

import { isJson } from '@helpers/other'
import { isUrl } from '@helpers/url'

import mainConfig from '@config/main'

const isTest = false

class _WebView {
  isWebView =
    (!!window.hasOwnProperty('ReactNativeWebView') && mainConfig.custom.webViewEnabled) || isTest

  prefix = mainConfig.custom.webViewPostMessagePrefix || mainConfig.appBrand

  postMessage = (source, payload = {}) => {
    if (!this.isWebView) return
    window.ReactNativeWebView?.postMessage(JSON.stringify({ source, payload }))
  }

  goTo = (event, screen = '', params = {}) => {
    if (!this.isWebView) return

    if (_.isObject(event) && _.isFunction(event.preventDefault)) {
      event.preventDefault()
    }

    this.postMessage(this.prefix + 'GoTo', { screen, params })
  }

  close = (event) => {
    if (!this.isWebView) return

    if (_.isObject(event) && _.isFunction(event.preventDefault)) {
      event.preventDefault()
    }

    this.postMessage(this.prefix + 'Close')
  }

  anchorClickHandler = (event) => {
    if (!this.isWebView) return

    const anchor = event.target.closest('a')

    if (anchor !== null) {
      const href = anchor.getAttribute('href')
      const target = anchor.getAttribute('target')

      if (isUrl(href) || _.includes(href, 'mailto:') || _.includes(href, 'tel:')) {
        event.preventDefault()
        this.postMessage(this.prefix + 'AnchorClick', { href, target })
      }
    }
  }

  log = (payload) => {
    if (!this.isWebView) return
    this.postMessage(this.prefix + 'ConsoleLog', payload)
  }
}

const WebView = new _WebView()

export default WebView

export const useWebViewAnchorClickHandler = (deps = []) => {
  useEffect(() => {
    if (WebView.isWebView) {
      document.addEventListener('click', WebView.anchorClickHandler)
    }
    return () => {
      if (WebView.isWebView) {
        document.removeEventListener('click', WebView.anchorClickHandler)
      }
    }
  }, deps)
}

export const useWebViewListenPostMessage = (source, callback, deps = []) => {
  useEffect(() => {
    function handle(event) {
      if (event.origin !== window.location.origin) {
        return
      }

      const data = isJson(event.data) ? JSON.parse(event.data) : event.data

      if (data?.source !== source) {
        return
      }

      callback({ payload: data?.payload || {} })
    }

    window.addEventListener('message', handle)
    return () => window.removeEventListener('message', handle)
  }, [...deps]) // eslint-disable-line react-hooks/exhaustive-deps
}
