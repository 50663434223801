import React, { useMemo } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import i18n from 'i18next'

import { useLoader } from '@library/CustomHooks'
import MediaPreview from '@library/media/MediaPreview'
import Modal from '@library/modal/Modal'
import DtlGroup from '@library/ui/dtl/DtlGroup'
import DtlItem from '@library/ui/dtl/DtlItem'

import { formatDate } from '@helpers/time'

import { openEventModal } from '@ps/helpers/events'
import { resourceExtractor } from '@ps/helpers/resourceExtractor'
import { useGetMediaItems } from '@ps/library/CustomHooks'
import RoleName from '@ps/library/RoleName'

import { $psHistory, $psResources } from '@store'

import styles from './BaseDocuments.module.scss'

const BaseDocumentsModal = ({ _core, baseItem, items }) => {
  const { t } = useTranslation()

  const baseEvent = baseItem.event

  const apptEvent = useMemo(() => {
    return $psHistory.getEventById(baseEvent.appointmentId, 'appointment_main')
  }, [$psHistory.events, baseEvent]) // eslint-disable-line react-hooks/exhaustive-deps

  const isLoading = useLoader(async () => {
    const events = [..._.map(items, (x) => x.event), apptEvent]
    return await $psResources.getCompleteResources({ items: events })
  })

  const baseExtractor = useMemo(() => {
    return resourceExtractor(baseItem.event, baseItem.resource, [
      'text',
      'type',
      'valuesAsString',
      'operator',
    ])
  }, [isLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  const baseLabel = () => {
    let label = ''

    if (_.isString(baseExtractor.type)) {
      label = baseExtractor.type
    } else if (i18n.exists(baseEvent.label)) {
      label = t(baseEvent.label)
    }

    if (items.length > 1) {
      return label
    }

    let desc = baseExtractor.text

    if (!desc && _.snakeCase(baseExtractor.type) !== _.snakeCase(baseExtractor.valuesAsString)) {
      desc = baseExtractor.valuesAsString
    }

    return [label, desc].filter((x) => x).join(': ')
  }

  const relatedAppt = useMemo(() => {
    const resource = $psResources.getById(apptEvent.id)

    const extractor = resourceExtractor(apptEvent, resource, ['text'])
    const { text } = extractor

    return { event: apptEvent, resource, text }
  }, [isLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  const mediaItems = useGetMediaItems({ isLoading, items })

  return (
    <Modal centered scrollable size="xl" className={styles.modal}>
      <div className="modal-toolbar">
        <div className="modal-toolbar-btn modal-toolbar-btn--close" onClick={_core.onHide} />
      </div>

      <Modal.Header>
        <div className="modal-header-title title-md text-first-letter-uppercase">{baseLabel()}</div>
      </Modal.Header>

      <Modal.Body isLoading={isLoading} className="styled_scroll">
        <DtlGroup className="row pb-30 row--mb-20">
          <DtlItem className="col-12 col-lg-4" label={t('ps.label.upload_date')}>
            {formatDate(baseEvent.effectiveDate)}
          </DtlItem>

          {/*<DtlItem className="col-12 col-lg-4" label={t('ps.label.upload_by')}>*/}
          {/*  {}*/}
          {/*</DtlItem>*/}

          {baseExtractor.operator && (
            <DtlItem className="col-12 col-lg-4" label={t('ps.label.operator')}>
              {baseExtractor.operator.value.fullName}{' '}
              <RoleName
                role={baseExtractor.operator.role}
                resource={baseExtractor.operator.value}
              />
            </DtlItem>
          )}

          {relatedAppt && relatedAppt.text && (
            <DtlItem className="col-12 col-lg-4" label={t('ps.label.appointment')}>
              {
                <span
                  className="a"
                  onClick={() => openEventModal([_.pick(relatedAppt, ['event', 'resource'])])}
                >
                  {relatedAppt.text}
                </span>
              }
            </DtlItem>
          )}
        </DtlGroup>

        <div className="title-sm">
          {t(baseEvent.extractor === 'document' ? 'ps.label.files' : 'ps.label.media')}
        </div>
        <MediaPreview items={mediaItems} />
      </Modal.Body>
    </Modal>
  )
}

export default observer(BaseDocumentsModal)
