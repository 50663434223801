import moment from 'moment/moment';
var TimeSlotPickerHelpers;
(function (TimeSlotPickerHelpers) {
    TimeSlotPickerHelpers.getParsedDate = function (value, timezone) {
        if (timezone) {
            return moment(value).tz(timezone);
        }
        else {
            return moment(value);
        }
    };
    TimeSlotPickerHelpers.getDaysBoard = function (_a) {
        var dates = _a.dates, visibleDaysCount = _a.visibleDaysCount, todayLabel = _a.todayLabel, timezone = _a.timezone, _b = _a.isMobile, isMobile = _b === void 0 ? false : _b;
        var today = moment.tz(timezone);
        var slicedDates = isMobile ? dates : dates.slice(0, visibleDaysCount);
        var prevMonth = null;
        var result = slicedDates.map(function (item) {
            var date = TimeSlotPickerHelpers.getParsedDate(item.key, timezone);
            var month = date.format('MMM');
            var properties = {
                isLocked: !item.value,
                isToday: today.isSame(date, 'day'),
                day: date.format('DD'),
                weekDay: date.format('ddd'),
                value: date,
                valueString: item.key,
                notice: today.isSame(date, 'day') ? todayLabel : undefined,
            };
            if (!prevMonth) {
                prevMonth = month;
            }
            else if (prevMonth !== month) {
                properties.notice = month;
                prevMonth = month;
            }
            return properties;
        });
        return result;
    };
})(TimeSlotPickerHelpers || (TimeSlotPickerHelpers = {}));
export default TimeSlotPickerHelpers;
