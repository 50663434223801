import React from 'react'

const Appointments = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path
        d="M11.856 3.857c.553 0 1.001.448 1.001 1.009v1.562h10.286V4.866c0-.557.448-1.009 1.001-1.009h.569c.553 0 1.001.448 1.001 1.009v1.563a5.143 5.143 0 0 1 5.143 5.142v14.143a5.143 5.143 0 0 1-5.143 5.143H10.286a5.143 5.143 0 0 1-5.143-5.143V11.571a5.143 5.143 0 0 1 5.143-5.142V4.866c0-.557.448-1.009 1.001-1.009h.569zM28.285 18H7.714v7.715a2.571 2.571 0 0 0 2.572 2.572h15.428a2.571 2.571 0 0 0 2.572-2.572L28.285 18zM25.715 9v1.563c0 .557-.449 1.008-1.002 1.008h-.569a1.003 1.003 0 0 1-1.001-1.008V8.999H12.857v1.564c0 .557-.448 1.008-1.001 1.008h-.569a1.003 1.003 0 0 1-1.001-1.008V9a2.571 2.571 0 0 0-2.572 2.571v3.857h20.57l.002-3.857A2.571 2.571 0 0 0 25.714 9z"
        fill="#FFF"
      />
    </g>
  </svg>
)

export default Appointments
