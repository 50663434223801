import React from 'react'

import { observer } from 'mobx-react'

import withBookingDataInit from '@booking/library/withBookingDataInit/withBookingDataInit'
import SelectAddress from '@booking/pages/bookingHome/selectAddress/SelectAddress'
import SelectPractice from '@booking/pages/bookingHome/selectPractice/SelectPractice'
import SelectService from '@booking/pages/bookingHome/selectService/SelectService'

const BookingHome = () => {
  return (
    <>
      <SelectAddress />
      <SelectPractice />
      <SelectService />
    </>
  )
}
export default withBookingDataInit(observer(BookingHome))
