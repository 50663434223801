var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState, } from 'react';
import classNames from 'classnames';
import { getUuid, isAbsoluteEmpty } from '@helpers/other';
var initField = {
    value: '',
};
var InputSearch = forwardRef(function (_a, ref) {
    var className = _a.className, innerClassNames = _a.innerClassNames, inputClass = _a.inputClass, _b = _a.clear, clear = _b === void 0 ? true : _b, _c = _a.label, label = _c === void 0 ? '' : _c, _d = _a.help, help = _d === void 0 ? '' : _d, _e = _a.mode, mode = _e === void 0 ? '' : _e, 
    //
    _f = _a.field, 
    //
    field = _f === void 0 ? initField : _f, onClear = _a.onClear, _id = _a.id, 
    //
    children = _a.children, 
    //
    props = __rest(_a, ["className", "innerClassNames", "inputClass", "clear", "label", "help", "mode", "field", "onClear", "id", "children"]);
    var inputRef = useRef(null);
    var _g = useState(field.value), innerValue = _g[0], setInnerValue = _g[1];
    var id = useMemo(function () { return (_id ? _id : getUuid()); }, [_id]);
    var isEmpty = useMemo(function () {
        if (field && field.hasOwnProperty('value')) {
            return isAbsoluteEmpty(field.value);
        }
        return isAbsoluteEmpty(field.value);
    }, [field, field.value]); //eslint-disable-line react-hooks/exhaustive-deps
    useEffect(function () {
        if (field.value !== innerValue) {
            setInnerValue(field.value);
        }
    }, [field.value]); //eslint-disable-line react-hooks/exhaustive-deps
    function handleOnChange(event) {
        var value = _.get(event, 'target.value', event);
        setInnerValue(value);
        if (field && _.isFunction(field.onChange)) {
            field.onChange(value);
        }
    }
    function handleClear(e) {
        if (e && _.isFunction(e.stopPropagation)) {
            e.stopPropagation();
        }
        var result = '';
        if (field && _.isString(field.value)) {
            result = '';
        }
        else if (field && _.isArray(field.value)) {
            result = [];
        }
        else if (field && _.isObject(field.value)) {
            result = {};
        }
        handleOnChange(result);
        if (_.isFunction(onClear)) {
            onClear();
        }
    }
    function getMode(mode) {
        var array = _.isArray(mode) ? mode : [mode];
        return array.map(function (x) { return 'search--' + x; }).join(' ');
    }
    // Search methods
    useImperativeHandle(ref, function () { return ({
        focus: function () {
            inputRef.current.focus();
        },
        clear: function () {
            handleClear();
        },
    }); });
    return (_jsxs("div", __assign({ className: classNames('search', className, mode && getMode(mode), !isEmpty && 'search--active', field && field.isInvalid && 'has-error') }, { children: [label && (_jsx("label", __assign({ className: "search-label", htmlFor: id }, { children: label }))), _jsxs("div", __assign({ className: classNames(innerClassNames, 'search-item') }, { children: [_jsx("input", __assign({ ref: inputRef, type: "text", id: id, className: classNames('search-field', inputClass), value: innerValue, onChange: handleOnChange }, props)), clear && !isEmpty && _jsx("div", { className: "search-clear", onClick: handleClear })] })), help && _jsx("div", __assign({ className: "search-help" }, { children: help })), !_.isEmpty(children) && children] })));
});
export default InputSearch;
