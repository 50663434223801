import React, { useRef, useState } from 'react'

import classNames from 'classnames'

import BaseImage from '@library/media/mediaTypes/base/BaseImage'
import ProgressRing from '@library/ui/progressRing/ProgressRing'

import styles from './Types.module.scss'

const Image = ({ src, srcSet, fallbackSrc, onIsLoading }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isDefault, setIsDefault] = useState(false)
  const [isError, setIsError] = useState(false)

  const changeIsLoading = (value) => {
    setIsLoading(value)

    if (_.isFunction(onIsLoading)) {
      onIsLoading(value)
    }
  }

  return (
    <div
      className={classNames(
        styles.image,
        isLoading && styles.isLoading,
        isDefault && styles.isDefault,
        isError && styles.isError,
      )}
    >
      <BaseImage
        src={src}
        srcSet={srcSet}
        fallbackSrc={fallbackSrc}
        onIsLoading={changeIsLoading}
        onIsDefault={setIsDefault}
        onIsError={setIsError}
      />
      {isLoading && <ProgressRing radius={21} progress={25} spin={true} centered={true} />}
    </div>
  )
}

export default Image
