import React, { useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import ActorProfileModal from '@library/actorProfile/ActorProfile.modal'
import { useHeightAnimation } from '@library/CustomHooks'
import DtlGroup from '@library/ui/dtl/DtlGroup'
import DtlItem from '@library/ui/dtl/DtlItem'

import styles from '@pages/appt/apptDetails/ApptDetails.module.scss'

import { extendAddress } from '@helpers/addressHelper'
import hAppt from '@helpers/hAppt'
import { formatDate } from '@helpers/time'

import { $appts, $modal } from '@store'

const ApptDetails = () => {
  const { t } = useTranslation()
  const {
    containerStyle: animatedContainerStyle,
    handleClick: animateHeight,
    contentRef: animatedContentRef,
  } = useHeightAnimation()

  const { fullAppt, status } = $appts.byRoute
  const { appointment, service, place, agent = {}, provider = {}, office } = fullAppt

  const [expanded, setExpanded] = useState(false)

  function openActorProfile(role) {
    $modal.add(ActorProfileModal, { role })
  }

  return (
    <div className={classNames(styles.apptDetails, 'mb-30', expanded ? 'expanded' : '')}>
      <div style={animatedContainerStyle}>
        <div className="mb-20" ref={animatedContentRef}>
          <DtlGroup className="row row--mb-20">
            <DtlItem className="col-12 col-lg-4" label={t('appt.label.address')}>
              {extendAddress(place).line2()}
            </DtlItem>
            <DtlItem className="col-12 col-lg-4" label={t('appt.label.scheduled_time')}>
              {appointment.appointedStart &&
                formatDate(appointment.appointedStart, { timezone: place.timezone })}
            </DtlItem>
            <DtlItem className="col-12 col-lg-4" label={t('appt.label.type')}>
              {hAppt.typeLabel({ service })}
            </DtlItem>
            <DtlItem className="col-12 col-lg-4" label={t('appt.label.practice')}>
              {hAppt.practiceLabel({ office })}
            </DtlItem>
            <DtlItem className="col-12 col-lg-4" label={t('appt.label.occ')}>
              {status.agentAssigned && (
                <div className="a" onClick={() => openActorProfile('agent')}>
                  {agent.name}
                </div>
              )}
            </DtlItem>
            <DtlItem className="col-12 col-lg-4" label={t('appt.label.provider')}>
              {status.providerAssigned && (
                <div className="a" onClick={() => openActorProfile('provider')}>
                  {provider.name}
                </div>
              )}
            </DtlItem>
            <DtlItem className="col-12 col-lg-4" label={t('appt.label.booked')}>
              {formatDate(appointment.created, { timezone: place.timezone })}
            </DtlItem>
            <DtlItem className="col-12 col-lg-4" label={t('appt.label.price')}>
              {hAppt.amount({ appointment })}
            </DtlItem>
            <DtlItem className="col-12 col-lg-4" label={t('timezone')}>
              {place.timezone}
            </DtlItem>
            <DtlItem className="col-12 col-lg-4" label={t('appt.label.appointmentId')}>
              {appointment.id}
            </DtlItem>
          </DtlGroup>
        </div>
      </div>
      <p
        className={classNames(styles.apptDetailsSwitch, 'a')}
        onClick={() => {
          animateHeight()
          setExpanded(!expanded)
        }}
      >
        {expanded ? t('appt.label.hide') : t('appt.label.see_details')}
      </p>
    </div>
  )
}

export default observer(ApptDetails)
