import React from 'react'

import { useTranslation } from 'react-i18next'

import { formatDate } from '@helpers/time'

import RoleName from '@ps/library/RoleName'
import ViewCode from '@ps/library/viewCode/ViewCode'

const Condition = ({ event, resource, extractor }) => {
  const { t } = useTranslation()
  const {
    asString,
    codeObj,
    dateEntered,
    clinicalStatus,
    verificationStatus,
    subject = {},
    asserter = {},
  } = extractor

  return (
    <div className="event_details">
      <div className="event_details-header">
        <div className="event_details-title">
          {asString}
          {event.isSelfReported && (
            <span className="badge-self_reported">{t('ps.label.self_reported')}</span>
          )}
        </div>
        <div className="event_details-code">
          <ViewCode codeObj={codeObj} />
        </div>
      </div>
      <div className="event_details-body">
        {dateEntered && (
          <div className="event_details-line">
            {t('ps.event_viewer.entered_date:')} {formatDate(dateEntered)}
          </div>
        )}
        {clinicalStatus && (
          <div className="event_details-line">
            {t('ps.event_viewer.clinical_status:')} {clinicalStatus}
          </div>
        )}
        {verificationStatus && (
          <div className="event_details-line">
            {t('ps.event_viewer.verification_status:')} {verificationStatus}
          </div>
        )}
        <div className="event_details-line mt-10">
          {t('ps.event_viewer.subject:')} {subject.value.fullName}
        </div>
        {/*<div className="event_details-line">*/}
        {/*  {t('ps.event_viewer.asserter')} {asserter.value.fullName}{' '}*/}
        {/*  <RoleName role={asserter.role} resource={asserter.value} />*/}
        {/*</div>*/}
        <div className="event_details-line event_details-line--date">
          {formatDate(event.effectiveDate)}
        </div>
      </div>
    </div>
  )
}

export default Condition
