import { useEffect, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'

import { setRG } from '@common/analytics/raygun'

import { config } from '@library/plugins/termsOfService/Tos.config'

import { loadScript } from '@helpers/url'

import { $alerts } from '@store'

import mainConfig from '@config/main'

export const useLoadJson = ({ type }) => {
  const { t } = useTranslation()
  const lang = 'en'

  const document = {
    mapUrl: `/legal/documents.json?v=${Date.now()}`,
    name: config[type]['document'],
  }

  const [json, setJson] = useState(null)
  const extractRef = useRef({})
  const isLoading = json === null

  const getFullUrl = (url) => `${mainConfig.web.tosUrl}${url}`

  useEffect(async () => {
    try {
      const documentsRes = await fetch(getFullUrl(document.mapUrl))
      const documents = await documentsRes.json()

      const tosRes = await fetch(getFullUrl(documents[lang][document.name]))
      const tosJson = await tosRes.json()

      loadScript(getFullUrl(documents[lang]['renderJSON']), () => setJson(tosJson))
    } catch (error) {
      setRG('send', {
        error: `Unable to load tos`,
        customData: { error },
      })

      $alerts.add(t('errors.load.tos'))
    }
  }, [])

  return { json, isLoading, extractRef }
}
