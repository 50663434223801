import React from 'react'

const ArrowIcon = ({ color = '#000000' }) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M5.5 18.5V18.5C5.5 20.7091 7.29086 22.5 9.5 22.5H18.5C20.7091 22.5 22.5 20.7091 22.5 18.5V9.5C22.5 7.29086 20.7091 5.5 18.5 5.5H9.5C7.29086 5.5 5.5 7.29086 5.5 9.5V9.5"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
        <path
          d="M14.6934 14L11.1578 17.5355M14.6934 14L11.1578 10.4645M14.6934 14L5.50097 14"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

export default ArrowIcon
