import i18n from 'i18next';
import { isAbsoluteEmpty } from '@helpers/other';
// todo: rename to getEnumsTKeyValue
export function getEnumsValue(data, enums, withOtherLabel) {
    var _a, _b, _c;
    if (withOtherLabel === void 0) { withOtherLabel = false; }
    var value;
    if (typeof data === 'object') {
        value = data.code;
    }
    else {
        value = data;
    }
    if (isAbsoluteEmpty(value)) {
        return '';
    }
    if (!enums[value] || value === ((_a = enums.other) === null || _a === void 0 ? void 0 : _a.id)) {
        var val = typeof data === 'object' && data.text ? data === null || data === void 0 ? void 0 : data.text : data;
        var tKey_1 = (_b = enums.other) === null || _b === void 0 ? void 0 : _b.tKey;
        var label = i18n.exists(tKey_1) ? i18n.t(tKey_1) : i18n.t('enums.other');
        if (withOtherLabel) {
            return [label, val].join(': ');
        }
        else if (value === ((_c = enums.other) === null || _c === void 0 ? void 0 : _c.id)) {
            return label;
        }
        return val;
    }
    var tKey = enums[value].tKey;
    return i18n.exists(tKey)
        ? i18n.t(tKey)
        : typeof data === 'object' && data.text
            ? data === null || data === void 0 ? void 0 : data.text
            : value;
}
