import React from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import TrendsList from '@library/trendsList/TrendsList'
import Title from '@library/ui/title/Title'

import { useInitPatient } from '@ps/library/CustomHooks'
import PatientDetails from '@ps/pages/patient/PatientDetails'
import PatientFooterForPrint from '@ps/pages/patient/PatientFooterForPrint'
import PatientHeader from '@ps/pages/patient/PatientHeader'
import PatientHeaderForPrint from '@ps/pages/patient/PatientHeaderForPrint'
import PatientNavigation from '@ps/pages/patient/PatientNavigation'
import PatientToolbar from '@ps/pages/patient/PatientToolbar'
import TabOverview from '@ps/pages/patient/tabs/TabOverview'

import { $main, $patients, $psHistory, $psPatient, $user } from '@store'

const Patient = ({ byRoute = {} }) => {
  const { t } = useTranslation()
  const { categoryId, patientId } = useParams()

  useInitPatient({ categoryId })
  const patient = $psPatient.patient
  const cocPatient = $patients.getById(patientId)

  const isVitals = categoryId === 'vitals'
  const hasVitals = $main.isMobile && isVitals

  const TabComponent = $psHistory.category && $psHistory.categoryConfig?.component

  return (
    <div className="content">
      <PatientToolbar hasVitals={hasVitals} />
      <div className="container patient_page_print_wrapper">
        <div className="box">
          <div className="box-header">
            {hasVitals ? (
              <Title size="h1">{t('ps.label.vitals')}</Title>
            ) : $psHistory.category ? (
              <Title size="h1">{t('ps.categories.' + $psHistory.category)}</Title>
            ) : (
              <PatientHeader patient={patient} cocPatient={cocPatient} />
            )}
          </div>
          <div className="box-body box-body--padding">
            {TabComponent ? (
              <>
                <PatientHeaderForPrint patient={cocPatient} />
                <TabComponent byRoute={byRoute} />
                <PatientFooterForPrint profile={$user.profile} />
              </>
            ) : (
              <>
                {(!$main.isMobile || hasVitals) && (
                  <PatientDetails patient={patient} cocPatient={cocPatient} />
                )}
                {!hasVitals && (
                  <>
                    {$main.isMobile && (
                      <Title size="h3" className="mb-20">
                        {t('label.demographics')}
                      </Title>
                    )}
                    <PatientNavigation />
                    <TabOverview />
                    <TrendsList />
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(Patient)
