import React from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import InputBase from '@library/form/fields/new/InputBase'

const AddInsuranceInput = ({ form, fieldKey, fieldProps, tKey }) => {
  const { t } = useTranslation()

  return (
    <InputBase
      className="mb-10"
      label={t(`insurance.label.${tKey}`)}
      placeholder={t(`insurance.ph.${tKey}`)}
      field={form.fields[fieldKey]}
      mode="lg"
    />
  )
}

export default observer(AddInsuranceInput)
