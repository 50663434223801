import React from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import PatientsList from '@library/patients/patientsList/PatientsList'
import Toolbar from '@library/toolbar/Toolbar'

import { $patients } from '@store'

import { routeActions } from '@config/routeActions'

const Patients = () => {
  const { t } = useTranslation()

  function handleOnClick(item) {
    return routeActions.PATIENT({ patientId: item.id })
  }

  return (
    <div className="content">
      <Toolbar goBackProps={{ autoTarget: routeActions.HOME() }} />
      <div className="container">
        <div className="box">
          <div className="box-header">
            <div className="box-header-title title-lg mb-0">{t('label.circle_of_care')}</div>
          </div>
          <div className="box-body box-body--padding box--md">
            <PatientsList items={$patients.items} to={handleOnClick} hoverText={t('btn.view')} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(Patients)
