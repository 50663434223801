import React, { forwardRef, useEffect, useMemo, useState } from 'react'

import { observer } from 'mobx-react'

import classNames from 'classnames'

import { $main } from '@store'

const AutoTextarea = forwardRef(
  (
    {
      value = '',
      onChange,
      onSubmit,
      minRows = 1,
      maxRows = 5,
      className = '',
      wrapperProps = {},
      ...passedProps
    },
    ref,
  ) => {
    const [height, setHeight] = useState('auto')
    const [parentHeight, setParentHeight] = useState('auto')

    const lineHeight = useMemo(() => {
      if (!ref || !ref.current) return false
      return parseInt(window.getComputedStyle(ref.current).getPropertyValue('line-height'))
    }, [ref.current]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      if (!ref || !ref.current) return false

      let scrollHeight = ref.current.scrollHeight

      let minHeight = lineHeight * minRows
      let maxHeight = lineHeight * maxRows

      if (_.isEmpty(value)) {
        scrollHeight = minHeight
      }

      if (scrollHeight > maxHeight) {
        scrollHeight = maxHeight
      }

      setHeight(scrollHeight + 'px')
      setParentHeight(scrollHeight + 'px')
    }, [ref, value, lineHeight, minRows, maxRows])

    function handleOnChange(e) {
      setHeight('auto')

      if (_.isFunction(onChange)) {
        onChange(e)
      }
    }

    function handleOnKeyDown(e) {
      if (!$main.isMobile && e.keyCode === 13 && !e.shiftKey && _.isFunction(onSubmit)) {
        e.preventDefault()
        onSubmit(e)
      }
    }

    return (
      <div style={{ width: '100%', parentHeight }} {...wrapperProps}>
        <textarea
          {...passedProps}
          ref={ref}
          rows={minRows}
          style={{ height }}
          className={classNames('styled_scroll', className)}
          value={value}
          onChange={handleOnChange}
          onKeyDown={handleOnKeyDown}
        />
      </div>
    )
  },
)

export default observer(AutoTextarea)
