import React, { useMemo } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'

import { toPath, useRouterSearchParams } from '@helpers/router'

import Vitals from '@ps/assets/svg/vitals'
import { eventsSchema } from '@ps/config/events'

import { $main, $psHistory } from '@store'

const PatientNavigation = () => {
  const { t } = useTranslation()
  const params = useParams()

  const searchParams = useRouterSearchParams()
  const backToApptUrl = searchParams.get('backToApptUrl')

  function handleChangeTab(x) {
    const categoryId = x ? _.kebabCase(x) : undefined
    return toPath('psPatient', {
      params: { ...params, categoryId },
      query: { ...(backToApptUrl && { backToApptUrl }) },
    })
  }

  const preparedList = useMemo(() => {
    let result = []

    if ($main.isMobile) {
      result.push({
        id: 'vitals',
        label: t('ps.label.vitals'),
        color: '#f3a7a8',
        icon: Vitals,
      })
    }

    _.forEach(_.orderBy(eventsSchema, 'order', 'asc'), (x) => {
      const count = _.get($psHistory.eventsBySubcategory, [x.id + '_all'], []).length

      if (count) {
        result.push({ ...x, label: t(x.label), count })
      }
    })

    return result
  }, [$main.isMobile, $psHistory.eventsBySubcategory]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="ps_patient_nav mb-30">
      <div className="row row--gutters-10">
        {preparedList.map((item) => (
          <div className="col-12 col-lg-4" key={item.id}>
            <Link to={handleChangeTab(item.id)} className="ps_patient_nav-item">
              <div className="ps_patient_nav-item-icon" style={{ backgroundColor: item.color }}>
                <item.icon />
              </div>
              <div className="ps_patient_nav-item-label title-sm mb-0">
                {item.label} <span>{item.count}</span>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}

export default observer(PatientNavigation)
