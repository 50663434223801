import DocumentsTableDate from '@ps/library/eventsViewer/tableDetails/DocumentsTableDate'
import DocumentsTableDetails from '@ps/library/eventsViewer/tableDetails/DocumentsTableDetails'
import BaseDocumentsModal from '@ps/library/modals/BaseDocuments.modal'

export const labsConfig = {
  lab_diagnostic_report: {
    extractor: 'diagnostic_report',
    // needDeepInclude: true,
    condition: {
      and: [{ field: 'resource', eq: 'DiagnosticReport' }],
      or: [
        { field: 'subtype', eq: ['medical_history'] },
        { field: 'subtype', eq: ['medical_history', 'lab'] },
      ],
    }, // rowType: 'labs',
    viewer: {},
  },
  lab_document: {
    extractor: 'document',
    eventModal: BaseDocumentsModal,
    condition: [
      { field: 'resource', eq: 'DocumentReference' },
      {
        field: 'subtype',
        sin: [
          'test_result_document_type',
          'diagnostic_report_document_type',
          'test_result_reported_to_doh_document_type',
        ],
      },
    ],
    rowType: 'documents',
    viewer: {
      tableDetails: DocumentsTableDetails,
      tableDate: DocumentsTableDate,
    },
  },
}
