var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { getMode } from '@helpers/other';
import ArrowIcon from '@assets/svg/Arrow';
import styles from './MultiSelectButton.module.scss';
var MultiSelectButton = function (_a) {
    var onOpen = _a.onOpen, onClear = _a.onClear, _b = _a.selection, selection = _b === void 0 ? [] : _b, visibleCount = _a.visibleCount, multiSelect = _a.multiSelect, allOption = _a.allOption, mode = _a.mode, label = _a.label, placeholder = _a.placeholder, getSelected = _a.getSelected, clear = _a.clear, hasSelection = _a.hasSelection, isOpen = _a.isOpen, isInvalid = _a.isInvalid, readonly = _a.readonly, innerClassNames = _a.innerClassNames;
    var t = useTranslation().t;
    var _c = useState(isOpen), open = _c[0], setOpen = _c[1];
    useEffect(function () {
        if (isOpen !== open) {
            setOpen(isOpen);
        }
    }, [open, isOpen]);
    var toggle = function (b) {
        setOpen(!open);
        onOpen(!open);
    };
    function handleClear() {
        onClear([]);
        setOpen(false);
        onOpen(false);
    }
    var multiselectPreview = useMemo(function () {
        if (selection.length <= visibleCount) {
            return getSelected;
        }
        else if (selection.length === 0 && allOption) {
            return allOption;
        }
        else if (selection.length === 0 && placeholder) {
            return placeholder;
        }
        else if (selection.length === 0 && !allOption && !placeholder) {
            return '';
        }
        else {
            return t('label.selected_preview', { selectedCount: selection.length });
        }
    }, [t, placeholder, allOption, selection, getSelected, visibleCount]);
    var _mode = _.isArray(mode) ? mode : [mode];
    return (_jsxs(_Fragment, { children: [(_mode.indexOf('sm') !== -1 || label || clear) && (_jsxs("div", __assign({ className: styles.topBar }, { children: [_mode.indexOf('sm') !== -1 && label && _jsx("div", __assign({ className: styles.topBarLabel }, { children: label })), clear && hasSelection && (_jsx("div", __assign({ className: styles.topBarClear, onClick: function () { return handleClear(); } }, { children: t('btn.clear') })))] }))), _jsxs("div", __assign({ className: classNames(styles.multiselect, innerClassNames, _mode && getMode(mode, styles), open && styles.multiselectOpen, isInvalid && styles.multiselectError, readonly && styles.multiselectReadonly, 'multiselect'), onClick: function () { return toggle(!open); } }, { children: [_jsxs("div", __assign({ className: classNames(styles.multiselectPreview, 'multiselect-preview') }, { children: [_mode.indexOf('lg') !== -1 && _jsx("div", __assign({ className: styles.multiselectLabel }, { children: label })), _jsx("div", __assign({ className: classNames(styles.multiselectSelected, !hasSelection && placeholder && styles.multiselectPlaceholder, _mode.indexOf('fw-400') !== -1 && styles.fw400) }, { children: multiSelect ? multiselectPreview : getSelected }))] })), _jsx("div", __assign({ className: classNames(styles.multiselectArrow, open && styles.multiselectArrowOpen) }, { children: _jsx(ArrowIcon, { color: open ? '#000000' : undefined }) }))] }))] }));
};
export default observer(MultiSelectButton);
