import React from 'react'

import { observer } from 'mobx-react'

import { $configure } from '@library/plugins/configure/configure.store'
import ProgressRing from '@library/ui/progressRing/ProgressRing'

const ConfigureProgress = () => {
  const progress = Math.round(($configure.currentStep / $configure.allSteps) * 100)

  return <ProgressRing progress={progress} radius={14} stroke={2} />
}

export default observer(ConfigureProgress)
