import { makeAutoObservable, reaction, runInAction } from 'mobx'

import moment from 'moment-timezone'

import { analytics } from '@common/analytics/zipAnalytics'

import settingsService from '@services/settings'

import { getWindowSizes, localStore } from '@helpers/other'

import { $user } from '@store'

class MainStore {
  constructor() {
    makeAutoObservable(this)
  }

  sidebar = false
  pageIsLoaded = 0
  windowSize = getWindowSizes()

  settings = {}
  #settingsCacheDate = null

  initExtraData = localStore.get('userSettings.initExtraData', {})
  rightSidebar = localStore.get('userSettings.rightSidebar', { active: null, payload: {} })

  // Computed
  get isMobile() {
    return this.windowSize.isMobile
  }

  get isMobileOnly() {
    return this.windowSize.isMobileOnly
  }

  get isMobileSize() {
    return this.windowSize.maxLG
  }

  // Mutations

  SET_PAGE_IS_LOADED(value) {
    this.pageIsLoaded = value ? this.pageIsLoaded + 1 : 0
  }

  SET_WINDOW_SIZE(value) {
    this.windowSize = value
  }

  SET_INIT_EXTRA_DATA(value) {
    this.initExtraData = value
  }

  // Actions
  sidebarToggle(value) {
    if (typeof value === 'undefined') {
      value = !this.sidebar
    }

    if (value) {
      document.documentElement.classList.add('sidebar-open')
    } else {
      document.documentElement.classList.remove('sidebar-open')
    }

    this.sidebar = value

    analytics.breadcrumb('nav-toggleSidebar', { state: this.sidebar })
  }

  showRightSidebar(key, payload) {
    this.rightSidebar.active = key
    if (payload) {
      this.rightSidebar.payload[key] = payload
    }
    localStore.set('userSettings.rightSidebar', this.rightSidebar)
  }

  hideRightSidebar() {
    this.rightSidebar.active = null
    localStore.set('userSettings.rightSidebar', this.rightSidebar)
  }

  removeRightSidebar(key) {
    this.rightSidebar.active = null
    delete this.rightSidebar.payload[key]
    localStore.set('userSettings.rightSidebar', this.rightSidebar)
  }

  async loadSettings() {
    if (!_.isEmpty(this.settings) && moment().diff(this.#settingsCacheDate, 'seconds') < 900) {
      return this.settings
    }

    const response = await settingsService.getAll()

    runInAction(() => {
      if (response?.prepared) {
        this.settings = response.prepared
        this.#settingsCacheDate = new Date().toISOString()
      }
    })
  }
}

const store = new MainStore()

setTimeout(() => {
  reaction(
    () => $user.user,
    (value) => {
      if (value) {
        store.rightSidebar = localStore.get('userSettings.rightSidebar', {
          active: null,
          payload: {},
        })
      } else {
        store.rightSidebar = { active: null, payload: {} }
      }
    },
  )
}, 0)

reaction(
  () => store.initExtraData,
  (value) => {
    localStore.set('userSettings.initExtraData', value, { lifeTime: 3600 }) // todo: decrease/increase lifeTime?
  },
)

export default store
