import { action, makeObservable, observable } from 'mobx'

import moment from 'moment-timezone'

import { $psHistory } from '@src/store'

import { extractorScheme } from '@ps/config/resourceExtractor'
import { getReferenceId, parseReferenceId, withFullName } from '@ps/helpers/fhir'
import psService from '@ps/services/psService'

const RESOURCE_CACHE = 300 // seconds
const COMPLETE_RESOURCE_CACHE = 60 // seconds

class psResourcesStore {
  constructor() {
    makeObservable(this, {
      items: observable,
      cacheMap: observable,
      clear: action,
      ADD_ITEMS: action,
      getResources: action,
      getCompleteResources: action,
    })
  }

  items = {}
  cacheMap = {}

  // clear
  clear() {
    this.items = {}
    this.cacheMap = {}
  }

  // Computed

  // Mutations
  ADD_ITEMS(values, isComplete = false) {
    let result = this.items
    let cache = this.cacheMap

    _.forEach(values, (value) => {
      if (value.hasOwnProperty('name')) {
        value = withFullName(value)
      }
      result[value.id] = value
      cache[value.id] = { date: new Date().toISOString(), isComplete }
    })

    this.items = result
    this.cacheMap = cache
  }

  // Actions
  checkInCache(id) {
    let result = false
    const cacheItem = this.cacheMap[id]

    if (cacheItem && !_.isEmpty(this.getById(id))) {
      const targetDiff = cacheItem.isComplete ? COMPLETE_RESOURCE_CACHE : RESOURCE_CACHE
      result = moment().diff(cacheItem.date, 'seconds') < targetDiff
    }

    return result
  }

  async getResources({ items = [] }) {
    let exists = []
    let references = []
    let forCompleteRequest = []

    _.forEach(items, (item) => {
      const found = this.getById(item.id)

      if (!_.isEmpty(found) && this.checkInCache(item.id)) {
        exists.push(found)
      } else if (item.extractor && item.needDeepInclude) {
        forCompleteRequest.push(item)
      } else {
        references.push(getReferenceId(item))
      }
    })

    let result

    if (references.length) {
      const response = await psService.getResourcesByReferences({ references })

      const res = _.get(response, 'prepared.resources', [])
      this.ADD_ITEMS(res)

      result = [...exists, ...res]
    } else {
      result = exists
    }

    if (forCompleteRequest.length) {
      const res = await this.getCompleteResources({ items: forCompleteRequest })
      const filteredByItems = _.intersectionBy(forCompleteRequest, res, 'id')
      result = [...result, ...filteredByItems]
    }

    return result
  }

  async getCompleteResources({ items = [], deepinclude = [] }) {
    deepinclude = ['* > PractitionerRole:practitioner', ...deepinclude]

    let references = []

    items = items.filter((x) => !_.isEmpty(x))

    // forming ids for resources from events
    _.forEach(items, (item) => {
      references.push(getReferenceId(item))

      if (item.extractor) {
        const deepincludeConfig = _.get(extractorScheme(item.extractor), 'deepinclude', [])
        if (deepincludeConfig.length) {
          deepinclude = [...deepinclude, ...deepincludeConfig]
          if (item.resource === 'Appointment') {
            const encounterEvent = $psHistory.getEncounterByAppointmentId(item.id)
            if (!_.isEmpty(encounterEvent)) {
              references.push(getReferenceId(encounterEvent))
            }
          }
        }
      }
    })

    const response = await psService.getCompleteResourcesByReferences({
      references,
      deepinclude: _.uniq(deepinclude),
    })

    const result = _.get(response, 'prepared.resources', [])
    // _.forEach(result, (x) => {
    // if (x.resourceType === 'DiagnosticReport') {
    // console.log(x.resourceType, x.id)
    // }
    // })
    this.ADD_ITEMS(result, true)

    return result
  }

  getById(id) {
    return this.items[id] || {}
  }

  getByReferences(data = {}) {
    const reference = _.get(data, 'reference', data)
    const parsed = parseReferenceId(reference)

    return this.getById(parsed.id)
  }

  getEncounterByAppointmentId(appointmentId) {
    const id = getReferenceId({ resource: 'Appointment', id: appointmentId })

    // todo change to resources map with keys for optimization
    return (
      _.find(this.items, (x) => {
        return x.resourceType === 'Encounter' && _.some(x.appointment, (y) => y.reference === id)
      }) || {}
    )
  }
}

export const psResourcesStoreCore = psResourcesStore

const store = new psResourcesStore()
export default store
