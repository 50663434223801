var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { $main } from '@store';
import styles from './dtl.module.scss';
var DtlGroup = function (_a) {
    var className = _a.className, children = _a.children, _b = _a.asList, asList = _b === void 0 ? $main.windowSize.maxLG : _b;
    return (_jsx("div", __assign({ className: classNames(styles.group, asList && 'dtl-group--as-list', className) }, { children: children })));
};
export default observer(DtlGroup);
