import React from 'react'

import { observer } from 'mobx-react'

import BookingHome from '@booking/pages/bookingHome/BookingHome'
import classNames from 'classnames'

import Toolbar from '@library/toolbar/Toolbar'

import QuickLinks from '@pages/home/QuickLinks'

import { $main } from '@store'

import mainConfig from '@config/main'

import styles from './Home.module.scss'

const Home = ({ routeConfig }) => {
  return (
    <div className="content">
      <Toolbar
        hasBurger={routeConfig.meta?.hasBurger && $main.isMobileSize}
        className={styles.toolbar}
      />
      <div className={classNames('container', $main.isMobileSize && 'p-0')}>
        <BookingHome />
        {mainConfig.custom.homeQuickLinks && <QuickLinks />}
      </div>
    </div>
  )
}

export default observer(Home)
