import React, { useEffect, useMemo, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import BookingLayout from '@booking/library/bookingLayout/BookingLayout'
import withBookingDataInit from '@booking/library/withBookingDataInit/withBookingDataInit'
import SelectPracticeModal from '@booking/pages/bookingTimePicker/selectPracticeModal/SelectPractice.modal'

import TimeSlotPicker from '@library/timeSlotPicker/TimeSlotPicker'
import Button from '@library/ui/button/Button'

import { customGoTo } from '@helpers/router'
import { formatDate } from '@helpers/time'

import { $booking, $modal } from '@store'

const BookingTimePicker = () => {
  const { t } = useTranslation()

  useEffect(() => {
    $booking.SET_FINISH_PRACTICE_ID(null)
  }, [])

  const [selectedWindow, setSelectedWindow] = useState({
    key: $booking.selectedTime,
    value: [],
  })

  const requestParams = useMemo(() => {
    // mock for dev
    //   return {
    //     practiceId: 1,
    //     serviceId: $booking.serviceId,
    //     placeId: 15,
    //     patientNumber: 1,
    //     symptomIds: [12, 135],
    //     begin: `$date:${begin.format('YYYY-MM-DDTHH:mm:ss')}`,
    //     end: `$date:${end.format('YYYY-MM-DDTHH:mm:ss')}`,
    //   }
    // mock for dev

    const result = {
      serviceId: $booking.serviceId,
      placeId: $booking.placeId,
      patientNumber: $booking.$patients.selectedIds.length,
      symptomIds: $booking.$symptoms.selectedIds,
    }

    if ($booking.practiceId) {
      result.practiceId = $booking.practiceId
      result.officeId = $booking.officeId
    }

    return result
  }, [])

  // mock for dev
  // const timezone = $booking.$places.getById(requestParams.placeId).timezone
  // mock for dev

  const timezone = $booking.place.timezone

  const handleNext = () => {
    if (selectedWindow.value.length > 1) {
      const practices = $booking.servePractices
        .filter((item) => selectedWindow.value.includes(item.id))
        .map((item) => {
          const practiceService = _.find(
            $booking.practiceServices,
            ({ practiceId, serviceId }) =>
              practiceId === item.id && serviceId === $booking.serviceId,
          )

          return {
            practiceId: item.id,
            practiceName: item.name,
            price: practiceService?.price,
            currency: practiceService?.currency,
          }
        })

      $modal.add(SelectPracticeModal, {
        practices: practices,
        onSelect: (selected) => {
          $booking.SET_SELECTED_TIME(selectedWindow.key)
          $booking.SET_FINISH_PRACTICE_ID(selected)
          customGoTo($booking.schemaConfig?.nextStep())
        },
      })
    } else if (selectedWindow.value.length === 1) {
      $booking.SET_SELECTED_TIME(selectedWindow.key)
      $booking.SET_FINISH_PRACTICE_ID(selectedWindow.value[0])
      customGoTo($booking.schemaConfig?.nextStep())
    }
  }

  return (
    <BookingLayout
      breadcrumbs={['place', 'service', 'patients']}
      title={t('label.visit_date')}
      isMobileFooterFixed={true}
      isDesktopFooterFixed={true}
      button={
        <Button
          mode="primary"
          tag="button"
          buttonType="button"
          disabled={_.isEmpty(selectedWindow?.value)}
          label={
            _.isEmpty(selectedWindow?.value)
              ? t('new_time_slot_picker.select_slot')
              : formatDate(selectedWindow.key, {
                  format: 'datetimeFullNoYear',
                  timezone: timezone,
                })
          }
          action={handleNext}
        />
      }
    >
      <TimeSlotPicker
        value={selectedWindow}
        onChange={setSelectedWindow}
        requestParams={requestParams}
        placeTimezone={timezone}
      />
    </BookingLayout>
  )
}

export default withBookingDataInit(observer(BookingTimePicker))
