import React, { useEffect, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useLocation, useParams } from 'react-router-dom'

import classNames from 'classnames'

import Button from '@library/ui/button/Button'
import PatientPhotoAndName from '@library/ui/patientPhotoAndName/PatientPhotoAndName'

import EncounterControl from '@pages/appt/patient/EncounterControl'

import hAppt from '@helpers/hAppt'
import { getUserText } from '@helpers/user'

import { $appts, $main } from '@store'

import { routeActions } from '@config/routeActions'

import styles from './ApptPatient.module.scss'

const ApptPatient = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const { encounterId } = useParams()
  const { fullAppt, status } = $appts.byRoute
  const { appointment, patients, encounters } = fullAppt

  const [activePatient, setActivePatient] = useState(null)
  const [updatingPatientInfo, setUpdatingPatientInfo] = useState(false)
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    if (encounterId) {
      setUpdatingPatientInfo(true)
      setActivePatient(_.find(patients, (x) => x.activeEncounter === +encounterId))
    }
  }, [encounterId]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (activePatient && updatingPatientInfo) {
      setUpdatingPatientInfo(false)
    }
  }, [activePatient]) // eslint-disable-line react-hooks/exhaustive-deps

  const encounterControlIsVisible =
    hAppt.needToFinishEncountersManually(fullAppt) &&
    status.isStartProvider &&
    appointment.status === 'started'

  return (
    <div className={classNames(styles.wrapper, 'mb-30')}>
      {activePatient && (
        <>
          <div className={styles.apptPatient}>
            <div className={styles.name}>
              <PatientPhotoAndName
                firstName={activePatient.firstName}
                lastName={activePatient.lastName}
                avatar={activePatient.avatar}
              />
            </div>
            <div className={styles.info}>
              {$main.isMobile && !expanded ? (
                <>
                  <div className={styles.mobileInfoBlock}>
                    <p>{getUserText(activePatient, ['gender', 'yo'])}</p>
                  </div>
                  <div
                    className={classNames('a', styles.moreInfoBtn)}
                    onClick={() => setExpanded(!expanded)}
                  >
                    {t('btn.more')}
                  </div>
                </>
              ) : (
                <>
                  <div className={styles.patientInfoBlock}>
                    <div className={styles.patientInfoBlockTitle}>
                      {t('label.sex_assigned_at_birth')}
                    </div>
                    <div className={styles.patientInfoBlockText}>
                      {getUserText(activePatient, ['gender'])}
                    </div>
                  </div>
                  <div className={styles.patientInfoBlock}>
                    <p className={styles.patientInfoBlockTitle}>{t('label.date_of_birth')}</p>
                    <p className={styles.patientInfoBlockText}>
                      {getUserText(activePatient, ['dob'])} ({getUserText(activePatient, ['yo'])})
                    </p>
                  </div>
                  <div className={styles.patientInfoBlock}>
                    <p className={styles.patientInfoBlockTitle}>{t('label.phone')}</p>
                    <p className={styles.patientInfoBlockText}>{activePatient.phone}</p>
                  </div>
                  <div className={styles.patientInfoBlock}>
                    <p className={styles.patientInfoBlockTitle}>{t('label.email')}</p>
                    <p className={styles.patientInfoBlockText}>{activePatient.email}</p>
                  </div>
                  <div className={styles.patientInfoBlock}>
                    <p className={styles.patientInfoBlockTitle}>{t('label.encounter_id')}</p>
                    <p className={styles.patientInfoBlockText}>{encounterId}</p>
                  </div>
                  <div className={styles.patientInfoBlock}>
                    <p className={styles.patientInfoBlockTitle}>{t('label.patient_id')}</p>
                    <p className={styles.patientInfoBlockText}>{activePatient.id}</p>
                  </div>
                  {$main.isMobile && (
                    <div
                      className={classNames('a', styles.moreInfoBtn)}
                      onClick={() => setExpanded(!expanded)}
                    >
                      {t('label.minimize')}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          <div className={classNames(styles.controls, $main.isMobile && styles.controlsMobileView)}>
            <div className={styles.controlsHistoryBtn}>
              <Button
                mode={$main.isMobile ? ['secondary', 'icon'] : ['white', 'icon']}
                className={classNames('btn-icon--blue-link-arrow')}
                autoTarget={routeActions.PS_PATIENT(
                  {
                    patientId: activePatient.id,
                    practiceId: appointment.practiceId,
                  },
                  { backToApptUrl: location.pathname },
                )}
              >
                {t('btn.view_open_patient_history')}
              </Button>
            </div>
            <EncounterControl
              withControls={encounterControlIsVisible}
              encounter={encounters[encounterId]}
              appointment={appointment}
              durationInButton={$main.isMobile}
              isLoading={updatingPatientInfo}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default observer(ApptPatient)
