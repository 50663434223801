import React from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import Toolbar from '@library/toolbar/Toolbar'

import CocPatientDetails from '@pages/cocPatient/CocPatientDetails'
import PatientHeader from '@pages/cocPatient/CocPatientHeader'

import PsPatientPractices from '@ps/library/widgets/psPatientPractices/PsPatientPractices'

import { $patients } from '@store'

import { routeActions } from '@config/routeActions'

const CocPatient = () => {
  const { t } = useTranslation()
  const { patientId } = useParams()

  const patient = $patients.getById(patientId)

  return (
    <div className="content">
      <Toolbar goBackProps={{ autoTarget: routeActions.PATIENTS() }} />
      <div className="container">
        <div className="box">
          <div className="box-header">
            <PatientHeader patient={patient} />
          </div>
          <div className="box-body box-body--padding box--md">
            <CocPatientDetails patient={patient} />
          </div>
          <div className="box-header title-sm mt-10 pb-0">{t('label.practices')}</div>
          <div className="box-body box--md">
            <PsPatientPractices patientId={patientId} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(CocPatient)
