import { makeAutoObservable, runInAction } from 'mobx'

import { patientsPageLimit } from '@ps/config/patients'
import { withFullName } from '@ps/helpers/fhir'
import psService from '@ps/services/psService'

class psPatientsStore {
  constructor() {
    makeAutoObservable(this)
  }

  items = []
  searchParams = {}

  // clear
  clear() {
    this.items = []
    this.searchParams = {}
  }

  // Computed

  // Mutations
  SET_SEARCH_PARAMS(value) {
    this.searchParams = value
  }

  // Actions
  async getList({ page, ...extendParams } = {}) {
    page = Number(page) || 1

    const params = {
      limit: patientsPageLimit,
      offset: patientsPageLimit * (page - 1), //
      content: this.searchParams.content,
      ...this.searchParams.regular,
      ...this.searchParams.custom, //
      ...extendParams,
    }

    const response = await psService.getPatients(params)
    let items = _.get(response, 'prepared.patients', [])
    items = items.map((item) => {
      if (item.hasOwnProperty('name')) {
        item = withFullName(item)
      }
      return item
    })

    runInAction(() => {
      if (page > 1) {
        this.items = [...this.items, ...items]
      } else {
        this.items = items
      }
    })

    return this.items
  }
}

export default new psPatientsStore()
