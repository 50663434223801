var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { analytics } from '@common/analytics/zipAnalytics';
import { useInnerValue } from '@library/CustomHooks';
import { getFilteredItems } from '@library/selectDropdown/MultiSelect.helpers';
import CheckIcon from '@assets/svg/Check';
import styles from './MultiSelectDropdown.module.scss';
var MultiSelectDropdown = function (_a) {
    var onSelected = _a.onSelected, onOpen = _a.onOpen, _b = _a.selection, _selection = _b === void 0 ? [] : _b, _c = _a.items, items = _c === void 0 ? [] : _c, multiSelect = _a.multiSelect, allowUnselect = _a.allowUnselect, allOption = _a.allOption, search = _a.search, selectBtnWidth = _a.selectBtnWidth;
    var t = useTranslation().t;
    var _d = useInnerValue(_selection), selection = _d[0], setSelection = _d[1];
    var _e = useState(''), searchString = _e[0], setSearchString = _e[1];
    var filteredItems = useMemo(function () {
        return getFilteredItems(searchString, items);
    }, [items, searchString]);
    function isSelected(element) {
        return selection.indexOf(element.id) !== -1;
    }
    function handleCheck(id) {
        var result = _.cloneDeep(selection);
        if (!allowUnselect && result.length === 1 && result.includes(id)) {
            if (!multiSelect) {
                onOpen(false);
            }
            return;
        }
        analytics.track('dropdown-select', {
            multi: multiSelect,
            id: id,
        });
        if (multiSelect) {
            var index = result.indexOf(id);
            if (index !== -1) {
                result.splice(index, 1);
            }
            else {
                result.push(id);
            }
            setSelection(result);
            onSelected(result);
        }
        else {
            if (id === selection[0]) {
                result = [];
            }
            else {
                result = [id];
            }
            onOpen(false);
            setSelection(result);
            onSelected(result);
        }
    }
    var handleSelectAll = function () {
        setSelection([]);
        onSelected([]);
        onOpen(false);
    };
    function handleSearch(event) {
        var value = _.get(event, 'target.value', event);
        setSearchString(value);
    }
    function handleClearSearch(e) {
        e.preventDefault();
        setSearchString('');
    }
    return (_jsxs("div", __assign({ className: styles.dropdown }, { children: [search && (_jsx("div", __assign({ className: styles.search }, { children: _jsxs("div", __assign({ className: styles.searchItem }, { children: [_jsx("input", { type: "text", value: searchString, onChange: function (e) { return handleSearch(e); }, className: styles.searchField }), searchString && (_jsx("div", { className: styles.searchClear, onClick: function (e) { return handleClearSearch(e); } }))] })) }))), _jsx("div", __assign({ className: classNames(styles.inner, 'styled_scroll'), style: { maxHeight: search ? 300 + 'px' : 360 + 'px' } }, { children: filteredItems.length > 0 && filteredItems[0].items.length > 0
                    ? filteredItems.map(function (item) { return (_jsxs("div", { children: [item.title && _jsx("div", __assign({ className: styles.itemTitle }, { children: item.title && item.title })), _jsxs("ul", { children: [allOption && (_jsxs("li", __assign({ className: styles.itemSub, onClick: function () { return handleSelectAll(); } }, { children: [_jsx("p", __assign({ className: styles.text }, { children: allOption })), selection.length === 0 && (_jsx("div", __assign({ className: styles.isCheck }, { children: _jsx(CheckIcon, { color: '#17a2c6' }) })))] }))), item.items.map(function (el) { return (_jsxs("li", __assign({ style: {
                                            maxWidth: el.additionalText
                                                ? selectBtnWidth > 375
                                                    ? "".concat(selectBtnWidth, "px")
                                                    : '375px'
                                                : 'auto',
                                        }, className: classNames(styles.itemSub, isSelected(el) && styles.itemSub_isChecked), onClick: function () { return handleCheck(el.id); }, title: el.text }, { children: [_jsx("p", __assign({ className: styles.text }, { children: el.text })), el.additionalText && _jsx("span", { children: el.additionalText }), isSelected(el) && (_jsx("div", __assign({ className: classNames(styles.isCheck, el.additionalText && styles.isCheckPositionTop) }, { children: _jsx(CheckIcon, { color: '#17a2c6' }) })))] }), el.id)); })] })] }, item.items.map(function (x) { return x.id; }).join())); })
                    : search &&
                        filteredItems && (_jsx("div", __assign({ className: styles.searchNoResults }, { children: t('label.search.no_results', { searchString: searchString }) }))) }))] })));
};
export default observer(MultiSelectDropdown);
