// import AppointmentSymptoms from '@ps/library/eventsViewer/tableDetails/AppointmentSymptoms'
import AsString from '@ps/library/eventsViewer/tableDetails/AsString'
import AppointmentEventModal from '@ps/library/modals/AppointmentEvent.modal'

export const appointmentConfig = {
  appointment_main: {
    extractor: 'appointment',
    needDeepInclude: true,
    eventModal: AppointmentEventModal,
    condition: [
      { field: 'resource', eq: 'Appointment' },
      { field: 'subtype', eq: ['appointment'] },
    ],
    rowType: 'appointments',
    viewer: {
      // tableDetails: AppointmentSymptoms,
      tableDetails: AsString,
    },
  },
  appointment_next: {
    extractor: 'appointment',
    needDeepInclude: true,
    eventModal: AppointmentEventModal,
    condition: [
      { field: 'resource', eq: 'Appointment' },
      { field: 'subtype', eq: ['next_appointment'] },
    ],
    rowType: 'appointments',
    viewer: {
      tableDetails: AsString,
    },
  },
}
