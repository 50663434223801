var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react';
import { $main } from '@store';
import imgBurger from '@assets/images/ui/burger.svg';
import styles from './Burger.module.scss';
var Burger = function () {
    function handleClick(e) {
        e.stopPropagation();
        $main.sidebarToggle();
    }
    return (_jsx("div", __assign({ className: styles.burger, onClick: handleClick }, { children: _jsx("img", { src: imgBurger, alt: "" }) })));
};
export default observer(Burger);
