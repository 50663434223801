var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import classNames from 'classnames';
import { getMode } from '@helpers/other';
import styles from './PatientInfoBox.module.scss';
var PatientInfoBox = function (_a) {
    var _b = _a.mode, mode = _b === void 0 ? 'sm' : _b, _c = _a.clickable, clickable = _c === void 0 ? true : _c, onClick = _a.onClick, className = _a.className, label = _a.label, value = _a.value, children = _a.children, withError = _a.withError, photo = _a.photo;
    var handleClick = function () {
        if (clickable && onClick) {
            return onClick();
        }
    };
    return (_jsxs("div", __assign({ className: classNames(styles.infoBox, mode && getMode(mode, styles), clickable && styles.clickable, withError && styles.withError, className), onClick: handleClick }, { children: [photo && _jsx("div", __assign({ className: styles.infoBoxPhoto }, { children: photo })), (value || label || !_.isEmpty(children)) && (_jsxs("div", { children: [_jsx("div", __assign({ className: styles.infoBoxValue }, { children: _.isEmpty(children) ? value : children })), label && _jsx("div", __assign({ className: styles.infoBoxLabel }, { children: label }))] }))] })));
};
export default PatientInfoBox;
