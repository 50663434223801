var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import classNames from 'classnames';
import Badge from '@library/ui/badge/Badge';
import './Breadcrumbs.scss';
var Breadcrumbs = function (_a) {
    var values = _a.values, className = _a.className;
    return (_jsx("div", __assign({ className: classNames('breadcrumbs', className) }, { children: !_.isEmpty(values) &&
            values.map(function (x, i) {
                return !_.isEmpty(x) && (_jsx(Badge, __assign({ type: "gray", className: "breadcrumbs__badge" }, { children: x }), "breadcrumb-badge-".concat(i)));
            }) })));
};
export default Breadcrumbs;
