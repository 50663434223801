import React from 'react'

import { action, makeObservable, observable } from 'mobx'

import { analytics } from '@common/analytics/zipAnalytics'

import { getUuid, pushOrUpdate } from '@helpers/other'

class AlertsStore {
  constructor() {
    makeObservable(this, {
      items: observable,
      clear: action,
      add: action,
      update: action,
      hide: action,
      hideAll: action,
      remove: action,
    })
  }

  items = []

  clear() {
    this.items = []
  }

  // Actions
  add(body, { title, onLeaved, ...other } = {}) {
    const newItem = {
      id: getUuid(),
      show: true,
      title: action(() => title),
      body: action(() => body),
      onLeaved: _.isFunction(onLeaved) ? action(() => onLeaved()) : onLeaved,
      ...other,
    }

    analytics.track('alert', { name: title })
    this.hideAll()
    this.items = pushOrUpdate(this.items, newItem)
  }

  update(id, payload) {
    const index = _.findIndex(this.items, (x) => x.id === id)

    if (index !== -1) {
      this.items[index] = { ...this.items[index], ...payload }
    }
  }

  hide(id) {
    this.update(id, { show: false })
  }

  hideAll() {
    if (this.items.length) {
      _.forEach(this.items, (x) => this.hide(x.id))
    }
  }

  remove(id) {
    this.items = this.items.filter((x) => x.id !== id)
  }
}

const store = new AlertsStore()
export default store
