import React from 'react'

import clientService from '@services/client'

import SelectPaymentMethodModal from '@pages/paymentMethods/SelectMethod.modal'

import { $appts, $loader } from '@store'

const BookingRepayModal = ({ _core, appointment }) => {
  const handleOnSelect = $loader.registerHandler('appt-handle-action-button-repay', async (id) => {
    const res = await clientService.changePaymentMethod({
      id: appointment.id,
      paymentMethodId: id,
    })
    if (res.prepared) {
      await $appts.getOne({ id: appointment.id })
    }
  })

  return (
    <SelectPaymentMethodModal
      _core={_core}
      onSelect={handleOnSelect}
      selected={appointment.paymentMethodId}
    />
  )
}

export default BookingRepayModal
