import * as EmailValidator from 'email-validator'
import moment from 'moment-timezone'

import { getPhone } from '@library/phoneNumber/PhoneNumber'

import { splitBloodPressure } from '@helpers/converters'
import { isBase64, saveImageToBase64 } from '@helpers/images'
import { isAbsoluteEmpty } from '@helpers/other'

import mainConfig from '@config/main'

/*
  rule(fieldValue, rule, rules)
 */
export const validationRules = {
  // basic
  func(value, rule, ...props) {
    if (_.isFunction(rule.value)) {
      return rule.value(value, rule, ...props)
    }
  },
  pattern(value, rule) {
    if (_.isRegExp(rule.value)) {
      return value.search(rule.value) !== -1
    }
  },
  required(value) {
    return !isAbsoluteEmpty(value)
  },
  forceNotRequired() {
    return true
  },
  minLen1OrEmpty(value) {
    return _.trim(value).length >= 1 || value === ''
  },
  // custom
  name(value) {
    return _.trim(value).length >= 1
  },
  dob(value, rule) {
    if (_.isEmpty(value)) {
      return false
    }

    const preset = mainConfig.ages[rule.dobType] || mainConfig.ages['default']

    const minDate = moment().subtract(Math.abs(preset.max), 'years')
    const maxDate = moment().subtract(Math.abs(preset.min), 'years')

    return moment(value).isAfter(minDate) && moment(value).isBefore(maxDate)
  },
  gender(value) {
    return ['male', 'female', 'na'].includes(value)
  },
  phone(value, rule) {
    return (!rule.required && _.isEmpty(value.number)) || value.isValid
  },
  email(value) {
    return EmailValidator.validate(value)
  },
  code(value) {
    return value.search(new RegExp(mainConfig.phone.verifyCode.pattern)) !== -1
  },
  bloodPressure(value) {
    value = splitBloodPressure(value)
    return value && value.systolic && value.diastolic
  },
  min(value, rule) {
    if (rule.strict) {
      return Number(value) > rule.compareTo
    } else {
      return Number(value) >= rule.compareTo
    }
  },
  max(value, rule) {
    if (rule.strict) {
      return Number(value) < rule.compareTo
    } else {
      return Number(value) <= rule.compareTo
    }
  },
}

export const prepValuePresets = {
  value(e) {
    return _.get(e, 'target.value', e)
  },
  valueOrEmptyString(value) {
    return !isAbsoluteEmpty(value) ? value : ''
  },
  singleSelect(value) {
    return value[0]
  },
  avatar(e) {
    let file = _.get(e, 'target.files[0]')

    if (file) {
      return saveImageToBase64(file)
    }
  },
}

export const outputPresets = {
  avatar(value) {
    if (isAbsoluteEmpty(value) || value.includes('http://') || value.includes('https://')) {
      return undefined
    }

    return isBase64(value) ? value.split(';')[1].substring(7) : value
    // return value
  },
  emptyUndefined(value) {
    return _.isEmpty(value) ? undefined : value
  },
  emptyNull(value) {
    return _.isEmpty(value) ? null : value
  },
  getPhone(value) {
    return getPhone(value)
  },
  integer(value) {
    return parseInt(value)
  },
  string(value) {
    return _.toString(value)
  },
  strict(value) {
    return isAbsoluteEmpty(value) ? value : `${value}|strict`
  },
  sort(value) {
    return JSON.parse(value)
  },
  dashedEmpty(value) {
    return isAbsoluteEmpty(value) ? '---' : value
  },
  bloodPressure(value) {
    return splitBloodPressure(value)
  },
}
