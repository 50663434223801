import React from 'react'

import { useTranslation } from 'react-i18next'

import { getDisplayOrText } from '@ps/helpers/resourceExtractor'

const RoleName = ({ role = '', resource = {} }) => {
  const { t } = useTranslation()

  let result

  if (role === 'relatedPerson') {
    result = getDisplayOrText(_.get(resource, 'relationship[0]'))
  }

  if (!result) {
    result = role ? t('ps.role.' + role) : role
  }

  if (result) {
    return <>({result})</>
  }

  return <></>
}

export default RoleName
