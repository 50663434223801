import React from 'react'

import { observer } from 'mobx-react'

import classNames from 'classnames'

import styles from '@src/layouts/sidebar/Sidebar.module.scss'
import SidebarFooter from '@src/layouts/sidebar/SidebarFooter'
import SidebarLogo from '@src/layouts/sidebar/SidebarLogo'
import SidebarMenu from '@src/layouts/sidebar/SidebarMenu'

import { $appts, $main } from '@store'

const Sidebar = () => {
  const style = {
    bottom: $appts.nearestBarIsVisible && $appts.nearestBarHeight,
  }

  function hideSidebar() {
    $main.sidebarToggle(false)
  }

  return (
    <div
      className={classNames('sidebar', styles.wrapper, 'styled_scroll', 'styled_scroll--bg-gray')}
      style={style}
    >
      <SidebarLogo onHideSidebar={hideSidebar} />
      <div className={styles.body}>
        <SidebarMenu onHideSidebar={hideSidebar} />
      </div>
      <SidebarFooter />
    </div>
  )
}

export default observer(Sidebar)
