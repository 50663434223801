import queryString from 'query-string'

import { sessionStore } from '@helpers/other'

import { parseReferenceId } from '@ps/helpers/fhir'
import psService from '@ps/services/psService'
import { getPreparedPatientId, getPreparedPracticeId } from '@ps/services/psService.helpers'

export function mediaAuthInstance({
  patientId = getPreparedPatientId(),
  practiceId = getPreparedPracticeId(),
} = {}) {
  const id = [patientId, practiceId].filter((x) => x).join('_')

  return {
    get(full = false) {
      const result = sessionStore.get(['mediaAuth', id], {})

      if (!full) {
        return result.params
      }

      return result
    },
    set(params) {
      sessionStore.set(['mediaAuth', id], {
        date: Date.now() / 1000,
        timeout: 60 * 3, // 3 minutes,
        params,
      })
    },
    async fetch(references = []) {
      if (references.length && !checkIfNeedLoad(references)) {
        return
      }

      if (checkIfValid(this.get(true))) {
        return
      }

      const res = await psService.updateMediaAuth({ patientId, practiceId })

      if (res) {
        this.set(res)
      }
    },
    getUrlWithAuth(url) {
      if (checkIfValid(this.get(true))) {
        const queryParams = this.get()
        if (_.isEmpty(queryParams)) return
        return queryString.stringifyUrl({ url, query: queryParams })
      }
      return url
    },
  }
}

function checkIfNeedLoad(list = []) {
  let isFound = false

  _.forEach(list, (x) => {
    if (['Media', 'DocumentReference'].includes(parseReferenceId(x).resource)) {
      isFound = true
      return false
    }
  })

  return isFound
}

function checkIfValid(data) {
  const isNotExpired = Date.now() / 1000 - data.date < data.timeout
  const hasParams = !_.isEmpty(data.params)

  return isNotExpired && hasParams
}
