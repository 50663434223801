var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Fragment } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
var DayPickerMonth = function (_a) {
    var currentDate = _a.currentDate, selectedDate = _a.selectedDate, setModel = _a.setModel, setView = _a.setView, minDate = _a.minDate, maxDate = _a.maxDate, availableMonths = _a.availableMonths, board = _a.board;
    var handleSelectMonth = function (value) {
        return function () {
            setModel(value);
            setView('calendar');
        };
    };
    var isSelected = function (value) {
        if (!selectedDate) {
            return false;
        }
        return value.isSame(selectedDate, 'month');
    };
    var isCurrent = function (value) {
        return value.isSame(currentDate, 'month');
    };
    var isLocked = function (value) {
        if (!_.isEmpty(availableMonths)) {
            return !(value.format('YYYY-MM') in availableMonths);
        }
        else {
            return value.isBefore(minDate, 'month') || value.isAfter(maxDate, 'month');
        }
    };
    return (_jsx("div", __assign({ className: "day-picker-month" }, { children: _jsx("div", __assign({ className: "body" }, { children: board.map(function (cells, rowIndex) { return (_jsx("div", __assign({ className: "row-month" }, { children: cells.map(function (_a, i) {
                    var text = _a.text, value = _a.value;
                    return (_jsxs(Fragment, { children: [!isLocked(value) && (_jsx("div", __assign({ className: classNames('cell', {
                                    'cell-selected': isSelected(value),
                                    'cell-current': isCurrent(value),
                                }), onClick: handleSelectMonth(value) }, { children: text }))), isLocked(value) && (_jsx("div", __assign({ className: classNames('cell', {
                                    'cell-selected': isSelected(value),
                                    'cell-current': isCurrent(value),
                                    'cell-locked': true,
                                }) }, { children: text })))] }, value.toString() + i));
                }) }), rowIndex)); }) })) })));
};
export default DayPickerMonth;
