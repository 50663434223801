import React, { useEffect, useRef } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import Button from '@library/ui/button/Button'

import styles from './TabsBarMobile.module.scss'

const TabsBarMobile = ({ items, selected, selectAll, onChange, autoTarget, moreModalParams }) => {
  const { t } = useTranslation()
  const wrapperRef = useRef()

  useEffect(() => {
    if (!wrapperRef.current) return

    const button = wrapperRef.current.querySelector('.btn-primary')
    if (button) {
      button.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      })
    }
  }, [selected])

  return (
    <div ref={wrapperRef} className={styles.wrapper}>
      <Button
        mode={['secondary-dark', 'block', 'sm']}
        className={classNames(styles.btn, styles.search)}
        action={moreModalParams}
      />
      {selectAll && (
        <Button
          mode={[selected ? 'secondary-dark' : 'primary', 'block']}
          className={styles.btn}
          action={() => onChange && onChange(null)}
          label={selectAll}
        />
      )}
      {items.map((x, index) => (
        <Button
          key={index}
          mode={[selected === x.id ? 'primary' : 'secondary-dark', 'block']}
          className={styles.btn}
          action={() => onChange && onChange(x.id)}
          autoTarget={autoTarget && autoTarget(x.id)}
          label={x.text}
        />
      ))}
    </div>
  )
}

export default observer(TabsBarMobile)
