import Appointments from '@ps/assets/svg/appointments'
import DocumentsXs from '@ps/assets/svg/documentsXs'
import Labs from '@ps/assets/svg/labs'
import MedicalHistory from '@ps/assets/svg/medicalHistory'
import Medications from '@ps/assets/svg/medications'
import { appointmentConfig } from '@ps/config/events/appointments'
import {
  documentFormTypes,
  documentPersonalTypes,
  documentsConfig,
} from '@ps/config/events/documents'
import { labsConfig } from '@ps/config/events/labs'
import { medicalHistoryConfig } from '@ps/config/events/medicalHistory'
import { medicationsConfig } from '@ps/config/events/medications'
import { otherConfig } from '@ps/config/events/other'
import ApptTabDocuments from '@ps/library/modals/appointmentEvent/tabs/ApptTabDocuments'
import ApptTabLabs from '@ps/library/modals/appointmentEvent/tabs/ApptTabLabs'
import ApptTabMedicalHistory from '@ps/library/modals/appointmentEvent/tabs/ApptTabMedicalHistory'
import ApptTabMedications from '@ps/library/modals/appointmentEvent/tabs/ApptTabMedications'
import ApptTabNotesList from '@ps/library/modals/appointmentEvent/tabs/ApptTabNotesList'
import TabAppointments from '@ps/pages/patient/tabs/TabAppointments'
import TabDocuments from '@ps/pages/patient/tabs/TabDocuments'
import TabLabs from '@ps/pages/patient/tabs/TabLabs'
import TabMedicalHistory from '@ps/pages/patient/tabs/TabMedicalHistory'
import TabMedications from '@ps/pages/patient/tabs/TabMedications'

const baseEventsSchema = {
  medical_history: {
    all: [
      'medical_history_observation',
      'medical_history_condition',
      'medical_history_allergy',
      'medical_history_immunization',
      'medical_history_vitals',
      'medical_history_assessment',
      'medical_history_procedure',
      'medical_history_careplan',
      'medical_history_examination',
      'medical_history_hpi',
      'medical_history_service_request',
    ],
    notes: [
      'medical_history_assessment',
      'medical_history_careplan',
      'medical_history_examination',
      'medical_history_hpi',
      //
    ],
    appt_all() {
      return this.all.filter((x) => !this.notes.includes(x))
    },
    immunizations: [
      'medical_history_immunization',
      //
    ],
    conditions: [
      'medical_history_condition',
      //
    ],
    procedures: [
      'medical_history_procedure',
      //
    ],
    allergies: [
      'medical_history_allergy',
      //
    ],
    orders: [
      'medical_history_service_request',
      //
    ],
  },
  appointments: {
    all: [
      'appointment_main',
      //
    ],
  },
  labs: {
    all: [
      // 'lab_diagnostic_report',
      'lab_document',
      //
    ],
  },
  medications: {
    all: [
      'medication_statement',
      // 'medication_request',
      //
    ],
  },
  documents: {
    all: [
      ...Object.keys(documentPersonalTypes),
      ...Object.keys(documentFormTypes),
      'document_media',
      'document_other',
      //
    ],
    personal: [
      ...Object.keys(documentPersonalTypes),
      //
    ],
    non_personal: [
      'document_media',
      'document_other',
      //
    ],
    only_forms: [
      ...Object.keys(documentFormTypes),
      //
    ],
    only_documents() {
      return this.all.filter((x) => !this.only_media.includes(x) && !this.only_forms.includes(x))
    },
    only_media: [
      'document_media',
      //
    ],
  },
}

export const eventsSchema = mapEventsSchema({
  medical_history: {
    order: 0,
    component: TabMedicalHistory,
    color: '#3ce2b2',
    icon: MedicalHistory,
    subcategories: {
      medical_history_all: {
        isDefault: true,
        order: 0,
        items: baseEventsSchema.medical_history.all,
      },
      medical_history_notes: {
        order: 1,
        items: baseEventsSchema.medical_history.notes,
      },
      medical_history_conditions: {
        order: 2,
        items: baseEventsSchema.medical_history.conditions,
      },
      medical_history_immunizations: {
        order: 3,
        items: baseEventsSchema.medical_history.immunizations,
      },
      medical_history_procedures: {
        order: 4,
        items: baseEventsSchema.medical_history.procedures,
      },
      medical_history_allergies: {
        order: 5,
        items: baseEventsSchema.medical_history.allergies,
      },
      medical_history_orders: {
        order: 6,
        items: baseEventsSchema.medical_history.orders,
      },
    },
  },
  appointments: {
    order: 1,
    component: TabAppointments,
    color: '#d88dda',
    icon: Appointments,
    subcategories: {
      appointments_all: {
        isDefault: true,
        order: 0,
        items: baseEventsSchema.appointments.all,
      },
    },
  },
  labs: {
    order: 2,
    component: TabLabs,
    color: '#f1c6b5',
    icon: Labs,
    subcategories: {
      labs_all: {
        isDefault: true,
        order: 0,
        items: baseEventsSchema.labs.all,
      },
    },
  },
  medications: {
    order: 3,
    component: TabMedications,
    color: '#35d3fe',
    icon: Medications,
    subcategories: {
      medications_all: {
        isDefault: true,
        order: 0,
        items: baseEventsSchema.medications.all,
      },
    },
  },
  documents: {
    order: 4,
    component: TabDocuments,
    color: '#7593f6',
    icon: DocumentsXs,
    subcategories: {
      documents_all: {
        order: 0,
        items: baseEventsSchema.documents.all,
      },
      documents_personal: {
        order: 1,
        items: baseEventsSchema.documents.personal,
      },
      documents_non_personal: {
        isDefault: true,
        order: 2,
        items: baseEventsSchema.documents.non_personal,
      },
      documents_forms: {
        order: 3,
        items: baseEventsSchema.documents.only_forms,
      },
    },
  },
})

export const apptEventsSchema = mapEventsSchema({
  notes: {
    order: 0,
    component: ApptTabNotesList,
    subcategories: {
      notes_all: {
        isDefault: true,
        order: 0,
        viewType: 'table',
        items: baseEventsSchema.medical_history.notes,
      },
    },
  },
  medical_history: {
    order: 1,
    component: ApptTabMedicalHistory,
    subcategories: {
      medical_history_all: {
        isDefault: true,
        order: 0,
        viewType: 'table',
        items: baseEventsSchema.medical_history.appt_all(),
      },
      medical_history_conditions: {
        order: 1,
        viewType: 'table',
        items: baseEventsSchema.medical_history.conditions,
      },
      // medical_history_immunizations: {
      //   order: 2,
      //   viewType: 'table',
      //   items: baseEventsSchema.medical_history.immunizations,
      // },
      medical_history_procedures: {
        order: 4,
        viewType: 'table',
        items: baseEventsSchema.medical_history.procedures,
      },
      medical_history_allergies: {
        order: 5,
        viewType: 'table',
        items: baseEventsSchema.medical_history.allergies,
      },
      medical_history_orders: {
        order: 6,
        viewType: 'table',
        items: baseEventsSchema.medical_history.orders,
      },
    },
  },
  labs: {
    order: 2,
    component: ApptTabLabs,
    subcategories: {
      labs_all: {
        isDefault: true,
        order: 0,
        viewType: 'table',
        items: baseEventsSchema.labs.all,
      },
    },
  },
  medications: {
    order: 3,
    component: ApptTabMedications,
    subcategories: {
      medications_all: {
        isDefault: true,
        order: 0,
        viewType: 'table',
        items: baseEventsSchema.medications.all,
      },
    },
  },
  documents: {
    order: 4,
    component: ApptTabDocuments,
    subcategories: {
      documents_only_documents: {
        order: 2,
        viewType: 'table',
        items: baseEventsSchema.documents.only_documents(),
      },
      documents_only_media: {
        isDefault: true,
        order: 1,
        viewType: 'gallery',
        items: baseEventsSchema.documents.only_media,
      },
      documents_forms: {
        order: 3,
        viewType: 'table',
        items: baseEventsSchema.documents.only_forms,
      },
    },
  },
})

function mapEventsSchema(schema) {
  return _.mapValues(schema, (category, id) => {
    category.id = id
    category.label = 'ps.categories.' + id

    category.subcategories = _.mapValues(category.subcategories, (x, subId) => {
      x.id = subId
      x.label = 'ps.categories.' + subId
      x.category = id
      return x
    })

    return category
  })
}

export const eventsConfig = _.mapValues(
  {
    ...medicalHistoryConfig,
    ...appointmentConfig,
    ...labsConfig,
    ...medicationsConfig,
    ...documentsConfig,
    ...otherConfig,
  },
  (x, key) => {
    x.configId = key
    x.label = 'ps.event_label.' + key

    return x
  },
)

export const historyPageLimit = 15
