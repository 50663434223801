import React from 'react'

import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import ShowMoreText from '@library/showMoreText/ShowMoreText'

import { formatDate } from '@helpers/time'

import { openEventModal } from '@ps/helpers/events'
import { resourceExtractor } from '@ps/helpers/resourceExtractor'
import EventsViewer from '@ps/library/eventsViewer/EventsViewer'

const AppointmentsTablePage = ({ pageItems }) => {
  const { t } = useTranslation()

  return (
    <>
      {pageItems.map(({ event, resource }) => {
        const extractor = resourceExtractor(event, resource)

        return (
          <div
            key={event.id}
            className={classNames('h_table-tr', 'h_table-tr--' + event.rowType)}
            onClick={() => openEventModal([{ event, resource }])}
          >
            <div className="h_table-td h_table-td--delimiter" />
            <div className="h_table-td h_table-td--label">{t(event.label)}</div>
            <div className="h_table-td h_table-td--details">
              <div className="font-weight-600">
                <ShowMoreText buttonClassName="mt-5">
                  <EventsViewer
                    type="tableDetails"
                    event={event}
                    resource={resource}
                    extractor={extractor}
                  />
                </ShowMoreText>
              </div>
            </div>
            <div className="h_table-td h_table-td--code">{extractor.combinedStatus}</div>
            <div className="h_table-td h_table-td--date">
              <span className="event_date">
                {formatDate(event.effectiveDate, { format: 'date' })}
              </span>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default AppointmentsTablePage
