import i18n from 'i18next'

import { apptStatusRules } from '@config/appts'

export const AUTO_UPDATE_BY_ROUTE = 10000
export const TIME_FOR_ONSITE_REBOOK = 2 * 60 // minutes
export const TIME_FOR_VIRTUAL_REBOOK = 60 // minutes
export const TIME_FOR_CONFIRMATION = 2 * 60 // minutes
export const VISIBLE_BUTTONS_IN_PANEL = 2

export const apptTypes = {
  getType: (service) => {
    const isOnSite = service.agentLoad === 'onsite'
    const isFull = service.agentLoad && service.providerLoad
    const isOnlyProvider = service.providerLoad && !service.agentLoad

    return `${isOnSite ? 'onsite' : 'virtual'}_${
      isFull ? 'full' : isOnlyProvider ? 'provider' : 'agent'
    }`
  },
  getBasicTypes: (service) => {
    const { agentLoad, providerLoad, reasons } = service

    const additionalParams = {
      isVirtual: false,
      isAtHome: false,
      isAtClinic: false,
    }

    if (reasons.includes('clinic')) {
      additionalParams.isAtClinic = true
    } else {
      if (_.includes([null, 'virtual'], agentLoad) && _.includes([null, 'virtual'], providerLoad)) {
        additionalParams.isVirtual = true
      }
      if (agentLoad === 'onsite' && _.includes([null, 'virtual'], providerLoad)) {
        additionalParams.isAtHome = true
      }
    }

    return additionalParams
  },
  onSite: ['onsite_full', 'onsite_agent'],
  isOnSite(service) {
    const type = this.getType(service)
    return this.onSite.includes(type)
  },
  virtual: ['virtual_full', 'virtual_provider'],
  isVirtual(service) {
    const type = this.getType(service)
    return this.virtual.includes(type)
  },
  //
  withAgent: ['onsite_full', 'onsite_agent', 'virtual_full'],
  isWithAgent(service) {
    const type = this.getType(service)
    return this.withAgent.includes(type)
  },
  withProvider: ['onsite_full', 'virtual_provider', 'virtual_full'],
  isWithProvider(service) {
    const type = this.getType(service)
    return this.withProvider.includes(type)
  },
  //
  startAgent: ['onsite_full', 'onsite_agent', 'virtual_full'],
  isStartAgent(service) {
    const type = this.getType(service)
    return this.startAgent.includes(type)
  },
  startProvider: ['virtual_provider'],
  isStartProvider(service) {
    const type = this.getType(service)
    return this.startProvider.includes(type)
  },
  //
  full: ['onsite_full', 'virtual_full'],
  isFull(service) {
    const type = this.getType(service)
    return this.full.includes(type)
  },
  onsiteAgent: ['onsite_agent'],
  isOnsiteAgent(service) {
    const type = this.getType(service)
    return this.onsiteAgent.includes(type)
  },
  virtualProvider: ['virtual_provider'],
  isVirtualProvider(service) {
    const type = this.getType(service)
    return this.virtualProvider.includes(type)
  },
}

export const apptStatuses = {
  list: ['booked', 'unbooked', 'started', 'finished', 'complete', 'canceled', 'exception'],
  //
  active: ['booked', 'unbooked', 'started', 'finished'],
  isActive(status) {
    return this.active.includes(status)
  },
  actual: ['booked', 'started', 'finished', 'complete', 'exception'],
  isActual(status) {
    return this.actual.includes(status)
  },
  past: ['complete', 'canceled', 'exception'],
  isPast(status) {
    return this.past.includes(status)
  },
  done: ['finished', 'complete', 'canceled', 'exception'],
  isDone(status) {
    return this.done.includes(status)
  },
  success: ['finished', 'complete'],
  isSuccess(status) {
    return this.success.includes(status)
  },
  progress: ['started', 'finished'],
  isProgress(status) {
    return this.progress.includes(status)
  },
}

export const availableActions = {
  viewForms: false,
  fillForms: false,
  fillIntake: false,

  callOffice: false,
  callEmergency: false,

  textGeneral: false,
  textStaff: false,

  viewTextGeneral: false,
  viewTextStaff: false,

  startVideo: false,

  // provider && agent
  accept: false,
  confirm: false,
  reject: false,
  shift: false,
  start: false,
  finish: false,
  pureFinish: true,

  // only provider
  note: false,
  notes: false,

  // only agent
  way: false,
  delay: false,

  // only client
  rebook: false,
  selfRebook: false,
  repay: false,
  complete: false,
  cancel: false,
  survey: false,
  feedback: false,
}

export function getLastEvents(needle, events) {
  const sortedEvents = _.orderBy(events, ['id'], ['desc']) // maybe sorting is not necessary

  let tmp = _.cloneDeep(needle)
  let result = []

  _.forEach(sortedEvents, (item) => {
    const index = tmp.indexOf(item.event)

    if (index !== -1) {
      result.push(item)
      tmp.splice(index, 1)
    }
  })

  return result
}

// isDefault - display in future steps in timeline
// isCurrent - if is current or was current
// isFinale - guaranteed last active step in timeline
export function getAllStatuses(fullAppt) {
  const steps = apptStatusRules(fullAppt)

  let result = steps.map(() => [])
  let current = false
  let final = false

  _.forEach(steps, (group, index) => {
    let rule

    _.forEach(group, (item) => {
      if (!final && item.isCurrent()) {
        current = item.id
        rule = item

        if (_.isFunction(item.isFinal)) {
          final = true
        }
      } else if (!rule && _.isFunction(item.isDefault) && item.isDefault()) {
        rule = item
      }
    })

    result[index] = _.pickBy(rule, (x) => !_.isFunction(x))
  })

  let isFound = false

  result = result.filter((x) => !_.isEmpty(x))
  result = result.map((item) => {
    let status = i18n.t(`appt_statuses.${item.id}.status`)

    let extend = {
      previous: false,
      current: false,
      texts: { status, ...item.texts },
      payAttention: [],
    }

    if (current) {
      if (item.id === current) {
        extend.current = true
        isFound = true

        _.forEach(item.requiredActions, (x, key) => {
          if (item.actions[key] && x) {
            extend.payAttention.push(key)
          }
        })
      } else if (!isFound) {
        extend.previous = true
      }
    }

    return { ...item, ...extend }
  })

  // console.log(
  //   fullAppt.appointment.id,
  //   fullAppt.appointment.type,
  // );
  // result.map(x => console.log(x.previous, x.current, x));
  // console.log('//////////////////');

  return result
}
