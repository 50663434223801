import React, { Fragment, useMemo } from 'react'

import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'

import classNames from 'classnames'

import DropDown from '@library/dropdown/DropDown'
import Button from '@library/ui/button/Button'

import { $appts, $loader } from '@store'

import { apptMapActions } from '@config/appts'
import { VISIBLE_BUTTONS_IN_PANEL } from '@config/apptsCore'

const ActionButtons = ({ byRoute }) => {
  const { fullAppt, status, actions, office } = byRoute || $appts.byRoute

  const preparedList = useMemo(() => {
    let inPanel = [],
      inDropdown = []

    let mapActions = apptMapActions({ fullAppt, status, actions })
    mapActions = _.orderBy(mapActions, ['visible'], ['desc'])

    _.forEach(mapActions, (action) => {
      let hidden = false

      if (action.visible < 0) {
        inDropdown.push(action)
        hidden = true
      } else if (action.visible) {
        if (inPanel.length < VISIBLE_BUTTONS_IN_PANEL) {
          inPanel.push(action)
        } else {
          inDropdown.push(action)
          hidden = true
        }
      }

      if (hidden) {
        inPanel.push({ ...action, hidden: true })
      }
    })

    return { inPanel, inDropdown }
  }, [fullAppt, status, actions])

  const handleAction = async (item) => {
    const fn = $loader.registerHandler('appt-handle-action-button-' + item.id, async () => {
      if (!_.isEmpty(item.actionProp)) {
        await item.action(_.get(fullAppt, item.actionProp))
      } else {
        await item.action()
      }
    })
    await fn()
  }

  function getClass(item) {
    return classNames(
      item.primary ? 'btn-primary' : 'btn-secondary',
      item.disabled && 'disabled',
      item.marker && 'red_marker',
      item.hidden && 'btn--hidden',
    )
  }

  return (
    <div className="appt_control-buttons btn-group">
      {preparedList.inPanel.map((item) => (
        <Fragment key={item.id}>
          {item.type === 'link' ? (
            <Link to={item.action} className={getClass(item)}>
              {item.label}
            </Link>
          ) : (
            <Button
              className={getClass(item)}
              isLoading={$loader.isRunHandler('appt-handle-action-button-' + item.id)}
              action={() => handleAction(item)}
            >
              {item.label}
            </Button>
          )}
        </Fragment>
      ))}

      {preparedList.inDropdown.length > 0 && (
        <DropDown
          renderButton={(ref) => (
            <div ref={ref} className="btn btn-xs-lr btn-secondary btn-icon btn-icon--more" />
          )}
          items={preparedList.inDropdown}
          align={'right'}
        />
      )}
    </div>
  )
}

export default observer(ActionButtons)
