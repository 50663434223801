import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import { Chart } from 'react-chartjs-2'
import { useTranslation } from 'react-i18next'

import ChartJS from 'chart.js/auto'
import 'chartjs-adapter-moment'
import zoomPlugin from 'chartjs-plugin-zoom'

import MultiSelect from '@library/selectDropdown/MultiSelect'

import { detectMouseWheelDirection } from '@helpers/other'
import { splitDate } from '@helpers/time'

import { eventsSchema } from '@ps/config/events'
import { getEventsMinMaxDate, getSubcategoriesByEventConfigId } from '@ps/helpers/events'

import { $main, $psHistory } from '@store'

import styles from './TimelineChart.module.scss'

ChartJS.register(zoomPlugin)

const barOptions = {
  barPercentage: 1,
  maxBarThickness: 30,
}

const TimelineChart = () => {
  const { t } = useTranslation()

  const [initialDays, setInitialDays] = useState(90)
  const [historyType, setHistoryType] = useState([''])
  const chartRef = useRef(null)
  const allTime = 365 * 100

  function groupEventsByDay(events = []) {
    let result = {}

    _.forEach(events, (event) => {
      const subcategories = _.uniqBy(
        getSubcategoriesByEventConfigId(event.configId, eventsSchema),
        'category',
      )

      if (!_.isEmpty(subcategories)) {
        let date = splitDate(event.effectiveDate)

        _.forEach(subcategories, (subcategory) => {
          result[date] = result[date] || {}
          result[date][subcategory.category] = result[date][subcategory.category] || []

          result[date][subcategory.category].push(event)
        })
      }
    })

    return result
  }

  const pointData = useMemo(() => {
    let result = {}

    const eventsByDay = groupEventsByDay($psHistory.events)

    _.forEach(eventsByDay, (group, date) => {
      _.forEach(group, (events, categoryId) => {
        result[categoryId] = result[categoryId] || []
        result[categoryId].push({ x: date, y: events.length })
      })
    })

    return result
  }, [$psHistory.events])

  const data = useMemo(() => {
    let datasets = []

    _.forEach(_.orderBy(eventsSchema, 'order', 'asc'), (category) => {
      if (pointData[category.id]) {
        datasets.push({
          label: t(category.label),
          data: pointData[category.id] || [],
          backgroundColor: category.color,
          ...barOptions,
        })
      }
    })
    if (historyType && historyType.length === 0) setHistoryType([datasets.length])

    return { datasets }
  }, [pointData])

  const filteredData = useMemo(() => {
    let datasets = _.cloneDeep(data.datasets)

    if (historyType.length) {
      if (historyType[0] !== '') {
        let filteredDatasets = []
        for (let i = 0; i < datasets.length; i++) {
          if (historyType.includes(i)) filteredDatasets.push(datasets[i])
        }
        datasets = filteredDatasets
      }
    }

    if (initialDays !== allTime) {
      let minDate = Date.now()
      minDate = minDate - initialDays * 86400000
      for (let dataset of datasets) {
        dataset.data = dataset.data.filter((el) => Date.parse(el.x) > minDate)
      }
    }

    return { datasets }
  }, [data, initialDays, historyType])

  const { minDate, maxDate } = useMemo(() => {
    let { minDate: min, maxDate: max } = getEventsMinMaxDate($psHistory.events)

    if (!max) max = Date.now()
    if (!min) min = max - Array.isArray(initialDays) ? initialDays[0] : initialDays * 86400000

    min = _.isNumber(min) ? min : Date.parse(min)
    max = _.isNumber(max) ? max : Date.parse(max)

    const days = Math.floor((max - min) / 86400000)
    const delta = Math.max(Array.isArray(initialDays) ? initialDays[0] : initialDays - days, 0)

    return {
      minDate: min - (delta / 2) * 86400000,
      maxDate: max + (delta / 2) * 86400000,
    }
  }, [$psHistory.events, initialDays])

  // const setZoom = useCallback(() => {
  //   const data = { min: maxDate - INITIAL_DAYS * 86400000, max: maxDate };
  //   chartRef.current.zoomScale('x', data);
  // }, [maxDate]);
  //
  // const toStart = useCallback(() => chartRef.current.pan(minDate), [minDate]);
  // const toEnd = useCallback(() => chartRef.current.pan(-maxDate), [maxDate]);

  // useEffect(() => {
  //   setTimeout(() => setZoom() && toEnd(),0);
  // }, [setZoom, toEnd]); // eslint-disable-line react-hooks/exhaustive-deps

  const options = useMemo(() => {
    return {
      plugins: {
        legend: {
          align: 'end',
          labels: {
            usePointStyle: true,
            boxWidth: 5,
          },
        },
        tooltip: {
          enabled: false,
        },
        zoom: {
          pan: {
            // enabled: true,
            mode: 'x',
            modifierKey: 'ctrl',
          },
          zoom: {
            wheel: {
              //   enabled: true,
              speed: 0.4,
              modifierKey: 'ctrl',
            },
            pinch: {
              // enabled: true
            },
            drag: {
              //   enabled: true,
              //   borderWidth: 1,
              //   backgroundColor: 'rgba(24, 162, 190, 0.35)',
              //   borderColor: 'rgba(24, 162, 190, 0.35)',
            },
            mode: 'x',
            onZoomStart({ chart, event, point }) {
              const direction = detectMouseWheelDirection(event)
              const x = chart.scales.x

              if (!$main.isMobile && direction === 'up' && x.ticks.length <= 6) {
                // not working in mobile
                event.preventDefault()
                return false
              }
            },
          },
          limits: {
            x: {
              min: minDate,
              max: maxDate,
            },
          },
        },
      },
      scales: {
        x: {
          type: 'time',
          ticks: {
            autoSkip: true,
            autoSkipPadding: 20,
            maxRotation: 0,
            major: { enabled: true },
            font(ctx) {
              if (ctx.tick && ctx.tick.major) return { weight: '600' }
            },
          },
          time: {
            minUnit: 'day',
            // round: true,
            displayFormats: {
              month: 'MM/YYYY',
            },
          },
          grid: {
            display: false,
          },
          stacked: true,
        },
        y: {
          suggestedMin: 4,
          title: {
            display: true,
            text: 'Amount',
            font: { size: 14 },
            padding: { top: 0, left: 0, right: 0, bottom: 10 },
          },
          ticks: {
            precision: 0,
          },
          grid: {
            drawBorder: false,
          },
          alignToPixels: true,
          stacked: true,
        },
      },
    }
  }, [minDate, maxDate]) //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="row justify-content-between">
        <div className="col-12 col-sm-6 mb-10">
          <div className={styles.formGroup}>
            <MultiSelect
              field={{
                value: initialDays,
                onChange: setInitialDays,
              }}
              items={[
                { id: 30, text: t('label.months', { count: 1 }) },
                { id: 90, text: t('label.months', { count: 3 }) },
                { id: 180, text: t('label.months', { count: 6 }) },
                { id: 365, text: t('label.years', { count: 1 }) },
                { id: 365 * 2, text: t('label.years', { count: 2 }) },
                { id: 365 * 5, text: t('label.years', { count: 5 }) },
                { id: allTime, text: t('label.allTime') },
              ]}
            />
          </div>
        </div>
        <div className="col-12 col-sm-6 mb-10">
          <div className={`${styles.formGroup} ${styles.toRight}`}>
            {data ? (
              <MultiSelect
                field={{
                  value: historyType,
                  onChange: setHistoryType,
                }}
                items={[...data.datasets.map((el, id) => ({ id, text: el.label }))]}
                multiSelect={true}
                allowUnselect
                allOption={t('label.allTypes')}
              />
            ) : null}
          </div>
        </div>
      </div>
      {filteredData ? (
        <div className={styles.timelineChart}>
          <Chart type="bar" ref={chartRef} data={filteredData} options={options} />
        </div>
      ) : null}
    </>
  )
}

export default observer(TimelineChart)
