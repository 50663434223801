import React, { useMemo, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import { useForm } from '@library/form/FormControllerNew'
import PageNoResults from '@library/pageNoResults/PageNoResults'
import RadioTabs from '@library/radioTabs/RadioTabs'
import MultiSelect from '@library/selectDropdown/MultiSelect'

import { apptEventsSchema } from '@ps/config/events'
import { sortEventsBy } from '@ps/helpers/events'
import '@ps/library/historyTable/HistoryTable.scss'
import MedicalHistoryTablePage from '@ps/pages/patient/tabs/medicalHistory/MedicalHistoryTablePage'

import { $psResources } from '@store'

const ApptTabMedicalHistory = ({ category, eventsBySubcategory }) => {
  const { t } = useTranslation()

  const categoryConfig = apptEventsSchema[category] || {}
  const [subcategory, setSubcategory] = useState(
    (_.find(categoryConfig.subcategories, (x) => x.isDefault) || {}).id,
  )
  const events = eventsBySubcategory[subcategory] || []
  const viewType = categoryConfig.subcategories[subcategory].viewType

  const { form } = useForm({
    sortBy: { value: 'date_desc', prepValue: 'singleSelect' },
  })
  const { sortBy } = form.fields

  const items = useMemo(() => {
    let result = []

    _.forEach(sortEventsBy(events, sortBy.value), (x) => {
      result.push({ event: x, resource: $psResources.getById(x.id) })
    })

    return result
  }, [events, sortBy.value]) // eslint-disable-line react-hooks/exhaustive-deps

  const subcategoryTabItems = useMemo(() => {
    let result = []
    const list = categoryConfig.subcategories

    _.forEach(_.orderBy(list, 'order', 'asc'), (x) => {
      result.push({
        id: x.id,
        text: t(x.label),
        count: _.get(eventsBySubcategory, [x.id], []).length,
      })
    })

    return result
  }, [eventsBySubcategory, category]) //eslint-disable-line react-hooks/exhaustive-deps

  const sortByItems = useMemo(() => {
    return [
      { id: 'date_desc', text: t('ps.label.sortBy.date_desc') },
      { id: 'date_asc', text: t('ps.label.sortBy.date_asc') },
    ]
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="row_wrapper mb-20">
        <div className="row row--mb-10">
          <div className="col-12 col-lg-8">
            <RadioTabs
              value={subcategory}
              setValue={(x) => setSubcategory(x)}
              items={subcategoryTabItems}
              mode="light"
            />
          </div>
          <div className="col-12 col-lg-4">
            <MultiSelect items={sortByItems} field={sortBy} mode={['sm', 'fw-400']} />
          </div>
        </div>
      </div>
      {items.length > 0 ? (
        <div className="row_wrapper">
          <div className={classNames('h_table', category && 'h_table--' + category)}>
            <MedicalHistoryTablePage pageItems={items} showRelatedAppt={false} />
          </div>
        </div>
      ) : (
        <PageNoResults>{t('errors.no_results')}</PageNoResults>
      )}
    </>
  )
}

export default observer(ApptTabMedicalHistory)
