import React from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import PageNoResults from '@library/pageNoResults/PageNoResults'

import NotesBar from '@pages/appt/apptNotes/notesBar/NotesBar'

import { getLinkByPractice } from '@helpers/byPractice'

import { $main } from '@store'

import styles from './ApptNotes.module.scss'

const ApptNotes = ({ byRoute, noResultsLabel = false, className }) => {
  const { t } = useTranslation()
  const { encounterId } = useParams()
  const { fullAppt, actions } = byRoute
  const { appointment, encounters, patients } = fullAppt
  const activeEncounter = encounters?.[encounterId]

  const patient = patients[activeEncounter.patientId]

  const mdToolbox = getLinkByPractice('mdToolbox', appointment.practiceId)
  const parachuteHealth = getLinkByPractice('parachuteHealth', appointment.practiceId)

  return (
    <>
      {actions.notes && activeEncounter ? (
        <div id="notes_wrapper" className={className}>
          <div className="d-flex align-items-center justify-content-between">
            <div className="title-sm m-0">{t('ps.label.summary_notes')}</div>
            <div className={styles.linksWrapper}>
              <a href={mdToolbox.link} target="_blank" rel="noreferrer" className="box-header-link">
                {t('ps.label.md_toolbox')}
              </a>
              {parachuteHealth ? (
                <a
                  href={parachuteHealth.link}
                  target="_blank"
                  rel="noreferrer"
                  className="box-header-link"
                >
                  {t('ps.label.parachute_health')}
                </a>
              ) : null}
            </div>
          </div>
          <NotesBar
            patient={patient}
            appointment={appointment}
            encounter={activeEncounter}
            sidebarDisabled={
              $main.rightSidebar.active === 'notes' &&
              $main.rightSidebar?.payload?.notes?.encounterId === +activeEncounter.id
            }
          />
        </div>
      ) : (
        noResultsLabel && <PageNoResults>{t('appt_notes.label.not_available')}</PageNoResults>
      )}
    </>
  )
}

export default observer(ApptNotes)
