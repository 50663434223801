import React, { useEffect, useMemo } from 'react'

import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import { useInView } from 'react-intersection-observer'

import { useLoader } from '@library/CustomHooks'
import ProgressRing from '@library/ui/progressRing/ProgressRing'

import { $psResources } from '@store'

const HistoryTablePage = ({
  allPages,
  pageIndex,
  page,
  increasePage,
  pagesInLoading,
  setPagesInLoading,
  tableItems,
  pageLimit,
  pageComponent: PageComponent,
}) => {
  const events = useMemo(() => {
    const pos = pageIndex * pageLimit
    return _.cloneDeep(tableItems.slice(pos, pos + pageLimit))
  }, [tableItems, pageIndex, pageLimit])

  const isLoading = useLoader(async () => {
    if (!_.includes(pagesInLoading, pageIndex)) {
      setPagesInLoading((x) => [...x, pageIndex])
    }

    await $psResources.getResources({ items: events })

    setPagesInLoading((prev) => _.filter(prev, (x) => x !== pageIndex))
  })

  const pageItems = useMemo(() => {
    let result = []

    if (isLoading) {
      return result
    }

    _.forEach(events, (x) => {
      result.push({ event: x, resource: $psResources.getById(x.id) })
    })

    return result
  }, [isLoading, events]) // eslint-disable-line react-hooks/exhaustive-deps

  const { ref, inView } = useInView({ triggerOnce: true })

  useEffect(() => {
    const isCurrent = page === pageIndex + 1
    const isMostLast = page < allPages

    if (!isLoading && isCurrent && isMostLast) {
      increasePage()
    }
  }, [inView]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {!isLoading ? (
        <PageComponent pageItems={pageItems} />
      ) : (
        (pagesInLoading.length === 1 || pagesInLoading[0] === pageIndex) && (
          <div className="h_table-tr h_table-tr--loader">
            <div className="h_table-td">
              <ProgressRing progress={25} spin={true} />
            </div>
          </div>
        )
      )}
      {!isLoading && !inView && <div ref={ref} />}
    </>
  )
}

export default observer(HistoryTablePage)
