import axios from 'axios'
import queryString from 'query-string'

import apiService from '@services/api'

import { isAbsoluteEmpty } from '@helpers/other'

import { mediaAuthInstance } from '@ps/helpers/mediaAuth'
import { getPreparedPatientId, getPreparedPracticeId } from '@ps/services/psService.helpers'

import { $auth } from '@store'

import mainConfig from '@config/main'

class psService {
  #clearPayload(data, excludedKeys = []) {
    return _.pickBy(
      data,
      (x, key) => _.includes(excludedKeys, key) || !isAbsoluteEmpty(x) || x === false,
    )
  }

  async getPractices(props = {}) {
    let params = {
      patientId: props.patientId || getPreparedPatientId(),
      practiceId: props.practiceId || getPreparedPracticeId(),
    }

    return await apiService.fetchLoggedV3(
      'patient_stories.view_practices',
      this.#clearPayload(params),
      {
        skipAlert: true,
      },
    )
  }

  async getPatients(props) {
    const params = {
      practiceId: getPreparedPracticeId(),
      ...props,
    }

    return await apiService.fetchLoggedV3(
      'patient_stories.search_patients',
      this.#clearPayload(params),
      {
        skipAlert: true,
      },
    )
  }

  async typeaheadSearch({ text, limit = 10 }) {
    const params = {
      practiceId: getPreparedPracticeId(),
      text: text.slice(0, 150),
      limit,
    }

    const response = await apiService.fetchLoggedV3(
      'patient_stories.typeahead_search',
      this.#clearPayload(params),
      {
        skipAlert: true,
      },
    )

    let result = _.get(response, 'prepared', {})
    result = _.map(result, (value, groupName) => {
      let items = []

      _.forEach(value, (x) => {
        let data = { ...x, groupName }

        if (!data.hasOwnProperty('id')) {
          data.id = groupName + data.text
        }

        items.push(data)
      })

      return { name: groupName, items }
    })

    return result
  }

  async getPatientDemographics() {
    let params = {
      patientId: getPreparedPatientId(),
      practiceId: getPreparedPracticeId(),
    }

    return await apiService.fetchLoggedV3(
      'patient_stories.get_demographics',
      this.#clearPayload(params),
      {
        skipAlert: true,
      },
    )
  }

  async getPatientVitalSigns() {
    let params = {
      patientId: getPreparedPatientId(),
      practiceId: getPreparedPracticeId(),
    }

    return await apiService.fetchLoggedV3(
      'patient_stories.get_vital_signs',
      this.#clearPayload(params),
      {
        skipAlert: true,
      },
    )
  }

  async getPatientInsurance() {
    let params = {
      patientId: getPreparedPatientId(),
      practiceId: getPreparedPracticeId(),
    }

    return await apiService.fetchLoggedV3(
      'patient_stories.get_insurance',
      this.#clearPayload(params),
      {
        skipAlert: true,
      },
    )
  }

  async getPatientSocialHistory() {
    let params = {
      patientId: getPreparedPatientId(),
      practiceId: getPreparedPracticeId(),
    }

    return await apiService.fetchLoggedV3(
      'patient_stories.get_social_history',
      this.#clearPayload(params),
      {
        skipAlert: true,
      },
    )
  }

  async searchEvents({
    resourceId,
    resources,
    appointmentId,
    diagnosticReportId, // only for observations
    subtype,
    code,
    identifier,
    text,
    startDate,
    stopDate,
    resourceProjection,
    status,
    statusNot,
  } = {}) {
    let params = {
      patientId: getPreparedPatientId(),
      practiceId: getPreparedPracticeId(),
      resourceId,
      resources,
      appointmentId,
      diagnosticreportId: diagnosticReportId,
      subtype,
      code,
      identifier,
      text,
      startDate,
      stopDate,
      resourceProjection,
      status,
      statusNot,
    }

    return await apiService.fetchLoggedV3(
      'patient_stories.search_events',
      this.#clearPayload(params),
      {
        skipAlert: true,
      },
    )
  }

  async getResourcesByReferences({ references, identifier, effectiveDateTime, extraParams }) {
    let params = {
      patientId: getPreparedPatientId(),
      practiceId: getPreparedPracticeId(),
      references,
      identifier,
      'effectiveDateTime|strict': effectiveDateTime,
      extraParams,
    }

    await mediaAuthInstance(params).fetch(references)

    return await apiService.fetchLoggedV3(
      'patient_stories.search_by_references',
      this.#clearPayload(params, ['references']),
      {
        skipAlert: true,
      },
    )
  }

  async getCompleteResourcesByReferences({ references, deepinclude }) {
    let params = {
      patientId: getPreparedPatientId(),
      practiceId: getPreparedPracticeId(),
      references,
      _deepinclude: deepinclude,
    }

    await mediaAuthInstance(params).fetch(references)

    return await apiService.fetchLoggedV3(
      'patient_stories.complete_resources',
      this.#clearPayload(params),
      {
        skipAlert: true,
      },
    )
  }

  async getMeasurements({ codes = [] } = {}) {
    let params = {
      patientId: getPreparedPatientId(),
      practiceId: getPreparedPracticeId(),
      code: [codes],
    }

    return await apiService.fetchLoggedV3(
      'patient_stories.get_measurements',
      this.#clearPayload(params),
      {
        skipAlert: true,
      },
    )
  }

  async updateMediaAuth({ patientId, practiceId } = {}) {
    let result = false

    const accessToken = await $auth.getAccessToken()

    if (!accessToken) {
      return result
    }

    let res

    try {
      let params = {
        patient_id: patientId || getPreparedPatientId(),
        practice_id: practiceId || getPreparedPracticeId(),
      }

      const host = _.replace(mainConfig.api.baseUrl, '/myack-rpc/', '')
      let url = `${host}/hooks/zmr/${mainConfig.appType}/media/authorize`
      let separator = url.indexOf('?') !== -1 ? '&' : '?'
      let queryParams = queryString.stringify(this.#clearPayload(params))

      res = await axios.get(url + separator + queryParams, {
        headers: {
          'X-Access-Token': accessToken,
        },
        // withCredentials: true,
      })
    } catch (e) {}

    if (res && res.data) {
      result = res.data
    }

    return result
  }
}

export default new psService()
