import React, { useMemo } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { useLoader } from '@library/CustomHooks'
import Modal from '@library/modal/Modal'

import PaymentMethodsList from '@pages/paymentMethods/PaymentMethodsList'

import { $pms } from '@store'

const SelectPaymentMethodModal = ({ _core, selected, onSelect }) => {
  const { t } = useTranslation()

  const isLoading = useLoader(async () => {
    await $pms.get()
  })

  const method = useMemo(() => {
    return $pms.getPaymentById(selected)
  }, [selected]) // eslint-disable-line react-hooks/exhaustive-deps

  function handleOnSelect(id) {
    onSelect(id)
    _core.onHide()
  }

  return (
    <Modal centered scrollable>
      <div className="modal-toolbar">
        <div className="modal-toolbar-btn modal-toolbar-btn--close" onClick={_core.onHide} />
      </div>
      <Modal.Header>{t('pms_select.title')}</Modal.Header>
      <Modal.Body isLoading={isLoading}>
        {method.isExpired && (
          <div className="alert alert--danger mb-20">{t('pms.error.expired')}</div>
        )}
        <PaymentMethodsList selected={selected} onSelect={handleOnSelect} showCheck={false} />
      </Modal.Body>
    </Modal>
  )
}

export default observer(SelectPaymentMethodModal)
