var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import SelectModal from '@library/select/Select.modal';
import { $modal } from '@store';
import { COUNTRIES } from '@config/countries';
import styles from './SelectCountryFlag.module.scss';
var SelectCountryFlag = function (_a) {
    var _b = _a.country, _country = _b === void 0 ? [] : _b, onChange = _a.onChange;
    var t = useTranslation().t;
    var _c = useState(_country), country = _c[0], setCountry = _c[1];
    useEffect(function () {
        if (!_.isEqual(_country, country)) {
            setCountry(_country);
        }
    }, [_country]); //eslint-disable-line react-hooks/exhaustive-deps
    var preparedItems = useMemo(function () {
        var result = [];
        _.forEach(COUNTRIES, function (item) {
            result.push({ id: item.iso2, text: item.label });
        });
        return result;
    }, []);
    function handleOnClick() {
        $modal.add(SelectModal, {
            title: t('select_country_modal.title'),
            searchBar: true,
            searchLabel: t('select_country_modal.ph.search'),
            allOption: t('select_country_modal.label.all'),
            items: preparedItems,
            value: country,
            onChange: function (values) {
                setCountry(values);
                if (_.isFunction(onChange)) {
                    onChange(values);
                }
            },
        });
    }
    return (_jsx("div", __assign({ className: classNames(styles.flagSelector, 'flag_selector'), onClick: handleOnClick }, { children: _.isEmpty(country) ? (_jsx("div", { className: styles.flagSelectorFlagAll })) : (_jsx("div", { className: classNames(styles.flagIcon, 'flag-icon', 'flag-icon--' + country[0]) })) })));
};
export default observer(SelectCountryFlag);
