var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import ClickOutside from '@library/ClickOutside';
import { useInnerValue } from '@library/CustomHooks';
import { getPreparedItems, getSelectedItems } from '@library/selectDropdown/MultiSelect.helpers';
import MultiSelectButton from '@library/selectDropdown/multiSelect/MultiSelectButton';
import MultiSelectDropdown from '@library/selectDropdown/multiSelect/MultiSelectDropdown';
import { useGetDropdownPosition } from '@library/utils/UseGetDropdownPosition.hook';
import styles from './MultiSelect.module.scss';
var MultiSelect = function (_a) {
    var field = _a.field, label = _a.label, _b = _a.mode, mode = _b === void 0 ? 'sm' : _b, placeholder = _a.placeholder, _c = _a.wrapperClassName, wrapperClassName = _c === void 0 ? '' : _c, _d = _a.className, className = _d === void 0 ? '' : _d, innerClassNames = _a.innerClassNames, _e = _a.items, items = _e === void 0 ? [] : _e, _f = _a.multiSelect, multiSelect = _f === void 0 ? false : _f, _g = _a.clear, clear = _g === void 0 ? false : _g, _h = _a.allowUnselect, allowUnselect = _h === void 0 ? false : _h, _j = _a.visibleCount, visibleCount = _j === void 0 ? 1 : _j, _k = _a.separator, separator = _k === void 0 ? ', ' : _k, allOption = _a.allOption, _l = _a.search, search = _l === void 0 ? false : _l, _m = _a.itemsFullWidth, itemsFullWidth = _m === void 0 ? false : _m, _o = _a.readonly, readonly = _o === void 0 ? false : _o;
    var t = useTranslation().t;
    var value = field.value, onChange = field.onChange, isInvalid = field.isInvalid;
    var _p = useState(false), isOpen = _p[0], setIsOpen = _p[1];
    var _q = useInnerValue((_.isArray(value) ? value : [value]).filter(function (x) { return x || x === 0; })), selection = _q[0], setSelection = _q[1];
    var hasSelection = selection.length > 0;
    var preparedItems = getPreparedItems(items, selection, setSelection, t);
    var selectedItems = getSelectedItems(preparedItems, selection, allOption, hasSelection, placeholder, visibleCount, multiSelect, separator);
    var _r = useGetDropdownPosition({
        isOpen: isOpen,
        itemsFullWidth: itemsFullWidth,
    }), fieldRef = _r.fieldRef, dropdownRef = _r.dropdownRef, dropdownStyles = _r.dropdownStyles;
    var handleOpen = function (open) {
        setIsOpen(open);
    };
    var handlerClear = function (val) {
        setSelection(val);
        onChange(val);
    };
    var handleSelected = function (val) {
        setSelection(val);
        onChange(val);
    };
    useEffect(function () {
        if (readonly) {
            handleOpen(false);
        }
    }, [readonly]);
    return (_jsx("div", __assign({ ref: fieldRef, className: classNames('multiselect-outside-wrapper', 'tsform-item', wrapperClassName) }, { children: _jsxs(ClickOutside, __assign({ className: classNames(styles.wrapper, className, readonly && styles.wrapperReadonly), onClick: function () { return setIsOpen(false); } }, { children: [_jsx(MultiSelectButton, { onOpen: handleOpen, onClear: handlerClear, selection: selection, visibleCount: visibleCount, multiSelect: multiSelect, allOption: allOption, mode: mode, label: label, getSelected: selectedItems, clear: clear, hasSelection: hasSelection, isOpen: isOpen, placeholder: placeholder, isInvalid: isInvalid, readonly: readonly, innerClassNames: innerClassNames }), _jsx("div", __assign({ className: classNames(styles.dropdownWrapper, isOpen && styles.dropdownWrapperOpen), style: dropdownStyles, ref: dropdownRef }, { children: _jsx(MultiSelectDropdown, { onSelected: handleSelected, onOpen: handleOpen, selection: selection, items: preparedItems, multiSelect: multiSelect, allowUnselect: allowUnselect, allOption: allOption, search: search, selectBtnWidth: (fieldRef === null || fieldRef === void 0 ? void 0 : fieldRef.current) && fieldRef.current.innerWidth }) }))] })) })));
};
export default observer(MultiSelect);
