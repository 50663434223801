import React, { forwardRef } from 'react'

import { Elements } from '@stripe/react-stripe-js'

import { loadStripe } from '@stripe/stripe-js'
import i18n from 'i18next'

import Inner from '@library/stripe/Inner'

import mainConfig from '@config/main'

const stripePromise = loadStripe(mainConfig.STRIPE_KEY, { locale: i18n.language })

const Stripe = forwardRef(({ get }, ref) => {
  return (
    <Elements stripe={stripePromise}>
      <Inner ref={ref} />
    </Elements>
  )
})

export default Stripe
