import React from 'react'

import { toJS } from 'mobx'
import { useTranslation } from 'react-i18next'

import { formatDate } from '@helpers/time'

import ViewCode from '@ps/library/viewCode/ViewCode'

const MedicationRequestDetails = ({ event, resource, extractor }) => {
  const { t } = useTranslation()
  const { asString, codeObj, dateEntered, subject = {}, recorder = {}, status = {} } = extractor

  return (
    <div className="event_details">
      <div className="event_details-header">
        <div className="event_details-title">{asString}</div>
        <div className="event_details-code">
          <ViewCode codeObj={codeObj} />
        </div>
      </div>
      <div className="event_details-body">
        {status.display && (
          <div className="event_details-line">
            {t('ps.event_viewer.status:')} {status.display}
          </div>
        )}

        {dateEntered && (
          <div className="event_details-line">
            {t('ps.event_viewer.entered_date:')} {formatDate(dateEntered)}
          </div>
        )}

        <div className="event_details-line mt-10">
          {t('ps.event_viewer.subject:')} {subject.value.fullName}
        </div>
        {recorder.value?.fullName && (
          <div className="event_details-line">
            {t('ps.event_viewer.recorder:')} {recorder.value.fullName}
          </div>
        )}
        <div className="event_details-line event_details-line--date">
          {formatDate(event.effectiveDate)}
        </div>
      </div>
    </div>
  )
}

export default MedicationRequestDetails
