import React from 'react'

import BookingFinish from '@booking/pages/bookingFinish/BookingFinish'
import BookingSelectPatients from '@booking/pages/bookingSelectPatients/BookingSelectPatients'
import BookingSymptoms from '@booking/pages/bookingSymptoms/BookingSymptoms'
import BookingTimePicker from '@booking/pages/bookingTimePicker/BookingTimePicker'

import DefaultLayout from '@src/layouts/default/Default'

import Appt from '@pages/Appt'
import EmptyView from '@pages/appt/childrenRoutes/EmptyView'
import EncounterView from '@pages/appt/childrenRoutes/EncounterView'
import Appts from '@pages/Appts'
import Signout from '@pages/auth/Signout'
import CocPatient from '@pages/CocPatient'
import Error404 from '@pages/errors/Error404'
import Home from '@pages/Home'
import Patients from '@pages/Patients'
import PaymentMethods from '@pages/PaymentMethods'
import Places from '@pages/Places'
import Profile from '@pages/Profile'

import PsPatient from '@ps/pages/Patient'

import { $accounts, $appts, $patients, $places, $pms, $psHistory, $psPatient, $user } from '@store'

export const routesBaseList = [
  // Home Page
  {
    name: 'home',
    path: '/',
    layout: <DefaultLayout />,
    element: <Home />,
    exact: true,
    meta: {
      hasBurger: true,
    },
  }, // Book: Step 1 (Select Patient)
  {
    name: 'bookPatients',
    path: '/book/patients',
    layout: <DefaultLayout />,
    element: <BookingSelectPatients />,
    private: true,
  }, // Book: Step 2 (Select Symptoms)
  {
    name: 'bookSymptoms',
    path: '/book/symptoms',
    layout: <DefaultLayout />,
    element: <BookingSymptoms />,
    meta: {
      fullHeight: true,
    },
    private: true,
  }, // Book: Step 3 (Select Date)
  {
    name: 'bookDate',
    path: '/book/date',
    layout: <DefaultLayout />,
    element: <BookingTimePicker />,
    private: true,
  }, // Book: Step 4 (Select Insurance, Voucher, Payment)
  {
    name: 'bookFinish',
    path: '/book/finish',
    layout: <DefaultLayout />,
    element: <BookingFinish />,
    private: true,
  }, // Appointments list
  {
    name: 'appts',
    path: '/appointments',
    layout: <DefaultLayout />,
    element: <Appts />,
    exact: true,
    private: true,
  }, // Appointments list FOR PATIENT
  {
    name: 'patientAppts',
    path: '/appointments/patient/:patientId',
    layout: <DefaultLayout />,
    element: <Appts />,
    exact: true,
    private: true,
  }, // Appointment by id
  {
    name: 'appt',
    path: '/appointments/:id',
    layout: <DefaultLayout />,
    element: <Appt />,
    private: true,
    onLoad: async ({ route }) => {
      const res = await $appts.getOne({ id: route.params.id })
      return res.isSuccess
    },
    children: [
      {
        name: 'apptEncounter',
        path: '/encounter/:encounterId?',
        exact: true,
        element: <EncounterView />,
      },
      {
        name: 'apptEmpty',
        path: '/',
        exact: true,
        element: <EmptyView />,
      },
    ],
  }, // Profile
  {
    name: 'profile',
    path: '/profile',
    layout: <DefaultLayout />,
    element: <Profile />,
    private: true,
    onLoad: async () => {
      if ($user.user) {
        await $accounts.get()
        await $user.refreshProfile()
      }
    },
  }, // Circle Of Care list
  {
    name: 'patients',
    path: '/circle',
    layout: <DefaultLayout />,
    element: <Patients />,
    exact: true,
    private: true,
    onLoad: async () => {
      await $patients.get()
    },
  }, // Circle Patient
  {
    name: 'patient',
    path: '/circle/:patientId',
    layout: <DefaultLayout />,
    element: <CocPatient />,
    exact: true,
    private: true,
    onLoad: async ({ route }) => {
      const { patientId } = route.params

      await $patients.get({ id: patientId }) // todo: need use $patients.getOne
      const patient = await $patients.getById(patientId)

      if (_.isEmpty(patient)) {
        return false
      }
    },
  },

  // Patient Story
  {
    name: 'psPatient',
    path: '/circle/:patientId/practice/:practiceId/:categoryId?',
    layout: <DefaultLayout />,
    element: <PsPatient />,
    private: true,
    onLoad: async ({ route }) => {
      const { patientId, categoryId } = route.params

      $psPatient.clear()
      await $psPatient.loadData()
      if (_.isEmpty($psPatient.demographics)) {
        return false
      }

      // todo: temporarily solution for show common patient name and photo
      const patient = $patients.getById(patientId)
      if (_.isEmpty(patient)) await $patients.get()
      // todo: temporarily solution for show common patient name and photo

      $psHistory.SET_CATEGORY(categoryId)
    },
  },

  // Addresses list
  {
    name: 'places',
    path: '/addresses',
    layout: <DefaultLayout />,
    element: <Places />,
    private: true,
    onLoad: async () => {
      await $places.get()
    },
  }, // Payment Methods list
  {
    name: 'pms',
    path: '/payment-methods',
    layout: <DefaultLayout />,
    element: <PaymentMethods />,
    private: true,
    onLoad: async () => {
      await $pms.get()
    },
  }, // Signout
  {
    name: 'signout',
    path: '/signout',
    layout: <DefaultLayout />,
    element: <Signout />,
    private: true,
  }, // Error page
  {
    name: 'error',
    path: '*',
    layout: <DefaultLayout />,
    element: <Error404 />,
  },
]

export const areaList = {
  appointmentPage: {
    appt: 'id',
  },
  hideNearestBar: {
    appt: 'id',
    bookServiceType: 'id',
    bookAddress: 'id',
    bookPatients: 'id',
    bookSymptoms: 'id',
    bookDate: 'id',
    bookFinish: 'id',
    error: 'id',
  },
  hideRightSidebar: {
    bookServiceType: 'id',
    bookAddress: 'id',
    bookPatients: 'id',
    bookSymptoms: 'id',
    bookDate: 'id',
    bookFinish: 'id',
  },
}
