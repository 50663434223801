import React, { useEffect, useState } from 'react'

import { observer } from 'mobx-react'

import { $modal } from '@store'

import confirmActions from '@config/confirmActions'

const ActionConfirmator = () => {
  let [actions, setActions] = useState(confirmActions)

  useEffect(() => {
    function confirmHandle({ detail = {} }) {
      let { id, skip, once = true, data = {}, callback = null, event, ...passedProps } = detail

      if (actions[id]) {
        const actionOptions = _.cloneDeep(actions[id])
        let skipped = false

        if (typeof skip !== 'undefined') {
          if (skip === true || (_.isFunction(skip) && skip())) {
            skipped = true
          }
        } else {
          let defaultSkip

          if (actionOptions.hasOwnProperty('skip')) {
            defaultSkip = actionOptions.skip
          }

          if (defaultSkip === true || (_.isFunction(defaultSkip) && defaultSkip())) {
            skipped = true
          }
        }

        let resultCallback

        if (!callback && actionOptions.hasOwnProperty('defaultCallback')) {
          resultCallback = () => {
            actionOptions.defaultCallback()
          }
        } else if (callback) {
          resultCallback = callback
        }

        if (actionOptions.enabled && !skipped) {
          if (_.isObject(event) && _.isFunction(event.preventDefault)) {
            event.preventDefault()
          }

          if (once && isModalOpened(actionOptions.modalTemplate)) {
            return false
          }

          $modal.add(actionOptions.modalTemplate, {
            multi: true,
            callback: resultCallback,
            data: _.merge(actionOptions.data, data),
            ...passedProps,
          })
        } else if (resultCallback) {
          if (_.isObject(event) && _.isFunction(event.preventDefault)) {
            event.preventDefault()
          }

          resultCallback()
        }
      }
    }

    function isModalOpened(template) {
      return _.find($modal.current, { template }) || _.find($modal.items, { template })
    }

    function changeHandle({ detail = {} }) {
      let { id, payload } = detail

      if (actions[id]) {
        setActions((prev) => {
          let tmp = _.cloneDeep(prev)
          tmp[id] = _.merge(tmp[id], payload)
          return tmp
        })
      }
    }

    window.addEventListener('ac-confirm', confirmHandle)
    window.addEventListener('ac-change', changeHandle)

    return () => {
      window.removeEventListener('ac-confirm', confirmHandle)
      window.removeEventListener('ac-change', changeHandle)
    }
  }, [actions, $modal])

  return <></>
}

/*
  id    | Config item
  props | Available: {
              callback = fn(),           |
              cancelCallback = fn(),     |
              data = {},                 | MERGE default
              once = bool                |
              skip = bool || fn()        | REPLACE default
              modalOptions = {}          | MERGE default
            }
  event | Used to prevent default event
 */
export const confirmAction = (id = '', props = {}, event = null) => {
  if (id) {
    window.dispatchEvent(
      new CustomEvent('ac-confirm', {
        detail: { id, ...props, event },
      }),
    )
  }
}

export const changeAction = (id = '', payload = {}) => {
  if (id) {
    window.dispatchEvent(
      new CustomEvent('ac-change', {
        detail: { id, payload },
      }),
    )
  }
}

export const enableAction = (id = '') => {
  changeAction(id, { enabled: true })
}

export const disableAction = (id = '') => {
  changeAction(id, { enabled: false })
}

export default observer(ActionConfirmator)
