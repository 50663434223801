import React, { useMemo } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import styles from './Checkbox.module.scss'

const Checkbox = (props) => {
  const { t } = useTranslation()

  let {
    field,
    binaryValue,
    // optional
    name, // Required if value is array || object || string || number
    label,
    className = '',
    labelClass = '',
    isButton = false,
    readonly = false,
    mode,
  } = props
  const { value, onChange } = field

  const isBinary = useMemo(() => typeof binaryValue !== 'undefined', [binaryValue])

  const isChecked = useMemo(() => {
    if (_.isBoolean(value)) {
      if (isBinary) {
        return value === binaryValue
      } else {
        return value
      }
    } else if (_.isArray(value) && typeof name !== 'undefined') {
      return value.indexOf(name) !== -1
    } else if (_.isObject(value) && typeof name !== 'undefined') {
      return _.find(value, (x, key) => x && key === name)
    } else {
      return value === name
    }
  }, [value, name]) //eslint-disable-line react-hooks/exhaustive-deps

  function onClick() {
    if (readonly) {
      return
    }
    if (isBinary) {
      onChange(binaryValue, name)
    } else {
      onChange(!isChecked, name)
    }
  }

  return (
    <div
      className={classNames(
        styles.formCheck,
        className,
        isChecked && styles.checked,
        isButton && styles.btn,
        readonly && styles.readonly,
        mode && styles[mode],
        !label && !binaryValue && styles.noLabel,
      )}
      onClick={onClick}
    >
      <div className={classNames(styles.label, labelClass)}>
        {label || (isBinary && (binaryValue ? t('btn.yes') : t('btn.no')))}
      </div>
    </div>
  )
}

export default observer(Checkbox)
