import React, { useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { useLoader } from '@library/CustomHooks'
import PracticeButton from '@library/practice/practiceButton/PracticeButton'
import WithLoaderWrapper from '@library/ui/withLoaderWrapper/WithLoaderWrapper'

import { withFullName } from '@ps/helpers/fhir'
import psService from '@ps/services/psService'

import { $main } from '@store'

import { routeActions } from '@config/routeActions'

const PsPatientPractices = ({ patientId }) => {
  const { t } = useTranslation()
  const [practicesList, setPracticesList] = useState([])

  const isLoading = useLoader(async () => {
    const response = await psService.getPractices()

    if (response.prepared) {
      let result = []

      _.forEach(response.prepared, ({ practice, lastUpdated, ...patient }) => {
        _.forEach(practice, (item) => {
          item.lastUpdated = lastUpdated
          item.patient = withFullName(patient)
          if (item.ids && item.ids[1]) {
            item.id = item.ids[1]
          }
          result.push(item)
        })
      })

      result = _.orderBy(result, (x) => x.lastUpdated, 'desc')
      setPracticesList(result)
    }
  }, [])

  return (
    <WithLoaderWrapper isLoading={isLoading} visibleCondition={practicesList.length > 0}>
      {practicesList.map((item) => (
        <PracticeButton
          to={routeActions.PS_PATIENT({ patientId: patientId, practiceId: item.id }).to}
          imageUrl={item.imageUrl}
          practiceName={item.name}
          buttonText={$main.windowSize.maxMD ? t('btn.view') : t('btn.view_patient_history')}
          key={item.id}
        />
      ))}
    </WithLoaderWrapper>
  )
}

export default observer(PsPatientPractices)
