import React, { useMemo } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import UserPhoto from '@library/user/UserPhoto'

import hAppt from '@helpers/hAppt'

import { $appts } from '@store'

const ApptConference = ({ byRoute, isMap = false }) => {
  const { t } = useTranslation()

  const { fullAppt, status, actions } = byRoute || $appts.byRoute

  const preparedList = useMemo(() => {
    const { clientReady, agentReady, providerReady } = status
    const { textGeneral, textStaff } = actions

    return [
      {
        role: 'client',
        visibleCondition: textGeneral,
        isJoined: clientReady,
      },
      {
        role: 'agent',
        visibleCondition: textStaff,
        isJoined: agentReady,
      },
      {
        role: 'provider',
        visibleCondition: textStaff,
        isJoined: providerReady,
      },
    ]
  }, [actions, status])

  function getJoinStatus(isReady) {
    return isReady ? t('appt_conference.status.joined') : t('appt_conference.status.pending')
  }

  function handleResend(target) {
    const url = hAppt.meetingUrl({ fullAppt, type: 'join', source: 'app' })
    let result

    if (target === 'client' && actions.textGeneral) {
      result = actions.textGeneral
    } else if (target === 'agent' && actions.textStaff) {
      result = actions.textStaff
    } else if (target === 'provider' && actions.textStaff) {
      result = actions.textStaff
    }

    if (_.isFunction(result.action) && url) {
      result.action(url)
    }
  }

  if (!actions.finish) {
    return null
  }

  return (
    <div className={classNames('appt_conference', isMap && 'appt_conference--map')}>
      {!isMap && <div className="title-sm">{t('appt_conference.title')}</div>}
      <div className="row">
        {preparedList.map(
          (item) =>
            item.visibleCondition && (
              <div className="col" key={item.role}>
                <div className="appt_conference-item">
                  <UserPhoto item={{ name: t('appt_conference.role.' + item.role) }} />
                  <div className="appt_conference-content">
                    <div className="appt_conference-content-title">
                      {t('appt_conference.role.' + item.role)}
                    </div>
                    <div
                      className={classNames(
                        'appt_conference-content-meta',
                        !item.isJoined && 'text-meta',
                      )}
                    >
                      {getJoinStatus(item.isJoined)}
                    </div>
                  </div>
                  <div className="a appt_conference-link" onClick={() => handleResend(item.role)}>
                    {t('btn.resend_link')}
                  </div>
                </div>
              </div>
            ),
        )}
        {!isMap && (
          <div className="col">
            <div className="appt_conference-item">
              <UserPhoto item={{ name: t('appt_conference.role.owner') }} />
              <div className="appt_conference-content">
                <div className="appt_conference-content-title">
                  {t('appt_conference.role.owner')}
                </div>
                <div className="appt_conference-content-meta">
                  {t('appt_conference.status.owner')}
                </div>
              </div>
              <div
                className="a appt_conference-link"
                onClick={() => actions.startVideo.copyVideoLink()}
              >
                {t('btn.copy_video_link')}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default observer(ApptConference)
