import React, { useEffect, useRef } from 'react'

function useClickOutside(callback) {
  const innerRef = useRef()
  const callbackRef = useRef()

  useEffect(() => {
    callbackRef.current = callback
  })

  useEffect(() => {
    function handleClick(e) {
      if (innerRef.current && callbackRef.current && !innerRef.current.contains(e.target)) {
        callbackRef.current(e)
      }
    }

    document.addEventListener('click', handleClick, true)

    return () => {
      document.removeEventListener('click', handleClick, true)
    }
  }, [])

  return innerRef
}

const ClickOutside = ({ children, onClick, tag = 'div', ...props }) => {
  const ref = useClickOutside(onClick)
  const Tag = tag

  return (
    <Tag ref={ref} {...props}>
      {children}
    </Tag>
  )
}

export default ClickOutside
