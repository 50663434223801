import React from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import AddInsuranceModal from '@library/addInsuranceModal/AddInsurance.modal'
import Button from '@library/ui/button/Button'
import Checkbox from '@library/ui/checkbox/Checkbox'

import { $modal } from '@store'

import styles from './InsuranceSelector.module.scss'

const InsuranceSelector = ({ insurance, onChangeInsurance }) => {
  const { t } = useTranslation()

  const handleAddInsurance = () => {
    $modal.add(AddInsuranceModal, {
      onDone: (insuranceFields) => {
        const newInsurance = {}
        _.forEach(insuranceFields, (value, fieldName) => {
          if (_.isNumber(value)) {
            value = _.toString(value)
          }
          newInsurance[fieldName] = value || null
        })
        newInsurance.useInsurance = false
        onChangeInsurance(newInsurance)
      },
    })
  }

  const removeInsurance = () => onChangeInsurance(null)

  const handleClick = () =>
    onChangeInsurance({ ...insurance, useInsurance: !insurance.useInsurance })

  return (
    <>
      {insurance === null ? (
        <Button
          tag="button"
          mode={['secondary', 'icon']}
          className={classNames(styles.button, 'btn-icon--blue-plus')}
          action={handleAddInsurance}
        >
          {t('btn.add_insurance')}
        </Button>
      ) : (
        <div className={classNames(styles.wrapper)}>
          <div className={styles.body} onClick={handleClick}>
            <Checkbox
              className={styles.checkbox}
              isChecked={insurance.useInsurance}
              isDark={true}
            />
            <div className={styles.content}>
              <div className={styles.label}>{t('label.health_insurance')}</div>
              <div className={styles.insuranceName}>
                <span>{insurance.insuranceProviderName}</span>
                <span>&nbsp;• {insurance.insuranceMemberId}</span>
              </div>
            </div>
            <div
              className={styles.trash}
              onClick={(e) => {
                e.stopPropagation()
                removeInsurance(insurance)
              }}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default observer(InsuranceSelector)
