var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import _ from 'lodash';
import BubbleLoader from '@library/ui/bubbleLoader/BubbleLoader';
import { getMode } from '@helpers/other';
import styles from './InfoBox.module.scss';
var InfoBox = function (_a) {
    var _b = _a.tag, tag = _b === void 0 ? 'div' : _b, mode = _a.mode, className = _a.className, label = _a.label, value = _a.value, isLoading = _a.isLoading, children = _a.children, to = _a.to, onClick = _a.onClick, childButton = _a.childButton, withError = _a.withError, clickable = _a.clickable;
    var isClickable = clickable && !isLoading;
    var handleOnClick = function (e) {
        if (isLoading) {
            e.preventDefault();
            return;
        }
        if (isClickable && _.isFunction(onClick)) {
            return onClick(e);
        }
    };
    var buttonClassNames = useMemo(function () {
        return [
            styles.infoBox,
            mode && getMode(mode, styles),
            isClickable && styles.clickable,
            withError && styles.withError,
            isLoading && styles.isLoading,
        ];
    }, [isLoading, mode, isClickable, withError]);
    function Child() {
        return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: styles.item }, { children: [label && _jsx("div", __assign({ className: styles.infoBoxLabel }, { children: label })), _jsx("div", __assign({ className: styles.infoBoxValue }, { children: _.isEmpty(children) || childButton ? value : children }))] })), childButton && _jsx("div", __assign({ className: styles.item }, { children: children })), isLoading && _jsx(BubbleLoader, { innerClassNames: styles.bubbleLoader })] }));
    }
    return (_jsx(_Fragment, { children: tag === 'link' ? (_jsx(Link, __assign({ to: to, onClick: handleOnClick, className: classNames(buttonClassNames, className) }, { children: _jsx(Child, {}) }))) : tag === 'a' ? (_jsx("a", __assign({ href: to, onClick: handleOnClick, className: classNames(buttonClassNames, className) }, { children: _jsx(Child, {}) }))) : (_jsx("div", __assign({ onClick: handleOnClick, className: classNames(buttonClassNames, className) }, { children: _jsx(Child, {}) }))) }));
};
export default InfoBox;
