import React from 'react'

import { observer } from 'mobx-react'
import { Route, Switch } from 'react-router-dom'

const Outlet = ({ basePath, routesList, ...passedProps }) => {
  return (
    <Switch>
      {routesList.map((route, index) => (
        <Route
          key={route.path + index}
          path={basePath + route.path}
          exact={route.exact}
          render={() => {
            return React.cloneElement(route.element, { ...passedProps })
          }}
        />
      ))}
    </Switch>
  )
}

export default observer(Outlet)
