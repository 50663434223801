import React from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import moment from 'moment-timezone'

import InputBase from '@library/form/fields/new/InputBase'
import { useForm } from '@library/form/FormControllerNew'
import NewDatePickerModal from '@library/newDatePicker/NewDatePicker.modal'

import { formatDob } from '@helpers/time'

import { $modal } from '@store'

const ApptsSearch = ({ setFromDate, setToDate }) => {
  const { t } = useTranslation()

  const { form, useFormOnChange } = useForm({
    fromDate: { value: '' }, // SET_DATE_FROM
    toDate: { value: '' }, // SET_DATE_TO
  })
  const { fromDate, toDate } = form.fields

  const formatToDate = (date) => {
    return date ? moment(toDate.value).endOf('day').toISOString() : ''
  }

  useFormOnChange(() => {
    setFromDate(fromDate.value)
    setToDate(formatToDate(toDate.value))
  }, [fromDate.value, toDate.value])

  return (
    <div className="pd-search-params mb-30">
      <div className="row">
        <div className="col-12 col-lg-5">
          <div className="tsform-join-group">
            <InputBase
              field={{
                value: formatDob(fromDate.value),
                onChange: fromDate.onChange,
                isInvalid: fromDate.isInvalid,
              }}
              onClick={() => {
                $modal.add(NewDatePickerModal, {
                  type: 'selectDateTime',
                  selectedDate: fromDate.value,
                  onChange: fromDate.onChange,
                  minDate: moment().add(-1, 'year'),
                  maxDate: moment().add(2, 'month'),
                })
              }}
              label={t('ps.label.date_from')}
              placeholder={t('ps.ph.date_from')}
              mode={['sm', 'fw-400', 'cursor-pointer']}
              readOnly={true}
              clear
            />
            <InputBase
              field={{
                value: formatDob(toDate.value),
                onChange: toDate.onChange,
                isInvalid: toDate.isInvalid,
              }}
              onClick={() => {
                $modal.add(NewDatePickerModal, {
                  type: 'selectDateTime',
                  selectedDate: toDate.value,
                  onChange: toDate.onChange,
                  minDate: moment().add(-1, 'year'),
                  maxDate: moment().add(2, 'month'),
                })
              }}
              label={t('ps.label.date_to')}
              placeholder={t('ps.ph.date_to')}
              mode={['sm', 'fw-400', 'cursor-pointer']}
              readOnly={true}
              clear
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(ApptsSearch)
