import React from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import Modal from '@library/modal/Modal'

import mainConfig from '@config/main'

import styles from './Restricted.modal.module.scss'

const RestrictedModal = ({ _core }) => {
  const { t } = useTranslation()

  return (
    <Modal centered>
      <div className="modal-toolbar">
        <div className="modal-toolbar-btn modal-toolbar-btn--close" onClick={_core.onHide} />
      </div>
      <Modal.Header>
        {t('home.no_booking_placeholder.title', { name: mainConfig.appName })}
      </Modal.Header>
      <Modal.Body>
        <div className={styles.content}>
          <p className="no-booking-placeholder__text">
            {t('home.no_booking_placeholder.text.line_one')}
          </p>
          <p className="no-booking-placeholder__text">
            {t('home.no_booking_placeholder.text.line_two')}
          </p>
          <p className="no-booking-placeholder__text">
            {t('home.no_booking_placeholder.text.line_three')}
          </p>
          <div className={styles.storeLinks}>
            <div className={styles.storeLinksItem}>
              <p className={styles.storeLinksItemText}>{t('label.app_store')}</p>
              <div className={classNames(styles.qr, styles.qrAppStore)} />
            </div>
            <div className={styles.storeLinksItem}>
              <p className={styles.storeLinksItemText}>{t('label.google_play')}</p>
              <div className={classNames(styles.qr, styles.qrGooglePlay)} />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default observer(RestrictedModal)
