import React, { useEffect, useRef, useState } from 'react'

const BaseVideo = ({
  src,
  contentType,
  time = 0.1,
  preload = 'metadata',
  onIsLoading,
  onIsError,
  ...passedProps
}) => {
  const videoRef = useRef()

  const [metadataLoaded, setMetadataLoaded] = useState(false)
  const [dataLoaded, setDataLoaded] = useState(false)
  const [suspended, setSuspended] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    if (metadataLoaded && dataLoaded && suspended) {
      if (_.isFunction(onIsLoading)) {
        onIsLoading(false)
      }
    }
  }, [metadataLoaded, dataLoaded, suspended]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (error) {
      if (_.isFunction(onIsLoading)) {
        onIsLoading(false)
      }
      if (_.isFunction(onIsError)) {
        onIsError(true)
      }
    }
  }, [error]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <video
      ref={videoRef}
      onLoadedMetadata={() => setMetadataLoaded(true)}
      onLoadedData={() => setDataLoaded(true)}
      onSuspend={() => setSuspended(true)}
      onError={() => setError(true)}
      preload={preload}
      {...passedProps}
    >
      <source src={src + '#t=' + time} type={contentType} />
    </video>
  )
}

export default BaseVideo
