import rg4js from 'raygun4js'

import { FILTERED_KEYS, SKIPPED_RG_CODES } from './raygun.contants'
import { onBeforeSend } from './raygun.helpers'

let initialized = false

export function initRaygun({
  env = process.env.REACT_APP_ENV,
  appType = process.env.REACT_APP_TYPE,
  appBrand = process.env.REACT_APP_BRAND,
  appVersion = process.env.REACT_APP_VERSION,
  commitHash = process.env.REACT_APP_COMMIT_HASH,
  apiKey,
} = {}) {
  if (!apiKey) {
    return false
  }

  rg4js('enableCrashReporting', true)
  rg4js('enablePulse', false)
  rg4js('withTags', [env, window.location.host, appBrand, appType, appVersion, commitHash])
  rg4js('logContentsOfXhrCalls', true)
  rg4js('filterSensitiveData', FILTERED_KEYS)
  rg4js('setFilterScope', 'all')
  rg4js('onBeforeSend', onBeforeSend(FILTERED_KEYS))
  rg4js('apiKey', apiKey)

  initialized = true
}

export function setRG(event, params) {
  if (!initialized) {
    return false
  }

  params = _.cloneDeep(params)

  function clearParams(data) {
    let result = data

    _.forEach(data, (x, key) => {
      if (_.isObject(x) || _.isArray(x)) {
        result[key] = clearParams(x)
      } else if (key === 'access_token') {
        result[key] = '***'
      }
    })

    return result
  }

  let isSend = true

  if (params.customData) {
    const code = _.get(params, 'customData.response.error.code')

    if (SKIPPED_RG_CODES.includes(code)) {
      isSend = false
    } else {
      params.customData = clearParams(params.customData)
    }
  }

  if (isSend) {
    rg4js(event, params)
  }
}
