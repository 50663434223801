import React from 'react'

import { useTranslation } from 'react-i18next'

import { formatDate } from '@helpers/time'

import RoleName from '@ps/library/RoleName'
import ViewCode from '@ps/library/viewCode/ViewCode'

const Allergy = ({ event, resource, extractor }) => {
  const { t } = useTranslation()
  const {
    text,
    valuesAsString,
    noteAsString,
    codeObj,
    type = {},
    patient = {},
    recorder = {},
    status = {},
    verificationStatus = {},
  } = extractor

  return (
    <div className="event_details">
      <div className="event_details-header">
        <div className="event_details-title">
          {text}
          {event.isSelfReported && (
            <span className="badge-self_reported">{t('ps.label.self_reported')}</span>
          )}
        </div>
        <div className="event_details-code">
          <ViewCode codeObj={codeObj} />
        </div>
      </div>
      <div className="event_details-body">
        {valuesAsString && (
          <div className="event_details-line">
            {t('ps.event_viewer.reaction:')} {valuesAsString}
          </div>
        )}
        {noteAsString && (
          <div className="event_details-line">
            {t('ps.event_viewer.note:')} {noteAsString}
          </div>
        )}
        {type.code && (
          <div className="event_details-line">
            {t('ps.event_viewer.type:')} {t(type.label)}
          </div>
        )}
        {status.display && (
          <div className="event_details-line">
            {t('ps.event_viewer.status:')} {status.display}
          </div>
        )}
        {verificationStatus && (
          <div className="event_details-line">
            {t('ps.event_viewer.verification_status:')} {verificationStatus}
          </div>
        )}
        <div className="event_details-line mt-10">
          {t('ps.event_viewer.patient')} {patient.value.fullName}
        </div>
        {/*<div className="event_details-line">*/}
        {/*  {t('ps.event_viewer.asserter')} {asserter.value.fullName}{' '}*/}
        {/*  <RoleName role={asserter.role} resource={asserter.value} />*/}
        {/*</div>*/}
        {!_.isEmpty(recorder.value) && (
          <div className="event_details-line">
            {t('ps.event_viewer.recorder:')} {recorder.value.fullName}{' '}
            <RoleName role={recorder.role} resource={recorder.value} />
          </div>
        )}
        <div className="event_details-line event_details-line--date">
          {formatDate(event.effectiveDate)}
        </div>
      </div>
    </div>
  )
}

export default Allergy
