import i18n from 'i18next'
import _ from 'lodash'
import moment from 'moment-timezone'

import { isAbsoluteEmpty } from '@helpers/other'

import { $user } from '@store'

const diffUnits = {
  en: {
    h: {
      web: 'h',
      mobile: 'hours',
    },
    m: {
      web: 'm',
      mobile: 'minutes',
    },
  },
  ru: {
    h: {
      web: 'ч',
      mobile: 'часов',
    },
    m: {
      web: 'м',
      mobile: 'минут',
    },
  },
  uk: {
    h: {
      web: 'г',
      mobile: 'годин',
    },
    m: {
      web: 'хв',
      mobile: 'хвилин',
    },
  },
  pl: {
    h: {
      web: 'h',
      mobile: 'hours',
    },
    m: {
      web: 'm',
      mobile: 'minutes',
    },
  },
  es: {
    h: {
      web: 'h',
      mobile: 'hours',
    },
    m: {
      web: 'm',
      mobile: 'minutes',
    },
  },
}

export const dateFormats = {
  en: {
    datetime: 'MMM D, YYYY h:mm A',
    datetimeNoYear: 'MMM D, h:mm A',
    datetimeFullNoYear: 'MMMM D, h:mm A',
    date: 'MMM D, YYYY',
    dateNum: 'MM/DD/YYYY',
    dateOnlyDay: 'D',
    dateOnlyMonth: 'ddd',
    dateFullMonth: 'MMMM',
    dateFullNoDay: 'MMMM YYYY',
    dateFullNoYear: 'MMMM D',
    dateNoYear: 'MMM D',
    dateNoDay: 'MMM YYYY',
    dateFull: 'MMMM D, YYYY',
    time: 'h:mm A',
    iso: 'YYYY-MM-DDTHH:mm:ss',
    isoShort: 'YYYY-MM-DD',
    sameHour: '[in] mm [min]',
    calendarDatetime: {
      sameDay: '[Today], h:mm A',
      nextDay: '[Tomorrow], h:mm A',
      nextWeek: 'MMM D, h:mm A',
      lastDay: '[Yesterday], h:mm A',
      lastWeek: 'MMM D, h:mm A',
      sameElse: 'MMM D, h:mm A',
    },
    calendarDatetimeShort: {
      sameDay: 'h:mm A',
      nextDay: '[Tomorrow], h:mm A',
      nextWeek: 'MMM D, h:mm A',
      lastDay: '[Yesterday], h:mm A',
      lastWeek: 'MMM D, h:mm A',
      sameElse: 'MMM D, h:mm A',
    },
    calendarDate: {
      sameDay: '[Today]',
      nextDay: '[Tomorrow]',
      nextWeek: 'MMM D',
      lastDay: '[Yesterday]',
      lastWeek: 'MMM D',
      sameElse: 'MMM D',
    },
    calendarWithText: {
      sameDay: '[at] h:mm A',
      nextDay: '[Tomorrow], h:mm A',
      nextWeek: 'MMM D, h:mm A',
      lastDay: '[Yesterday], h:mm A',
      lastWeek: 'MMM D, h:mm A',
      sameElse: 'MMM D, h:mm A',
    },
  },
  ru: {
    datetime: 'D MMM, YYYY HH:mm',
    datetimeNoYear: 'D MMM, HH:mm',
    datetimeFullNoYear: 'D MMMM, HH:mm',
    date: 'D MMM, YYYY',
    dateNum: 'DD/MM/YYYY',
    dateOnlyDay: 'D',
    dateOnlyMonth: 'ddd',
    dateFullMonth: 'MMMM',
    dateFullNoDay: 'MMMM YYYY',
    dateFullNoYear: 'D MMMM',
    dateNoYear: 'D MMM',
    dateNoDay: 'MMM YYYY',
    dateFull: 'D MMMM, YYYY',
    time: 'HH:mm',
    iso: 'YYYY-MM-DDTHH:mm:ss',
    isoShort: 'YYYY-MM-DD',
    sameHour: '[in] mm [min]',
    calendarDatetime: {
      sameDay: '[Today], HH:mm',
      nextDay: '[Tomorrow], HH:mm',
      nextWeek: 'D MMM, HH:mm',
      lastDay: '[Yesterday], HH:mm',
      lastWeek: 'D MMM, HH:mm',
      sameElse: 'D MMM, HH:mm',
    },
    calendarDatetimeShort: {
      sameDay: 'HH:mm',
      nextDay: '[Tomorrow], HH:mm',
      nextWeek: 'D MMM, HH:mm',
      lastDay: '[Yesterday], HH:mm',
      lastWeek: 'D MMM, HH:mm',
      sameElse: 'D MMM, HH:mm',
    },
    calendarDate: {
      sameDay: '[Today]',
      nextDay: '[Tomorrow]',
      nextWeek: 'D MMM',
      lastDay: '[Yesterday]',
      lastWeek: 'D MMM',
      sameElse: 'D MMM',
    },
    calendarWithText: {
      sameDay: '[at] HH:mm',
      nextDay: '[Tomorrow], HH:mm',
      nextWeek: 'D MMM, HH:mm',
      lastDay: '[Yesterday], HH:mm',
      lastWeek: 'D MMM, HH:mm',
      sameElse: 'D MMM, HH:mm',
    },
  },
  uk: {
    datetime: 'D MMM, YYYY HH:mm',
    datetimeNoYear: 'D MMM, HH:mm',
    datetimeFullNoYear: 'D MMMM, HH:mm',
    date: 'D MMM, YYYY',
    dateNum: 'DD/MM/YYYY',
    dateOnlyDay: 'D',
    dateOnlyMonth: 'ddd',
    dateFullMonth: 'MMMM',
    dateFullNoDay: 'MMMM YYYY',
    dateFullNoYear: 'D MMMM',
    dateNoYear: 'D MMM',
    dateNoDay: 'MMM YYYY',
    dateFull: 'D MMMM, YYYY',
    time: 'HH:mm',
    iso: 'YYYY-MM-DDTHH:mm:ss',
    isoShort: 'YYYY-MM-DD',
    sameHour: '[in] mm [min]',
    calendarDatetime: {
      sameDay: '[Today], HH:mm',
      nextDay: '[Tomorrow], HH:mm',
      nextWeek: 'D MMM, HH:mm',
      lastDay: '[Yesterday], HH:mm',
      lastWeek: 'D MMM, HH:mm',
      sameElse: 'D MMM, HH:mm',
    },
    calendarDatetimeShort: {
      sameDay: 'HH:mm',
      nextDay: '[Tomorrow], HH:mm',
      nextWeek: 'D MMM, HH:mm',
      lastDay: '[Yesterday], HH:mm',
      lastWeek: 'D MMM, HH:mm',
      sameElse: 'D MMM, HH:mm',
    },
    calendarDate: {
      sameDay: '[Today]',
      nextDay: '[Tomorrow]',
      nextWeek: 'D MMM',
      lastDay: '[Yesterday]',
      lastWeek: 'D MMM',
      sameElse: 'D MMM',
    },
    calendarWithText: {
      sameDay: '[at] HH:mm',
      nextDay: '[Tomorrow], HH:mm',
      nextWeek: 'D MMM, HH:mm',
      lastDay: '[Yesterday], HH:mm',
      lastWeek: 'D MMM, HH:mm',
      sameElse: 'D MMM, HH:mm',
    },
  },
  pl: {
    datetime: 'D MMM, YYYY HH:mm',
    datetimeNoYear: 'D MMM, HH:mm',
    datetimeFullNoYear: 'D MMMM, HH:mm',
    date: 'D MMM, YYYY',
    dateNum: 'DD/MM/YYYY',
    dateOnlyDay: 'D',
    dateOnlyMonth: 'ddd',
    dateFullMonth: 'MMMM',
    dateFullNoDay: 'MMMM YYYY',
    dateFullNoYear: 'D MMMM',
    dateNoYear: 'D MMM',
    dateNoDay: 'MMM YYYY',
    dateFull: 'D MMMM, YYYY',
    time: 'HH:mm',
    iso: 'YYYY-MM-DDTHH:mm:ss',
    isoShort: 'YYYY-MM-DD',
    sameHour: '[in] mm [min]',
    calendarDatetime: {
      sameDay: '[Today], HH:mm',
      nextDay: '[Tomorrow], HH:mm',
      nextWeek: 'D MMM, HH:mm',
      lastDay: '[Yesterday], HH:mm',
      lastWeek: 'D MMM, HH:mm',
      sameElse: 'D MMM, HH:mm',
    },
    calendarDatetimeShort: {
      sameDay: 'HH:mm',
      nextDay: '[Tomorrow], HH:mm',
      nextWeek: 'D MMM, HH:mm',
      lastDay: '[Yesterday], HH:mm',
      lastWeek: 'D MMM, HH:mm',
      sameElse: 'D MMM, HH:mm',
    },
    calendarDate: {
      sameDay: '[Today]',
      nextDay: '[Tomorrow]',
      nextWeek: 'D MMM',
      lastDay: '[Yesterday]',
      lastWeek: 'D MMM',
      sameElse: 'D MMM',
    },
    calendarWithText: {
      sameDay: '[at] HH:mm',
      nextDay: '[Tomorrow], HH:mm',
      nextWeek: 'D MMM, HH:mm',
      lastDay: '[Yesterday], HH:mm',
      lastWeek: 'D MMM, HH:mm',
      sameElse: 'D MMM, HH:mm',
    },
  },
  es: {
    datetime: 'D MMM, YYYY HH:mm',
    datetimeNoYear: 'D MMM, HH:mm',
    datetimeFullNoYear: 'D MMMM, HH:mm',
    date: 'D MMM, YYYY',
    dateNum: 'DD/MM/YYYY',
    dateOnlyDay: 'D',
    dateOnlyMonth: 'ddd',
    dateFullMonth: 'MMMM',
    dateFullNoDay: 'MMMM YYYY',
    dateFullNoYear: 'D MMMM',
    dateNoYear: 'D MMM',
    dateNoDay: 'MMM YYYY',
    dateFull: 'D MMMM, YYYY',
    time: 'HH:mm',
    iso: 'YYYY-MM-DDTHH:mm:ss',
    isoShort: 'YYYY-MM-DD',
    sameHour: '[in] mm [min]',
    calendarDatetime: {
      sameDay: '[Today], HH:mm',
      nextDay: '[Tomorrow], HH:mm',
      nextWeek: 'D MMM, HH:mm',
      lastDay: '[Yesterday], HH:mm',
      lastWeek: 'D MMM, HH:mm',
      sameElse: 'D MMM, HH:mm',
    },
    calendarDatetimeShort: {
      sameDay: 'HH:mm',
      nextDay: '[Tomorrow], HH:mm',
      nextWeek: 'D MMM, HH:mm',
      lastDay: '[Yesterday], HH:mm',
      lastWeek: 'D MMM, HH:mm',
      sameElse: 'D MMM, HH:mm',
    },
    calendarDate: {
      sameDay: '[Today]',
      nextDay: '[Tomorrow]',
      nextWeek: 'D MMM',
      lastDay: '[Yesterday]',
      lastWeek: 'D MMM',
      sameElse: 'D MMM',
    },
    calendarWithText: {
      sameDay: '[at] HH:mm',
      nextDay: '[Tomorrow], HH:mm',
      nextWeek: 'D MMM, HH:mm',
      lastDay: '[Yesterday], HH:mm',
      lastWeek: 'D MMM, HH:mm',
      sameElse: 'D MMM, HH:mm',
    },
  },
}

export function getFormatByLocale(key, locale) {
  const currentLocale = locale || moment.locale()
  return _.get(dateFormats, [currentLocale, key])
}

export function objectToIso(type, obj = {}) {
  let { year, month, day, hour, minute, second, microsecond, utcoffset } = obj
  let result = ''

  if (type === 'datetime') {
    result = moment.utc([year, month - 1, day, hour, minute, second]).utcOffset(-utcoffset)
    result = result.format(getFormatByLocale('iso')) + 'Z'
    // if (utcoffset !== 0) {
    //   console.log(`${year}-${month}-${day}T${hour}:${minute}:${second} ${utcoffset}`, result)
    // }
  } else if (type === 'date') {
    result = moment.utc([year, month - 1, day])
    result = result.format(getFormatByLocale('isoShort'))
  }

  return result
}

export function isoToObject(type, value) {
  const date = moment.utc(value).toObject()

  if (type === 'datetime') {
    return {
      _type: 'datetime',
      year: date.years,
      month: date.months + 1,
      day: date.date,
      //
      hour: date.hours,
      minute: date.minutes,
      second: date.seconds,
      microsecond: date.milliseconds,
      utcoffset: 0,
    }
  } else if (type === 'date') {
    return {
      _type: 'date',
      year: date.years,
      month: date.months + 1,
      day: date.date,
    }
  }
}

export function formatDurationSeconds(duration) {
  return Math.ceil(duration / 1000)
}

// duration in milliseconds
export function formatDuration(duration = 0, format = 'm:ss') {
  let negative = false
  duration = Number(duration)
  if (duration < 0) {
    duration = Math.abs(duration)
    negative = true
  }
  duration = Math.ceil(duration / 1000)

  let hours = Math.floor((duration / (60 * 60)) % 24)
  let minutes = Math.floor((duration / 60) % 60)
  let seconds = Math.floor(duration % 60)

  let result = []

  function _getFormatted(type, value) {
    return _.padStart(value, type.length, '0')
  }

  for (let v of format.split(':')) {
    let tmp

    v = v.toLowerCase()

    if (v === 'h' || v === 'hh') {
      tmp = _getFormatted(v, hours)
    } else if (v === 'm' || v === 'mm') {
      tmp = _getFormatted(v, minutes)
    } else if (v === 's' || v === 'ss') {
      tmp = _getFormatted(v, seconds)
    }

    result.push(tmp)
  }

  result = result.join(':')
  return negative ? `-${result}` : result
}

export function transformDateFromDb(value) {
  if (typeof value === 'string') {
    if (value.includes('$datetime:')) {
      const result = value.replace('$datetime:', '')
      return moment.utc(result)
    }

    if (value.includes('$date:')) {
      const result = value.replace('$date:', '')
      return moment.utc(result)
    }
  }

  return value
}

export function formatAsAge(date, withText = true) {
  date = transformDateFromDb(date)
  const years = moment.utc().diff(date, 'years')

  if (withText) {
    return years + i18n.t('user.yo')
  }

  return years
}

export function formatDob(date) {
  return date ? moment(date).format('L') : ''
}

export function formatDate(date, { timezone = $user.timezone, format = 'auto' } = {}) {
  date = moment(date)
  const isDate = date.creationData().format === 'YYYY-MM-DD'

  if (format === 'auto') {
    format = isDate ? 'date' : 'datetime'
  }

  // console.log(date.creationData().input, date.creationData().format, isDate, format);

  if (!isDate && timezone) {
    date = date.tz(timezone)
  }

  if (getFormatByLocale(format)) {
    format = getFormatByLocale(format)
  }

  return date.format(format)
}

export function calendar(
  date,
  { type = 'calendarDatetime', timezone = $user.timezone, relative = false } = {},
) {
  let relativeDate = null

  if (date.fullTime && _.isFunction(date.fullTime.isMoment)) {
    date = date.fullTime
  } else if (date.fullTime) {
    date = moment(date.fullTime)
  } else if (date.date && date.time) {
    date = moment(new Date(date.date + ' ' + date.time))
  } else {
    date = moment(date)

    if (relative && timezone) {
      relativeDate = moment().tz(timezone)
    }
  }

  if (timezone && relativeDate) {
    date = date.tz(timezone)
  }

  return moment(date).calendar(relativeDate, getFormatByLocale(type))
}

export const formatDateOrDash = (value, format) => {
  return value ? formatDate(value, format) : '—'
}

export function splitDate(date = '') {
  return date.split('T')[0]
}

export function getBrowserTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export function getRelativeCalendar(
  date,
  { type = 'calendarDate', timezone = $user.timezone } = {},
) {
  let now = moment.tz(timezone).startOf('day')
  let fixedDate = moment.tz(date, timezone).startOf('day')

  return fixedDate.calendar(now, getFormatByLocale(type))
}

export const formatMinutesToHoursMinutes = (minutes) => {
  const hours = {
    value: Math.floor(minutes / 60),
    label: {
      web: diffUnits[moment.locale()].h.web,
      mobile: diffUnits[moment.locale()].h.mobile,
    },
  }

  const min = {
    value: Math.ceil(minutes % 60),
    label: {
      web: diffUnits[moment.locale()].m.web,
      mobile: diffUnits[moment.locale()].m.mobile,
    },
  }

  const h = `${hours.value > 0 ? `${hours.value}${hours.label.web}` : ''}`
  const m = `${min.value > 0 ? `${min.value}${min.label.web}` : ''}`

  return { hours: h, minutes: m }
}

export const getHMDiff = (from, to) => {
  const volume = moment(to).diff(moment(from)) / 1000 / 60

  const { hours, minutes } = formatMinutesToHoursMinutes(volume)

  return _.trim(`${hours} ${minutes}`)
}

export const getInitFactTimeDiff = (start, finish, factStart, factFinish) => {
  let result
  let volume =
    Math.ceil(moment(factFinish).diff(moment(factStart)) / 1000 / 60) -
    moment(finish).diff(moment(start)) / 1000 / 60

  if (volume < 0) {
    const { hours, minutes } = formatMinutesToHoursMinutes(-volume)
    result = '-' + _.trim(`${hours} ${minutes}`)
  } else if (volume > 0) {
    const { hours, minutes } = formatMinutesToHoursMinutes(volume)
    result = '+' + _.trim(`${hours} ${minutes}`)
  } else {
    result = ''
  }

  return result
}

export const composeDateComponentsToDateString = (dateComponents, asStringAlways) => {
  const { year, month, day } = dateComponents
  let result
  if (!isAbsoluteEmpty(day)) {
    if (asStringAlways) {
      result = `${year}-${_.padStart(month + 1, 2, '0')}-${_.padStart(day, 2, '0')}`
    } else {
      result = new Date(year, month, day).toISOString()
    }
  } else {
    result = year.toString()
    if (!isAbsoluteEmpty(month)) {
      result += `-${_.padStart(month + 1, 2, '0')}`
    }
  }
  return result
}

export const decomposeDateStringToDateComponents = (dateString) => {
  let result = {}
  if (!isAbsoluteEmpty(dateString)) {
    if (dateString.length < 8) {
      // incomplete date string
      if (dateString.left === 4) {
        result.year = dateString
      } else {
        result.year = dateString.split('-')[0]
        result.month = dateString.split('-')[1]
      }
    } else {
      const tempDate = new Date(dateString)
      result.year = tempDate.getFullYear()
      result.month = tempDate.getMonth() + 1
      result.day = tempDate.getDate()
    }
  }
  return result
}

export const decodeAndFormatDateObject = (dateObj) => {
  const { dateString, isFull } = dateObj
  if (isAbsoluteEmpty(dateString)) {
    return ''
  }

  let result
  if (isFull) {
    result = formatDate(dateString, { format: 'date' })
  } else {
    if (dateString.length === 4) {
      // only a year, like 1994
      result = dateString
    } else if (dateString.length > 7) {
      result = formatDate(dateString, { format: 'date' })
    } else {
      result = formatDate(dateString, { format: 'dateNoDay' })
    }
  }
  return result
}

export function isValidDateString(str) {
  const regex = /^\d{4}-\d{2}-\d{2}(T\d{2}:\d{2}:\d{2}(?:.\d+)?(?:Z|[+-]\d{2}:\d{2})?)?$/
  return regex.test(str)
}
