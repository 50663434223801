import React, { useEffect, useRef, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import usePlacesAutocomplete from 'use-places-autocomplete'

import { TransitionWrapper } from '@library/animations/Animations'
import ClickOutside from '@library/ClickOutside'
import InputSearch from '@library/form/fields/InputSearch'
import maps from '@library/map/Map'
import SelectCountryFlag from '@library/selectCountry/SelectCountryFlag'
import BubbleLoader from '@library/ui/bubbleLoader/BubbleLoader'
import Button from '@library/ui/button/Button'

import { extendAddress } from '@helpers/addressHelper'

import { $alerts } from '@store'

import styles from './AddAddressModalSearchPlace.module.scss'

const AddAddressModalSearchPlace = (props) => {
  const { t } = useTranslation()

  const { place, country, isDetails, onChange, toggleView } = props

  const inputRef = useRef()

  const [showPopup, setShowPopup] = useState(false)

  const config = getConfig(country.value)
  const autoComplete = usePlacesAutocomplete(config)
  const { ready, value, setValue, suggestions, clearSuggestions } = autoComplete
  const [tmpValue, setTmpValue] = useState(value)

  useEffect(() => {
    let result = ''

    if (place) {
      result = extendAddress(place).address
    }

    setValue(result, false)
    setTmpValue(result)
  }, [place]) // eslint-disable-line react-hooks/exhaustive-deps

  async function selectPlace(item) {
    clearSuggestions()
    onChange({ geocoderId: item.place_id, updateHookCoords: true })
  }

  function clearInput() {
    clearSuggestions()
    inputRef.current.focus()
  }

  async function handleDetect() {
    const { coords, error } = await maps.getUserGeolocation()

    if (coords) {
      onChange({ coords, updateHookCoords: true })
    } else if (error) {
      $alerts.add(t('addresses.detect_disabled.message'), {
        title: t('addresses.detect_disabled.title'),
      })
    }
  }

  return (
    <TransitionWrapper
      items={!isDetails}
      initial={isDetails}
      from={{ opacity: 0, height: 0, marginBottom: 0 }}
      enter={{ opacity: 1, height: 'auto', marginBottom: 20 }}
      leave={{ opacity: 0, height: 0, marginBottom: 0 }}
    >
      {(item) =>
        item &&
        ((style) => (
          <div className={styles.submit} style={style}>
            <Button
              mode={['secondary', 'icon']}
              className="btn-icon--location"
              action={handleDetect}
            />
            <SelectCountryFlag country={country.value} onChange={country.onChange} />
            <InputSearch
              className={classNames(suggestions.loading && 'search--loading')}
              ref={inputRef}
              field={{ value, onChange: setValue }}
              onClear={clearInput}
              onKeyDown={() => setShowPopup(true)}
              onFocus={() => setShowPopup(true)}
              disabled={!ready}
              placeholder={maps.isDetailedPlace(place) ? tmpValue : t('add_address.ph.search')}
            >
              {showPopup && (suggestions.status !== '' || suggestions.loading) && (
                <ClickOutside className="search-popup" onClick={() => setShowPopup(false)}>
                  <div className="search-popup-scroll">
                    {suggestions.loading && (
                      <div className="search-popup-group-loader">
                        <BubbleLoader mode="lg" />
                      </div>
                    )}
                    {suggestions.data.length > 0
                      ? suggestions.data.map((item) => (
                          <div
                            key={item.place_id}
                            className="search-popup-item"
                            onClick={() => selectPlace(item)}
                          >
                            <span className="search-popup-item-title">
                              {item.structured_formatting.main_text}
                            </span>
                            <span className="search-popup-item-meta">
                              , {item.structured_formatting.secondary_text}
                            </span>
                          </div>
                        ))
                      : suggestions.status !== '' && (
                          <div className="search-popup-error">{t('errors.no_results')}</div>
                        )}
                  </div>
                </ClickOutside>
              )}
            </InputSearch>
            <Button
              tag="button"
              mode="primary"
              buttonType="submit"
              disabled={_.isEmpty(value) || _.isEmpty(tmpValue)}
              action={toggleView}
              label={t('add_address.btn.submit')}
            />
          </div>
        ))
      }
    </TransitionWrapper>
  )
}

function getConfig(selected) {
  // disabled available because maximum 5 countries
  // if (_.isEmpty(selected)) {
  //   selected = getEnabledCountries(true)
  // }

  return {
    cache: false,
    requestOptions: {
      types: ['premise', 'street_address', 'postal_code'],
      componentRestrictions: {
        country: selected,
      },
    },
    debounce: 400,
  }
}

export default observer(AddAddressModalSearchPlace)
