import React, { useEffect, useRef, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import ProgressRing from '@library/ui/progressRing/ProgressRing'

import { waitForVariable } from '@helpers/async'

import styles from './AwsCaptcha.module.scss'

const AwsCaptcha = ({
  show,
  domainList,
  apiKey,
  onLoad,
  onPuzzleCorrect,
  onSuccess,
  onError,
  className,
}) => {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(true)
  const [isSuccess, setIsSuccess] = useState(false)
  const container = useRef()

  useEffect(() => {
    async function func() {
      await waitForVariable('AwsWafCaptcha')
      const renderCaptcha = window.AwsWafCaptcha?.renderCaptcha

      if (!_.isFunction(renderCaptcha)) {
        return false
      }

      window.awsWafCookieDomainList = domainList

      renderCaptcha(container.current, {
        apiKey: apiKey,
        onLoad: () => {
          setIsLoading(false)
          if (_.isFunction(onLoad)) onLoad()
        },
        onPuzzleCorrect: () => {
          setIsSuccess(true)
          if (_.isFunction(onPuzzleCorrect)) onPuzzleCorrect()
        },
        onSuccess: (wafToken) => {
          if (_.isFunction(onSuccess)) onSuccess()
          console.log('wafToken', wafToken)
        },
        onError: (error) => {
          setIsSuccess(false)
          if (_.isFunction(onError)) onError()
          console.log('wafError', error)
        },
        // defaultLocale: i18n.language,
        dynamicWidth: true,
        skipTitle: true,
      })
    }

    if (show) {
      func()
    }
  }, [show, domainList, apiKey]) // eslint-disable-line react-hooks/exhaustive-deps

  if (!show) {
    return <></>
  }

  return (
    <div
      className={classNames(
        styles.captcha,
        isLoading && styles.isLoading,
        isSuccess && styles.captchaSuccess,
        className,
      )}
    >
      {isLoading && <ProgressRing progress={25} spin={true} radius={15} />}
      {isSuccess ? (
        <div>{t('label.success')}</div>
      ) : (
        <div id="aws-captcha-container" ref={container} />
      )}
    </div>
  )
}

export default observer(AwsCaptcha)
