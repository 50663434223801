import React, { useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import InputBase from '@library/form/fields/new/InputBase'
import InputDate from '@library/form/fields/new/InputDate'
import UploadAvatar from '@library/form/fields/UploadAvatar'
import { useForm } from '@library/form/FormControllerNew'
import Modal from '@library/modal/Modal'
import MultiSelect from '@library/selectDropdown/MultiSelect'
import Button from '@library/ui/button/Button'

import DeleteProfile from '@pages/profile/DeleteProfile'

import { ageLimit } from '@helpers/patients'

import { $alerts, $loader, $user } from '@store'

import { ENUMS_PERSON_GENDER } from '@config/enums/person'

const EditProfileModal = ({ _core }) => {
  const { t } = useTranslation()

  const [view, setView] = useState('main')

  const { profile } = $user
  const dobType = 'profile'

  const { form } = useForm({
    avatar: { value: profile.avatar, rules: [] },
    firstName: { value: profile.firstName || '', rules: ['name', 'required'] },
    lastName: { value: profile.lastName || '', rules: ['name', 'required'] },
    gender: { value: profile.gender, rules: ['gender', 'required'], prepValue: 'singleSelect' },
    dob: { value: profile.dob, rules: [{ name: 'dob', dobType }, 'required'] },
    code: { value: profile.code, rules: [] },
  })
  const { avatar, firstName, lastName, gender, dob, code } = form.fields

  const handleSubmit = $loader.registerHandler('edit-profile', async (e) => {
    e.preventDefault()

    if (form.validateAll().isValid) {
      _core.onBlock()

      const res = await $user.saveProfile(form.values)

      _core.onUnblock()

      if (res.isSuccess) {
        _core.onHide()
        $alerts.add(t('alert.profile_updated'))
      }
    }
  })

  return (
    <Modal centered scrollable>
      {view === 'deleteProfile' ? (
        <DeleteProfile onHide={_core.onHide} _core={_core} onBack={() => setView('main')} />
      ) : (
        <>
          <div className="modal-toolbar">
            <div className="modal-toolbar-btn modal-toolbar-btn--close" onClick={_core.onHide} />
          </div>

          <Modal.Header>{t('edit_profile.title')}</Modal.Header>
          <Modal.Body>
            <form id="edit_profile_form" onSubmit={handleSubmit}>
              <UploadAvatar
                user={{ ...profile, firstName: firstName.value, lastName: lastName.value }}
                field={avatar}
                value={avatar.value}
                onChange={avatar.onChange}
              />

              <div className="row row--gutters-5">
                <div className="col-6">
                  <InputBase
                    className="mb-10"
                    label={t('user.label.first_name')}
                    field={firstName}
                    mode="lg"
                  />
                </div>
                <div className="col-6">
                  <InputBase
                    className="mb-10"
                    label={t('user.label.last_name')}
                    field={lastName}
                    mode="lg"
                  />
                </div>
              </div>

              <MultiSelect
                className="mb-10"
                label={t('label.sex_assigned_at_birth')}
                items={Object.values(ENUMS_PERSON_GENDER).map((x) => ({
                  id: x.id,
                  text: t(x.tKey),
                }))}
                field={gender}
                mode="lg"
              />

              <div className="row row--gutters-5">
                <div className="col-sm-6">
                  <InputDate
                    className="mb-10"
                    minDate={ageLimit(dobType).from}
                    maxDate={ageLimit(dobType).to}
                    label={t('calendar.dob')}
                    field={dob}
                    mode="lg"
                  />
                </div>
                <div className="col-sm-6">
                  <InputBase
                    className="mb-10"
                    label={t('user.label.code')}
                    field={code}
                    mode="lg"
                  />
                </div>
              </div>

              <Button
                mode={['secondary-dark', 'block']}
                action={() => {
                  setView('deleteProfile')
                }}
                label={t('edit_profile.btn.delete_profile')}
              />
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              tag="button"
              form="edit_profile_form"
              mode={['primary', 'block']}
              isLoading={$loader.isRunHandler('edit-profile')}
            >
              {t('btn.save')}
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  )
}

export default observer(EditProfileModal)
