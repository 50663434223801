import React from 'react'

import classNames from 'classnames'

import styles from './Checkbox.module.scss'

// todo: move to core
const Checkbox = ({ className, isChecked, isMulti, isDark, isSmall }) => {
  return (
    <div
      className={classNames(styles.checkbox, className, {
        [styles.checkboxChecked]: isChecked,
        [styles.checkboxSmall]: isSmall,
        [styles.checkboxDark]: isDark,
        [styles.checkboxMulti]: isMulti,
      })}
    />
  )
}

export default Checkbox
