import moment from 'moment-timezone'

import mainConfig from '@config/main'

export const ageLimit = (type) => {
  const preset = mainConfig.ages[type] || mainConfig.ages['default']
  const currentDate = moment().format()

  let from = moment(currentDate).subtract(preset.max, 'years').format()
  let to = moment(currentDate).subtract(preset.min, 'years').format()

  return { from, to }
}
