var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import packageJson from '@root/package.json';
import { TOS_GENERAL, TOS_PROVIDER } from '@library/plugins/termsOfService/Tos.config';
import mainConfig from '@config/main';
import styles from './SidebarFooter.module.scss';
var SidebarFooter = function () {
    var t = useTranslation().t;
    return (_jsxs("div", __assign({ className: styles.wrapper }, { children: ["\u00A9", new Date().getFullYear(), " ", mainConfig.serviceCompanyName, ".", _jsx("div", { children: _jsx(Link, __assign({ to: "?".concat(TOS_GENERAL) }, { children: t('footer.tos') })) }), mainConfig.custom.tosProviderEnabled && (_jsx("div", { children: _jsx(Link, __assign({ to: "?".concat(TOS_PROVIDER) }, { children: t('footer.tos_provider') })) })), t('version', { number: packageJson.version })] })));
};
export default SidebarFooter;
