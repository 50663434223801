import React from 'react'

import PreviewAudio from '@library/media/mediaTypes/preview/Audio'
import PreviewDocumentPdf from '@library/media/mediaTypes/preview/DocumentPdf'
import PreviewImage from '@library/media/mediaTypes/preview/Image'
import PreviewUnknown from '@library/media/mediaTypes/preview/Unknown'
import PreviewVideo from '@library/media/mediaTypes/preview/Video'
import ViewerAudio from '@library/media/mediaTypes/viewer/Audio'
import ViewerDocumentPdf from '@library/media/mediaTypes/viewer/DocumentPdf'
import ViewerImage from '@library/media/mediaTypes/viewer/Image'
import ViewerUnknown from '@library/media/mediaTypes/viewer/Unknown'
import ViewerVideo from '@library/media/mediaTypes/viewer/Video'

const MediaTypes = ({ collection, content }) => {
  const {
    // common
    id,
    type,
    extension,
    effectiveDate,
    contentType,
    url,
    // image
    thumb,
    thumb3x,
    originalExtension,
    fallback,
    // video
    time,
    openInIframe,
  } = content

  const isImage = type === 'image'
  const isVideo = type === 'video'
  const isDocumentPdf = contentType === 'application/pdf'
  const isAudio = type === 'audio'

  const key = id || url + contentType

  if (collection === 'preview') {
    return (
      <>
        {isImage ? (
          <PreviewImage key={key} src={thumb} srcSet={thumb3x + ' 3x'} fallbackSrc={fallback} />
        ) : isVideo ? (
          <PreviewVideo key={key} src={url} contentType={contentType} time={time} />
        ) : isDocumentPdf ? (
          <PreviewDocumentPdf key={key} date={effectiveDate} />
        ) : isAudio ? (
          <PreviewAudio key={key} src={url} />
        ) : (
          <PreviewUnknown key={key} />
        )}
      </>
    )
  }

  if (collection === 'viewer') {
    return (
      <>
        {isImage ? (
          <ViewerImage key={key} src={url} fallbackSrc={fallback} />
        ) : isVideo ? (
          <ViewerVideo key={key} src={url} contentType={contentType} time={time} />
        ) : isDocumentPdf ? (
          <ViewerDocumentPdf key={key} src={url} openInIframe={openInIframe} />
        ) : isAudio ? (
          <ViewerAudio key={key} src={url} />
        ) : (
          <ViewerUnknown key={key} src={url} />
        )}
      </>
    )
  }
}

export default MediaTypes
