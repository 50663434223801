import React from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { getUserText } from '@helpers/user'

const PatientFooterForPrint = ({ profile }) => {
  const { t } = useTranslation()
  return (
    <div className="patient_medications_print">
      <div className="row">
        <div className="col-6">
          <p className="label">{t('ps.label.generated_by')}</p>
          <p>{getUserText(profile, ['fullName'])}</p>
        </div>
        <div className="col-6">
          <p className="label">{t('ps.label.generated_on_date')}</p>
          <p>{new Date().toLocaleDateString('en-US')}</p>
        </div>
      </div>
    </div>
  )
}

export default observer(PatientFooterForPrint)
