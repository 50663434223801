import React, { useEffect, useState } from 'react'

import { useFirstRender } from '@library/CustomHooks'

import { formatByMask } from '@helpers/text'

const InputMask = (props) => {
  const { value, onChange, mask, maskCleaner, ...passedProps } = props
  const isFirstRender = useFirstRender()

  const [innerValue, setInnerValue] = useState(value)

  useEffect(() => {
    if (isFirstRender || value !== innerValue) {
      handleOnChange({ target: { value } })
    }
  }, [value]) // eslint-disable-line react-hooks/exhaustive-deps

  function getFormatted(x) {
    const cleanValue = _.isFunction(maskCleaner) ? maskCleaner(x) : x
    return formatByMask(cleanValue, mask)
  }

  function handleOnChange(event) {
    const value = event.target.value
    const formatted = getFormatted(value)

    const position = event.target.selectionStart - 1

    if (position && value !== formatted) {
      let charPath = ''

      _.forEach(value, (char, index) => {
        if (index > position) {
          return false
        }
        if (!_.isNaN(parseInt(char))) {
          charPath += char
        }
      })

      let newPosition = position
      let formattedCharPath = ''

      _.forEach(formatted, (char, index) => {
        if (!_.isNaN(parseInt(char))) {
          formattedCharPath += char
        }
        if (charPath === formattedCharPath) {
          newPosition = index + 1
          return false
        } else if (charPath.length === formattedCharPath.length) {
          newPosition = index
          return false
        }
      })

      window.requestAnimationFrame(() => {
        event.target.selectionStart = newPosition
        event.target.selectionEnd = newPosition
      })
    }

    event.target.value = formatted
    setInnerValue(formatted)

    if (_.isFunction(onChange)) {
      onChange(event)
    }
  }

  return <input value={innerValue} onChange={handleOnChange} {...passedProps} />
}

export default InputMask
