import React from 'react'

import { autorun } from 'mobx'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'

import App from '@src/App'

import { history } from '@helpers/router'

export function initAutorun(store) {
  autorun((reaction) => {
    if (store.appIsReady) {
      ReactDOM.render(
        <React.StrictMode>
          <Router history={history}>
            <App />
          </Router>
        </React.StrictMode>,
        document.getElementById('root'),
      )
    }
  })
}
