import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import BaseVideo from '@library/media/mediaTypes/base/BaseVideo'
import ProgressRing from '@library/ui/progressRing/ProgressRing'

import styles from './Types.module.scss'

const Video = ({ src, contentType, time, onIsLoading }) => {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(true)
  const isDefault = !isLoading
  const [isError, setIsError] = useState(false)

  function changeIsLoading(value) {
    setIsLoading(value)

    if (_.isFunction(onIsLoading)) {
      onIsLoading(value)
    }
  }

  return (
    <div
      className={classNames(
        styles.video,
        isLoading && styles.isLoading,
        isDefault && styles.isDefault,
        isError && styles.isError,
      )}
    >
      <div className="embed-responsive embed-responsive-16by9">
        <BaseVideo
          src={src}
          contentType={contentType}
          time={time}
          controls={true}
          onIsLoading={changeIsLoading}
          onIsError={setIsError}
        />
      </div>
      {isDefault && isError && (
        <div className={styles.defaultContent}>
          <div className={styles.icon} />
          <div className={styles.text}>{t('errors.preview_unavailable')}</div>
        </div>
      )}
      {isLoading && <ProgressRing radius={28} progress={25} spin={true} centered={true} />}
    </div>
  )
}

export default Video
