import React from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import Badge from '@library/ui/badge/Badge'

import { PATIENT_GROUP_TYPES } from '@config/services'

const PatientGroupTypes = ({ service, mode = 'sm' }) => {
  const { t } = useTranslation()

  const patientGroupType = service.patientGroupType

  return (
    <>
      {patientGroupType === PATIENT_GROUP_TYPES.individual && (
        <Badge type={[mode, 'green']}>{t('appt_type.individual')}</Badge>
      )}
      {patientGroupType === PATIENT_GROUP_TYPES.family && (
        <>
          <Badge type={[mode, 'green']}>{t('appt_type.individual')}</Badge>
          <Badge type={[mode, 'purple']}>{t('appt_type.family')}</Badge>
        </>
      )}
      {patientGroupType === PATIENT_GROUP_TYPES.group && (
        <Badge type={[mode, 'purple']}>{t('appt_type.group')}</Badge>
      )}
    </>
  )
}

export default observer(PatientGroupTypes)
