var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import ProgressRing from '@library/ui/progressRing/ProgressRing';
import AttentionSvg from '@assets/svg/Attention';
import styles from './Document.module.scss';
var Document = function (_a) {
    var className = _a.className, title = _a.title, status = _a.status, _b = _a.type, type = _b === void 0 ? 'default' : _b, loading = _a.loading, icon = _a.icon, to = _a.to, onClick = _a.onClick, _c = _a.clickable, clickable = _c === void 0 ? !!(to || onClick) : _c;
    var Card = useCallback(function () {
        return (_jsxs("div", __assign({ className: classNames(styles.card, className, clickable && styles.cardClickable), onClick: clickable && !to && onClick ? onClick : null }, { children: [title && (_jsx("div", __assign({ className: classNames(styles.cardTitle, type === 'error' && styles.cardTitleGray) }, { children: title }))), loading && (_jsx("div", __assign({ className: styles.loader }, { children: _jsx("div", { children: _jsx(ProgressRing, { spin: true, centered: true, radius: 16, progress: 25 }) }) }))), icon && _jsx("div", __assign({ className: styles.icon }, { children: icon })), status && (_jsx("div", __assign({ className: styles.cardMeta }, { children: (function () {
                        switch (type) {
                            case 'warning':
                                return (_jsxs("div", __assign({ className: classNames(styles.status, styles.statusDefault) }, { children: [_jsx("div", { className: classNames(styles.statusPoint) }), status] })));
                            case 'error':
                                return (_jsxs("div", __assign({ className: classNames(styles.status, styles.statusError) }, { children: [_jsx(AttentionSvg, {}), status] })));
                            default:
                                return status;
                        }
                    })() }))), _jsx("div", { className: styles.cardInner })] })));
    }, [title, status, type, loading, onClick, clickable]); // eslint-disable-line react-hooks/exhaustive-deps
    return (_jsx(_Fragment, { children: to ? (_jsx(Link, __assign({ to: to, onClick: function (e) {
                if (!clickable)
                    e.preventDefault();
            } }, { children: _jsx(Card, {}) }))) : (_jsx(Card, {})) }));
};
export default observer(Document);
