export function getSelectedItems(
  preparedItems,
  selection,
  allOption,
  hasSelection,
  placeholder,
  visibleCount,
  multiSelect,
  separator,
) {
  if (allOption && selection.length === 0) {
    return allOption
  } else if (!hasSelection) {
    return placeholder
  } else {
    let idMap = {}
    let viewed

    for (let item of preparedItems) {
      for (let element of item.items) {
        idMap[element.id] = element.text
      }
    }

    if (visibleCount >= 1) {
      viewed = selection.slice(0, visibleCount)
    } else {
      viewed = selection
    }
    if (!multiSelect) {
      return idMap[selection]
    } else {
      return viewed.map((item) => idMap[item]).join(separator)
    }
  }
}

export function getPreparedItems(items, selection, setSelection, t) {
  let options
  let withGroups = false
  let undefinedList = []

  function isGroup(items) {
    return items && items[0] && 'items' in items[0]
  }

  if (isGroup(items)) {
    options = _.cloneDeep(items)
    withGroups = true
  } else {
    options = _.cloneDeep([{ title: '', items: items }])
  }

  if (selection[0] === '') {
    setSelection([])
  }

  for (let id of selection) {
    let isFound = false
    for (let option of options) {
      let result = option.items.findIndex((item) => item.id === id)
      if (result !== -1) {
        isFound = true
      }
    }
    if (!isFound) {
      undefinedList.push(id)
    }
  }
  if (undefinedList.length > 0) {
    let itemList = []

    for (let id of undefinedList) {
      itemList.push({ id: id, text: t('options.text.undefined') })
    }

    if (withGroups) {
      options.push({ title: t('options.title.unknown_group'), items: itemList })
    } else {
      options[0].items = [...options[0].items, ...itemList]
    }
  }
  return options
}

export function getFilteredItems(value, items) {
  let _value = value.toLowerCase()

  if (_value.trim() === '' || _.isEmpty(items)) {
    return items
  }
  let _items = _.cloneDeep(items)
  let result

  if (_items[0].items && _items[0].items.length) {
    if (_items.length > 1) {
      result = _items.filter((el) => el.items.some((i) => i.text.toLowerCase().includes(_value)))

      let arr = []

      result.forEach((el) => {
        arr.push({
          title: el.title,
          items: el.items.filter((i) => i.text.toLowerCase().includes(_value)),
        })
      })

      result = arr
    } else {
      result = _items.map((el) => {
        return [
          {
            title: el.title,
            items: el.items.filter((i) => i.text.toLowerCase().includes(_value)),
          },
        ]
      })[0]
    }
  }

  return result
}
