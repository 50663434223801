import AllergyDetails from '@ps/library/eventsViewer/details/Allergy'
import AssessmentDetails from '@ps/library/eventsViewer/details/Assessment'
import CarePlanDetails from '@ps/library/eventsViewer/details/CarePlan'
import ConditionDetails from '@ps/library/eventsViewer/details/Condition'
import ExaminationDetails from '@ps/library/eventsViewer/details/ExaminationDetails'
import HpiDetails from '@ps/library/eventsViewer/details/HpiDetails'
import Immunization from '@ps/library/eventsViewer/details/Immunization'
import ObservationDetails from '@ps/library/eventsViewer/details/Observation'
import ProcedureDetails from '@ps/library/eventsViewer/details/Procedure'
import ServiceRequestDetails from '@ps/library/eventsViewer/details/ServiceRequestDetails'
import AsString from '@ps/library/eventsViewer/tableDetails/AsString'
import CarePlanTableDetails from '@ps/library/eventsViewer/tableDetails/CarePlan'
import ExaminationTableDetails from '@ps/library/eventsViewer/tableDetails/ExaminationTableDetails'
import ObservationTableDetails from '@ps/library/eventsViewer/tableDetails/Observation'
import HistoryEventModal from '@ps/library/modals/HistoryEvent.modal'

export const medicalHistoryConfig = {
  medical_history_observation: {
    extractor: 'observation',
    eventModal: HistoryEventModal,
    condition: {
      and: [
        { field: 'resource', eq: 'Observation' },
        { field: 'diagnosticreportId', exists: false },
      ],
      or: [
        { field: 'subtype', eq: ['medical_history'] },
        { field: 'subtype', eq: ['medical_history', 'intake'] },
        { field: 'subtype', eq: ['medical_history', 'appointment_reason_symptom'] },
      ],
    },
    rowType: 'medical_history',
    viewer: {
      tableDetails: ObservationTableDetails,
      details: ObservationDetails,
    },
  },
  medical_history_lab_observation: {
    extractor: 'observation',
    eventModal: HistoryEventModal,
    condition: {
      and: [
        { field: 'resource', eq: 'Observation' },
        { field: 'diagnosticreportId', exists: true },
      ],
      or: [
        { field: 'subtype', eq: ['medical_history'] },
        { field: 'subtype', eq: ['medical_history', 'lab'] },
      ],
    },
    rowType: 'medical_history',
    viewer: {
      tableDetails: ObservationTableDetails,
      details: ObservationDetails,
    },
  },
  medical_history_condition: {
    extractor: 'condition',
    eventModal: HistoryEventModal,
    condition: {
      and: [{ field: 'resource', eq: 'Condition' }],
      or: [
        { field: 'subtype', eq: ['medical_history'] },
        { field: 'subtype', eq: ['medical_history', 'intake'] },
      ],
    },
    rowType: 'medical_history',
    viewer: {
      tableDetails: AsString,
      details: ConditionDetails,
    },
  },
  medical_history_allergy: {
    extractor: 'allergy',
    eventModal: HistoryEventModal,
    condition: {
      and: [{ field: 'resource', eq: 'AllergyIntolerance' }],
      or: [
        { field: 'subtype', eq: ['medical_history', 'allergy'] },
        { field: 'subtype', eq: ['medical_history', 'allergy', 'intake'] },
      ],
    },
    rowType: 'medical_history',
    viewer: {
      tableDetails: AsString,
      details: AllergyDetails,
    },
  },
  medical_history_immunization: {
    extractor: 'immunization',
    eventModal: HistoryEventModal,
    condition: {
      and: [{ field: 'resource', eq: 'Immunization' }],
      or: [
        { field: 'subtype', eq: ['medical_history', 'immunization'] },
        { field: 'subtype', eq: ['medical_history', 'immunization', 'intake'] },
      ],
    },
    rowType: 'medical_history',
    viewer: {
      tableDetails: AsString,
      details: Immunization,
    },
  },
  medical_history_assessment: {
    extractor: 'assessment',
    eventModal: HistoryEventModal,
    condition: [
      { field: 'resource', eq: 'Condition' },
      { field: 'subtype', eq: ['medical_history', 'diagnosis'] },
    ],
    rowType: 'medical_history',
    viewer: {
      tableDetails: AsString,
      details: AssessmentDetails,
    },
  },
  medical_history_procedure: {
    extractor: 'procedure',
    eventModal: HistoryEventModal,
    condition: {
      and: [{ field: 'resource', eq: 'Procedure' }],
      or: [
        { field: 'subtype', eq: ['medical_history', 'procedure'] },
        { field: 'subtype', eq: ['medical_history', 'procedure', 'intake'] },
      ],
    },
    rowType: 'medical_history',
    viewer: {
      tableDetails: AsString,
      details: ProcedureDetails,
    },
  },
  medical_history_vitals: {
    extractor: 'observation',
    eventModal: HistoryEventModal,
    condition: {
      and: [{ field: 'resource', eq: 'Observation' }],
      or: [
        { field: 'subtype', in: ['medical_history', 'note', 'vital'] },
        { field: 'subtype', in: ['medical_history', 'lab'] },
      ],
    },
    rowType: 'medical_history',
    viewer: {
      tableDetails: ObservationTableDetails,
      details: ObservationDetails,
    },
  },
  medical_history_careplan: {
    extractor: 'careplan',
    eventModal: HistoryEventModal,
    condition: [
      { field: 'resource', eq: 'CarePlan' },
      { field: 'subtype', eq: ['medical_history', 'note', 'care_plan'] },
    ],
    rowType: 'medical_history',
    viewer: {
      tableDetails: CarePlanTableDetails,
      details: CarePlanDetails,
    },
  },
  medical_history_examination: {
    extractor: 'examination',
    eventModal: HistoryEventModal,
    condition: [
      { field: 'resource', eq: 'Observation' },
      { field: 'subtype', eq: ['medical_history', 'note', 'examination'] },
    ],
    appTypes: ['provider'],
    rowType: 'medical_history',
    viewer: {
      tableDetails: ExaminationTableDetails,
      details: ExaminationDetails,
    },
  },
  medical_history_hpi: {
    extractor: 'hpi',
    eventModal: HistoryEventModal,
    condition: [
      { field: 'resource', eq: 'Observation' },
      { field: 'subtype', eq: ['medical_history', 'note', 'hpi'] },
    ],
    appTypes: ['provider'],
    rowType: 'medical_history',
    viewer: {
      tableDetails: AsString,
      details: HpiDetails,
    },
  }, // medical_history_media: {
  //   extractor: 'media',
  //   eventModal: BaseDocumentsModal,
  //   condition: [
  //     { field: 'resource', eq: 'Media' },
  //     { field: 'subtype', in: ['medical_history', 'media'] },
  //   ],
  //   rowType: 'medical_history',
  //   viewer: {
  //     tableDetails: AsString,
  //     details: MediaDetails,
  //   },
  // },
  medical_history_service_request: {
    extractor: 'service_request',
    eventModal: HistoryEventModal,
    condition: [{ field: 'resource', eq: 'ServiceRequest' }],
    rowType: 'medical_history',
    viewer: {
      tableDetails: AsString,
      details: ServiceRequestDetails,
    },
  },
}
