export var apptDocumentsFormList = [
    {
        id: 'healthRiskAssessment',
        label: 'appt_documents.fill_out_forms.label.health_risk_assessment',
        staging: {
            18: {
                type: 'openBlank',
                link: 'https://form.jotform.com/231347800563049',
            },
        },
        production: {
            '1_2': {
                type: 'openBlank',
                link: 'https://form.jotform.com/231347800563049',
            },
        },
    },
    {
        id: 'patientHealthQuestionnaire9',
        label: 'appt_documents.fill_out_forms.label.patient_health_questionnaire_9',
        staging: {
            18: {
                type: 'openBlank',
                link: 'https://form.jotform.com/231347627318054',
            },
        },
        production: {
            '1_2': {
                type: 'openBlank',
                link: 'https://form.jotform.com/231347627318054',
            },
        },
    },
    {
        id: 'generalizedAnxietyDisorder7',
        label: 'appt_documents.fill_out_forms.label.generalized_anxiety_disorder_7',
        staging: {
            18: {
                type: 'openBlank',
                link: 'https://form.jotform.com/231347668204053',
            },
        },
        production: {
            '1_2': {
                type: 'openBlank',
                link: 'https://form.jotform.com/231347668204053',
            },
        },
    },
    {
        id: 'functionalRiskAssessment',
        label: 'appt_documents.fill_out_forms.label.functional_risk_assessment',
        staging: {
            18: {
                type: 'openBlank',
                link: 'https://form.jotform.com/230678702997168',
            },
        },
        production: {
            '1_2': {
                type: 'openBlank',
                link: 'https://form.jotform.com/230678702997168',
            },
        },
    },
    {
        id: 'painAssessment',
        label: 'appt_documents.fill_out_forms.label.pain_assessment',
        staging: {
            18: {
                type: 'openBlank',
                link: 'https://form.jotform.com/231348022147044',
            },
        },
        production: {
            '1_2': {
                type: 'openBlank',
                link: 'https://form.jotform.com/231348022147044',
            },
        },
    },
    {
        id: 'painAssessmentNonVerbal',
        label: 'appt_documents.fill_out_forms.label.pain_assessment_non_verbal',
        staging: {
            18: {
                type: 'openBlank',
                link: 'https://form.jotform.com/231347724915055',
            },
        },
        production: {
            '1_2': {
                type: 'openBlank',
                link: 'https://form.jotform.com/231347724915055',
            },
        },
    },
    {
        id: 'fallRiskAssessment',
        label: 'appt_documents.fill_out_forms.label.fall_risk_assessment',
        staging: {
            18: {
                type: 'openBlank',
                link: 'https://form.jotform.com/231348504867058',
            },
        },
        production: {
            '1_2': {
                type: 'openBlank',
                link: 'https://form.jotform.com/231348504867058',
            },
        },
    },
    {
        id: 'urinaryIncontinenceAssessment',
        label: 'appt_documents.fill_out_forms.label.urinary_incontinence_assessment',
        staging: {
            18: {
                type: 'openBlank',
                link: 'https://form.jotform.com/231347663214049',
            },
        },
        production: {
            '1_2': {
                type: 'openBlank',
                link: 'https://form.jotform.com/231347663214049',
            },
        },
    },
    {
        id: 'socialNeedsScreening',
        label: 'appt_documents.fill_out_forms.label.social_needs_screening',
        staging: {
            18: {
                type: 'openBlank',
                link: 'https://form.jotform.com/231348467284059',
            },
        },
        production: {
            '1_2': {
                type: 'openBlank',
                link: 'https://form.jotform.com/231348467284059',
            },
        },
    },
    {
        id: 'auditAssessment',
        label: 'appt_documents.fill_out_forms.label.audit_assessment',
        staging: {
            18: {
                type: 'openBlank',
                link: 'https://form.jotform.com/231348076608055',
            },
        },
        production: {
            '1_2': {
                type: 'openBlank',
                link: 'https://form.jotform.com/231348076608055',
            },
        },
    },
    {
        id: 'drugAssessmentScreeningTest10',
        label: 'appt_documents.fill_out_forms.label.drug_assessment_screening_test_10',
        staging: {
            18: {
                type: 'openBlank',
                link: 'https://form.jotform.com/231348586431055',
            },
        },
        production: {
            '1_2': {
                type: 'openBlank',
                link: 'https://form.jotform.com/231348586431055',
            },
        },
    },
    {
        id: 'healthScreeningFormPneumococcal',
        label: 'appt_documents.fill_out_forms.label.health_screening_form_pneumococcal',
        staging: {
            18: {
                type: 'openBlank',
                link: 'https://hipaa.jotform.com/223467897698078',
            },
        },
        production: {
            '1_2': {
                type: 'openBlank',
                link: 'https://hipaa.jotform.com/223467897698078',
            },
        },
    },
    {
        id: 'healthScreeningFormInfluenza',
        label: 'appt_documents.fill_out_forms.label.health_screening_form_influenza',
        staging: {
            18: {
                type: 'openBlank',
                link: 'https://hipaa.jotform.com/223466229758063',
            },
        },
        production: {
            '1_2': {
                type: 'openBlank',
                link: 'https://hipaa.jotform.com/223466229758063',
            },
        },
    },
    {
        id: 'miniMentalStatusExamForm',
        label: 'appt_documents.fill_out_forms.label.mini_mental_status_exam_form',
        staging: {
            18: {
                type: 'openBlank',
                link: 'https://form.jotform.com/231348432183049',
            },
        },
        production: {
            '1_2': {
                type: 'openBlank',
                link: 'https://form.jotform.com/231348432183049',
            },
        },
    },
    {
        id: 'medicationReconciliationForm',
        label: 'appt_documents.fill_out_forms.label.medication_reconciliation_form',
        staging: {
            18: {
                type: 'openBlank',
                link: 'https://form.jotform.com/231348025771050',
            },
        },
        production: {
            '1_2': {
                type: 'openBlank',
                link: 'https://form.jotform.com/231348025771050',
            },
        },
    },
    {
        id: 'cognitionAndMemoryAssessmentCognitiveImpairmentScreening',
        label: 'appt_documents.fill_out_forms.label.cognition_and_memory_assessment.cognitive_impairment_screening',
        staging: {
            18: {
                type: 'openBlank',
                link: 'https://form.jotform.com/231347823211044',
            },
        },
        production: {
            '1_2': {
                type: 'openBlank',
                link: 'https://form.jotform.com/231347823211044',
            },
        },
    },
    {
        id: 'medicalInformationReleaseForm',
        label: 'appt_documents.fill_out_forms.label.medical_information_release_form',
        dev: {
            any: {
                type: 'openBlank',
                link: 'https://hipaa.jotform.com/223558099718066',
            },
        },
        staging: {
            18: {
                type: 'openBlank',
                link: 'https://hipaa.jotform.com/223558099718066',
            },
        },
        production: {
            '1_2': {
                type: 'openBlank',
                link: 'https://hipaa.jotform.com/223558099718066',
            },
        },
    },
    {
        id: 'reviewOfSystem',
        label: 'appt_documents.fill_out_forms.label.review_of_system',
        staging: {
            18: {
                type: 'openBlank',
                link: 'https://form.jotform.com/231430114456142',
            },
        },
        production: {
            '1_2': {
                type: 'openBlank',
                link: 'https://form.jotform.com/231430114456142',
            },
        },
    },
    {
        id: 'hearingTest',
        label: 'appt_documents.fill_out_forms.label.hearing_test',
        staging: {
            18: {
                type: 'openBlank',
                link: 'https://form.jotform.com/230678117358159',
            },
        },
        production: {
            '1_2': {
                type: 'openBlank',
                link: 'https://form.jotform.com/230678117358159',
            },
        },
    },
];
