import React from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import Duration from '@library/Duration'
import Button from '@library/ui/button/Button'

import styles from '@pages/appt/patient/EncounterControl.module.scss'

const EncounterControl = ({ encounter }) => {
  const { t } = useTranslation()

  const isCancelled = encounter.cancelTime
  const isInProgress = encounter.startTime && !encounter.finishTime && !encounter.cancelTime
  const isFinished = encounter.finishTime

  const isVisible = isCancelled || isInProgress || isFinished

  return (
    <div
      className={classNames(styles.encountersControl, isVisible && styles.encountersControlVisible)}
    >
      {isCancelled && (
        <Button mode={['secondary-dark']} tag="button" disabled={true}>
          {t('appt.encounter.status.canceled')}
          {encounter.startTime && (
            <>
              &nbsp;
              <Duration
                from={encounter.startTime || encounter.cancelTime}
                to={encounter.cancelTime}
              />
            </>
          )}
        </Button>
      )}
      {isInProgress && (
        <Button mode={['secondary-dark']} tag="button" disabled={true}>
          {t('appt.encounter.status.in_progress')}&nbsp;
          <Duration from={encounter.startTime} />
        </Button>
      )}
      {isFinished && (
        <Button mode={['secondary-dark']} tag="button" disabled={true}>
          {t('appt.encounter.status.completed')}&nbsp;
          <Duration from={encounter.startTime} to={encounter.finishTime} />
        </Button>
      )}
    </div>
  )
}

export default observer(EncounterControl)
