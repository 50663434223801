var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import UserPhoto from '@library/user/UserPhoto';
import { getUserText } from '@helpers/user';
import styles from './PatientsListItem.module.scss';
var PatientsListItem = function (_a) {
    var item = _a.item, hoverText = _a.hoverText, innerClassNames = _a.innerClassNames;
    var t = useTranslation().t;
    return (_jsxs(_Fragment, { children: [_jsx(UserPhoto, { item: item }), _jsxs("div", __assign({ className: styles.user }, { children: [_jsxs("div", __assign({ className: styles.userTitle }, { children: [getUserText(item, ['fullName']), item.isDefault && " (".concat(t('user.you'), ")")] })), _jsx("div", __assign({ className: styles.userMeta }, { children: getUserText(item, ['gender', 'yo']) }))] })), _jsx("div", __assign({ className: classNames(styles.userSelect, innerClassNames) }, { children: hoverText }))] }));
};
export default PatientsListItem;
