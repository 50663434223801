import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import Modal from '@library/modal/Modal'
import Button from '@library/ui/button/Button'
import Checkbox from '@library/ui/checkbox/Checkbox'

import { formatAmount } from '@helpers/text'

import styles from './SelectPractice.modal.module.scss'

const SelectPracticeModal = ({ _core, practices, onSelect }) => {
  const { t } = useTranslation()
  const [selected, setSelected] = useState(null)

  const handleClick = () => {
    onSelect(selected)
    _core.onHide()
  }

  return (
    <Modal scrollable centered _core={_core}>
      <div className="modal-toolbar">
        <div className="modal-toolbar-btn modal-toolbar-btn--close" onClick={_core.onHide} />
      </div>
      <Modal.Header>
        <div className="title-md mb-0">{t('book.select_practice')}</div>
      </Modal.Header>
      <Modal.Body>
        {practices.map(
          (item) => (
            <div className={styles.wrapper} key={item.practiceId}>
              <div className={styles.body} onClick={() => setSelected(item.practiceId)}>
                <Checkbox
                  className={styles.checkbox}
                  isChecked={selected === item.practiceId}
                  isDark={true}
                />
                <div className={styles.content}>
                  <div className={styles.title}>
                    <div className={styles.titleText}>{item.practiceName}</div>
                    <div className={styles.titlePrice}>
                      {formatAmount(item.price, item.currency)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ),
          [],
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          tag="button"
          buttonType="button"
          mode={['primary', 'block']}
          label={t('btn.continue')}
          disabled={selected === null}
          action={handleClick}
        />
      </Modal.Footer>
    </Modal>
  )
}

export default SelectPracticeModal
