import React from 'react'

import BookingAddress from '@booking/pages/bookingAddress/BookingAddress'
import BookingError from '@booking/pages/bookingError/BookingError'
import BookingServiceType from '@booking/pages/bookingServiceType/BookingServiceType'

import DefaultLayout from '@src/layouts/default/Default'

import Appts from '@pages/Appts'
import LoginFull from '@pages/auth/LoginFull'

import { $main } from '@store'

import mainConfig from '@config/main'

const env = process.env.REACT_APP_ENV

/*
 * Expand list of routes
 */
const extraList = [
  {
    name: 'home',
    private: true,
    element: <Appts />,
    meta: {
      hasBurger: true,
    },
    onLoad: null,
  },
  {
    name: 'bookServiceType',
    path: '/book/start',
    layout: <DefaultLayout />,
    element: <BookingServiceType />,
    private: true,
    errorElement: <BookingError />,
    onLoad: () => {
      if (!$main.initExtraData?.patientId || !$main.initExtraData?.serviceIds) {
        return false
      }
    },
    sidebar: false,
  },
  {
    name: 'bookAddress',
    path: '/book/address',
    layout: <DefaultLayout />,
    element: <BookingAddress />,
    private: true,
    // errorElement: <BookingError />,
    // onLoad: () => {
    //   if (!$main.initExtraData?.patientId || !$main.initExtraData?.serviceIds) {
    //     return false
    //   }
    // },
    sidebar: false,
  },
  {
    name: 'bookPatients',
    sidebar: false,
  },
  {
    name: 'bookSymptoms',
    sidebar: false,
  },
  {
    name: 'bookDate',
    sidebar: false,
  },
  {
    name: 'bookDate',
    sidebar: false,
  },
  {
    name: 'bookFinish',
    sidebar: false,
  },
  {
    name: 'profile',
    isRemoved: true,
  },
  {
    name: 'pms',
    isRemoved: true,
  },
  {
    name: 'signout',
    isRemoved: true,
  },
]

if (['dev', 'local'].includes(env) && mainConfig.unAuthPage.type === 'route') {
  extraList.push({
    name: 'login',
    path: '/login',
    layout: <DefaultLayout />,
    element: <LoginFull />,
    exact: true,
    sidebar: false,
    meta: {
      fullHeight: true,
    },
  })
}

export const routesExtraList = extraList
