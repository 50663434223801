import { makeAutoObservable, runInAction } from 'mobx'

import bookingSelectSymptomsConfig from '@booking/pages/bookingSymptoms/bookingSelectSymptoms/BookingSelectSymptoms.config'
import i18n from 'i18next'
import moment from 'moment-timezone'

import clientService from '@services/client'

import { $main } from '@store'

const localizedCategories = (categories, names) => {
  function getCategoryByKey(id) {
    const list = bookingSelectSymptomsConfig.symptomsCategories
    const undefinedName = _.get(names, id, i18n.t('symptoms.errors.undefined'))

    let preparedList = []

    for (const key in list) {
      preparedList.push({
        keys: list[key],
        value: i18n.t('symptoms.categories.' + key),
      })
    }
    const res = preparedList.find((item) => item.keys.indexOf(id) !== -1)

    return (res && res.value) || undefinedName
  }

  const tmp = categories
    .map((item) => {
      return { ...item, category: getCategoryByKey(item.category) }
    })
    .sort((a, b) => a.category.localeCompare(b.category))

  const result = []

  _.forEach(tmp, (el) => {
    let index = result.findIndex((x) => x.category === el.category)

    if (index === -1) {
      result.push({ category: el.category, symptoms: [] })
      index = result.length - 1
    }

    result[index].symptoms = [...result[index].symptoms, ...el.symptoms]
  })

  return result.map((item) => {
    const symptoms = item.symptoms.sort((a, b) => a.name.localeCompare(b.name))
    return { ...item, symptoms }
  })
}

const getCleanCategories = (symptoms) => {
  const result = []

  _.forEach(symptoms, (symptom) => {
    let index = result.findIndex((x) => x.category === symptom.category)

    if (index === -1) {
      result.push({ category: symptom.category, symptoms: [] })
      index = result.length - 1
    }

    result[index].symptoms.push(symptom)
  })

  return result
}

class BookingSymptomsStore {
  _rootStore

  selectedIds = []
  answers = {}

  all = []
  #allCacheDate = null
  isLoading = true

  constructor(store) {
    makeAutoObservable(this)
    this._rootStore = store
  }

  // CLEAR
  clear = () => {
    this.selectedIds = []
    this.answers = {}
  }

  // MUTATIONS
  SET_SELECTED = (id) => {
    const ids = _.isArray(id) ? id : [id]
    this.selectedIds = _.uniq([...this.selectedIds, ...ids])
  }

  REMOVE_SELECTED = (id) => {
    const ids = _.isArray(id) ? id : [id]
    this.selectedIds = _.filter(this.selectedIds, (item) => !ids.includes(item))
  }

  TOGGLE_SELECTED = (id) => {
    const ids = _.isArray(id) ? id : [id]
    ids.forEach((item) => {
      if (this.isSelected(item)) {
        this.REMOVE_SELECTED(item)
      } else {
        this.SET_SELECTED(item)
      }
    })
  }

  SET_ANSWERS = (answers) => {
    this.answers = answers
  }

  // ACTIONS
  load = async () => {
    if (this.all.length && moment().diff(this.#allCacheDate, 'seconds') < 900) {
      return this.all
    }

    if (!this.isLoading) {
      this.isLoading = true
    }

    const response = await Promise.all([$main.loadSettings(), clientService.loadSymptoms()])

    runInAction(() => {
      const result = response[1]?.prepared?.symptoms.items.filter((item) => item.practiceIds.length)
      if (result) {
        this.all = result
        this.#allCacheDate = new Date().toISOString()
      }
      this.isLoading = false
    })

    return this.all
  }

  // COMPUTED
  isSelected = (id) => {
    const ids = _.isArray(id) ? id : [id]

    for (const item of ids) {
      if (!this.selectedIds.includes(item)) {
        return false
      }
    }
    return true
  }

  get categoryNames() {
    const categories = $main.settings.symptomCategories
    return _.mapKeys(categories, (value, key) => _.snakeCase(key))
  }

  get allAvailablePracticeIds() {
    return _.uniq(this.all.reduce((acc, x) => acc.concat(x.practiceIds), []))
  }

  get common() {
    return bookingSelectSymptomsConfig.commonSymptoms
      .map((item) => {
        if (_.isArray(item.id)) {
          let result = true
          item.id.forEach((id) => {
            if (!this.filteredSymptomsByPractices.some((symptom) => symptom.id === id)) {
              result = false
            }
          })
          return result && item
        }
        return this.filteredSymptomsByPractices.some((symptom) => symptom.id === item.id) && item
      })
      .filter((item) => item)
  }

  get categorized() {
    const cleanCategories = getCleanCategories(this.filteredSymptomsByPractices)
    return localizedCategories(cleanCategories, this.categoryNames)
  }

  get practiceIds() {
    return this._rootStore.availablePracticeIds
  }

  get filteredSymptomsByPractices() {
    return this.all.filter((item) => item.practiceIds.some((id) => this.practiceIds.includes(id)))
  }

  get hasSelectedSymptomsInPractices() {
    if (!this.practiceIds.length) {
      return false
    }

    return this.selectedIds.every((id) =>
      this.filteredSymptomsByPractices.some((item) => item.id === id),
    )
  }

  getSymptomName = (id) => {
    const ids = _.isArray(id) ? id : [id]

    const result = this.common.find((item) => {
      const idList = _.isArray(item.id) ? item.id : [item.id]
      return !_.pullAll(idList.slice(), ids).length
    })

    if (result) {
      return result.name
    }

    return this.all
      .filter((item) => ids.indexOf(item.id) !== -1)
      .map((item) => item.name)
      .join(', ')
  }

  get combinedSelectedSymptomsIds() {
    if (!this.selectedIds.length) {
      return []
    }
    const selectedIds = [...this.selectedIds]
    const result = []
    const skipList = []

    _.forEachRight(selectedIds, (current) => {
      if (skipList.indexOf(current) !== -1) {
        return
      }

      let done = false

      this.common
        .filter((item) => item.id.indexOf(current) !== -1)
        .sort((a, b) => a.id.length - b.id.length)
        .forEach((item) => {
          if (
            _.pullAll(item.id.slice(), skipList).length === item.id.length &&
            !_.pullAll(item.id.slice(), selectedIds).length
          ) {
            skipList.push(...item.id)
            result.push(item.id)
            done = true
            return false
          }
        })

      if (!done) {
        result.push(current)
      }
    })

    return result.reverse()
  }
}

export default BookingSymptomsStore
