import React from 'react'

const ArrowIcon = ({ color = '#000000' }) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5858 19.5858C10.1953 19.9763 10.1953 20.6095 10.5858 21C10.9763 21.3905 11.6095 21.3905 12 21L18.7071 14.2929L12 7.5858C11.6095 7.19528 10.9763 7.19528 10.5858 7.5858C10.1953 7.97633 10.1953 8.60949 10.5858 9.00001L15.8787 14.2929L10.5858 19.5858Z"
        fill={color}
      />
    </svg>
  )
}

export default ArrowIcon
