import { makeObservable, observable, runInAction } from 'mobx'

class GlobalStore {
  constructor() {
    makeObservable(this, {
      appIsReady: observable,
      translationsReady: observable,
    })
  }

  appIsReady = false
  translationsReady = false

  setAppIsReady(value) {
    runInAction(() => (this.appIsReady = value))
  }

  setTranslationsReady(value) {
    runInAction(() => (this.translationsReady = value))
  }
}

const store = new GlobalStore()
export default store
