var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@library/ui/button/Button';
import { getUserText } from '@helpers/user';
import { $main } from '@store';
import Exit from '@assets/svg/Exit';
import styles from './NotesBar.module.scss';
var NotesBar = function (_a) {
    var patient = _a.patient, appointment = _a.appointment, encounter = _a.encounter, sidebarDisabled = _a.sidebarDisabled;
    var t = useTranslation().t;
    return (_jsxs("div", __assign({ className: styles.NotesBar }, { children: [_jsxs("div", __assign({ className: styles.InfoBlock }, { children: [_jsx("p", __assign({ className: styles.PatientInfo }, { children: "".concat(getUserText(patient, [
                            'fullName',
                            'genderShort',
                        ]), " ").concat(getUserText(patient, ['yo'])) })), _jsxs("p", __assign({ className: styles.ApptInfo }, { children: [t('label.appointment'), "\u00A0", appointment.id] }))] })), _jsxs(Button, __assign({ className: styles.InitNotesBtn, tag: "button", mode: 'primary', disabled: sidebarDisabled, action: function () {
                    return $main.showRightSidebar('notes', {
                        encounterId: encounter.id,
                        appointmentId: appointment.id,
                        practiceId: appointment.practiceId,
                    });
                } }, { children: [t('notes.label.fill_out'), " ", _jsx(Exit, { color: sidebarDisabled ? '#d1d1d1' : '#fff' })] }))] })));
};
export default NotesBar;
