import { toJS } from 'mobx'

import moment from 'moment-timezone'

import { findByCondition } from '@helpers/other'
import { splitDate } from '@helpers/time'

import { eventsConfig } from '@ps/config/events'

import { $modal } from '@store'

import mainConfig from '@config/main'

export function prepareEvents(_items = []) {
  let result = []

  _.forEach(_items, (item) => {
    let configs = findByCondition(item, eventsConfig)

    if (_.isEmpty(configs)) {
      console.warn('UNKNOWN EVENT:', toJS(item))
    }

    configs = configs.filter((x) => {
      if (x.appTypes) {
        return x.appTypes.includes(mainConfig.appType)
      }
      return true
    })

    let resourceProjection

    if (item.resourceProjection) {
      resourceProjection = prepareEventsResourceProjection(item)
    }

    _.forEach(configs, (config) => {
      const EXCLUDED_LIST = ['appTypes', 'condition', 'viewer']
      config = _.pickBy(config, (x, key) => !EXCLUDED_LIST.includes(key))

      result.push({
        ...item,
        ...config,
        resourceProjection,
        isSelfReported:
          _.includes(item.subtype, 'intake') ||
          _.includes(item.subtype, 'appointment_reason_symptom'),
      })
    })
  })

  return result
}

export function prepareEventsResourceProjection(eventItem = {}) {
  const rules = {
    identifier: 'list',
    extension: 'list',
    DiagnosticReport: {
      result: 'list',
    },
  }
  const defaultRule = 'first'

  return _.mapValues(eventItem.resourceProjection, (x, key) => {
    const rule = _.get(rules, [eventItem.resource, key]) || _.get(rules, [key]) || defaultRule

    if (rule === 'first') {
      return _.first(x)
    }

    return x
  })
}

export function getSubcategoriesByEventConfigId(configId, schema = {}) {
  let flatSchema = {}

  _.forEach(schema, (category) => {
    _.forEach(category.subcategories, (x) => {
      flatSchema[x.id] = x
    })
  })

  let result = []

  _.forEach(flatSchema, (x) => {
    if (_.isEmpty(x.items) || x.items.includes(configId)) {
      result.push(x)
    }
  })

  return result
}

export function groupEventsBySubcategory(events = [], schema = {}) {
  let flatSchema = {}
  let result = {}

  // making results obj like
  // {
  //     labs_all: [],
  //     appointments_all: [],
  //   ...
  // }
  _.forEach(schema, (category) => {
    _.forEach(category.subcategories, (x) => {
      flatSchema[x.id] = x
      result[x.id] = []
    })
  })

  // filling results with events by subcats
  _.forEach(events, (event) => {
    _.forEach(flatSchema, (x) => {
      if (_.isEmpty(x.items) || x.items.includes(event.configId)) {
        result[x.id].push(event)
      }
    })
  })

  return result
}

export function getEventsMinMaxDate(items = []) {
  let min, max

  items = _.orderBy(items, (x) => x.effectiveDate, 'desc')

  const minItem = _.last(items)
  const maxItem = _.first(items)

  if (minItem) {
    min = minItem.effectiveDate
  }

  if (maxItem) {
    max = maxItem.effectiveDate
  }

  if (!min && !max) {
    min = moment().add(1, 'day').toISOString()
    max = moment().add(-1, 'day').toISOString()
  }

  return { minDate: min, maxDate: max }
}

export function openEventModal(items = [], { baseItem = {}, ...passedProps } = {}) {
  if (_.isEmpty(baseItem)) {
    baseItem = _.first(items) || {}
  }

  $modal.add(baseItem.event.eventModal, {
    baseItem,
    items,
    ...passedProps,
  })
}

export function sortEventsBy(events, condition) {
  if (condition === 'date_desc') {
    events = _.orderBy(events, 'effectiveDate', 'desc')
  } else if (condition === 'date_asc') {
    events = _.orderBy(events, 'effectiveDate', 'asc')
  }

  return events
}
