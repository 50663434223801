import React from 'react'

import classNames from 'classnames'

import { firstLetters, splitName } from '@helpers/text'

import logoSimpleWhite from '@assets/images/logo-simple-white.svg'

import styles from './PracticeIcon.module.scss'

const PracticeIcon = ({
  imageUrl,
  name,
  isActive = false,
  isHoverable = false,
  wrapperClassName = '',
}) => {
  return (
    <div
      className={classNames(
        'practice_icon',
        styles.icon,
        isActive && styles.isActive,
        isHoverable && styles.isHoverable,
        wrapperClassName,
      )}
    >
      {imageUrl ? (
        <div className={classNames(styles.inner)}>
          <img src={imageUrl} alt="" />
        </div>
      ) : name ? (
        <div className={classNames(styles.inner, styles.firstLetters)}>
          {firstLetters(splitName(name, 'consistently'))}
        </div>
      ) : (
        <div className={classNames(styles.inner, styles.empty)}>
          <img src={logoSimpleWhite} alt="" />
        </div>
      )}
    </div>
  )
}

export default PracticeIcon
