import axios from 'axios'

export function openInNewTab(url) {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')

  if (newWindow) {
    newWindow.opener = null
  }
}

export function callToPhone(number) {
  window.location = 'tel:' + number
}

export function getFileNameFromUrl(url) {
  if (url) {
    var m = url.toString().match(/.*\/(.+?)\./)
    if (m && m.length > 1) {
      return m[1]
    }
  }
  return ''
}

export function nativeScrollTo(id = '', params = {}) {
  if (id) {
    const el = document.querySelector('#' + id)

    if (el) {
      el.scrollIntoView(params)
    }
  }
}

export async function fetchBlob(url) {
  return await axios.get(url, { responseType: 'blob' })
}

export async function downloadBy({ url = '', blob, prefetch = false, fileName = 'filename' }) {
  if (url && prefetch) {
    try {
      const response = await fetchBlob(url)
      blob = response.data
    } catch (e) {}
  }

  if (blob instanceof Blob) {
    url = URL.createObjectURL(blob)
  }

  const link = document.createElement('a')

  link.href = url
  link.download = fileName

  document.body.appendChild(link)

  link.dispatchEvent(
    new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
      view: window,
    }),
  )

  document.body.removeChild(link)
  URL.revokeObjectURL(url)
}

export function removeHttp(url) {
  return _.replace(url, /^https?:\/\//, '')
}

export function isUrl(url) {
  return url.startsWith('http')
}

export function loadScript(src, onLoad = () => {}) {
  let script = document.createElement('script')
  script.setAttribute('src', src)
  script.onload = onLoad
  document.head.appendChild(script)
}
