import React from 'react'

import { useTranslation } from 'react-i18next'

const CarePlan = ({ event, resource, extractor }) => {
  const { t } = useTranslation()
  const { text, valuesAsString } = extractor

  return (
    <>
      {text}
      {text && valuesAsString && <br />}
      {valuesAsString && (
        <>
          {t('ps.event_viewer.note:')} {valuesAsString}
        </>
      )}
    </>
  )
}

export default CarePlan
