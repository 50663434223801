import React from 'react'

import { useTranslation } from 'react-i18next'

import { formatDate } from '@helpers/time'

import RoleName from '@ps/library/RoleName'
import ViewCode from '@ps/library/viewCode/ViewCode'

const Procedure = ({ event, resource, extractor }) => {
  const { t } = useTranslation()
  const { text, valuesAsString, codeObj, date, subject = {}, recorder = {} } = extractor

  return (
    <div className="event_details">
      <div className="event_details-header">
        <div className="event_details-title">
          {text}
          {event.isSelfReported && (
            <span className="badge-self_reported">{t('ps.label.self_reported')}</span>
          )}
        </div>
        <div className="event_details-code">
          <ViewCode codeObj={codeObj} />
        </div>
      </div>
      <div className="event_details-body">
        {valuesAsString && <div className="event_details-line mb-10">{valuesAsString}</div>}

        {date.formatted && (
          <div className="event_details-line">
            {t('ps.event_viewer.procedure_date:')} {date.formatted}
          </div>
        )}

        <div className="event_details-line">
          {t('ps.event_viewer.subject:')} {subject.value.fullName}
        </div>
        {/*<div className="event_details-line">*/}
        {/*  {t('ps.event_viewer.asserter')} {asserter.value.fullName}{' '}*/}
        {/*  <RoleName role={asserter.role} resource={asserter.value} />*/}
        {/*</div>*/}
        {!_.isEmpty(recorder.value) && (
          <div className="event_details-line">
            {t('ps.event_viewer.recorder:')} {recorder.value.fullName}{' '}
            <RoleName role={recorder.role} resource={recorder.value} />
          </div>
        )}
        <div className="event_details-line event_details-line--date">
          {formatDate(event.effectiveDate, { format: 'date' })}
        </div>
      </div>
    </div>
  )
}

export default Procedure
