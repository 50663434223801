var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import _ from 'lodash';
import { analytics } from '@common/analytics/zipAnalytics';
import BubbleLoader from '@library/ui/bubbleLoader/BubbleLoader';
import './Button.scss';
var Button = function (_a) {
    var _b = _a.tag, tag = _b === void 0 ? 'div' : _b, mode = _a.mode, className = _a.className, label = _a.label, buttonType = _a.buttonType, disabled = _a.disabled, form = _a.form, isLoading = _a.isLoading, children = _a.children, autoTarget = _a.autoTarget, _c = _a.to, to = _c === void 0 ? autoTarget === null || autoTarget === void 0 ? void 0 : autoTarget.to : _c, _d = _a.href, href = _d === void 0 ? autoTarget === null || autoTarget === void 0 ? void 0 : autoTarget.href : _d, _e = _a.wvAction, wvAction = _e === void 0 ? autoTarget === null || autoTarget === void 0 ? void 0 : autoTarget.wvAction : _e, action = _a.action;
    function handleOnClick(e) {
        if (isLoading) {
            e.preventDefault();
            return;
        }
        if (_.isFunction(wvAction)) {
            wvAction(e);
        }
        if (_.isFunction(action)) {
            action(e);
            analytics.breadcrumb('input-click', {
                tag: tag,
                source: action.name,
                class: className,
                form: form,
                label: label,
            });
        }
    }
    var preparedType = _.isArray(mode) ? mode : [mode];
    var buttonClassNames = useMemo(function () {
        var result = [
            'btn',
            preparedType.length > 0 && preparedType.map(function (x) { return x && 'btn-' + x; }).join(' '),
        ];
        if (isLoading) {
            result.push('progress_button', 'progress_button--active');
        }
        return result;
    }, [preparedType, isLoading]);
    return (_jsx(_Fragment, { children: tag === 'a' || href ? (_jsxs("a", __assign({ href: href, onClick: handleOnClick, className: classNames(buttonClassNames, className) }, { children: [_jsx(ButtonLoader, { show: isLoading }), children || label] }))) : tag === 'link' || to ? (_jsxs(Link, __assign({ to: to, onClick: handleOnClick, className: classNames(buttonClassNames, className) }, { children: [_jsx(ButtonLoader, { show: isLoading }), children || label] }))) : tag === 'button' ? (_jsxs("button", __assign({ type: buttonType, form: form, disabled: disabled, onClick: handleOnClick, className: classNames(buttonClassNames, className) }, { children: [_jsx(ButtonLoader, { show: isLoading }), children || label] }))) : (_jsxs("div", __assign({ onClick: handleOnClick, className: classNames(buttonClassNames, className) }, { children: [_jsx(ButtonLoader, { show: isLoading }), children || label] }))) }));
};
export default Button;
function ButtonLoader(_a) {
    var show = _a.show;
    if (!show) {
        return _jsx(_Fragment, {});
    }
    return (_jsx("div", __assign({ className: "progress_button-indicator" }, { children: _jsx(BubbleLoader, {}) })));
}
