import React, { forwardRef, useEffect } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import { usePhone } from '@library/CustomHooks'
import InputBase from '@library/form/fields/new/InputBase'
import { useForm } from '@library/form/FormControllerNew'
import PhoneNumber from '@library/phoneNumber/PhoneNumber'

import { getUserText } from '@helpers/user'

import mainConfig from '@config/main'

import styles from './PatientDetails.module.scss'

const PatientDetails = forwardRef(({ patient, onClickSave, onClickRemove, className }, ref) => {
  const { t } = useTranslation()

  const { form } = useForm({
    email: { value: patient.email || '', rules: ['email', 'required'] },
    phone: {
      value: usePhone(patient.phone || ''),
      rules: [{ name: 'phone', required: true }],
      output: ['getPhone'],
    },
  })

  const { email, phone } = form.fields

  useEffect(() => {
    ref.current.set(patient.id, form)
    return () => {
      delete ref.current.delete(patient.id)
    }
  }, [form])

  return (
    <div className={className}>
      <div className={styles.header}>
        <p className={styles.title}>{t('add_patient.title.details')}</p>
        <div className={styles.iconsContainer}>
          {onClickSave && (
            <div
              className={classNames(styles.icon, styles.iconSave)}
              onClick={async () => await onClickSave(form)}
            />
          )}
          {mainConfig.booking.isPatientsRemovable && (
            <div className={classNames(styles.icon, styles.iconRm)} onClick={onClickRemove} />
          )}
        </div>
      </div>
      <div className="form-group">
        <InputBase
          className="mb-10"
          label={t('book.label.patient')}
          placeholder={t('book.label.patient')}
          field={{
            value: getUserText(patient, ['fullName', 'gender', 'yo']),
          }}
          readonly={true}
          mode={['lg', 'gray-without-border', 'value-bold']}
        />
        <InputBase
          className="mb-10"
          type="email"
          label={t('book.label.email')}
          field={email}
          placeholder={t('book.ph.email')}
          mode={['lg', 'gray-without-border', 'value-bold']}
        />
        <InputBase
          label={t('label.phone')}
          field={phone}
          mode={['lg', 'gray-without-border', 'value-bold']}
          className="mt-0"
        >
          <PhoneNumber
            className={'phone_number--pt-0'}
            popupClass={'phone_number-popup--on_top'}
            phone={phone.value}
            onChange={phone.onChange}
          />
        </InputBase>
      </div>
    </div>
  )
})

export default observer(PatientDetails)
