import React from 'react'

import { useTranslation } from 'react-i18next'

const AsString = ({ event, resource, extractor }) => {
  const { t } = useTranslation()
  const { asString } = extractor

  return (
    <>
      {asString}
      {event.isSelfReported && (
        <span className="badge-self_reported">{t('ps.label.self_reported')}</span>
      )}
    </>
  )
}

export default AsString
