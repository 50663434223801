import React, { useEffect, useLayoutEffect, useState } from 'react'

import { runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { Redirect, Route, Switch, useParams } from 'react-router-dom'

import { useDocTitle } from '@library/CustomHooks'

import { getRouteByName, history, routes, toPath } from '@helpers/router'
import WebView from '@helpers/webView'

import { $auth, $main, $modal, $user } from '@store'

import mainConfig from '@config/main'

export const PageSwitcher = observer(() => {
  return (
    <Switch>
      {routes.map((route, index) => (
        <Route
          key={route.path + index}
          path={route.path}
          exact={route.exact}
          render={({ match }) => {
            return <PageProvider match={match} route={route} />
          }}
        />
      ))}
    </Switch>
  )
})

export const PageProvider = observer(({ match, route: routeConfig }) => {
  // set history
  useLayoutEffect(() => {
    runInAction(() => {
      history.match = match
      history.routeConfig = routeConfig
    })
  }, [match, routeConfig])

  // On Enter / On Leave
  useLayoutEffect(() => {
    $modal.dropAll()

    if (routeConfig.onEnter) {
      routeConfig.onEnter()
    }
    return () => {
      if (routeConfig.onLeave) {
        routeConfig.onLeave()
      }
    }
  }, [routeConfig]) // eslint-disable-line react-hooks/exhaustive-deps

  // On Load / On Reload
  const params = useParams()
  const [isError, setIsError] = useState(false)
  const [paramsForReload, setParamsForReload] = useState(null)

  useEffect(() => {
    if (routeConfig.reloadParams) {
      setParamsForReload(_.pick(params, routeConfig.reloadParams))
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!routeConfig.hasOwnProperty('reloadParams')) {
      return false
    }
    const actualParams = _.pick(params, routeConfig.reloadParams)
    if (_.isEqual(actualParams, paramsForReload)) {
      return actualParams
    }

    const result = _.pick(params, routeConfig.reloadParams)
    setParamsForReload(result)
  }, [params]) // eslint-disable-line react-hooks/exhaustive-deps

  useLayoutEffect(() => {
    if ($user.user) {
      $main.SET_PAGE_IS_LOADED(false)
    }

    const func = async () => {
      if (_.isFunction(routeConfig.onLoad)) {
        let reqParams = _.isEmpty(paramsForReload) ? params : paramsForReload
        const res = await routeConfig.onLoad({ route: { params: reqParams } })
        if (res === false) {
          setIsError(true)
        }
      }

      $main.SET_PAGE_IS_LOADED(true)
    }

    if (routeConfig.reloadParams) {
      if (!_.isEmpty(paramsForReload)) {
        func()
      }
    } else {
      func()
    }
  }, [$user.user, paramsForReload]) // eslint-disable-line react-hooks/exhaustive-deps

  // Redirect if not authorized
  if (routeConfig.private && _.isEmpty($auth.currentLogged)) {
    if (WebView.isWebView) {
      WebView.close()
      return null
    }

    const unAuthPage = _.isFunction(mainConfig.unAuthPage)
      ? mainConfig.unAuthPage()
      : mainConfig.unAuthPage

    if (unAuthPage.type === false || routeConfig.errorElement) {
      // do nothing
    } else if (unAuthPage.type === 'parent') {
      const targetHost = `${mainConfig.web.parentUrl}${unAuthPage.path}`

      let result = ''
      if (unAuthPage.parentBackUrlType === 'same-domain-without-host') {
        result = targetHost + window.location.href.replace(window.location.origin, '')
      } else if (unAuthPage.parentBackUrlType === 'get-with-host') {
        result = targetHost + encodeURIComponent(window.location.href)
      } else {
        result = targetHost
      }

      window.location.replace(result)
      return null
    } else if (unAuthPage.type === 'url') {
      window.location.href = unAuthPage.path
      return null
    } else {
      let redirectParams

      if ($auth.isInit && routeConfig.saveUnAuthReferrer) {
        const referrer = _.get(history, 'location.pathname')
        redirectParams = referrer && { query: { referrer } }
      }

      return <Redirect to={toPath(unAuthPage.path, redirectParams)} />
    }
  }

  // Custom data
  // useDocTitle(routeConfig);

  const element = isError
    ? routeConfig.errorElement || getRouteByName('error').element
    : routeConfig.element

  if ($auth.isInit) {
    $auth.SET_IS_INIT(false)
  }

  return React.cloneElement(routeConfig.layout, { element, routeConfig, isError })
})
