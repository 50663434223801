import { createElement } from 'react'

import { TosLink } from '@library/plugins/termsOfService/TosLink'

export const TOS_GENERAL = 'tos'
export const TOS_PROVIDER = 'tos-provider'

const getRule = (name, options = {}) => {
  switch (name) {
    case 'main-title':
      return {
        name: 'mainTitle',
        match: ({ attributes }) => {
          return attributes && attributes['data-name'] && attributes['data-name'] === 'main-title'
        },
        cb: () => {
          return null
        },
      }

    case 'link':
      return {
        name: 'links',
        match: ({ type, attributes }) => {
          return type === 'a' && attributes.href && attributes.href.startsWith('#') ? true : false
        },
        cb: ({ attributes, children }) => {
          return createElement(
            TosLink,
            {
              type: options.type,
              to: attributes.href.slice(1),
              onlyScroll: !!options.onlyScroll,
            },
            children,
          )
        },
      }
  }
}

export const config = {
  [TOS_GENERAL]: {
    document: 'tos-full',
    rules: ({ onlyScroll = false }) => {
      return [getRule('main-title'), getRule('link', { type: TOS_GENERAL, onlyScroll })]
    },
  },
  [TOS_PROVIDER]: {
    document: 'provider',
    rules: ({ onlyScroll = false }) => {
      return [getRule('main-title'), getRule('link', { type: TOS_PROVIDER, onlyScroll })]
    },
  },
}
