import i18n from 'i18next'

import { getEnumsValue } from '@helpers/enums'
import { getFullName } from '@helpers/text'
import { formatAsAge, formatDate } from '@helpers/time'

import {
  ENUMS_PERSON_ETHNICITY,
  ENUMS_PERSON_GENDER,
  ENUMS_PERSON_GENDER_IDENTITY,
  ENUMS_PERSON_MARITAL_STATUS,
  ENUMS_PERSON_PRONOUN,
  ENUMS_PERSON_PS_GENDER,
  ENUMS_PERSON_RACE,
} from '@config/enums/person'

function getUserFullName({ firstName, lastName, name }) {
  if (firstName || lastName) {
    return getFullName({ firstName, lastName })
  }
  return name
}

function getUserGender(value, isShort) {
  let tKey = (ENUMS_PERSON_GENDER[value] || ENUMS_PERSON_PS_GENDER[value])?.tKey
  let result = i18n.exists(tKey) ? i18n.t(tKey) : value
  return isShort ? result.substring(0, 1) : result
}

function getUserAge(date, withText) {
  if (date?.birthdate !== 'undefined' || date.birthDate !== 'undefined') {
    return formatAsAge(date, withText)
  }
  return date.age
}

export function getUserText(data = {}, format = []) {
  const gender = data.gender
  const dob = data.dob || data.birthdate || data.birthDate

  let result = []

  _.forEach(format, (item) => {
    if (item === 'fullName') {
      const fullName =
        data.fullName ||
        getUserFullName({
          firstName: data.firstName,
          lastName: data.lastName,
        }) ||
        data.name
      result.push(fullName)
    } else if (item === 'gender') {
      result.push(getUserGender(gender, false))
    } else if (item === 'genderShort') {
      result.push(getUserGender(gender, true))
    } else if (item === 'age') {
      result.push(getUserAge(dob, false))
    } else if (item === 'yo') {
      result.push(getUserAge(dob, true))
    } else if (item === 'ageText') {
      result.push(i18n.t('user.age') + ` ${getUserAge(dob, false)}`)
    } else if (item === 'dob') {
      result.push(formatDate(dob))
    } else if (item === 'pronoun') {
      const value = data.pronoun
      result.push(getEnumsValue(value, ENUMS_PERSON_PRONOUN))
    } else if (item === 'genderIdentity') {
      const value = data.genderIdentity
      result.push(getEnumsValue(value, ENUMS_PERSON_GENDER_IDENTITY))
    } else if (item === 'race') {
      const value = data.race
      result.push(getEnumsValue(value, ENUMS_PERSON_RACE))
    } else if (item === 'ethnicity') {
      const value = data.ethnicity
      result.push(getEnumsValue(value, ENUMS_PERSON_ETHNICITY))
    } else if (item === 'maritalStatus') {
      const value = data.maritalStatus
      result.push(getEnumsValue(value, ENUMS_PERSON_MARITAL_STATUS))
    }
  })

  return result.filter((x) => x).join(', ')
}
