var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { makeAutoObservable, runInAction } from 'mobx';
import moment from 'moment-timezone';
import clientService from '@services/client';
import Types from './DocumentUploader.types';
var DocumentUploaderStore = /** @class */ (function () {
    function DocumentUploaderStore() {
        var _this = this;
        this.encounterDocumentsMap = {};
        this.patientDocumentsMap = {};
        this.documentTypes = {};
        this.addDocument = function (document, patientId, encounterId) {
            var documentsList = _this.getDocumentsList(patientId, encounterId);
            documentsList.push(document);
        };
        this.removeDocument = function (patientId, index, encounterId) {
            var documentsList = _this.getDocumentsList(patientId, encounterId);
            documentsList.splice(index, 1);
        };
        this.setDocumentStatus = function (props, status) {
            var documentsList = _this.getDocumentsList(props.patientId, props.encounterId);
            documentsList[props.index].status = status;
        };
        makeAutoObservable(this);
    }
    DocumentUploaderStore.prototype.getDocumentsList = function (patientId, encounterId) {
        var documentsMap = encounterId ? this.encounterDocumentsMap : this.patientDocumentsMap;
        var documentsMapKey = encounterId ? encounterId : patientId;
        if (!documentsMap[documentsMapKey]) {
            runInAction(function () {
                documentsMap[documentsMapKey] = [];
            });
        }
        return documentsMap[documentsMapKey];
    };
    DocumentUploaderStore.prototype.getDocumentTypes = function () {
        return __awaiter(this, void 0, void 0, function () {
            var res, isSuccess;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, clientService.getDocumentTypes()];
                    case 1:
                        res = _a.sent();
                        isSuccess = res && res.prepared;
                        if (isSuccess) {
                            runInAction(function () {
                                _this.documentTypes = res.result;
                            });
                        }
                        return [2 /*return*/, { isSuccess: isSuccess }];
                }
            });
        });
    };
    DocumentUploaderStore.prototype.getDocumentUploadUrl = function (_a) {
        var patientId = _a.patientId, encounterId = _a.encounterId, index = _a.index, document = _a.document, baseFileName = _a.baseFileName;
        return __awaiter(this, void 0, void 0, function () {
            var typeName, dateUpload, fileExtension, fileName, params, res, isSuccess;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        typeName = document.type.name;
                        dateUpload = moment(new Date()).utc().format('YYYYMMDD_kkmmss');
                        fileExtension = _.last(document.file.name.split('.'));
                        fileName = [baseFileName, typeName, dateUpload];
                        params = {
                            encounter_id: encounterId,
                            data: [
                                {
                                    content_type: document.file.type,
                                    filename: fileName.join('_') + '.' + fileExtension,
                                },
                            ],
                        };
                        return [4 /*yield*/, clientService.getDocumentUploadUrl(params)];
                    case 1:
                        res = _b.sent();
                        isSuccess = res && res.prepared;
                        if (!isSuccess) {
                            this.setDocumentStatus({ patientId: patientId, encounterId: encounterId, index: index }, Types.Status.ERROR);
                        }
                        return [2 /*return*/, { isSuccess: isSuccess, data: isSuccess ? res.prepared.urls[0] : '' }];
                }
            });
        });
    };
    DocumentUploaderStore.prototype.uploadFile = function (_a) {
        var patientId = _a.patientId, encounterId = _a.encounterId, index = _a.index, url = _a.url, file = _a.file, fields = _a.fields, filename = _a.filename;
        return __awaiter(this, void 0, void 0, function () {
            var formData, isSuccess, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        formData = new FormData();
                        _.forEach(fields, function (value, key) {
                            formData.append(key, value);
                        });
                        formData.append('file', file, filename);
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, clientService.uploadFile({ url: url, data: formData })];
                    case 2:
                        _b.sent();
                        isSuccess = true;
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _b.sent();
                        this.setDocumentStatus({ patientId: patientId, encounterId: encounterId, index: index }, Types.Status.ERROR);
                        isSuccess = false;
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/, { isSuccess: isSuccess }];
                }
            });
        });
    };
    DocumentUploaderStore.prototype.createDocument = function (_a) {
        var patientId = _a.patientId, encounterId = _a.encounterId, index = _a.index, key = _a.key, document = _a.document;
        return __awaiter(this, void 0, void 0, function () {
            var params, res, isSuccess;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        params = {
                            key: key,
                            content_type: document.file.type,
                            encounter_id: encounterId,
                            document_type: document.type.name,
                            description: document.description,
                        };
                        return [4 /*yield*/, clientService.createDocument(params)];
                    case 1:
                        res = _b.sent();
                        isSuccess = res && res.prepared && res.prepared.result;
                        this.setDocumentStatus({ patientId: patientId, encounterId: encounterId, index: index }, isSuccess ? Types.Status.UPLOADED : Types.Status.ERROR);
                        return [2 /*return*/];
                }
            });
        });
    };
    DocumentUploaderStore.prototype.uploadProcess = function (_a) {
        var patientId = _a.patientId, encounterId = _a.encounterId, baseFileName = _a.baseFileName, document = _a.document, index = _a.index;
        return __awaiter(this, void 0, void 0, function () {
            var uploadUrlParams, uploadFile;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.setDocumentStatus({ patientId: patientId, encounterId: encounterId, index: index }, Types.Status.UPLOADING);
                        return [4 /*yield*/, this.getDocumentUploadUrl({
                                patientId: patientId,
                                encounterId: encounterId,
                                index: index,
                                document: document,
                                baseFileName: baseFileName,
                            })];
                    case 1:
                        uploadUrlParams = _b.sent();
                        if (!uploadUrlParams.isSuccess) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.uploadFile(__assign(__assign({}, uploadUrlParams.data), { patientId: patientId, encounterId: encounterId, index: index, file: document.file, filename: uploadUrlParams.data.filename }))];
                    case 2:
                        uploadFile = _b.sent();
                        if (!uploadFile.isSuccess) return [3 /*break*/, 4];
                        return [4 /*yield*/, this.createDocument({
                                patientId: patientId,
                                encounterId: encounterId,
                                index: index,
                                key: uploadUrlParams.data.fields.key,
                                document: document,
                            })];
                    case 3:
                        _b.sent();
                        _b.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    DocumentUploaderStore.prototype.uploadDocument = function (_a) {
        var patientId = _a.patientId, encounterId = _a.encounterId, file = _a.file, type = _a.type, baseFileName = _a.baseFileName, description = _a.description;
        return __awaiter(this, void 0, void 0, function () {
            var document;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        document = {
                            file: file,
                            type: type,
                            status: Types.Status.UPLOADING,
                            description: description,
                        };
                        this.addDocument(document, patientId, encounterId);
                        return [4 /*yield*/, this.uploadProcess({
                                patientId: patientId,
                                encounterId: encounterId,
                                index: this.getDocumentsList(patientId, encounterId).length - 1,
                                baseFileName: baseFileName,
                                document: document,
                            })];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    return DocumentUploaderStore;
}());
var $documentUploader = new DocumentUploaderStore();
export default $documentUploader;
