import { useLocation } from 'react-router-dom'

import queryString from 'query-string'

import { useLoader } from '@library/CustomHooks'
import maps from '@library/map/Map'

import { useRouterSearchParams } from '@helpers/router'

import { $auth, $main, $user } from '@store'

import mainConfig from '@config/main'

export const useAppInitLoader = () => {
  const searchParams = useRouterSearchParams()
  const location = useLocation()

  return useLoader(async () => {
    await maps.mapLoader()

    if (mainConfig.auth.loginToken) {
      //
      // mb move for separate helper const { token, extraParams } = getExtraParams()
      //
      // ONLY FOR DEV
      // await authService.devLoginByTokenStubReconfigure()
      // await authService.devLoginByTokenStubConfig()
      // ONLY FOR DEV

      let allSearchParams = searchParams.getAll()
      let token = allSearchParams[mainConfig.auth.loginToken]

      if (!token && location.hash) {
        const hash = _.trimStart(_.trimStart(location.hash, '#'), '?')
        allSearchParams = queryString.parse(hash, { parseBooleans: true, parseNumbers: true })
        allSearchParams = _.mapValues(allSearchParams, (item, key) => {
          if (key === 'serviceCodes') {
            return (_.isArray(item) ? item : [item])
              .map((x) => _.split(x, ','))
              .filter((x) => x[1] > 0)
              .map((x) => x[0])
          }
          return item
        })
        token = allSearchParams[mainConfig.auth.loginToken]
      }

      const extraParams = _.pick(allSearchParams, mainConfig.auth.loginExtraParams)
      //
      // mb move for separate helper const { token, extraParams } = getExtraParams()
      //

      if (token) {
        const response = await $auth.loginByToken(token, extraParams)

        if (response.error) {
          //
        } else if (response.prepared) {
          if (response.prepared.extraData) {
            const extraData = { extraParams, ...response.prepared.extraData }
            $main.SET_INIT_EXTRA_DATA(extraData)
          }
        }
      }
    }

    if (!_.isEmpty($auth.currentLogged) && !$user.user) {
      await $auth.refresh()
      await $auth.loadInitData()
    } else {
      $user.SET_IS_LOADING(false)
    }
  })
}
