import React, { useEffect, useRef, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import BookingLayout from '@booking/library/bookingLayout/BookingLayout'
import withBookingDataInit from '@booking/library/withBookingDataInit/withBookingDataInit'
import PatientDetails from '@booking/pages/bookingSelectPatients/patientDetails/PatientDetails'
import PatientItem from '@booking/pages/bookingSelectPatients/patientItem/PatientItem'
import SelectPatientModal from '@booking/pages/bookingSelectPatients/selectPatientModal/SelectPatient.modal'
import classNames from 'classnames'

import Button from '@library/ui/button/Button'

import { customGoTo } from '@helpers/router'

import { $booking, $loader, $modal } from '@store'

import { PATIENT_GROUP_TYPES } from '@config/services'

import styles from './BookingSelectPatients.module.scss'

const BookingSelectPatients = () => {
  const { t } = useTranslation()

  const [openIds, setOpenIds] = useState([])
  const openFormsMapRef = useRef(new Map())

  const showSelectPatientModal = () => {
    $modal.add(SelectPatientModal, {
      selectedIds: $booking.$patients.selectedIds,
      onSelect: (patient) => {
        $booking.$patients.addSelected(patient.id)
      },
    })
  }

  useEffect(() => {
    if (!$booking.$patients.items.length) {
      showSelectPatientModal()
    }
  }, [])

  const updatePatientDetails = async (patientId, updateOriginal) => {
    const form = openFormsMapRef.current.get(patientId)

    if (form) {
      const validation = form.validateAll()

      if (validation.isValid) {
        const { phone, email } = form.values
        return $booking.$patients.updateSelected(patientId, { phone, email }, updateOriginal)
      }
    }

    return false
  }

  const openForm = (patientId) => setOpenIds([...openIds, patientId])
  const closeForm = (patientId) => setOpenIds(openIds.filter((x) => x !== patientId))

  const handleNext = $loader.registerHandler('booking-next', async () => {
    let isFormsValid = true

    for (const [patientId] of openFormsMapRef.current.entries()) {
      const res = await updatePatientDetails(patientId, true)
      if (!res) isFormsValid = false
    }

    if (isFormsValid && $booking.$patients.isAllPatientsValid) {
      customGoTo($booking.schemaConfig?.nextStep())
    } else if (isFormsValid && !$booking.$patients.isAllPatientsValid) {
      $booking.$patients.items.forEach((item) => {
        if (!item.phone || !item.email) {
          openForm(item.id)
          setTimeout(() => openFormsMapRef.current.get(item.id)?.validateAll())
        }
      })
    }
  })

  const isFamilyType = $booking.service?.patientGroupType === PATIENT_GROUP_TYPES.family

  return (
    <BookingLayout
      breadcrumbs={['place', 'service']}
      title={t('book.title.who_needs_help')}
      isMobileFooterFixed={$booking.$patients.items.length > 0}
      button={
        <Button
          mode="primary"
          tag="button"
          buttonType="button"
          disabled={_.isEmpty($booking.$patients.items)}
          label={t('btn.next')}
          action={handleNext}
          isLoading={$loader.isRunHandler('booking-next')}
        />
      }
    >
      <div className="form form--md">
        {$booking.$patients.items.map((patient, index) =>
          openIds.includes(patient.id) || !isFamilyType ? (
            <PatientDetails
              key={patient.id}
              ref={openFormsMapRef}
              className={styles.patientItemDetails}
              patient={patient}
              onClickSave={
                isFamilyType
                  ? async () => {
                      const res = await updatePatientDetails(patient.id, true)
                      if (res) closeForm(patient.id)
                    }
                  : undefined
              }
              onClickRemove={() => {
                $booking.$patients.removeSelected(patient.id)
                closeForm(patient.id)
              }}
            />
          ) : (
            <PatientItem
              key={patient.id}
              className={styles.patientItem}
              patient={patient}
              onClickEdit={() => openForm(patient.id)}
              onClickRemove={() => $booking.$patients.removeSelected(patient.id)}
            />
          ),
        )}
        {(isFamilyType || $booking.$patients.items.length === 0) && (
          <Button
            mode={['create', 'block']}
            className={classNames({ 'mt-30': $booking.$patients.items.length })}
            action={showSelectPatientModal}
            label={t('btn.add_patient')}
          />
        )}
      </div>
    </BookingLayout>
  )
}

export default withBookingDataInit(observer(BookingSelectPatients))
