import React from 'react'

import classNames from 'classnames'

import PatientInfoBox from '@library/ui/patientInfoBox/PatientInfoBox'
import UserPhoto from '@library/user/UserPhoto'

import { getUserText } from '@helpers/user'

import mainConfig from '@config/main'

import styles from './SelectPatientItem.module.scss'

const PatientItem = ({ patient, onClickEdit, onClickRemove, className }) => {
  return (
    <div className={classNames(styles.container, className)}>
      <PatientInfoBox
        className={styles.patientBox}
        value={getUserText(patient, ['fullName'])}
        label={getUserText(patient, ['gender', 'yo'])}
        photo={<UserPhoto item={patient} />}
      />
      <div className={styles.iconsContainer}>
        <div className={classNames(styles.icon, styles.iconEdit)} onClick={onClickEdit} />
        {mainConfig.booking.isPatientsRemovable && (
          <div className={classNames(styles.icon, styles.iconRm)} onClick={onClickRemove} />
        )}
      </div>
    </div>
  )
}

export default PatientItem
