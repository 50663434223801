import React, { createElement, Fragment, useEffect } from 'react'

import { config, TOS_GENERAL } from '@library/plugins/termsOfService/Tos.config'

import { useRouterSearchParams } from '@helpers/router'
import { nativeScrollTo } from '@helpers/url'

export const TosText = ({ type = TOS_GENERAL, json, extractRef, onlyScroll = false }) => {
  const searchParams = useRouterSearchParams()
  const rules = config[type]['rules']({ onlyScroll })

  useEffect(() => nativeScrollTo(searchParams.get(type)), [searchParams])

  return createElement(
    'div',
    {},
    json.map((el, index) => {
      return (
        <Fragment key={index}>
          {window.ziphyTosRenderJSON({
            json: el,
            rules,
            extractData: extractRef.current,
            createElement,
          })}
        </Fragment>
      )
    }),
  )
}
