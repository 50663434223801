import { toJS } from 'mobx'

import i18n from 'i18next'

import { extendAddress } from '@helpers/addressHelper'
import { isAbsoluteEmpty, splitAtIndex } from '@helpers/other'
import { humanize } from '@helpers/text'
import { decodeAndFormatDateObject, formatDate } from '@helpers/time'
import { removeHttp } from '@helpers/url'
import { getUserText } from '@helpers/user'

import {
  CODING_ORGANIZATIONS,
  CODING_SYSTEMS,
  CODING_SYSTEMS_ORDERED,
  LAB_CANONICAL_STATUSES,
  LAB_OBSERVATION_INTERPRETATIONS,
  LAB_RAPID_CODES,
} from '@ps/config/fhir.constants'
import { extractorScheme } from '@ps/config/resourceExtractor'
import { parseReferenceId } from '@ps/helpers/fhir'
import { mediaAuthInstance } from '@ps/helpers/mediaAuth'

import { $psResources } from '@store'

import mainConfig from '@config/main'

export function findCodingOrganization(coding) {
  let result = _.find(CODING_ORGANIZATIONS, (x) => _.includes(coding.system, removeHttp(x.system)))
  return result || _.find(CODING_ORGANIZATIONS, (x) => x.id === 'unknown')
}

export function findCodingSystem(coding, key = '') {
  const result = _.find(CODING_SYSTEMS_ORDERED, (x) => {
    const url = coding[key] || coding.url || coding.system
    return _.includes(url, removeHttp(x[1]))
  })
  return _.first(result) || 'unknown'
}

export function isCompleteCoding(coding) {
  let result = true
  let completeCodingKeys = ['code', 'display', 'system']
  const codingKeys = Object.keys(coding)
  if (codingKeys.length !== 3) {
    result = false
  } else {
    codingKeys.forEach((x) => {
      if (!_.includes(completeCodingKeys, x)) {
        result = false
      }
    })
  }
  return result
}

export function getCodeObject(data = {}, priority = []) {
  const { coding, text = '' } = data

  let tmp = []

  if (!_.isEmpty(coding)) {
    _.forEach(coding, (codingItem) => {
      const codingOrganization = findCodingOrganization(codingItem)

      tmp.push({
        text: text,
        code: codingItem.code,
        display: codingItem.display || text,
        system: codingItem.system,
        organizationLabel: codingOrganization.label,
        asString: [codingOrganization.label, codingItem.code].filter((x) => x).join(' '),
      })
    })
  } else if (text) {
    tmp.push({
      text: text,
      code: '',
      display: text,
      system: '',
      organizationLabel: '',
      asString: text,
    })
  }

  let result

  _.forEach(priority, (system) => {
    const found = _.find(tmp, (x) => removeHttp(x.system) === removeHttp(system))

    if (found) {
      result = found
      return false
    }
  })

  return result || tmp[0]
}

export function findByUrlOrSystem(items = [], needle, key = '') {
  if (!_.isArray(needle)) {
    needle = [needle]
  }

  return _.find(items, (item) => {
    const url = removeHttp(item[key] || item.url || item.system)
    return _.some(needle, (x) => _.includes(x, url))
  })
}

export function getAddresses(data = {}, clean = false) {
  const list = _.cloneDeep(data.address) || []

  return _.map(list, (x) => {
    const tmp = extendAddress({
      zip: x.postalCode,
      state: x.state,
      country: x.country || '',
      building: '',
      street: x.line?.[0],
      city: x.city,
      apartment: x.line?.[1],
    })

    x.asString = tmp.address({ clean })

    return x
  })
}

export function getTelecom(data = {}, system = '') {
  let list = _.cloneDeep(data.telecom) || []

  list = list.filter((x) => x.system === system)

  return list
}

export function getPassport(data = {}) {
  return findByUrlOrSystem(data.identifier, CODING_SYSTEMS.labqPatientPassport)
}

export function getPreparedIdentifiers(identifiers = []) {
  let result = {}

  _.forEach(identifiers, (item) => {
    const codingSystemId = findCodingSystem(item)

    if (codingSystemId) {
      result[codingSystemId] = {
        ...item,
        id: codingSystemId,
      }
    } else {
      result[item.system] = {
        ...item,
        id: item.system,
      }
    }
  })

  return result
}

export function getDiagnosticReportChartNumber(data = {}) {
  return findByUrlOrSystem(data.extension, CODING_SYSTEMS.labqPatientChartNumber)
}

export function getDisplayOrText(data) {
  // @todo: use codeObject
  return _.get(data, 'coding[0].display') || _.get(data, '[0].text') || _.get(data, 'text')
}

export function getValues(data) {
  let values = []

  if (data) {
    _.forEach(data, (item) => {
      values.push(item.text)
    })
  }

  return values
}

export function getCodingValuesAsString(data, ctx, key = 'values') {
  const values = _.isFunction(ctx[key]) ? ctx[key](data, ctx) : ctx[key]

  if (!_.isEmpty(values)) {
    return convertCodingValuesToString(values)
  }

  return ''
}

export function getValuesAsString(r, ctx, key = 'values') {
  if (_.isFunction(ctx[key])) {
    return convertValuesToString(ctx[key](r, ctx))
  }

  return ctx[key]
}

export function getDocumentValues(data) {
  let values = []

  let coding = _.get(data, 'type.coding', []).filter((x) => x.system !== 'https://fhir.ziphy.com')

  _.forEach(coding, (x) => {
    values.push(x.display)
  })

  return values
}

//
// https://www.hl7.org/fhir/observation.html
//
export function getObservationValues(data = {}) {
  const {
    code,
    valueString,
    valueBoolean,
    valueQuantity,
    valueCodeableConcept,
    valueDateTime,
    component,
    modifierExtension,
  } = data

  const coding = _.get(code, 'coding')

  let values = []

  if (valueString) {
    values.push({
      type: 'valueString',
      value: valueString,
      valueClear: _.replace(valueString, /[^+\d.]/g, ''),
      coding,
    })
  }

  if (valueDateTime) {
    values.push({
      type: 'valueDateTime',
      value: formatDate(valueDateTime, { format: 'date' }),
      coding,
    })
  }

  if (!_.isUndefined(valueBoolean)) {
    values.push({
      type: 'valueBoolean',
      value: valueBoolean ? i18n.t('ps.label.yes') : i18n.t('ps.label.no'),
      coding,
    })
  }

  if (valueQuantity) {
    const { value, unit, code, system, comparator } = valueQuantity

    values.push({
      type: 'valueQuantity',
      code,
      system,
      value,
      unit,
      coding,
      comparator,
    })
  }
  if (valueCodeableConcept) {
    let codingValue = _.get(valueCodeableConcept, 'coding[0]', {})
    const { system, code, display } = codingValue

    let list = []
    _.forEach(modifierExtension, (x) => list.push(...getObservationValues(x)))

    values.push({
      type: 'valueCodeableConcept',
      system,
      code,
      value: display || valueCodeableConcept.text,
      coding,
      modifierExtension: list,
    })
  }

  if (component) {
    _.forEach(component, (item) => {
      let value = getObservationValues(item)

      if (value) {
        values = [...values, ...value]
      }
    })
  }

  return values
}

export function getObservationComment(data = {}) {
  return _.get(data, 'text.div')
}

// https://www.hl7.org/fhir/observation.html#:~:text=(Measurement)%20Device-,referenceRange,-I
export function getObservationReferenceRange(r = {}) {
  const { referenceRange = [] } = r
  let range = referenceRange[0]

  if (!range) {
    return []
  }

  let tmpValues = []

  range = _.mapValues(range, (x, rangeType) => {
    let result = {}

    if (['text'].includes(rangeType)) {
      if (['missing'].includes(_.toLower(x))) {
        return
      }
      result = getObservationValues({ valueString: x })
    }
    if (['low', 'high'].includes(rangeType)) {
      result = getObservationValues({ valueQuantity: x })
    }
    if (['type', 'appliesTo'].includes(rangeType)) {
      result = getObservationValues({ valueCodeableConcept: x })
    }

    result = {
      ..._.first(result),
      asString: convertValuesToString(result, humanize),
    }

    tmpValues.push(result)

    return result
  })

  tmpValues = _.orderBy(tmpValues, 'value')
  range.values = tmpValues

  const inlineValues = _.map(tmpValues, (x, index) => {
    // add unit to last value
    if (index === tmpValues.length - 1) {
      return x.asString
    } else {
      return x.value
    }
  })
  const inlineValuesClear = _.map(tmpValues, (x) => {
    return x.value
  })

  let comparator = false

  if (!range.low && range.high) {
    comparator = '<'
  } else if (!range.high && range.low) {
    comparator = '>'
  }

  if (comparator) {
    range.valuesAsString = comparator + ' ' + inlineValues.join(' ')
    range.valuesAsStringClear = comparator + ' ' + inlineValuesClear.join(' ')
  } else {
    range.valuesAsString = inlineValues.join(' - ')
    range.valuesAsStringClear = inlineValuesClear.join(' - ')
  }

  return [range]
}

export function getObservationInReferenceRange(r = {}) {
  let result = true

  if (r.interpretation) {
    const interpretation = getObservationInterpretation(r)

    if (interpretation && interpretation.hasOwnProperty('inRange')) {
      result = interpretation.inRange
    }
  }

  return result
}

export function getObservationAbsentReason(data = {}) {
  const found = findByUrlOrSystem(data.dataAbsentReason?.coding, CODING_SYSTEMS.hl7DataAbsentReason)
  const text = data.dataAbsentReason?.text

  const types = {
    'temp-unknown': 'ps.absent_reasons.not_valid',
    'not-performed': 'ps.absent_reasons.not_valid',
    unknown: 'ps.absent_reasons.not_valid',
  }

  let result = {
    system: found?.system || '',
    code: found?.code || '',
    text: text || '',
    asString: '',
  }

  if (found?.code) {
    result.asString = types[found?.code] ? i18n.t(types[found?.code]) : i18n.t(types['unknown'])
  }

  return result
}

export function getObservationTestComponentConfig(r = {}) {
  return {
    index: r.componentIndex,
    ...r.componentConfig,
  }
}

export function getHpiValues(data = {}) {
  return getObservationValues(data)
}

export function getHpiValuesAsString(data = {}, ctx) {
  return getValuesAsString(data, ctx)
}

export function getMediaValues(data = {}) {
  const { modality, note, bodySite, component } = data

  const coding = _.get(modality, 'coding')

  let values = []

  if (bodySite) {
    let codingValue = _.get(bodySite, 'coding[0]', {})
    const { system, code, display } = codingValue

    values.push({
      type: 'bodySite',
      system,
      code,
      value: display,
      coding,
    })
  }

  if (!bodySite && note && note.length) {
    const noteValues = getValues(note)

    _.forEach(noteValues, (x) => {
      values.push({
        type: 'note',
        value: x,
        coding,
      })
    })
  }

  if (component) {
    _.forEach(component, (item) => {
      let value = getMediaValues(item)

      if (value) {
        values = [...values, ...value]
      }
    })
  }

  return values
}

export function convertCodingValuesToString(values, key = 'asString', prepareValue) {
  let result = []

  _.forEach(values, (item) => {
    let value = item[key]

    if (_.isFunction(prepareValue)) {
      value = prepareValue(value)
    }

    result.push(value)
  })

  return result.join(', ')
}

export function convertValuesToString(values, prepareValue) {
  if (_.isString(values)) {
    if (_.isFunction(prepareValue)) {
      return prepareValue(values)
    }
    return values
  }

  let result = []

  _.forEach(values, (item) => {
    if (_.isString(item)) {
      result.push(item)
    } else if (_.isObject(item)) {
      let { value, unit, modifierExtension } = item

      if (_.isFunction(prepareValue)) {
        value = prepareValue(value)
      }

      let tmp = [value, unit].filter((x) => !isAbsoluteEmpty(x))

      if (modifierExtension) {
        let modifierValues = _.map(modifierExtension, (x) => x.value).filter((x) => x)

        if (modifierValues.length) {
          tmp.push(' (' + modifierValues.join(', ') + ')')
        }
      }

      if (tmp.length) {
        result.push(tmp.join(' '))
      }
    }
  })

  return result.filter((x) => x).join(', ')
}

export function getActorByReference(data, resource) {
  let result = {
    role: '',
    value: {},
    extractor: {},
  }
  // todo: simplify the structure, make a flat extractor

  const tmp = resource || $psResources.getByReferences(data)

  if (tmp.resourceType === 'Patient') {
    result = {
      role: 'patient',
      value: tmp,
    }
    if (result.value) {
      result.extractor = resourceExtractor({ extractor: 'subject' }, result.value)
    }
  } else if (tmp.resourceType === 'RelatedPerson') {
    result = {
      role: 'relatedPerson',
      value: tmp,
    }
  } else if (tmp.resourceType === 'Organization') {
    result = {
      role: 'organization',
      value: tmp,
    }
    if (result.value) {
      result.extractor = resourceExtractor({ extractor: 'organization' }, result.value)
    }
  } else if (tmp.resourceType === 'PractitionerRole' && tmp.practitioner) {
    const value = $psResources.getByReferences(tmp.practitioner)

    result = {
      role: _.get(tmp, 'code[0].text'),
      value: value,
    }
  } else if (tmp.resourceType === 'Practitioner') {
    result = {
      role: _.get(tmp, 'code[0].text'),
      value: tmp,
    }
    if (result.value) {
      result.extractor = resourceExtractor({ extractor: 'practitioner' }, result.value)
    }
  } else if (tmp.fullName) {
    result.value = tmp
  }

  return result
}

export function getEncounterIds(appt) {
  const resourse = $psResources.getEncounterByAppointmentId(appt.id)
  const encounter = resourceExtractor({ extractor: 'encounter' }, resourse)
  return encounter.ids
}

export function getAddressByAppointmentId(appt, type = 'address') {
  const encounter = $psResources.getEncounterByAppointmentId(appt.id)
  const location = $psResources.getByReferences(_.get(encounter, 'location[0].location'))

  const address = toJS(location.address) || {}

  let data = {
    zip: address.postalCode,
    state: address.state,
    country: address.country,
    street: _.get(address, 'line[0]'),
    city: address.city,
    building: address.building,
    apartment: _.get(address, 'line[1]'),
    floor: address.floor,
  }

  data = _.mapValues(data, (x) => x || '')

  const extendedPlace = extendAddress(data)

  if (type) {
    if (_.isFunction(extendedPlace[type])) {
      return extendedPlace[type]()
    }

    return extendedPlace.address()
  }

  return extendedPlace
}

export function getPracticeByAppointment(appt) {
  const encounter = $psResources.getEncounterByAppointmentId(appt.id)
  return $psResources.getByReferences(encounter.serviceProvider)
}

export function getReasonsByAppointment(appt) {
  const encounter = $psResources.getEncounterByAppointmentId(appt.id)

  let reasons = []

  _.forEach(encounter.reasonReference, (reason) => {
    const tmp = $psResources.getByReferences(reason)

    if (!_.isEmpty(tmp)) {
      reasons.push(resourceExtractor({ extractor: 'observation' }, tmp))
    }
  })

  return reasons
}

export function getOriginalAppt(appt) {
  const identifier = _.get(appt, 'identifier[0].value', '')
  const parsed = parseReferenceId(identifier)

  return parsed.id
}

export function getParticipants(appt) {
  let result = {}

  _.forEach(appt.participant, (item) => {
    const tmp = getActorByReference(item.actor)

    if (tmp && tmp.role) {
      if (tmp.role === 'patient') {
        result['patients'] = result['patients'] || []
        result['patients'].push(tmp.value)
      } else {
        result[tmp.role] = tmp.value
      }
    }
  })

  return result
}

export function getAppointmentStatus(r) {
  const tKey = 'ps.appt_statuses.' + _.snakeCase(r.status)
  return i18n.exists(tKey) ? i18n.t(tKey) : humanize(r.status, true)
}

export function getAppointmentCombinedStatus(r) {
  let status = ''

  const encounterResource = $psResources.getEncounterByAppointmentId(r.id)
  if (encounterResource) status = getEncounterStatus(encounterResource)

  if (!status) {
    status = getAppointmentStatus(r)
  }

  return status
}

export function getEncounterStatus(r) {
  const tKey = 'ps.appt_encounter_statuses.' + _.snakeCase(r.status)
  return i18n.exists(tKey) ? i18n.t(tKey) : humanize(r.status, true)
}

export function getAllergyType(r) {
  const type = r.type || 'allergy'
  return { code: type, label: 'ps.event_viewer.allergy.type.' + type }
}

export function getAllergyStatus(r) {
  return getCodeObject(r.clinicalStatus, [CODING_SYSTEMS.hl7AllergyIntoleranceClinical])
}

export function getAllergyReaction(r) {
  let result = []

  _.forEach(r.reaction, (item) => {
    _.forEach(item.manifestation, (x) => {
      const value = getCodeObject(x)

      if (value) {
        result.push(value)
      }
    })
  })

  return result
}

export function getObservationPerformer(r = {}) {
  return getActorByReference(_.last(r.performer))
}

export function getObservationIsRapidLabq(r = {}) {
  const item = getCodeObject(r.code, [CODING_SYSTEMS.labqOrderResultCode])
  return item && _.some(LAB_RAPID_CODES, (x) => String(x) === String(item.code))
}

export function getObservationStatus(r = {}) {
  const status = r.status

  const MAPPING = {
    default: {
      registered: 'created',
      partial: 'received',
      preliminary: 'received',
      final: 'completed',
      amended: 'completed',
      corrected: 'corrected',
      appended: 'completed',
      cancelled: 'cancelled',
      'entered-in-error': 'entered_in_error',
      unknown: 'unknown',
    },
  }

  const mappedCode = MAPPING.default[status] || MAPPING.default.unknown
  let tKey = 'ps.labs_statuses.' + mappedCode
  let label = i18n.exists(tKey) ? i18n.t(tKey) : status

  return {
    code: status,
    mappedCode: mappedCode,
    label: label,
  }
}

export function getDiagnosticReportStatus(r = {}) {
  const status = r.status

  // sync with getOrderStatus in LabQ
  const MAPPING = {
    client: {
      lab: {
        registered_created: 'created',
        registered_started: 'started',
        registered_scanned: 'started',
        registered_collected: 'pending',
        registered_send: 'pending',
        registered_accessioned: 'received',
        preliminary_accessioned: 'received',
        preliminary_partial: 'partial',
      },
      rapid: {
        registered_created: 'created',
        registered_started: 'started',
        registered_scanned: 'started',
        registered_collected: 'pending',
      },
    },
    supervisor: {
      lab: {
        registered_created: 'created',
        registered_started: 'started',
        registered_scanned: 'scanned',
        registered_collected: 'collected',
        registered_send: 'collected',
        registered_accessioned: 'received',
        preliminary_accessioned: 'received',
        preliminary_partial: 'partial',
      },
      rapid: {
        registered_created: 'created',
        registered_started: 'started',
        registered_scanned: 'scanned',
        registered_collected: 'collected',
      },
    },
    default: {
      registered: 'created',
      partial: 'received', // unused
      preliminary: 'received',
      final: 'completed',
      amended: 'completed', // unused
      corrected: 'corrected',
      appended: 'completed', // unused
      cancelled: 'cancelled',
      'entered-in-error': 'entered_in_error', // unused
      unknown: 'unknown', // unused
    },
  }

  let mappedCode

  const extension = findByUrlOrSystem(r.extension, [
    CODING_SYSTEMS.labqProcedureStatus,
    CODING_SYSTEMS.labqProcedureStatusLegacy, // todo: remove later
  ])
  const extensionStatus = extension?.valueCode

  if (extensionStatus) {
    const isRapid = getDiagnosticReportIsRapidLabq(r)
    const appSubType = mainConfig.appSubType || 'client'
    const type = isRapid ? 'rapid' : 'lab'

    mappedCode = _.get(MAPPING, appSubType + '.' + type + '.' + status + '_' + extensionStatus)
  }

  if (!mappedCode) {
    mappedCode = MAPPING.default[status] || MAPPING.default.unknown
  }

  let tKey = 'ps.labs_statuses.' + mappedCode
  let label = i18n.exists(tKey) ? i18n.t(tKey) : status

  return {
    code: status,
    extensionStatus: extensionStatus,
    mappedCode: mappedCode,
    label: label,
    isFinal: ['completed', 'cancelled', 'corrected', 'entered_in_error', 'unknown'].includes(
      mappedCode,
    ),
  }
}

// todo: split to Observation and DiagnosticReport methods?
export function getLabsCanonicalStatus(r) {
  const canonical = LAB_CANONICAL_STATUSES[r.status]

  if (canonical) {
    return {
      code: canonical,
      label: i18n.t('ps.labs_canonical_statuses.' + _.snakeCase(canonical)),
    }
  }
}

export function getObservationInterpretation(r) {
  let result = []

  _.forEach(r.interpretation, (item) => {
    _.forEach(item.coding, (x) => {
      const system = LAB_OBSERVATION_INTERPRETATIONS[x.system]

      if (system && system[x.code]) {
        result.push({ ...x, ...system[x.code] })
      }
    })
  })

  const interpretation = _.first(result)

  if (r.interpretation && !interpretation) {
    console.warn('Unknown interpretation: ', toJS(r.interpretation))
  }

  if (interpretation) {
    return {
      ...interpretation,
      label: i18n.t('ps.labs_interpretations.' + _.toUpper(interpretation.code)),
    }
  } else {
    return {
      inRange: true,
      isCritical: false,
    }
  }
}

export function getMediaContent(resource = {}) {
  const data = _.get(resource, 'content[0].attachment') || resource.content || {}

  if (!data.contentType) {
    return {}
  }

  let [type, extension] = data.contentType.split('/')

  let result = {
    type,
    extension,
    contentType: data.contentType,
    url: mediaAuthInstance().getUrlWithAuth(data.url),
  }

  if (type === 'image') {
    const thumbSuffix = '/thumbnail'
    const url = new URL(result.url)

    const dotIndex = url.pathname.lastIndexOf('.')
    const [path, ext] = splitAtIndex(url.pathname, dotIndex)

    url.pathname = path + thumbSuffix + '.' + ext
    result.thumb = url.href

    url.pathname = path + thumbSuffix + '@3x.' + ext
    result.thumb3x = url.href

    result.originalExtension = ext
    // result.fallback // data.url;
  }

  return result
}

export function getDiagnosticReportInReferenceRange(data = {}) {
  let result = true

  _.forEach(data.result, (item) => {
    const tmp = $psResources.getByReferences(item)

    if (!_.isEmpty(tmp)) {
      const extractor = resourceExtractor({ extractor: 'observation' }, tmp, ['inReferenceRange'])

      if (!extractor.inReferenceRange) {
        result = false
        return false
      }
    }
  })

  return result
}

export function getDiagnosticReportCollectLocation(r = {}) {
  let reference = findByUrlOrSystem(r.extension, CODING_SYSTEMS.labqCollectLocation)?.valueReference

  // <--- todo: temporarily solution, delete after ETL, performer it is not correct
  if (!reference) {
    reference = _.first(r.performer)
  }
  // todo: temporarily solution, delete after ETL, performer it is not correct --->

  return getActorByReference(reference)
}

export function getDiagnosticReportOrderingPhysician(r = {}) {
  let reference = findByUrlOrSystem(
    r.extension,
    CODING_SYSTEMS.labqOrderingPhysician,
  )?.valueReference

  if (reference) {
    return getActorByReference(reference)
  }

  return getActorByReference(null, {
    resourceType: 'Practitioner',
    firstName: 'Martin J.',
    lastName: 'King, Ph.D.',
    fullName: 'Martin J. King, Ph.D.',
    address: [{ line: ['140 58th Street'], city: 'Brooklyn', state: 'NY', postalCode: '11220' }],
    telecom: [{ system: 'phone', value: '+17185345227' }],
  })
}

export function getDiagnosticReportPerformer(r = {}) {
  return getActorByReference(_.last(r.performer))
}

export function getDiagnosticReportObservations(data = {}) {
  let result = []

  _.forEach(data.result, (item) => {
    const resource = $psResources.getByReferences(item)

    if (!_.isEmpty(resource)) {
      const extractor = resourceExtractor({ extractor: 'observation' }, resource)
      result.push({ resource, extractor })
    }
  })

  return result
}

export function getDiagnosticReportIsRapidLabq(r = {}) {
  const item = getCodeObject(r.code, [CODING_SYSTEMS.labqOrderCode])
  return item && _.some(LAB_RAPID_CODES, (x) => String(x) === String(item.code))
}

export function getDiagnosticReportOrderId(data = {}) {
  return findByUrlOrSystem(data.identifier, CODING_SYSTEMS.labqOrderIdentifier)
}

export function getDiagnosticAccessionNumber(data = {}) {
  return findByUrlOrSystem(data.identifier, CODING_SYSTEMS.labqAccession)
}

export function getSubjectGender(data = {}) {
  if (!data.gender) {
    return
  }

  return {
    code: data.gender,
    label: getUserText(data, ['gender']),
  }
}

export function getDiagnosticReportCannedComment(data = {}) {
  const codeObj = getCodeObject(data.code, [CODING_SYSTEMS.labqOrderCode])

  const temp = {
    '0001': {
      source: 'Nasopharyngeal',
      text:
        'Remote COVID-19 Rapid Antigen Testing was performed using FDA EUA approved kits for detection of SARS-CoV-2. All results were interpreted by direct visualization of the results directly on the test cartridge.\n' +
        '\n' +
        'Emergency Warning Signs\n' +
        'If you develop emergency warning signs for COVID-19 get medical attention immediately. Emergency warning signs include*:\n' +
        '\n' +
        '• Trouble breathing\n' +
        '• Persistent pain or pressure in the chest \n' +
        '• New confusion or inability to arouse \n' +
        '• Bluish lips or face\n' +
        '\n' +
        '*This list is not all inclusive. Please consult your medical provider for any other symptoms that are severe or concerning.\n' +
        '\n' +
        'This test had been authorized by the FDA under an Emergency Use Authorization and approved under CLIA waived status.  This test is only authorized for the duration of time the declaration that circumstances exist justifying the authorization of the emergency use of in-vitro diagnostic tests for the detection of SARS-CoV-2 virus and/or diagnosis of COVID 19 infection under the section 564(b)(1) of the Act, 21 U.S.C. 360bbb-3(b)(1), unless the authorization is terminated ore revoked sooner.',
      methodology: '',
    },
    2023: {
      source: 'NP',
      text: 'This test has been authorized by the FDA under an EUA for use by author laboratories. Negative results do not preclude infection of the upper respiratory tract.',
      methodology: 'TMA{RT-PCR}',
    },
    2024: {
      source: 'Nasal',
      text: 'This test has been authorized as an EUA for use by authorized laboratories. Negative results do not preclude infection of the upper respiratory tract.',
      methodology: 'RT-PCR',
    },
    8024: {
      source: 'Nasopharyngeal',
      text: 'This test has been authorized as an EUA for use by authorized laboratories. Negative results do not preclude infection of the upper respiratory tract.',
      methodology: 'RT-PCR',
    },
    9000: {
      source: 'Saliva',
      text: 'This test has been authorized by the FDA under an EUA for use by authorized laboratories. Negative results do not preclude infection of the upper respiratory tract.',
      methodology: 'RT-PCR',
    },
    M024: {
      source: 'Nasopharyngeal',
      text: 'This test has been authorized by the FDA under an EUA for use by authorized laboratories. Negative results do not preclude infection of the upper respiratory tract.',
      methodology: 'RT-PCR',
    },
    P024: {
      source: 'Nasopharyngeal',
      text: 'This test has been authorized as an EUA for use by authorized laboratories. Negative results do not preclude infection of the upper respiratory tract.',
      methodology: 'RT-PCR',
    },
    W024: {
      source: 'Nasopharyngeal',
      text: 'This test has been authorized as an EUA for use by authorized laboratories. Negative results do not preclude infection of the upper respiratory tract.',
      methodology: 'RT-PCR',
    },
  }

  let result = {
    source: null,
    text: null,
    methodology: null,
  }

  if (codeObj && temp[codeObj.code]) {
    result = temp[codeObj.code]
  }

  if (data.text?.div) {
    result.text = data.text?.div
  }

  return result
}

export function getDiagnosticSpecimenType(r = {}) {
  return findByUrlOrSystem(r.extension, CODING_SYSTEMS.labqSpecimenType)
}

export function getDiagnosticReportRoom(data = {}) {
  return findByUrlOrSystem(data.extension, CODING_SYSTEMS.labqOrderRoomNumber)
}

export function getDiagnosticDateOrdered(r = {}) {
  findByUrlOrSystem(r.extension, CODING_SYSTEMS.labqOrderDate)?.valueDateTime
}

export function getDiagnosticReportDateReported(r = {}) {
  let result = findByUrlOrSystem(r.extension, CODING_SYSTEMS.labqFinalReportDate)?.valueDateTime
  if (result) return result

  const status = getDiagnosticReportStatus(r)

  if (status?.isFinal) {
    const observations = getDiagnosticReportObservations(r)
    const items = _.map(observations, (x) => x.resource?.effectiveDateTime)
    return _.max(_.compact(items))
  }
}

export function getDiagnosticDateReceived(data = {}) {
  return findByUrlOrSystem(data.extension, CODING_SYSTEMS.labqLabEntryDate)?.valueDateTime
}

export function getObservationDateCorrected(r = {}) {
  if (r.status === 'corrected') {
    return r.effectiveDateTime
  }
}

export function getDiagnosticReportDateCorrected(r = {}) {
  const observations = getDiagnosticReportObservations(r)
  const items = _.map(observations, (x) => x.extractor.dateCorrected)
  return _.max(_.compact(items))
}

export function getDiagnosticReportDateCancelled(r = {}) {
  let result

  const dateCancelledByPatient = findByUrlOrSystem(
    r.extension,
    CODING_SYSTEMS.labqCancelledByPatient,
  )
  result = dateCancelledByPatient?.valueDateTime
  if (result) return result

  const observations = getDiagnosticReportObservations(r)
  const items = _.map(observations, (x) => {
    return x.resource?.status === 'cancelled' ? x.resource?.effectiveDateTime : null
  })
  result = _.max(_.compact(items))
  if (result) return result

  if (r.status === 'cancelled') {
    return r.issued
  }
}

export function getDiagnosticReportCancelledByPatient(r = {}) {
  return !!findByUrlOrSystem(r.extension, CODING_SYSTEMS.labqCancelledByPatient)
}

export function getDiagnosticRouteNumber(r = {}) {
  return findByUrlOrSystem(r.extension, CODING_SYSTEMS.labqRouteNumber)
}

export function getMedicationRequestMedication(r = {}) {
  const id = _.trimStart(r.medicationReference?.reference, '#')
  let result

  if (id) {
    result = _.find(r.contained, (x) => x.Id === id)
  }

  if (!result) {
    result = _.find(r.contained, (x) => x.resourceType === 'Medication')
  }

  return result
}

export function getMedicationRequestEffectivePeriod(data, type) {
  const effectivePeriod = findByUrlOrSystem(data.extension, [
    CODING_SYSTEMS.ziphyMedicationRequestEffectivePeriod,
    'effectivePeriod',
  ])

  if (_.isEmpty(effectivePeriod)) {
    return null
  }

  return _.get(effectivePeriod, `valuePeriod.${type}`, null)
}

export function getMedicationRequestStatus(data = {}) {
  let result = getCodeObject(data.statusReason, [CODING_SYSTEMS.medicationRequestStatusReason])

  if (_.isEmpty(result)) {
    const statuses = {
      active: 'ps.medication_requests_statuses.active',
      completed: 'ps.medication_requests_statuses.completed',
      stopped: 'ps.medication_requests_statuses.stopped',
      'on-hold': 'ps.medication_requests_statuses.on_hold',
      unknown: 'ps.medication_requests_statuses.unknown',
      cancelled: 'ps.medication_requests_statuses.cancelled',
      'entered-in-error': 'ps.medication_requests_statuses.entered_in_error',
      draft: 'ps.medication_requests_statuses.draft',
    }

    const status = statuses[data.status] || statuses.unknown
    const text = i18n.t(status)

    result = getCodeObject({
      coding: [
        { code: data.status, display: text, system: CODING_SYSTEMS.medicationRequestDefaultStatus },
      ],
    })
  }

  return result
}

export function getMedicationStatementStatus(data = {}) {
  return i18n.t(`ps.medication_statement_status.${data.status}`)
}

export function getMedicationStatementDosage(r = {}) {
  const doseQuantity = _.get(r, 'dosage[0].doseAndRate[0].doseQuantity')

  if (doseQuantity) {
    return [doseQuantity]
  }

  return []
}

export function getMedicationRequestDosage(r = {}) {
  const doseQuantity = _.get(r, 'dosageInstruction[0].doseAndRate[0].doseQuantity')

  if (doseQuantity) {
    return [doseQuantity]
  }

  return []
}

export function getServiceRequestType(data, ctx) {
  const category = _.get(data, 'category[0]')
  return getCodeObject(category)
}

export function getExaminationTemplate(data) {
  const tmp = findByUrlOrSystem(data.extension, CODING_SYSTEMS.ziphyObservationExaminationTemplate)
  let result = getCodeObject(tmp?.valueCodeableConcept) || {}

  // temporarily fix
  if (!result.display) {
    result.display = _.startCase(result.code)
  }

  return result
}

export function getExaminationValues(data, ctx) {
  const isLegacy = ctx.isLegacy(data)

  if (isLegacy) {
    return getObservationValues(data)
  }

  let values = []

  _.forEach(data.component, (item) => {
    let result = _.first(getObservationValues(item))
    result.coding = getCodeObject(item.code)
    values.push(result)
  })

  if (data.note) {
    values.push({
      value: _.map(data.note, (x) => x.text).join(', '),
      label: 'ps.label.notes',
    })
  }

  return values
}

export function getProcedureDate(r) {
  let result = {}

  if (r.performedDateTime) {
    result = { dateString: r.performedDateTime, isFull: true }
  } else if (r.performedString) {
    result = { dateString: r.performedString, isFull: false }
  }

  return { ...result, formatted: decodeAndFormatDateObject(result) }
}

export function getAsString(data, ctx, pathList = [], output = ': ') {
  let result = ''
  let tmp = []

  _.forEach(pathList, (path) => {
    const value = getResourceExtractorValueByPath(data, ctx, path)

    if (!_.isUndefined(value)) {
      tmp.push({ [path]: value })
    }
  })

  if (_.isFunction(output)) {
    result = output(tmp)
  } else {
    tmp = tmp.map((x) => Object.values(x)[0]).filter((x) => x)
    tmp = _.uniqBy(tmp, (x) => _.toLower(x))

    result = tmp.join(output)
  }

  return result
}

export function resourceExtractor(event = {}, resource = {}, executed = true, props) {
  const config = extractorScheme(event.extractor, props)

  if (!config || _.isEmpty(resource)) {
    return {}
  }

  let result = {}

  _.forEach(config, (method, key, ctx) => {
    if (_.isFunction(method)) {
      result[key] = method.bind(ctx, resource, ctx)
    }
  })

  // Extend all configs
  result.origin = () => {
    const list = [
      'performer',
      'asserter',
      'informationSource',
      'author',
      'operator',
      'reportOrigin',
    ]
    return _.find(Object.keys(result), (x) => list.includes(x))
  }

  if (executed === true) {
    return _.mapValues(result, (x) => (_.isFunction(x) ? x() : x))
  } else if (_.isArray(executed)) {
    let tmp = {}

    _.forEach(executed, (key) => {
      if (_.isFunction(result[key])) {
        tmp[key] = result[key]()
      }
    })

    return tmp
  }

  return result
}

export function getResourceExtractorValueByPath(resource, extractor, path) {
  const [method, ...remainingPath] = _.toPath(path)
  let result

  if (extractor[method]) {
    const value = _.isFunction(extractor[method])
      ? extractor[method](resource, extractor)
      : extractor[method]

    if (remainingPath && remainingPath.length) {
      result = _.get(value, remainingPath)
    } else {
      result = value
    }
  }

  return result
}
