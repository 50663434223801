import { customActions as customAppActions } from '@appNoBrand/config/customActions'

import { routeActions } from '@config/routeActions'

// () => ({onClick, wvAction})
export const customActions = {
  ...customAppActions,
  HANDLE_ADD_PATIENT: null,
  HANDLE_EDIT_PATIENT: null,
  HANDLE_REJECT_PAPERWORK: () => {
    const { wvAction, href } = routeActions.ROOT()
    return {
      onClick: () => window.location.replace(href),
      wvAction: wvAction,
    }
  },
}
