import React from 'react'

import { useTranslation } from 'react-i18next'

import { formatDate } from '@helpers/time'

import RoleName from '@ps/library/RoleName'
import ViewCode from '@ps/library/viewCode/ViewCode'

const ExaminationDetails = ({ event, resource, extractor }) => {
  const { t } = useTranslation()
  const {
    isLegacy,
    valuesAsString,
    values,
    template,
    codeObj,
    subject = {},
    performer = {},
  } = extractor

  return (
    <div className="event_details">
      <div className="event_details-header">
        <div className="event_details-title">{isLegacy ? valuesAsString : template.display}</div>
        <div className="event_details-code">
          <ViewCode codeObj={codeObj} />
        </div>
      </div>
      <div className="event_details-body">
        {!isLegacy &&
          values.map((item) => (
            <div className="event_details-line mb-10" key={item.coding?.display + item.value}>
              <span className="font-weight-700">
                {item.label ? t(item.label) : item.coding?.display}:
              </span>{' '}
              {item.value}
            </div>
          ))}
        <div className="event_details-line">
          {t('ps.event_viewer.subject:')} {subject.value?.fullName}
        </div>
        {!_.isEmpty(performer.value) && (
          <div className="event_details-line">
            {t('ps.event_viewer.performer')} {performer.value?.fullName}{' '}
            <RoleName role={performer.role} resource={performer.value} />
          </div>
        )}
        <div className="event_details-line event_details-line--date">
          {formatDate(event.effectiveDate)}
        </div>
      </div>
    </div>
  )
}

export default ExaminationDetails
