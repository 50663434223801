import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { isAndroid, isIOS, isWindows } from 'react-device-detect'
import { useResizeDetector } from 'react-resize-detector'

import i18n from 'i18next'
import moment from 'moment-timezone'

import { preparePhone } from '@library/phoneNumber/PhoneNumber'

import { getWindowSizes } from '@helpers/other'

import { $main } from '@store'

// todo: rename function
export const useIsWindows = () => {
  useEffect(() => {
    if (isWindows) {
      document.documentElement.classList.add('isWindows')
    } else if (isIOS) {
      document.documentElement.classList.add('isIOS')
    } else if (isAndroid) {
      document.documentElement.classList.add('isAndroid')
    }
  }, [])
}

export const usePhone = (phone) => {
  return useMemo(() => preparePhone(phone), [phone])
}

export const useDocTitle = (title) => {
  let pageTitle = ''

  function getTitle(title) {
    return _.isFunction(title) ? title(i18n.t) : title
  }

  if (_.isObject(title) && title.meta && title.meta.title) {
    pageTitle = getTitle(title.meta.title)
  } else if (_.isString(title)) {
    pageTitle = title
  }

  const [docTitle, setDocTitle] = useState(pageTitle)

  useEffect(() => {
    if (docTitle) {
      document.title = docTitle
    }
  }, [docTitle])

  return [docTitle, setDocTitle]
}

export const useWindowSize = (settings) => {
  useEffect(() => {
    function handleResize() {
      const sizes = getWindowSizes(settings)
      $main.SET_WINDOW_SIZE(sizes)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
}

export const useForceUpdate = () => {
  const [value, setValue] = useState(0)
  return () => setValue((value) => value + 1)
}

export const useFirstRender = () => {
  const firstRender = useRef(true)

  useEffect(() => {
    firstRender.current = false
  }, [])

  return firstRender.current
}

export const useUpdateCallback = (callback) => {
  const isFirstRender = useFirstRender()
  const [updater, setUpdater] = useState(0)

  useEffect(() => {
    if (!isFirstRender) {
      callback()
    }
  }, [updater]) // eslint-disable-line react-hooks/exhaustive-deps

  return () => setUpdater((x) => x + 1)
}

export const useTimer = ({ callback, duration, autoStart = true }) => {
  const [isRunning, setIsRunning] = useState(true)

  const timerId = useRef()
  const started = useRef()
  const remaining = useRef(duration)

  const start = useCallback(() => {
    if (!timerId.current) {
      setIsRunning(true)

      started.current = Date.now()
      clearTimeout(timerId.current)

      timerId.current = setTimeout(() => {
        stop()

        if (_.isFunction(callback)) {
          callback()
        }
      }, remaining.current)
    }
  }, [callback])

  useEffect(() => {
    if (!timerId.current && autoStart) {
      start()
    }

    return () => stop()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  function pause() {
    if (timerId.current) {
      stop()

      remaining.current -= Date.now() - started.current
    }
  }

  function stop() {
    if (timerId.current) {
      setIsRunning(false)
      clearTimeout(timerId.current)
      timerId.current = undefined
    }
  }

  return {
    isRunning,
    started: started.current,
    remaining: remaining.current,
    start,
    pause,
    stop,
  }
}

export const useIntervalTimer = ({
  callback,
  duration = 1000,
  autoStart = true,
  startOnInit = false,
  deps = [],
}) => {
  const isRunning = useRef()

  const timerId = useRef()
  const started = useRef()
  const remaining = useRef(duration)

  const start = useCallback(async () => {
    if (!timerId.current) {
      isRunning.current = true

      if (startOnInit && duration > 0 && _.isUndefined(started.current) && _.isFunction(callback)) {
        await callback()
      }

      started.current = Date.now()
      clearTimeout(timerId.current)

      const func = () =>
        setTimeout(async () => {
          if (!isRunning.current) {
            return
          }

          if (_.isFunction(callback)) {
            await callback()
          }

          timerId.current = func()
        }, remaining.current)

      timerId.current = func()
    }
  }, [callback, isRunning.current]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!timerId.current && autoStart) {
      start()
    }
    return () => {
      stop()
    }
  }, [...deps]) // eslint-disable-line react-hooks/exhaustive-deps

  function pause() {
    if (timerId.current) {
      stop()

      remaining.current -= Date.now() - started.current
    }
  }

  function stop() {
    if (timerId.current) {
      isRunning.current = false
      clearTimeout(timerId.current)
      timerId.current = undefined
    }
  }

  return {
    isRunning: isRunning.current,
    started: started.current,
    remaining: remaining.current,
    start,
    pause,
    stop,
  }
}

export const useInterval = ({
  callback,
  interval = 1000,
  autoStart = true,
  skipStartCallback = false,
  onStart = () => {},
  deps = [],
}) => {
  const [isRunning, setIsRunning] = useState(true)
  const [isStarted, setIsStarted] = useState(false)

  const timerId = useRef()
  const started = useRef()

  const start = useCallback(
    (data) => {
      if (!timerId.current) {
        setIsStarted(true)

        const func = async () => {
          await onStart(data)
          setIsRunning(true)

          started.current = Date.now()
          stop()

          timerId.current = setInterval(() => {
            if (_.isFunction(callback)) {
              callback()
            }
          }, interval)

          if (_.isFunction(callback) && !skipStartCallback) {
            callback()
          }
        }

        func()
      }
    },
    [callback, interval, onStart, skipStartCallback],
  )

  useEffect(() => {
    if (!timerId.current && autoStart) {
      start()
    }

    return () => stop()
  }, [...deps]) // eslint-disable-line react-hooks/exhaustive-deps

  function stop() {
    if (timerId.current) {
      setIsRunning(false)
      clearInterval(timerId.current)
      timerId.current = undefined
    }
  }

  return {
    isRunning,
    isStarted,
    started: started.current,
    start,
    stop,
  }
}

export const useLoader = (callback, deps = []) => {
  let [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    let isActive = true

    const func = async () => {
      setIsLoading(true)
      await callback()

      if (isActive) {
        setIsLoading(false)
      }
    }

    func()

    return () => {
      isActive = false
    }
  }, deps) // eslint-disable-line react-hooks/exhaustive-deps

  return isLoading
}

export const useChatResizeDetector = ({ wrapperRef, stopDetecting = false }) => {
  const [lastHeight, setLastHeight] = useState(0)
  const [lastScrollTop, setLastScrollTop] = useState(0)

  const wrapperOnResize = useCallback(
    (width, height) => {
      const delta = lastHeight - height
      if (lastHeight > 0 && !stopDetecting) {
        wrapperRef.current.scrollTo({ top: lastScrollTop + delta })
      }
      setLastHeight(height)
    },
    [wrapperRef, lastHeight, lastScrollTop, stopDetecting],
  )

  useResizeDetector({ onResize: wrapperOnResize, targetRef: wrapperRef, refreshMode: 'throttle' })

  return { setLastScrollTop }
}

export const useNowEveryMinute = (timezone) => {
  let now = moment().tz(timezone)
  const [minutes, setMinutes] = useState(now.minutes())

  useInterval({
    callback: () => {
      now = moment().tz(timezone)

      if (now.seconds() === 0) {
        setMinutes(now.minutes())
      }
    },
  })

  return now
}

export const useChangeScrollDirection = (callback) => {
  const ref = useRef()

  useEffect(() => {
    const element = ref.current

    function onScrollHandle(e) {
      if (_.isFunction(callback)) {
        callback(e)
      }

      if (e.shiftKey) {
        return false
      }

      let isTrackpad = false

      if (e.wheelDeltaY) {
        if (e.wheelDeltaY === e.deltaY * -3) {
          isTrackpad = true
        }
      } else if (e.deltaMode === 0) {
        isTrackpad = true
      }

      if (isTrackpad) {
        return false
      }

      if (e.wheelDeltaY !== 0) {
        element.scrollLeft -= e.wheelDelta
        e.preventDefault()
      }
    }

    element.addEventListener('wheel', onScrollHandle, { passive: false })
    return () => element.removeEventListener('wheel', onScrollHandle, { passive: false })
  }, [callback])

  return ref
}

export const usePageLoaderGetGroups = ({ items, page, counter, perPage = 10, pathToId = 'id' }) => {
  return useMemo(() => {
    let result = []

    const grouped = _.chunk(_.cloneDeep(items), perPage)

    _.forEach(grouped, (items) => {
      let id = items.map((x) => _.get(x, pathToId)).join('_')

      if (Number(page) === 1) {
        id = counter + id
      }

      result.push({ id, items })
    })

    return result
  }, [items, page]) // eslint-disable-line react-hooks/exhaustive-deps
}

export const useInnerValue = (value) => {
  const [innerValue, setInnerValue] = useState(value)

  useEffect(() => {
    if (!_.isEqual(value, innerValue)) {
      setInnerValue(value)
    }
  }, [value]) //eslint-disable-line react-hooks/exhaustive-deps

  return [innerValue, setInnerValue]
}

export const useHeightAnimation = (isOpen = false, duration = 200) => {
  const contentRef = useRef() // Inner wrapper that keeps the height if the outer animated is closed
  const [height, setHeight] = useState(isOpen ? 'auto' : 0)

  const handleClick = () => {
    const contentRect = contentRef.current.getBoundingClientRect()

    // Set the height so the element has an initial height to transition from
    setHeight(`${contentRect.height}px`)

    // Set the new desired height, either 0px or full height from the innerRef
    setTimeout(() => setHeight(height === 0 ? `${contentRect.height}px` : 0))

    setTimeout(
      () => {
        if (height === 0) {
          // Set the height to "auto" to prevent issues with resizing when fully opened
          setHeight('auto')
        }
      },
      duration,
      height,
    )
  }

  const containerStyle = {
    maxHeight: height,
    overflow: height === 'auto' ? 'visible' : 'hidden',
    transition: `max-height ${duration}ms ease-out`,
  }

  return { containerStyle, handleClick, contentRef }
}

export const useDebounce = (value, delay = 500) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const id = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)
    return () => clearTimeout(id)
  }, [value])

  return debouncedValue
}

export const useRemoveRootLoader = (isLoading) => {
  useEffect(() => {
    if (isLoading) return
    const rootLoader = document.querySelector('#root-loader')
    const rootLoaderStyles = document.querySelector('#root-loader-styles')

    rootLoader.classList.add('root-loader--hide')
    setTimeout(() => {
      rootLoader?.remove()
      rootLoaderStyles?.remove()
    }, 300)
  }, [isLoading])
}
