import React, { useEffect, useMemo, useState } from 'react'

import { toJS } from 'mobx'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import HistoryTablePage from '@ps/library/historyTable/HistoryTablePage'

import './HistoryTable.scss'

const HistoryTable = ({
  tableItems = [],
  isLoading = false,
  pageLimit,
  mode,
  headComponent: HeadComponent,
  pageComponent: PageComponent,
}) => {
  const { t } = useTranslation()

  const [page, setPage] = useState(1)
  const [pagesInLoading, setPagesInLoading] = useState([])

  const allPages = useMemo(() => {
    return Math.ceil(tableItems.length / pageLimit)
  }, [tableItems, pageLimit])

  useEffect(() => setPage(1), [allPages])

  function increasePage() {
    setPage((x) => {
      if (x + 1 <= allPages) {
        return x + 1
      }
      return x
    })
  }

  return (
    <div
      className={classNames(
        'h_table',
        !tableItems.length && 'h_table--empty',
        mode && 'h_table--' + mode,
      )}
    >
      <HeadComponent />
      <div className={classNames('h_table-tbody', isLoading && 'h_table-tbody--loading')}>
        {tableItems.length > 0 ? (
          [...Array(page)].map((x, i) => (
            <HistoryTablePage
              key={[i, allPages, tableItems.length, tableItems?.[0].id].join()}
              pageIndex={i}
              page={page}
              allPages={allPages}
              increasePage={increasePage}
              pagesInLoading={pagesInLoading}
              setPagesInLoading={setPagesInLoading}
              tableItems={tableItems}
              pageLimit={pageLimit}
              pageComponent={PageComponent}
            />
          ))
        ) : (
          <>{t('ps.label.no_results_found')}</>
        )}
      </div>
    </div>
  )
}

export default HistoryTable
