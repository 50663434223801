import React, { useEffect, useMemo, useRef, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import Button from '@library/ui/button/Button'

import styles from './TabsBarDesktop.module.scss'

const TabsBarDesktop = ({
  mode,
  modeSelected,
  items,
  selected,
  selectAll,
  onChange,
  autoTarget,
  moreButtonText = 'btn.more',
  moreModalParams,
}) => {
  const { t } = useTranslation()
  const wrapperRef = useRef(null)

  function getButtonWith(className) {
    const el = wrapperRef.current?.querySelector('.' + className)
    return el ? el.getBoundingClientRect().width : 0
  }

  const [visibleItems, setVisibleItems] = useState([])

  useEffect(() => {
    function handleResize() {
      if (!wrapperRef.current) return
      let result = []

      const containerWidth = wrapperRef.current.getBoundingClientRect().width
      const btnAllWidth = getButtonWith('select-all')
      const btnMoreWidth = getButtonWith('select-more')

      let availableWidth = containerWidth - btnAllWidth - btnMoreWidth

      items.forEach((x) => {
        const btnWidth = getButtonWith(`select-id-${x.id}`) + 8 // 8px margin between buttons

        if (availableWidth - btnWidth > 0) {
          availableWidth -= btnWidth
          result.push(x.id)
        }
      })

      setVisibleItems(result)
    }

    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [selected, items])

  const defaultMoreText = t(moreButtonText)
  const moreBtnText = useMemo(() => {
    if (selected && items.length && !visibleItems.includes(selected)) {
      return items.find((x) => x.id === selected).text
    } else {
      return defaultMoreText
    }
  }, [selected, items, visibleItems, defaultMoreText])

  return (
    <div ref={wrapperRef} className={styles.wrapper}>
      <div className={classNames(styles.selectAll, 'select-all')}>
        {selectAll && (
          <Button
            mode={selected ? mode : modeSelected}
            className={classNames(styles.btn, styles.btnVisible)}
            action={() => onChange(null)}
          >
            {selectAll}
          </Button>
        )}
      </div>
      {items.map((x, index) => (
        <Button
          key={index}
          mode={selected === x.id ? modeSelected : mode}
          className={classNames(
            styles.btn,
            visibleItems.includes(x.id) && styles.btnVisible,
            `select-id-${x.id}`,
          )}
          action={() => onChange && onChange(x.id)}
          autoTarget={autoTarget && autoTarget(x.id)}
        >
          {x.text}
        </Button>
      ))}
      <Button
        mode={moreBtnText !== defaultMoreText ? modeSelected : mode}
        className={classNames(
          styles.btn,
          styles.btnMore,
          items.length && items.length > visibleItems.length && styles.btnVisible,
          'select-more',
        )}
        action={moreModalParams}
      >
        {moreBtnText}
      </Button>
    </div>
  )
}

export default observer(TabsBarDesktop)
