import React, { useMemo, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import { useChangeScrollDirection } from '@library/CustomHooks'
import Document from '@library/document/Document'

import { formatDate } from '@helpers/time'

import { openEventModal } from '@ps/helpers/events'

import styles from './DocumentsList.module.scss'

const DocumentsList = ({ items }) => {
  const { t } = useTranslation()

  const [viewAll, setViewAll] = useState(false)
  const ref = useChangeScrollDirection()

  const preparedList = useMemo(() => {
    let result = []

    _.forEach(_.groupBy(items, 'configId'), (item) => {
      const first = _.first(item)

      if (first) {
        result.push({
          id: first.configId,
          label: first.label,
          text: formatDate(first.effectiveDate),
          items: _.map(item, (x) => ({ event: x })),
        })
      }
    })

    return result
  }, [items])

  return (
    <div className="ps_documents mb-30">
      <div className="ps_documents-title simple_header mb-10">
        <div className="simple_header-title title-sm mb-0">{t('ps.label.personal_documents')}</div>
        {preparedList.length > 4 && (
          <div className="simple_header-link" onClick={() => setViewAll((x) => !x)}>
            {viewAll ? t('ps.btn.minimize') : t('ps.btn.view_all')}
          </div>
        )}
      </div>
      <div className="ps_documents-group ps_documents-group--has-scroll">
        <div
          className={classNames(
            'ps_documents-group-scroll',
            viewAll && 'ps_documents-group-scroll--wrapped',
          )}
          ref={ref}
        >
          {preparedList.map((item) => (
            <Document
              key={item.id}
              className={styles.document}
              onClick={() => openEventModal(item.items)}
              title={t(item.label)}
              status={item.text}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default observer(DocumentsList)
