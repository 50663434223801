var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { Chart } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import helpers from '@library/trendsList/trendsListChart/TrendsListChart.helpers';
import styles from './TrendsListChart.module.scss';
var TrendsListChart = function (_a) {
    var value = _a.value, config = _a.config, className = _a.className;
    var t = useTranslation().t;
    var labels = useMemo(function () {
        var stringDates = value.items.flatMap(function (item) { return item.map(function (item) { return item.date; }); });
        var dates = stringDates.map(function (date) { return new Date(date).getTime(); });
        var minDate = Math.min.apply(Math, dates);
        var maxDate = Math.max.apply(Math, dates);
        return helpers.getRangeOfDates(minDate, maxDate, 4);
    }, [value]);
    return (_jsxs("div", __assign({ className: classNames(styles.container, className) }, { children: [_jsx("div", __assign({ className: styles.title }, { children: t(config.label) })), _jsx("div", __assign({ className: styles.chart }, { children: _jsx(Chart, { type: 'line', data: {
                        labels: labels,
                        datasets: value.items.map(function (item, index) {
                            var _a, _b, _c, _d;
                            return ({
                                data: item.map(function (item) { return ({ y: item.value, x: item.date }); }),
                                fill: true,
                                borderColor: (_a = config.chart[index]) === null || _a === void 0 ? void 0 : _a.lineColor,
                                backgroundColor: (_b = config.chart[index]) === null || _b === void 0 ? void 0 : _b.backgroundColor,
                                // When there is only one point
                                pointRadius: item.length < 2 ? 3 : 0,
                                pointBackgroundColor: item.length < 2 ? (_c = config.chart[index]) === null || _c === void 0 ? void 0 : _c.lineColor : '#fff',
                                pointHoverBorderColor: item.length < 2 ? (_d = config.chart[index]) === null || _d === void 0 ? void 0 : _d.lineColor : undefined,
                            });
                        }),
                    }, options: {
                        elements: {
                            line: {
                                borderWidth: 3,
                                tension: 0.5,
                                cubicInterpolationMode: 'monotone',
                            },
                        },
                        plugins: {
                            legend: { display: false },
                            tooltip: { enabled: false },
                        },
                        scales: {
                            x: {
                                type: 'time',
                                time: {
                                    unit: 'millisecond',
                                    displayFormats: { millisecond: 'MM/YYYY' },
                                },
                                ticks: {
                                    display: true,
                                    source: 'labels',
                                    color: 'rgba(117, 117, 117, 1)',
                                    font: { size: 12 },
                                },
                                grid: { display: false, drawBorder: false },
                            },
                            y: {
                                grid: { drawBorder: false },
                                ticks: {
                                    count: 4,
                                    callback: function (label) { return Math.round(+label * 10) / 10; },
                                    color: 'rgba(117, 117, 117, 1)',
                                    font: { size: 12 },
                                },
                            },
                        },
                    } }) }))] })));
};
export default TrendsListChart;
