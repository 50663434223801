import React, { useState } from 'react'

import { observer } from 'mobx-react'

import classNames from 'classnames'

import Modal from '@library/modal/Modal'

import AddContact from '@pages/profile/profileContacts/AddContact'
import ContactList from '@pages/profile/profileContacts/ContactList'
import SelectChannel from '@pages/profile/profileContacts/SelectChannel'

const ProfileContactsModal = ({ _core }) => {
  let [view, setView] = useState('list')
  let [current, setCurrent] = useState({})

  return (
    <Modal
      centered
      heightAuto
      scrollable={view !== 'phone'}
      className={classNames(
        view === 'phone' && 'profile_contacts_phone',
        view === 'email' && 'profile_contacts_email',
      )}
    >
      {view === 'channel' ? (
        <SelectChannel key={view} setView={setView} _core={_core} />
      ) : ['phone', 'phoneActivate'].includes(view) ? (
        <AddContact key={view} setView={setView} type="phone" value={current.value} _core={_core} />
      ) : ['email', 'emailActivate'].includes(view) ? (
        <AddContact key={view} setView={setView} type="email" value={current.value} _core={_core} />
      ) : (
        <ContactList key={view} setView={setView} setCurrent={setCurrent} _core={_core} />
      )}
    </Modal>
  )
}

export default observer(ProfileContactsModal)
