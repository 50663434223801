import React from 'react'

const HomeMarker = ({ color = '#ffffff' }) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.0013 10.7098L15.6683 6.56177C14.7054 5.81274 13.3571 5.81274 12.3943 6.56177L7.06025 10.7098C6.41065 11.2149 6.03086 11.9919 6.03125 12.8148V20.0148C6.03125 21.1193 6.92668 22.0148 8.03125 22.0148H20.0312C21.1358 22.0148 22.0312 21.1193 22.0312 20.0148V12.8148C22.0312 11.9918 21.6513 11.2148 21.0013 10.7098"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 16.0001C15.79 17.3331 12.208 17.3331 10 16.0001"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default HomeMarker
