import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Transition } from 'react-spring/renderprops'

import BookingLayout from '@booking/library/bookingLayout/BookingLayout'
import classNames from 'classnames'

import clientService from '@services/client'

import { confirmAction } from '@library/ActionConfirmator'
import { getPresetGroup } from '@library/animations/Animations'
import Checkbox from '@library/Checkbox'
import BubbleLoader from '@library/ui/bubbleLoader/BubbleLoader'
import Button from '@library/ui/button/Button'

import { getConfigByCountry } from '@helpers/countries'
import { getRandomNumber } from '@helpers/other'
import { customGoTo } from '@helpers/router'

import { $alerts, $booking } from '@store'

import mainConfig from '@config/main'
import { routeActions } from '@config/routeActions'

import styles from './BookingChat.module.scss'

const isDev = _.includes(['dev', 'local'], process.env.REACT_APP_ENV)
const queueTimeout = isDev ? 0 : 160

const BookingChat = ({ setChat, skipSymptoms, breadcrumbs, onClickContinue }) => {
  const { t } = useTranslation()

  const [answers, setAnswers] = useState(isDev ? [1] : [])
  const [symptoms, setSymptoms] = useState([])
  const [answerVariants, setAnswerVariants] = useState([])
  const [symptomsVariants, setSymptomsVariants] = useState(false)

  const emergencyNumber = useMemo(() => {
    const place = $booking.$places.getById($booking.placeId)
    return getConfigByCountry(place.country).emergencyNumber
  }, [$booking.placeId]) // eslint-disable-line react-hooks/exhaustive-deps

  function handleAnswers(isChecked, name) {
    if (isChecked) {
      setAnswers((i) => [name])
    } else {
      setAnswers((i) => i.filter((el) => el !== name))
    }
  }

  function handleSymptoms(isChecked, name) {
    if (isChecked) {
      setSymptoms((i) => [...i, name])
    } else {
      setSymptoms((i) => i.filter((el) => el !== name))
    }
  }

  const [button, setButton] = useState('initial')

  const [isTyping, setIsTyping] = useState('initial')

  let isRest = useRef(true)
  let questions = useRef({})
  let questionIndex = useRef(false)
  let diagnosisId = useRef(false)
  let allAnswers = useRef([])
  let flowAnswers = useRef([])
  let skippedSymptoms = useRef([])

  const wrapperRef = useRef(null)
  const innerRef = useRef(null)

  function forceNoOne() {
    skippedSymptoms.current = [
      ...skippedSymptoms.current,
      ..._.pullAll(symptomsVariants.map((i) => i.id).slice()),
    ]

    const newMsg = {
      text: t('symptoms_chat.answers.none'),
      isUser: true,
      callback: () => checkSymptoms(),
    }

    setSymptoms([])
    setSymptomsVariants(false)

    setTimeout(() => {
      setQueue((q) => [...q, newMsg])
    }, queueTimeout)
  }

  function submitSymptoms() {
    $booking.$symptoms.SET_SELECTED(symptoms)
    skippedSymptoms.current = [
      ...skippedSymptoms.current,
      ..._.pullAll(symptomsVariants.map((i) => i.id).slice(), ...symptoms),
    ]
    const newMsg = {
      text:
        t('symptoms_chat.messages.symptom_is') +
        '\n' +
        symptomsVariants
          .filter((i) => symptoms.indexOf(i.id) !== -1)
          .map((i) => '- ' + _.startCase(i.text))
          .join('\n'),
      isUser: true,
      callback: () => {
        checkSymptoms()
      },
    }
    setSymptoms([])
    setSymptomsVariants(false)

    setTimeout(() => {
      setQueue((q) => [...q, newMsg])
    }, queueTimeout)
  }

  const getSelectedMessages = () => {
    if (!$booking.$symptoms.combinedSelectedSymptomsIds.length) {
      return {
        isUser: true,
        text: t('symptoms_chat.messages.cant_found'),
        callback: ziphyThinking(checkSymptoms),
      }
    }
    return {
      isUser: true,
      text:
        t('symptoms_chat.messages.i_selected') +
        '\n' +
        $booking.$symptoms.combinedSelectedSymptomsIds
          .map((el) => '- ' + _.startCase($booking.$symptoms.getSymptomName(el)))
          .join('\n'),
      callback: ziphyThinking(checkSymptoms),
    }
  }

  function submitAnswers() {
    const answered = answerVariants[answers[0]]

    flowAnswers.current = [
      ...flowAnswers.current,
      [diagnosisId.current, questionIndex.current, answers[0]],
    ]
    if (isDev) {
      setAnswers([1])
    } else {
      setAnswers([])
    }
    setAnswerVariants([])
    setSymptomsVariants(false)

    const newMsg = {
      text: answered.text,
      isUser: true,
      callback: () => {
        if (answered.next) {
          askNext(answered.next)
        } else {
          allAnswers.current = [...allAnswers.current, ...flowAnswers.current]
          flowAnswers.current = []
          checkSymptoms()
        }
      },
    }

    setTimeout(() => setQueue((q) => [...q, newMsg]), queueTimeout)
  }

  function askNext(key) {
    const currentQuestion = _.find(questions.current, (x) => x.key === key)
    questionIndex.current = key

    setIsTyping('waiting')
    setQueue((q) => [...q, { ...currentQuestion.value, hold: 'answering' }])
  }

  function askFlow(diagnoses = []) {
    const diagnosis = _.find(diagnoses, (x) => x.decision === 'ask')

    questions.current = diagnosis.questions?.items || []
    diagnosisId.current = diagnosis.id

    const currentQuestion =
      _.find(questions.current, (x) => x.value?.root) || _.first(questions.current)
    questionIndex.current = currentQuestion.key

    setIsTyping('waiting')
    setQueue((q) => [...q, { ...currentQuestion.value, hold: 'answering' }])
  }

  function prepareAnswers(answers) {
    let result = {}

    function getValue(val) {
      const items = val.slice()

      if (items.length === 1) {
        return items.shift()
      }

      const key = items.shift()
      return { [key]: getValue(items) }
    }

    _.forEach(answers, (answer) => {
      result = _.merge(result, getValue(answer))
    })

    return result
  }

  const dispatchChecked = useCallback(
    (checked) => {
      const { decision, diagnoses = [], askSymptomIds = [] } = checked

      if (
        (decision === 'accept' && !$booking.$symptoms.hasSelectedSymptomsInPractices) ||
        (decision === 'ask' &&
          askSymptomIds.length &&
          !$booking.$symptoms.hasSelectedSymptomsInPractices)
      ) {
        setQueue((q) => [
          ...q,
          {
            text: t('symptoms_chat.results.noServedPractices'),
            callback: () => {
              setIsTyping(false)
              setButton('noServedPractices')
            },
          },
        ])
        return
      }

      if (decision === 'accept' && askSymptomIds.length) {
        const res = _.pullAll(askSymptomIds.slice(), skippedSymptoms.current)

        if (res.length) {
          setIsTyping('waiting')
          setQueue((q) => [
            ...q,
            {
              text: t('symptoms_chat.results.symptoms'),
              hold: 'answering',
              callback: () => {
                setSymptomsVariants(
                  res.map((symptom) => {
                    return { id: symptom, text: $booking.$symptoms.getSymptomName(symptom) }
                  }),
                )
                setButton('waiting')
              },
            },
          ])
          return
        }
      }

      switch (decision) {
        case 'reject':
          setIsTyping('waiting')
          setQueue((q) => [
            ...q,
            {
              danger: true,
              text: t('symptoms_chat.results.reject', { number: emergencyNumber }),
              hold: 'finished',
              callback: () => {
                setAnswerVariants(false)
                setButton('reject')
              },
            },
          ])
          break
        case 'ask':
          askFlow(diagnoses.items)
          break
        case 'accept':
          setIsTyping('waiting')
          setQueue((q) => [
            ...q,
            {
              text: t('symptoms_chat.results.accept'),
              hold: 'finished',
              callback: () => {
                setAnswerVariants(false)
                setButton('accept')
              },
            },
          ])
          break
        default:
          break
      }
    },
    [$booking.$symptoms.getSymptomName, emergencyNumber], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const checkSymptoms = useCallback(async () => {
    const checked = await clientService.checkSymptoms(
      $booking.$symptoms.selectedIds,
      prepareAnswers(allAnswers.current),
    )
    if (checked) {
      dispatchChecked(checked)
    }
  }, [$booking.$symptoms.selectedIds, dispatchChecked])

  function ziphyThinking(onShow) {
    return () => {
      setIsTyping('waiting')
      onShow()
    }
  }

  const [messages, setMessages] = useState([])
  const [queue, setQueue] = useState([getSelectedMessages])

  // inner
  const formIsValid = useMemo(() => !isTyping || 'answering' === isTyping, [isTyping])

  // show message
  const showMessage = useCallback(
    (msg = {}) => {
      if (!wrapperRef || !wrapperRef.current) return false
      if (!innerRef || !innerRef.current) return false

      if (msg.answers && msg.answers.length) {
        setIsTyping('answering')
        setAnswerVariants(msg.answers)
      }

      setMessages((prev) => [...prev, msg])
    },
    [setMessages, wrapperRef, innerRef],
  )

  const upQueue = useCallback(() => {
    if (queue.length > 0) {
      const queueItem = queue.shift()
      let newMessage = false

      if (_.isFunction(queueItem)) {
        newMessage = queueItem()
      } else {
        newMessage = queueItem
      }

      if (newMessage === false) {
        if (!queue.length) {
          setIsTyping((i) => (i === 'thinking' ? i : false))
        } else {
          upQueue()
        }
        return
      }

      if (!newMessage.isUser) {
        setIsTyping('typing')
      }

      setTimeout(
        () => {
          if (newMessage.hold) {
            setIsTyping(newMessage.hold)
          }

          showMessage(newMessage)

          if (newMessage.callback) {
            newMessage.callback()
          }
        },
        newMessage.isUser
          ? 0
          : newMessage.timer
          ? newMessage.timer
          : getTypingDuration(newMessage.text),
      )

      return
    }

    setIsTyping((i) => (['thinking', 'answering', 'waiting'].indexOf(i) !== -1 ? i : false))
  }, [queue, showMessage])

  function onRest() {
    if (!queue.length) {
      isRest.current = true
    }
    upQueue()
  }

  useEffect(() => {
    if (isRest.current) {
      isRest.current = false
      upQueue()
    }
  }, [queue, upQueue])

  function hide(e) {
    confirmAction(
      'customAlert',
      {
        data: {
          title: t('symptoms_chat.hide_alert.title'),
          message: t('symptoms_chat.hide_alert.message'),
          done: t('symptoms_chat.hide_alert.done'),
          cancel: t('symptoms_chat.hide_alert.cancel'),
        },
        callback: () => customGoTo(routeActions.BOOK_START()),
      },
      e,
    )
  }

  function getTypingDuration(text = '') {
    if (isDev) {
      return 0
    }
    return Math.max(Math.min(text.length * 10, 900), getRandomNumber(600, 1200))
  }

  async function continueFlow() {
    $booking.$symptoms.SET_ANSWERS(prepareAnswers(allAnswers.current))
    onClickContinue()
  }

  function handleGoIt() {
    showLastMessageAsNotification()
    customGoTo(routeActions.BOOK_START())
  }

  function handleCallEmergency() {
    customGoTo(routeActions.BOOK_START())
    window.open('tel:' + emergencyNumber, '_self')
  }

  function handleChangeSymptoms() {
    setChat(false)
    $booking.$symptoms.clear()
  }

  function showLastMessageAsNotification() {
    if (!messages.length) {
      return false
    }

    const lastMessage = messages[messages.length - 1]

    if (lastMessage.text) {
      $alerts.add(lastMessage.text, { duration: 10000 })
    }
  }

  return (
    <BookingLayout
      title={t('book.ph.symptoms')}
      breadcrumbs={breadcrumbs}
      isMobileFooterFixed={true}
      className={styles.layout}
      button={
        <>
          {symptomsVariants ? (
            <>
              <Button
                mode={['primary', 'block']}
                tag="button"
                buttonType="button"
                disabled={!formIsValid || !symptoms.length}
                action={submitSymptoms}
                label={t('btn.continue')}
              />
              <Button
                mode={['secondary', 'block']}
                tag="button"
                buttonType="button"
                disabled={!formIsValid || answers.length}
                action={forceNoOne}
                label={t('btn.none')}
                className={'mt-10'}
              />
            </>
          ) : null}
          {answerVariants.length ? (
            <>
              {answerVariants.length ? (
                <div>
                  {answerVariants.map((answer, index) => (
                    <Checkbox
                      field={{ value: answers, onChange: handleAnswers }}
                      name={index}
                      key={index}
                      label={answer.text}
                      className={styles.checkbox}
                    />
                  ))}
                </div>
              ) : (
                <></>
              )}

              <Button
                mode={['primary', 'block']}
                tag="button"
                buttonType="button"
                disabled={!formIsValid || !answers.length}
                action={submitAnswers}
                label={t('btn.continue')}
                className={'mt-20'}
              />
            </>
          ) : (
            <>
              {button === 'noServedPractices' && (
                <>
                  <Button
                    mode={['primary', 'block']}
                    tag="button"
                    buttonType="button"
                    action={() => setChat(false)}
                    label={t('btn.back')}
                  />

                  <Button
                    mode={['secondary', 'block']}
                    tag="button"
                    buttonType="button"
                    action={hide}
                    label={t('btn.close')}
                    className={'mt-10'}
                  />
                </>
              )}
              {(button === 'initial' || (button === 'waiting' && !symptomsVariants)) && (
                <Button
                  mode={['primary', 'block']}
                  tag="button"
                  buttonType="button"
                  disabled={true}
                  label={t('btn.next')}
                />
              )}
              {button === 'reject' && (
                <>
                  <Button
                    mode={['danger', 'block']}
                    tag="button"
                    buttonType="button"
                    action={handleGoIt}
                    label={t('btn.got_it')}
                  />
                  <Button
                    mode={['danger', 'block']}
                    action={handleCallEmergency}
                    label={t('btn.callEmergency', { number: emergencyNumber })}
                    className={'mt-10'}
                  />
                  {!skipSymptoms && (
                    <div className="text-center mt-20">
                      <div className="a" onClick={handleChangeSymptoms}>
                        {t('symptoms_chat.btn.change_symptoms')}
                      </div>
                    </div>
                  )}
                </>
              )}
              {button === 'accept' && (
                <Button
                  mode={['primary', 'block']}
                  tag="button"
                  buttonType="button"
                  action={continueFlow}
                  label={t('btn.continue')}
                />
              )}
            </>
          )}
        </>
      }
      isDesktopFooterFixed={true}
      isLoading={$booking.$symptoms.isLoading}
    >
      <div ref={wrapperRef} className={classNames(styles.chat)}>
        <div ref={innerRef} className={classNames(styles.chatInner, 'styled_scroll')} dir={'rtl'}>
          {symptomsVariants?.length ? (
            <div
              className={classNames('tsform-join-group d-block', styles.chatVariants)}
              dir={'ltr'}
            >
              {symptomsVariants.map((symptom, index) => (
                <Checkbox
                  field={{ value: symptoms, onChange: handleSymptoms }}
                  name={symptom.id}
                  key={index}
                  labelClass={'text-capitalize'}
                  label={symptom.text}
                  className={styles.checkbox}
                />
              ))}
            </div>
          ) : null}
          <div className={styles.chatMessages} dir={'ltr'}>
            <Transition
              {...getPresetGroup('chatMessages')}
              items={messages}
              keys={(_, index) => index}
              onRest={onRest}
            >
              {(item) =>
                item &&
                ((style) => (
                  <div
                    className={classNames(
                      styles.chatMessage,
                      item.isUser ? styles.chatMessageRight : styles.chatMessageLeft,
                      item.danger && styles.chatMessageDanger,
                    )}
                    style={style}
                  >
                    <div className={styles.chatMessageInner}>{item.text.trim()}</div>
                  </div>
                ))
              }
            </Transition>
          </div>
        </div>
        {isTyping && ['typing', 'waiting'].indexOf(isTyping) !== -1 && (
          <div className={styles.status}>
            {t('symptoms_chat.statuses.' + isTyping, { appName: mainConfig.appName })}
            <BubbleLoader />
          </div>
        )}
      </div>
    </BookingLayout>
  )
}

export default observer(BookingChat)
