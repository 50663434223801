import React, { useEffect } from 'react'

import { observer } from 'mobx-react'

import Alert from '@library/alerts/Alert'

import { $alerts } from '@store'

import styles from './Alerts.module.scss'

const AlertsViewer = () => {
  useEffect(() => {
    return () => $alerts.clear()
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {$alerts.items.length > 0 && (
        <div className={styles.alerts}>
          {$alerts.items.map((item) => (
            <Alert options={item} key={item.id} />
          ))}
        </div>
      )}
    </>
  )
}

export default observer(AlertsViewer)
