import React, { useRef } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import Modal from '@library/modal/Modal'
import Stripe from '@library/stripe/Stripe'
import Button from '@library/ui/button/Button'

import { $loader, $pms } from '@store'

import './AddMethod.modal.scss'

const AddPaymentMethodModal = ({ _core }) => {
  const { t } = useTranslation()

  const stripeRef = useRef(null)

  const handleSubmit = $loader.registerHandler('payment-method-add', async (e) => {
    e.preventDefault()

    _core.onBlock()

    const [card, stripe] = stripeRef.current.getData()
    const res = await $pms.add(card, stripe)

    _core.onUnblock()

    if (res.isSuccess && res.paymentMethod) {
      _core.onHide(true, res.paymentMethod)
    }
  })

  return (
    <Modal centered scrollable>
      <div className="modal-toolbar">
        <div className="modal-toolbar-btn modal-toolbar-btn--close" onClick={_core.onHide} />
      </div>
      <Modal.Header>{t('pms_card.title')}</Modal.Header>
      <Modal.Body className="mt--20">
        <form id="add_payment_method_modal" onSubmit={handleSubmit}>
          <Stripe ref={stripeRef} />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          tag="button"
          mode={['primary', 'block']}
          form="add_payment_method_modal"
          isLoading={$loader.isRunHandler('payment-method-add')}
        >
          {t('pms_card.btn.add')}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default observer(AddPaymentMethodModal)
