import moment from 'moment-timezone';
export var useListRef = function () {
    var ref = {};
    var setRef = function (date, format) {
        var key = moment(date).format(format);
        return function (element) {
            if (!(key in ref)) {
                ref[key] = element;
            }
        };
    };
    return [ref, setRef];
};
