import React, { forwardRef, useEffect, useLayoutEffect, useRef, useState } from 'react'

import { inject, observer } from 'mobx-react'
import { useResizeDetector } from 'react-resize-detector'

import classNames from 'classnames'

import { analytics } from '@common/analytics/zipAnalytics'

import { TransitionWrapper } from '@library/animations/Animations'
import ProgressRing from '@library/ui/progressRing/ProgressRing'

import { $modal } from '@store'

import styles from '@assets/styles/_modal.module.scss'

const Modal = (props) => {
  let {
    className,
    innerClassNames,
    dialogClassName,
    //
    duration = 300,
    animation = 'modal',
    overlay = true,
    animationOnShow = true,
    delayBeforeLeave,
    overlayDuration,
    closeOnOverlay = true,
    //
    size,
    centered,
    scrollable,
    headerBordered,
    footerBordered,
    heightAuto,
    fullHeight,
    //
    _core,
    children,
    //
    onHide,
    depsOnHide = [],
  } = props

  const modalContentRef = useRef()

  useEffect(() => _core.onShow(), []) // eslint-disable-line react-hooks/exhaustive-deps

  async function onHideModal() {
    if (closeOnOverlay) {
      if (_.isFunction(onHide)) {
        await onHide()
      }
      _core.onHide(false)
    }
  }

  function handleOnEnter() {
    document.documentElement.classList.add('modal-open')
    analytics.breadcrumb('modal-open', { name: className?.toString() })

    if (_.isFunction(_core.onEnter)) {
      _core.onEnter()
    }
  }

  function handleOnEntered() {
    if (modalContentRef.current) {
      modalContentRef.current.style.removeProperty('transform')
    }
    if (_.isFunction(_core.onEntered)) {
      _core.onEntered()
    }
  }

  function handleOnLeave() {
    analytics.breadcrumb('modal-close', { name: className?.toString() })
    if (_.isFunction(_core.onLeave)) {
      _core.onLeave()
    }
  }

  function handleOnLeaved() {
    $modal.remove(_core.id)

    if (!$modal.current.length) {
      document.documentElement.classList.remove('modal-open')
    }

    if (_.isFunction(_core.onLeaved)) {
      _core.onLeaved()
    }
  }

  useEffect(() => {
    const handleOnEsc = async (e) => {
      let lastModal = $modal.current.slice(-1)[0]
      const isCurrentModal = _core?.id === lastModal?._core?.id

      if (e.key === 'Escape' && isCurrentModal) {
        await onHideModal()
      }
    }
    window.addEventListener('keydown', handleOnEsc)
    return () => window.removeEventListener('keydown', handleOnEsc)
  }, []) //eslint-disable-line react-hooks/exhaustive-deps

  if (!_core) {
    console.error('You should pass "_core" from the parent component.')
    return <></>
  }

  return (
    <div className={classNames(['modal', className])}>
      <div
        className={classNames(
          'modal-dialog',
          size && 'modal-dialog--' + size,
          centered && 'modal-dialog--centered',
          scrollable && 'modal-dialog--scrollable',
          headerBordered && 'modal-dialog--header-bordered',
          _core.footerShadow && 'modal-dialog--footer-shadow',
          footerBordered && 'modal-dialog--footer-bordered',
          heightAuto && 'modal-dialog--height-auto',
          fullHeight && 'modal-dialog--full-height',
          dialogClassName,
        )}
      >
        <TransitionWrapper
          items={_core.show}
          from={animationOnShow && _core.animationOnShow ? undefined : {}}
          // trail={delayBeforeLeave || _core.delayBeforeLeave}
          config={{ duration: overlayDuration || duration }}
        >
          {(item) =>
            item &&
            ((style) => (
              <div
                style={style}
                className={classNames('modal-overlay', !overlay && 'modal-overlay--hidden')}
                onClick={onHideModal}
              />
            ))
          }
        </TransitionWrapper>
        <TransitionWrapper
          items={_core.show}
          preset={animation}
          from={animationOnShow && _core.animationOnShow ? undefined : {}}
          trail={delayBeforeLeave || _core.delayBeforeLeave}
          config={{ duration: duration }}
          onEnter={handleOnEnter}
          onEntered={handleOnEntered}
          onLeave={handleOnLeave}
          onLeaved={handleOnLeaved}
        >
          {(item) =>
            item &&
            ((style) => (
              <div className={classNames(innerClassNames, "modal-content")} ref={modalContentRef} style={style}>
                {children}
              </div>
            ))
          }
        </TransitionWrapper>
        {_core.isBlocked && <div className="modal-blocked-overlay" />}
      </div>
    </div>
  )
}

const ModalHeader = ({ children, className, titleClassName = 'title-md' }) => {
  if (_.isString(children)) {
    children = <div className={classNames(['modal-header-title', titleClassName])}>{children}</div>
  }

  return <div className={classNames(['modal-header', className])}>{children}</div>
}

const ModalBody = forwardRef(
  ({ children, className, isLoading = false, _core, ...passedProps }, forwardedRef) => {
    const defaultRef = useRef(null)
    const ref = forwardedRef ? forwardedRef : defaultRef

    const { height } = useResizeDetector({
      targetRef: ref,
      refreshMode: 'debounce',
      refreshRate: 50,
    })

    useLayoutEffect(() => {
      function updateSize() {
        if (ref.current && ref.current.clientHeight && ref.current.scrollHeight) {
          const modalBodyCH = ref.current.clientHeight
          const modalBodySH = ref.current.scrollHeight
          const result = modalBodyCH < modalBodySH

          $modal.setFooterShadow(_core.id, result)
        }
      }

      window.addEventListener('resize', updateSize)
      updateSize()
      return () => window.removeEventListener('resize', updateSize)
    }, [_core.id, isLoading, height, ref.current?.clientHeight, ref.current?.scrollHeight]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
      <div
        ref={ref}
        className={classNames(['modal-body', className, isLoading && 'modal-body--loading'])}
        {...passedProps}
      >
        {isLoading ? <ProgressRing progress={25} spin={true} /> : children}
      </div>
    )
  },
)

const ModalFooter = ({ children, className, style }) => {
  return (
    <div style={style} className={classNames(['modal-footer', className])}>
      {children}
    </div>
  )
}

const ModalControls = ({ position, items, onPrev, onNext, _core }) => {
  useEffect(() => {
    function onKeyDownHandle(e) {
      if (e.code === 'ArrowLeft') {
        if (_.isFunction(onPrev)) {
          onPrev()
        }
      } else if (e.code === 'ArrowRight') {
        if (_.isFunction(onNext)) {
          onNext()
        }
      } else if (e.code === 'Escape') {
        _core.onHide()
      } else if (e.code === 'Space') {
        // todo: add play if impossible
      }
    }

    document.addEventListener('keydown', onKeyDownHandle)
    return () => document.removeEventListener('keydown', onKeyDownHandle)
  }, [items]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div
        className={classNames(
          styles.controlButtonPrev,
          position <= 0 && styles.controlButtonDisabled,
        )}
        onClick={onPrev}
      />
      <div
        className={classNames(
          styles.controlButtonNext,
          position >= items.length - 1 && styles.controlButtonDisabled,
        )}
        onClick={onNext}
      />
    </>
  )
}

Modal.Header = inject('_core')(observer(ModalHeader))
Modal.Body = inject('_core')(observer(ModalBody))
Modal.Footer = inject('_core')(observer(ModalFooter))
Modal.Controls = inject('_core')(observer(ModalControls))

export default inject('_core')(observer(Modal))
