import React from 'react'

import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import BubbleLoader from '@library/ui/bubbleLoader/BubbleLoader'

import { $alerts } from '@store'

import styles from './CodeResenderCallMe.module.scss'

const CodeResenderCallMe = ({ className = '', loading, onSubmit }) => {
  const { t } = useTranslation()

  const isLoading = loading === 'voice'

  function handleSubmit() {
    onSubmit('voice')
    $alerts.add(t('alert.calling_you_now'))
  }

  return (
    <div className={classNames(styles.CallMe, className, isLoading && styles.CallMeLoading)}>
      {t('code_resender.text.default')}{' '}
      <span onClick={handleSubmit}>
        {isLoading && <BubbleLoader />}
        {t('code_resender.btn.call_me')}
      </span>
    </div>
  )
}

export default CodeResenderCallMe
