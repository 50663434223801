import React from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import Title from '@library/ui/title/Title'

import TabOverviewNoResults from '@ps/pages/patient/tabs/overview/TabOverviewNoResults'
import TimelineChart from '@ps/pages/patient/tabs/overview/timeline/TimelineChart'

import { $psHistory } from '@store'

const TabOverview = () => {
  const { t } = useTranslation()

  return (
    <>
      {$psHistory.events.length > 0 ? (
        <>
          <Title size="h3" className="mb-20">
            {t('label.patient_history')}
          </Title>
          <TimelineChart />
        </>
      ) : (
        <TabOverviewNoResults />
      )}
    </>
  )
}

export default observer(TabOverview)
