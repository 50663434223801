import React, { useEffect, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import ClickOutside from '@library/ClickOutside'
import InputMask from '@library/form/fields/InputMask'

import { getFilteredCountries, PHONE_COUNTRIES } from '@helpers/countries'
import { isAbsoluteEmpty } from '@helpers/other'

import mainConfig from '@config/main'

import './PhoneNumber.scss'

const PhoneNumberInput = ({
  country,
  number: _number = '',
  disabled,
  clear,
  onChange,
  onFocus,
  onToggle,
  onClose,
}) => {
  const { t } = useTranslation()

  const [number, setNumber] = useState(_number)

  const currentCountry = useMemo(() => {
    return _.find(PHONE_COUNTRIES, (x) => x.iso2 === country)
  }, [country])

  const mask = useMemo(() => {
    return currentCountry.mask || mainConfig.phone.defaultMask
  }, [currentCountry])

  const placeholder = useMemo(() => {
    if (currentCountry.mask && _.isString(currentCountry.mask)) {
      return _.replace(currentCountry.mask, /\./g, '5')
    }

    return t('user.ph.phone')
  }, [currentCountry])

  function maskCleaner(value) {
    return (value.match(/\d/g) || []).join('')
  }

  function checkByMask(value, pattern) {
    let vLength = value.length
    let pLength = pattern.replace(/[^.]/g, '').length

    return vLength >= pLength || (pLength >= 11 && vLength >= 7)
  }

  function handleOnChange(formatted) {
    const cleanValue = maskCleaner(formatted)
    setNumber(formatted)

    onChange({
      number: cleanValue,
      formatted: formatted,
      dialCode: currentCountry.dialCode,
      isValid: checkByMask(cleanValue, mask),
    })
  }

  function handleClear() {
    handleOnChange('')
  }

  useEffect(() => {
    const cleanNumber = maskCleaner(number)
    const cleanNewNumber = maskCleaner(_number)

    if (cleanNumber !== cleanNewNumber) {
      setNumber(cleanNewNumber)
    }
  }, [_number]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="phone_number-flag" onClick={onToggle}>
        <div className={classNames('flag-icon', 'flag-icon--' + country)} />
        <div className="phone_number-flag-code">
          {mainConfig.phone.prefix + currentCountry.dialCode}
        </div>
      </div>
      <div className="phone_number-field" onClick={onClose}>
        <InputMask
          key={country}
          type="tel"
          value={number}
          onChange={(e) => handleOnChange(e.target.value)}
          mask={mask}
          maskCleaner={maskCleaner}
          onFocus={onFocus}
          placeholder={placeholder}
          disabled={disabled}
        />
      </div>
      {clear && !isAbsoluteEmpty(number) && (
        <div className="phone_number-clear" onClick={handleClear} />
      )}
    </>
  )
}

const PhoneNumber = (props) => {
  const { t } = useTranslation()

  let {
    country: _country = props.phone.country || 'us',
    number = props.phone.number || '',
    disabled = false,
    bordered,
    onChange,
    onFocus,
    className,
    popupClass = '',
    clear = false,
  } = props

  let [country, setCountry] = useState(_country)
  let [popup, setPopup] = useState(false)
  let [searchString, setSearchString] = useState('')

  const preparedCountries = useMemo(() => {
    let result = getFilteredCountries({
      list: PHONE_COUNTRIES,
      preferred: mainConfig.phone.preferred,
      only: mainConfig.phone.only,
      excluded: mainConfig.phone.excluded,
    })

    if (searchString) {
      let needle = _.trim(searchString).toLowerCase().split(' ')

      result = result.filter((x) => {
        let full = [x.label, mainConfig.phone.prefix + x.dialCode].join(' ').toLowerCase()
        return _.every(needle, (n) => full.includes(n))
      })
    }

    return result
  }, [searchString])

  function togglePopup(e) {
    e.preventDefault()
    setSearchString('')
    !popup ? showPopup() : closePopup()
  }

  function showPopup() {
    if (popup || disabled) return
    setSearchString('')
    setPopup(true)
  }

  function closePopup() {
    if (!popup) return
    setPopup(false)
  }

  function selectCountry(item) {
    setCountry(item.iso2)
    closePopup()
  }

  function inputHandle(value) {
    onChange({ country, ...value })
  }

  function inputFocus() {
    if (_.isFunction(onFocus)) {
      onFocus()
    }
  }

  return (
    <ClickOutside
      className={classNames(
        'phone_number',
        disabled && 'phone_number--disabled',
        bordered && 'phone_number--bordered',
        className,
      )}
      onClick={closePopup}
    >
      <PhoneNumberInput
        country={country}
        number={number}
        onChange={inputHandle}
        onFocus={inputFocus}
        onToggle={togglePopup}
        onClose={closePopup}
        disabled={disabled}
        clear={clear}
        bordered
      />
      {popup && (
        <div className={classNames('phone_number-popup', popupClass)}>
          <div className="phone_number-popup-header">
            <div className="phone_number-popup-search">
              <input
                type="text"
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                placeholder={t('ph.search_your_country')}
              />
            </div>
          </div>
          <div className="phone_number-popup-body styled_scroll">
            {preparedCountries.length ? (
              preparedCountries.map(
                (item) =>
                  item && (
                    <div
                      className={classNames(
                        'phone_number-popup-country',
                        item.iso2 === country && 'phone_number-popup-country--current',
                        // item.preferred && 'phone_number-popup-country--preferred',
                      )}
                      onClick={() => selectCountry(item)}
                      key={item.iso2 + item.dialCode}
                    >
                      <div className="country_name">{item.label}</div>
                      <div className="country_code">{mainConfig.phone.prefix + item.dialCode}</div>
                    </div>
                  ),
              )
            ) : (
              <div />
            )}
          </div>
        </div>
      )}
    </ClickOutside>
  )
}

export default PhoneNumber

export function preparePhone(number) {
  let preparedPhone = { country: 'us', number: '' }

  if (!_.isEmpty(number)) {
    let mostWanted = false
    let mostWantedPrefix = false

    let cleanPhone = number.replace(/\.|\+|\s|-|\(|\)/g, '')

    _.forEach(PHONE_COUNTRIES, (country) => {
      const code = country.dialCode
      const inners = country.areaCodes

      if (cleanPhone.indexOf(code) === 0) {
        if (mostWantedPrefix && mostWantedPrefix.length > code.length) {
          return true
        }

        mostWantedPrefix = code
        mostWanted = country

        if (inners) {
          _.forEach(inners, (inner) => {
            const combined = code.toString() + '' + inner.toString()

            if (cleanPhone.indexOf(combined) === 0) {
              if (mostWantedPrefix && mostWantedPrefix.length > combined.length) {
                return true
              }

              mostWantedPrefix = combined
              mostWanted = country
            }
          })
        }
      }
    })

    if (mostWanted) {
      preparedPhone.country = mostWanted.iso2
      preparedPhone.number = cleanPhone.slice(mostWanted.dialCode.length)
    }
  }

  return preparedPhone
}

export function getPhone(phone) {
  if (_.isEmpty(phone.number)) {
    return null
  }
  const dialCode = phone.dialCode ? phone.dialCode : phone.dial_code
  return mainConfig.phone.prefix + dialCode + phone.number
}
