import React, { useEffect } from 'react'

import { observer } from 'mobx-react'

import { $auth } from '@store'

const Signout = () => {
  useEffect(() => {
    $auth.signout()
  }, [$auth])

  return <></>
}

export default observer(Signout)
