import React, { Fragment, useMemo } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import SymptomItem from '@booking/library/symptomItem/SymptomItem'
import OfficesPhonesModal from '@booking/pages/bookingSymptoms/bookingSelectSymptoms/officesPhonesModal/OfficesPhones.modal'

import Button from '@library/ui/button/Button'

import { $modal } from '@store'

import styles from './AllSymptoms.module.scss'

const AllSymptoms = ({ items, selectedCategory, searchString }) => {
  const { t } = useTranslation()

  const preparedList = useMemo(() => {
    const prepared = selectedCategory ? [selectedCategory] : items

    if (!searchString.length) {
      return prepared
    }

    return prepared
      .map((category) => {
        const filtered = category.symptoms.filter((symptom) => {
          const words = searchString
            .split(/,| /)
            .map((word) => word.trim())
            .filter((e) => e !== ',' && e)
          let isFound = true
          words.forEach((word) => {
            if (symptom.name.toLowerCase().indexOf(word.toLowerCase()) === -1) {
              isFound = false
              return false
            }
          })
          return isFound
        })

        return filtered.length && { ...category, symptoms: filtered }
      })
      .filter((item) => item)
  }, [searchString, items, selectedCategory])

  if (searchString.length && !preparedList.length) {
    return (
      <div className={styles.notFound}>
        <div className="search-no_result">{t('symptoms.errors.not_found')}</div>
        <Button
          mode="secondary"
          tag="button"
          buttonType="button"
          className={styles.button}
          label={t('book.btn.not_experiencing_anything_listed')}
          action={() => $modal.add(OfficesPhonesModal)}
        />
      </div>
    )
  }

  return preparedList.map((item) => (
    <Fragment key={item.category}>
      {selectedCategory ? null : <div className={styles.categoryName}>{item.category}</div>}
      <div className={styles.listContainer}>
        {item.symptoms.map((symptom) => (
          <SymptomItem key={symptom.id} symptom={symptom} />
        ))}
      </div>
    </Fragment>
  ))
}

export default observer(AllSymptoms)
