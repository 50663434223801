import { getAddressByParams } from '@config/countries'

export const PLACE_TEMPLATE = {
  zip: '',
  state: '',
  country: '',
  street: '',
  city: '',
  building: '',
  apartment: '',
  floor: '',
  directions: '', //
  geocoderId: '', // coords: {},
}

export const extendAddress = (place) => {
  let tmp = _.cloneDeep(place) || {}

  tmp.buildingStreetCity = (params) =>
    getAddressByParams(
      {
        building: tmp.building,
        street: tmp.street,
        city: tmp.city,
      },
      params,
    )

  tmp.line1 = (params) =>
    getAddressByParams(
      {
        building: tmp.building,
        street: tmp.street,
        apartment: tmp.apartment,
        floor: tmp.floor,
        directions: tmp.directions,
      },
      params,
    )

  tmp.shortLine1 = (params) =>
    getAddressByParams(
      {
        building: tmp.building,
        street: tmp.street,
      },
      params,
    )

  tmp.line2 = (params) =>
    getAddressByParams(
      {
        city: tmp.city,
        state: tmp.state,
        zip: tmp.zip, // country: tmp.country,
      },
      params,
    )

  tmp.address = (params) =>
    getAddressByParams(
      {
        building: tmp.building,
        street: tmp.street,
        apartment: tmp.apartment,
        floor: tmp.floor,
        directions: tmp.directions,
        city: tmp.city,
        state: tmp.state,
        zip: tmp.zip,
        country: tmp.country,
      },
      params,
    )

  tmp.shortAddress = (params) =>
    getAddressByParams(
      {
        building: tmp.building,
        street: tmp.street,
        city: tmp.city,
        state: tmp.state,
        zip: tmp.zip,
        country: tmp.country,
      },
      params,
    )

  return tmp
}
