import React, { useEffect } from 'react'

import { observer } from 'mobx-react'

import { useIntervalTimer } from '@library/CustomHooks'
import Notice from '@library/notifications/Notice'

import { $notices, $user } from '@store'

import mainConfig from '@config/main'

import styles from './Notifications.module.scss'

const NotificationsViewer = () => {
  const { start, stop } = useIntervalTimer({
    duration: mainConfig.notifications.updateInterval,
    callback: async () => {
      await $notices.getNew()
    },
    autoStart: false,
  })

  useEffect(() => {
    if ($user.user) {
      $notices.clear()
      start()
    } else {
      stop()
      $notices.clear()
    }

    return () => {
      stop()
      $notices.clear()
    }
  }, [$user.user]) //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {$notices.popups.length > 0 && (
        <div className={styles.popupNotices}>
          {$notices.popups.map((item) => (
            <Notice options={item} key={item.id} />
          ))}
        </div>
      )}
    </>
  )
}

export default observer(NotificationsViewer)
