var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import DayPickerCalendar from '@library/newDatePicker/dayPicker/components/dayPickerCalendar/DayPickerCalendar';
import { changeDateMonth, getCalendarRows, } from '@library/newDatePicker/dayPicker/DayPicker.helpers';
import { getScrollToDate } from '@library/newDatePicker/NewDatePicker.helpers';
import { useListRef } from '@library/newDatePicker/NewDatePicker.hooks';
import TimeSlotPickerHooks from '@library/timeSlotPicker/TimeSlotPicker.hooks';
import ProgressRing from '@library/ui/progressRing/ProgressRing';
var DayListPicker = forwardRef(function (_a, ref) {
    var currentDate = _a.currentDate, selectedDate = _a.selectedDate, setDate = _a.setDate, minDate = _a.minDate, maxDate = _a.maxDate, _b = _a.shownOtherDays, shownOtherDays = _b === void 0 ? false : _b, availableDates = _a.availableDates, disabledDates = _a.disabledDates, scrollTo = _a.scrollTo, hasPrevLoadTrigger = _a.hasPrevLoadTrigger, hasNextLoadTrigger = _a.hasNextLoadTrigger;
    var minDateString = minDate.format();
    var maxDateString = maxDate.format();
    var board = useMemo(function () {
        var result = [];
        var dateModel = minDate.clone();
        while (dateModel.isBefore(maxDate, 'month') || dateModel.isSame(maxDate, 'month')) {
            var month = getCalendarRows(dateModel, shownOtherDays);
            // Filter empty week in month
            var filteredMonth = month.map(function (week) {
                var emptyRow = true;
                week.forEach(function (day) {
                    emptyRow = emptyRow && !day.text;
                });
                return !emptyRow ? week : null;
            });
            _.remove(filteredMonth, function (item) { return !item; });
            result.push({ model: dateModel, board: filteredMonth });
            dateModel = changeDateMonth(dateModel, true);
        }
        return result;
    }, [maxDateString, minDateString]); // eslint-disable-line react-hooks/exhaustive-deps
    var _c = useListRef(), refDay = _c[0], setRefDay = _c[1];
    // Scroll to day
    useEffect(function () {
        var scrollToDate = getScrollToDate(selectedDate, currentDate, minDate, maxDate, scrollTo);
        var key = scrollToDate.format('YYYYMMDD');
        if (key in refDay) {
            refDay[key].scrollIntoView({ block: 'center' });
        }
    }, [selectedDate, scrollTo]); // eslint-disable-line react-hooks/exhaustive-deps
    // Fix scroll after adding elements to start
    var _d = TimeSlotPickerHooks.useFixedScroll({
        scrollDependency: minDateString,
        changeContentDependency: maxDateString,
    }), scrollRef = _d.scrollRef, contentRef = _d.contentRef;
    var getHeader = function () {
        return board && board.length ? board[0].board[0] : [];
    };
    return (_jsxs("div", __assign({ className: "day-list-picker" }, { children: [_jsx("div", __assign({ className: "header" }, { children: getHeader().map(function (_a, i) {
                    var value = _a.value;
                    return (_jsx("div", __assign({ className: 'cell' }, { children: value.format('ddd') }), i));
                }) })), _jsx("div", __assign({ className: classNames('body', 'styled_scroll'), ref: scrollRef }, { children: _jsxs("div", __assign({ ref: contentRef }, { children: [hasPrevLoadTrigger && (
                        //       @ts-ignore
                        _jsx("div", __assign({ ref: ref.prevLoaderRef, className: "box-body box-body--padding text-center" }, { children: _jsx(ProgressRing, { className: "", progress: 25, spin: true }) }))), board.map(function (item, i) { return (_jsx(DayPickerCalendar, { currentDate: currentDate, selectedDate: selectedDate, model: item.model, setDate: setDate, minDate: minDate, maxDate: maxDate, availableDays: availableDates === null || availableDates === void 0 ? void 0 : availableDates.day, disabledDays: disabledDates === null || disabledDates === void 0 ? void 0 : disabledDates.day, board: item.board, dayWeek: false, nameMonth: true, setRefDay: setRefDay }, i)); }), hasNextLoadTrigger && (
                        //       @ts-ignore
                        _jsx("div", __assign({ ref: ref.nextLoaderRef, className: "box-body box-body--padding text-center" }, { children: _jsx(ProgressRing, { className: "", progress: 25, spin: true }) })))] })) }))] })));
});
export default DayListPicker;
