import _ from 'lodash'

export const skipAlertErrorCodes = [
  //
  'error.jwt.invalid',
  'error.jwt.expired',
  'error.auth.expired_session',
  'error.auth.high_rate_captcha',
]

function sortErrorMessages(items) {
  return _.orderBy(items, (x) => Object.keys(x.data || {}).length, 'desc')
}

export const customMessages = sortErrorMessages([
  {
    code: 'error.resource.precondition_failed',
    data: { method: 'appointments.rebook' },
    msg: 'api_errors.appointments.rebook',
  },
  {
    code: 'error.resource.precondition_failed',
    data: { method: 'appointments.cancel' },
    msg: 'api_errors.appointments.cancel',
  },
  // internal
  {
    code: 'error.rpc.internal',
    data: { method: 'appointments.create' },
    msg: 'api_errors.appointments.create',
    msgPrefix: 'RPC Internal: ',
  },
  {
    code: 'error.rpc.internal',
    msg: 'api_errors.default',
    msgPrefix: 'RPC Internal: ',
  },
  {
    code: 'error.catch.internal',
    msg: 'api_errors.default',
    msgPrefix: 'API CATCH: ',
  },
])
