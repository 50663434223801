import React, { useMemo } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import SelectModal from '@library/select/Select.modal'
import Toolbar from '@library/toolbar/Toolbar'
import Button from '@library/ui/button/Button'
import InfoBox from '@library/ui/infoBox/InfoBox'
import PatientInfoBox from '@library/ui/patientInfoBox/PatientInfoBox'
import UserPhoto from '@library/user/UserPhoto'

import EditProfileModal from '@pages/profile/EditProfile.modal'
import ProfileContactsModal from '@pages/profile/ProfileContacts.modal'

import { changeCurrentLanguage } from '@helpers/i18n'
import { getUserText } from '@helpers/user'

import { $modal, $user } from '@store'

import { LANGUAGES } from '@config/countries'
import { routeActions } from '@config/routeActions'

const Profile = () => {
  const { t, i18n } = useTranslation()
  const { profile } = $user

  const availableLangs = useMemo(() => LANGUAGES.map((x) => ({ id: x.locale, text: x.name })), [])
  const onChangeLang = (locale) => {
    if (i18n.language === locale) return
    changeCurrentLanguage(locale)
  }

  return (
    <div className="content">
      <Toolbar goBackProps={{ autoTarget: routeActions.HOME() }} />
      <div className="container">
        <div className="box">
          <div className="box-header">
            <div className="box-header-title title-lg mb-0">{t('profile.title')}</div>
          </div>
          <div className="box-body box-body--padding">
            <div className="box box--md">
              <PatientInfoBox
                onClick={() => $modal.add(EditProfileModal)}
                value={getUserText(profile, ['fullName'])}
                label={getUserText(profile, ['gender', 'yo'])}
                className="mb-10"
                mode="arrow"
                photo={<UserPhoto item={profile} />}
              />

              <InfoBox
                onClick={() => $modal.add(ProfileContactsModal)}
                value={t('profile.label.contacts')}
                clickable
                className="mb-10"
                mode={['lg', 'gray', 'icon']}
              />

              {availableLangs.length > 1 && (
                <InfoBox
                  onClick={() =>
                    $modal.add(SelectModal, {
                      title: t('profile.label.language'),
                      items: availableLangs,
                      value: i18n.language,
                      allowUnselect: false,
                      onChange: (v) => onChangeLang(v[0]),
                    })
                  }
                  value={t('profile.label.language')}
                  clickable
                  className="mb-10"
                  mode={['lg', 'gray', 'icon']}
                />
              )}

              <div className="btn-group">
                <Button
                  label={t('profile.btn.signout')}
                  mode={['secondary-dark', 'block']}
                  autoTarget={routeActions.SIGNOUT()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(Profile)
