import { trendsListConfig } from '@library/trendsList/TrendsList.config';
import { getBodyMassIndex } from '@helpers/converters';
import { getCodeObject } from '@ps/helpers/resourceExtractor';
var getPreparedItem = function (item, valueQuantity) {
    var value = valueQuantity.value;
    return {
        date: item.effectiveDateTime,
        value: value,
        valueAsString: [value, valueQuantity.unit].join(' '),
        id: item.id,
    };
};
export var measurementsResponseAdapter = function (groups) {
    var prepared = [];
    _.forEach(groups, function (group) {
        var codeObj = getCodeObject(group.code);
        var result = {
            label: codeObj === null || codeObj === void 0 ? void 0 : codeObj.display,
            code: codeObj === null || codeObj === void 0 ? void 0 : codeObj.code,
            unit: '',
            items: [],
        };
        var uniqMap = new Map();
        var setUnit = function (unit) {
            if (!result.unit && unit) {
                result.unit = unit;
            }
        };
        var isUniq = function (preparedItem, index) {
            if (index === void 0) { index = 0; }
            var uniqKey = [preparedItem.date, preparedItem.value, index].join('|');
            if (uniqMap.get(uniqKey))
                return false;
            uniqMap.set(uniqKey, true);
            return true;
        };
        _.forEach(group.resources, function (item) {
            if (!item.valueQuantity) {
                _.forEach(item.component, function (component, index) {
                    setUnit(component.valueQuantity.unit);
                    var preparedItem = getPreparedItem(item, component.valueQuantity);
                    if (!isUniq(preparedItem, index))
                        return;
                    if (!result.items[index])
                        result.items[index] = [];
                    result.items[index].push(preparedItem);
                });
            }
            else {
                setUnit(item.valueQuantity.unit);
                var preparedItem = getPreparedItem(item, item.valueQuantity);
                if (!isUniq(preparedItem))
                    return;
                if (!result.items.length)
                    result.items[0] = [];
                result.items[0].push(preparedItem);
            }
        });
        if (result.items.length) {
            // blood pressure - overlapping higher line background color with bottom line
            if (result.items.length > 1) {
                result.items.reverse();
            }
            prepared.push(result);
        }
    });
    return prepared;
};
export var getBMIMeasurement = function (height, weight) {
    var result = {
        label: 'Body mass index',
        code: trendsListConfig.bodyMassIndex.code,
        unit: 'bmi',
        items: [],
    };
    _.forEach(weight.items, function (items, arrayIndex) {
        _.forEach(items, function (item, elementIndex) {
            var _a, _b, _c;
            var bmi = getBodyMassIndex(item.value, (_c = (_b = (_a = height.items) === null || _a === void 0 ? void 0 : _a[arrayIndex]) === null || _b === void 0 ? void 0 : _b[elementIndex]) === null || _c === void 0 ? void 0 : _c.value);
            if (!result.items[arrayIndex]) {
                result.items[arrayIndex] = [];
            }
            result.items[arrayIndex].push({
                id: item.id,
                date: item.date,
                value: +bmi,
                valueAsString: bmi,
            });
        });
    });
    return result;
};
