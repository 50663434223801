var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import classNames from 'classnames';
import styles from './ShowMoreDetails.module.scss';
var ShowMoreDetails = function (_a) {
    var _b = _a.items, items = _b === void 0 ? [] : _b, _c = _a.collapsedLabel, collapsedLabel = _c === void 0 ? '' : _c, _d = _a.expanded, _expanded = _d === void 0 ? false : _d, _e = _a.className, className = _e === void 0 ? '' : _e;
    var _f = useState(_expanded), expanded = _f[0], setExpanded = _f[1];
    function handleOpen() {
        setExpanded(!expanded);
    }
    if (!items.length) {
        return _jsx(_Fragment, {});
    }
    if (items.length === 1) {
        return items[0].value;
    }
    return (_jsx("div", __assign({ className: classNames(styles.wrapper, className) }, { children: !expanded ? (_jsx("div", __assign({ className: styles.button, onClick: handleOpen }, { children: collapsedLabel || items[0].value }))) : (items.map(function (item, index) { return (_jsxs("div", __assign({ className: styles.line }, { children: [item.label, " ", item.value] }), item.id || __spreadArray([index], Object.values(item), true).join('_'))); })) })));
};
export default ShowMoreDetails;
