import React, { useEffect, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import classNames from 'classnames'

import RadioTabs from '@library/radioTabs/RadioTabs'
import Toolbar from '@library/toolbar/Toolbar'
import Button from '@library/ui/button/Button'

import styles from '@pages/appts/ApptsSearch.module.scss'
import ApptsSearch from '@pages/appts/ApptsSearch'
import ApptsTabCurrent from '@pages/appts/ApptsTabCurrent'
import ApptsTabHistory from '@pages/appts/ApptsTabHistory'

import { $appts, $main } from '@store'

import { routeActions } from '@config/routeActions'

const Appts = ({ routeConfig }) => {
  const { t } = useTranslation()

  const { patientId } = useParams()
  const preparedPastList = patientId ? $appts.byPatient.pastList : $appts.pastList
  const preparedActiveList = patientId ? $appts.byPatient.activeList : $appts.activeList

  const [view, setView] = useState('current')
  const [fromDate, setFromDate] = useState('')
  const [toDate, setToDate] = useState('')
  const [showFilters, setShowFilters] = useState(false)

  const onClear = () => {
    setFromDate('')
    setToDate('')
  }

  useEffect(() => {
    if ((view) => !view) {
      setShowFilters(false)
      onClear()
    }
  }, [view])

  const handleOnClick = () => {
    setShowFilters((x) => !x)
    onClear()
  }

  return (
    <div className="content">
      <Toolbar
        hasBurger={routeConfig.meta?.hasBurger && $main.isMobileSize}
        goBackProps={
          !routeConfig.meta?.hasBurger &&
          (patientId ? { historyBack: true } : { autoTarget: routeActions.ROOT() })
        }
      />
      <div className="container">
        <div className="box">
          <div className="box-header pb-0">
            <div className="box-header-title title-lg">{t('appts.title')}</div>
          </div>
          <div className="box-body">
            <div className={styles.apptsSearch}>
              <RadioTabs
                value={view}
                setValue={setView}
                items={[
                  { id: 'current', text: t('appts.tabs.current') },
                  { id: 'history', text: t('appts.tabs.history') },
                ]}
                className={styles.tabs}
                mode="dark"
              />
              <Button
                mode={['filter-outline', 'sm']}
                className={classNames(styles.btnFilter, showFilters && 'btn--active')}
                action={handleOnClick}
              >
                <span className={styles.btnText}>{t('ps.btn.filters')}</span>
              </Button>
            </div>
            {showFilters && <ApptsSearch setFromDate={setFromDate} setToDate={setToDate} />}
            {view === 'current' && (
              <ApptsTabCurrent
                preparedActiveList={preparedActiveList}
                fromDate={fromDate}
                toDate={toDate}
              />
            )}
            {view === 'history' && (
              <ApptsTabHistory
                preparedPastList={preparedPastList}
                patientId={patientId}
                fromDate={fromDate}
                toDate={toDate}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(Appts)
