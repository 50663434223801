var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import service from '@services/client';
import clientService from '@services/client';
import { useLoader } from '@library/CustomHooks';
import DaySlotPicker from '@library/timeSlotPicker/daySlotPicker/DaySlotPicker';
import WindowSlotPicker from '@library/timeSlotPicker/windowSlotPicker/WindowSlotPicker';
import { $user } from '@store';
import mainConfig from '@config/main';
import styles from './TimeSlotPicker.module.scss';
var defaultBegin = moment().utc();
var defaultEnd = defaultBegin.clone().add(7, 'day');
var getDefaultItems = Object.assign(function (visibleDaysCount) {
    if (getDefaultItems._cache[visibleDaysCount]) {
        return getDefaultItems._cache[visibleDaysCount];
    }
    var result = [];
    for (var i = 0; i < visibleDaysCount; i++) {
        result.push({ key: defaultBegin.clone().add(i, 'day').format('YYYY-MM-DD'), value: false });
    }
    getDefaultItems._cache[visibleDaysCount] = result;
    return getDefaultItems._cache[visibleDaysCount];
}, { _cache: {} });
var TimeSlotPicker = function (_a) {
    var value = _a.value, onChange = _a.onChange, requestParams = _a.requestParams, placeTimezone = _a.placeTimezone, _b = _a.visibleDaysCount, visibleDaysCount = _b === void 0 ? 7 : _b, _c = _a.cellNumberInRow, cellNumberInRow = _c === void 0 ? {
        desktop: 6,
        tablet: 4,
        mobile: 3,
    } : _c;
    var t = useTranslation().t;
    var _d = useState(null), stat = _d[0], setStat = _d[1];
    var _e = useState(null), windows = _e[0], setWindows = _e[1];
    var _f = useState(null), selectedDay = _f[0], setSelectedDay = _f[1];
    // Infinity Loading
    var loadPages = useRef([]);
    var nextPage = useRef(0);
    var prevPage = useRef(-1);
    var pagesList = useMemo(function () {
        var result = [];
        var now = moment.utc();
        var minDate = now.startOf('day');
        var maxDate = minDate.clone().add(mainConfig.booking.timePickerDaysMaxLimit, 'day');
        var pageLimit = Math.ceil(mainConfig.booking.timePickerDaysMaxLimit / mainConfig.booking.timePickerDaysLoadNumber);
        var prevSelectedDate = !selectedDay && (value === null || value === void 0 ? void 0 : value.key) ? value === null || value === void 0 ? void 0 : value.key : null;
        for (var page = 0; page < pageLimit; page++) {
            var begin = minDate.clone().add(page * mainConfig.booking.timePickerDaysLoadNumber, 'day');
            var end = begin.clone().add(mainConfig.booking.timePickerDaysLoadNumber, 'day');
            if (end > maxDate) {
                end = maxDate;
            }
            if (prevSelectedDate && begin.isBefore(prevSelectedDate) && end.isAfter(prevSelectedDate)) {
                nextPage.current = page;
                prevPage.current = page - 1;
            }
            result.push({
                begin: "$date:".concat(begin.format('YYYY-MM-DDTHH:mm:ss')),
                end: "$date:".concat(end.format('YYYY-MM-DDTHH:mm:ss')),
            });
        }
        return result;
    }, []);
    var loadPrevPage = function () {
        if (prevPage.current > -1) {
            loadSchedulesStat(prevPage.current, 'prev');
        }
    };
    var loadNextPage = function () {
        if (nextPage.current < pagesList.length) {
            loadSchedulesStat(nextPage.current, 'next');
        }
    };
    var loadSchedulesStat = function (page, direction) { return __awaiter(void 0, void 0, void 0, function () {
        var requestParamsPeriod, prepared, items_1, day;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    if (loadPages.current[page]) {
                        return [2 /*return*/];
                    }
                    loadPages.current[page] = true;
                    requestParamsPeriod = pagesList[page];
                    return [4 /*yield*/, clientService.getSchedulesStat(__assign(__assign({}, requestParams), requestParamsPeriod))];
                case 1:
                    prepared = _c.sent();
                    if (prepared === null || prepared === void 0 ? void 0 : prepared.stat) {
                        if (direction === 'next') {
                            nextPage.current = page + 1;
                        }
                        else {
                            prevPage.current = page - 1;
                        }
                        items_1 = prepared.stat.items.items || [];
                        setStat(function (prevState) {
                            return prevState
                                ? __assign(__assign({}, prevState), { begin: direction === 'prev' ? prepared.stat.begin : prevState.begin, end: direction === 'next' ? prepared.stat.end : prevState.end, items: direction === 'next'
                                        ? __spreadArray(__spreadArray([], prevState.items, true), items_1, true) : __spreadArray(__spreadArray([], items_1, true), prevState.items, true) }) : { begin: prepared.stat.begin, end: prepared.stat.end, items: items_1 };
                        });
                        if (!selectedDay) {
                            day = (value === null || value === void 0 ? void 0 : value.key) && moment.utc().isBefore(value === null || value === void 0 ? void 0 : value.key)
                                ? value === null || value === void 0 ? void 0 : value.key
                                : ((_a = items_1.find(function (item) { return item.value; })) === null || _a === void 0 ? void 0 : _a.key) || ((_b = items_1[0]) === null || _b === void 0 ? void 0 : _b.key);
                            setSelectedDay(day);
                        }
                    }
                    else {
                        loadPages.current[page] = undefined;
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var isInitLoadingStat = useLoader(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (pagesList.length) {
                loadNextPage();
            }
            return [2 /*return*/];
        });
    }); }, [pagesList]);
    var isLoadingWindows = useLoader(function () { return __awaiter(void 0, void 0, void 0, function () {
        var date, params, prepared, items, prevSelectedWindow;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!stat || !selectedDay) {
                        return [2 /*return*/];
                    }
                    date = moment(selectedDay);
                    params = __assign(__assign({}, requestParams), { begin: "$date:".concat(date.format('YYYY-MM-DDTHH:mm:ss')) });
                    delete params.end;
                    return [4 /*yield*/, service.getSchedulesWindows(params)];
                case 1:
                    prepared = _b.sent();
                    if (prepared === null || prepared === void 0 ? void 0 : prepared.windows) {
                        if (!((_a = value === null || value === void 0 ? void 0 : value.value) === null || _a === void 0 ? void 0 : _a.length)) {
                            items = prepared.windows.items.items || [];
                            prevSelectedWindow = items.find(function (item) { return item.key === value.key; });
                            onChange(prevSelectedWindow ? prevSelectedWindow : items[0] || {});
                        }
                        setWindows(prepared.windows);
                    }
                    return [2 /*return*/];
            }
        });
    }); }, [selectedDay]);
    var onSelectWindow = function (value) {
        var item = windows.items.items.find(function (item) { return item.key === value.replace('.000Z', 'Z'); });
        if (item) {
            onChange(item);
        }
    };
    return (_jsxs(_Fragment, { children: [stat ? (_jsx(DaySlotPicker, { selectedDay: selectedDay, onChangeDay: setSelectedDay, minDate: stat.begin, maxDate: stat.end, dates: stat.items, visibleDaysCount: visibleDaysCount, nextLoaderParams: {
                    hasLoadTrigger: nextPage.current < pagesList.length,
                    onIntersectLoadTrigger: loadNextPage,
                }, prevLoaderParams: {
                    hasLoadTrigger: prevPage.current > -1,
                    onIntersectLoadTrigger: loadPrevPage,
                } })) : (_jsx(DaySlotPicker, { selectedDay: getDefaultItems(visibleDaysCount)[0].key, onChangeDay: setSelectedDay, minDate: defaultBegin.format('YYYY-MM-DD'), maxDate: defaultEnd.format('YYYY-MM-DD'), dates: getDefaultItems(visibleDaysCount), visibleDaysCount: visibleDaysCount, isInfinityScroll: false })), placeTimezone !== $user.timezone && (_jsx("div", __assign({ className: styles.notify }, { children: t('book_select_time.different_timezone') }))), _jsx(WindowSlotPicker, { selectedWindow: value.key, onChangeWindow: onSelectWindow, isLoading: (!stat && isInitLoadingStat) || isLoadingWindows, placeTimezone: placeTimezone, windows: windows === null || windows === void 0 ? void 0 : windows.items.items, cellNumberInRow: cellNumberInRow })] }));
};
export default TimeSlotPicker;
