import { makeAutoObservable, runInAction } from 'mobx'

import { withFullName } from '@ps/helpers/fhir'
import psService from '@ps/services/psService'

import { $psHistory } from '@store'

class psPatientStore {
  constructor() {
    makeAutoObservable(this)
  }

  demographics = {}
  vitals = {}
  insurances = []
  socialHistory = {}

  // clear
  clear() {
    this.demographics = {}
    this.vitals = {}
    this.insurances = []
    this.socialHistory = {}
  }

  // Computed
  get patient() {
    let result = {
      ...withFullName(this.demographics),
      vitals: this.vitals,
      insurances: this.insurances,
      socialHistory: this.socialHistory,
    }

    return result
  }

  // Mutations

  // Actions
  async loadData() {
    const response = await Promise.all([
      psService.getPatientDemographics(), // 0
      psService.getPatientVitalSigns(), // 1
      psService.getPatientInsurance(), // 2
      psService.getPatientSocialHistory(), // 3
      $psHistory.loadEvents(),
    ])

    runInAction(() => {
      this.demographics = response[0].prepared || {}
      this.vitals = response[1].prepared || {}
      this.insurances = response[2].prepared?.insurance || []
      this.socialHistory = response[3].prepared || {}
    })

    // console.js(this.patient)
  }
}

export default new psPatientStore()
