var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import classNames from 'classnames';
import { isBase64 } from '@helpers/images';
import { firstLetters, splitName } from '@helpers/text';
import { isUrl } from '@helpers/url';
var UserPhoto = function (_a) {
    var item = _a.item, _b = _a.size, size = _b === void 0 ? '' : _b;
    var avatar = item.avatar, updated = item.updated;
    var image = useMemo(function () {
        if (_.isEmpty(_.trim(avatar)) || _.isPlainObject(avatar)) {
            return false;
        }
        if (isBase64(avatar)) {
            return avatar;
        }
        if (!isUrl(avatar)) {
            return "data:image;base64,".concat(avatar);
        }
        if (updated) {
            return "".concat(avatar, "?cache=").concat(new Date(updated).getTime());
        }
        return avatar;
    }, [avatar, updated]);
    if (image) {
        return (_jsx("div", __assign({ className: classNames('user_photo', size && 'user_photo--' + size) }, { children: _jsx("img", { src: image, alt: "" }) })));
    }
    function getNameLetters(item) {
        var prepared;
        if (typeof item.firstName === 'undefined' && item.name) {
            prepared = splitName(item.name);
        }
        else {
            prepared = [item.firstName, item.lastName];
        }
        return firstLetters(prepared);
    }
    return (_jsx("div", __assign({ className: classNames('user_photo', 'user_photo--empty', size && 'user_photo--' + size) }, { children: getNameLetters(item) })));
};
export default UserPhoto;
