import React, { useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'

import RadioTabs from '@library/radioTabs/RadioTabs'

import { apptEventsSchema } from '@ps/config/events'

const AppointmentEventTabs = ({ eventsBySubcategory = {} }) => {
  const { t } = useTranslation()

  const [category, setCategory] = useState('notes')

  const tabItems = useMemo(() => {
    let result = []

    _.forEach(_.orderBy(apptEventsSchema, 'order', 'asc'), (x) => {
      let allListCount = 0

      if (eventsBySubcategory[x.id + '_all']) {
        allListCount = eventsBySubcategory[x.id + '_all'].length
      } else {
        _.forEach(x.subcategories, (_, id) => {
          if (eventsBySubcategory[id]) {
            allListCount += eventsBySubcategory[id].length
          }
        })
      }

      result.push({
        id: x.id,
        text: t(x.label),
        count: allListCount,
      })
    })

    return result
  }, [eventsBySubcategory]) //eslint-disable-line react-hooks/exhaustive-deps

  const TabComponent = apptEventsSchema[category]?.component

  return (
    <>
      <RadioTabs
        value={category}
        setValue={setCategory}
        items={tabItems}
        mode="dark"
        className="mb-20"
      />
      <TabComponent category={category} eventsBySubcategory={eventsBySubcategory} />
    </>
  )
}

export default AppointmentEventTabs
