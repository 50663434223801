import React from 'react'

import { useTranslation } from 'react-i18next'

import { formatDate } from '@helpers/time'

import RoleName from '@ps/library/RoleName'
import ViewCode from '@ps/library/viewCode/ViewCode'

const Observation = ({ event, resource, extractor }) => {
  const { t } = useTranslation()
  const {
    asString,
    codeObj,
    subject = {},
    performer = {},
    referenceRange = {},
    inReferenceRange = {},
  } = extractor

  return (
    <div className="event_details">
      <div className="event_details-header">
        <div className="event_details-title">
          {!inReferenceRange && <span className="red_marker red_marker--info" />}
          {asString}
          {event.isSelfReported && (
            <span className="badge-self_reported">{t('ps.label.self_reported')}</span>
          )}
        </div>
        <div className="event_details-code">
          <ViewCode codeObj={codeObj} />
        </div>
      </div>
      <div className="event_details-body">
        {referenceRange.valuesAsString && (
          <div className="event_details-line mb-20">
            {t('ps.label.reference_range:')} {referenceRange.valuesAsString}
          </div>
        )}
        <div className="event_details-line">
          {t('ps.event_viewer.subject:')} {subject.value.fullName}
        </div>
        {/*{!_.isEmpty(performer.value) && (*/}
        {/*  <div className="event_details-line">*/}
        {/*    {t('ps.event_viewer.performer')} {performer.value.fullName}{' '}*/}
        {/*    <RoleName role={performer.role} resource={performer.value} />*/}
        {/*  </div>*/}
        {/*)}*/}
        <div className="event_details-line event_details-line--date">
          {formatDate(event.effectiveDate)}
        </div>
      </div>
    </div>
  )
}

export default Observation
