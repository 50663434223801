import BookingRebookModal from '@booking/library/modals/bookingRebook/BookingRebook.modal'
import BookingRepayModal from '@booking/library/modals/bookingRepay/BookingRepay.modal'
import copy from 'copy-to-clipboard'
import i18n from 'i18next'

import { confirmAction } from '@library/ActionConfirmator'
import ChatModal from '@library/chat/Chat.modal'

import ApptFeedbackModal from '@pages/appt/actions/ApptFeedback.modal'
import ApptSurveyModal from '@pages/appt/actions/ApptSurvey.modal'
import JoinConferenceModal from '@pages/appt/actions/JoinConference.modal'

import { extendAddress } from '@helpers/addressHelper'
import { getConfigByCountry } from '@helpers/countries'
import hAppt from '@helpers/hAppt'
import { customGoTo } from '@helpers/router'
import { calendar } from '@helpers/time'
import { callToPhone, nativeScrollTo, openInNewTab } from '@helpers/url'

import { $alerts, $appts, $modal } from '@store'

import { apptStatuses, apptTypes, availableActions, getLastEvents } from '@config/apptsCore'
import { routeActions } from '@config/routeActions'

export const apptEvents = {
  needForStatus: [
    'appointment_booked',
    'appointment_unbooked',
    'payment_exception',
    'payment_created',
    'payment_authorized',
    'payment_canceled',
    'payment_refunded', //
    'appointment_canceled',
    'agent_confirmed',
    'agent_departed',
    'agent_running_late',
  ],
}

/**
 1. Booking
 'Booked' || 'Rebook' || 'Repay'

 2. Team
 'OCC assigned' || 'Provider assigned' || 'Team assigned'

 3. OCC (Optional. 0nly when onSite appointment)
 'OCC is on the way' || 'OCC is running late'

 4. Start
 'Started'

 5. Done
 'Confirm appointment' || 'Completed' || 'Completed with exception' || 'Canceled'
 **/

export const apptStatusRules = (fullAppt) => {
  const {
    appointment,
    place,
    patient = {},
    agent = {},
    provider = {},
    events = [],
    service,
    encounters,
  } = fullAppt

  if (!appointment) {
    return []
  }

  const {
    status,
    amount,
    agentId,
    providerId,
    userId,
    agentReady,
    providerReady,
    clientReady,
    clientSatisfied,
    agentConfirmed: _agentConfirmed,
    providerConfirmed: _providerConfirmed,
    survey,
    feedback,
  } = appointment

  const lastEvents = getLastEvents(apptEvents.needForStatus, events)

  const isCanceled = status === 'canceled' || ifGT('appointment_canceled', 'appointment_booked')
  const isRejected = status === 'rejected'

  const isOnSite = apptTypes.isOnSite(service)
  const isVirtual = apptTypes.isVirtual(service)
  const isWithAgent = apptTypes.isWithAgent(service)
  const isWithProvider = apptTypes.isWithProvider(service)
  const isStartAgent = apptTypes.isStartAgent(service)
  const isStartProvider = apptTypes.isStartProvider(service)
  const isFull = apptTypes.isFull(service)
  const isOnsiteAgent = apptTypes.isOnsiteAgent(service)
  const isVirtualProvider = apptTypes.isVirtualProvider(service)

  const isActive = apptStatuses.isActive(status) && !isCanceled
  const isPast = apptStatuses.isPast(status) || isCanceled
  const isDone = apptStatuses.isDone(status) || isCanceled
  const isSuccess = apptStatuses.isSuccess(status) && !isCanceled
  const isProgress = apptStatuses.isProgress(status) && !isCanceled

  const isFree = hAppt.isFree(amount)
  const isPaymentSuccess = ifGT('payment_created', 'payment_exception')
  const isPaymentAuthorized = ifGT('payment_authorized', 'payment_created')
  const isPaymentException = ifGT('payment_exception', 'payment_created')
  const isPaymentRefunded = ifGT('payment_refunded', 'payment_created')
  const isPaymentCanceled = ifGT('payment_canceled', 'payment_created')

  const isPaymentOK =
    isFree || (isPaymentSuccess && !isPaymentException && !isPaymentRefunded && !isPaymentCanceled)
  const isPaymentFAIL = !isFree && (isPaymentException || isPaymentRefunded || isPaymentCanceled)
  const canRepay = !isFree && !isPaymentAuthorized

  const agentAssigned = !!agentId
  const providerAssigned = !!providerId
  const clientAssigned = !!userId

  const agentConfirmed = agentAssigned && !!_agentConfirmed
  const providerConfirmed = providerAssigned && !!_providerConfirmed

  const teamAssigned =
    (isFull && agentAssigned && providerAssigned) ||
    (!isFull && (agentAssigned || providerAssigned))
  const teamConfirmed =
    (isFull && agentConfirmed && providerConfirmed) ||
    (!isFull && (agentConfirmed || providerConfirmed))

  const agentDeparted =
    agentAssigned &&
    ifGT('agent_departed', 'agent_confirmed') &&
    ifGT('agent_departed', 'appointment_unbooked')
  const agentLate = agentDeparted && ifGT('agent_running_late', 'agent_departed')

  const providerNoted = _.every(encounters, (x) => x.patientPostVisitNoteInternalId)
  const isException = status === 'exception'

  const isUnbooked = status === 'unbooked'
  const isCanRebook = isUnbooked || hAppt.isCanRebook(fullAppt)

  const formsNotFilled = hAppt.formsNotFilled(fullAppt)
  const isTimeInProgress = hAppt.isTimeInProgress({ appointment })
  const isZoomStarted = (isStartAgent && agentReady) || (isStartProvider && providerReady)

  const emergencyNumber = getConfigByCountry(place.country).emergencyNumber
  const hasEncounters = !_.isEmpty(encounters)

  const isHasClientConfirmation = _.isBoolean(clientSatisfied)

  function eventId(name) {
    const event = _.find(lastEvents, (x) => x.event === name)
    return (event && event.id) || 0
  }

  function ifGT(first, second) {
    return eventId(first) > eventId(second)
  }

  const values = {
    agentAssigned,
    providerAssigned,
    teamAssigned,
    clientAssigned,

    hasEncounters,

    agentConfirmed,
    providerConfirmed,
    teamConfirmed,

    clientReady,
    agentReady,
    providerReady,

    isWithAgent,
    isWithProvider,
    isStartAgent,
    isStartProvider,

    formsNotFilled,

    isOnSite,
    isVirtual,
    isFull,
    isOnsiteAgent,
    isVirtualProvider,

    isActive,
    isPast,
    isDone,
    isSuccess,
    isProgress,

    isCanceled,
    isException,
    isRejected,

    isCanRebook,

    isTimeInProgress,
    isZoomStarted,

    apptIndicators: [formsNotFilled.any && 'forms'].filter((x) => x),

    // fees: ! isFree && (agentConfirmed || providerConfirmed),
    fees: false,
    isFree,
    emergencyNumber,
    clientSatisfied,
    isHasClientConfirmation,

    isPaymentSuccess,
    isPaymentAuthorized,
    isPaymentException,
    isPaymentRefunded,
    isPaymentCanceled,
    isPaymentOK,
    isPaymentFAIL,
  }

  // prepare texts
  const typeLabel = hAppt.typeLabel({ service })
  const extendedPlace = extendAddress(place)

  const calendarParams = { type: 'calendarDatetimeShort', timezone: place.timezone, relative: true }
  const startDate =
    appointment.appointedStart && calendar(appointment.appointedStart, calendarParams)
  const endDate = appointment.factFinish && calendar(appointment.factFinish, calendarParams)

  const calendarParams2 = { type: 'calendarWithText', timezone: place.timezone, relative: true }
  const startDateText =
    appointment.appointedStart && calendar(appointment.appointedStart, calendarParams2)
  const factStartDateText =
    appointment.factStart && calendar(appointment.factStart, calendarParams2)
  const endDateText = appointment.factFinish && calendar(appointment.factFinish, calendarParams2)

  let textsTeam = i18n.t('appt_desc.team.find')
  const teamParams = { agent: agent.name, provider: provider.name, email: patient.email }

  if (isFull && agentAssigned && providerAssigned) {
    textsTeam = i18n.t('appt_desc.team.full', teamParams)
  } else if (isOnsiteAgent && agentAssigned) {
    textsTeam = i18n.t('appt_desc.team.onsite_agent', teamParams)
  } else if (isVirtualProvider && providerAssigned) {
    textsTeam = i18n.t('appt_desc.team.virtual_provider', teamParams)
  }

  const texts = {
    startDate: i18n.t('appt_map.start', { typeLabel, date: startDate }),
    finishedDate: i18n.t('appt_map.finished', { typeLabel, date: endDateText }),
    completedDate: i18n.t('appt_map.completed', { typeLabel, date: endDateText }),
    shortAddress: extendedPlace.shortAddress(), // nearest
    nearestStart: isProgress
      ? i18n.t('appt_texts.nearest_started', {
          typeLabel,
          date: factStartDateText,
        })
      : i18n.t('appt_texts.nearest_starts', { typeLabel, date: startDateText }), // desc
    starts: i18n.t('appt_desc.starts', { typeLabel, date: startDateText }),
    scheduled: i18n.t('appt_desc.scheduled'),
    started: i18n.t('appt_desc.started', { typeLabel, date: startDateText }),
    finished: i18n.t('appt_desc.finished', { typeLabel, date: endDateText }),
    team: textsTeam,
    selfRebook: i18n.t('appt_desc.self_rebook'),
    rebook: i18n.t('appt_desc.rebook'),
    repay: i18n.t('appt_desc.repay'),
    complete: i18n.t('appt_desc.complete'),
    survey: i18n.t('appt_desc.survey'),
    feedback: i18n.t('appt_desc.feedback'),
  }

  return [
    // ------- STEP 1 (Booking) -------
    [
      {
        id: 'booked',
        actions: {
          ...availableActions,
          viewForms: true,
          fillForms: true,
          fillIntake: true,
          selfRebook: true,
          repay: canRepay,
          cancel: true,
          callOffice: true,
          callEmergency: true,
        },
        texts: {
          ...texts,
          map: { label: texts.startDate, meta: texts.shortAddress },
          desc: [texts.starts, texts.scheduled, 'forms', texts.team, texts.selfRebook],
        },
        ...values,
        isDefault: () => true,
        isCurrent: () => status === 'booked' && isPaymentOK,
      },
      {
        id: 'rebook',
        actions: {
          ...availableActions,
          viewForms: true,
          fillForms: true,
          fillIntake: true,
          rebook: true,
          cancel: true,
          callOffice: false,
          callEmergency: true,
        },
        texts: {
          ...texts,
          map: { label: texts.startDate, meta: texts.shortAddress },
          desc: [texts.rebook],
        },
        ...values,
        isCurrent: () => isUnbooked,
        isFinal: () => true,
      },
      {
        id: 'repay',
        actions: {
          ...availableActions,
          viewForms: true,
          fillForms: true,
          fillIntake: true,
          repay: true,
          cancel: true,
          callOffice: true,
          callEmergency: true,
        },
        texts: {
          ...texts,
          map: { label: texts.startDate, meta: texts.shortAddress },
          desc: [texts.repay],
        },
        ...values,
        isCurrent: () => status === 'booked' && isPaymentFAIL,
        isFinal: () => true,
      },
    ], // ------- STEP 2 (Team) -------
    [
      {
        id: 'team_assigned',
        actions: {
          ...availableActions,
          viewForms: true,
          fillForms: true,
          fillIntake: true,
          selfRebook: true,
          repay: canRepay,
          textGeneral: agentAssigned || providerAssigned,
          cancel: true,
          callOffice: true,
          callEmergency: true,
        },
        texts: {
          ...texts,
          map: { label: texts.startDate, meta: texts.shortAddress },
          desc: [texts.starts, texts.scheduled, 'forms', texts.team],
        },
        ...values,
        isDefault: () => !isCanceled,
        isCurrent: () => teamAssigned,
      },
    ], // ------- STEP 3 (OCC) -------
    [
      {
        id: 'agent_way',
        actions: {
          ...availableActions,
          viewForms: true,
          fillForms: true,
          fillIntake: true,
          selfRebook: true,
          repay: canRepay,
          textGeneral: agentAssigned || providerAssigned,
          cancel: true,
          callOffice: true,
          callEmergency: true,
        },
        texts: {
          ...texts,
          map: { label: texts.startDate, meta: texts.shortAddress },
          desc: [texts.starts, texts.scheduled, 'forms', texts.team],
        },
        ...values,
        isDefault: () => !isCanceled && isOnSite,
        isCurrent: () => agentConfirmed && agentDeparted,
      },
      {
        id: 'agent_late',
        actions: {
          ...availableActions,
          viewForms: true,
          fillForms: true,
          fillIntake: true,
          selfRebook: true,
          repay: canRepay,
          textGeneral: agentAssigned || providerAssigned,
          cancel: true,
          callOffice: true,
          callEmergency: true,
        },
        texts: {
          ...texts,
          map: { label: texts.startDate, meta: texts.shortAddress },
          desc: [texts.starts, texts.scheduled, 'forms', texts.team],
        },
        ...values,
        isCurrent: () => agentConfirmed && agentLate,
      },
    ], // ------- STEP 4 (Start) -------
    [
      {
        id: 'started',
        actions: {
          ...availableActions,
          viewForms: true,
          fillForms: true,
          fillIntake: true,
          startVideo: isVirtual && isZoomStarted,
          textGeneral: agentAssigned || providerAssigned,
          callOffice: true,
          callEmergency: true,
        },
        texts: {
          ...texts,
          map: { label: texts.startDate, meta: texts.shortAddress },
          desc: [!isProgress && texts.starts, isProgress && texts.started, 'forms', texts.team],
        },
        ...values,
        isDefault: () => !isCanceled,
        isCurrent: () => isProgress,
      },
    ], // ------- STEP 5 (Done) -------
    [
      {
        id: 'finished',
        actions: {
          ...availableActions,
          viewForms: true,
          fillForms: !isHasClientConfirmation,
          fillIntake: !isHasClientConfirmation,
          viewTextGeneral: agentAssigned || providerAssigned,
          complete: !isHasClientConfirmation,
          completeNotHappen: !isHasClientConfirmation,
          survey: isHasClientConfirmation && !survey && clientSatisfied,
          feedback: isHasClientConfirmation && !feedback && !clientSatisfied,
          callOffice: true,
          callEmergency: true,
        },
        texts: {
          ...texts,
          ...(!isHasClientConfirmation && {
            status: i18n.t('appt_statuses.finished.status.confirmation'),
          }),
          map: { label: texts.finishedDate, meta: texts.shortAddress },
          desc: [
            !isHasClientConfirmation && texts.complete,
            isHasClientConfirmation && !survey && clientSatisfied && texts.survey,
            isHasClientConfirmation && !feedback && !clientSatisfied && texts.feedback,
          ],
        },
        ...values,
        isCurrent: () => status === 'finished',
        isFinal: () => true,
      },
      {
        id: 'completed',
        actions: {
          ...availableActions,
          viewForms: true,
          viewTextGeneral: agentAssigned || providerAssigned,
          survey: isHasClientConfirmation && !survey && clientSatisfied,
          feedback: isHasClientConfirmation && !feedback && !clientSatisfied,
        },
        texts: {
          ...texts,
          map: { label: texts.completedDate, meta: texts.shortAddress },
          desc: [
            isHasClientConfirmation && !survey && clientSatisfied && texts.survey,
            isHasClientConfirmation && !feedback && !clientSatisfied && texts.feedback,
          ],
        },
        ...values,
        isDefault: () => true,
        isCurrent: () => isDone,
      },
      {
        id: 'exception',
        actions: {
          ...availableActions,
          viewForms: true,
          viewTextGeneral: agentAssigned || providerAssigned,
          survey: isHasClientConfirmation && !survey && clientSatisfied,
          feedback: isHasClientConfirmation && !feedback && !clientSatisfied,
        },
        texts: {
          ...texts,
          map: { label: texts.completedDate, meta: texts.shortAddress },
          desc: [
            isHasClientConfirmation && !survey && clientSatisfied && texts.survey,
            isHasClientConfirmation && !feedback && !clientSatisfied && texts.feedback,
          ],
        },
        ...values,
        isCurrent: () => isException,
        isFinal: () => true,
      },
      {
        id: 'canceled',
        actions: {
          ...availableActions,
          viewForms: true,
          viewTextGeneral: agentAssigned || providerAssigned,
        },
        texts: {
          ...texts,
          map: { label: texts.startDate, meta: texts.shortAddress },
        },
        ...values,
        isCurrent: () => isCanceled,
        isFinal: () => true,
      },
      {
        id: 'rejected',
        actions: {
          ...availableActions,
        },
        texts: {
          ...texts,
          map: { label: _.trim(_.trim(texts.startDate, ' '), ','), meta: texts.shortAddress },
        },
        ...values,
        isCurrent: () => isRejected,
        isFinal: () => true,
      },
    ],
  ]
}

export const apptActions = ({ fullAppt, status }, asObject = false) => {
  const { appointment, agent, provider } = fullAppt
  const appointmentId = appointment.id

  if (!status) {
    status = hAppt.getCurrentStatus({ fullAppt })
  }

  const { clientReady, fees, emergencyNumber, isCanRebook } = status
  const list = [
    {
      id: 'fillForms',
      action: () => {
        const { formsNotFilled } = status
        const encounters = Object.keys(formsNotFilled?.encounters)
        const firstUnfilledEncounter = _.find(encounters, (x) => formsNotFilled.encounters[x])
        const firstEncounter = _.first(encounters)
        const encounterId =
          firstUnfilledEncounter === undefined ? firstEncounter : firstUnfilledEncounter
        customGoTo(
          routeActions.APPT({
            appointmentId: appointment.id,
            encounterId: encounterId,
          }),
          { type: 'replace' },
        )
        nativeScrollTo('appt_documents', { behavior: 'smooth', block: 'center' })
      },
    },
    {
      id: 'callOffice',
      action: async (buttonId) => {
        const res = await $appts.callOffice({ appointmentId: appointment.id, buttonId })

        if (res.isSuccess) {
          $alerts.add(i18n.t('alert.call_office_initialization'))
        }

        return res
      },
    },
    {
      id: 'callEmergency',
      action: async () => {
        callToPhone(emergencyNumber)
      },
    },
    {
      id: 'textGeneral',
      action: (message) => {
        $modal.add(ChatModal, {
          appointmentId,
          pushMessage: message,
        })
      },
    },
    {
      id: 'viewTextGeneral',
      action: () => {
        $modal.add(ChatModal, {
          appointmentId,
          historyMode: true,
        })
      },
    },
    {
      id: 'selfRebook',
      action: () => {
        if (isCanRebook) {
          $modal.add(BookingRebookModal, { fullAppt: fullAppt, isSelfRebook: true })
        } else {
          $alerts.add(i18n.t('alert.rebook_unavailable'))
        }
      },
    },
    {
      id: 'rebook',
      action: () => {
        $modal.add(BookingRebookModal, { fullAppt: fullAppt, isSelfRebook: false })
      },
    },
    {
      id: 'repay',
      action: () => {
        $modal.add(BookingRepayModal, { appointment: fullAppt.appointment })
      },
    },
    {
      id: 'startVideo',
      action: ({ linkType, source } = {}) => {
        if (!clientReady) {
          $appts.readyForConference({ id: appointment.id })
        }

        const url = hAppt.meetingUrl({ fullAppt, status, type: linkType, source })

        if (clientReady && !source) {
          $modal.add(JoinConferenceModal)
        } else {
          openInNewTab(url)
        }
      },
      copyVideoLink: ({ linkType, source } = {}) => {
        const url = hAppt.meetingUrl({ fullAppt, status, type: linkType, source })

        if (url) {
          copy(url)
          $alerts.add(i18n.t('alert.link_copied.body'))
        }
      },
      readyForConference: () => {
        if (!clientReady) {
          $appts.readyForConference({ id: appointment.id })
        }
      },
    },
    {
      id: 'complete',
      action: async () => {
        return await $appts.complete({ id: appointment.id })
      },
    },
    {
      id: 'completeNotHappen',
      action: async () => {
        return await $appts.complete({ id: appointment.id, ok: false })
      },
    },
    {
      id: 'cancel',
      action: async (e) => {
        const feesText = fees
          ? i18n.t('appt_confirmator.cancel.fees.yes')
          : i18n.t('appt_confirmator.cancel.fees.no')

        confirmAction(
          'customAlert',
          {
            data: {
              title: i18n.t('appt_confirmator.cancel.title'),
              message: `${i18n.t('appt_confirmator.cancel.message')} ${feesText}`,
              done: i18n.t('btn.cancel_appointment'),
            },
            callback: async () => {
              await $appts.cancel(appointment.id)
            },
          },
          e,
        )
      },
    },
    {
      id: 'survey',
      action: () => {
        $modal.add(ApptSurveyModal, { fullAppt })
      },
    },
    {
      id: 'feedback',
      action: () => {
        $modal.add(ApptFeedbackModal, { fullAppt })
      },
    },
  ]

  let filtered = []

  _.forEach(status.actions, (value, actionName) => {
    if (value) {
      const action = _.find(list, (x) => x.id === actionName)

      if (action) {
        filtered.push(action)
      } else {
        filtered.push({ id: actionName })
      }
    }
  })

  if (asObject) {
    let result = {}
    _.forEach(filtered, (x) => (result[x.id] = x))
    return result
  }

  return filtered
}

export const apptMapActions = ({ fullAppt, status, actions }) => {
  const {
    clientReady,
    isZoomStarted,
    isCanRebook,
    isVirtual,
    isOnsite,
    isPaymentFAIL,
    formsNotFilled,
  } = status

  let list = [
    {
      id: 'fillForms',
      action: actions.fillForms?.action,
      label: i18n.t('btn.complete_forms'),
      visible: formsNotFilled.any, //
      marker: true,
    },
    {
      id: 'selfRebook',
      action: actions.selfRebook?.action,
      label: i18n.t('btn.rebook'),
      visible: isCanRebook, //
    },
    {
      id: 'selfRebookAlert',
      actionProp: ['office', 'phone'],
      action: actions.selfRebook?.action,
      label: i18n.t('btn.rebook'),
      visible: !isCanRebook, //
    },
    {
      id: 'rebook',
      actionProp: ['office', 'phone'],
      action: actions.rebook?.action,
      label: i18n.t('btn.rebook'),
      visible: true,
      primary: true,
    },
    {
      id: 'repay',
      action: actions.repay?.action,
      label: i18n.t('btn.edit_payment'),
      visible: true,
      primary: isPaymentFAIL,
    },
    {
      id: 'pendingConference',
      label: i18n.t('btn.pending_conference'),
      visible:
        !isZoomStarted &&
        !actions.startVideo &&
        ((isVirtual && ['agent_way', 'agent_late', 'started'].includes(status.id)) ||
          (isOnsite && ['agent_way', 'agent_late'].includes(status.id))), //
      disabled: true,
    },
    {
      id: 'startVideo',
      action: actions.startVideo?.action,
      label: i18n.t('btn.join_conference'),
      visible: true, //
      primary: true,
    },
    {
      id: 'copyVideoLink',
      action: actions.startVideo?.copyVideoLink,
      label: i18n.t('btn.copy_video_link'),
      visible: clientReady ? -1 : true, //
    },
    {
      id: 'videoAlreadyStarted',
      action: actions.startVideo?.readyForConference,
      label: i18n.t('btn.video_already_started'),
      visible: clientReady ? false : -1, //
    },
    {
      id: 'complete',
      action: actions.complete?.action,
      label: i18n.t('btn.confirm_appointment'),
      visible: true, //
      primary: true,
    },
    {
      id: 'completeNotHappen',
      action: actions.completeNotHappen?.action,
      label: i18n.t('btn.not_happen_appointment'),
      visible: true, //
    },
    {
      id: 'survey',
      action: actions.survey?.action,
      label: i18n.t('btn.rate_appointment'),
      visible: true, //
      primary: true,
    },
    {
      id: 'feedback',
      action: actions.feedback?.action,
      label: i18n.t('btn.feedback'),
      visible: true, //
      primary: true,
    },
    {
      id: 'cancel',
      action: actions.cancel?.action,
      label: i18n.t('btn.cancel_appointment'),
      visible: ['booked', 'repay', 'rebook'].includes(status.id) ? true : -1, //
    },
  ]

  list = list.map((x) => {
    if (!x.hasOwnProperty('visible') || !_.isNumber(x.visible)) {
      x.visible = x.visible ? 1 : 0
    }

    return x
  })

  return list.filter((x) => _.isFunction(x.action) || x.disabled)
}
