import React from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import clientService from '@services/client'

import TypeAhead from '@library/form/fields/typeAhead/TypeAhead'

import { isAbsoluteEmpty } from '@helpers/other'

const AddInsuranceTypeaheadProvider = ({ form, fieldKey, fieldProps, tKey }) => {
  const { t } = useTranslation()
  const { insuranceProviderId, insuranceProviderName } = form.fields

  async function handleOnload({ searchString = '' }) {
    let result = []
    const isSearch = searchString.length

    let filter
    if (isSearch) {
      const needle = searchString.split(' ').filter((x) => !isAbsoluteEmpty(x))
      filter = {
        or: [
          { imatch: ['payer_id', _.escapeRegExp(searchString)] },
          { and: _.map(needle, (x) => ({ imatch: ['name', _.escapeRegExp(x)] })) },
        ],
      }
    } else {
      filter = { eq: ['is_popular', true] }
    }

    const response = await clientService.getInsuranceProviders({
      filter: filter,
      order: [['name', 'asc']],
    })
    const items = response.prepared?.insuranceProviders.items || []

    if (items.length) {
      result = items.map((x) => ({
        id: x.payerId,
        text: x.name,
        rightText: x.payerId,
      }))

      if (!isSearch) {
        result = [{ text: t('insurance.label.most_popular'), items: result }]
      }
    }

    return result
  }

  return (
    <TypeAhead
      value={{
        id: insuranceProviderId.value,
        text: insuranceProviderName.value,
        rightText: insuranceProviderId.value,
      }}
      onLoad={handleOnload}
      onLoadMinSearchLength={0}
      onChange={(data) => {
        insuranceProviderId.onChange(isAbsoluteEmpty(data.id) ? '' : data.id)
        insuranceProviderName.onChange(isAbsoluteEmpty(data.text) ? '' : data.text)
      }}
      className="mb-10"
      fieldAttributes={{
        label: t(`insurance.label.${tKey}`),
        placeholder: t(`insurance.ph.${tKey}`),
        isInvalid: insuranceProviderName.isInvalid,
        unFocusedKey: 'rightText',
      }}
      popupParams={{
        useAsIs: true,
      }}
    />
  )
}

export default observer(AddInsuranceTypeaheadProvider)
