export var ENUMS_INSURANCE_TYPES = {
    private: {
        id: 'private',
        tKey: 'enums.insurance.types.private',
    },
    public: {
        id: 'public',
        tKey: 'enums.insurance.types.public',
    },
    medicaid: {
        id: 'medicaid',
        tKey: 'enums.insurance.types.medicaid',
    },
    medicare: {
        id: 'medicare',
        tKey: 'enums.insurance.types.medicare',
    },
    other: {
        id: 'other',
        tKey: 'enums.insurance.types.other',
    },
};
export var ENUMS_INSURANCE_POLICY_HOLDERS = {
    self: {
        id: 'self',
        tKey: 'enums.insurance.policy_holders.self',
    },
    spouse: {
        id: 'spouse',
        tKey: 'enums.insurance.policy_holders.spouse',
    },
    parent: {
        id: 'parent',
        tKey: 'enums.insurance.policy_holders.parent',
    },
    guardian: {
        id: 'guardian',
        tKey: 'enums.insurance.policy_holders.guardian',
    },
    guarantor: {
        id: 'guarantor',
        tKey: 'enums.insurance.policy_holders.guarantor',
    },
    other: {
        id: 'other',
        tKey: 'enums.insurance.policy_holders.other',
    },
};
export var INSURANCE_FIELDS = [
    'insuranceType',
    'insuranceProviderName',
    'insuranceProviderId',
    'insuranceGroupId',
    'insuranceMemberFirstName',
    'insuranceMemberLastName',
    'insuranceMemberId',
    'insurancePolicyHolder',
];
