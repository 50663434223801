import React from 'react'

import classNames from 'classnames'

import styles from './Types.module.scss'

const Audio = ({ src }) => {
  return (
    <div className={classNames(styles.audio, styles.isDefault)}>
      <audio controls src={src} />
    </div>
  )
}

export default Audio
