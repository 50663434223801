import React, { useMemo, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import AddAddressModal from '@library/addAddressModal/AddAddress.modal'
import InputSearch from '@library/form/fields/InputSearch'
import Toolbar from '@library/toolbar/Toolbar'
import Button from '@library/ui/button/Button'

import { $modal, $places } from '@store'

import mainConfig from '@config/main'
import { routeActions } from '@config/routeActions'

const Places = () => {
  const { t } = useTranslation()

  let [searchString, setSearchString] = useState('')

  const preparedList = useMemo(() => {
    let result = $places.extendedItems

    if (searchString) {
      let needle = _.trim(searchString).toLowerCase().split(' ')

      result = result.filter((x) => {
        let full = [x.address()].join(' ').toLowerCase()
        return _.every(needle, (n) => full.includes(n))
      })
    }

    return result
  }, [$places.extendedItems, searchString]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="content">
      <Toolbar goBackProps={{ autoTarget: routeActions.HOME() }} />
      <div className="container">
        <div className="box">
          <div className="box-header">
            <div className="box-header-title title-lg mb-0">{t('addresses.title')}</div>
          </div>
          <div className="box-body box-body--padding">
            <div className="form form--md">
              {$places.items.length > mainConfig.searchIfMore && (
                <InputSearch
                  className="mb-20"
                  field={{ value: searchString, onChange: setSearchString }}
                  placeholder={t('addresses.ph.search')}
                />
              )}
              {preparedList.map((item) => (
                <div className="form-group form-group--bg" key={item.id}>
                  <div
                    className="form-item form-item--as-btn form-item--icon"
                    onClick={() => $modal.add(AddAddressModal, { editId: item.id })}
                  >
                    <div className="form-field">{item.line1()}</div>
                    <label className="form-label">{item.line2()}</label>
                  </div>
                </div>
              ))}

              <div className="form-group btn-group">
                <Button
                  action={() => $modal.add(AddAddressModal)}
                  mode={['create', 'block']}
                  label={t('addresses.btn.add')}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(Places)
