import React, { useMemo } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { TransitionWrapper } from '@library/animations/Animations'
import { useTimer } from '@library/CustomHooks'
import NotificationsButton from '@library/widgets/notifications/NotificationsButton'

import { $notices, $user } from '@store'

import mainConfig from '@config/main'

import styles from './Notifications.module.scss'

const Notice = ({ options }) => {
  const { t } = useTranslation()

  const {
    id,
    show,
    title,
    body,
    actions,
    event,
    duration = mainConfig.notifications.displayDuration,
    preset = 'popupNotices',
    closeOnClick = false,
    pauseOnHover = true,
    onLeaved,
  } = options

  const { isRunning, start, pause } = useTimer({
    duration,
    callback: () => $notices.hidePopup(id),
    autoStart: duration > 0,
  })

  const practiceName = useMemo(() => {
    if (event) {
      const found = _.find($user.practices, (x) => x.id === event.practiceId) || {}
      return found.name
    }
  }, [event, $user.practices])

  function handleOnClose() {
    $notices.view({ ids: [id] })
    $notices.hidePopup(id)
  }

  function handleOnClick() {
    if (closeOnClick) {
      handleOnClose()
    }
  }

  function handleOnMouseHover() {
    if (pauseOnHover) {
      isRunning ? pause() : start()
    }
  }

  function handleOnLeaved() {
    if (_.isFunction(onLeaved)) {
      onLeaved()
    }

    $notices.removePopup(id)
  }

  function handleDismiss() {
    $notices.hideAllPopups()
  }

  return (
    <TransitionWrapper items={show} preset={preset} onLeaved={handleOnLeaved}>
      {(item) =>
        item &&
        ((style) => (
          <div
            className={styles.popupNoticesItem}
            onClick={handleOnClick}
            onMouseEnter={handleOnMouseHover}
            onMouseLeave={handleOnMouseHover}
            style={style}
          >
            <div className={styles.popupNoticesTolbar}>
              <div className={styles.popupNoticesTolbarIcon} />
              <div className={styles.popupNoticesTolbarTitle}>
                {practiceName || t('popup_notices.title')}
              </div>
              <div className={styles.popupNoticesTolbarClose} onClick={handleOnClose} />
            </div>
            {title() && <div className={styles.popupNoticesTitle}>{title()}</div>}
            <div className={styles.body}>{body()}</div>
            {actions.length > 0 && (
              <div className={styles.popupNoticesButtons}>
                {actions.map((btn, index) => (
                  <NotificationsButton
                    key={index}
                    notice={options}
                    btn={btn}
                    onClose={handleOnClose}
                    isPopup={true}
                  />
                ))}
              </div>
            )}
            {$notices.popups.length > 1 && (
              <div className={styles.popupNoticesDismiss} onClick={handleDismiss}>
                <div>{t('btn.dismiss_all_count', { count: `(${$notices.popups.length})` })}</div>
              </div>
            )}
          </div>
        ))
      }
    </TransitionWrapper>
  )
}

export default observer(Notice)
