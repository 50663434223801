import { getUA } from 'react-device-detect'

import i18n from 'i18next'

import apiService from '@services/api'

import WebView from '@helpers/webView'

import { $alerts, $user } from '@store'

import mainConfig from '@config/main'

class auth {
  device = getUA
  application = WebView.isWebView ? 'mobile' : 'web'

  async requestCode({ type, using = 'text', value }, skipAlert = false) {
    let params = {
      type,
      using,
      value,
    }

    if (params.type === 'email' && params.using === 'voice') {
      params.using = 'text'
    }

    // <-- for testing
    let method = 'user.auth.request_code'
    // const queryParams = queryString.parse(history.location.search)
    // if (queryParams.mmm) method = queryParams.mmm
    // for testing -->

    let response = await apiService.fetchV3(method, params, { skipAlert })

    if (!skipAlert) {
      if (response.error && response.error.code === 'error.resource.invalid_status') {
        const actual = _.get(response.error, 'data.actual')

        if (actual === 'removed') {
          $alerts.add(i18n.t(`login.alert.account_removed.${type}`), {
            title: i18n.t(`login.alert.account_removed.title`),
          })
        } else {
          $alerts.add(i18n.t(`login.alert.account_not_exist.${type}`), {
            title: i18n.t(`login.alert.account_not_exist.title`),
          })
        }
      } else if (response?.prepared?.hasOwnProperty('codeSent') && !response.prepared.codeSent) {
        $alerts.add(i18n.t('api_errors.default'))
      }
    }

    return response
  }

  async login({ type, value, code }) {
    const params = {
      expand: { role: ['practice_id'] },
      application: this.application,
      device: this.device,
      preferableRoles: [mainConfig.appType],
      type,
      value,
      code,
    }

    return await apiService.fetchV3('user.auth.login', params)
  }

  async loginByToken(token, extraData) {
    const params = {
      expand: { role: ['practice_id'] },
      application: this.application,
      token,
    }

    if (!_.isEmpty(extraData)) {
      params.extraData = extraData
    }

    return await apiService.fetchV3('user.auth.login_by_token', params)
  }

  async devLoginByTokenStubConfig() {
    return await apiService.fetchV3('stub_plugin.get_config')
  }

  /*
  check_subscription_false_rate and booked_notify_false_rate determine after how many requests the virtual labq will respond with false to the subscription request:
    0 - subscription is always present
    1 - there is never a subscription
    2 - not on every second request, etc.
   */
  async devLoginByTokenStubReconfigure() {
    return await apiService.fetchV3('stub_plugin.reconfigure', {
      config: {
        labq_stub: {
          check_subscription_false_rate: 0,
          booked_notify_false_rate: 0,
          mode: 'stub',
        },
        travel_stub: {
          travel_time: 20,
          mode: 'stub',
        },
      },
    })
  }

  async signup({ type, value, code }) {
    const params = {
      expand: { role: ['practice_id'] },
      application: this.application,
      device: this.device,
      preferableRoles: [mainConfig.appType],
      accept_tos: false, // TODO: flow probably will be changed
      type,
      value,
      code,
    }

    return await apiService.fetchV3('user.auth.signup', params)
  }

  async signout() {
    return await apiService.fetchLoggedV3('user.auth.logout', {}, { skipAlert: true })
  }

  async delete_session({ id }) {
    return await apiService.fetchLoggedV3('user.sessions.delete', { id })
  }

  async refresh(params) {
    if (_.isEmpty($user.roles)) {
      params.expand = { role: ['practice_id'] }
    }

    return await apiService.fetchV3('user.auth.refresh', params)
  }
}

const service = new auth()
export default service
