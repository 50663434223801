var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import Burger from '@library/toolbar/burger/Burger';
import GoBack from '@library/toolbar/goBack/GoBack';
import Notes from '@library/toolbar/notes/Notes';
import Notification from '@library/toolbar/notification/Notification';
import { isRouteByArea, useRouterSearchParams } from '@helpers/router';
import { isUrl } from '@helpers/url';
import { $main, $user } from '@store';
import styles from './Toolbar.module.scss';
var Toolbar = function (_a) {
    var _b;
    var _c = _a.goBackProps, goBackProps = _c === void 0 ? null : _c, _d = _a.useBackToApptUrl, useBackToApptUrl = _d === void 0 ? false : _d, _e = _a.hasBurger, hasBurger = _e === void 0 ? false : _e, children = _a.children, className = _a.className;
    var _f = useState(false), isScrolledDown = _f[0], setIsScrolledDown = _f[1];
    var searchParams = useRouterSearchParams();
    if (useBackToApptUrl) {
        var backToApptUrl = searchParams.get('backToApptUrl');
        if (backToApptUrl) {
            if (isUrl(backToApptUrl)) {
                goBackProps.autoTarget.href = backToApptUrl;
            }
            else {
                goBackProps.autoTarget.to = backToApptUrl;
            }
        }
    }
    useEffect(function () {
        var handleScroll = function () {
            var scrollTopPosition = window.scrollY || document.documentElement.scrollTop;
            setIsScrolledDown(scrollTopPosition > 0);
        };
        window.addEventListener('scroll', handleScroll);
        return function () { return window.removeEventListener('scroll', handleScroll); };
    }, []);
    return (_jsxs("div", __assign({ className: classNames(styles.toolbar, className, (_b = {}, _b[styles.toolbarFixed] = isScrolledDown, _b)), id: 'toolbar' }, { children: [goBackProps && _jsx(GoBack, __assign({}, goBackProps, { className: styles.goBack })), hasBurger && _jsx(Burger, {}), children, !isRouteByArea('hideRightSidebar') && $user.user && (_jsxs("div", __assign({ className: classNames(styles.rightSidebar, !goBackProps && !hasBurger && !children && styles.rightSidebarAlignRight) }, { children: [_jsx(Notification, { className: classNames(styles.rightSidebarButton, $main.rightSidebar.active === 'notifications' && styles.rightSidebarButtonHidden) }), _jsx(Notes, { className: classNames(styles.rightSidebarButton, $main.rightSidebar.active === 'notes' && styles.rightSidebarButtonDisplayNone) })] })))] })));
};
export default observer(Toolbar);
