import React from 'react'

const Medications = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.91 28A7.714 7.714 0 1 1 8 17.09L17.09 8A7.714 7.714 0 0 1 28 18.91zm1.817-5.454-7.273-7.273-3.636 3.636a5.13 5.13 0 0 0-1.087 1.599l.008-.018a5.11 5.11 0 0 0-.082.2l.074-.182c-.035.08-.067.162-.098.244l.024-.063a5.118 5.118 0 0 0-.068.188l.044-.125a5.12 5.12 0 0 0-.065.186l.02-.061a5.131 5.131 0 0 0-.134.461l.015-.06a5.15 5.15 0 0 0-.136.796l.01-.093a5.17 5.17 0 0 0-.018.187l.008-.094a5.172 5.172 0 0 0-.016.229l.008-.135a5.173 5.173 0 0 0-.01.185l.002-.05a5.174 5.174 0 0 0-.005.19l.003-.14a5.175 5.175 0 0 0-.003.25v-.11l.001.187-.001-.077c0 .062.002.123.005.185l-.004-.108c.002.072.005.144.01.216l-.006-.108c.004.073.009.147.015.22l-.009-.112c.005.069.011.137.018.206l-.009-.094c.006.061.012.123.02.184l-.01-.09c.006.063.014.127.024.19l-.014-.1c.01.082.023.164.037.245l-.023-.144c.009.06.019.122.03.182l-.007-.038c.01.061.022.122.035.182l-.028-.144c.013.074.028.147.045.22l-.017-.076c.013.061.027.122.042.182l-.025-.106c.016.072.034.144.054.216l-.029-.11c.018.07.037.14.058.21l-.029-.1c.018.066.038.132.059.198l-.03-.098c.018.06.037.121.057.181l-.027-.083c.02.062.04.123.062.185l-.035-.102c.026.076.053.152.082.227l-.047-.125c.023.062.046.123.071.185l-.024-.06c.02.052.042.104.064.155l-.04-.095c.031.077.064.153.1.229l-.06-.134c.029.068.06.135.092.202l-.033-.068.08.164-.047-.096c.03.065.064.13.098.194l-.05-.098c.032.065.066.128.101.191l-.051-.093c.03.056.06.111.093.166l-.042-.073c.037.066.076.132.116.197l-.074-.124c.038.065.078.13.119.193l-.045-.07c.034.055.07.11.106.163l-.061-.093c.038.06.078.12.12.178l-.06-.085.113.16-.054-.075c.042.059.085.118.13.176l-.076-.101c.049.066.099.131.151.195l-.075-.094c.085.11.175.217.27.322l.17.177.157.15a5.143 5.143 0 0 0 7.116-.15l3.636-3.636zm5.455-12.728a5.143 5.143 0 0 0-7.273 0l-3.637 3.636 7.274 7.273 3.636-3.636a5.143 5.143 0 0 0 .15-7.116z"
      fill="#FFF"
      fillRule="evenodd"
    />
  </svg>
)

export default Medications
