import React from 'react'

import { observer } from 'mobx-react'
import { Trans, useTranslation } from 'react-i18next'

import Toolbar from '@library/toolbar/Toolbar'
import Button from '@library/ui/button/Button'

import mainConfig from '@config/main'
import { routeActions } from '@config/routeActions'

const BookingError = () => {
  const { t } = useTranslation()

  return (
    <div className="content">
      <Toolbar />
      <div className="container">
        <div className="max_width--md">
          <h1>{t('book.error.something_went_wrong.title')}</h1>
          <div>
            <Trans
              i18nKey="book.error.something_went_wrong.message"
              values={{ email: mainConfig.supportMail }}
              components={[
                <a href={'mailto:' + mainConfig.supportMail} target="_blank" rel="noreferrer" />,
              ]}
            />
          </div>
          <Button mode={['secondary']} autoTarget={routeActions.ROOT()} className="mt-20">
            {t('btn.return_to_app', { appName: mainConfig.parentProductName })}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default observer(BookingError)
