var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useEffect, useState } from 'react';
import { autorun } from 'mobx';
import { useTranslation } from 'react-i18next';
import DocumentUploader from '@library/plugins/documentUploader/DocumentUploader';
import $documentUploader from '@library/plugins/documentUploader/documentUploader.store';
import Types from '@library/plugins/documentUploader/DocumentUploader.types';
var statusText = (_a = {},
    _a[Types.Status.UPLOADING] = 'label.uploading',
    _a[Types.Status.UPLOADED] = 'label.uploaded',
    _a[Types.Status.ERROR] = 'label.upload_error',
    _a);
export var useDocumentUploader = function (props) {
    var t = useTranslation().t;
    var _a = useState([]), documents = _a[0], setDocuments = _a[1];
    var getActions = useCallback(function (document, index) { return [
        {
            label: t('btn.try_again'),
            action: function () { return $documentUploader.uploadProcess(__assign(__assign({}, props), { document: document, index: index })); },
        },
        {
            label: t('btn.clear'),
            action: function () { return $documentUploader.removeDocument(props.patientId, index, props.encounterId); },
        },
    ]; }, __spreadArray([], Object.values(props), true));
    useEffect(function () {
        var disposer = autorun(function () {
            var documents = $documentUploader.getDocumentsList(props.patientId, props.encounterId);
            var prepared = documents.map(function (item, index) { return (__assign(__assign({}, item), { isLoading: item.status === Types.Status.UPLOADING, hasError: item.status === Types.Status.ERROR, statusText: t(statusText[item.status]), actions: item.status === Types.Status.ERROR ? getActions(item, index) : undefined })); });
            setDocuments(prepared);
        });
        return function () { return disposer(); };
    }, __spreadArray([], Object.values(props), true));
    var Uploader = useCallback(function (_a) {
        var children = _a.children;
        return (_jsx(DocumentUploader, __assign({}, props, { children: children })));
    }, __spreadArray([], Object.values(props), true));
    return { documents: documents, DocumentUploader: Uploader };
};
