import { makeAutoObservable } from 'mobx'

import userService from '@services/user'

import { availableConfigureSteps } from '@library/plugins/configure/configure.config'
import ConfigurePreviewModal from '@library/plugins/configure/modals/ConfigurePreview.modal'

import { customExecute, localStore } from '@helpers/other'

import { $auth, $main, $modal, $user } from '@store'

import { customActions } from '@config/customActions'
import mainConfig from '@config/main'

class configureStore {
  constructor() {
    makeAutoObservable(this)
  }

  currentStep = 0
  allSteps = 0

  // Computed

  // Actions
  async init() {
    if (!_.isEmpty($user.info)) {
      this.checkConfigure({ showPreview: true })
    }
  }

  checkConfigure({ showPreview = false } = {}) {
    const requiredSteps = this.getRequiredSteps()

    if (showPreview && requiredSteps.length) {
      $modal.add(ConfigurePreviewModal, {
        canDrop: false,
        onEntered: () => {
          this.allSteps = requiredSteps.length
          this.currentStep = 0
        },
        onCancel: async () => {
          await customExecute(customActions.HANDLE_REJECT_PAPERWORK())
        },
      })
    } else {
      _.forEach(requiredSteps, (step) => {
        this.currentStep++
        step.action()

        return false
      })
    }
  }

  getRequiredSteps() {
    let result = []

    mainConfig.configureSteps.forEach((stepName) => {
      const found = availableConfigureSteps.find((x) => x.name === stepName)

      if (found) {
        const isShow = _.isFunction(found.condition) ? found.condition() : found.condition

        if (isShow) {
          result.push(found)
        }
      }
    })

    return result
  }

  checkTosVersion(type = '') {
    type = _.camelCase(type)

    const currentTos = _.get($user.info.tosDocs, [type, 'version'])
    const expectedTos = _.get($main.settings, ['tosVersions', type])

    return Number(currentTos) >= Number(expectedTos) // true if correct
  }

  async setTosAccepted(props) {
    const response = await userService.acceptTos(props)

    if (response.result) {
      $auth._fillData(response)
      return { isSuccess: true }
    } else {
      return { isSuccess: false }
    }
  }
}

export const $configure = new configureStore()
