import { routerSearchParams, toPath } from '@helpers/router'
import WebView from '@helpers/webView'

import mainConfig from '@config/main'

// () => ({to, href, wvAction, onClick})
export const routeActions = {
  BRAND_ROOT: () => {
    const searchParams = routerSearchParams()
    const backUrl = searchParams.get('backUrl')

    if (backUrl) {
      return {
        href: backUrl,
        wvAction: WebView.close,
      }
    }

    return {
      href: mainConfig.web.parentUrl,
      wvAction: WebView.close,
    }
  },
  APPT: ({ appointmentId, encounterIds, encounterId }) => {
    if (!_.isEmpty(encounterIds) || encounterId) {
      return {
        to: toPath('apptEncounter', {
          params: { id: appointmentId, encounterId: encounterId || _.head(encounterIds) },
        }),
      }
    }
    return {
      to: toPath('appt', { params: { id: appointmentId } }),
    }
  },
}
