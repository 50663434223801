import React from 'react'

import { observer } from 'mobx-react'

import Checkbox from '@library/ui/checkbox/Checkbox'

import { $booking } from '@store'

import styles from './SymptomItem.module.scss'

const SymptomItem = ({ symptom }) => {
  return (
    <div
      key={symptom.id}
      className={styles.item}
      onClick={() => $booking.$symptoms.TOGGLE_SELECTED(symptom.id)}
    >
      {symptom.name}
      <Checkbox
        className={styles.checkbox}
        isChecked={$booking.$symptoms.isSelected(symptom.id)}
        isSmall={true}
        isMulti={true}
      />
    </div>
  )
}

export default observer(SymptomItem)
