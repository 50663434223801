import React, { useMemo } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import ApptsListPageItemDesktop from '@pages/appts/ApptsListPageItemDesktop'
import ApptsListPageItemMobile from '@pages/appts/ApptsListPageItemMobile'

import { extendAddress } from '@helpers/addressHelper'
import hAppt from '@helpers/hAppt'

import { $main } from '@store'

const ApptsListPageItem = ({ fullAppt }) => {
  const { t } = useTranslation()

  const { appointment, service, place, office, patients, payment = {}, paymentMethod } = fullAppt

  const status = useMemo(() => hAppt.getCurrentStatus({ fullAppt }), [fullAppt])
  const extendedPlace = extendAddress(place)

  return (
    <>
      {$main.isMobile ? (
        <ApptsListPageItemMobile
          status={status}
          appointment={appointment}
          patients={patients}
          service={service}
          payment={payment}
        />
      ) : (
        <ApptsListPageItemDesktop
          status={status}
          appointment={appointment}
          place={place}
          office={office}
          patients={patients}
          service={service}
          payment={payment}
          extendedPlace={extendedPlace}
        />
      )}
    </>
  )
}

export default observer(ApptsListPageItem)
