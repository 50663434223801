import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react'

import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'

import classNames from 'classnames'

import ClickOutside from '@library/ClickOutside'

import styles from './DropDown.module.scss'

const DropDown = ({ renderButton, items, align = 'left' }) => {
  const buttonRef = useRef()
  const popupRef = useRef()
  const [isVisible, setIsVisible] = useState(false)

  const handleClose = (event) => {
    const button = buttonRef.current
    const target = event.target
    const isEventOnButton = button && (button === target || button.contains(target))

    if (!isEventOnButton) {
      setIsVisible(false)
    }
  }

  const handleClick = (item) => {
    item.action()
    setIsVisible(false)
  }

  const changePosition = useCallback(() => {
    const button = buttonRef.current
    const popup = popupRef.current

    if (!popup || !button) return

    const rect = button.getBoundingClientRect()
    popup.style.top = `${rect.bottom + 10}px`
    if (align === 'right') {
      popup.style.right = `${document.documentElement.clientWidth - rect.right}px`
    } else {
      popup.style.left = `${rect.left > 0 ? rect.left : 0}px`
    }
  }, [])

  useEffect(() => {
    if (isVisible) {
      document.addEventListener('scroll', changePosition)
      return () => document.removeEventListener('scroll', changePosition)
    }
  }, [isVisible, changePosition])

  useEffect(() => {
    const button = buttonRef.current

    if (button) {
      const handleToggle = () => {
        changePosition()
        setIsVisible((prevState) => !prevState)
      }

      button.addEventListener('click', handleToggle)
      return () => button.removeEventListener('click', handleToggle)
    }
  }, [])

  return (
    <ClickOutside className={classNames(styles.dropdown, 'dropdown')} onClick={handleClose}>
      {renderButton(buttonRef)}
      <div ref={popupRef} className={classNames(styles.popup, isVisible && styles.popupVisible)}>
        {items.map((item, index) => (
          <Fragment key={item.id + index + item.label}>
            {item.type === 'link' ? (
              <Link
                to={item.action}
                className={classNames(styles.popupItem, item.disabled && styles.popupItemDisabled)}
                onClick={handleClose}
              >
                {item.label}
              </Link>
            ) : (
              <div
                onClick={() => handleClick(item)}
                className={classNames(styles.popupItem, item.disabled && styles.popupItemDisabled)}
              >
                {item.label}
              </div>
            )}
          </Fragment>
        ))}
      </div>
    </ClickOutside>
  )
}

export default observer(DropDown)
