import { makeAutoObservable } from 'mobx'

import bookingApiHelpers from '@booking/helpers/bookingApiHelpers'
import _ from 'lodash'

import { $patients } from '@store'

import { PATIENT_GROUP_TYPES } from '@config/services'

class BookingPatientsStore {
  _rootStore

  constructor(store) {
    makeAutoObservable(this)
    this._rootStore = store
  }

  selectedIds = []
  updatedValues = {}

  // Clear
  clear() {
    this.selectedIds = []
  }

  // Computed
  get originalItems() {
    return $patients.items
  }

  get items() {
    return $patients.items
      .filter((x) => this.selectedIds.includes(x.id))
      .map((x) => ({ ...x, ...this.updatedValues[x.id] }))
  }

  get isAllPatientsValid() {
    return this.items.every((item) => item.email && item.phone)
  }

  get hasUpdatedValues() {
    return _.some(this.updatedValues, (item) => _.has(item, 'phone') || _.has(item, 'email'))
  }
  // Mutations

  // Actions
  getOriginalById(id) {
    return _.find(this.originalItems, (x) => String(x.id) === String(id))
  }

  async get() {
    return await $patients.get()
  }

  addSelected(id) {
    if (!this.selectedIds.includes(id)) {
      if (this._rootStore.service?.patientGroupType === PATIENT_GROUP_TYPES.family) {
        this.selectedIds.push(id)
      } else {
        this.selectedIds = [id]
      }
    }
  }

  removeSelected(id) {
    this.selectedIds = this.selectedIds.filter((selectedId) => selectedId !== id)
  }

  async updateSelected(id, values, updateOriginal = false) {
    this.updatedValues[id] = { ...this.updatedValues[id], ...values }

    if (updateOriginal) {
      const { voucher, useInsurance, ...updatedValues } = values
      const originalValues = _.pick($patients.getById(id), Object.keys(updatedValues))

      if (!_.isEqual(updatedValues, originalValues)) {
        const formattedValues = bookingApiHelpers.getFormattedPatientUpdatedValues(updatedValues)
        const res = await $patients.update(id, formattedValues)
        return res.isSuccess
      }
    }

    return true
  }

  updateAllSelected(getUpdatedValues) {
    const newUpdatedValues = { ...this.updatedValues }
    this.items.forEach((item) => {
      newUpdatedValues[item.id] = { ...newUpdatedValues[item.id], ...getUpdatedValues(item) }
    })
    this.updatedValues = newUpdatedValues
  }
}

export default BookingPatientsStore
