const pmsConfig = {
  list: [
    {
      brand: 'visa',
      type: 'visa',
      icon: 'payments/visa.svg',
      label: 'Visa',
    },
    {
      brand: 'mastercard',
      type: 'mastercard',
      icon: 'payments/mastercard.svg',
      label: 'Mastercard',
    },
    {
      brand: 'maestro',
      type: 'maestro',
      icon: 'payments/maestro.svg',
      label: 'Maestro',
    },
    {
      brand: 'american-express',
      type: 'american-express',
      icon: 'payments/american-express.svg',
      label: 'American Express',
    },
  ],
  get(brand) {
    return _.find(this.list, (x) => x.brand === brand)
  },
  getLabel(brand) {
    let item = this.get(brand)
    return item ? item.label : brand
  },
}

export default pmsConfig
