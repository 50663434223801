import React from 'react'

const MedicalHistory = () => (
  <svg width="36" height="36" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m15.429 5.143-.227.006A3.857 3.857 0 0 0 11.572 9v2.571H9a3.857 3.857 0 0 0-3.857 3.858v5.142l.006.227A3.857 3.857 0 0 0 9 24.428h2.571V27a3.857 3.857 0 0 0 3.858 3.857h5.142l.227-.006A3.857 3.857 0 0 0 24.428 27v-2.571H27a3.857 3.857 0 0 0 3.857-3.858V15.43l-.006-.227A3.857 3.857 0 0 0 27 11.572h-2.571V9a3.857 3.857 0 0 0-3.858-3.857H15.43zm0 2.571h5.142c.71 0 1.286.576 1.286 1.286v3.857c0 .71.576 1.286 1.286 1.286H27c.71 0 1.286.575 1.286 1.286v5.142c0 .71-.576 1.286-1.286 1.286h-3.857c-.71 0-1.286.576-1.286 1.286V27c0 .71-.575 1.286-1.286 1.286H15.43c-.71 0-1.286-.576-1.286-1.286v-3.857c0-.71-.576-1.286-1.286-1.286H9c-.71 0-1.286-.575-1.286-1.286V15.43c0-.71.576-1.286 1.286-1.286h3.857c.71 0 1.286-.576 1.286-1.286V9c0-.71.575-1.286 1.286-1.286z"
      fill="#FFF"
      fillRule="evenodd"
    />
  </svg>
)

export default MedicalHistory
