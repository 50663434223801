import React from 'react'

import { Link } from 'react-router-dom'

import queryString from 'query-string'

import { useRouterSearchParams } from '@helpers/router'
import { nativeScrollTo } from '@helpers/url'

export const TosLink = ({ to = '', type, children, onlyScroll = false }) => {
  const searchParams = useRouterSearchParams()

  function handleClick(e) {
    e.preventDefault()

    nativeScrollTo(to)

    if (!onlyScroll) {
      searchParams.set({ [type]: to })
    }
  }

  return (
    <Link to={'?' + queryString.stringify({ [type]: to })} onClick={handleClick}>
      {children}
    </Link>
  )
}
