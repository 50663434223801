import React from 'react'

const ExaminationTableDetails = ({ event, resource, extractor }) => {
  const { isLegacy, template, valuesAsString } = extractor

  if (isLegacy) {
    return <>{valuesAsString}</>
  }

  return <>{template.display}</>
}

export default ExaminationTableDetails
