import { useEffect, useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import i18n from 'i18next'

import { useFirstRender } from '@library/CustomHooks'

import { resourceExtractor } from '@ps/helpers/resourceExtractor'

import { $psHistory, $psResources } from '@store'

export const useGetMediaItems = ({ isLoading, items }) => {
  const { t } = useTranslation()

  return useMemo(() => {
    let result = []

    if (isLoading) {
      return result
    }

    _.forEach(items, ({ event, resource }) => {
      if (_.isEmpty(resource)) {
        resource = $psResources.getById(event.id)
      }

      const extractor = resourceExtractor(event, resource)
      let label = ''

      if (_.isString(extractor.type)) {
        label = extractor.type
      } else if (i18n.exists(event.label)) {
        label = t(event.label)
      }

      result.push({
        ...extractor.content,
        id: event.id,
        effectiveDate: event.effectiveDate,
        title: [label, extractor.text].filter((x) => x).join(': '),
        source: { event, resource, extractor },
      })
    })

    return result
  }, [isLoading, items]) // eslint-disable-line react-hooks/exhaustive-deps
}

export const useInitPatient = ({ categoryId }) => {
  const isFirstRender = useFirstRender()

  useEffect(
    () => () => $psResources.clear(),
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  useEffect(
    () => {
      if (!isFirstRender) {
        $psHistory.clearSearch()
      }
      $psHistory.SET_CATEGORY(categoryId)
      $psHistory.clearSubcategory()
    },
    [categoryId], // eslint-disable-line react-hooks/exhaustive-deps
  )
}
