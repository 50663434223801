var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import DayPickerCalendar from '@library/newDatePicker/dayPicker/components/dayPickerCalendar/DayPickerCalendar';
import DayPickerMonth from '@library/newDatePicker/dayPicker/components/dayPickerMonth/DayPickerMonth';
import DayPickerSelector from '@library/newDatePicker/dayPicker/components/dayPickerSelector/DayPickerSelector';
import DayPickerYear from '@library/newDatePicker/dayPicker/components/dayPickerYear/DayPickerYear';
import { getCalendarRows, getMonthRows, getYearRows, } from '@library/newDatePicker/dayPicker/DayPicker.helpers';
import { getScrollToDate } from '@library/newDatePicker/NewDatePicker.helpers';
import { useListRef } from '@library/newDatePicker/NewDatePicker.hooks';
var DayPicker = forwardRef(function (_a, ref) {
    var currentDate = _a.currentDate, selectedDate = _a.selectedDate, setDate = _a.setDate, minDate = _a.minDate, maxDate = _a.maxDate, _b = _a.shownOtherDays, shownOtherDays = _b === void 0 ? false : _b, availableDates = _a.availableDates, disabledDates = _a.disabledDates, forceUpdate = _a.forceUpdate, scrollTo = _a.scrollTo;
    // Internal of date model for switching date
    var _c = useState(currentDate.clone()), model = _c[0], setModel = _c[1];
    useEffect(function () {
        if (!selectedDate) {
            return;
        }
        setModel(selectedDate.clone());
    }, [selectedDate]);
    var _d = useState('calendar'), view = _d[0], setView = _d[1];
    var keyBoard = model.format('YYYYMM');
    var getBoard = function (type) {
        return (keyBoard in board && board[keyBoard][type]) || [];
    };
    var getYearBoard = function () {
        return (keyBoard in board && board[keyBoard]['year']) || [];
    };
    var boardMemo = useMemo(function () {
        var _a;
        return _a = {},
            _a[keyBoard] = {
                day: getCalendarRows(model, shownOtherDays),
                month: getMonthRows(model),
                year: getYearRows(model, minDate, maxDate),
            },
            _a;
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    var _e = useState(boardMemo), board = _e[0], setBoard = _e[1];
    useEffect(function () {
        if (!(keyBoard in board)) {
            setBoard(function (original) {
                var _a;
                return __assign(__assign({}, original), (_a = {}, _a[keyBoard] = {
                    day: getCalendarRows(model, shownOtherDays),
                    month: getMonthRows(model),
                    year: getYearRows(model, minDate, maxDate),
                }, _a));
            });
        }
    }, [model]); // eslint-disable-line react-hooks/exhaustive-deps
    useEffect(function () { return forceUpdate(); }, [view]); // eslint-disable-line react-hooks/exhaustive-deps
    useImperativeHandle(ref, function () {
        return {
            getView: function () { return view; },
            goToBack: function () { return setView('calendar'); },
        };
    }, [view]);
    var _f = useListRef(), refDay = _f[0], setRefDay = _f[1];
    // Scroll to day
    useEffect(function () {
        if (view === 'calendar') {
            var scrollToDate = getScrollToDate(selectedDate, currentDate, minDate, maxDate, scrollTo);
            if (!selectedDate) {
                setModel(scrollToDate);
            }
        }
    }, [selectedDate, scrollTo]); // eslint-disable-line react-hooks/exhaustive-deps
    var _g = useListRef(), refYear = _g[0], setRefYear = _g[1];
    // Scroll to selected or current year
    useEffect(function () {
        if (view === 'year') {
            var key = selectedDate ? selectedDate.format('YYYY') : currentDate.format('YYYY');
            if (key in refYear) {
                refYear[key].scrollIntoView({ block: 'center' });
            }
        }
    }, [view]); // eslint-disable-line react-hooks/exhaustive-deps
    return (_jsx("div", __assign({ className: "day-picker" }, { children: _jsxs("div", __assign({ className: "body" }, { children: [view === 'calendar' && (_jsxs(_Fragment, { children: [_jsx(DayPickerSelector, { model: model, setModel: setModel, setView: setView, minDate: minDate, maxDate: maxDate }), _jsx(DayPickerCalendar, { currentDate: currentDate, selectedDate: selectedDate, model: model, setDate: setDate, minDate: minDate, maxDate: maxDate, availableDays: availableDates === null || availableDates === void 0 ? void 0 : availableDates.day, disabledDays: disabledDates === null || disabledDates === void 0 ? void 0 : disabledDates.day, board: getBoard('day'), setRefDay: setRefDay })] })), view === 'year' && (_jsx(DayPickerYear, { currentDate: currentDate, selectedDate: selectedDate, setModel: setModel, setView: setView, minDate: minDate, maxDate: maxDate, availableYears: availableDates === null || availableDates === void 0 ? void 0 : availableDates.year, board: getYearBoard(), setRefYear: setRefYear })), view === 'month' && (_jsx(DayPickerMonth, { currentDate: currentDate, selectedDate: selectedDate, setModel: setModel, setView: setView, minDate: minDate, maxDate: maxDate, availableMonths: availableDates === null || availableDates === void 0 ? void 0 : availableDates.month, board: getBoard('month') }))] })) })));
});
export default DayPicker;
