import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import BaseDocumentPdf from '@library/media/mediaTypes/base/BaseDocumentPdf'
import ProgressRing from '@library/ui/progressRing/ProgressRing'

import styles from './Types.module.scss'

const DocumentPdf = ({ src, onIsLoading, openInIframe }) => {
  const { t } = useTranslation()

  const [isLoading, setIsLoading] = useState(true) //
  const [isDefault, setIsDefault] = useState(false)

  function changeIsLoading(value) {
    setIsLoading(value)

    if (_.isFunction(onIsLoading)) {
      onIsLoading(value)
    }
  }

  return (
    <div
      className={classNames(
        styles.document,
        isLoading && styles.isLoading,
        // isDefault && styles.isDefault,
      )}
    >
      <BaseDocumentPdf
        src={src}
        onIsLoading={changeIsLoading}
        onIsDefault={setIsDefault}
        openInIframe={openInIframe}
      />
      {isLoading && (
        <div className={styles.progressWrapper}>
          <ProgressRing radius={28} progress={25} spin={true} centered={true} />
          <div className={classNames('text_dotty', styles.textDotty)}>{t('label.loading')}</div>
        </div>
      )}
    </div>
  )
}

export default DocumentPdf
