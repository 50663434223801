import React from 'react'

import { formatDate } from '@helpers/time'

const DocumentsTableDate = ({ event }) => {
  return (
    <span className="event_date">{formatDate(event.effectiveDate, { format: 'datetime' })}</span>
  )
}

export default DocumentsTableDate
