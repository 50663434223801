import React from 'react'

import { observer } from 'mobx-react'

import RightSidebar from '@src/layouts/rightSidebar/RightSidebar'

import { useAppInitLoader } from '@services/auth.hooks'

import ActionConfirmator from '@library/ActionConfirmator'
import AlertsViewer from '@library/alerts/AlertsViewer'
import { useIsWindows, useRemoveRootLoader, useWindowSize } from '@library/CustomHooks'
import ApiDeprecationChecker from '@library/deprecation/ApiDeprecationChecker'
import ModalViewer from '@library/modal/ModalViewer'
import NotificationsViewer from '@library/notifications/NotificationsViewer'
import { PageSwitcher } from '@library/PageProvider'
import TosLauncher from '@library/plugins/termsOfService/TosLauncher'
import PopoverViewer from '@library/popover/PopoverViewer'
import Notifications from '@library/widgets/notifications/Notifications'

import { useWebViewAnchorClickHandler } from '@helpers/webView'

import { $appts, $user } from '@store'

import '@assets/styles/styles.scss'

const App = () => {
  useWindowSize()
  useIsWindows()
  useWebViewAnchorClickHandler()

  const isLoading = useAppInitLoader()
  useRemoveRootLoader(isLoading)

  return (
    <>
      {!isLoading && ($user.user || !$user.isLoading) && (
        <>
          <PageSwitcher />
          {$user.user && (
            <RightSidebar
              config={{
                notifications: {
                  component: <Notifications />,
                  bottom: $appts.nearestBarIsVisible && $appts.nearestBarHeight,
                  minWidth: 365,
                },
              }}
            />
          )}
        </>
      )}
      {/* app plugins */}
      <AlertsViewer />
      <NotificationsViewer />
      <ModalViewer />
      <ActionConfirmator />
      <TosLauncher />
      <ApiDeprecationChecker />
      <PopoverViewer />
    </>
  )
}

export default observer(App)
