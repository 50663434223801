import React, { useEffect } from 'react'

import { observer } from 'mobx-react'
import { useInView } from 'react-intersection-observer'

import ApptsListPageItem from '@pages/appts/ApptsListPageItem'

const ApptsListPage = ({ items, pages, page, onIncreasePage, perPage = 10 }) => {
  const { ref, inView } = useInView({ triggerOnce: true })

  useEffect(() => {
    const isLastPage = pages === page
    const isFullPage = items.length >= perPage

    if (inView && isLastPage && isFullPage) {
      onIncreasePage()
    }
  }, [inView]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {items.length > 0 &&
        items.map((fullAppt) => (
          <ApptsListPageItem fullAppt={fullAppt} key={fullAppt.appointment.id} />
        ))}
      {!inView && <div ref={ref} />}
    </>
  )
}

export default observer(ApptsListPage)
