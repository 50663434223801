import React, { useMemo } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import Modal from '@library/modal/Modal'
import Button from '@library/ui/button/Button'
import UserPhoto from '@library/user/UserPhoto'

import { T } from '@helpers/text'
import { getUserText } from '@helpers/user'

import { $appts, $main } from '@store'

import styles from './ActorProfile.modal.module.scss'

const ActorProfileModal = ({ byRoute, _core, role = '' }) => {
  const { t } = useTranslation()

  const { fullAppt, actions } = byRoute || $appts.byRoute
  const actor = fullAppt[role] || {}

  const specialties = useMemo(() => {
    let items = actor.specialty || []

    items = _.split(items, ',')
    items = items.filter((x) => x)
    items = items.map((x) => _.upperFirst(x.trim()))

    return items
  }, [actor.specialty])

  const chatGeneral = actions.textGeneral || actions.viewTextGeneral
  const callOffice = actions.callOffice

  return (
    <Modal className={styles.actorProfile} centered scrollable>
      <div className="modal-toolbar">
        <div className="modal-toolbar-btn modal-toolbar-btn--close" onClick={_core.onHide} />
      </div>
      <Modal.Body className={classNames(styles.modalBody, 'styled_scroll')}>
        <div className={styles.userPhoto}>
          <UserPhoto item={actor} />
        </div>
        <div className={classNames(styles.title, 'title-md')}>
          {getUserText(actor, ['fullName'])}
        </div>
        <div className={styles.meta}>
          <span className={styles.metaRole}>{t('actor_profile.role.' + role)}</span>
          {/*<span className={styles.metaRating}>*/}
          {/*  4.9*/}
          {/*</span>*/}
        </div>

        <div className={styles.contacts}>
          <Button
            tag="button"
            buttonType="button"
            className={classNames(styles.contactsItem, !chatGeneral && styles.contactsItemDisabled)}
            action={() => chatGeneral && chatGeneral.action()}
          >
            <div className={classNames(styles.contactsIcon, styles.contactsIconText)} />
            <div className={styles.contactsTitle}>{t('chat.general.title')}</div>
          </Button>
          {
            <Button
              tag="button"
              buttonType="button"
              className={classNames(
                styles.contactsItem,
                !callOffice && styles.contactsItemDisabled,
              )}
              action={() => callOffice && callOffice.action()}
            >
              <div className={classNames(styles.contactsIcon, styles.contactsIconPhone)} />
              <div className={styles.contactsTitle}>{t('actor_profile.call.office')}</div>
            </Button>
          }
        </div>
        {actor.description && (
          <T className={classNames(styles.desc, 'text-group text-pre-line')}>{actor.description}</T>
        )}
        {specialties.length > 0 && (
          <div className={styles.specialties}>
            <div className="title-sm mb-10">{t('label.specialties')}</div>
            <div className="meta-group">
              {specialties.map((item) => (
                <div className="meta meta--secondary-dark" key={item}>
                  {item}
                </div>
              ))}
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default observer(ActorProfileModal)
