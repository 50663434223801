import { useLayoutEffect, useMemo, useRef } from 'react';
import TimeSlotPickerHelpers from '@library/timeSlotPicker/TimeSlotPicker.helpers';
var TimeSlotPickerHooks;
(function (TimeSlotPickerHooks) {
    TimeSlotPickerHooks.useFixedScroll = function (options) {
        var prevDependency = useRef(null);
        var prevContentRect = useRef(null);
        var scrollRef = useRef(null);
        var contentRef = useRef(null);
        var scrollDependency = options.scrollDependency, changeContentDependency = options.changeContentDependency, horizontalScrollToElement = options.horizontalScrollToElement;
        useLayoutEffect(function () {
            if (!scrollRef.current || !contentRef.current) {
                return;
            }
            var resizeObserver = new ResizeObserver(function (entries) {
                for (var _i = 0, entries_1 = entries; _i < entries_1.length; _i++) {
                    var entry = entries_1[_i];
                    if (scrollDependency !== prevDependency.current) {
                        prevDependency.current = scrollDependency;
                        if (prevContentRect.current === null) {
                            prevContentRect.current = entry.contentRect;
                            if (horizontalScrollToElement === null || horizontalScrollToElement === void 0 ? void 0 : horizontalScrollToElement.current) {
                                scrollRef.current.scrollLeft =
                                    horizontalScrollToElement.current.offsetLeft -
                                        scrollRef.current.offsetWidth / 2 +
                                        horizontalScrollToElement.current.offsetWidth / 2;
                                resizeObserver.disconnect();
                                return;
                            }
                        }
                        if (prevContentRect.current.height !== entry.contentRect.height) {
                            scrollRef.current.scrollTop =
                                entry.contentRect.height - prevContentRect.current.height;
                        }
                        if (prevContentRect.current.width !== entry.contentRect.width) {
                            scrollRef.current.scrollLeft =
                                entry.contentRect.width - prevContentRect.current.width - 200;
                        }
                        prevContentRect.current = entry.contentRect;
                        resizeObserver.disconnect();
                    }
                }
            });
            resizeObserver.observe(contentRef.current);
            return function () { return resizeObserver.disconnect(); };
        }, [scrollDependency]);
        useLayoutEffect(function () {
            if (!scrollRef.current || !contentRef.current) {
                return;
            }
            var resizeObserver = new ResizeObserver(function (entries) {
                for (var _i = 0, entries_2 = entries; _i < entries_2.length; _i++) {
                    var entry = entries_2[_i];
                    prevContentRect.current = entry.contentRect;
                    resizeObserver.disconnect();
                }
            });
            resizeObserver.observe(contentRef.current);
            return function () { return resizeObserver.disconnect(); };
        }, [changeContentDependency]);
        return { scrollRef: scrollRef, contentRef: contentRef };
    };
    TimeSlotPickerHooks.useParsedDate = function (date, timezone) {
        return useMemo(function () {
            return TimeSlotPickerHelpers.getParsedDate(date, timezone);
        }, [date, timezone]);
    };
})(TimeSlotPickerHooks || (TimeSlotPickerHooks = {}));
export default TimeSlotPickerHooks;
