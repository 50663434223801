import React from 'react'

import { toJS } from 'mobx'

import i18n from 'i18next'

import ViewInsuranceModal from '@library/addInsuranceModal/ViewInsurance.modal'

import { extendAddress } from '@helpers/addressHelper'
import { getEnumsValue } from '@helpers/enums'

import { $modal } from '@store'

import { ENUMS_INSURANCE_TYPES } from '@config/enums/insurance'
import { ENUMS_TELECOM_PHONE } from '@config/enums/telecom'

export function getTelecom(items, type) {
  items = _.filter(items, (x) => x.system === type)

  return items.map((x) => ({
    label: '',
    value: (
      <>
        {x.value}{' '}
        {x.use && type === 'phone' && (
          <span className="text-muted">&bull; {getEnumsValue(x.use, ENUMS_TELECOM_PHONE)}</span>
        )}
      </>
    ),
  }))
}

export function getAddress(places) {
  let result = _.map(places, (place) => {
    const address = toJS(place) || {}

    return {
      label: '',
      value: extendAddress({
        zip: address.postalcode,
        state: address.state,
        country: address.country,
        street: address.line?.[0],
        city: address.city,
        apartment: address.line?.[1],
      }).address(),
    }
  })
  return result.filter((x) => x.value)
}

export function getInsurances(items) {
  return _.map(items, (x) => {
    return {
      label: '',
      value: (
        <>
          <span
            className="a"
            onClick={(e) => {
              e.stopPropagation()
              $modal.add(ViewInsuranceModal, { data: x })
            }}
          >
            {x.insuranceProviderName}
          </span>{' '}
          <span className="text-muted">
            &bull; {getEnumsValue(x.insuranceType, ENUMS_INSURANCE_TYPES)}
          </span>
        </>
      ),
    }
  })
}

export function getLifestyle(data = {}) {
  let result = []

  function getValue(value, label) {
    return {
      label: '',
      value: (
        <>
          {value} <span className="text-muted">&bull; {label}</span>
        </>
      ),
    }
  }

  if (data.sexualBehaviorSocialHistory?.text) {
    result.push(
      getValue(
        data.sexualBehaviorSocialHistory.text,
        i18n.t('ps.notes.social_history.label.sexual_behavior'),
      ),
    )
  }

  if (data.hobbiesOrOtherRecreationalActivitiesSocialHistory?.text) {
    result.push(
      getValue(
        data.hobbiesOrOtherRecreationalActivitiesSocialHistory.text,
        i18n.t('ps.notes.social_history.label.hobbies'),
      ),
    )
  }

  if (data.travelSocialHistory?.text) {
    result.push(
      getValue(data.travelSocialHistory.text, i18n.t('ps.notes.social_history.label.travel')),
    )
  }

  if (data.hoursOfSleepSocialHistory?.text) {
    result.push(
      getValue(
        data.hoursOfSleepSocialHistory.text,
        i18n.t('ps.notes.social_history.label.hours_of_sleep'),
      ),
    )
  }

  return result
}
