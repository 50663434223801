import React from 'react'

import { useTranslation } from 'react-i18next'

import Map from '@booking/pages/bookingHome/selectAddress/selectAddressMap/SelectAddressMap'
import classNames from 'classnames'

import BubbleLoader from '@library/ui/bubbleLoader/BubbleLoader'
import Button from '@library/ui/button/Button'

import { extendAddress } from '@helpers/addressHelper'

import ArrowRight from '@assets/images/ui/right.svg'

import styles from './SelectAddressMobile.module.scss'

const SelectAddressMobile = ({
  placeDetails,
  isDetailedPlace,
  isCollapsedMap,
  onClickBook,
  onClickChange,
}) => {
  const { t } = useTranslation()

  return (
    <div className={classNames(styles.mobile, { [styles.mobileMapHeight]: !isCollapsedMap })}>
      <div
        className={styles.mobileAddress}
        onClick={() => {
          if (isCollapsedMap) {
            onClickChange()
          }
        }}
      >
        {isCollapsedMap ? (
          <div className={styles.mobileAddressMeta}>
            {t('book_review.address')}
            <div className={styles.mobileAddressArrow}>
              <img className={styles.mobileAddressImage} src={ArrowRight} alt="" />
            </div>
          </div>
        ) : (
          <div className={styles.mobileAddressMeta}>{t('book_review.book_for')}</div>
        )}
        <div className={styles.mobileAddressTitle}>
          {isDetailedPlace ? extendAddress(placeDetails).buildingStreetCity() : <BubbleLoader />}
        </div>
      </div>
      <div className={classNames(styles.mobileMap, isCollapsedMap && styles.collapsed)}>
        <div className={styles.mobileMapInner}>
          <Map coords={placeDetails.coords} />
        </div>
        <div className={styles.mobileMapButtonsWrapper}>
          <div className={styles.mobileMapButton}>
            <Button mode={['primary', 'block']} action={onClickBook} label={t('btn.book_here')} />
          </div>
          <div className={styles.mobileMapButton}>
            <Button
              mode={['white', 'block']}
              action={onClickChange}
              label={t('btn.change_address')}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SelectAddressMobile
