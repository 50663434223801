import React from 'react'

const Vitals = () => (
  <svg width="28" height="28" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.105 21.56c4.765-4.33 6.26-5.87 7.235-7.685.45-.837.66-1.6.66-2.375C22 9.525 20.475 8 18.5 8a3.989 3.989 0 0 0-2.977 1.387l-.762.894a1 1 0 0 1-1.522 0l-.762-.894A3.989 3.989 0 0 0 9.5 8C7.525 8 6 9.525 6 11.5c0 .778.212 1.544.666 2.386.979 1.813 2.483 3.36 7.23 7.665l.11.099.099-.09zM12.58 6.88 14 8.09l1.42-1.209A5.93 5.93 0 0 1 18.5 6c3.08 0 5.5 2.42 5.5 5.5 0 3.78-3.4 6.86-8.55 11.54l-.777.702a1 1 0 0 1-1.344-.003l-.779-.709C7.4 18.36 4 15.28 4 11.5 4 8.42 6.42 6 9.5 6a5.93 5.93 0 0 1 3.08.881z"
      fill="#FFF"
      fillRule="evenodd"
    />
  </svg>
)

export default Vitals
