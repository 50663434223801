import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import ClickOutside from '@library/ClickOutside'
import { useInnerValue, useUpdateCallback } from '@library/CustomHooks'
import InputBase from '@library/form/fields/new/InputBase'
import TypeAheadPopup from '@library/form/fields/typeAhead/TypeAheadPopup'
import { useForm } from '@library/form/FormControllerNew'
import { useGetDropdownPosition } from '@library/utils/UseGetDropdownPosition.hook'

import { delayedRequest } from '@helpers/async'
import { isAbsoluteEmpty } from '@helpers/other'

import styles from './TypeAhead.module.scss'

const TypeAhead = (
  {
    value = {}, // { id: '', text: '', rightText: '', subText: ''}

    onLoad,
    //  [
    //   { id: 'id_1', text: 'Some text 1', subText: '1111 },
    //   { id: 'id_2', text: 'Some text 2', rightText: 'Some subtext' },
    //   {
    //     id: 'g_id_1',
    //     text: 'Group 1',
    //     items: [{ id: 'id_3', text: 'Some text 3' }],
    //   },
    //   { id: 'id_4', text: 'Some text 4' },
    //   {
    //     id: 'g_id_2',
    //     text: 'Group 2',
    //     items: [
    //       { id: 'id_5', text: 'Some text 5' },
    //       { id: 'id_6', text: 'Some text 6' },
    //     ],
    //   },
    // ]
    onLoadDelayLimit = 400,
    onLoadMinSearchLength = 3,

    onChange,
    className,
    fieldAttributes = {
      // label
      // placeholder
      // maxLength
      // mode
      // isInvalid
      // unFocusedKey = 'rightText' || 'subText'
    },
    popupParams = {
      // simplifyGroups = false,
      // useAsIs = false,
    },
  },
  forwardedRef,
) => {
  const { t } = useTranslation()
  const suggestions = useRef([])
  const isNeedUpdate = useRef(true)
  const [isLoading, setIsLoading] = useState(null)
  const [isVisible, setIsVisible] = useState(false)
  const [innerValue, setInnerValue] = useInnerValue(
    value.hasOwnProperty('text') ? value : { text: value.text },
  )

  const { form, useFormOnChange } = useForm({
    searchString: { value: innerValue.text, prepValue: 'valueOrEmptyString' },
  })
  const { searchString } = form.fields

  const updateCallback = useUpdateCallback(handleTypeahead)
  useFormOnChange(updateCallback, [searchString.value])

  useEffect(() => {
    searchString.onChange(innerValue.text)
  }, [innerValue]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isVisible && isNeedUpdate.current) {
      updateCallback()
    }
  }, [isVisible]) // eslint-disable-line react-hooks/exhaustive-deps

  const { fieldRef, dropdownRef, dropdownStyles } = useGetDropdownPosition({
    isOpen: isVisible,
    alwaysBottom: true,
    itemsLength: suggestions.current?.length,
  })

  async function handleTypeahead() {
    if (searchString.value?.length < onLoadMinSearchLength) {
      if (isAbsoluteEmpty(searchString.value)) {
        suggestions.current = []
      }
      setIsLoading(null)
      return false
    }

    if (!isVisible) {
      return false
    }

    setIsLoading(true)

    suggestions.current = await delayedRequest(async () => {
      dropdownRef.current.style.height = 'initial' // without this reset height calculated wrong when we have auto focus after render

      let result = await onLoad({
        searchString: searchString.value,
      })
      return _.isArray(result) ? result : []
    }, onLoadDelayLimit)

    setIsLoading(false)
  }

  function handleSelect(item) {
    if (item.text !== innerValue.text) {
      isNeedUpdate.current = true
      suggestions.current = []
    } else {
      isNeedUpdate.current = false
    }
    setInnerValue(item)
    onChange(item)
    setIsVisible(false)
  }

  function handleClickOutside() {
    if (searchString.value.length) {
      searchString.onChange(innerValue.text)
    }
    if (!window.getSelection()?.toString()) {
      setIsVisible(false)
    }
  }

  useImperativeHandle(forwardedRef, () => ({
    setIsVisible(x) {
      setIsVisible(x)
    },
  }))

  return (
    <div ref={fieldRef} className={classNames(styles.wrapper, className)}>
      <ClickOutside onClick={handleClickOutside} className={classNames(styles.wrapper, className)}>
        <InputBase
          field={{
            value: searchString.value,
            onChange: (value) => {
              if (fieldAttributes.isInvalid) {
                onChange(value)
              }
              searchString.onChange(value)
            },
            isInvalid: fieldAttributes.isInvalid,
          }}
          label={fieldAttributes.label || t('label.label')}
          placeholder={fieldAttributes.placeholder || t('label.search')}
          maxLength={fieldAttributes.maxLength || 150}
          mode={fieldAttributes.mode || 'lg'}
          clear={true}
          onClear={() => handleSelect({ text: '' })}
          onFocus={() => setIsVisible(true)}
          unFocusComponent={
            fieldAttributes.unFocusedKey &&
            !isAbsoluteEmpty(innerValue.text) &&
            innerValue.text === searchString.value ? (
              <div className={styles.unFocusedWrapper}>
                <div className={styles.text}>{innerValue.text}</div>
                {innerValue[fieldAttributes.unFocusedKey] && (
                  <div className={styles.meta}>{innerValue[fieldAttributes.unFocusedKey]}</div>
                )}
              </div>
            ) : null
          }
        />
        <div
          ref={dropdownRef}
          style={{ ...dropdownStyles, ...(!isVisible ? { height: '0' } : {}) }} // without this reset height calculated wrong when we have auto focus after render
          className={styles.ddWrapper}
        >
          {isVisible && searchString.value?.length >= onLoadMinSearchLength && (
            <TypeAheadPopup
              searchString={searchString}
              items={suggestions.current}
              onSelect={handleSelect}
              isLoading={isLoading}
              simplifyGroups={popupParams.simplifyGroups}
              useAsIs={popupParams.useAsIs}
              fieldWidth={fieldRef?.current && fieldRef.current.getBoundingClientRect().width}
            />
          )}
        </div>
      </ClickOutside>
    </div>
  )
}

export default observer(forwardRef(TypeAhead))
