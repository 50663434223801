var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Fragment, useMemo } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import TimeSlotPickerHelpers from '@library/timeSlotPicker/TimeSlotPicker.helpers';
import TimeSlotPickerHooks from '@library/timeSlotPicker/TimeSlotPicker.hooks';
import ProgressRing from '@library/ui/progressRing/ProgressRing';
import { $main } from '@store';
import styles from './WindowSlotPicker.module.scss';
var useParsedDate = TimeSlotPickerHooks.useParsedDate;
var WindowSlotPicker = function (_a) {
    var selectedWindow = _a.selectedWindow, onChangeWindow = _a.onChangeWindow, placeTimezone = _a.placeTimezone, isLoading = _a.isLoading, _b = _a.windows, windows = _b === void 0 ? [] : _b, cellNumberInRow = _a.cellNumberInRow;
    var parsedSelectedWindow = useParsedDate(selectedWindow, placeTimezone);
    var parsedWindows = useMemo(function () {
        var result = {};
        _.forEach(windows, function (item) {
            var date = TimeSlotPickerHelpers.getParsedDate(item.key, placeTimezone);
            var key = date.tz(placeTimezone).format('A');
            if (!(key in result)) {
                result[key] = [];
            }
            result[key].push(date);
        });
        return result;
    }, [windows]);
    var isSelected = function (value) {
        return parsedSelectedWindow.isSame(value);
    };
    var cellWidth = useMemo(function () {
        var cellNumber = _.isNumber(cellNumberInRow)
            ? cellNumberInRow
            : $main.windowSize.isSM
                ? cellNumberInRow.tablet
                : $main.windowSize.isXS
                    ? cellNumberInRow.mobile
                    : cellNumberInRow.desktop;
        var cellWidth = "calc((100% - ".concat(cellNumber * 10 - 10, "px) / ").concat(cellNumber, ")");
        return cellWidth;
    }, [cellNumberInRow, $main.windowSize.isSM, $main.windowSize.isXS]);
    return (_jsxs("div", __assign({ className: styles.WindowSlotPicker }, { children: [isLoading && (_jsx("div", __assign({ className: styles.loading }, { children: _jsx(ProgressRing, { progress: 25, spin: true, className: '' }) }))), _jsx("div", __assign({ className: styles.body }, { children: Object.keys(parsedWindows).map(function (key) { return (_jsx(Fragment, { children: _jsxs("div", __assign({ className: styles.rowWrapper }, { children: [_jsx("div", __assign({ className: styles.chunkDay }, { children: key })), _jsx("div", __assign({ className: styles.rowSlot }, { children: parsedWindows[key].map(function (value, i) {
                                    var _a;
                                    return (_jsx(Fragment, { children: _jsx("div", __assign({ className: classNames(styles.cell, (_a = {},
                                                _a[styles.cellSelected] = isSelected(value),
                                                _a)), style: { width: cellWidth }, onClick: function () { return onChangeWindow(value.toISOString()); } }, { children: value.format('LT') })) }, value.toString() + i));
                                }) }))] })) }, key)); }) }))] })));
};
export default observer(WindowSlotPicker);
