import React from 'react'

import { observer } from 'mobx-react'

import classNames from 'classnames'

import { TransitionWrapper } from '@library/animations/Animations'
import { useTimer } from '@library/CustomHooks'

import { $alerts } from '@store'

import mainConfig from '@config/main'

import styles from './Alerts.module.scss'

const Alert = ({ options }) => {
  const {
    id,
    show,
    title,
    body,
    duration = mainConfig.notifications.displayDuration,
    preset = 'alerts',
    error = false,
    closeOnClick = true,
    pauseOnHover = false,
    onLeaved,
  } = options

  const { isRunning, start, pause } = useTimer({
    duration,
    callback: () => $alerts.hide(id),
    autoStart: duration > 0,
  })

  function handleOnClick() {
    if (closeOnClick) {
      $alerts.hide(id)
    }
  }

  function handleOnMouseHover() {
    if (pauseOnHover) {
      isRunning ? pause() : start()
    }
  }

  function handleOnLeaved() {
    if (_.isFunction(onLeaved)) {
      onLeaved()
    }

    $alerts.remove(id)
  }

  return (
    <TransitionWrapper items={show} preset={preset} onLeaved={handleOnLeaved}>
      {(item) =>
        item &&
        ((style) => (
          <div
            className={classNames(styles.item, error && styles.itemError)}
            onClick={handleOnClick}
            onMouseEnter={handleOnMouseHover}
            onMouseLeave={handleOnMouseHover}
            style={style}
          >
            {title() && <div className={styles.itemTitle}>{title()}</div>}
            <div className={styles.itemBody}>{body()}</div>
          </div>
        ))
      }
    </TransitionWrapper>
  )
}

export default observer(Alert)
