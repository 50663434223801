export function delay(ms) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), ms)
  })
}

export const delayedRequest = async (callback, delayLimit = 1000) => {
  const start = new Date().getTime()
  let result = await callback()
  const end = new Date().getTime()

  const delta = end - start

  if (delta < delayLimit) {
    await delay(delayLimit - delta)
  }

  return result
}

export async function waitForVariable(callback, timeout = 250, maxTimeout = 3000) {
  let waiting = true
  let counter = 0

  let condition = callback

  if (_.isString(callback)) {
    condition = () => _.get(window, callback)
  }

  while (waiting && _.isFunction(condition) && !condition()) {
    if (counter <= maxTimeout) {
      await new Promise((resolve) => setTimeout(resolve, timeout))
      counter += timeout
    } else {
      waiting = false
    }
  }
}

export function wrapPromise(promise) {
  let status = 'pending'
  let result

  const suspender = promise.then(
    (r) => {
      result = r
      status = 'success'
    },
    (e) => {
      result = e
      status = 'error'
    },
  )

  return {
    read() {
      if (status === 'pending') {
        throw suspender
      } else if (status === 'error') {
        throw result
      } else if (status === 'success') {
        return result
      }
    },
  }
}
