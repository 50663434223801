import React, { useEffect, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import BookingLayout from '@booking/library/bookingLayout/BookingLayout'
import AllSymptoms from '@booking/pages/bookingSymptoms/bookingSelectSymptoms/allSymptoms/AllSymptoms'
import CategorizedSymptoms from '@booking/pages/bookingSymptoms/bookingSelectSymptoms/categorizedSymptoms/CategorizedSymptoms'
import CommonSymptoms from '@booking/pages/bookingSymptoms/bookingSelectSymptoms/commonSymptoms/CommonSymptoms'
import SelectedSymptoms from '@booking/pages/bookingSymptoms/bookingSelectSymptoms/selectedSymptoms/SelectedSymptoms'
import SymptomsSearchBar from '@booking/pages/bookingSymptoms/bookingSelectSymptoms/SymptomsSearchBar'

import Button from '@library/ui/button/Button'

import { $booking } from '@store'

const BookingSelectSymptoms = ({ service, breadcrumbs, onClickNext }) => {
  const { t } = useTranslation()

  const [searchString, setSearchString] = useState('')
  const [isAllVisible, setIsAllVisible] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState(null)

  useEffect(() => {
    if (!$booking.$symptoms.isLoading) {
      setIsAllVisible(!$booking.$symptoms.common.length)
    }
  }, [$booking.$symptoms.isLoading])

  return (
    <BookingLayout
      title={t('book.ph.symptoms')}
      breadcrumbs={breadcrumbs}
      isMobileFooterFixed={true}
      button={
        <Button
          mode="primary"
          tag="button"
          buttonType="button"
          disabled={service.hasSymptoms ? !$booking.$symptoms.selectedIds.length : false}
          label={t('btn.next')}
          action={onClickNext}
        />
      }
      isDesktopFooterFixed={true}
      isLoading={$booking.$symptoms.isLoading}
    >
      <SymptomsSearchBar onChange={setSearchString} />
      <SelectedSymptoms />

      {!searchString.length && (
        <div className="simple_header mb-10 mt-30">
          <div className="title-sm mb-0">
            {selectedCategory
              ? selectedCategory.category
              : t(isAllVisible ? 'symptoms.groups.all' : 'symptoms.groups.common')}
          </div>
          {$booking.$symptoms.common.length > 0 && (
            <div
              className="simple_header-link"
              onClick={() => {
                if (selectedCategory) {
                  setSelectedCategory(null)
                  setIsAllVisible(false)
                } else {
                  setIsAllVisible(!isAllVisible)
                }
              }}
            >
              {t(
                isAllVisible || selectedCategory
                  ? 'symptoms.btn.common_symptoms'
                  : 'symptoms.btn.all',
              )}
            </div>
          )}
        </div>
      )}

      {searchString.length || isAllVisible || selectedCategory ? (
        <AllSymptoms
          items={$booking.$symptoms.categorized}
          selectedCategory={selectedCategory}
          searchString={searchString}
        />
      ) : (
        <>
          <CommonSymptoms items={$booking.$symptoms.common} />
          <CategorizedSymptoms
            items={$booking.$symptoms.categorized}
            selectedCategory={selectedCategory}
            onSelectCategory={setSelectedCategory}
          />
        </>
      )}
    </BookingLayout>
  )
}

export default observer(BookingSelectSymptoms)
