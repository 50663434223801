import React from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import i18n from 'i18next'

import { $main } from '@store'

const DocumentsTableDetails = ({ event, resource, extractor, hasIcons = [] }) => {
  const { t } = useTranslation()
  const { asString } = extractor

  let label = ''

  if (_.isString(extractor.type)) {
    label = extractor.type
  } else if (i18n.exists(event.label)) {
    label = t(event.label)
  }

  return (
    <>
      {hasIcons.length > 0 && (
        <div className="icons_bar">
          {hasIcons.includes('document')} &&
          <span className="icons_bar-item icons_bar-item--document" />
        </div>
      )}
      {$main.isMobile && <span className="h_table-td--details-label">{label}</span>}
      <span className="h_table-td--details-content">{asString}</span>
    </>
  )
}

export default observer(DocumentsTableDetails)
