import React from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import Modal from '@library/modal/Modal'

import styles from './SelectChannel.module.scss'

const SelectChannel = ({ setView, _core }) => {
  const { t } = useTranslation()

  return (
    <>
      <div className="modal-toolbar">
        <div
          className="modal-toolbar-btn modal-toolbar-btn--back"
          onClick={() => setView('list')}
        />
        <div className="modal-toolbar-btn modal-toolbar-btn--close" onClick={_core.onHide} />
      </div>
      <Modal.Header className="text-lg-center">
        {t('profile_contacts.title.select_channel')}
      </Modal.Header>
      <Modal.Body>
        <div className="btn btn-arrow" onClick={() => setView('phone')}>
          {t('profile_contacts.btn.add_phone')}
        </div>
        <div className="btn btn-arrow mb-0" onClick={() => setView('email')}>
          {t('profile_contacts.btn.add_email')}
        </div>
      </Modal.Body>
    </>
  )
}

export default observer(SelectChannel)
