import { COUNTRIES } from '@config/countries'

export const PHONE_COUNTRIES = _preparedPhoneList()

const countriesCache = new Map()

export function getConfigByCountry(iso2 = 'us') {
  if (countriesCache.has(iso2)) {
    return countriesCache.get(iso2)
  }
  let result = _.find(COUNTRIES, (x) => x.iso2 === _.toLower(iso2))

  if (!result) {
    result = _.find(COUNTRIES, (x) => x.iso2 === 'us')
  }
  countriesCache.set(iso2, result)
  return result
}

export function getFilteredCountries(props = {}) {
  const { list = COUNTRIES, only = [], preferred = [], excluded = [], usedField = 'iso2' } = props

  let result = list

  if (only.length) {
    result = list.filter((x) => only.includes(x[usedField]))
  }

  if (preferred.length) {
    let tmp = []
    let toPreferred = []
    let toOther = []

    _.forEach(result, (item) => {
      if (preferred.includes(item[usedField])) {
        tmp.push({ ...item, preferred: true })
      } else {
        toOther.push({ ...item, preferred: false })
      }
    })

    _.forEach(preferred, (preferredItem) => {
      const found = _.find(tmp, (x) => x[usedField] === preferredItem)

      if (found) {
        toPreferred.push(found)
      }
    })

    result = [...toPreferred, ...toOther]
  }

  if (excluded.length) {
    result = result.filter((x) => !excluded.includes(x[usedField]))
  }

  return result
}

function _preparedPhoneList() {
  let result = []

  _.forEach(COUNTRIES, (country) => {
    result.push({
      label: country.label,
      iso2: country.iso2,
      dialCode: country.phone.dialCode,
      mask: country.phone.mask || '',
      areaCodes: country.phone.areaCodes || [],
    })
  })

  return result
}
