import React from 'react'

import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import BubbleLoader from '@library/ui/bubbleLoader/BubbleLoader'

import { formatDurationSeconds } from '@helpers/time'

import styles from './CodeResenderButton.module.scss'

const CodeResenderButton = (props) => {
  const { t } = useTranslation()

  const {
    type,
    mainClassName = 'btn-secondary',
    className = '',
    time,
    timer,
    loading,
    onSubmit,
    using = 'text', // using or voice
  } = props

  if (_.isEmpty(timer)) {
    return <></>
  }

  const isLoading = loading === using

  if (type === 'button') {
    return (
      <div
        className={classNames(
          'btn',
          mainClassName,
          className,
          (timer.isRunning || isLoading) && 'disabled',
        )}
        onClick={() => onSubmit(using)}
      >
        {isLoading ? (
          <BubbleLoader innerClassNames={styles.bubbleLoader} />
        ) : (
          <>
            {t('code_resender.btn.default')} {timer.isRunning && formatDurationSeconds(time) + 's'}
          </>
        )}
      </div>
    )
  }

  return (
    <div
      className={classNames(
        styles.resenderBtn,
        (timer.isRunning || isLoading) && styles.resenderBtnDisabled,
      )}
      onClick={() => onSubmit(using)}
    >
      {isLoading ? (
        <BubbleLoader innerClassNames={styles.bubbleLoader} />
      ) : (
        <>
          {t('code_resender.btn.default')} {timer.isRunning && formatDurationSeconds(time) + 's'}
        </>
      )}
    </div>
  )
}

export default CodeResenderButton
