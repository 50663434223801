import React from 'react'

import classNames from 'classnames'

import styles from './Types.module.scss'

const Unknown = () => {
  return <div className={classNames(styles.unknown, styles.isDefault)} />
}

export default Unknown
