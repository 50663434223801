var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import styles from './ShowMoreText.module.scss';
var ShowMoreText = function (_a) {
    var children = _a.children, _b = _a.lines, lines = _b === void 0 ? 5 : _b, // max 20, see styles
    _c = _a.expanded, // max 20, see styles
    _expanded = _c === void 0 ? false : _c, _d = _a.toggleByAll, toggleByAll = _d === void 0 ? false : _d, _e = _a.className, className = _e === void 0 ? '' : _e, _f = _a.buttonMore, buttonMore = _f === void 0 ? '' : _f, _g = _a.buttonLess, buttonLess = _g === void 0 ? '' : _g, _h = _a.buttonClassName, buttonClassName = _h === void 0 ? '' : _h, _j = _a.showLessButton, showLessButton = _j === void 0 ? true : _j;
    var t = useTranslation().t;
    var ref = useRef(null);
    var _k = useState(_expanded), expanded = _k[0], setExpanded = _k[1];
    var _l = useState(false), canClamped = _l[0], setCanClamped = _l[1];
    useLayoutEffect(function () {
        if (!ref.current)
            return;
        if (ref.current.scrollHeight > ref.current.clientHeight) {
            setCanClamped(true);
        }
    }, [expanded]);
    useEffect(function () {
        setExpanded(_expanded);
    }, [_expanded]);
    var canToggleByAll = toggleByAll && (showLessButton || (!showLessButton && !expanded));
    function handleToggle() {
        setExpanded(!expanded);
    }
    function clickOnWrapper() {
        if (canToggleByAll) {
            handleToggle();
        }
    }
    function clickOnButton(e) {
        if (!toggleByAll) {
            e.stopPropagation();
            handleToggle();
        }
    }
    return (_jsxs("div", __assign({ className: classNames(className, canClamped && canToggleByAll && styles.cursorPointer), onClick: clickOnWrapper }, { children: [_jsx("div", __assign({ ref: ref, className: classNames(styles.inner, expanded && styles.expanded, !expanded && lines > 0 && styles['lines' + lines]) }, { children: children })), canClamped && !expanded ? (_jsx("span", __assign({ className: classNames(buttonClassName, styles.button, 'a'), onClick: clickOnButton }, { children: buttonMore || t('btn.show_more') }))) : (expanded &&
                canClamped &&
                showLessButton && (_jsx("span", __assign({ className: classNames(buttonClassName, styles.button, 'a'), onClick: clickOnButton }, { children: buttonLess || t('btn.show_less') }))))] })));
};
export default ShowMoreText;
