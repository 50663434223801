import { useEffect, useRef, useState } from 'react'

import { observer } from 'mobx-react'

import moment from 'moment'

import apiService from '@services/api'

import DeprecationModal from '@library/deprecation/Deprecation.modal'

import { $modal } from '@store'

const CHECK_API_INTERVAL = 1800 * 1000 // 1 hour

const ApiDeprecationChecker = () => {
  const lastDeprecationCheck = useRef(null)
  const [updateNeeded, setUpdateNeeded] = useState(false)
  const [notSupported, setNotSupported] = useState(false)

  const checkActualBuildVersion = async () => {
    const currentBuild = {
      version: process.env.REACT_APP_VERSION,
      revision: process.env.REACT_APP_COMMIT_HASH,
    }
    const lastBuild = await apiService.getBuildJson()

    if (!_.isEmpty(lastBuild) && currentBuild.revision !== lastBuild.revision) {
      const currentVersion = parseInt(currentBuild.version.split('.').join(''))
      const deprecatedVersion = parseInt(lastBuild.versionDeprecation.split('.').join(''))

      if (currentVersion < deprecatedVersion) {
        setNotSupported(true)
        setUpdateNeeded(true)
      } else {
        setNotSupported(false)
        setUpdateNeeded(true)
      }
    }
  }

  useEffect(() => {
    const check = () => {
      const lastCheck = lastDeprecationCheck.current
      const diff = Math.abs(moment(lastCheck).diff(moment()))

      if ((lastCheck && diff > CHECK_API_INTERVAL) || !lastCheck) {
        checkActualBuildVersion().finally(() => {
          lastDeprecationCheck.current = moment()
          setTimeout(check, CHECK_API_INTERVAL)
        })
      } else {
        setTimeout(check, CHECK_API_INTERVAL - diff)
      }
    }

    check()
  }, [])

  const onCancelUpdate = () => {
    setNotSupported(false)
    setUpdateNeeded(false)
  }

  useEffect(() => {
    if (updateNeeded) {
      $modal.add(DeprecationModal, { canDrop: false, onLeave: onCancelUpdate, notSupported })
    }
  }, [updateNeeded]) // eslint-disable-line react-hooks/exhaustive-deps

  return null
}

export default observer(ApiDeprecationChecker)
