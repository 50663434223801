import React from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import classNames from 'classnames'

import TabsBar from '@library/widgets/tabsBar/TabsBar'

import { customGoTo, getParamsByPathname } from '@helpers/router'
import { getUserText } from '@helpers/user'

import { $main } from '@store'

import { routeActions } from '@config/routeActions'

const PatientsList = ({ appointment, patients }) => {
  const { t } = useTranslation()
  const location = useLocation()

  const { encounterId } = getParamsByPathname('apptEncounter', location.pathname)
  const activePatientId = _.find(patients, (x) => x.activeEncounter === +encounterId)?.id

  const preparedList = _.map(patients, (x) => ({
    id: x.id,
    text: `${getUserText(x, ['fullName', 'genderShort'])} ${getUserText(x, ['yo'])}`,
  }))

  function getApptLink(patientId) {
    return routeActions.APPT({
      appointmentId: appointment.id,
      encounterId: patients[patientId].activeEncounter,
    })
  }

  return (
    <div className={classNames($main.isMobileSize ? 'mb-20' : 'mb-10')}>
      <TabsBar
        mode={['secondary-dark', 'block']}
        modeSelected={['black']}
        items={preparedList}
        selected={activePatientId}
        autoTarget={getApptLink}
        moreButtonText={'label.all_patients'}
        moreModalParams={{
          title: t('label.patients'),
          items: preparedList,
          searchBar: true,
          searchMap: ['text'],
          allowUnselect: false,
          searchLabel: t('label.search_patient'),
          value: [activePatientId],
          onChange: (valuesList) => {
            const value = valuesList[0]
            customGoTo(getApptLink(value))
          },
        }}
      />
    </div>
  )
}

export default observer(PatientsList)
