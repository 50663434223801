import React, { useEffect, useMemo, useRef, useState } from 'react'

import { observer } from 'mobx-react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import classNames from 'classnames'
import queryString from 'query-string'

import { analytics } from '@common/analytics/zipAnalytics'

import AwsCaptcha from '@library/awsCaptcha/AwsCaptcha'
import CodeResender from '@library/codes/CodeResender'
import CodeResenderButton from '@library/codes/CodeResenderButton'
import CodeResenderCallMe from '@library/codes/CodeResenderCallMe'
import { usePhone } from '@library/CustomHooks'
import InputBase from '@library/form/fields/new/InputBase'
import { useForm } from '@library/form/FormControllerNew'
import Modal from '@library/modal/Modal'
import PhoneNumber from '@library/phoneNumber/PhoneNumber'
import { TOS_GENERAL, TOS_PROVIDER } from '@library/plugins/termsOfService/Tos.config'
import SelectModal from '@library/select/Select.modal'
import Button from '@library/ui/button/Button'

import { changeCurrentLanguage } from '@helpers/i18n'
import { customGoTo, toPath } from '@helpers/router'

import { $alerts, $auth, $loader, $modal, $user } from '@store'

import { LANGUAGES } from '@config/countries'
import mainConfig from '@config/main'

import styles from './LoginPhone.modal.module.scss'

const LoginPhoneModal = ({ type = 'phone', _core }) => {
  const { t, i18n } = useTranslation()

  const location = useLocation()

  const { referrer } = queryString.parse(location.search)
  const successUrl = referrer ? { to: toPath(referrer) } : null

  let isInit = useRef(true)
  const [initialPrefetch, setInitialPrefetch] = useState()

  const isPhone = type === 'phone'

  const formPhone = useForm({
    account: {
      value: usePhone(''),
      rules: [{ name: 'phone', required: true }],
      output: ['getPhone', 'emptyUndefined'],
    },
    code: { value: '', rules: ['code'] },
  })

  const formEmail = useForm({
    account: { value: '', rules: ['email', 'required'], output: 'emptyUndefined' },
    code: { value: '', rules: ['code'] },
  })

  const { form } = isPhone ? formPhone : formEmail
  const { account, code } = form.fields

  const [codeResenderData, setCodeResenderData] = useState({})
  const isSent = useRef(false)
  const [tmpValue, setTmpValue] = useState(false)
  const isWaiting = isSent.current && _.isEqual(account.value, tmpValue)

  async function handlePrefetchCode(using) {
    setTmpValue(account.value)
    code.onChange('')

    const params = { type, using, value: form.values.account }
    const res = await $auth.sendCode(params, { setIsBound: true })

    return _.get(res, 'prepared.codeSent')
  }

  function handleChangeType(type) {
    _core.onHide()
    $modal.add(LoginPhoneModal, { type, uniq: false })
  }

  function clear() {
    account.onChange('')
    code.onChange('')
    setTmpValue(false)
    isSent.current = false
  }

  const submitSendCodeHandle = $loader.registerHandler('auth-phone-submit', async (e) => {
    e.preventDefault()

    if (form.validate('account').isValid) {
      _core.onBlock()

      isSent.current = false
      const codeSent = await handlePrefetchCode()

      if (codeSent) {
        isSent.current = true
        setInitialPrefetch(codeSent)
        isInit.current = false
      } else {
        setInitialPrefetch(null)
        if (!$auth.needCaptcha) {
          form.forceError('account')
        }
      }
      _core.onUnblock()
    }
  })

  const submitDoneHandle = $loader.registerHandler('auth-phone-submit', async (e) => {
    e.preventDefault()
    if (!form.validateAll().isValid) return

    _core.onBlock()

    const values = form.values
    const response = await $auth.loginByCode({ type, value: values.account, code: values.code })

    if (response.error) {
      if (response.error.code === 'account_role') {
        $alerts.add(t(`login.alert.account_role.body`, { role: mainConfig.appType }), {
          title: t(`login.alert.account_role.title`),
        })
        analytics.error('account_role')
        clear()
      } else {
        form.forceError('code')
      }
    } else if (response.prepared) {
      handleComplete()
    }

    _core.onUnblock()
  })

  function handleComplete() {
    if (successUrl) {
      customGoTo(successUrl)
    }
    _core.onUnblock()
    _core.onHide()
  }

  useEffect(() => {
    if ($user.user) {
      handleComplete()
    }
  }, [$user.user])

  const availableLangs = useMemo(() => LANGUAGES.map((x) => ({ id: x.locale, text: x.name })), [])
  const onChangeLang = (locale) => {
    if (i18n.language === locale) return
    changeCurrentLanguage(locale)
  }

  return (
    <Modal className={styles.authForm} centered>
      <div className="modal-toolbar">
        <div className="modal-toolbar-btn modal-toolbar-btn--close" onClick={_core.onHide} />
      </div>
      <Modal.Header>{t('login.title', { name: mainConfig.appName })}</Modal.Header>
      <Modal.Body>
        <form
          id="auth_form"
          onSubmit={isWaiting ? submitDoneHandle : submitSendCodeHandle}
          noValidate
        >
          {isPhone ? (
            <InputBase key="inputPhone" field={account} mode="lg">
              <PhoneNumber phone={account.value} onChange={account.onChange} clear />
            </InputBase>
          ) : (
            <InputBase
              key="inputEmail"
              field={account}
              type="email"
              mode="lg"
              placeholder={t('login.ph.email')}
              clear
            />
          )}
          <AwsCaptcha
            show={$auth.needCaptcha}
            apiKey={mainConfig.awsCaptcha.key}
            domainList={mainConfig.awsCaptcha.domains}
          />
          {isWaiting && (
            <>
              <div className={classNames(styles.authFormInfoText, 'mt-10')}>
                <Trans i18nKey="login.message.sent" components={[<br />]} />
              </div>
              <CodeResender
                key={type}
                initialPrefetch={initialPrefetch}
                prefetch={handlePrefetchCode}
                code={code}
                onChange={code.onChange}
                onSetData={setCodeResenderData}
                label={false}
                placeholder={t('login.ph.code')}
                textButton={false}
                autoFocus={isInit.current}
              />
            </>
          )}
          <div className="mt-10">
            <Button
              tag="button"
              mode={['primary', 'block']}
              form="auth_form"
              isLoading={$loader.isRunHandler('auth-phone-submit')}
            >
              {isWaiting ? t('btn.continue') : t(`login.btn.${type}`)}
            </Button>
          </div>
          {isWaiting && (
            <div className="mt-10">
              <CodeResenderButton type="button" className="btn-block" {...codeResenderData} />
            </div>
          )}
          <div className={classNames(styles.authFormChange, 'mt-20')}>
            {isPhone && isWaiting ? (
              <>
                <CodeResenderCallMe {...codeResenderData} />
              </>
            ) : (
              <>
                {t('login.other.text')}
                {isPhone ? (
                  <span className="a" onClick={() => handleChangeType('email')}>
                    {t(`login.other.email`)}
                  </span>
                ) : (
                  <span className="a" onClick={() => handleChangeType('phone')}>
                    {t(`login.other.phone`)}
                  </span>
                )}
              </>
            )}
          </div>
          {availableLangs.length > 1 && (
            <div className={classNames(styles.authFormChange, 'mt-10')}>
              <span
                className="a text-meta"
                onClick={() =>
                  $modal.add(SelectModal, {
                    title: t('profile.label.language'),
                    items: availableLangs,
                    value: i18n.language,
                    allowUnselect: false,
                    onChange: (v) => onChangeLang(v[0]),
                  })
                }
              >
                {t('login.label.change_language')}
              </span>
            </div>
          )}
        </form>
      </Modal.Body>
      <Modal.Footer>
        {mainConfig.custom.tosProviderEnabled ? (
          <Trans
            i18nKey="login.agreement_provider"
            values={{ name: mainConfig.appName }}
            components={[
              <Link to={`?${TOS_GENERAL}`} className="a" />,
              <Link to={`?${TOS_GENERAL}=pp`} className="a" />,
              <Link to={`?${TOS_PROVIDER}`} className="a" />,
            ]}
          />
        ) : (
          <Trans
            i18nKey="login.agreement"
            values={{ name: mainConfig.appName }}
            components={[
              <Link to={`?${TOS_GENERAL}`} className="a" />,
              <Link to={`?${TOS_GENERAL}=pp`} className="a" />,
            ]}
          />
        )}
      </Modal.Footer>
    </Modal>
  )
}

export default observer(LoginPhoneModal)
