var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { useForceUpdate, useUpdateCallback } from '@library/CustomHooks';
import DayListPicker from '@library/newDatePicker/dayListPicker/DayListPicker';
import DayPicker from '@library/newDatePicker/dayPicker/DayPicker';
import { getMinMaxDate, getParsedDate, getSwitchDates, } from '@library/newDatePicker/NewDatePicker.helpers';
import { formatDate } from '@helpers/time';
import { $user } from '@store';
var NewDatePicker = forwardRef(function (_a, ref) {
    var _b;
    var _c = _a.type, type = _c === void 0 ? 'selectDay' : _c, title = _a.title, selectedDate = _a.selectedDate, onChange = _a.onChange, _d = _a.timezone, timezone = _d === void 0 ? $user.timezone : _d, minDate = _a.minDate, maxDate = _a.maxDate, onHide = _a.onHide, _e = _a.confirmDate, confirmDate = _e === void 0 ? false : _e, availableDates = _a.availableDates, disabledDates = _a.disabledDates, _f = _a.dayList, dayList = _f === void 0 ? false : _f, _g = _a.scrollTo, scrollTo = _g === void 0 ? 'current' : _g, hasPrevLoadTrigger = _a.hasPrevLoadTrigger, hasNextLoadTrigger = _a.hasNextLoadTrigger;
    var t = useTranslation().t;
    var currentDate = useMemo(function () {
        return getParsedDate(null, type, timezone);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps
    var dayPickerRef = useRef();
    var forceUpdate = useForceUpdate();
    // Start screen
    var _h = useState(function () { return (dayList ? 'dayList' : 'day'); }), view = _h[0], setView = _h[1];
    var modelMemo = useMemo(function () {
        if (!selectedDate) {
            return;
        }
        return getParsedDate(selectedDate, type, timezone);
    }, [selectedDate]); // eslint-disable-line react-hooks/exhaustive-deps
    var _j = useState(modelMemo), model = _j[0], setModel = _j[1];
    // Re-initialize if input selectedDate (modelMemo) is changed
    useEffect(function () {
        if (!modelMemo) {
            return;
        }
        setModel(modelMemo);
    }, [modelMemo]);
    // Get available dates
    var parsedAvailableDates = useMemo(function () {
        if (_.isEmpty(availableDates)) {
            return;
        }
        return getSwitchDates(availableDates, type, timezone);
    }, [availableDates]); // eslint-disable-line react-hooks/exhaustive-deps
    // Get disabled dates
    var parsedDisabledDates = useMemo(function () {
        if (_.isEmpty(disabledDates)) {
            return;
        }
        return getSwitchDates(disabledDates, type, timezone);
    }, [disabledDates]); // eslint-disable-line react-hooks/exhaustive-deps
    // Get min and max dates
    var _k = getMinMaxDate(minDate, maxDate, parsedAvailableDates, currentDate, type, timezone), parsedMinDate = _k[0], parsedMaxDate = _k[1];
    // Update date or datetime into parent component as date string or datetime ISO string
    var handleApplyDate = function () {
        var newDatetime;
        if (type === 'selectDay') {
            newDatetime = model.format('YYYY-MM-DD');
        }
        else if (type === 'selectDateTime') {
            var modelMidnight = model.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
            if (model.format('YYYY-MM-DD') === parsedMinDate.format('YYYY-MM-DD') &&
                modelMidnight.isBefore(parsedMinDate) &&
                scrollTo === 'start') {
                newDatetime = parsedMinDate.toISOString();
            }
            else if (model.format('YYYY-MM-DD') === parsedMaxDate.format('YYYY-MM-DD') &&
                parsedMaxDate.isAfter(modelMidnight) &&
                scrollTo === 'end') {
                newDatetime = parsedMaxDate.toISOString();
            }
            else {
                newDatetime = modelMidnight.toISOString();
            }
        }
        onChange(newDatetime);
        if (onHide) {
            onHide();
        }
    };
    var forceCallback = useUpdateCallback(handleApplyDate);
    var handleSetDate = function (value) {
        setModel(value);
        // Run callback after model is changed
        if (!confirmDate) {
            forceCallback();
        }
    };
    return (_jsxs("div", __assign({ className: "new-date-picker" }, { children: [_jsxs("div", __assign({ className: "control" }, { children: [view === 'day' && _.includes(['month', 'year'], (_b = dayPickerRef.current) === null || _b === void 0 ? void 0 : _b.getView()) && (_jsx("div", { className: "action back", onClick: function () { var _a; return (_a = dayPickerRef.current) === null || _a === void 0 ? void 0 : _a.goToBack(); } })), onHide && _jsx("div", { className: "action close", onClick: onHide })] })), _jsx("div", __assign({ className: "header" }, { children: _jsx("div", __assign({ className: "title title-md text-no-select" }, { children: title || t('new_date_picker.title') })) })), _jsxs("div", __assign({ className: "body" }, { children: [view === 'day' && (_jsx(DayPicker, { currentDate: currentDate, selectedDate: model, setDate: handleSetDate, minDate: parsedMinDate, maxDate: parsedMaxDate, availableDates: parsedAvailableDates, disabledDates: parsedDisabledDates, forceUpdate: forceUpdate, ref: dayPickerRef, scrollTo: scrollTo })), view === 'dayList' && (_jsx(DayListPicker, { currentDate: currentDate, selectedDate: model, setDate: handleSetDate, minDate: parsedMinDate, maxDate: parsedMaxDate, availableDates: parsedAvailableDates, disabledDates: parsedDisabledDates, scrollTo: scrollTo, hasPrevLoadTrigger: hasPrevLoadTrigger, hasNextLoadTrigger: hasNextLoadTrigger, ref: ref }))] })), confirmDate && (_jsxs("div", __assign({ className: "footer" }, { children: [!model && (_jsx("span", __assign({ className: "selected-date locked" }, { children: t('new_date_picker.choose_date') }))), model && (_jsx("span", __assign({ className: "selected-date", onClick: handleApplyDate }, { children: formatDate(model, { format: 'date' }) })))] })))] })));
});
export default observer(NewDatePicker);
