export const CODING_ORGANIZATIONS = _.orderBy(
  [
    {
      id: 'snomed',
      system: 'http://snomed.info/sct',
      label: 'SNOMED',
    },
    {
      id: 'loinc',
      system: 'http://loinc.org',
      label: 'LOINC',
    },
    {
      id: 'cpt',
      system: 'http://ama-assn.org/go/cpt',
      label: 'CPT',
    },
    {
      id: 'cpt',
      system: 'http://www.ama-assn.org/go/cpt',
      label: 'CPT',
    },
    {
      id: 'cvx',
      system: 'http://hl7.org/fhir/sid/cvx',
      label: 'CVX',
    },
    {
      id: 'rxnorm',
      system: 'http://www.nlm.nih.gov/research/umls/rxnorm',
      label: 'RxNorm',
    },
    {
      id: 'icd10',
      system: 'http://hl7.org/fhir/sid/icd-10',
      label: 'ICD-10',
    },
    {
      id: 'icd10cm',
      system: 'http://hl7.org/fhir/sid/icd-10-cm',
      label: 'ICD-10 CM',
    },
    {
      id: 'icd10pcs',
      system: 'http://hl7.org/fhir/sid/ex-icd-10-procedures',
      label: 'ICD-10 PCS',
    },
    {
      id: 'hcpcs',
      system: 'https://www.cms.gov/Medicare/Coding/HCPCSReleaseCodeSets',
      label: 'HCPCS',
    },
    {
      id: 'ndc',
      system: 'http://hl7.org/fhir/sid/ndc',
      label: 'NDC',
    },
    {
      id: 'HL7',
      system: 'https://hl7.org',
      label: 'HL7',
    },
    {
      id: 'tHL7',
      system: 'https://terminology.hl7.org',
      label: 'HL7',
    },
    {
      id: 'fhirZiphy',
      system: 'https://fhir.ziphy.com',
      label: 'ZIPHY',
    },
    {
      id: 'fhirLabq',
      system: 'https://fhir.labq.com',
      label: 'LABQ',
    },
    {
      id: 'unknown',
      system: '',
      label: '',
    },
  ],
  (x) => x.system.length,
  'desc',
)

export const CODING_SYSTEMS = {
  snomed: 'http://snomed.info/sct',
  loinc: 'http://loinc.org',
  cpt: 'http://www.ama-assn.org/go/cpt',
  cvx: 'http://hl7.org/fhir/sid/cvx',
  rxnorm: 'http://www.nlm.nih.gov/research/umls/rxnorm',
  icd10: 'http://hl7.org/fhir/sid/icd-10',
  icd10cm: 'http://hl7.org/fhir/sid/icd-10-cm',
  icd10pcs: 'http://hl7.org/fhir/sid/ex-icd-10-procedures',
  ndc: 'http://hl7.org/fhir/sid/ndc',
  hl7: 'http://hl7.org',
  thl7: 'http://terminology.hl7.org',
  ziphy: 'https://fhir.ziphy.com',
  hl7AllergyIntoleranceClinical:
    'https://terminology.hl7.org/CodeSystem/allergyintolerance-clinical',
  medicationRequestDefaultStatus: 'https://terminology.hl7.org/CodeSystem/medicationrequest-status',
  medicationRequestStatusReason:
    'https://terminology.hl7.org/CodeSystem/medicationrequest-status-reason',
  hl7DataAbsentReason: 'http://terminology.hl7.org/CodeSystem/data-absent-reason',
  hl7SidUsNpi: 'http://hl7.org/fhir/sid/us-npi',
  ziphyMedicationRequestEffectivePeriod:
    'https://fhir.ziphy.com/1.0/StructureDefinition/resource-effectivePeriod',
  ziphyIdentifier: 'https://fhir.ziphy.com/1.0/ziphy-identifier',
  ziphyObservationExaminationTemplate:
    'https://fhir.ziphy.com/1.0/StructureDefinition/observation-examinationTemplate',
  labqOrderIdentifier: 'https://fhir.labq.com/1.0/labq-order-identifier',
  labqOrderResultCode: 'https://fhir.labq.com/1.0/labq-order-result-code',
  labqOrderCode: 'https://fhir.labq.com/1.0/labq-order-code',
  labqIdentifier: 'https://fhir.labq.com/1.0/labq-identifier',
  labqFacilityIdentifier: 'https://fhir.labq.com/1.0/labq-facility-identifier',
  labqProcedureStatus: 'https://fhir.labq.com/fhir/StructureDefinition/labq-procedure-status',
  labqProcedureStatusLegacy: 'https://fhir.labq.com/1.0/StructureDefinition/labq-procedure-status', // todo: remove later
  labqAccession: 'https://fhir.labq.com/1.0/labq-accession',
  labqCollectLocation: 'https://fhir.labq.com/fhir/StructureDefinition/collect-location',
  labqOrderingFacility: 'https://fhir.labq.com/fhir/StructureDefinition/ordering-facility',
  labqOrderingPhysician: 'https://fhir.labq.com/fhir/StructureDefinition/ordering-physician',
  labqPatientPassport: 'https://fhir.labq.com/1.0/patient-passport',
  labqPatientChartNumber: 'https://fhir.labq.com/fhir/StructureDefinition/patient-chart-number',
  labqOrderRoomNumber: 'https://fhir.labq.com/fhir/StructureDefinition/order-room-number',
  labqOrderDate: 'https://fhir.labq.com/fhir/StructureDefinition/order-date',
  labqLabEntryDate: 'https://fhir.labq.com/fhir/StructureDefinition/lab-entry-date',
  labqPreviousResultDate: 'https://fhir.labq.com/fhir/StructureDefinition/previous-result-date',
  labqRouteNumber: 'https://fhir.labq.com/some-route-number',
  labqMedicalDirector: 'https://fhir.labq.com/fhir/StructureDefinition/medical-director',
  labqClia: 'https://fhir.labq.com/1.0/clia',
  labqSpecimenType: 'https://fhir.labq.com/fhir/StructureDefinition/specimen-type',
  labqFinalReportDate: 'https://fhir.labq.com/fhir/StructureDefinition/final-report-date',
  labqCancelledByPatient: 'https://fhir.labq.com/fhir/StructureDefinition/cancelled-by-patient',
}

export const CODING_SYSTEMS_ORDERED = _.orderBy(
  _.entries(CODING_SYSTEMS),
  (x) => x[1].length,
  'desc',
)

// todo: Fhir 5.0
export const CANONICAL_STATUSES = {
  received: 'received',
  complete: 'complete',
  abandoned: 'abandoned',
  error: 'error', // unused
  unknown: 'unknown', // unused
}

// https://www.hl7.org/fhir/valueset-diagnostic-report-status.html#expansion
export const LAB_CANONICAL_STATUSES = {
  registered: CANONICAL_STATUSES.received,
  partial: CANONICAL_STATUSES.received, // unused
  preliminary: CANONICAL_STATUSES.received,
  final: CANONICAL_STATUSES.complete,
  amended: CANONICAL_STATUSES.complete, // unused
  corrected: CANONICAL_STATUSES.complete,
  appended: CANONICAL_STATUSES.complete, // unused
  cancelled: CANONICAL_STATUSES.abandoned,
  entered_in_error: CANONICAL_STATUSES.error, // unused
  unknown: CANONICAL_STATUSES.unknown, // unused
}

// https://www.hl7.org/fhir/valueset-observation-interpretation.html
// value true - mean 'isOk' and 'inReferenceRange'
export const LAB_OBSERVATION_INTERPRETATIONS = {
  'http://terminology.hl7.org/CodeSystem/v3-ObservationInterpretation': {
    // ObservationInterpretationNormality
    A: {
      inRange: false,
      isCritical: false,
    },
    AA: {
      inRange: false,
      isCritical: true,
    },
    HH: {
      inRange: false,
      isCritical: true,
    },
    LL: {
      inRange: false,
      isCritical: true,
    },
    H: {
      inRange: false,
      isCritical: false,
    },
    HU: {
      inRange: false,
      isCritical: false,
    },
    L: {
      inRange: false,
      isCritical: false,
    },
    LU: {
      inRange: false,
      isCritical: false,
    },
    N: {
      inRange: true,
      isCritical: false,
    },
  },
}

export const LAB_RAPID_CODES = ['0001']
