import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';
import styles from './BubbleLoader.module.scss';
var BubbleLoader = function (_a) {
    var className = _a.className, mode = _a.mode, innerClassNames = _a.innerClassNames;
    var preparedType = _.isArray(mode) ? mode : [mode];
    return (_jsx("div", { className: classNames('bubble-loader', innerClassNames, styles.bubbleLoader, preparedType.length > 0 && preparedType.map(function (x) { return x; }).join(' '), className) }));
};
export default BubbleLoader;
