import React from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import styles from './QuickLinks.module.scss'

const QuickLinks = () => {
  const { t } = useTranslation()

  return (
    <div className={classNames('box', styles.quickLinks)}>
      <div className="box-header">
        <div className="box-header-title title-sm mb-0">{t('quick_links.title')}</div>
      </div>
      <div className="box-body">
        <div className="row row--mb-10">
          <div className="col-md-6 col-xl-4">
            <div className={styles.quickLinksItem}>
              <a href="https://ziphycare.com/welcome-to-myziphy/" target="_blank" rel="noreferrer">
                {t('quick_links.welcome')}
              </a>
            </div>
          </div>
          <div className="col-md-6 col-xl-4">
            <div className={styles.quickLinksItem}>
              <a href="https://ziphycare.com/about-ziphycare/" target="_blank" rel="noreferrer">
                {t('quick_links.about')}
              </a>
            </div>
          </div>
          <div className="col-md-6 col-xl-4">
            <div className={styles.quickLinksItem}>
              <a href="https://ziphycare.com/downloads/" target="_blank" rel="noreferrer">
                {t('quick_links.download')}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default observer(QuickLinks)
