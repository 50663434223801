import React from 'react'

import { useTranslation } from 'react-i18next'

import { formatDate } from '@helpers/time'

import RoleName from '@ps/library/RoleName'
import ViewCode from '@ps/library/viewCode/ViewCode'

const Medication = ({ event, resource, extractor }) => {
  const { t } = useTranslation()
  const { asString, status, codeObj, subject = {}, informationSource = {}, dateEntered } = extractor

  return (
    <div className="event_details">
      <div className="event_details-header">
        <div className="event_details-title">
          {asString}
          {event.isSelfReported && (
            <span className="badge-self_reported">{t('ps.label.self_reported')}</span>
          )}
        </div>
        <div className="event_details-code">
          <ViewCode codeObj={codeObj} />
        </div>
      </div>
      <div className="event_details-body">
        {status && (
          <div className="event_details-line">
            {t('ps.event_viewer.status:')} {status || status.display}
          </div>
        )}
        {dateEntered && (
          <div className="event_details-line">
            {t('ps.event_viewer.entered_date:')} {formatDate(dateEntered)}
          </div>
        )}

        <div className="event_details-line mt-10">
          {t('ps.event_viewer.subject:')} {subject.value?.fullName}
        </div>

        {informationSource?.role !== 'patient' && (
          <div className="event_details-line">
            {t('ps.event_viewer.recorder:')} {informationSource.value.fullName}
            {/*<RoleName role={informationSource.role} resource={informationSource.value} />*/}
          </div>
        )}
        <div className="event_details-line event_details-line--date">
          {formatDate(event.effectiveDate)}
        </div>
      </div>
    </div>
  )
}

export default Medication
