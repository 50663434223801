import React from 'react'

const LocationMarker = ({ color = '#ffffff' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
      <g fill="none" fillRule="evenodd">
        <g fill={color}>
          <g>
            <g>
              <g>
                <path
                  d="M19.657 6.343c3.124 3.124 3.124 8.19 0 11.314L14 23.314l-5.657-5.657c-3.124-3.124-3.124-8.19 0-11.314 3.124-3.124 8.19-3.124 11.314 0zm-9.9 1.414c-2.343 2.344-2.343 6.142 0 8.486L14 20.485l4.243-4.242c2.343-2.344 2.343-6.142 0-8.486-2.344-2.343-6.142-2.343-8.486 0zM14 8c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4zm0 2c-1.105 0-2 .895-2 2s.895 2 2 2 2-.895 2-2-.895-2-2-2z"
                  transform="translate(-540 -210) translate(310 109) translate(215 90) translate(15 11)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default LocationMarker
