import React from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import DtlGroup from '@library/ui/dtl/DtlGroup'
import DtlItem from '@library/ui/dtl/DtlItem'
import ShowMoreDetails from '@library/ui/showMoreDetails/ShowMoreDetails'

import { getBodyMassIndex, splitHeightFromInches } from '@helpers/converters'
import { getUserText } from '@helpers/user'

import {
  getAddress,
  getInsurances,
  getLifestyle,
  getTelecom,
} from '@ps/pages/patient/PatientDetails.helpers'

const PatientDetails = ({ patient, cocPatient }) => {
  const { t } = useTranslation()

  const phones = getTelecom(patient.telecom, 'phone')
  const emails = getTelecom(patient.telecom, 'email')
  const addresses = getAddress(patient.address)
  const lifestyle = getLifestyle(patient.socialHistory)

  return (
    <DtlGroup className="row pb-30 row--mb-20">
      <DtlItem className="col-12 col-lg-3" label={t('label.sex_assigned_at_birth')}>
        {getUserText(cocPatient, ['gender'])}
      </DtlItem>
      <DtlItem className="col-12 col-lg-3" label={t('label.date_of_birth')}>
        {getUserText(cocPatient, ['dob'])} ({getUserText(cocPatient, ['yo'])})
      </DtlItem>
      <DtlItem className="col-12 col-lg-3" label={t('label.marital_status')}>
        {getUserText(patient, ['maritalStatus'])}
      </DtlItem>
      <DtlItem className="col-12 col-lg-3" label={t('label.patient_id')}>
        {patient.zid}
      </DtlItem>
      <DtlItem className="col-12 col-lg-3" label={t('label.race')}>
        {getUserText(patient, ['race'])}
      </DtlItem>
      <DtlItem className="col-12 col-lg-3" label={t('label.ethnic_origin')}>
        {getUserText(patient, ['ethnicity'])}
      </DtlItem>
      <DtlItem className="col-12 col-lg-3" label={t('label.gender_identity')}>
        {getUserText(patient, ['genderIdentity'])}
      </DtlItem>
      <DtlItem className="col-12 col-lg-3" label={t('label.pronouns')}>
        {getUserText(patient, ['pronoun'])}
      </DtlItem>
      <DtlItem className="col-12 col-lg-3" label={t('label.height_ft&in')}>
        {splitHeightFromInches(patient.vitals.height?.valueQuantity?.value, true)}
      </DtlItem>
      <DtlItem className="col-12 col-lg-3" label={t('label.weight_lbs')}>
        {patient.vitals.weight?.valueQuantity?.value}
      </DtlItem>
      <DtlItem className="col-12 col-lg-3" label={t('label.bmi')}>
        {patient.vitals.weight?.valueQuantity?.value &&
          patient.vitals.height?.valueQuantity?.value &&
          getBodyMassIndex(
            patient.vitals.weight?.valueQuantity?.value,
            patient.vitals.height?.valueQuantity?.value,
          )}
      </DtlItem>
      <DtlItem className="col-12 col-lg-3" label={t('label.medical_insurance')}>
        {patient.insurances.length > 0 && (
          <ShowMoreDetails items={getInsurances(patient.insurances)} />
        )}
      </DtlItem>
      <DtlItem className="col-12 col-lg-3" label={t('label.alcohol')}>
        {patient.socialHistory.alcoholConsumptionSocialHistory?.text}
      </DtlItem>
      <DtlItem className="col-12 col-lg-3" label={t('label.smoking')}>
        {patient.socialHistory.tobaccoUserSocialHistory?.text}
      </DtlItem>
      <DtlItem className="col-12 col-lg-3" label={t('label.substance_use')}>
        {patient.socialHistory.substanceUseSocialHistory?.text}
      </DtlItem>
      <DtlItem className="col-12 col-lg-3" label={t('label.lifestyle')}>
        {lifestyle.length > 0 && <ShowMoreDetails items={lifestyle} />}
      </DtlItem>
      <DtlItem className="col-12 col-lg-3" label={t('label.phone')}>
        {phones.length > 0 && <ShowMoreDetails items={phones} />}
      </DtlItem>
      <DtlItem className="col-12 col-lg-3" label={t('label.email')}>
        {emails.length > 0 && <ShowMoreDetails items={emails} />}
      </DtlItem>
      <DtlItem className="col-12 col-lg-3" label={t('label.address')}>
        {addresses.length > 0 && <ShowMoreDetails items={addresses} />}
      </DtlItem>
    </DtlGroup>
  )
}

export default observer(PatientDetails)
