import React, { useEffect } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import InsuranceOrVoucherModal from '@booking/pages/bookingFinish/insuranceVoucherSelectModal/InsuranceVoucherSelect.modal'
import classNames from 'classnames'

import { getEnumsValue } from '@helpers/enums'
import { formatAmount } from '@helpers/text'
import { getUserText } from '@helpers/user'

import { $booking, $main, $modal } from '@store'

import { ENUMS_INSURANCE_TYPES } from '@config/enums/insurance'
import mainConfig from '@config/main'

import closeSvg from '@assets/images/ui/close-white.svg'

import styles from './PatientItem.module.scss'

const PatientItem = ({ patient, number }) => {
  const { t } = useTranslation()

  useEffect(() => {
    if (patient.voucher && patient.voucher.practiceId !== $booking.practiceId) {
      $booking.$patients.updateSelected(patient.id, { voucher: null })
    }
  }, [$booking.practiceId])

  const openModal = () => {
    $modal.add(InsuranceOrVoucherModal, {
      patient: patient,
    })
  }

  const clearInsuranceAndVoucher = () => {
    $booking.$patients.updateSelected(patient.id, {
      useInsurance: false,
      voucher: null,
    })
  }

  const isUsedInsuranceOrVoucher = patient.useInsurance || patient.voucher
  const formattedServicePrice = formatAmount(
    $booking.practiceService.price,
    $booking.practiceService.currency,
  )

  const UserText = () => (
    <div className={styles.column}>
      <div className={classNames(styles.row, styles.rowLabel)}>
        {t('book_details.label.patient_number', { number: number })}
      </div>
      <div className={styles.row}>{getUserText(patient, ['fullName', 'gender', 'yo'])}</div>
    </div>
  )

  const InsuranceVoucherButton = () =>
    mainConfig.booking.isSelectablePatientInsuranceOrVoucher &&
    $booking.practiceService.price > 0 ? (
      <div className={styles.column}>
        <button
          className={classNames(styles.button, isUsedInsuranceOrVoucher && styles.buttonSelected)}
          onClick={isUsedInsuranceOrVoucher ? undefined : openModal}
        >
          <div className={styles.insuranceVoucherValue}>
            {patient.useInsurance
              ? getEnumsValue(patient.insuranceType, ENUMS_INSURANCE_TYPES)
              : patient.voucher
              ? patient.voucher.code
              : t('book_details.label.add_insurance_or_voucher')}
          </div>
          {patient.useInsurance && (
            <div className={styles.insuranceCode}>
              &nbsp;• {patient.insuranceMemberId.replace('|strict', '')}
            </div>
          )}
          {isUsedInsuranceOrVoucher && (
            <img src={closeSvg} onClick={clearInsuranceAndVoucher} alt="" />
          )}
        </button>
      </div>
    ) : null

  const Price = () =>
    $booking.practiceService.price > 0 && (
      <div className={classNames(styles.column, styles.columnAlignEnd)}>
        <div
          className={classNames(
            styles.row,
            styles.rowLabel,
            isUsedInsuranceOrVoucher && styles.rowLabelStrikethrough,
          )}
        >
          {isUsedInsuranceOrVoucher ? formattedServicePrice : t('appt.label.price')}
        </div>
        <div className={classNames(styles.row, styles.noWrap)}>
          {isUsedInsuranceOrVoucher
            ? formatAmount(
                $booking.payments.patients[patient.id],
                $booking.practiceService.currency,
              )
            : formattedServicePrice}
        </div>
      </div>
    )

  return $main.isMobileSize ? (
    <div className={styles.containerMobile}>
      <div className={styles.containerMobileHeader}>
        <UserText />
        <Price />
      </div>
      <InsuranceVoucherButton />
    </div>
  ) : (
    <div className={styles.containerDesktop}>
      <UserText />
      <InsuranceVoucherButton />
      <Price />
    </div>
  )
}

export default observer(PatientItem)
