var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import classNames from 'classnames';
import './Badge.scss';
var Badge = function (_a) {
    var type = _a.type, onClose = _a.onClose, callback = _a.callback, clickable = _a.clickable, children = _a.children, className = _a.className;
    var clickHandler = function () {
        if (clickable && callback) {
            return callback();
        }
    };
    function getTypes(type) {
        var array = _.isArray(type) ? type : [type];
        return array.map(function (x) { return 'badge--' + x; }).join(' ');
    }
    var closeHandler = function (e) {
        e.stopPropagation();
        onClose();
    };
    return (_jsxs("div", __assign({ className: classNames('badge', type && getTypes(type), clickable && 'badge--clickable', className), onClick: clickHandler }, { children: [children, onClose && (_jsx("div", { className: "badge-close", onClick: function (e) { return closeHandler(e); } }))] })));
};
export default Badge;
