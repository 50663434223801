import React from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import Button from '@library/ui/button/Button'

import { $appts, $loader } from '@store'

const ApptContacts = ({ byRoute }) => {
  const { t } = useTranslation()

  const { fullAppt, status, actions } = byRoute || $appts.byRoute
  const { patient = {}, agent = {}, provider = {} } = fullAppt

  const chatGeneral = actions.textGeneral || actions.viewTextGeneral
  const chatStaff = actions.textStaff || actions.viewTextStaff
  const callOffice = actions.callOffice
  const callEmergency = actions.callEmergency

  const rules = {
    hasGeneral: Boolean(chatGeneral),
    hasStaff: Boolean(chatStaff),
    hasCallOffice: Boolean(callOffice),
    hasCallEmergency: Boolean(callEmergency),
  }

  const allItems = Object.values(rules).filter((x) => x !== null).length
  const visibleItems = Object.values(rules).filter((x) => x).length

  let colClass = ['col']
  colClass.push(allItems === 4 ? 'all-4' : 'all-3')
  colClass.push(visibleItems === 4 ? 'visible-4' : 'visible-3')
  colClass.join(' ')

  const handleCallOffice = $loader.registerHandler('appt-contacts-call-office', async () => {
    if (_.isFunction(callOffice?.action)) {
      await callOffice.action()
    }
  })

  return (
    <div className={classNames('appt_contacts', !visibleItems && 'appt_contacts--disabled')}>
      <div className="appt_contacts-title title-sm">{t('appt.title.contacts')}</div>
      <div className="row">
        {rules.hasGeneral !== null && (
          <div className={classNames(colClass, !chatGeneral && 'disabled')}>
            <div className="appt_contacts-item" onClick={() => chatGeneral && chatGeneral.action()}>
              <div className="appt_contacts-icon appt_contacts-icon--text" />
              <div className="appt_contacts-content">
                <div className="appt_contacts-content-title">
                  <span className="full">{t('appt_contacts.general.full')}</span>
                  <span className="short">{t('appt_contacts.general.full')}</span>
                  <span className="extra_short">{t('appt_contacts.general.full')}</span>
                </div>
                {/*<div className="appt_contacts-content-meta">*/}
                {/*  Hi I’m running late. Traffic, Sorry.*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
        )}
        {rules.hasStaff !== null && (
          <div className={classNames(colClass, !chatStaff && 'disabled')}>
            <div className="appt_contacts-item" onClick={() => chatStaff && chatStaff.action()}>
              <div className="appt_contacts-icon appt_contacts-icon--text" />
              <div className="appt_contacts-content">
                <div className="appt_contacts-content-title">
                  <span className="full">
                    {t('appt_contacts.agent.' + (agent.name ? 'full' : 'short'), {
                      name: agent.name,
                    })}
                  </span>
                  <span className="short">{t('appt_contacts.agent.short')}</span>
                  <span className="extra_short">{t('appt_contacts.agent.extra_short')}</span>
                </div>

                {/*<div className="appt_contacts-content-meta">*/}
                {/*  Hi I’m running late. Traffic, Sorry.*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
        )}
        {rules.hasCallOffice !== null && (
          <div className={classNames(colClass, !callOffice && 'disabled')}>
            <Button
              tag="div"
              className="appt_contacts-item"
              action={handleCallOffice}
              isLoading={$loader.isRunHandler('appt-contacts-call-office')}
            >
              <div className="appt_contacts-icon appt_contacts-icon--phone" />
              <div className="appt_contacts-content">
                <div className="appt_contacts-content-title">
                  <span className="full">{t('appt_contacts.call_office.full')}</span>
                  <span className="short">{t('appt_contacts.call_office.short')}</span>
                  <span className="extra_short">{t('appt_contacts.call_office.extra_short')}</span>
                </div>
              </div>
            </Button>
          </div>
        )}
        {rules.hasCallEmergency !== null && (
          <div className={classNames(colClass, !callEmergency && 'disabled')}>
            <div
              className="appt_contacts-item"
              onClick={() => callEmergency && callEmergency.action()}
            >
              <div className="appt_contacts-icon appt_contacts-icon--phone" />
              <div className="appt_contacts-content">
                <div className="appt_contacts-content-title">
                  <span className="full">
                    {t('appt_contacts.call_emergency.full', { number: status.emergencyNumber })}
                  </span>
                  <span className="short">
                    {t('appt_contacts.call_emergency.short', { number: status.emergencyNumber })}
                  </span>
                  <span className="extra_short">
                    {t('appt_contacts.call_emergency.extra_short', {
                      number: status.emergencyNumber,
                    })}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default observer(ApptContacts)
