import React from 'react'

const CameraMarker = ({ color = '#ffffff' }) => {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 12.0001L21.553 9.72412C21.8629 9.56926 22.2309 9.58583 22.5256 9.76792C22.8203 9.95002 22.9998 10.2717 23 10.6181V17.3821C22.9998 17.7286 22.8203 18.0502 22.5256 18.2323C22.2309 18.4144 21.8629 18.431 21.553 18.2761L18 16.0001V12.0001Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 10C5 8.89543 5.89543 8 7 8H16C17.1046 8 18 8.89543 18 10V18C18 19.1046 17.1046 20 16 20H7C5.89543 20 5 19.1046 5 18V10Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CameraMarker
