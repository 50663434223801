var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Fragment } from 'react';
import classNames from 'classnames';
import _ from 'lodash';
export var DayPickerCalendar = function (_a) {
    var currentDate = _a.currentDate, selectedDate = _a.selectedDate, model = _a.model, setDate = _a.setDate, minDate = _a.minDate, maxDate = _a.maxDate, availableDays = _a.availableDays, disabledDays = _a.disabledDays, board = _a.board, _b = _a.dayWeek, dayWeek = _b === void 0 ? true : _b, _c = _a.nameMonth, nameMonth = _c === void 0 ? false : _c, setRefDay = _a.setRefDay;
    var handleSelectDay = function (value) {
        return function () { return setDate(value); };
    };
    var isSelected = function (value) {
        if (!selectedDate) {
            return false;
        }
        return value.isSame(selectedDate, 'day');
    };
    var isOtherMonth = function (value) {
        return !value.isSame(model, 'month');
    };
    var isCurrent = function (value) {
        return value.isSame(currentDate, 'day');
    };
    var isLocked = function (value) {
        if (!_.isEmpty(availableDays)) {
            return !(value.format('YYYY-MM-DD') in availableDays);
        }
        else {
            return (value.isBefore(minDate, 'day') ||
                value.isAfter(maxDate, 'day') ||
                (!_.isEmpty(disabledDays) && value.format('YYYY-MM-DD') in disabledDays));
        }
    };
    var getHeader = function () {
        return board && board.length ? board[0] : [];
    };
    return (_jsxs("div", __assign({ className: "day-picker-calendar" }, { children: [_jsxs("div", __assign({ className: "header" }, { children: [dayWeek && (_jsx("div", __assign({ className: "day-week" }, { children: getHeader().map(function (_a, i) {
                            var value = _a.value;
                            return (_jsx("div", __assign({ className: 'cell' }, { children: value.format('ddd') }), i));
                        }) }))), nameMonth && _jsx("div", __assign({ className: "name-month" }, { children: model.format('MMMM YYYY') }))] })), _jsx("div", __assign({ className: "body" }, { children: board.map(function (cells, rowIndex) { return (_jsx("div", __assign({ className: "row-week" }, { children: cells.map(function (_a, i) {
                        var text = _a.text, value = _a.value;
                        return (_jsxs(Fragment, { children: [!text && _jsx("div", __assign({ className: classNames('cell', 'cell-hidden') }, { children: "\u00A0" })), text && !isLocked(value) && (_jsx("div", __assign({ className: classNames('cell', {
                                        'cell-selected': isSelected(value),
                                        'cell-other-month': isOtherMonth(value),
                                        'cell-current': isCurrent(value),
                                    }), onClick: handleSelectDay(value), ref: setRefDay && setRefDay(value, 'YYYYMMDD') }, { children: text }))), text && isLocked(value) && (_jsx("div", __assign({ className: classNames('cell', {
                                        'cell-selected': isSelected(value),
                                        'cell-other-month': isOtherMonth(value),
                                        'cell-current': isCurrent(value),
                                        'cell-locked': true,
                                    }), ref: setRefDay && setRefDay(value, 'YYYYMMDD') }, { children: text })))] }, value.toString() + i));
                    }) }), rowIndex)); }) }))] })));
};
export default DayPickerCalendar;
