import React from 'react'

import { observer } from 'mobx-react'
import { useParams } from 'react-router-dom'

import classNames from 'classnames'

import CustomLink from '@library/ui/customLink/CustomLink'
import PatientPhotoAndName from '@library/ui/patientPhotoAndName/PatientPhotoAndName'

import { useRouterSearchParams } from '@helpers/router'

import { routeActions } from '@config/routeActions'

import styles from './PatientHeader.module.scss'

const PatientHeader = ({ patient, cocPatient }) => {
  const { id, patientId } = useParams()
  const searchParams = useRouterSearchParams()
  const backToApptUrl = searchParams.get('backToApptUrl')

  const practiceName = patient.practice?.name

  return (
    <>
      <div className={classNames(styles.header, styles.mobileView)}>
        <PatientPhotoAndName
          firstName={cocPatient.firstName}
          lastName={cocPatient.lastName}
          avatar={cocPatient.avatar}
        />
      </div>
      {practiceName && (
        <div className={styles.practiceLabel}>
          <div className={styles.text}>{practiceName}</div>
          {backToApptUrl ? (
            <CustomLink to={backToApptUrl} className={styles.close} />
          ) : (
            <CustomLink autoTarget={routeActions.PATIENT({ patientId })} className={styles.close} />
          )}
        </div>
      )}
    </>
  )
}

export default observer(PatientHeader)
