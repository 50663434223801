import React, { useEffect, useMemo } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import Button from '@library/ui/button/Button'
import CustomLink from '@library/ui/customLink/CustomLink'

import { extendAddress } from '@helpers/addressHelper'

import { $appts, $main } from '@store'

import { routeActions } from '@config/routeActions'

import logoSimpleWhite from '@assets/images/logo-simple-white.svg'

import styles from './NearestAppt.module.scss'

const NearestAppt = () => {
  const { t } = useTranslation()

  const status = $appts.getNearest?.status
  const appointment = $appts.getNearest?.fullAppt?.appointment
  const place = $appts.getNearest?.fullAppt?.place

  const text = useMemo(() => {
    if (_.isEmpty(status)) {
      return false
    }

    const result = [status.texts.nearestStart]

    if ($main.windowSize.minMD) {
      result.push(status.texts.status)
    }
    return result.join(', ')
  }, [status, $main.windowSize.minMD]) // eslint-disable-line react-hooks/exhaustive-deps

  const apptPath = useMemo(() => {
    if (_.isEmpty(appointment)) {
      return ''
    }

    return routeActions.APPT({
      appointmentId: appointment.id,
      encounterIds: appointment.encounterIds,
    })
  }, [appointment])

  useEffect(() => {
    if ($appts.nearestBarIsVisible) {
      $appts.SET_NEAREST_BAR_HEIGHT(88)
    } else {
      $appts.SET_NEAREST_BAR_HEIGHT(0)
    }
  }, [$appts.nearestBarIsVisible]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={classNames(
        styles.nearestAppt,
        $appts.nearestBarIsVisible && styles.nearestApptVisible,
      )}
    >
      {text && (
        <div className="container">
          <div className={styles.nearestApptWrapper}>
            <div className={styles.nearestApptLogo}>
              <img src={logoSimpleWhite} alt="" />
            </div>
            <div className={styles.nearestApptContent}>
              <div className={styles.nearestApptContentText}>
                <div className="font-weight-bold">{text}</div>
                {$main.windowSize.minSM && extendAddress(place).shortAddress()}
              </div>
              <div className={styles.nearestApptContentButtons}>
                {$main.windowSize.minMD ? (
                  <Button
                    mode={['primary', 'outline']}
                    autoTarget={apptPath}
                    label={t('btn.view_details')}
                  />
                ) : (
                  <CustomLink autoTarget={apptPath} className={styles.nearestApptContentBtn} />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default observer(NearestAppt)
