var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { observer } from 'mobx-react';
import CustomLink from '@library/ui/customLink/CustomLink';
import { routeActions } from '@config/routeActions';
import logoVert from '@assets/images/logo-vert.svg';
import styles from './SidebarLogo.module.scss';
var SidebarLogo = function (_a) {
    var onHideSidebar = _a.onHideSidebar;
    function handleHideSidebar() {
        if (_.isFunction(onHideSidebar)) {
            onHideSidebar();
        }
    }
    return (_jsx("div", __assign({ className: styles.wrapper }, { children: _jsx(CustomLink, __assign({ autoTarget: routeActions.HOME(), onClick: handleHideSidebar }, { children: _jsx("img", { src: logoVert, alt: "" }) })) })));
};
export default observer(SidebarLogo);
