import React, { useMemo, useState } from 'react'

import classNames from 'classnames'

import './ProgressRing.scss'

const ProgressRing = ({
  radius = 28,
  stroke = 1,
  progress = 0,
  spin = false,
  centered = false,
  className = '',
}) => {
  const [normalizedRadius] = useState(radius - stroke * 2)
  const [circumference] = useState(normalizedRadius * 2 * Math.PI)

  const strokeDashoffset = useMemo(() => {
    return circumference - (progress / 100) * circumference
  }, [circumference, progress])

  return (
    <div
      className={classNames(
        'progress_indicator-ring',
        spin && 'progress_indicator-ring--spin',
        className,
      )}
      style={centered ? { marginTop: -radius, marginLeft: -radius } : {}}
    >
      <svg height={radius * 2} width={radius * 2}>
        <circle
          className="progress_indicator-ring-base"
          strokeWidth={stroke}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
        <circle
          className="progress_indicator-ring-color"
          strokeWidth={stroke}
          strokeDasharray={circumference + ' ' + circumference}
          style={{ strokeDashoffset }}
          r={normalizedRadius}
          cx={radius}
          cy={radius}
        />
      </svg>
    </div>
  )
}

export default ProgressRing
