export function getName(name, use = 'official') {
  let found = name

  if (_.isArray(name)) {
    found = name.find((x) => x.use === use)

    if (!found && name.length) {
      found = name[0]
    }
  }

  let result = {}

  if (found && found.hasOwnProperty('given')) {
    result.firstName = found.given.join(' ')
  }

  if (found && found.hasOwnProperty('family')) {
    result.lastName = found.family
  }

  return result
}

export function withFullName(item) {
  let result = _.cloneDeep(item)

  const name = getName(item.name)

  const firstName = name.firstName || ''
  const lastName = name.lastName || ''

  if (!result.hasOwnProperty('firstName')) {
    result.firstName = firstName
  }

  if (!result.hasOwnProperty('lastName')) {
    result.lastName = lastName
  }

  if (!result.hasOwnProperty('fullName')) {
    result.fullName = [firstName, lastName].filter((x) => x).join(' ')

    if (item.name && !result.fullName) {
      result.fullName = item.name
    }
  }

  return result
}

export function getReferenceId({ resource, id } = {}) {
  return resource + '/' + id
}

export function parseReferenceId(reference = '') {
  if (!_.isString(reference)) {
    return {}
  }

  const parsed = reference.split('/')

  return {
    resource: parsed[0],
    id: parsed[1],
  }
}
