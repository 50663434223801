import React from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import Badge from '@library/ui/badge/Badge'

import { formatAmount } from '@helpers/text'

import { $booking } from '@store'

import styles from './SelectedVoucherOrCode.module.scss'

const SelectedVoucherOrCode = ({ onOpenVoucherModal }) => {
  const { t } = useTranslation()
  const voucher = $booking.voucher
  const office = $booking.office

  return (
    <div className={styles.wrapper}>
      <div className={styles.practice}>{$booking.practice?.name}</div>
      <div className={styles.group}>
        {voucher && (
          <Badge
            type={['primary-fill', 'lg']}
            className={styles.groupItem}
            clickable={true}
            callback={onOpenVoucherModal}
            onClose={() => {
              $booking.SET_VOUCHER(null)
              $booking.SET_PREDEFINED_SERVICE_IDS([])
            }}
          >
            <span className={styles.badgeLabel}>{t('label.voucher:')}</span>
            {voucher.code} &bull; {formatAmount(voucher.price, voucher.currency)}
          </Badge>
        )}
        {office && (
          <Badge
            type={['primary-fill', 'lg']}
            className={styles.groupItem}
            clickable={true}
            callback={onOpenVoucherModal}
            onClose={() => {
              $booking.SET_OFFICE_ID(null)
            }}
          >
            <span className={styles.badgeLabel}>{t('label.code:')}</span>
            {office.code}
          </Badge>
        )}
      </div>
    </div>
  )
}

export default observer(SelectedVoucherOrCode)
