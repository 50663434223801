var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import DocumentUploaderModal from '@library/plugins/documentUploader/DocumentUploader.modal';
import { $modal } from '@store';
var DocumentUploader = function (_a) {
    var patientId = _a.patientId, encounterId = _a.encounterId, baseFileName = _a.baseFileName, children = _a.children;
    var inputRef = useRef();
    useEffect(function () {
        var changeFile = function (e) {
            var target = e.target;
            if (target.files[0]) {
                $modal.add(DocumentUploaderModal, {
                    patientId: patientId,
                    encounterId: encounterId,
                    baseFileName: baseFileName,
                    file: target.files[0],
                });
            }
        };
        if (inputRef.current) {
            inputRef.current.removeEventListener('change', changeFile);
            inputRef.current.addEventListener('change', changeFile);
        }
        return function () { var _a; return (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('change', changeFile); };
    }, [inputRef.current, encounterId]);
    var openUploadModal = useCallback(function () {
        inputRef.current.value = null;
        inputRef.current.click();
    }, [inputRef.current, encounterId]);
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ style: { display: 'none' } }, { children: _jsx("input", { ref: inputRef, type: "file", name: "file" }) })), React.cloneElement(children, { onClick: openUploadModal })] }));
};
export default observer(DocumentUploader);
