import React, { useState } from 'react'

import { toJS } from 'mobx'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import { confirmAction } from '@library/ActionConfirmator'
import Button from '@library/ui/button/Button'

import AddPaymentMethodModal from '@pages/paymentMethods/AddMethod.modal'

import { $modal, $pms } from '@store'

import pmsConfig from '@config/pms'

const PaymentMethodsList = ({ selected, onSelect, showCheck = false }) => {
  const { t } = useTranslation()

  const [methodId, setMethodId] = useState(selected || $pms.defaultId)

  function onSelectHandle(item) {
    if (item.isExpired) {
      return false
    }

    setMethodId(item.id)

    if (_.isFunction(onSelect)) {
      onSelect(item.id)
    }
  }

  function removeHandle(id, e) {
    e.stopPropagation()

    confirmAction(
      'customAlert',
      {
        data: {
          title: t('pms.remove_alert.title'),
          message: t('pms.remove_alert.message'),
        },
        callback: async () => {
          await $pms.remove(id)
        },
      },
      e,
    )
  }

  function openAddModal() {
    $modal.add(AddPaymentMethodModal, {
      onDone: (e) => onSelectHandle(e),
    })
  }

  return (
    <div className="pms_list">
      {$pms.items.map((item) => (
        <div
          key={item.id}
          className={classNames(
            'pms_list-item ct_actions--x1',
            item.isExpired && 'pms_list-item--disabled',
          )}
          onClick={() => onSelectHandle(item)}
        >
          {showCheck && (
            <div
              className={classNames(
                'pms_list-check',
                item.id === methodId && 'pms_list-check--checked',
              )}
            />
          )}

          <div
            className={classNames('pms_icon', 'pms_icon--' + _.kebabCase(item.brand.toLowerCase()))}
          />

          <div className="pms_list-text">
            <div className="pms_list-text-field">
              {pmsConfig.getLabel(item.brand)} • {item.last4}
            </div>
            {item.isExpired ? (
              <div className="pms_list-text-label text-danger">{t('pms.label.expired')}</div>
            ) : (
              <div className="pms_list-text-label">
                {item.expMonth}/{item.expYear}
              </div>
            )}
          </div>

          <div
            className="ct_actions ct_actions--full ct_actions--dark"
            onClick={(e) => removeHandle(item.id, e)}
          >
            <div className="ct_actions-btn ct_actions-btn--remove" />
          </div>
        </div>
      ))}
      <div className="pms_list-buttons btn-group">
        <Button mode={['create', 'block']} action={openAddModal} label={t('pms.btn.add')} />
      </div>
    </div>
  )
}

export default observer(PaymentMethodsList)
