class BookingSelectSymptomsConfig {
  commonSymptoms = [
    {
      id: [132],
      name: 'Bronchitis',
      img: 'bronchitis',
    },
    {
      id: [206],
      name: 'High Fever',
      img: 'highFever',
    },
    {
      id: [82],
      name: 'One Side Weakness',
      img: 'weakness',
    },
    {
      id: [207],
      name: 'Upper Respiratory',
      img: 'upperRespiratory',
    },
    {
      id: [12],
      name: 'Sore Throat',
      img: 'soreThroat',
    },
    {
      id: [135],
      name: 'Cold',
      img: 'cold',
    },
    {
      id: [43],
      name: 'Wheezing',
      img: 'wheezing',
    },
    {
      id: [134],
      name: 'Flu',
      img: 'flu',
    },
    {
      id: [39],
      name: 'Cough',
      img: 'cough',
    },
    {
      id: [17],
      name: 'Earache',
      img: 'earPain',
    },
    {
      id: [106],
      name: 'Rash',
      img: 'rashHives',
    },
    {
      id: [107],
      name: 'Hives',
      img: 'rashHives',
    },
    {
      id: [33],
      name: 'Eye redness',
      img: 'eyeRedness',
    },
    {
      id: [27],
      name: 'Pink eye',
      img: 'pinkEye',
    },
    {
      id: [47],
      name: 'Palpitations',
      img: 'palpitations',
    },
  ]

  symptomsCategories = {
    general_concerns: ['general', 'neuro', 'misc', 'feel'],
    back_issues: ['back'],
    chest_symptoms: ['chest'],
    cold_infection_respiratory: ['infection'],
    ear_issues: ['ear'],
    eye_issues: ['eye'],
    gyn_concerns: ['pelvis', 'gyn'],
    heart_issues: ['heart'],
    joint_issues: ['joints'],
    lung_issues: ['lungs'],
    neck_issues: ['neck'],
    nose_issues: ['nose'],
    psychological_issues: ['psych'],
    rectal_issues: ['rectum', 'rectum_special'],
    skin_related_issues: ['skin', 'skin_burn'],
    stomach_abdomen_issues: ['abdomen', 'feel', 'gusex'],
    stroke_weakness_neurological_concerns: [
      'neuro',
      'unable_exec',
      'feel',
      'unable',
      'neuro_special',
    ],
    throat_issues: ['throat'],
    trauma_conditions: ['extremities', 'trauma'],
    urological_sexual_concerns: ['pelvis', 'gu', 'gusex'],
  }
}

export default new BookingSelectSymptomsConfig()
