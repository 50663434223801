var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { IMaskInput } from 'react-imask';
import classNames from 'classnames';
import { useInnerValue } from '@library/CustomHooks';
import { getUuid, isAbsoluteEmpty } from '@helpers/other';
var initField = {
    value: '',
};
var InputBase = forwardRef(function (_a, forwardedRef) {
    var _b = _a.field, field = _b === void 0 ? initField : _b, 
    //
    onClick = _a.onClick, onClear = _a.onClear, _c = _a.type, type = _c === void 0 ? 'text' : _c, className = _a.className, fieldClassName = _a.fieldClassName, label = _a.label, _d = _a.labelClamp, labelClamp = _d === void 0 ? 1 : _d, _e = _a.headerActions, headerActions = _e === void 0 ? [] : _e, placeholder = _a.placeholder, _id = _a.id, _f = _a.clear, clear = _f === void 0 ? false : _f, _g = _a.readonly, readonly = _g === void 0 ? false : _g, mode = _a.mode, children = _a.children, mask = _a.mask, _h = _a.icon, icon = _h === void 0 ? {} : _h, onRemove = _a.onRemove, _j = _a.canRemove, canRemove = _j === void 0 ? false : _j, unFocusComponent = _a.unFocusComponent, fieldAttributes = __rest(_a, ["field", "onClick", "onClear", "type", "className", "fieldClassName", "label", "labelClamp", "headerActions", "placeholder", "id", "clear", "readonly", "mode", "children", "mask", "icon", "onRemove", "canRemove", "unFocusComponent"]);
    var _k = useState(false), isFocused = _k[0], setIsFocused = _k[1];
    var isUnfocused = unFocusComponent && !isFocused;
    var value = field.value, onChange = field.onChange, isInvalid = field.isInvalid;
    var _l = useInnerValue(value), innerValue = _l[0], setInnerValue = _l[1];
    var id = useMemo(function () { return (_id ? _id : getUuid()); }, [_id]);
    function handleOnChange(value) {
        setInnerValue(value);
        if (_.isFunction(onChange)) {
            onChange(value);
        }
    }
    function handleClear(e) {
        if (e && _.isFunction(e.stopPropagation)) {
            e.preventDefault();
            e.stopPropagation();
        }
        handleOnChange('');
        if (_.isFunction(onClear)) {
            onClear();
        }
    }
    function getMode(mode) {
        var array = _.isArray(mode) ? mode : [mode];
        return array.map(function (x) { return 'tsform-item--' + x; }).join(' ');
    }
    function handleFocus(e) {
        if (readonly) {
            e.preventDefault();
            return;
        }
        if (unFocusComponent) {
            setIsFocused(true);
        }
        if (_.isFunction(fieldAttributes.onFocus)) {
            fieldAttributes.onFocus(e);
        }
    }
    function handleBlur(e) {
        if (isFocused) {
            setIsFocused(false);
        }
        if (_.isFunction(fieldAttributes.onBlur)) {
            fieldAttributes.onBlur(e);
        }
    }
    var fieldRef = useRef(null);
    // @ts-ignore
    useImperativeHandle(forwardedRef, function () { return ({
        focus: function () {
            fieldRef.current.focus();
        },
        clear: function () {
            handleClear();
        },
    }); });
    return (_jsxs("div", __assign({ className: classNames(className, 'tsform-item', 'tsform-item--input', mode && getMode(mode), isInvalid && 'tsform-item--error', readonly && 'tsform-item--readonly', isUnfocused && 'tsform-item--unfocused', canRemove && _.isFunction(onRemove) && 'tsform-item--with_remove') }, { children: [_jsxs("label", __assign({ className: classNames('tsform-item-inner', icon && icon.class && 'tsform-item-inner--' + icon.class) }, { children: [(label || headerActions.length > 0) && (_jsxs("div", __assign({ className: "tsform-header" }, { children: [label && (_jsx("div", __assign({ className: classNames('tsform-label', labelClamp && 'tsform-label-clamp-' + labelClamp) }, { children: label }))), headerActions.length > 0 &&
                                headerActions.map(function (action) { return (_jsx("div", __assign({ className: "tsform-header-action", onClick: function () { return action.onClick(); } }, { children: action.text }), action.text)); })] }))), _jsxs("div", __assign({ className: "tsform-body" }, { children: [_jsxs("div", __assign({ className: classNames('tsform-field', fieldClassName) }, { children: [isUnfocused && unFocusComponent, mask ? (_jsx(_Fragment, { children: _jsx(IMaskInput, __assign({ inputRef: function (el) { return (fieldRef.current = el); }, type: type, value: String(innerValue), onAccept: function (value, masked) {
                                                if (mask.onOutput) {
                                                    value = mask.onOutput(value, masked);
                                                }
                                                handleOnChange(value);
                                            }, id: id, placeholder: placeholder, onClick: onClick, readOnly: readonly, onFocus: handleFocus, onBlur: handleBlur }, _.omit(fieldAttributes, ['onFocus', 'onBlur']), { 
                                            //
                                            overwrite: "shift", radix: "." }, _.omit(mask, 'onOutput'))) })) : _.isEmpty(children) ? (_jsx("input", __assign({ ref: fieldRef, type: type, value: innerValue, onChange: function (e) { return handleOnChange(e.target.value); }, id: id, placeholder: placeholder, onClick: onClick, readOnly: readonly, onFocus: handleFocus, onBlur: handleBlur }, _.omit(fieldAttributes, ['onFocus', 'onBlur'])))) : (children), clear && !readonly && !isAbsoluteEmpty(innerValue) && (_jsx("div", { className: "tsform-field-clear", onClick: handleClear }))] })), icon && (_jsx("div", __assign({ className: classNames('tsform-icon', icon.class && 'tsform-icon--' + icon.class), onClick: icon.action }, { children: _jsx("img", { src: icon.img, alt: "" }) })))] }))] })), canRemove && _.isFunction(onRemove) && (_jsx("div", { className: "tsform-item-remove_button", onClick: onRemove }))] })));
});
export default observer(InputBase);
