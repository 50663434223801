import React from 'react'

import { useTranslation } from 'react-i18next'

import classNames from 'classnames'
import _ from 'lodash'

import CustomLink from '@library/ui/customLink/CustomLink'

import { getEnumsValue } from '@helpers/enums'
import hAppt from '@helpers/hAppt'
import { formatDate, getHMDiff, getInitFactTimeDiff } from '@helpers/time'
import { getUserText } from '@helpers/user'

import { apptTypes } from '@config/apptsCore'
import { ENUMS_APPT_VISIT_TYPE } from '@config/enums/appt'
import { routeActions } from '@config/routeActions'
import { PATIENT_GROUP_TYPES } from '@config/services'

import styles from './Appts.module.scss'

const ApptsListPageItemDesktop = ({
  status,
  appointment,
  place,
  office,
  patients,
  service,
  payment,
  extendedPlace,
}) => {
  const { t } = useTranslation()

  const getApptType = () => {
    const apptType = apptTypes.getBasicTypes(service)

    if (apptType.isAtClinic) {
      return 'at_clinic'
    } else if (apptType.isAtHome) {
      return 'at_home'
    } else {
      return 'virtual'
    }
  }

  const timeDifference = getInitFactTimeDiff(
    appointment.appointedStart,
    appointment.appointedFinish,
    appointment.factStart,
    appointment.factFinish,
  )

  const patientNameIsVisible =
    appointment.patientGroupType === PATIENT_GROUP_TYPES.individual ||
    (appointment.patientGroupType === PATIENT_GROUP_TYPES.family &&
      appointment.patientIds?.length === 1)

  return (
    <CustomLink
      className={classNames(styles.item, status.isProgress && styles.itemActive)}
      autoTarget={routeActions.APPT({
        appointmentId: appointment.id,
        encounterIds: appointment.encounterIds,
      })}
    >
      <div className={styles.table}>
        <div className={classNames(styles.tableItem, styles.dateBlock)}>
          <div className={styles.date}>
            {formatDate(appointment.appointedStart, { format: 'time' })}{' '}
            {formatDate(appointment.appointedStart, { format: 'date' })}
          </div>
          <div>{extendedPlace.shortAddress()}</div>
        </div>
        <div className={classNames(styles.tableItem, styles.typeBlock)}>
          <div className={styles.type}>
            {hAppt.typeLabel({ service })}
            <span className={styles.typeLabel}>
              {getEnumsValue(getApptType(), ENUMS_APPT_VISIT_TYPE)}
            </span>
          </div>
          <div className={styles.typeBlockOffice}>
            <div className={styles.officeText}>
              <div className={styles.officeTextName}>{hAppt.practiceLabel({ office })}</div>
            </div>
            <div className={styles.officeText}>
              {patientNameIsVisible ? (
                <div className={styles.officeTextName}>
                  {getUserText(_.head(Object.values(patients)), ['fullName'])}
                </div>
              ) : (
                <div className={styles.officeTextName}>
                  {appointment.patientIds.length} {t('label.patients')}
                </div>
              )}
              <span className={styles.typeLabel}>
                {t(`appt_type.${hAppt.getGroupTypeKey(appointment, patients)}`)}
              </span>
            </div>
          </div>
        </div>
        <div className={classNames(styles.tableItem, styles.durationBlock)}>
          {payment ? (
            <div className={styles.cost}>{hAppt.amount({ appointment })}</div>
          ) : (
            <>
              <div className={styles.duration}>
                {t('appt.duration')}:{' '}
                {getHMDiff(appointment.appointedStart, appointment.appointedFinish)}
              </div>
              {appointment.factStart && appointment.factFinish && (
                <div>
                  {t('appt.label.fact')}: {getHMDiff(appointment.factStart, appointment.factFinish)}
                  <div>
                    {timeDifference && <span className={styles.timeDiff}>({timeDifference})</span>}
                  </div>
                </div>
              )}
            </>
          )}
        </div>
        <div className={styles.statusBlockItem}>
          <div className={styles.statusBlock}>
            <div className={styles.status}>{_.get(status, 'texts.status')}</div>
            {status.isDone && !status.isCanceled && status.apptIndicators.length > 0 && (
              <div className={styles.indicators}>
                {status.apptIndicators.map((x) => t(`appt.indicator.${x}`)).join(', ')}
              </div>
            )}
          </div>
          <span className={styles.idBlock}>
            {t('label.id')} {appointment.id}
          </span>
        </div>
      </div>
    </CustomLink>
  )
}

export default ApptsListPageItemDesktop
