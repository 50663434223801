import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { Transition } from 'react-spring/renderprops'

import classNames from 'classnames'

import clientService from '@services/client'

import { getPresetGroup } from '@library/animations/Animations'
import AutoTextarea from '@library/AutoTextarea'
import { useChatResizeDetector, useIntervalTimer, useLoader } from '@library/CustomHooks'
import Modal from '@library/modal/Modal'

import { getFullName } from '@helpers/text'

import { $appts, $chats, $notices, $user } from '@store'

import styles from './Chat.modal.module.scss'

const ChatModal = ({ _core, ...props }) => {
  const { t } = useTranslation()

  const {
    // Required
    appointmentId,
    practiceId,
    partnerRole = 'general', // general || agent || provider
    staffOnly = false,

    // Optional
    historyMode: _historyMode,

    // Other
    pushMessage = false,
    title = t(`chat.${props.staffOnly ? partnerRole : 'general'}.title`),
    emptyText = t(`chat.${props.staffOnly ? partnerRole : 'general'}.empty`),
  } = props

  const historyMode = useRef(_historyMode)

  const [messages, setMessages] = useState([])
  const [message, setMessage] = useState('')

  const [isFixed, setIsFixed] = useState(false)
  const [isBlocked, setIsBlocked] = useState(false)

  const lastMessageId = useRef(0)
  const waitingList = useRef([])

  const [isInitialized, setIsInitialized] = useState(false)

  const members = useRef({})
  const [lastMemberName, setLastMemberName] = useState('')

  const hasMessageHistory = useRef(false)

  const requestId = useRef(0)

  const { start, stop } = useIntervalTimer({
    duration: 3000,
    callback: async () => {
      const res = await clientService.getNewChatMessages({
        appointmentId,
        lastId: lastMessageId.current,
        staffOnly,
      })

      if (res.length) {
        lastMessageId.current = res[res.length - 1].id

        let { mappedList, viewedList } = getPreparedList(res)

        if (viewedList.length) {
          $notices.view({ chatIds: viewedList }) // (?) probably not necessary here
        }

        mappedList = mappedList.filter((x) => {
          if (x.isUser) {
            const index = waitingList.current.indexOf(x.text)

            if (index !== -1) {
              waitingList.current = waitingList.current.filter((item, idx) => idx !== index)
              return false
            }
          }
          return true
        })

        $chats.sendMessages(mappedList)
      }
    },
    autoStart: false,
  })

  useEffect(() => {
    if (_.isEmpty(members.current)) {
      return
    }
    $notices.SET_STOPPED_CHAT(Object.keys(members.current).map((item) => parseInt(item)))
  }, [members.current]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    return () => {
      $notices.SET_STOPPED_CHAT(false)
      stop()
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const isLoading = useLoader(async () => {
    const resAppt = await $appts.getOne({
      id: appointmentId,
      practiceId,
      setByRoute: false,
    })
    const { fullAppt, actions } = resAppt.item

    const possibleMembers = ['patients', 'agent', 'provider']
    const resultMembers = {}

    possibleMembers.forEach((item) => {
      if (fullAppt?.[item]) {
        if (item === 'patients') {
          _.forEach(Object.values(fullAppt[item]), (patient) => {
            resultMembers[patient.userId] = {
              data: patient,
              role: 'patient',
              name:
                Object.keys(fullAppt[item]).length === 1
                  ? getPartnerName(patient)
                  : t('chat.client.title'),
            }
          })
        } else {
          resultMembers[fullAppt[item].userId] = {
            data: fullAppt[item],
            role: item,
            name: getPartnerName(fullAppt[item]),
          }
        }
      }
    })
    members.current = resultMembers

    const dataMap = {
      general: { partnerId: 'userId', canText: 'textGeneral' },
      agent: { partnerId: 'userId', canText: 'textStaff' },
      provider: { partnerId: 'userId', canText: 'textStaff' },
    }
    const dataByRole = dataMap[partnerRole]

    if (_.isUndefined(historyMode.current)) {
      historyMode.current = !actions[dataByRole.canText]
    }

    let res = await clientService.getChatMessages({
      appointmentId,
      staffOnly,
    })

    if (res.length) {
      lastMessageId.current = res[res.length - 1].id
    }

    let { mappedList, viewedList } = getPreparedList(res)

    if (viewedList.length) {
      $notices.view({ chatIds: viewedList })
    }

    if (pushMessage !== false) {
      waitingList.current.push(pushMessage)

      clientService.sendChatMessage({
        appointmentId,
        staffOnly,
        message: pushMessage,
      })

      mappedList.push({
        isUser: true,
        text: pushMessage,
        userId: $user.profile.userId,
      })
    }

    if (mappedList.length) {
      hasMessageHistory.current = true
    }

    setMessages(addMemberName(mappedList, lastMemberName))

    start()
  })

  function addMemberName(messageList, lastMemberName) {
    return (prev) => {
      const result = []
      let innerLastMemberName = lastMemberName
      messageList.forEach((item) => {
        const memberName = item.userId in members.current ? members.current[item.userId].name : ''

        if (!item.isUser && memberName !== innerLastMemberName) {
          innerLastMemberName = memberName
          result.push({ ...item, name: memberName })
        } else {
          innerLastMemberName = memberName
          result.push(item)
        }
      })
      setLastMemberName(innerLastMemberName)
      return [...prev, ...result]
    }
  }

  function getPreparedList(items = []) {
    let viewedList = []
    let mappedList = items.map((x) => {
      const isUser = x.userId === $user.profile.userId

      if (!isUser) {
        viewedList.push(x.id)
      }
      return {
        isUser,
        text: x.message,
        userId: x.userId,
      }
    })

    return { mappedList, viewedList }
  }

  // inner
  const wrapperRef = useRef(null)
  const innerRef = useRef(null)
  const formRef = useRef(null)

  const formIsValid = useMemo(() => !_.isEmpty(message.trim()), [message])

  // last state
  const { setLastScrollTop } = useChatResizeDetector({ wrapperRef, isInitialized })

  // show message
  const showMessage = useCallback(
    (msg = {}) => {
      if (!wrapperRef || !wrapperRef.current) return false
      if (!innerRef || !innerRef.current) return false

      if (
        wrapperRef.current.scrollTop + wrapperRef.current.clientHeight >=
        innerRef.current.clientHeight - 10
      ) {
        setIsFixed(true)
      }

      setMessages(addMemberName([msg], lastMemberName))
    },
    [setMessages, wrapperRef, innerRef, lastMemberName],
  )

  // check queue and show next
  useEffect(() => {
    if (isBlocked) {
      return false
    }
    if ($chats.queue.length) {
      let msg = $chats.queue[0]
      setIsBlocked(true)
      showMessage(msg)
    }
  }, [isBlocked, $chats.queue, showMessage, setIsBlocked]) // eslint-disable-line react-hooks/exhaustive-deps

  // Check error messages
  useEffect(() => {
    setMessages(() => {
      const result = []
      messages.forEach((item) => {
        if (item.requestId && _.includes($chats.errorRequestIds, item.requestId)) {
          result.push({ ...item, notDelivered: true })
        } else {
          result.push(item)
        }
      })
      return result
    })
  }, [$chats.errorRequestIds]) // eslint-disable-line react-hooks/exhaustive-deps

  // scroll to bottom
  useEffect(() => {
    if (!isLoading) {
      wrapperRef.current && wrapperRef.current.scrollTo(0, innerRef.current.clientHeight)
    }
  }, [isFixed, wrapperRef, innerRef, isLoading])

  useEffect(() => {
    if (isInitialized) {
      wrapperRef.current.scrollTo(0, innerRef.current.clientHeight)
    }
  }, [isInitialized])

  function afterMessageShowed() {
    if (isFixed) {
      setIsFixed(false)
    }
    if (!isInitialized) {
      setIsInitialized(true)
    }
    $chats.removeFromQueue()
    setIsBlocked(false)
  }

  function handleTextarea({ target: { value } }) {
    setMessage(value)
  }

  async function handleSubmit(e) {
    e.preventDefault()

    if (historyMode.current) {
      return false
    }

    if (formIsValid) {
      const newRequestId = requestId.current + 1
      requestId.current = newRequestId
      waitingList.current.push(message)

      clientService
        .sendChatMessage({
          appointmentId,
          staffOnly,
          message,
        })
        .then((result) => {
          if (!_.isEmpty(result.error)) {
            $chats.addToErrorRequestIds(newRequestId)
          }
        })
        .catch(() => {
          $chats.addToErrorRequestIds(newRequestId)
        })

      $chats.sendMessage(message, true, $user.profile.userId, newRequestId)
      setMessage('')
    }

    formRef.current.focus()
  }

  function getPartnerName(data) {
    if (!data) {
      return
    }
    return 'name' in data ? data.name : getFullName(data)
  }

  function getChatHeader() {
    if (staffOnly) {
      const partner = _.find(members.current, { role: partnerRole })
      if (!partner) {
        return title
      }
      return getPartnerName(partner)
    }
    return title
  }

  return (
    <Modal
      centered
      scrollable
      headerBordered
      className={classNames(styles.chatModal, styles.chatModalWithInput, 'chat-modal')}
    >
      <div className="modal-toolbar modal-toolbar--reverse-lg">
        {/*<a href="tel:+11111111111" className="modal-toolbar-btn modal-toolbar-btn--phone"/>*/}
        <div className="modal-toolbar-btn modal-toolbar-btn--close" onClick={_core.onHide} />
      </div>
      <Modal.Header className="text-lg-center">{getChatHeader()}</Modal.Header>
      <Modal.Body
        isLoading={isLoading}
        ref={wrapperRef}
        className={classNames(styles.chat, 'styled_scroll', isFixed && 'styled_scroll--locked')}
        onScroll={(e) => setLastScrollTop(e.target.scrollTop)}
      >
        <div
          ref={innerRef}
          className={classNames(styles.chatInner, isFixed && styles.chatInnerFixed)}
        >
          <div className={styles.chatMessages}>
            <div
              className={classNames(
                styles.chatEmpty,
                messages.length > 0 && styles.chatEmptyContent,
              )}
            >
              {emptyText}
            </div>
            <Transition
              {...getPresetGroup('chatMessages')}
              items={messages}
              initial={hasMessageHistory.current ? false : undefined}
              keys={(item, index) => index}
              onRest={afterMessageShowed}
            >
              {(item) =>
                item &&
                ((style) => (
                  <div
                    className={classNames(
                      styles.chatMessage,
                      item.isUser ? styles.chatMessageRight : styles.chatMessageLeft,
                      item.danger && styles.chatMessageDanger,
                    )}
                    style={style}
                  >
                    <div className={styles.chatMessageInner}>
                      {/*{partnerRole === 'general' && item.name && (*/}
                      {!staffOnly && item.name && (
                        <span className={styles.chatMessageInnerMember}>{item.name}</span>
                      )}
                      {item.text.trim()}
                    </div>
                    {item.notDelivered && <span className="red_marker red_marker--info" />}
                  </div>
                ))
              }
            </Transition>
          </div>
        </div>
      </Modal.Body>
      {!historyMode.current && (
        <Modal.Footer>
          <div className={styles.input}>
            <AutoTextarea
              ref={formRef}
              className={styles.inputField}
              value={message}
              onChange={handleTextarea}
              onSubmit={handleSubmit}
              placeholder={t('chat.ph.textarea')}
            />
            <button
              type="button"
              className={styles.btn}
              onClick={handleSubmit}
              disabled={!formIsValid}
            />
          </div>
        </Modal.Footer>
      )}
    </Modal>
  )
}

export default observer(ChatModal)
