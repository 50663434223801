import React, { useCallback } from 'react'

import { observer } from 'mobx-react'

import classNames from 'classnames'

import DefaultExpander from '@src/layouts/default/DefaultExpander'
import DefaultPageWrapper from '@src/layouts/default/DefaultPageWrapper'
import Sidebar from '@src/layouts/sidebar/Sidebar'

import ProgressRing from '@library/ui/progressRing/ProgressRing'

import { $main } from '@store'

const DefaultLayout = ({ element, routeConfig }) => {
  const handleClick = useCallback(() => {
    if ($main.sidebar) {
      $main.sidebarToggle(false)
    }
  }, [$main.sidebar]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    // todo: move pageClassName to routeConfig.meta
    <div className={classNames('default_layout', routeConfig.pageClassName)}>
      <DefaultExpander>
        {routeConfig.sidebar !== false && <Sidebar />}
        <main
          className={classNames(
            'content-wrapper',
            routeConfig.sidebar === false && 'content-wrapper--full-width',
            routeConfig.meta?.fullHeight && 'content-wrapper--full-height',
          )}
          onClick={handleClick}
        >
          {!$main.pageIsLoaded > 0 ? (
            <div className="box-body box-body--padding text-center">
              <ProgressRing className={'mt-100'} progress={25} spin={true} />
            </div>
          ) : (
            <DefaultPageWrapper>{React.cloneElement(element, { routeConfig })}</DefaultPageWrapper>
          )}
        </main>
      </DefaultExpander>
    </div>
  )
}

export default observer(DefaultLayout)
