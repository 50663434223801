import moment from 'moment-timezone'

import apiService from '@services/api'

import ChatModal from '@library/chat/Chat.modal'
import maps from '@library/map/Map'

import { customGoTo } from '@helpers/router'

import { $alerts, $auth, $modal, $user } from '@store'

import { apptEvents } from '@config/appts'
import mainConfig from '@config/main'
import { routeActions } from '@config/routeActions'

class coreClient {
  //
  // Notification center
  //
  async getNotifications({ lastTime = false }) {
    if (_.isObject(lastTime)) {
      lastTime = lastTime.format('YYYY-MM-DDTHH:mm:ss')
    }

    const response = await apiService.fetchLoggedV3('user.notifications.list', {
      filter: {
        and: [{ gt: ['created', '$datetime:' + lastTime] }, { eq: ['role', mainConfig.appType] }],
      },
      limit: 1000,
    })

    return _.get(response, 'prepared.notifications.items', [])
  }

  async getEventsByNotifications(list = []) {
    let result = _.cloneDeep(list)

    const eventsIds = []
    _.forEach(result, (x) => eventsIds.push(x.eventId))

    if (eventsIds.length > 0) {
      const responseEvents = await apiService.fetchLoggedV3('user.events.list', {
        filter: {
          and: [{ in: ['id', eventsIds] }],
        },
        limit: 1000,
      })

      let events = _.get(responseEvents, 'prepared.events.items', [])

      result = result.map((item) => {
        item.event = events.find((x) => x.id === item.eventId) || {}
        return item
      })
    }

    return result
  }

  async getPreparedNotifications(params) {
    let result

    result = await this.getNotifications(params)
    result = await this.getEventsByNotifications(result)

    const staffActors = ['agent', 'provider']

    result = result.map((x) => {
      x.actions = []
      x.hideIfViewed = false

      const created = moment.utc(x.created)
      const limit = moment.utc().subtract(mainConfig.notifications.actualPeriod, 'minutes')
      x.isActual = created.isAfter(limit)

      const { appointmentId, actorRole, practiceId } = x.event

      if (!appointmentId) {
        x.actions.push({
          type: 'mark_as_read',
          customClassName: 'btn btn-white btn-sm',
        })

        return x
      }

      const forClient = $user?.role?.role === 'client'
      const isGeneralChat = _.find(x.event?.recipients, (account) => account.role === 'client')

      if (x.notification === 'chat_message_received') {
        x.hideIfViewed = true
        x.actions.push({
          appointmentId,
          type: 'reply',
          action() {
            $modal.add(ChatModal, {
              appointmentId,
              practiceId,
              partnerRole: forClient || !_.includes(staffActors, actorRole) ? 'general' : actorRole,
              staffOnly: isGeneralChat ? false : _.includes(staffActors, actorRole),
            })
          },
          viewOnClick: false,
        })
      }

      if (!x.viewed) {
        x.actions.push({
          appointmentId,
          type: 'view_appointment',
          async action() {
            const encounterId = _.first(x.event.updates.appointment?.encounterIds)
            const currentPracticeId = $user.role.practiceId
            const isOtherPractice =
              practiceId && currentPracticeId && practiceId !== currentPracticeId

            if (isOtherPractice) {
              await $auth.refresh({ practiceId })
            }

            customGoTo(routeActions.APPT({ appointmentId, encounterId: encounterId }))
          },
        })

        x.actions.push({
          type: 'mark_as_read',
          customClassName: 'btn btn-white btn-sm',
        })
      }

      return x
    })

    return result
  }

  async viewNotifications({ ids = [] }) {
    let requestData = ids.map((id) => ({ method: 'user.notifications.view', params: { id } }))
    let result = []

    if (requestData.length) {
      const response = await apiService.fetchLoggedBatchV3(requestData)

      _.forEach(response, (x) => {
        const item = _.get(x, 'prepared.notification')

        if (item) {
          result.push(item)
        }
      })
    }

    return result
  }

  //
  // Chat
  //
  async getChatMessages({ appointmentId = 0, staffOnly }) {
    const response = await apiService.fetchLoggedV3('chat_messages.list', {
      filter: {
        and: [{ eq: ['appointment_id', appointmentId] }, { eq: ['staff_only', staffOnly] }],
      },
      order: [['id', 'asc']],
      limit: 1000,
    })
    return _.get(response, 'prepared.chatMessages.items', [])
  }

  async sendChatMessage({ appointmentId, staffOnly, message }) {
    const params = {
      appointmentId,
      message,
      staffOnly,
    }
    return await apiService.fetchLoggedV3('chat_messages.create', params)
  }

  async getNewChatMessages({ appointmentId = 0, lastId = 0, staffOnly }) {
    const response = await apiService.fetchLoggedV3('chat_messages.list', {
      filter: {
        and: [
          { eq: ['appointment_id', appointmentId] },
          { eq: ['staff_only', staffOnly] },
          { gt: ['id', lastId] },
        ],
      },
      order: [['id', 'asc']],
      limit: 1000,
    })

    return _.get(response, 'prepared.chatMessages.items', [])
  }

  //
  // Documents
  //
  async getDocumentsAccessToken({ encounterId }) {
    return await apiService.fetchLoggedV3(
      'documents.get_patient_access_token',
      { encounterId },
      { skipAlert: true },
    )
  }

  async getDocumentTypes() {
    return await apiService.fetchLoggedV3('documents.list_document_types')
  }

  async getDocumentUploadUrl(params) {
    return await apiService.fetchLoggedV3('documents.get_documents_upload_urls', params)
  }

  async uploadFile({ url, data }) {
    return await fetch(url, { method: 'POST', body: data, mode: 'no-cors' })
  }

  async createDocument(params) {
    return await apiService.fetchLoggedV3('documents.create_document', params)
  }

  //
  // Appointments LIST
  //
  async getAppointmentPatientForms(encounterIds) {
    let requestData = []
    let result = {}

    _.forEach(encounterIds, (encId) => {
      requestData.push({
        method: 'document_templates.patient_forms',
        params: {
          encounterId: encId,
        },
      })
    })

    const response = await apiService.fetchLoggedBatchV3(requestData)

    _.forEach(response, (x) => {
      let documentTemplates = _.get(x, 'prepared.documentTemplates.items', [])
      documentTemplates = _.sortBy(documentTemplates, (x) => (x.type === 'patient_tos' ? 1 : 0))
      result[encounterIds[response.indexOf(x)]] = documentTemplates
    })

    return result
  }

  async getApptEvents({ ids, events = apptEvents.needForStatus }) {
    const response = await apiService.fetchLoggedV3('user.events.list', {
      filter: {
        and: [{ in: ['appointment_id', ids] }, { in: ['event', events] }],
      },
      limit: 1000,
    })

    return _.get(response, 'prepared.events.items', [])
  }

  //
  // Appointments ACTIONS
  //
  async callOfficeAppointment({ appointmentId, buttonId = 'callOfficeSubmit' }) {
    // todo: remove buttonId
    return await apiService.fetchLoggedV3('calls.create', { appointmentId })
  }

  async readyForConference({ id }) {
    return await apiService.fetchLoggedV3('appointments.ready_for_conference', {
      id,
    })
  }

  async acceptAppointment({ id }) {
    return await apiService.fetchLoggedV3('appointments.accept', { id })
  }

  async startAppointment({ id }) {
    return await apiService.fetchLoggedV3('appointments.start', {
      id,
    })
  }

  async finishAppointment({ id }) {
    return await apiService.fetchLoggedV3('appointments.finish', { id })
  }

  async cancelAppointment({ id }) {
    return await apiService.fetchLoggedV3('appointments.cancel', { id })
  }

  async completeAppointment({ id, ok }) {
    return await apiService.fetchLoggedV3('appointments.confirm', {
      id,
      userConfirms: ok,
    })
  }

  async sendFeedbackAppointment({ id, feedback }) {
    return await apiService.fetchLoggedV3('appointments.feedback', {
      id,
      feedback,
    })
  }

  async sendSurveyAppointment({ id, survey }) {
    return await apiService.fetchLoggedV3('appointments.survey', { id, survey })
  }

  // ENCOUNTERS
  async createEncounter({ appointmentId, patientId }) {
    return await apiService.fetchLoggedV3('encounters.create', { appointmentId, patientId })
  }

  async startEncounter({ encounterId }) {
    return await apiService.fetchLoggedV3('encounters.start', { id: encounterId })
  }

  async finishEncounter({ encounterId }) {
    return await apiService.fetchLoggedV3('encounters.finish', { id: encounterId })
  }

  async cancelEncounter({ encounterId }) {
    return await apiService.fetchLoggedV3('encounters.cancel', { id: encounterId })
  }

  async getSsQuestions({ practiceId }) {
    return await apiService.fetchLoggedV3('ss_questions.list', {
      filter: {
        contains: ['practice_ids', [practiceId]],
      },
      order: [['order', 'asc']],
    })
  }

  // INSURANCE
  async getInsuranceProviders(params) {
    return await apiService.fetchLoggedV3('insurance_providers.list', params)
  }

  async getDocumentsSurvey({ encounterId, documentTemplateId }) {
    // return {
    //   prepared: {
    //     surveyId: '111',
    //     accessToken: '222',
    //   },
    // }
    return await apiService.fetchLoggedV3('documents.get_survey', {
      encounterId,
      documentTemplateId,
    })
  }

  //
  // Profile
  //
  async getZoomSignature({ id, roleId }) {
    return await apiService.fetchLoggedV3(
      'appointments.get_meeting_signature',
      {
        id,
      },
      { roleId },
    )
  }

  async updateProfile(payload) {
    return await apiService.fetchLoggedV3('user.profile.update', payload)
  }

  async getPatientProfile() {
    const response = await apiService.fetchLoggedV3('patients.list', {
      limit: 1,
      filter: {
        eq: ['is_default', true],
      },
    })
    return _.get(response, 'prepared.patients.items.0', {})
  }

  //
  // Patients
  //
  async getPatient({ id }) {
    return await apiService.fetchLoggedV3('patients.read', { id }, { skipAlert: true })
  }

  async getPatients() {
    return await apiService.fetchLoggedV3('patients.list', {
      filter: {
        or: [{ eq: ['is_default', true] }, { ne: ['is_hidden', true] }],
      },
      order: [
        ['is_default', 'desc'],
        ['id', 'desc'],
      ],
    })
  }

  async removePatient({ id }) {
    let response = await apiService.fetchLoggedV3('patients.delete', { id }, { skipAlert: true })

    if (response.error) {
      const data = await apiService.fetchLoggedV3('patients.read', { id }, { skipAlert: true })

      if (!data.error) {
        response = await apiService.fetchLoggedV3('patients.update', {
          id: data.prepared.patient.id,
          is_hidden: true,
        })
      } else {
        $alerts.add(response.error.message)
      }
    }

    return response
  }

  async updatePatient(payload) {
    return await apiService.fetchLoggedV3('patients.update', payload)
  }

  async addPatient(payload) {
    return await apiService.fetchLoggedV3('patients.create', payload)
  }

  //
  // Places
  //
  async getPlaces() {
    return await apiService.fetchLoggedV3('places.list', {
      filter: {
        ne: ['is_hidden', true],
      },
      order: [['id', 'desc']],
    })
  }

  async removePlace({ id }) {
    let response = await apiService.fetchLoggedV3('places.delete', { id }, { skipAlert: true })

    if (response.error) {
      response = await apiService.fetchLoggedV3('places.update', {
        id,
        is_hidden: true,
      })

      if (response.error) {
        $alerts.add(response.error.message)
      }
    }

    return response
  }

  async updatePlace(payload) {
    const ALLOWED_KEYS = ['name', 'id', 'apartment', 'floor', 'directions', 'isHidden']

    const name = [payload.building, payload.street].join(' ')
    const payloadAllowedProps = _.pick({ name, ...payload }, ALLOWED_KEYS)

    return await apiService.fetchLoggedV3('places.update', payloadAllowedProps)
  }

  async addPlace(payload) {
    const timeZoneId = await maps.getTimeZoneId(payload.coords.lat, payload.coords.lng)

    return await apiService.fetchLoggedV3('places.create', {
      name: [payload.building, payload.street].join(' '),
      ..._.omit(payload, ['id']),
      timezone: timeZoneId,
    })
  }

  //
  // Payments
  //
  async getPayments() {
    return await apiService.fetchLoggedV3('payment_methods.list', {
      order: [['id', 'desc']],
    })
  }

  async removePayment({ id }) {
    return await apiService.fetchLoggedV3('payment_methods.delete', { id })
  }

  async addPayment(card, stripe) {
    const res = await stripe.createToken(card)
    let response

    if (res.token) {
      response = await apiService.fetchLoggedV3('payment_methods.create', {
        paymentMethodToken: res.token.id,
      })
    } else if (res.error) {
      $alerts.add(res.error.message)
    }

    return response
  }

  async getLastUsedPayedAppointment() {
    return await apiService.fetchLoggedV3('appointments.list', {
      filter: {
        and: [
          {
            and: [{ gt: ['payment_method_id', 0] }],
          },
          {
            or: [
              { eq: ['status', 'started'] },
              { eq: ['status', 'finished'] },
              { eq: ['status', 'complete'] },
              { eq: ['status', 'exception'] },
            ],
          },
        ],
      },
      limit: 1,
      order: [['id', 'desc']],
    })
  }

  //
  // Booking
  //
  async getOfficesForAddress({ expand, zip, coords, country }) {
    return await apiService.fetchLoggedV3('offices.list_serve_address', {
      expand,
      zip,
      coords,
      country,
    })
  }

  async getPracticeServices({ id, practiceIds, serviceIds, limit = 1000 }) {
    const filters = [{ eq: ['is_active', true] }]

    if (id) {
      filters.push({ eq: ['id', id] })
    } else {
      if (_.isArray(practiceIds) && practiceIds.length) {
        filters.push({ in: ['practice_id', practiceIds] })
      }
      if (_.isArray(serviceIds) && serviceIds.length) {
        filters.push({ in: ['service_id', serviceIds] })
      }
    }

    return await apiService.fetchLoggedV3('practice_services.list', {
      filter: { and: filters },
      limit,
    })
  }

  async loadSymptoms({ limit = 1000 } = {}) {
    return await apiService.fetchLoggedV3('symptoms.list', {
      order: [
        ['category', 'asc'],
        ['name', 'asc'],
      ],
      limit: limit,
    })
  }

  async checkSymptoms(symptoms = [], answers = {}) {
    const response = await apiService.fetchLoggedV3('symptoms.check', {
      symptomIds: symptoms,
      answers,
    })

    return response.prepared
  }

  async getSchedulesStat(params) {
    const response = await apiService.fetchLoggedV3('schedules.stat', params)
    return response.prepared
  }

  async getSchedulesWindows(params) {
    const response = await apiService.fetchLoggedV3('schedules.find_windows', params)
    return response.prepared
  }

  async createAppointment(payload) {
    return await apiService.fetchLoggedV3('appointments.create', payload)
  }

  async rebookAppointment(payload) {
    return await apiService.fetchLoggedV3('appointments.book', payload)
  }

  async selfRebookAppointment(payload) {
    return await apiService.fetchLoggedV3('appointments.rebook', payload)
  }

  async changePaymentMethod(payload) {
    return await apiService.fetchLoggedV3('appointments.change_payment_method', payload)
  }

  async findAllOffices({ id, code, practiceIds, zip }) {
    const filters = [{ eq: ['is_active', true] }]

    if (id) {
      filters.push({ eq: ['id', id] })
    } else {
      filters.push({ imatch: ['code', '^' + _.escapeRegExp(code) + '$'] })
    }
    if (zip) {
      filters.push({ contains: ['serviced_zips', [zip]] })
    }
    if (_.isArray(practiceIds) && practiceIds.length) {
      filters.push({ in: ['practice_id', practiceIds] })
    }

    const response = await apiService.fetchLoggedV3('offices.list', {
      expand: { office: ['practiceId'] },
      filter: { and: filters },
    })
    return {
      offices: _.get(response, 'prepared.offices.items', []),
      practices: _.get(response, 'prepared.practices', []),
    }
  }

  async findAllVouchers({ id, code, practiceIds, practiceServiceId }) {
    const filters = [{ eq: ['is_active', true] }]

    if (id) {
      filters.push({ eq: ['id', id] })
    } else {
      filters.push({ imatch: ['code', '^' + _.escapeRegExp(code) + '$'] })
    }
    if (_.isArray(practiceIds) && practiceIds.length) {
      filters.push({ in: ['practice_id', practiceIds] })
    }
    if (_.isNumber(practiceServiceId)) {
      filters.push({ eq: ['practice_service_id', practiceServiceId] })
    }

    const response = await apiService.fetchLoggedV3('vouchers.list', {
      expand: { voucher: ['practiceId', 'practiceServiceId'] },
      filter: { and: filters },
    })
    return {
      vouchers: _.get(response, 'prepared.vouchers.items', []),
      practices: _.get(response, 'prepared.practices', []),
      practiceServices: _.get(response, 'prepared.practiceServices', []),
    }
  }

  //
  // Calendar
  //

  async getReport({ begin, end, roleIds }) {
    let params = {
      begin: `$datetime:${moment(begin).utc().format('YYYY-MM-DDTHH:mm:ss')}`,
      end: `$datetime:${moment(end).utc().format('YYYY-MM-DDTHH:mm:ss')}`,
      limit: 1000,
    }

    if (roleIds) {
      params.filter = {
        in: ['id', roleIds],
      }
    }

    const response = await apiService.fetchLoggedV3('schedules.report', params)
    let appointments = _.get(response, 'prepared.appointments', [])
    const workingRanges = _.get(response, 'prepared.workingRanges.map.items', [])
    const roles = _.get(response, 'prepared.roleMap.items', [])

    appointments = _.orderBy(
      _.map(appointments, (appointment) => {
        const start = moment(appointment.appointedStart)
        const end = moment(appointment.appointedFinish)

        appointment.duration = moment.duration(end.diff(start)).asMinutes()

        return appointment
      }),
      [(appointment) => new Date(appointment.appointedStart), 'duration', 'id'],
      ['asc', 'desc', 'asc'],
    )

    let prepared = {
      appointmentsSource: appointments,
      appointments: _.keyBy(appointments, 'id'),
      workingRanges: _.mapValues(_.keyBy(workingRanges, 'key'), 'value'),
      roles: _.map(roles, (x) => x.value),
    }

    return { prepared }
  }
}

export default coreClient
