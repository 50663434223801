import React, { useEffect } from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import BookingLayout from '@booking/library/bookingLayout/BookingLayout'
import SelectAddressModal from '@booking/library/modals/selectAddressModal/SelectAddress.modal'
import SelectOfficeAddressModal from '@booking/library/modals/selectOfficeAddress/SelectOfficeAddress.modal'
import withBookingDataInit from '@booking/library/withBookingDataInit/withBookingDataInit'

import AddAddressModal from '@library/addAddressModal/AddAddress.modal'
import { useLoader } from '@library/CustomHooks'
import InputBase from '@library/form/fields/new/InputBase'
import Button from '@library/ui/button/Button'

import { customGoTo } from '@helpers/router'

import { $alerts, $booking, $loader, $modal } from '@store'

import styles from './BookingAddress.module.scss'

const BookingSelectPatients = () => {
  const { t } = useTranslation()

  const isLoading = useLoader(async () => {
    if ($booking.isClinicAddressType) {
      $booking.$places.SET_ITEMS([])
    } else {
      await $booking.$places.get()
    }
  }, [])

  const showAddressModal = () => {
    if ($booking.isClinicAddressType) {
      $modal.add(SelectOfficeAddressModal)
    } else if ($booking.$places.items.length) {
      $modal.add(SelectAddressModal, {
        multi: false,
      })
    } else {
      $modal.add(AddAddressModal, {
        multi: false,
        onDone: () => {
          if ($booking.$places.items.length) {
            $booking.SET_PLACE_ID($booking.$places.items[0].id)
          }
        },
      })
    }
  }

  const deleteSelectedPlace = () => {
    $booking.SET_PLACE_ID(null)
  }

  useEffect(() => {
    if (isLoading) {
      return
    }
    if ($booking.isClinicAddressType && !_.isEmpty($booking.place)) {
      $booking.SET_PLACE_ID(null)
      showAddressModal()
    } else if (_.isEmpty($booking.place)) {
      showAddressModal()
    }
  }, [isLoading])

  useLoader(async () => {
    if ($booking.serviceId) {
      await $booking.loadOfficesPracticesByPlace($booking.place)
      if ($booking.servePracticeIds.length) {
        await $booking.loadPracticeServices()
      }
    }
  }, [$booking.place])

  const handleNext = $loader.registerHandler('booking-next', async () => {
    if ($booking.serviceId && !$booking.availableServiceIds.includes($booking.serviceId)) {
      return $alerts.add(t('book.alert.selected_address_for_visit_type_not_served'))
    }
    customGoTo($booking.schemaConfig?.nextStep())
  })

  return (
    <BookingLayout
      breadcrumbs={['service', 'patients']}
      title={t('book.title.where_needs_help')}
      isLoading={isLoading}
      button={
        <Button
          mode="primary"
          tag="button"
          buttonType="button"
          disabled={_.isEmpty($booking.place)}
          label={t('btn.next')}
          action={handleNext}
          isLoading={$loader.isRunHandler('booking-next')}
        />
      }
    >
      {!_.isEmpty($booking.place) ? (
        <>
          <div className={styles.address}>
            <div className={styles.addressTitle}>{t('appt.label.address')}</div>
            <div className={styles.addressTrash} onClick={deleteSelectedPlace} />
          </div>
          <InputBase
            className="mb-10"
            label={$booking.placeExtended.line2()}
            placeholder={t('book.label.patient')}
            field={{
              value: $booking.placeExtended.line1(),
            }}
            readonly={true}
            mode={['lg', 'gray-without-border', 'value-bold']}
          />
        </>
      ) : (
        <Button
          action={showAddressModal}
          mode={['create', 'block']}
          label={t('addresses.btn.add')}
        />
      )}
    </BookingLayout>
  )
}

export default withBookingDataInit(observer(BookingSelectPatients))
