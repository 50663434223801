import { action, computed, makeObservable, observable, reaction, runInAction, toJS } from 'mobx'

import authService from '@services/auth'
import clientService from '@services/client'
import userService from '@services/user'

import { isAbsoluteEmpty } from '@helpers/other'
import { getBrowserTimezone } from '@helpers/time'

import { $auth } from '@store'

import mainConfig from '@config/main'

class _userStoreCore {
  constructor() {
    makeObservable(this, {
      user: observable,
      info: observable,
      roles: observable,
      practices: observable,
      profile: observable,
      isLoading: observable,
      _timezone: observable,

      isFilledProfile: computed,
      rolesByAppType: computed,
      role: computed,
      practice: computed,
      timezone: computed,

      SET_IS_LOADING: action,
      fetchRoles: action,
      refreshProfile: action,
      saveProfile: action,
      deleteProfile: action,
      clear: action,
    })
  }

  user = false
  info = {}
  roles = []
  practices = []
  profile = {}
  isLoading = true
  _timezone = null

  // Computed
  get isFilledProfile() {
    return (
      !_.isEmpty(this.profile) &&
      !isAbsoluteEmpty(this.profile.firstName) &&
      !isAbsoluteEmpty(this.profile.lastName) &&
      !_.isEmpty(this.profile.gender) &&
      !_.isEmpty(this.profile.dob) &&
      !_.isEmpty(this.profile.phone) &&
      !_.isEmpty(this.profile.email)
    )
  }

  get rolesByAppType() {
    let result = this.roles.filter((x) => x.role === mainConfig.appType)
    return _.orderBy(result, 'practiceId', 'asc')
  }

  get role() {
    return $auth.currentLogged?.role || {}
  }

  get practice() {
    return _.find(this.practices, (x) => x.id === this.role.practiceId)
  }

  get timezone() {
    return this._timezone || getBrowserTimezone() || this.practice.timezone || 'UTC'
  }

  // Mutations
  SET_IS_LOADING(value) {
    runInAction(() => (this.isLoading = value))
  }

  SET_TIMEZONE(value) {
    runInAction(() => (this._timezone = value))
  }

  // Actions
  async fetchRoles() {
    const { roles, practices } = await userService.getRoles()

    runInAction(() => {
      this.roles = roles
      this.practices = practices
    })
  }

  async refreshProfile(profileData = null) {
    let data = profileData

    if (!profileData) {
      const response = await authService.refresh({ sessionToken: $auth.logged.sessionToken })

      if (response.error || !$auth.isRoleExist(response.prepared)) {
        await $auth.signout(false)
        return
      }

      const { role, user } = response.prepared
      if (!role || !user) {
        await $auth.signout(false)
        return
      }

      data = {
        ...role,
        avatar: user.avatar,
        gender: user.gender,
      }
    }
    runInAction(() => {
      this.profile = data
    })
  }

  async saveProfile(data) {
    const res = await clientService.updateProfile(data)

    if (res.prepared) {
      await this.refreshProfile()

      return { isSuccess: true }
    } else {
      return { isSuccess: false }
    }
  }

  async deleteProfile(code) {
    const res = await userService.deleteProfile(code)
    return { isSuccess: !_.isUndefined(res.prepared), prepared: res.prepared }
  }

  clear() {
    this.user = false
    this.isLoading = false
    this.info = {}
    this.roles = []
    this.practices = []
    this.profile = {}
  }
}

export const userStoreCore = _userStoreCore

// auto-update roles and practices if a practice is missing.
export const initReaction = (store) => {
  reaction(
    () => JSON.stringify(store.roles),
    async (data) => {
      const roles = JSON.parse(data)
      let practiceNotFound = false

      _.forEach(roles, (role) => {
        if (!role.practiceId) {
          return
        }

        const found = _.find(store.practices, (x) => x.id === role.practiceId)

        if (!found) {
          practiceNotFound = true
          return false
        }
      })

      if (practiceNotFound) {
        await store.fetchRoles()
      }
    },
  )
}

const store = new userStoreCore()
initReaction(store)

export default store
