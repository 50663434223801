var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { Fragment, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment/moment';
import NewDatePickerModal from '@library/newDatePicker/NewDatePicker.modal';
import TimeSlotPickerHelpers from '@library/timeSlotPicker/TimeSlotPicker.helpers';
import TimeSlotPickerHooks from '@library/timeSlotPicker/TimeSlotPicker.hooks';
import BubbleLoader from '@library/ui/bubbleLoader/BubbleLoader';
import { formatDate } from '@helpers/time';
import { $main, $modal, $user } from '@store';
import closeSvg from '@assets/images/ui/close-white.svg';
import styles from './DaySlotPicker.module.scss';
var defaultLoaderParams = {
    hasLoadTrigger: false,
    onIntersectLoadTrigger: null,
};
var DaySlotPicker = function (_a) {
    var _b;
    var selectedDay = _a.selectedDay, onChangeDay = _a.onChangeDay, minDate = _a.minDate, maxDate = _a.maxDate, dates = _a.dates, visibleDaysCount = _a.visibleDaysCount, _c = _a.prevLoaderParams, prevLoaderParams = _c === void 0 ? defaultLoaderParams : _c, _d = _a.nextLoaderParams, nextLoaderParams = _d === void 0 ? defaultLoaderParams : _d, _e = _a.isInfinityScroll, isInfinityScroll = _e === void 0 ? true : _e;
    var t = useTranslation().t;
    var parsedSelectedDate = TimeSlotPickerHooks.useParsedDate(selectedDay, $user.timezone);
    var _f = useInView(), prevLoaderRef = _f.ref, inViewPrevLoader = _f.inView;
    var _g = useInView(), nextLoaderRef = _g.ref, inViewNextLoader = _g.inView;
    useLayoutEffect(function () {
        if (_.isFunction(prevLoaderParams.onIntersectLoadTrigger) && inViewPrevLoader) {
            prevLoaderParams.onIntersectLoadTrigger();
        }
    }, [inViewPrevLoader]);
    useLayoutEffect(function () {
        if (_.isFunction(nextLoaderParams.onIntersectLoadTrigger) && inViewNextLoader) {
            nextLoaderParams.onIntersectLoadTrigger();
        }
    }, [inViewNextLoader]);
    var _h = useMemo(function () {
        var availableDates = [];
        var disabledDates = [];
        dates.forEach(function (item) {
            if (item.value) {
                availableDates.push(item.key);
            }
            else {
                disabledDates.push(item.key);
            }
        });
        return [availableDates, disabledDates];
    }, [dates]), availableDates = _h[0], disabledDates = _h[1];
    var _j = useState(null), newDatePickerModalId = _j[0], setNewDatePickerModalId = _j[1];
    var modalMaxDate = useMemo(function () { return moment(maxDate).tz($user.timezone).subtract(1, 'day').format('YYYY-MM-DD'); }, [maxDate]);
    useEffect(function () {
        $modal.updateParams(newDatePickerModalId, {
            minDate: minDate,
            maxDate: modalMaxDate,
            availableDates: availableDates,
            disabledDates: disabledDates,
            hasPrevLoadTrigger: prevLoaderParams.hasLoadTrigger,
            hasNextLoadTrigger: nextLoaderParams.hasLoadTrigger,
            ref: {
                prevLoaderRef: prevLoaderRef,
                nextLoaderRef: nextLoaderRef,
            },
        });
    }, [
        minDate,
        modalMaxDate,
        availableDates,
        disabledDates,
        prevLoaderParams.hasLoadTrigger,
        nextLoaderParams.hasLoadTrigger,
    ]);
    var daysBoard = useMemo(function () {
        return TimeSlotPickerHelpers.getDaysBoard({
            dates: dates,
            todayLabel: t('new_time_slot_picker.today'),
            timezone: $user.timezone,
            isMobile: $main.isMobileSize,
            visibleDaysCount: visibleDaysCount,
        });
    }, [dates, visibleDaysCount, $user.timezone, $main.isMobileSize]);
    var selectedRef = useRef(null);
    var _k = TimeSlotPickerHooks.useFixedScroll({
        scrollDependency: minDate,
        changeContentDependency: maxDate,
        horizontalScrollToElement: selectedRef,
    }), scrollRef = _k.scrollRef, contentRef = _k.contentRef;
    var isSelectedInDaysBoard = _.findIndex(daysBoard, function (item) {
        return item.value.isSame(parsedSelectedDate, 'day');
    }) > -1;
    return (_jsx("div", __assign({ className: styles.DaySlotPicker }, { children: _jsx("div", __assign({ className: styles.body }, { children: _jsxs("div", __assign({ className: classNames(styles.rowBoardDate, (_b = {},
                    _b[styles.isMobile] = $main.isMobileSize,
                    _b)), ref: isInfinityScroll ? scrollRef : undefined }, { children: [((!$main.isMobileSize && isSelectedInDaysBoard) || $main.isMobileSize) && (_jsxs("div", __assign({ className: styles.rowBoardDateContent, ref: isInfinityScroll ? contentRef : undefined }, { children: [prevLoaderParams.hasLoadTrigger && $main.isMobileSize && (_jsx("div", __assign({ ref: prevLoaderRef }, { children: _jsx("div", __assign({ className: classNames(styles.cell, styles.cellLoader) }, { children: _jsx(BubbleLoader, {}) })) }))), daysBoard.map(function (_a, index) {
                                var _b;
                                var day = _a.day, weekDay = _a.weekDay, value = _a.value, valueString = _a.valueString, notice = _a.notice, isLocked = _a.isLocked, isToday = _a.isToday;
                                var isSelected = parsedSelectedDate.isSame(value, 'day');
                                return (_jsx(Fragment, { children: _jsxs("div", __assign({ ref: isSelected ? selectedRef : undefined }, { children: [_jsxs("div", __assign({ className: classNames(styles.cell, (_b = {},
                                                    _b[styles.cellSelected] = isSelected,
                                                    _b[styles.cellCurrent] = isToday,
                                                    _b[styles.cellLocked] = isLocked,
                                                    _b)), onClick: function () { return onChangeDay(valueString); } }, { children: [_jsx("div", __assign({ className: styles.day }, { children: day })), _jsx("div", __assign({ className: styles.weekDay }, { children: weekDay }))] })), notice && _jsx("div", __assign({ className: styles.notice }, { children: notice }))] })) }, value.toString() + index));
                            }), nextLoaderParams.hasLoadTrigger && $main.isMobileSize && (_jsx("div", __assign({ ref: nextLoaderRef }, { children: _jsx("div", __assign({ className: classNames(styles.cell, styles.cellLoader) }, { children: _jsx(BubbleLoader, {}) })) })))] }))), !$main.isMobileSize && !isSelectedInDaysBoard && (_jsxs("div", __assign({ className: classNames(styles.cell, styles.selectedDate) }, { children: [formatDate(selectedDay, { format: 'dateFull' }), _jsx("img", { src: closeSvg, alt: 'close', onClick: function () { var _a, _b; return onChangeDay(((_a = dates.find(function (date) { return date.value; })) === null || _a === void 0 ? void 0 : _a.key) || ((_b = dates[0]) === null || _b === void 0 ? void 0 : _b.key)); } })] }))), !$main.isMobileSize && dates.length > visibleDaysCount && (_jsx("div", __assign({ onClick: function () {
                            var params = $modal.add(NewDatePickerModal, {
                                type: 'selectDateTime',
                                title: t('calendar.choose_date'),
                                selectedDate: selectedDay,
                                onChange: function (value) { return onChangeDay(value); },
                                timezone: $user.timezone,
                                minDate: minDate,
                                maxDate: modalMaxDate,
                                confirmDate: false,
                                availableDates: availableDates,
                                disabledDates: disabledDates,
                                dayList: true,
                                hasPrevLoadTrigger: prevLoaderParams.hasLoadTrigger,
                                hasNextLoadTrigger: nextLoaderParams.hasLoadTrigger,
                                ref: {
                                    prevLoaderRef: prevLoaderRef,
                                    nextLoaderRef: nextLoaderRef,
                                },
                            });
                            setNewDatePickerModalId(params._core.id);
                        }, className: classNames(styles.cell, styles.moreDates) }, { children: t('new_time_slot_picker.more_dates') })))] })) })) })));
};
export default observer(DaySlotPicker);
