var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { action, makeObservable, observable, runInAction } from 'mobx';
import _ from 'lodash';
import clientService from '@services/client';
var InitPatient = {
    avatar: '',
    code: '',
    dob: '',
    email: '',
    firstName: '',
    gender: '',
    id: 0,
    isDefault: false,
    isHidden: false,
    lastName: '',
    phone: '',
    placeId: '',
    practiceId: '',
    userId: '',
};
var PatientsStore = /** @class */ (function () {
    function PatientsStore() {
        this.items = [];
        makeObservable(this, {
            items: observable,
            getById: action,
            get: action,
            add: action,
            update: action,
            remove: action,
        });
    }
    // patient = {}
    // Computed
    // Mutations
    // Actions
    PatientsStore.prototype.getById = function (id) {
        return _.find(this.items, function (x) { return String(x.id) === String(id); }) || {};
    };
    PatientsStore.prototype.get = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, clientService.getPatients()];
                    case 1:
                        response = _a.sent();
                        runInAction(function () {
                            _this.items = _.get(response, 'prepared.patients.items', []);
                        });
                        // @ts-ignore
                        if (response && response.prepared) {
                            return [2 /*return*/, { isSuccess: true, items: this.items }];
                        }
                        return [2 /*return*/, { isSuccess: false, items: this.items }];
                }
            });
        });
    };
    // async getOne({ id }) {
    //   const response = await clientService.getPatient({ id })
    //   const item = _.get(response, 'prepared.patient', {})
    //
    //   runInAction(() => {
    //     this.patient = item
    //   })
    //
    //   return { isSuccess: !_.isEmpty(item), patient: item }
    // }
    PatientsStore.prototype.add = function (data) {
        return __awaiter(this, void 0, Promise, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, clientService.addPatient(data)
                        // @ts-ignore
                    ];
                    case 1:
                        result = _a.sent();
                        if (!(result && result.prepared)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.get()
                            // @ts-ignore
                        ];
                    case 2:
                        _a.sent();
                        // @ts-ignore
                        return [2 /*return*/, { isSuccess: true, patient: result.prepared.patient }];
                    case 3: return [2 /*return*/, { isSuccess: false }];
                }
            });
        });
    };
    PatientsStore.prototype.update = function (id, data) {
        return __awaiter(this, void 0, Promise, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, clientService.updatePatient(__assign({ id: id }, data))
                        // @ts-ignore
                    ];
                    case 1:
                        result = _a.sent();
                        if (!(result && result.prepared)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.get()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/, { isSuccess: true }];
                    case 3: return [2 /*return*/, { isSuccess: false }];
                }
            });
        });
    };
    PatientsStore.prototype.remove = function (id) {
        return __awaiter(this, void 0, Promise, function () {
            var patient, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        patient = this.getById(id);
                        // @ts-ignore
                        if (patient.isDefault) {
                            return [2 /*return*/, false];
                        }
                        return [4 /*yield*/, clientService.removePatient({ id: id })
                            // @ts-ignore
                        ];
                    case 1:
                        result = _a.sent();
                        if (!(result && result.prepared)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.get()];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return PatientsStore;
}());
var store = new PatientsStore();
export default store;
