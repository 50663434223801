import React from 'react'

import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import { confirmAction } from '@library/ActionConfirmator'
import Modal from '@library/modal/Modal'
import Button from '@library/ui/button/Button'

import { $accounts, $auth } from '@store'

import styles from './ContactList.module.scss'

const ContactList = ({ setView, setCurrent, _core }) => {
  const { t } = useTranslation()

  const deleteContact = (item) => () => {
    const isCurrent = $auth.currentLogged.account.id === item.id

    confirmAction('customAlert', {
      data: {
        title: t('profile_contacts.remove_alert.title'),
        message: isCurrent
          ? t('profile_contacts.remove_alert.message_current')
          : t('profile_contacts.remove_alert.message'),
      },
      callback: async () => {
        await $accounts.remove(item)

        if (isCurrent) {
          await $auth.signout()
        }
      },
    })
  }

  const setPrimary = (item) => () => {
    $accounts.setPrimary(item)
  }

  const activate = (item) => () => {
    setCurrent(item)
    setView(item.type + 'Activate')
  }

  return (
    <>
      <div className="modal-toolbar">
        <div className="modal-toolbar-btn modal-toolbar-btn--close" onClick={_core.onHide} />
      </div>
      <Modal.Header>{t('profile_contacts.title.list')}</Modal.Header>
      <Modal.Body>
        <div className={styles.contactList}>
          {$accounts.items.map((item) => (
            <div key={item.id} className={styles.contactListItem}>
              <div className={styles.contactListItemTitle}>{item.value}</div>
              <div className={styles.contactListItemLabel}>
                {t('profile_contacts.type.' + item.type)}
                {item.primary && <>, {item.primary && t('profile_contacts.type.primary')}</>}
                {item.status === 'new' && <>, {t('profile_contacts.type.inactive')}</>}
              </div>
              {item.status === 'active' && !item.primary && (
                <div className={classNames(styles.contactListItemButtons, 'btn-group')}>
                  {(item.type === 'phone' || item.type === 'email') && (
                    <Button
                      mode="white"
                      className="btn-sm"
                      action={setPrimary(item)}
                      label={t('profile_contacts.btn.set_as_primary')}
                    />
                  )}
                  <Button
                    mode="white"
                    className="btn-sm"
                    action={deleteContact(item)}
                    label={t('btn.delete')}
                  />
                </div>
              )}
              {item.status === 'new' && (
                <div className="contact_list-item-buttons btn-group">
                  <Button
                    mode="white"
                    className="btn-sm"
                    action={activate(item)}
                    label={t('btn.activate')}
                  />
                  <Button
                    mode="white"
                    className="btn-sm"
                    action={deleteContact(item)}
                    label={t('btn.delete')}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
        <div className="btn-group">
          <Button
            mode={['create', 'block']}
            action={() => setView('channel')}
            label={t('profile_contacts.btn.add_channel')}
          />
        </div>
      </Modal.Body>
    </>
  )
}

export default observer(ContactList)
