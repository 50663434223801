import React from 'react'

const Plus = ({ color = '#000' }) => (
  <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity="0.2" clipPath="url(#clip0_211_841)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 9C20.1716 9 19.5 9.67157 19.5 10.5V19.5H10.5C9.67157 19.5 9 20.1716 9 21C9 21.8284 9.67157 22.5 10.5 22.5H19.5V31.5C19.5 32.3284 20.1716 33 21 33C21.8284 33 22.5 32.3284 22.5 31.5V22.5H31.5C32.3284 22.5 33 21.8284 33 21C33 20.1716 32.3284 19.5 31.5 19.5H22.5V10.5C22.5 9.67157 21.8284 9 21 9Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_211_841">
        <rect width="42" height="42" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default Plus
