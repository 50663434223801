import React from 'react'

import { useTranslation } from 'react-i18next'

import { confirmAction } from '@library/ActionConfirmator'
import UserPhoto from '@library/user/UserPhoto'

import { getImageDimensions, saveImageToBase64 } from '@helpers/images'

import { $alerts } from '@store'

const UploadAvatar = ({
  user = {},
  field = {},
  value,
  onChange,
  remove = false,
  sizeMax = 100, // KB
  widthMax = 500, // px
  heightMax = 500, // px
}) => {
  const { t } = useTranslation()

  const { isValid } = field

  async function handleOnChange(e) {
    let file = _.get(e, 'target.files[0]')
    let result
    const { width, height } = await getImageDimensions(file)

    if (file) {
      result = await saveImageToBase64(file)
    }

    if (file.size > sizeMax * 1000) {
      return $alerts.add(t('alert.size_must_be_KB_maximum', { sizeMax }))
    }

    if (width > widthMax || height > heightMax) {
      return $alerts.add(t('alert.dimensions_must_be_px_maximum', { widthMax, heightMax }))
    }

    onChange(result)
  }

  function removeAvatar(e) {
    confirmAction(
      'customAlert',
      {
        data: {
          title: t('profile_edit.photo.remove_alert.title'),
          message: t('profile_edit.photo.remove_alert.message'),
          done: t('btn.delete'),
          cancel: t('btn.cancel'),
        },
        callback: async () => await handleOnChange(''),
      },
      e,
    )
  }

  return (
    <div className="upload_photo mb-30">
      <label className="upload_photo-label" htmlFor="upload_photo-field">
        {value ? (
          <>
            <UserPhoto item={{ ...user, avatar: value }} />
            <div className="upload_photo-btn">{t('btn.change_photo')}</div>
          </>
        ) : (
          <>
            {user.firstName || user.lastName ? (
              <UserPhoto item={{ ...user, avatar: value }} />
            ) : (
              <div className="user_photo user_photo--add" />
            )}
            <div className="upload_photo-btn">{t('btn.add_photo')}</div>
          </>
        )}
      </label>
      <input type="file" accept="image/*" id="upload_photo-field" onChange={handleOnChange} />
      {value && remove && (
        <div className="upload_photo-btn" onClick={() => removeAvatar()}>
          {t('btn.delete_photo')}
        </div>
      )}
    </div>
  )
}

export default UploadAvatar
