import React, { forwardRef, useImperativeHandle, useState } from 'react'

import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { useTranslation } from 'react-i18next'

import InputBase from '@library/form/fields/new/InputBase'

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontFamily:
        "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'sans-serif', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: 1.25,
      color: '#000000',
      '::placeholder': {
        color: '#d1d1d6',
      },
    },
    invalid: {
      color: '#000000',
    },
  },
}

const Inner = forwardRef(({ get }, ref) => {
  const { t } = useTranslation()

  const stripe = useStripe()
  const elements = useElements()

  useImperativeHandle(ref, () => ({
    getData: () => {
      const card = elements.getElement(CardNumberElement)
      return [card, stripe]
    },
  }))

  const [cardNumber, setCardNumber] = useState({})
  const [cardExpiry, setCardExpiry] = useState({})
  const [cardCvc, setCardCvc] = useState({})

  function numberOnChange(e) {
    setCardNumber(e.error)
  }

  function expiryOnChange(e) {
    setCardExpiry(e.error)
  }

  function cvcOnChange(e) {
    setCardCvc(e.error)
  }

  return (
    <>
      <InputBase
        field={{ isInvalid: !_.isEmpty(cardNumber) }}
        mode="lg"
        className="mb-10"
        label={t('pms_card.label.card')}
      >
        <CardNumberElement
          onChange={(e) => numberOnChange(e)}
          options={{ ...ELEMENT_OPTIONS, placeholder: t('pms_card.ph.card') }}
        />
      </InputBase>

      <div className="row row--gutters-10">
        <div className="col-6">
          <InputBase
            field={{ isInvalid: !_.isEmpty(cardExpiry) }}
            mode="lg"
            label={t('pms_card.label.exp_date')}
          >
            <CardExpiryElement
              onChange={(e) => expiryOnChange(e)}
              options={{ ...ELEMENT_OPTIONS, placeholder: t('pms_card.ph.exp_date') }}
            />
          </InputBase>
        </div>
        <div className="col-6">
          <InputBase
            field={{ isInvalid: !_.isEmpty(cardCvc) }}
            mode="lg"
            label={t('pms_card.label.sec_code')}
          >
            <CardCvcElement onChange={(e) => cvcOnChange(e)} options={{ ...ELEMENT_OPTIONS }} />
          </InputBase>
        </div>
      </div>
    </>
  )
})

export default Inner
