import React from 'react'

const DocumentsXs = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path
        d="M9.554 3a1 1 0 0 1 .739.326l1.446 1.583a1 1 0 0 1 .261.675V12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h4.554zM8 9H7a1 1 0 1 0 0 2h1a1 1 0 0 0 0-2zm1-3H7a1 1 0 1 0 0 2h2a1 1 0 1 0 0-2z"
        fill="#FFF"
      />
    </g>
  </svg>
)

export default DocumentsXs
